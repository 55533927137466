import React, { useEffect } from "react";
import RequestCard from "./RequestCard";
import { useState } from "react";
import Loader from "components/ui/loaders/Loader";
import { getFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { teamSliceActions } from "store/teamSlice";
import TeamMemberSkeleton from "components/ui/loaders/TeamMemberSkeleton";

function Approved(props) {
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.team?.approvedRequests);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(() => true);

      let dataFetched = await getFetch("/manager/approvedReferrals");

      if (dataFetched?.status == "ok") {
        dispatch(
          teamSliceActions.setApprovedRequests(dataFetched?.approved_referrals)
        );
      } else if (dataFetched?.error && dataFetched?.message) {
        toast.error(dataFetched?.message);
        dispatch(teamSliceActions.setApprovedRequests([]));
      } else {
        toast.error("error loading referrals, try later");
        dispatch(teamSliceActions.setApprovedRequests([]));
      }

      setIsLoading(() => false);
    };
    getData();
  }, []);

  console.log(data);

  return (
    <>
      {isLoading && data?.length == 0 ? (
        <TeamMemberSkeleton />
      ) : (
        data?.map?.((item) => <RequestCard accepted data={item} />)
      )}
    </>
  );
}

export default Approved;
