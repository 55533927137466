import React, { useState, useEffect } from "react";
import { MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { input1 } from "components/tailwindClasses";
import { addScanEngineSliceActions } from "store/scanner/addScanEngineSlice";

export const scanTypes = [
  { id: "active", label: "Active" },
  { id: "reconaissance", label: "Reconaissance" },
  { id: "darkWebMonitoring", label: "Dark Web Monitoring" },
];

export const scanSpeeds = [
  {
    id: "quick",
    label: "Quick",
  },
  {
    id: "medium",
    label: "Medium",
  },
];

function Index(props) {
  const marks = [
    {
      value: 4,
      label: "4",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 10,
      label: "10",
    },
  ];

  const dispatch = useDispatch();

  const name = useSelector((state) => state.scanner.addScanEngine.step1.name);
  const type = useSelector(
    (state) => state.scanner.addScanEngine.step1.scanType
  );
  const speed = useSelector(
    (state) => state.scanner.addScanEngine.step1.scanSpeed
  );
  const threads = useSelector(
    (state) => state.scanner.addScanEngine.step1.threads
  );

  useEffect(() => {
    !threads && dispatch(addScanEngineSliceActions.setThreads(3));
  }, [threads]);

  return (
    <form id="step1-form">
      <div
        style={{
          padding: "2%",
          paddingBottom: "3%",
          borderRadius: "10px",
          marginBottom: "1%",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        <div className="avatar-text">Scan Engine Name</div>
        <input
          value={name}
          onChange={(e) => {
            dispatch(addScanEngineSliceActions.setName(e.target.value));
          }}
          style={{ height: "50px" }}
          className={input1 + " mt-1p"}
          placeholder="Type Here"
          required
        />
        <div className="avatar-text mt-1p">Select Scan Type</div>

        <div>
          <Select
            sx={{
              width: "100%",
              boxSizing: "border-box",
              height: "45px",
              borderRadius: "5px",
            }}
            required
            value={type}
            onChange={(e) => {
              dispatch(addScanEngineSliceActions.setScanType(e.target.value));

              if (e.target.value == "active" || e.target.value == "passive") {
                dispatch(addScanEngineSliceActions.resetStep2());
              }
            }}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            MenuProps={{
              PaperProps: { sx: { maxHeight: 400 } },
            }}
          >
            <MenuItem value="">
              <em>Select </em>
            </MenuItem>

            {scanTypes?.map((st, i) => {
              return <MenuItem value={st.id}>{st.label}</MenuItem>;
            })}
          </Select>
        </div>

        <div className="avatar-text mt-3p">Threads</div>

        <Box sx={{ width: "60%" }} className="mt-1p ml-4">
          <Slider
            aria-label="Custom marks"
            defaultValue={marks[0].value}
            getAriaValueText={(v) => v}
            step={null}
            marks={marks}
            min={marks[0].value}
            max={marks[marks.length - 1].value}
            value={threads}
            onChange={(e) => {
              dispatch(addScanEngineSliceActions.setThreads(e.target.value));
            }}
          />
        </Box>
        <div className="avatar-text mt-1p">Scan Speed</div>

        <div>
          <Select
            sx={{
              width: "100%",
              boxSizing: "border-box",
              height: "45px",
              borderRadius: "5px",
            }}
            required
            value={speed}
            onChange={(e) => {
              dispatch(addScanEngineSliceActions.setScanSpeed(e.target.value));
            }}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            MenuProps={{
              PaperProps: { sx: { maxHeight: 400 } },
            }}
          >
            <MenuItem value="">
              <em>Select </em>
            </MenuItem>
            {scanSpeeds?.map((ss, i) => {
              return <MenuItem value={ss.id}>{ss.label}</MenuItem>;
            })}
          </Select>
        </div>
      </div>
    </form>
  );
}

export default Index;
