import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  team: [],
  pendingRequests: [],
  approvedRequests: [],
  disabledMembers: {
    rejected: [],
    deleted: [],
  },
};

let teamSlice = createSlice({
  name: "teamSlice",
  initialState: initialState,
  reducers: {
    setTeam(state, action) {
      state.team = action.payload;
    },
    setPendingRequests(state, action) {
      state.pendingRequests = action.payload;
    },
    setApprovedRequests(state, action) {
      state.approvedRequests = action.payload;
    },
    setDisabledMembers(state, action) {
      state.disabledMembers = action.payload;
    },
    reset(state) {
      state = initialState;
    },
  },
});

export const teamSliceActions = teamSlice.actions;

export default teamSlice;
