import React, { useEffect, useState } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Gauge from "components/ui/charts/Gauge";

import { useSelector, useDispatch } from "react-redux";
import { dashboardInfoSliceActions } from "store/dashboardInfoSlice";

import { getFetch, postFetch } from "lib/fetch";

import PieAndBarChart from "components/ui/charts/PieAndBarChart";
import { riskScoreColor } from "Util/RiskScoreColors";

function Index(props) {
  //
  const targetDashboardChartData = useSelector(
    (state) => state.scanner.targetDashboard.home.chartBoxData
  );

  return (
    <div>
      <div className="flex flex-col my-2">
        <div className="flex">
          <div className="w-1/2">
            <Gauge rating={targetDashboardChartData?.riskScore} />
          </div>

          <div className="flex justify-center items-center w-1/2">
            <div>
              <div
                className="chart-risk-score text-white"
                style={{
                  backgroundColor: riskScoreColor(
                    targetDashboardChartData?.riskScore
                  ),
                }}
              >
                {targetDashboardChartData?.riskScore?.toFixed(1) || "-"}
              </div>
              <div className="risk-score-text dark:text-white">
                &nbsp;&nbsp;&nbsp;&nbsp;Risk Score
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-end justify-evenly mt-4">
          <div className="w-1/2">
            <PieAndBarChart
              values={{
                critical: targetDashboardChartData?.critical,
                high: targetDashboardChartData?.high,
                medium: targetDashboardChartData?.medium,
                low: targetDashboardChartData?.low,
              }}
              type="vulnerability"
              width={72}
            />
          </div>
          <div className="w-1/2">
            <PieAndBarChart
              values={{
                new: targetDashboardChartData?.new,
                informative: targetDashboardChartData?.informative,
                triaged: targetDashboardChartData?.triaged,
                resolve: targetDashboardChartData?.resolve,
              }}
              type="status"
              width={72}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
