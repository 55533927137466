import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { Link, Navigate, useNavigate } from "react-router-dom";
import SeverityGroupButton from "components/ui/buttons/SeverityGroupButton";
import Performance from "components/ui/buttons/Performance";

import { useDispatch, useSelector } from "react-redux";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";
import { getFetch } from "lib/fetch";
import { dashboardInfoSliceActions } from "store/dashboardInfoSlice";
import { riskScoreColor } from "Util/RiskScoreColors";
import { Button } from "@mui/material";
import TableLoadingSkeleton from "components/ui/loaders/TableSkeleton";

export default function StickyHeadTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";

  const handleTargetClick = (e) => {
    const dataSets = e.target.dataset;
    dispatch(
      targetDashboardSliceActions.setCurrentTarget({
        name: dataSets.assetname,
        id: dataSets.assetid,
      })
    );
    navigate("/target-dashboard", { state: { fromApp: true } });
  };

  const handleDownloadClick = (hasReport, assetId) => {
    navigate("/report", {
      state: { downloadReport: true, assetId: assetId },
    });
  };

  const assets = useSelector((state) => state.dashboardInfo.assets);

  let data = assets.map((item) => {
    return {
      _id: item?.id,
      assetName: item?.title,
      riskScore: item?.risk_score?.toFixed?.(1),
      performance: item?.user_performance,
      totalVulnerabilities: item?.asset_vuln_count,
      criticalSeverity: item?.critical_vuln_count,
      highSeverity: item?.high_vuln_count,
      mediumSeverity: item?.medium_vuln_count,
      lowSeverity: item?.low_vuln_count,
      riskScore: item?.risk_score,
      hasReport: item?.has_generated_report,
    };
  });

  const userRole = useSelector((state) => state.user.data.role);

  const columns = [
    { id: "assetItem", label: "Assets", minWidth: 270, align: "left" },
    {
      id: "performanceItem",
      label:
        userRole == "S_MANAGER" || userRole == "MANAGER"
          ? "Risk Score"
          : "Performance",
      minWidth: 170,
      align: "left",
    },
    {
      id: "totalVulnerabilitiesItem",
      label: "Total Vulnerabilities",
      minWidth: 170,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "severityItem",
      label: "Severity",
      minWidth: 270,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];

  if (userRole === "MANAGER" || userRole == "S_MANAGER") {
    columns.push({
      id: "btnItem",
      label: "Download Report",
      minWidth: 170,
      align: "left",
    });
  }

  let rows = [];

  rows = data.map((item) => {
    return {
      assetItem: (
        <div
          data-assetname={item.assetName}
          data-assetid={item._id}
          onClick={handleTargetClick}
          className="cursor-pointer"
        >
          <Link className="pointer-events-none">{item.assetName}</Link>
        </div>
      ),
      performanceItem:
        userRole == "S_MANAGER" || userRole == "MANAGER" ? (
          item?.riskScore ? (
            <Button
              variant="contained"
              sx={{
                bgcolor: riskScoreColor(item?.riskScore),
                fontWeight: "bold",
                cursor: "default",
                color: "white",
              }}
            >
              {item?.riskScore?.toFixed(1)}{" "}
            </Button>
          ) : (
            "-"
          )
        ) : (
          <Performance percentage={item?.performance || 0} />
        ),
      totalVulnerabilitiesItem: (
        <Button variant="contained" sx={{ bgcolor: "#6922D6", color: "white" }}>
          {item.totalVulnerabilities}
        </Button>
      ),
      severityItem: (
        <SeverityGroupButton
          critical={item.criticalSeverity}
          high={item.highSeverity}
          medium={item.mediumSeverity}
          low={item.lowSeverity}
        />
      ),

      btnItem: (
        <div
          className="cursor-pointer"
          onClick={() => {
            handleDownloadClick(item?.hasReport, item?._id);
          }}
        >
          <img
            src={isDarkTheme ? "/downloadDark.svg" : "/download.svg"}
            alt=""
          />
        </div>
      ),
    };
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isLoading = useSelector((state) => state?.dashboardInfo?.isLoading);

  return (
    <div className="mt-1p ">
      <Paper className="w-full overflow-hidden dark:bg-lightBlack ">
        <Table stickyHeader aria-label="sticky table ">
          <TableHead sx={{}}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  // style={{
                  //   minWidth: column.minWidth,
                  // }}
                  sx={{
                    bgcolor: "rgba(217, 217, 217, .25)",
                  }}
                  className="font-semibold dark:bg-lightBlack3  dark:text-lightBlack1 dark:border-b9 "
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading && rows?.length == 0 ? (
            <TableLoadingSkeleton
              rowCount={5}
              columnCount={columns.length}
              tableCellClassName={
                "dark:text-white dark:border-b9 dark:bg-lightBlack"
              }
            />
          ) : (
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className="dark:text-white dark:border-b9 dark:bg-lightBlack"
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className=" dark:text-white"
        />
      </Paper>
    </div>
  );
}
