import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { useSelector, useDispatch } from "react-redux";
import { stepsSliceActions } from "store/stepsSlice";

const steps = [
  {
    description: "can add description",
    label: "Asset Type",
  },
  {
    description: "can add description",
    label: "Description",
  },
  {
    description: "can add description",
    label: "Team",
  },
  {
    description: "can add description",
    label: "Invitation",
  },
  {
    label: "Review & Confirm",
    description: "can add description",
  },
];

export default function VerticalLinearStepper(props) {
  let dispatch = useDispatch();

  const activeStep = useSelector((state) => state.steps.activeStep);
  const handleReset = () => {
    dispatch(stepsSliceActions.setActiveStep(0));
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 4 ? (
                  <Typography variant="caption">
                    <p className="dark:text-white">Last step</p>{" "}
                  </Typography>
                ) : (
                  <Typography variant="caption">
                    <p className="dark:text-white">Step {index + 1}</p>
                  </Typography>
                )
              }
            >
              <p style={{ fontWeight: "600" }} className="dark:text-white">
                {" "}
                {step.label}
              </p>
            </StepLabel>
            <StepContent>
              <Typography>
                <p className="dark:text-white"> {step.description}</p>
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
