import React from "react";
import Login from "./components/Login/Login";
import CompanyManagerLogin from "./components/Login/CompanyManager";
import SecurityManagerLogin from "./components/Login/SecurityManager";
import CompanyEngineerLogin from "./components/Login/CompanyEngineer";
import SecurityEngineerLogin from "./components/Login/SecurityEngineer";
import Link from "./components/Link";
import ChangePass1 from "./components/ChangePass1";
import ChangePass2 from "./components/ChangePass2";
import EmailOtp from "./components/EmailOtp";
import SignUp from "./components/SignUp";

export const LoginComponent = Login;
export const LinkComponent = Link;
export const ChangePass1Component = ChangePass1;
export const ChangePass2Component = ChangePass2;
export const EmailOtpComponent = EmailOtp;
export const SignUpComponent = SignUp;
export const LoginSecurityEngineerComponent = SecurityEngineerLogin;
export const CompanyManagerLoginComponent = CompanyManagerLogin;
export const SecurityManagerLoginComponent = SecurityManagerLogin;
export const CompanyEngineerLoginComponent = CompanyEngineerLogin;
export const SecurityEngineerLoginComponent = SecurityEngineerLogin;

function Index(props) {
  return <div></div>;
}

export default Index;
