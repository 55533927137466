import React, { useEffect } from "react";

import TopSection from "./TopSection/Index";
import LeftSection from "./LeftSection/Index";
import RightSection from "./RightSection/Index";
import { getFetch } from "lib/fetch";
import { useDispatch, useSelector } from "react-redux";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";
import { toast } from "react-toastify";

export default function ChartBox() {
  const dispatch = useDispatch();
  let currentAsset = useSelector(
    (state) => state?.targetDashboard?.currentTarget?.id
  );

  useEffect(() => {
    let getData = async () => {
      dispatch(targetDashboardSliceActions.setHomeIsLoading(true));

      const res = await getFetch("/targetDashboard", { assetId: currentAsset });
      if (res?.status == "ok") {
        dispatch(
          targetDashboardSliceActions.setChartBoxData(res?.data?.chartBoxData)
        );
        dispatch(
          targetDashboardSliceActions.setTopVulnerabilities(
            res?.data?.topVulnerabilities
          )
        );
        dispatch(targetDashboardSliceActions.setTopCve(res?.data?.topCve));

        dispatch(
          targetDashboardSliceActions.setWebsiteUrl(res?.data?.websiteUrl)
        );

        dispatch(
          targetDashboardSliceActions.setActivityFeed(res?.data?.activityFeed)
        );
        dispatch(
          targetDashboardSliceActions.setTimelineData(res?.data?.timeline_data)
        );
      } else {
        toast.error("some error occured, try later");
      }

      dispatch(targetDashboardSliceActions.setHomeIsLoading(false));
    };
    currentAsset && getData();
  }, [currentAsset]);
  return (
    <div className="rounded-b bg-white dark:bg-lightBlack p-1p">
      <div className="mb-2p">
        <TopSection />
      </div>

      <div style={{ display: "flex" }}>
        <div className="w-1/2 mr-1p">
          <LeftSection />
        </div>
        <div className="w-1/2 ml-1p">
          <RightSection />
        </div>
      </div>
    </div>
  );
}
