import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
// import ButtonGroup from "@mui/material/ButtonGroup";
// import DotButton from "../../DotButton";
import { Box, Typography } from "@mui/material";
import SeverityButton from "components/ui/buttons/SeverityButton";
import { useSelector } from "react-redux";
import dashboardInfoSlice from "store/dashboardInfoSlice";
import TableLoadingSkeleton from "components/ui/loaders/TableSkeleton";

export default function Vulnerability() {
  const topVulnerabilities =
    useSelector((state) => state?.dashboardInfo?.topVulnerabilities) || [];
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";

  const rows = topVulnerabilities?.map((vuln) => {
    return {
      id: vuln?._id,
      severity: SeverityButton(vuln?.severity),
      name: vuln?.name,
      box: (
        <div className="w-6 h-6 text-center bg-greyBg4 border-b4 border rounded">
          {vuln?.count}
        </div>
      ),
    };
  });

  const isLoading = useSelector((state) => state?.dashboardInfo?.isLoading);

  return (
    <TableContainer
      sx={{ height: "525px", overflowY: "scroll" }}
      className="dark:bg-lightBlack "
    >
      <Table>
        {/* <TableHead></TableHead> */}
        {isLoading && rows?.length == 0 ? (
          <TableLoadingSkeleton
            columnCount={3}
            rowCount={10}
            tableRowClassName={"dark:bg-lightBlack "}
            tableCellClassName={" dark:text-white dark:border-b dark:border-b9"}
          />
        ) : (
          <TableBody>
            {rows?.map((row, i) => (
              <TableRow key={i} className="dark:bg-lightBlack ">
                <TableCell
                  component="th"
                  scope="row"
                  className="dark:border-b dark:border-b9"
                >
                  {row?.severity}
                </TableCell>
                <TableCell
                  align="left"
                  className=" dark:text-white dark:border-b dark:border-b9"
                >
                  {row?.name}
                </TableCell>
                <TableCell
                  align="left"
                  className=" dark:text-white dark:border-b dark:border-b9"
                >
                  {row?.box}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
