import React, { useState, useEffect } from "react";
import { getFetch, postFetch,pythonGetFetch,pythonPostFetch } from "lib/fetch";
import { NotificationFormat } from "./notificationTypes";
import { useDispatch, useSelector } from "react-redux";
import { Menu, MenuItem } from "@mui/material";
import Fade from "@mui/material/Fade";
import { timeSince } from "Util/TimeSince";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "components/ui/loaders/Loader";

function Index({ children, passHasNewNotification, reload }) {
  const user = useSelector((state) => state.user.data);
  const isDarkMode = useSelector((state) => state.general.theme) == "dark";
  const appType = useSelector((state)=>state.general.currentApp);
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [notifications, setNotifications] = useState([]);

  // for popup
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);

    try {
      let res = await getFetch("/notifications/markAllReadForUser");
      if (res.status == "ok") {
      } else console.log("notifications not marked as read");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getData = async () => {

      let res = '';
      
      if(appType === "SCANNER"){
        setLoading(true);
        res = await pythonPostFetch("/get_notification",{});
      }else{
        setLoading(true);
        res = await getFetch("/notifications");
      }

      if (res.status == "ok") {
        setNotifications(() =>
          res.data
            .map((notification) => {
              return {
                data: notification,
                component: NotificationFormat(
                  notification,
                  user,
                  navigate,
                  dispatch,
                  location,
                  isDarkMode
                ),
              };
            })
            .filter((n) => n != null)
        );
        setLoading(false);

        passHasNewNotification(() => res?.hasNewNotification);
      }

      // setTimeout(getData, 1000 * 5 * 60); // will be called every 50 mins, change to 5 later
    };
    getData();
  }, [anchorEl, user.id, isDarkMode]);

  // anchorEl for reloading data on every click

  //

  const [deleteLoading, setDeleteLoading] = useState(false);
  let handleClearAllClick = async () => {
    setDeleteLoading(() => true);

    const res = await postFetch("/notifications/deleteForUser", {
      ids: notifications.map((n) => n.data._id),
    });
    if (res?.status == "ok") {
      setNotifications(() => []);
    } else if (res?.error && res?.message) {
      toast.error(res.message);
    } else {
      toast.error("Something went wrong, try again later");
    }

    setDeleteLoading(() => false);
  };

  return (
    <>
      <div onClick={handleClick}>{children}</div>

      <Menu
        id="fade-menu"
        // MenuListProps={{
        //   "aria-labelledby": "fade-button",
        // }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className="max-h-[500px] overflow-y-scroll mt-1p "
      >
        {notifications?.length > 0 ? (
          <>
            <MenuItem
              sx={{
                paddingY: "1%",
                fontSize: "14px",
                position: "relative",
                borderBottom: "2px solid rgba(0, 0, 0, 0.2)",
                paddingX: "0",
                maxWidth: "680px",
                // "&:hover": { backgroundColor: "white", cursor: "default" },
              }}
              className="dark:bg-lightBlack hover:bg-white dark:hover:bg-lightBlack"
            >
              <div
                className="text-end w-full j mr-4 text-sm font-bold text-[#CE3733] cursor-pointer"
                onClick={!deleteLoading && handleClearAllClick}
              >
                {deleteLoading ? <Loader /> : "Clear All"}
              </div>
                         </MenuItem>
             { <>
{
              appType === "SCANNER" ? 
              <>
{notifications?.map((n, i) => {
              return (
                <MenuItem
                  onClick={handleClose}
                  sx={{
                    paddingY: "10px",
                    fontSize: "14px",
                    position: "relative",
                    borderBottom:
                      i != notifications.length - 1 &&
                      "2px solid rgba(0, 0, 0, 0.2)",
                    paddingX: "0",
                    maxWidth: "680px",
                    cursor: "default",
                    backgroundColor: n?.data?.read
                      ? "white"
                      : "rgb(244, 248, 255)",
                  }}
                  className={
                    n?.data?.read
                      ? " dark:bg-lightBlack dark:text-white dark:hover:bg-lightBlack2"
                      : " dark:bg-lightBlack3 dark:text-white dark:hover:bg-lightBlack2"
                  }
                >
                  <div className="mx-4"> 
                    <div className="py-3p mr-10 whitespace-normal">
                      {n?.data?.type === "scan_add" && 
                              <>
                                New <span className={`${n?.data?.content?.type === "Active" ? 
'bg-red-500' : 'bg-green-500'} px-2 py-1 rounded-lg text-white font-bold`}>{n?.data?.content?.type}</span> scan <span className="font-bold">{n?.data?.content?.scan_name}</span>  added for asset <span className="text-blue-500 underline font-bold">{n?.data?.content?.asset_name}</span> </>
                      }

                      {
                        n?.data?.type === "scan_start" &&
                        <>
                            <span className={`${n?.data?.content?.type === "Active" ? 
'bg-red-500' : 'bg-green-500'} px-2 py-1 rounded-lg text-white font-bold`}>{n?.data?.content?.type.charAt(0).toUpperCase() + n?.data?.content?.type.slice(1)}</span> 
                             {" "}scan has been initiated for asset <span className="text-blue-500 underline font-bold">{n?.data?.content?.asset_name}</span>
                        </>
                      }
                    </div>
                    <div className="absolute right-2 bottom-1 text-xs font-bold opacity-50 leading-[14.5px]">
                      {" "}
                      {timeSince(new Date(n?.data?.added_at).getTime())} ago
                    </div>
                  </div>
                </MenuItem>
              );
            })}


  
              </> : 

              <>
 {notifications?.map((n, i) => {
              return (
                <MenuItem
                  onClick={handleClose}
                  sx={{
                    paddingY: "10px",
                    fontSize: "14px",
                    position: "relative",
                    borderBottom:
                      i != notifications.length - 1 &&
                      "2px solid rgba(0, 0, 0, 0.2)",
                    paddingX: "0",
                    maxWidth: "680px",
                    cursor: "default",
                    backgroundColor: n?.data?.read
                      ? "white"
                      : "rgb(244, 248, 255)",
                  }}
                  className={
                    n.data.read
                      ? " dark:bg-lightBlack dark:text-white dark:hover:bg-lightBlack2"
                      : " dark:bg-lightBlack3 dark:text-white dark:hover:bg-lightBlack2"
                  }
                >
                  <div className="mx-4">
                    <div className="py-3p mr-10 whitespace-normal">
                      {n?.component}
                    </div>
                    <div className="absolute right-2 bottom-1 text-xs font-bold opacity-50 leading-[14.5px]">
                      {" "}
                      {timeSince(n?.data?.createdDate)} ago
                    </div>
                  </div>
                </MenuItem>
              );
            })}


              </>
            }

              </> }

                                 </>
        ) : (
          <MenuItem>No New Notifications !</MenuItem>
        )}
      </Menu>
    </>
  );
}

export default Index;
