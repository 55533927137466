import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userSliceActions } from "store/userSlice";
import axios from "axios";
import { getFetch } from "lib/fetch";

function Protected({ role }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);

  useEffect(() => {
    const fetchData = async () => {
      const bearer_token = window.localStorage.getItem("bearer_token");
      axios.defaults.headers.common["Authorization"] = bearer_token;

      if (bearer_token) {
        const data = await getFetch("/profile");

        if (data && data?.isAuth === true) {
          dispatch(
            userSliceActions.setData({
              id: data?.id,
              name: data?.name,
              email: data?.email,
              role: data?.role_name,
              roleId: data?.role_id,
              isSecProvider: data?.is_sec_provider,
              isAuth: data?.isAuth,
              profileImage: data?.profile_image,
              companyName: data?.cname,
              phoneNumber: data?.phone_number,
              twoFaEnabled: data?.two_fa_enabled,
            })
          );
        } else {
          // console.log("if data?.isAuth is not true : ", data);
          dispatch(userSliceActions.setIsAuth(false));
          navigate("/login");
        }
      } else {
        // console.log("no bearer_token ");

        dispatch(userSliceActions.setIsAuth(false));
        navigate("/login");
      }
    };

    fetchData();
  }, []);

  if (user.isAuth) {
    if (user.role === "Manager") {
      user.isSecProvider
        ? dispatch(userSliceActions.setRole("S_MANAGER"))
        : dispatch(userSliceActions.setRole("MANAGER"));
    } else if (user.role === "Engineer") {
      user.isSecProvider
        ? dispatch(userSliceActions.setRole("S_ENGINEER"))
        : dispatch(userSliceActions.setRole("ENGINEER"));
    }
  } else {
    dispatch(userSliceActions.setRole(undefined));
  }

  return role ? (
    user.isAuth && user.role === role ? (
      <Outlet />
    ) : null
  ) : (
    user.isAuth && <Outlet />
  );
}

export default Protected;
