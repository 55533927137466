const Columns = (role, currentOption) => {
  let col = [
    {
      id: "status",
      numeric: false,
      label: "Status",
      minWidth: 150,
      align: "left",
    },
    {
      id: "title",
      numeric: false,
      label: "Title",
      // minWidth: 400,
      align: "left",
    },
    {
      id: "type",
      numeric: false,
      label: "Type",
      // minWidth: 300,
      align: "left",
    },
    {
      id: "severity",
      numeric: false,
      label: "Severity",
      // minWidth: 120,
      align: "left",
    },
    {
      id: "cvssScore",
      numeric: true,
      label: "CVSS Score",
      minWidth: 50,
      align: "left",
    },
    {
      id: "cve",
      numeric: false,
      label: "CVE/CWE",
      // minWidth: 100,
      align: "left",
    },
    {
      id: "researcher",
      numeric: false,
      label: "Researcher",
      minWidth: 150,
      align: "left",
    },
    {
      id: "createdOn",
      numeric: false,
      label: "Created On",
      // minWidth: 200,
      align: "left",
    },
  ];

  if (role == "S_ENGINEER") {
    col = col.filter((c) => c.id != "researcher");
  }
  if (currentOption != "Drafts") {
    col.push({
      id: "hasNewChat",
      label: "",
      minWidth: 100,
      align: "left",
    });
  }

  return col;
};

export default Columns;
