import { Textarea } from "@mui/joy";
import { Input, MenuItem, Select, TextareaAutosize } from "@mui/material";
import { input1 } from "components/tailwindClasses";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addReportSliceActions } from "store/addReportSlice";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { scanTypes, scanSpeeds } from "../Step1/Index";
import { robotsFiles, sitemapFiles, crawlScopes, depths } from "../Step2/Index";
import { addScanProfileSliceActions } from "store/scanner/addScanProfileSlice";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import { toast } from "react-toastify";

const frequencyOptions = [
  { id: "once", label: "Scan One Time" },
  { id: "weekly", label: "Scan every 7 days" },
  { id: "biweekly", label: "Scan every 14 days" },
  { id: "monthly", label: "Scan every month" },
];

function Index(props) {
  const dispatch = useDispatch();

  const scanNow = useSelector(
    (state) => state.scanner.addScanProfile.step3.scanNow
  );
  const date = useSelector((state) => state.scanner.addScanProfile.step3.date);
  const frequency = useSelector(
    (state) => state.scanner.addScanProfile.step3.frequency
  );

  useEffect(() => {
    scanNow == null && dispatch(addScanProfileSliceActions.setScanNow(1));
  }, []);

  return (
    <form id="step3-form">
      <div
        style={{
          padding: "2%",
          paddingBottom: "3%",
          borderRadius: "10px",
          marginBottom: "1%",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white "
      >
        <div className="avatar-text pb-2p border-b dark:border-b9 font-medium w-full">
          Choose Scan Time
        </div>

        <div>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={scanNow}
              onChange={(e) => {
                dispatch(addScanProfileSliceActions.setScanNow(e.target.value));
                if (e.target.value == 1) {
                  dispatch(addScanProfileSliceActions.setFrequency(null));
                  dispatch(addScanProfileSliceActions.setDate(null));
                }
              }}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Scan Now"
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="Schedule Scan"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className="avatar-text mt-1p">Date, Time</div>
        <div className="w-1/2">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateTimePicker
              value={dayjs(date)}
              onChange={(e) => {
                // select a future date
                if (dayjs(e).isBefore(dayjs(Date.now()))) {
                  toast.info("Please select a future date");
                  return;
                }

                dispatch(
                  addScanProfileSliceActions.setDate(dayjs(e).toISOString())
                );
              }}
              disabled={scanNow == 1}
            />
          </LocalizationProvider>
        </div>
        <div className="avatar-text mt-1p">Frequency</div>

        <div>
          <Select
            sx={{
              width: "100%",
              boxSizing: "border-box",
              height: "45px",
              backgroundColor: "#D9D9D940",
              border: "0px solid rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
            }}
            className="dark:text-white bg-inputBg dark:bg-inputBgDark"
            required
            value={frequency}
            onChange={(e) => {
              dispatch(addScanProfileSliceActions.setFrequency(e.target.value));
            }}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            MenuProps={{
              PaperProps: { sx: { maxHeight: 400 } },
            }}
            disabled={scanNow == 1}
          >
            <MenuItem value="">
              <em>Select </em>
            </MenuItem>

            {frequencyOptions?.map((f, i) => {
              return <MenuItem value={f.id}>{f.label}</MenuItem>;
            })}
          </Select>
        </div>
      </div>
    </form>
  );
}

export default Index;
