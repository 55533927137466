import React from "react";
import Profile from "./Profile/Profile";
import CompanyProfile from "./CompanyProfile/Index";
import ChangePassword from "./ChangePassword/Index";
import LoginActivity from "./LoginActivity/Index";

import { useSelector } from "react-redux";
function Index(props) {
  const selected = useSelector((state) => state.profile.selected);
  const user = useSelector((state) => state.user.data);

  return (
    <div style={{ padding: "1%" }}>
      {selected == 0 && <Profile />}
      {selected == 1 &&
        (user.role == "MANAGER" || user.role == "S_MANAGER") && (
          <CompanyProfile />
        )}
      {selected == 2 && <ChangePassword />}
      {selected == 3 && <LoginActivity />}
    </div>
  );
}

export default Index;
