import React, { useState, useEffect } from "react";
import UserNode from "./UserNode";
import { getFetch } from "lib/fetch";
import { useDispatch, useSelector } from "react-redux";
import { teamSliceActions } from "store/teamSlice";
import Loader from "components/ui/loaders/Loader";
import { toast } from "react-toastify";
import TeamMemberSkeleton from "components/ui/loaders/TeamMemberSkeleton";

function Index(props) {
  const dispatch = useDispatch();

  const team = useSelector((state) => state.team.team);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(() => true);

      let response = await getFetch("/getTeamMembersAssetDetails");
      if (response?.status == "ok") {
        dispatch(teamSliceActions.setTeam(response?.members_details));
      } else if (response?.error && response?.message) {
        toast.error(response?.message);

        dispatch(teamSliceActions.setTeam([]));
      } else {
        toast.error("error loading team members, try later");
        dispatch(teamSliceActions.setTeam([]));
      }
      setIsLoading(() => false);
    };
    getData();
  }, []);

  return (
    <div>
      {isLoading && team?.length == 0 ? (
        <TeamMemberSkeleton />
      ) : (
        team &&
        team?.length > 0 &&
        team?.map((member, index) => {
          return <UserNode data={member} />;
        })
      )}
    </div>
  );
}

export default Index;
