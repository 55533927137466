import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";

import { Box, Divider, Modal } from "@mui/material";
import { postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { InviteCompanyComponent } from "features/invite";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { targetSliceActions } from "store/targetSlice";
import { blueGradient1 } from "components/tailwindClasses";

export default function DotButton({ id, row = {} }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openModal, setOpen] = React.useState(false);
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const handleInviteClick = () => {
    handleModalOpen();
  };

  const navigate = useNavigate();
  const handleDownloadClick = () => {
    row?.hasReport
      ? navigate("/report")
      : navigate("/report", {
          state: { downloadReport: true, assetId: id },
        });
  };

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [deleteModalStep, setDeleteModalStep] = useState(0);
  const handleDeleteModalOpen = () => {
    if (!user.twoFaEnabled) {
      toast.error("please enable two factor Authentication to delete asset");
    } else setOpenDeleteModal(true);
  };
  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const [twoFaInput, setTwoFaInput] = useState("");

  const assets = useSelector((state) => state?.target?.targets);
  const handleDeleteClick = async () => {
    let response = await postFetch("/removeAsset", {
      id: id,
      token: twoFaInput,
    });

    if (response.status == "ok") {
      // toast.success("Asset Deleted ! ");
      dispatch(
        targetSliceActions.setTargets(assets?.filter?.((a) => a.id != id))
      );

      toast.success("asset removed successfully !");
      handleClose();
    } else if (response?.error && response?.message) {
      toast.error(response?.message);
    } else {
      toast.error("Some error occured ! Try Later");
    }
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img src="/dotButtonOutline.svg" alt="" width={"20px"} />
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleDownloadClick}>Download Report</MenuItem>
        <Divider sx={{}} />
        <MenuItem onClick={handleInviteClick}>Invite Company</MenuItem>
        <Divider sx={{}} />
        <MenuItem onClick={handleDeleteModalOpen}>Delete Target</MenuItem>

        <Modal
          open={openDeleteModal}
          onClose={handleDeleteModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: 24,
              width: "50%",
            }}
            className="bg-white rounded-10 shadow dark:bg-lightBlack dark:text-white p-1p"
          >
            <div>
              <div className="text-2xl font-bold flex items-center justify-center pb-2p border-b border-b2 dark:border-b9">
                Delete Asset
              </div>

              <div style={{ paddingTop: "2%" }}>
                Are you sure, you want to delete asset{" "}
                <span className="font-bold">{row?.assetName}</span> ?
              </div>

              {deleteModalStep == 1 && (
                <div>
                  <div className="font-bold mt-2p">
                    Two Factor Authentication:
                  </div>
                  <input
                    type="text"
                    className="second-step-input bg-inputBg dark:bg-inputBgDark h-15"
                    value={twoFaInput}
                    onChange={(e) => {
                      setTwoFaInput(() => e.target.value);
                    }}
                  />
                </div>
              )}

              <div className="flex items-center justify-end mt-2p">
                <div
                  className="cursor-pointer mr-8"
                  onClick={handleDeleteModalClose}
                >
                  {deleteModalStep == 0 ? "No" : "Cancel"}
                </div>

                <div
                  className={
                    "h-15 w-60 font-bold text-white rounded-10 cursor-pointer flex items-center justify-center" +
                    blueGradient1
                  }
                  onClick={() => {
                    deleteModalStep == 0 && setDeleteModalStep(() => 1);

                    deleteModalStep == 1 && handleDeleteClick();
                  }}
                >
                  {deleteModalStep == 0 ? "Yes" : "Done"}
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </Menu>

      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <InviteCompanyComponent handleClose={handleModalClose} row={row} />
      </Modal>
    </div>
  );
}
