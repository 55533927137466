import React from "react";
import { Avatar, Box } from "@mui/material";
import Modal from "@mui/material/Modal";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserImage } from "components/ui/ImageComponent";
import { useState, useRef } from "react";
import { handleFileUpload } from "services/uploadFile";
import Popup from "reactjs-popup";
import { getFetch, postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { userSliceActions } from "store/userSlice";
import Loader from "components/ui/loaders/Loader";
import { blueGradient1, button1 } from "components/tailwindClasses";

function Profile(props) {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  const user = useSelector((state) => state.user.data);

  const addImage = async (link) => {
    const res = await postFetch("/updateProfileImage", { url: link });
    if (res?.status == "ok") {
      toast.success("profile image updated !");
      dispatch(userSliceActions.setProfileImage(link));
    } else {
      toast.error("some error occured, try later !");
    }
  };

  const [selectedImage, setSelectedImage] = useState(null); // this is show image
  const [selectedImageFile, setSelectedImageFile] = useState(null); // this is upload image

  const [showImagePreview, setShowImagePreview] = useState(false);
  const fileInputRef = useRef(null);

  const openImagePreview = () => {
    setShowImagePreview(true);
  };

  const closeImagePreview = () => {
    setSelectedImageFile(() => null);
    setSelectedImage(() => null);

    setShowImagePreview(false);
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const file = files[0];

    setSelectedImageFile(() => file);

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage(reader.result);
        setShowImagePreview(true);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    // Implement your upload logic here

    const res = await handleFileUpload([selectedImageFile]);

    res?.[0] && (await addImage(res?.[0]));

    closeImagePreview();
  };

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpen] = React.useState(false);
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const [enable2FaData, setEnable2FaData] = useState({});

  const [twoFaInputToken, setTwoFaInputToken] = useState("");

  const handleEnable2FaClick = async () => {
    handleModalOpen();
    setIsLoading(() => true);
    const res = await postFetch("/enable2FA");
    if (!res?.error) {
      setEnable2FaData(res);
    } else {
      toast.error("Some error occured, try later");
      handleModalClose();
    }

    setIsLoading(() => false);
  };

  const handleVerifyClick = async () => {
    const getFormEle = document.getElementById("enable2FaVerifyForm92385");
    if (getFormEle && getFormEle.checkValidity()) {
      const verifyRes = await postFetch("/enable2FA", {
        token: twoFaInputToken,
      });
      if (verifyRes?.success) {
        toast.success("2fa enabled successfully !");

        dispatch(userSliceActions.setTWoFaEnabled(true));

        handleModalClose();
      } else if (verifyRes?.error && verifyRes?.message) {
        toast.error(verifyRes?.message);
      } else {
        toast.error("some error occured, try later");
      }
    } else {
      getFormEle.reportValidity();
    }
  };

  return (
    <div style={{}}>
      <div
        style={{
          marginBottom: "1%",
          padding: "3%",
          boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        <div className="flex items-center max-w-[90%]">
          <div style={{ marginRight: "15px" }}>
            <div className="relative inline-block">
              <UserImage
                image={user?.profileImage}
                name={user?.name}
                style={{ width: "96px", height: "96px" }}
              />

              <div
                className="absolute top-0 right-0 cursor-pointer w-8 h-8 flex justify-center items-center rounded-full bg-white shadow-md"
                onClick={() => fileInputRef.current.click()}
              >
                <img src={"/editPen.svg"} alt="" className="w-4" />
              </div>
            </div>

            <Popup
              open={showImagePreview}
              closeOnDocumentClick={false}
              closeOnEscape={false}
              onClose={closeImagePreview}
              nested
            >
              <div className="popup-content fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="max-w-full max-h-full">
                  {selectedImage && (
                    <img
                      src={selectedImage}
                      alt="Image Preview"
                      className="mx-auto max-w-full max-h-[80vh]"
                    />
                  )}
                  <div className="flex justify-center mt-4">
                    <button
                      onClick={handleUpload}
                      className="px-4 py-2 mr-2 bg-green-500 text-white rounded"
                    >
                      Upload
                    </button>
                    <button
                      onClick={closeImagePreview}
                      className="px-4 py-2 bg-red-500 text-white rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Popup>

            <input
              type="file"
              accept="image/*"
              className="hidden"
              ref={fileInputRef}
              onChange={handleImageChange}
            />
          </div>
          <div className="flex justify-between grow">
            <div className="mr-5">
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                }}
                className="text-[#2E3C52] dark:text-white"
              >
                Name
              </div>
              <div style={{ fontWeight: "600", fontSize: "20px" }}>
                {user?.name}
              </div>
            </div>
            <div>
              <div
                style={{
                  fontWeight: "700",
                  fontSize: "16px",
                }}
                className="text-[#2E3C52] dark:text-white"
              >
                Email
              </div>
              <div style={{ fontWeight: "600", fontSize: "20px" }}>
                {user?.email}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          marginBottom: "2%",
          padding: "3%",
          boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ fontWeight: "600", fontSize: "18px" }}>
            Mobile Number
          </div>
          {/* <Link to={"/profile/change-mobile"}>
            <div>
              <img src={"/editPen.svg"} alt="" style={{ width: "18px" }} />
            </div>
          </Link> */}
        </div>
        <div
          className="first-step-input dark:bg-lightBlack3"
          style={{ fontWeight: "400", marginTop: ".5%" }}
        >
          {user?.phoneNumber}
        </div>
      </div>
      <div
        style={{
          marginBottom: "2%",
          padding: "3%",
          boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white flex justify-between items-center"
      >
        <div>
          <div>Enable 2FA </div>
          <div className="text-sm">
            Note : once enabled it can not be disabled
          </div>
        </div>
        {user?.twoFaEnabled ? (
          <div className="text-blue1 font-bold">ENABLED</div>
        ) : (
          <div
            className="font-bold text-lg cursor-pointer"
            onClick={handleEnable2FaClick}
          >
            ENABLE
          </div>
        )}
        <Modal
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: 24,
            }}
            className=" shadow rounded-10 bg-white dark:bg-lightBlack dark:text-white w-[400px] p-1p"
          >
            {isLoading ? (
              <Loader />
            ) : (
              <div>
                <div className="flex flex-col justify-center items-center ">
                  {enable2FaData?.qr && (
                    <>
                      <div className="text-sm mb-2p">
                        Note : This QR Code will not be shown again, please save
                        it
                      </div>
                      <div>
                        <img src={enable2FaData?.qr} alt="" />
                      </div>
                    </>
                  )}
                  {/* {enable2FaData?.secret && (
                    <div className="flex items-center">
                      <div className=" text-lg">Secret- </div>{" "}
                      <div className="italic text-center">
                        {enable2FaData?.secret}
                      </div>
                    </div>
                  )} */}
                </div>
                <div>
                  <div className="font-bold mt-2">Enter 2FA Code:</div>
                  <form id="enable2FaVerifyForm92385">
                    <input
                      type="text"
                      className="second-step-input dark:bg-lightBlack4 h-15"
                      value={twoFaInputToken}
                      required
                      onChange={(e) => {
                        setTwoFaInputToken(() => e.target.value);
                      }}
                    />
                  </form>
                  <div
                    className={
                      blueGradient1 +
                      " h-15 rounded-10 font-bold flex items-center justify-center mt-4 cursor-pointer"
                    }
                    onClick={handleVerifyClick}
                  >
                    <div>Verify and Enable</div>
                  </div>
                </div>
              </div>
            )}
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default Profile;
