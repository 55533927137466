import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addReportSliceActions } from "store/addReportSlice";
import { useEffect } from "react";

function Index(props) {
  const dispatch = useDispatch();

  const currentAssetData = useSelector(
    (state) => state?.addReport?.currentAsset
  );
  const reportType = useSelector((state) => state?.addReport?.reportType);

  const handleChange = (event) => {
    dispatch(addReportSliceActions.setReportType(event.target.value));
  };

  useEffect(() => {
    !reportType && dispatch(addReportSliceActions.setReportType("Executive"));
  }, []);

  return (
    <form id="step2-form">
      <div
        style={{
          padding: "2%",
          paddingBottom: "3%",
          borderRadius: "10px",
          marginBottom: "1%",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        <div className="avatar-text" style={{ fontWeight: "500" }}>
          Report Type
        </div>

        <div>
          <Select
            sx={{
              width: "100%",
              boxSizing: "border-box",
              height: "45px",
              border: "0px solid rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
            }}
            className="dark:text-white bg-inputBg dark:bg-inputBgDark"
            value={reportType}
            onChange={handleChange}
            //   onChange={handleChange}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            required
          >
            <MenuItem value={"Executive"}>Executive Report</MenuItem>
            {/* <MenuItem value={2}>Dev Report</MenuItem> */}
          </Select>
        </div>
      </div>
      {currentAssetData?.description && (
        <div
          style={{
            borderRadius: "10px",

            //   width: "603px",
            height: "431px",
            padding: "2%",
          }}
          className="bg-white dark:bg-lightBlack dark:text-white "
        >
          <div
            style={{
              paddingBottom: "1%",
              width: " 100%",
              fontWeight: "500",
            }}
            className="border-b-2 border-b1 dark:border-b9"
          >
            Description
          </div>
          <p className="text-opacity-50 dark:text-white">
            {currentAssetData?.description}
          </p>
        </div>
      )}
    </form>
  );
}

export default Index;
