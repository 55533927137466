import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SecondData from "./SecondData";
import { stepsSliceActions } from "store/stepsSlice";
import { backButton, blueGradient1, button1 } from "components/tailwindClasses";

function createData(assets, rating, position, review) {
  return { assets, rating, position, review };
}

const rows = [
  createData("Mobile Application", "6/10", "Analyst", " "),
  createData("Mobile Application", "6/10", "Analyst", " "),
  createData("Mobile Application ", "6/10", "Analyst", " "),
  createData("Mobile Application ", "6/10", "Analyst", " "),
];

export default function First() {
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.steps.activeStep);

  let navigate = useNavigate();

  const handleNextClick = (e) => {
    dispatch(stepsSliceActions.setActiveStep(activeStep + 1));
  };
  const handleBackClick = (e) => {
    dispatch(stepsSliceActions.setActiveStep(activeStep - 1));
  };

  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [openModal, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <div>
      <div
        // className="user-top"
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
        }}
        className="bg-white py-3p px-[5%] dark:bg-lightBlack dark:text-white w-full"
      >
        <SecondData />

        <div
          style={{
            marginTop: "2%",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <button
            className={backButton}
            style={{ width: "240px" }}
            onClick={handleBackClick}
          >
            Back
          </button>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div
              className={
                blueGradient1 +
                " w-60 h-15 rounded-10 font-bold flex items-center justify-center cursor-pointer"
              }
              onClick={handleNextClick}
            >
              <div>Next Step</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
