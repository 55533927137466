import React from "react";

import Vulnerability from "./Vulnerability";
import CVE from "./CVE";
import { useState } from "react";
import { blueGradient1 } from "components/tailwindClasses";

function Index(props) {
  let [currentTab, setCurrentTab] = useState(false);

  const handleOptionClick = () => {
    setCurrentTab(() => !currentTab);
  };

  return (
    <div className="">
      <div className="bg-greyBg4 dark:text-lightBlack1 border-b4 border rounded h-12 flex items-center ">
        <div
          onClick={handleOptionClick}
          className="cursor-pointer w-1/2 mr-4 h-full"
        >
          {!currentTab && (
            <div
              className={
                "dark:text-white font-bold flex items-center justify-center rounded h-full " +
                blueGradient1
              }
            >
              Top Vulnerabilities
            </div>
          )}
          {currentTab && (
            <div className="flex items-center justify-center h-full">
              {" "}
              Top Vulnerabilities
            </div>
          )}
        </div>
        <div
          onClick={handleOptionClick}
          className="cursor-pointer w-1/2 h-full"
        >
          {currentTab && (
            <div
              className={
                "dark:text-white font-bold flex items-center justify-center rounded h-full" +
                blueGradient1
              }
            >
              Most Common CVE
            </div>
          )}
          {!currentTab && (
            <div className="flex items-center justify-center h-full">
              {" "}
              Most Common CVE
            </div>
          )}
        </div>
      </div>
      <div className=" border-b4 dark:border-b8 border rounded">
        {!currentTab && <Vulnerability vulnerabilitiesLoading={props.vulnerabilitiesLoading}/>}
        {currentTab && <CVE />}
      </div>
    </div>
  );
}

export default Index;
