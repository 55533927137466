import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeOption: 1,
  screenshot: "",
  data: {
    asset_name: "",
    asset_type: "",
    company_name: "",
    created_date: "",
    company_profile_image: "",
    asset_description: "",
    website_url: null,
    bugCount: null,
    vulnCount: null,
    reportCount: null,
  },
};

let targetInfoSlice = createSlice({
  name: "targetInfoSlice",
  initialState: initialState,
  reducers: {
    setactiveOption(state, action) {
      state.activeOption = action.payload;
    },
    setScreenshotUrl(state, action) {
      state.screenshot = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setBugCount(state, action) {
      state.data.bugCount = action.payload;
    },
    setVulnCount(state, action) {
      state.data.vulnCount = action.payload;
    },
    setReportCount(state, action) {
      state.data.reportCount = action.payload;
    },
  },
});

export const targetInfoSliceActions = targetInfoSlice.actions;

export default targetInfoSlice;
