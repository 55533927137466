import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

export function Search({ search = null, setSearch = () => {}, placeholder }) {
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "1% 1%",
      }}
      className="dark:bg-lightBlack bg-white flex-grow h-15 rounded-10 "
    >
      <div className="h-10  mr-2 flex-1 text-base rounded bg-white  dark:bg-lightBlack3 flex items-center relative">
        <input
          type="text"
          className=" dark:text-white flex-1 p-2 bg-inputBg dark:bg-inputBgDark "
          placeholder={placeholder || "Search Target Name"}
          value={search}
          onChange={(e) => {
            setSearch(() => e.target.value);
          }}
        />
        <img
          src={isDarkTheme ? "/searchIconDark.svg" : "/searchIcon.svg"}
          alt=""
          className="mx-2"
        />
      </div>
    </div>
  );
}
