import React from "react";
import ChangeMobile from "./components/ChangeMobile/Index";
import Profile from "./components/Profile";
import Notifications from "./components/notifications/Index";
import { NotificationFormat } from "./components/notifications/notificationTypes";

export const ChangeMobileComponent = ChangeMobile;
export const ProfileManagerComponent = Profile;
export const NotificationsComponent = Notifications;
export const NotificationFormatComponent = NotificationFormat;

function Index(props) {
  return <div></div>;
}

export default Index;
