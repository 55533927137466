import React, { useState, useEffect } from "react";

import RequestCard from "../Requests/RequestCard";
import UserNode from "../TeamMembers/UserNode";

import { getFetch } from "lib/fetch";
import Loader from "components/ui/loaders/Loader";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { teamSliceActions } from "store/teamSlice";
import TeamMemberSkeleton from "components/ui/loaders/TeamMemberSkeleton";

function Index(props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const disabledMembers = useSelector((state) => state?.team?.disabledMembers);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(() => true);

      const res = await getFetch("/manager/disabledMembers");
      if (res?.status == "ok") {
        dispatch(
          teamSliceActions.setDisabledMembers({
            rejected: res?.data?.rejected,
            deleted: res?.data?.deleted,
          })
        );
      } else if (res?.error && res?.message) {
        toast.error(res?.message);
      } else {
        toast.error("error loading disabled members, try later");
      }
      setIsLoading(() => false);
    };
    getData();
  }, []);

  return isLoading &&
    disabledMembers?.rejected?.length == 0 &&
    disabledMembers?.deleted?.length == 0 ? (
    <TeamMemberSkeleton />
  ) : (
    <div>
      {disabledMembers?.rejected?.map((item) => (
        <RequestCard rejected data={item} />
      ))}
      {disabledMembers?.deleted?.map((item) => (
        <UserNode deleted data={item} />
      ))}
    </div>
  );
}

export default Index;
