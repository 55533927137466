import React from "react";
import ChartBox from "./Chartbox/Index";
import { useDispatch, useSelector } from "react-redux";

import EngineerCharts from "./EngineerCharts/Index";
import Charts from "./Charts/Index";

export default function Index() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.data);

  return (
    <>
      <div className="p-1p">
        <ChartBox />
        {user.role == "S_ENGINEER" && <EngineerCharts />}
        <Charts />
      </div>
    </>
  );
}
