import React from "react";
import Table from "./EngineTable/Table";

import { Search } from "components/ui/inputs/Search";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { blueGradient1 } from "components/tailwindClasses";
import { Modal } from "@mui/material";
import AddEngine from "./AddEngine/Index";

const inactiveButtonStyle =
  "flex justify-center items-center mr-[2vw] h-15 w-60 cursor-pointer ";
const activeButtonStyle =
  "font-bold text-white bg-blue1 rounded-10 dark:border-2 dark:bg-lightBlack3 dark:border-white " +
  inactiveButtonStyle;

function Engine(props) {
  let user = useSelector((state) => state.user.data);
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";

  let [activeOption, setActiveOption] = useState(0);

  // for search
  const [search, setSearch] = useState("");

  let navigate = useNavigate();

  const handleClose = () => {
    setOpen(() => false);
  };

  const [open, setOpen] = useState(false);
  return (
    <div style={{ padding: "1%" }}>
      <div className="flex justify-between items-center mb-1p dark:text-white">
        <Search
          search={search}
          setSearch={setSearch}
          placeholder={"Search Engine Name"}
        />
        <div style={{ display: "flex" }}>
          <button
            className={
              blueGradient1 +
              " flex items-center justify-center cursor-pointer w-60 rounded-10 h-15 font-bold ml-2"
            }
            onClick={() => {
              setOpen(() => true);
            }}
          >
            Add New Engine
          </button>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddEngine handleClose={handleClose} />
      </Modal>

      <div className="rounded overflow-hidden bg-white dark:bg-lightBlack3 dark:text-white">
        <div
          style={
            activeOption == 0
              ? {
                  display: "flex",
                  flexGrow: "1",
                  // justifyContent: "space-between",
                  alignItems: "center",

                  fontSize: "16px",
                  fontWeight: "400",
                  height: "60px",
                  borderRadius: "10px",

                  cursor: "default",
                }
              : {
                  display: "flex",
                  flexGrow: "1",
                  // justifyContent: "space-between",
                  alignItems: "center",

                  fontSize: "16px",
                  fontWeight: "400",
                  height: "60px",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                  cursor: "default",
                }
          }
          className="bg-white dark:bg-lightBlack dark:text-white "
        >
          <div
            className={
              activeOption == 0
                ? inactiveButtonStyle +
                  " " +
                  blueGradient1 +
                  " rounded-10 font-bold"
                : inactiveButtonStyle
            }
            onClick={() => {
              setActiveOption(() => 0);
            }}
          >
            All
          </div>
          <div
            className={
              activeOption == 1
                ? inactiveButtonStyle +
                  " " +
                  blueGradient1 +
                  " rounded-10 font-bold"
                : inactiveButtonStyle
            }
            onClick={() => {
              setActiveOption(() => 1);
            }}
          >
            Bin
          </div>
        </div>
        <Table search={search} bin={activeOption == 1} />
      </div>
    </div>
  );
}

export default Engine;
