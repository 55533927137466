import {
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormGroup,
} from "@mui/material";
import SecondaryButton from "components/ui/buttons/SecondaryButton";
import { postFetch } from "lib/fetch";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";
import SimilarityCircle from "components/ui/buttons/SimilarityCircle";
import { blueGradient1 } from "components/tailwindClasses";

function Index({
  firstOptionData,
  secondOptionData,
  percentage,
  markDuplicate,
  children,
}) {
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  const selectedBugs = useSelector(
    (state) => state.targetDashboard.selectedBugs
  );

  const navigate = useNavigate();

  let handleBackClick = (e) => {
    navigate(-1);
  };

  // when only two items left, for radio
  const [currentOption, setCurrentOption] = useState("None");

  // when two options left
  const [firstRadio, setFirstRadio] = useState("");
  const [secondRadio, setSecondRadio] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = (e) => {
    setModalOpen(() => false);
  };

  const handleTakeActionClick = (e) => {
    setModalOpen(() => true);
  };

  const dispatch = useDispatch();
  const handleTakeActionSubmit = async (e) => {
    e.preventDefault();

    if (selectedBugs.length == 2) {
      let completed = true;
      if (currentOption == "None") {
        if (firstRadio && secondRadio) {
          try {
            const res1 = await postFetch(`/bug/changeStatus`, {
              bug_id: firstOptionData._id,
              newStatus: firstRadio,
            });
            if (res1.status == "ok") {
              toast.success(
                `New Status for ${firstOptionData.name} : ${firstRadio}`
              );
            } else if (res1?.error && res1?.message) {
              toast.error(`${firstOptionData.name} : ${res1.message}`);
            } else {
              toast.error(
                `Error occured for ${firstOptionData.name}, try later !`
              );
              completed = false;
            }

            const res2 = await postFetch(`/bug/changeStatus`, {
              bug_id: secondOptionData._id,
              newStatus: secondRadio,
            });
            if (res2.status == "ok") {
              toast.success(
                `New Status for ${firstOptionData.name} : ${secondRadio}`
              );
            } else if (res2?.error && res2?.message) {
              toast.error(`${secondOptionData.name} : ${res2.message}`);
            } else {
              toast.error(
                `Error occured for ${secondOptionData.name}, try later !`
              );

              completed = false;
            }

            handleModalClose();
          } catch (e) {
            toast.error("Some error occured, try later !");
            completed = false;
          }
        }
      } else if (currentOption == firstOptionData._id) {
        try {
          const res1 = await postFetch(`/bug/changeStatus`, {
            bug_id: firstOptionData._id,
            newStatus: "Duplicate",
          });
          if (res1.status == "ok") {
            toast.success(`New Status for ${firstOptionData.name} : Duplicate`);
          } else if (res1?.error && res1?.message) {
            toast.error(`${firstOptionData.name} : ${res1.message}`);
          } else {
            toast.error(
              `Error occured for ${firstOptionData.name}, try later !`
            );
            completed = false;
          }

          if (secondRadio) {
            const res2 = await postFetch(`/bug/changeStatus`, {
              bug_id: secondOptionData._id,
              newStatus: secondRadio,
            });
            if (res2.status == "ok") {
              toast.success(
                `New Status for ${secondOptionData.name} : ${secondRadio}`
              );
            } else if (res2?.error && res2?.message) {
              toast.error(`${secondOptionData.name} : ${res2.message}`);
            } else {
              toast.error(
                `Error occured for ${secondOptionData.name}, try later !`
              );
              completed = false;
            }
          }

          handleModalClose();
        } catch (e) {
          toast.error("Some error occured, try later !");
          completed = false;
        }
      } else if (currentOption == secondOptionData._id) {
        try {
          const res1 = await postFetch(`/bug/changeStatus`, {
            bug_id: secondOptionData._id,
            newStatus: "Duplicate",
          });
          if (res1.status == "ok") {
            toast.success(
              `New Status for ${secondOptionData.name} : Duplicate`
            );
          } else if (res1?.error && res1?.message) {
            toast.error(`${secondOptionData.name} : ${res1.message}`);
          } else {
            toast.error(
              `Error occured for ${secondOptionData.name}, try later !`
            );
            completed = false;
          }

          if (firstRadio) {
            const res2 = await postFetch(`/bug/changeStatus`, {
              bug_id: firstOptionData._id,
              newStatus: firstRadio,
            });
            if (res2.status == "ok") {
              toast.success(
                `New Status for ${firstOptionData.name} : ${firstRadio}`
              );
            } else if (res2?.error && res2?.message) {
              toast.error(`${firstOptionData.name} : ${res2.message}`);
            } else {
              toast.error(
                `Error occured for ${firstOptionData.name}, try later !`
              );
              completed = false;
            }
          }

          handleModalClose();
        } catch (e) {
          toast.error("Some error occured, try later !");
          completed = false;
        }
      }
      if (completed) {
        dispatch(targetDashboardSliceActions.setSelectedBugs([]));
        navigate(-1);
      }
    } else {
      markDuplicate(currentOption);

      // this will open up status change at bottom of screen
      dispatch(targetDashboardSliceActions.setStatusChangeAllowed(true));
      handleModalClose();
    }
  };

  return (
    <>
      <div className="bg-white dark:bg-lightBlack dark:text-white p-1p mb-1p flex items-center justify-between rounded relative">
        <button className="flex items-center" onClick={handleBackClick}>
          <img
            src={isDarkTheme ? "/leftArrowDark.svg" : "/leftArrow.svg"}
            alt=""
            className="inline mr-4 "
          />
          <span className="text-lg font-medium">Compare Reports</span>
        </button>

        {percentage && (
          <div className="absolute left-1/2 -translate-x-1/2">
            <SimilarityCircle percentage={percentage} />
          </div>
        )}

        <div className="w-60">
          <button
            onClick={handleTakeActionClick}
            className={
              "w-60 mr-2p cursor-pointer h-15 flex items-center justify-center font-bold rounded-10 " +
              blueGradient1
            }
          >
            Take action
          </button>
        </div>
      </div>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title2"
        aria-describedby="modal-modal-description2"
      >
        <div className="w-[800px] absolute bg-white dark:bg-lightBlack dark:text-white left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 p-2p pb-1p rounded-10">
          <form onSubmit={handleTakeActionSubmit}>
            <div className="text-2xl font-bold pb-2p border-b-2 border-b1 dark:border-b9  text-center ">
              Which bug report is duplicate?
            </div>
            <div className="border-b-2 dark:border-b9">
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="None"
                name="radio-buttons-group"
                value={currentOption}
                onChange={(e) => {
                  setCurrentOption(() => e.target.value);
                }}
              >
                <div className="py-1p border-b-2 dark:border-b9">
                  <FormControlLabel
                    value={firstOptionData?._id}
                    control={<Radio />}
                    label={
                      <div className=" w-[650px]">
                        <span
                          style={{
                            color: "white",
                            background: "#539486",
                            borderRadius: "5px",
                            padding: "6px 12px",
                            marginRight: "1%",
                          }}
                        >
                          #{firstOptionData?._id}
                        </span>
                        <span>{firstOptionData?.name}</span>
                      </div>
                    }
                  />
                  {selectedBugs.length == 2 &&
                    currentOption != firstOptionData?._id && (
                      <div className="px-[4%] bg-greyBg2 dark:bg-lightBlack3 py-2p">
                        <div className="font-bold text-lg ">Choose One</div>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="flex w-full justify-between"
                          aria-required
                          value={firstRadio}
                          onChange={(e) => {
                            setFirstRadio(() => e.target.value);
                          }}
                        >
                          {["Valid", "Resubmit", "Deleted"].map((item) => (
                            <FormControlLabel
                              value={item}
                              control={<Radio />}
                              label={item}
                            />
                          ))}
                        </RadioGroup>
                      </div>
                    )}
                </div>

                <div className="py-1p border-b-2 dark:border-b9">
                  <FormControlLabel
                    value={secondOptionData?._id}
                    control={<Radio />}
                    label={
                      <div className="w-[650px]">
                        <span
                          style={{
                            color: "white",
                            background: "#539486",
                            borderRadius: "5px",
                            padding: "6px 12px",
                            marginRight: "1%",
                          }}
                        >
                          #{secondOptionData?._id}
                        </span>
                        <span className="">{secondOptionData?.name}</span>
                      </div>
                    }
                  />
                  {selectedBugs.length == 2 &&
                    currentOption != secondOptionData?._id && (
                      <div className="px-[4%] bg-greyBg2 dark:bg-lightBlack3 py-2p">
                        <div className="font-bold text-lg ">Choose One</div>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="flex w-full justify-between"
                          aria-required
                          value={secondRadio}
                          onChange={(e) => {
                            setSecondRadio(() => e.target.value);
                          }}
                        >
                          {["Valid", "Resubmit", "Deleted"].map((item) => (
                            <FormControlLabel
                              value={item}
                              control={<Radio />}
                              label={item}
                            />
                          ))}
                        </RadioGroup>
                      </div>
                    )}
                </div>

                <FormControlLabel
                  value="None"
                  control={<Radio />}
                  label="None"
                  className="py-1p  "
                />
              </RadioGroup>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: "2%",
              }}
            >
              <button
                style={{
                  // marginTop: "3.2%",
                  textDecoration: "none",
                  color: "#2D3B51 !important",
                  marginRight: "50px",
                  fontWeight: "500",
                }}
                onClick={() => {
                  // setState(() => "");
                  setModalOpen(() => false);
                }}
              >
                Cancel
              </button>
              <button
                className={
                  "w-60   cursor-pointer h-15 flex items-center justify-center font-bold rounded-10 " +
                  blueGradient1
                }
                type="submit"
              >
                Done
              </button>
            </div>
          </form>
        </div>
      </Modal>
      {children}
    </>
  );
}

export default Index;
