import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";

export default function TargetInfoLoadingSkeleton({}) {
  return (
    <div className="bg-white dark:bg-lightBlack dark:text-white  px-1p flex justify-between items-center rounded-10 relative">
      <div className=" flex">
        <div className=" flex mr-4 h-fit">
          <Skeleton variant="circular" width={60} height={60} />
        </div>

        <div className="flex flex-col ">
          <div className="inline font-bold text-xl">
            <Skeleton width={100} height={20} />
          </div>
          <div>
            <Skeleton width={200} height={20} />
          </div>

          <Skeleton width={100} height={30} />
        </div>
      </div>
      <div className="flex  items-end h-max">
        <Skeleton width={150} height={20} className="mr-2" />

        <Skeleton variant="rect" width={128} height={128} />
      </div>
    </div>
  );
}
