import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
// import ButtonGroup from "@mui/material/ButtonGroup";
// import DotButton from "../../DotButton";
import { Box, Typography } from "@mui/material";
import SeverityButton from "components/ui/buttons/SeverityButton";
import { useDispatch, useSelector } from "react-redux";
import dashboardInfoSlice from "store/dashboardInfoSlice";
import TableLoadingSkeleton from "components/ui/loaders/TableSkeleton";
import { useEffect } from "react";
import { pythonGetFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { dashboardSliceActions } from "store/scanner/dashboardSlice";

export default function Vulnerability() {
  const dispatch = useDispatch();
  const topVulnerabilities = useSelector(
    (state) => state.scanner.dashboard.topVulnerabilities
  );

  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(() => true);
      const res = await pythonGetFetch("/dashboard/vuln_count");
      if (res?.status == "ok") {
        dispatch(dashboardSliceActions.setTopVulnerabilities(res?.data));
      } else if (res?.error && res?.message) {
        toast.error(res?.message);
      }
      setIsLoading(() => false);
    };

    getData();
  }, []);

  const rows = topVulnerabilities?.map((vuln) => {
    return {
      severity: SeverityButton(vuln?.[0]),
      name: vuln?.[1],
      box: (
        <div className="w-6 h-6 text-center bg-greyBg4 border-b4 border rounded">
          {vuln?.[2]}
        </div>
      ),
    };
  });

  return (
    <TableContainer
      sx={{ height: "465px", overflowY: "scroll" }}
      className="dark:bg-lightBlack "
    >
      <Table>
        {/* <TableHead></TableHead> */}
        {isLoading && rows?.length == 0 ? (
          <TableLoadingSkeleton
            columnCount={3}
            rowCount={10}
            tableRowClassName={"dark:bg-lightBlack "}
            tableCellClassName={" dark:text-white dark:border-b dark:border-b9"}
          />
        ) : (
          <TableBody>
            {rows?.map((row, i) => (
              <TableRow key={i} className="dark:bg-lightBlack ">
                <TableCell
                  component="th"
                  scope="row"
                  className="dark:border-b dark:border-b9"
                >
                  {row?.severity}
                </TableCell>
                <TableCell
                  align="left"
                  className=" dark:text-white dark:border-b dark:border-b9"
                >
                  {row?.name}
                </TableCell>
                <TableCell
                  align="left"
                  className=" dark:text-white dark:border-b dark:border-b9"
                >
                  {row?.box}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
