import React, { useState } from "react";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";

import { MarkdownText } from "components/imports/MarkdownEditor/CustomMdEdtor";
import { timeSince } from "Util/TimeSince";
import { UserImage } from "components/ui/ImageComponent";
import { useSelector } from "react-redux";
import clipboardCopy from "clipboard-copy";
import Popup from "reactjs-popup";
import { blueGradient1 } from "components/tailwindClasses";

function SingleReport({ data }) {
  let [open, setOpen] = useState(true);
  const isDarkMode = useSelector((state) => state.general.theme) == "dark";

  // for image
  const [copied, setCopied] = useState({});

  const handleCopyToClipboard = async (imageUrl, index) => {
    try {
      await clipboardCopy(imageUrl);

      setCopied(() => ({ [index]: true }));

      setTimeout(() => {
        setCopied(() => ({ [index]: false }));
      }, 1000);
    } catch (error) {
      console.error("Failed to copy:", error);
    }
  };

  return (
    <div className="dark:border-b9 border-b border-opacity-20">
      <div
        style={{
          padding: "1% 0",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="cursor-pointer"
          onClick={() => {
            setOpen((open) => !open);
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <UserImage
              name={data?.researcherName}
              image={data?.researcherProfileImage}
              company={data?.researcherCompany}
              email={data?.researcherEmail}
              style={{
                width: "50px",
                height: "50px",
                marginRight: "10px",
              }}
            />
            <div>
              <span style={{ fontWeight: "700" }}>{data?.researcherName} </span>
              {data?.researcherCompany && (
                <span
                  style={{
                    borderRadius: "43px",
                    padding: "6px 10px",
                    color: "white",
                  }}
                  className={"mx-1 " + blueGradient1}
                >
                  {data?.researcherCompany}
                </span>
              )}
              <span> submitted report to </span>
              {data?.company && (
                <span
                  style={{
                    borderRadius: "43px",
                    padding: "6px 10px",
                    color: "white",
                  }}
                  className={blueGradient1}
                >
                  {data?.company}
                </span>
              )}
            </div>
          </div>
          <div style={{ position: "relative", width: "20%" }} className="">
            <div
              style={{
                textAlign: "right",

                fontSize: "20px",
                fontWeight: "700",
                paddingRight: "2%",
              }}
              className="flex justify-end"
            >
              {open == true && (
                <img
                  src={isDarkMode ? "/upArrowDark.svg" : "/upArrow.svg"}
                  alt=""
                />
              )}
              {open == false && (
                <img
                  src={isDarkMode ? "/downArrowDark.svg" : "/downArrow.svg"}
                  alt=""
                />
              )}
            </div>
            <div
              style={{
                position: "absolute",
                right: "0",
                top: "20px",
                fontSize: "12px",
              }}
              className="opacity-50 dark:text-white"
            >
              {new Date(data.created_date).toDateString()} (
              {timeSince(new Date(data.created_date))} ago)
            </div>
          </div>
        </div>
      </div>

      {open == true && (
        <div
          style={{
            marginLeft: "60px",
            // fontSize: "14px",
            padding: "1% 0 4%",
          }}
        >
          {data?.name && (
            <div className="mt-2">
              <div className="font-bold text-xl  underline underline-offset-[6px]">
                Title
              </div>
              <div className="mt-1p ">{data?.name}</div>
            </div>
          )}
          {data.description && (
            <div className="mt-4">
              <div className="font-bold text-xl underline underline-offset-[6px]">
                Description
              </div>

              <div className=" mt-1p">
                {" "}
                <MarkdownText data={data.description} />
              </div>
            </div>
          )}
          {data.impact && (
            <div className="mt-4">
              <div className="font-bold text-xl  underline underline-offset-[6px]">
                Impact
              </div>

              <div className=" mt-1p">
                {" "}
                <MarkdownText data={data.impact} />
              </div>
            </div>
          )}
          {data.remediation && (
            <div className="mt-4 ">
              <div className="font-bold text-xl  underline underline-offset-[6px]">
                Remediation
              </div>
              <div className=" mt-1p">
                <MarkdownText data={data.remediation} />
              </div>
            </div>
          )}
          {data.references && (
            <div className="mt-4">
              <div className="font-bold text-xl  underline underline-offset-[6px]">
                References
              </div>

              <div className=" mt-1p">{data.references}</div>
            </div>
          )}

          {data?.files_attached && data?.files_attached?.length > 0 && (
            <div className="mt-4">
              <div className="font-bold text-xl  underline underline-offset-[6px]">
                Files Attached
              </div>

              <div className="mb-1">
                {data?.files_attached?.map((imageUrl, index) => {
                  return (
                    <div key={index} className="relative min-h-[50px] mt-2p">
                      <div className="relative flex justify-between">
                        {/* <ImageModal
                      small={imageUrl}
                      large={imageUrl}
                      className="max-w-[90%] h-auto rounded"
                    /> */}
                        <img
                          src={imageUrl}
                          alt="img"
                          className="max-w-[90%] h-auto rounded"
                        />
                      </div>
                      <div className="flex items-center justify-between mt-2">
                        <div className="text-gray-600 break-words flex-wrap w-[90%] dark:text-white italic">
                          {imageUrl}
                        </div>
                        <div className="relative ">
                          <div
                            onClick={() => {
                              handleCopyToClipboard(imageUrl, index);
                            }}
                            className="cursor-pointer"
                          >
                            <img src="/copy.svg" alt="" className="w-5  " />
                          </div>
                          <Popup
                            trigger={<div />}
                            position="bottom center"
                            contentStyle={{
                              padding: "8px",
                              background: "#000",
                              color: "#fff",
                              borderRadius: "4px",
                              fontSize: "14px",
                            }}
                            closeOnDocumentClick
                            open={copied?.[index]}
                          >
                            Copied!
                          </Popup>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {data?.files_attached?.length > 0 && (
                  <div className="text-xs mt-2p">
                    You can copy this url and use it in your comment (ctrl + k),
                    or using add image icon{" "}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SingleReport;
