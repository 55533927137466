import React, { useState } from "react";

import Modal from "@mui/material/Modal";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activePageActions } from "../../../store/activePageSlice";
import { getFetch } from "lib/fetch";

import InviteCompany from "./InviteCompany";

import Table from "./Table";
import { toast } from "react-toastify";
import { blueGradient1 } from "components/tailwindClasses";
import { inviteSliceActions } from "store/inviteSlice";
import { Search } from "components/ui/inputs/Search";

const inactiveButtonStyle =
  "flex justify-center items-center mr-[2vw] h-15 w-60";
const activeButtonStyle =
  "font-bold text-white bg-blue1 rounded-10 dark:border-2 dark:bg-lightBlack3 dark:border-white " +
  inactiveButtonStyle;

export default function Index() {
  let user = useSelector((state) => state.user.data);

  // active page
  let dispatch = useDispatch();

  // team member or requests
  let [activeOption, setActiveOption] = useState(0);

  const [openModal, setOpen] = React.useState(false);
  const handleModalOpen = () => {
    setOpen(true);
  };
  const handleModalClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const invites = useSelector((state) => state.invite.invites);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(() => true);

      const res = await getFetch("/companyInvite/listInvites");
      if (res?.status == "ok") {
        dispatch(
          inviteSliceActions.setInvites(
            res?.data?.map((i) => {
              return {
                inviteId: i?.invite_id,
                targetName: i?.asset_title,
                description: i?.description,
                category: i?.type,
                tag: i?.tag,
                status: i?.status,
                date: i?.created_date,
                isAccepted: i?.status == "accepted",
                accessAllowed: i?.access_allowed,
              };
            })
          )
        );
      } else if (res?.error && res?.message) {
        toast.error(res?.message);
        dispatch(inviteSliceActions.setInvites([]));
      } else {
        toast.error("some error occured, try later");
        dispatch(inviteSliceActions.setInvites([]));
      }

      setIsLoading(() => false);
    };
    getData();
  }, []);

  const [search, setSearch] = useState("");

  return (
    <div className="p-1p">
      <div className="mb-1p">
        <Search search={search} setSearch={setSearch} />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={
            activeOption == 0
              ? {
                  display: "flex",
                  flexGrow: "1",
                  // justifyContent: "space-between",
                  alignItems: "center",

                  fontSize: "16px",
                  fontWeight: "400",
                  height: "60px",
                  borderRadius: "10px",

                  cursor: "default",
                }
              : {
                  display: "flex",
                  flexGrow: "1",
                  // justifyContent: "space-between",
                  alignItems: "center",

                  fontSize: "16px",
                  fontWeight: "400",
                  height: "60px",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                  cursor: "default",
                }
          }
          className="bg-white dark:bg-lightBlack dark:text-white"
        >
          <div
            // className={
            //   activeOption == 0 ? activeButtonStyle : inactiveButtonStyle
            // }
            className={
              activeOption == 0
                ? inactiveButtonStyle +
                  " " +
                  blueGradient1 +
                  " rounded-10 font-bold"
                : inactiveButtonStyle
            }
            onClick={() => {
              setActiveOption(() => 0);
            }}
          >
            All
          </div>
          <div
            // className={
            //   activeOption == 1 ? activeButtonStyle : inactiveButtonStyle
            // }
            className={
              activeOption == 1
                ? inactiveButtonStyle +
                  " " +
                  blueGradient1 +
                  " rounded-10 font-bold"
                : inactiveButtonStyle
            }
            onClick={() => {
              setActiveOption(() => 1);
            }}
          >
            Accepted
          </div>

          <div
            // className={
            //   activeOption == 2 ? activeButtonStyle : inactiveButtonStyle
            // }
            className={
              activeOption == 2
                ? inactiveButtonStyle +
                  " " +
                  blueGradient1 +
                  " rounded-10 font-bold"
                : inactiveButtonStyle
            }
            onClick={() => {
              setActiveOption(() => 2);
            }}
          >
            Pending
          </div>
          {user?.role == "MANAGER" && (
            <div
              // className={
              //   activeOption == 3 ? activeButtonStyle : inactiveButtonStyle
              // }
              className={
                activeOption == 3
                  ? inactiveButtonStyle +
                    " " +
                    blueGradient1 +
                    " rounded-10 font-bold"
                  : inactiveButtonStyle
              }
              onClick={() => {
                setActiveOption(() => 3);
              }}
            >
              Bin
            </div>
          )}
        </div>
        {user?.role == "MANAGER" && activeOption == 0 && (
          <button
            onClick={handleModalOpen}
            className={
              " ml-1p h-15 w-60 text-white rounded-10 font-bold " +
              blueGradient1
            }
          >
            New Invite
          </button>
        )}
      </div>
      <div>
        <Modal
          open={openModal}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <InviteCompany handleClose={handleModalClose} />
        </Modal>
      </div>
      <div>
        <Table
          option={activeOption}
          invites={invites}
          isLoading={isLoading}
          search={search}
        />
      </div>
    </div>
  );
}
