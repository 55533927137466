import React from "react";
import { EmailFormat as EmailFormatCE } from "emailTypes/CE";
import { EmailFormat as EmailFormatCM } from "emailTypes/CM";
import { EmailFormat as EmailFormatSE } from "emailTypes/SE";
import { EmailFormat as EmailFormatSM } from "emailTypes/SM";

function Email({ type }) {
  return (
    <div className="border border-black absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[800px]  bg-white">
      <div
        style={{
          background:
            "linear-gradient(184.79deg, #497C79 3.87%, #1C2D48 122.28%)",
          height: "70px",
          position: "relative",
        }}
      >
        <img
          src="/emailCyteHackLogo.svg"
          alt=""
          className="absolute left-1/2 -translate-x-1/2 translate-y-[10px]  "
        />
        <img
          src="/emailCyteHackName.svg"
          alt=""
          className="absolute left-1/2 -translate-x-1/2 -bottom-1/2 translate-y-[100%] h-5"
        />
        <div className="italic font-bold text-white text-[13px] absolute right-0 bottom-0 mr-2p mb-1p">
          Your Privacy, Our Priority
        </div>
      </div>

      <div className="mt-[70px] ">
        {EmailFormatCM(11, {
          commentorImage: "/avatar.svg",
          commentorName: "name",
          companyName: "name",
          vulnId: "name",
          vulnName: "name",
          assetName: "name",
          oldState: "new",
          newState: "triaged",

          comment: "this is comment",
        })}

        <div className="flex justify-center">
          <a href="">
            {" "}
            <img src="/emailFacebookLogo.svg" alt="" className="mx-2" />
          </a>
          <a href="">
            {" "}
            <img src="/emailInstagramLogo.svg" alt="" className="mx-2" />
          </a>
          <a href="">
            {" "}
            <img src="/emailLinkedinLogo.svg" alt="" className="mx-2" />
          </a>
          <a href="">
            {" "}
            <img src="/emailTwitterLogo.svg" alt="" className="mx-2" />
          </a>
        </div>

        <div className="mt-6 w-[560px]  mx-auto text-xs mb-[5%]">
          <p className="text-center opacity-50">
            You have received this mail because your e-mail ID is registered
            with Cyethack Solution. This is a system-generated e-mail, please
            don't reply to this message.
          </p>
          <p className="text-center">
            <span className="opacity-50">
              Please do not sell or share with other parties. We recommend that
              you visit our{" "}
            </span>
            <a href="" className="no-underline opacity-100">
              Terms & Conditions
            </a>{" "}
            <span className="opacity-50">and the </span>
            <a href="" className="no-underline opacity-100">
              Security Advice
            </a>{" "}
            <span className="opacity-50">for more information.</span>
          </p>
        </div>
      </div>
    </div>
  );
}
export default Email;
