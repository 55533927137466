import React, { useState } from "react";
import { useSelector } from "react-redux";

let optionStyle = {
  display: "flex",
  justifyContent: "center",
  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  padding: "1% 2%",
  width: "50%",
  cursor: "pointer",
};
let optionStyleSelect = {
  ...optionStyle,
  fontWeight: "700",
  color: "#4B65ED",

  borderBottom: " 4px solid #4B65ED",
};
function Index(props) {
  const dataAll = useSelector(
    (state) => state?.targetDashboard?.home?.domainInfo
  );

  const data = dataAll?.whois_data;

  let [activeOption, setActiveOption] = useState(0);

  let options = ["Registrar","Registrant","Admin","Tech"];

  let dataToShow;
  if (activeOption == 0) {
    dataToShow = data['Registrar'];
  } else if (activeOption == 1) {
    dataToShow = data['Registrant']
  } else if (activeOption == 2) {
    dataToShow = data['Admin']
  } else if (activeOption == 3) {
    dataToShow = data['Tech']
  } 


 
  return (
    <div style={{ height: "380px", overflowY: "scroll" }}>
      <div style={{ display: "flex" }}>
        {options.map((item,i) => {
          return (
            <div
              onClick={() => {
                setActiveOption(() => i);
              }}
              style={activeOption == i ? optionStyleSelect : optionStyle}
            >
              {item}
            </div>
          );
        })}
      </div>   
    {Object.entries(dataToShow).map(([key,value],index)=>{

      return <div  className={` ${(index%2)===0 ? "bg-[#F4F5F7]" : ""} dark:bg-lightBlack6 min-h-[60px] flex items-center px-2p`}>
      <div className="w-1/2 dark:text-white font-medium px-1p">{key}</div>
      <div className="w-1/2  py-1p px-1p">
        {value}
      </div>
    </div> 
    })}
      {/* <div className="bg-[#F4F5F7] dark:bg-lightBlack6 min-h-[60px] flex items-center px-2p">
        <div className="w-1/2 dark:text-white font-bold px-1p">Name</div>
        <div className="w-1/2 font-semibold py-1p px-1p">
          {activeOption == 0 ? dataToShow?.RTechName : dataToShow?.OrgTechName}
        </div>
      </div> */}
      {/* <div className=" min-h-[60px] flex items-center px-2p ">
        <div className="w-1/2 dark:text-white font-bold px-1p">
          Organisation
        </div>
        <div className="w-1/2 font-semibold py-1p px-1p">
          {" "}
          {activeOption == 0 ? dataToShow?.registrar : dataToShow?.Organization}
        </div>
      </div>
      <div className="bg-[#F4F5F7] dark:bg-lightBlack6 min-h-[60px] flex items-center px-2p">
        <div className="w-1/2 dark:text-white font-bold px-1p">Admin ID</div>
        <div className="w-1/2 font-semibold py-1p px-1p">
          {" "}
          {activeOption == 0 ? "" : dataToShow?.OrgId}
        </div>
      </div>
      <div className=" min-h-[60px] flex items-center px-2p ">
        <div className="w-1/2 dark:text-white font-bold px-1p">Email</div>
        <div className="w-1/2 font-semibold py-1p px-1p">
          {" "}
          {activeOption == 0 ? dataToShow?.emails : dataToShow?.OrgTechEmail}
        </div>
      </div>
      <div className="bg-[#F4F5F7] dark:bg-lightBlack6 min-h-[60px] flex items-center px-2p">
        <div className="w-1/2 dark:text-white font-bold px-1p">Phone/Fax</div>
        <div className="w-1/2 font-semibold py-1p px-1p">
          {" "}
          {activeOption == 0
            ? dataToShow?.RTechPhone
            : dataToShow?.OrgTechPhone}
        </div>
      </div> */}
    </div>
  );
}

export default Index;
