import * as React from "react";
import { useState } from "react";
import DotButton from "./TargetTable/All/DotButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import FiberManualRecordRounded from "@mui/icons-material/FiberManualRecordRounded";
import { useDispatch, useSelector } from "react-redux";
import { activePageActions } from "../../../store/activePageSlice";
import { useNavigate } from "react-router-dom";
import SeverityGroupButton from "components/ui/buttons/SeverityGroupButton";
import BackToCompanyList from "Layouts/BackToCompanyList";
import TargetTable from "./TargetTable/All/TargetTable";
import Performace from "components/ui/buttons/Performance";

import { Link } from "react-router-dom";
import FilterDropdown from "./ui/FilterDropdown";
import { blueGradient1 } from "components/tailwindClasses";
import BinTargetTable from "./TargetTable/Bin/TargetTable";
import { Search } from "components/ui/inputs/Search";

const inactiveButtonStyle =
  "flex justify-center items-center mr-[2vw] h-15 w-60";
const activeButtonStyle =
  "font-bold text-white bg-blue1 rounded-10 dark:border-2 dark:bg-lightBlack3 dark:border-white " +
  inactiveButtonStyle;

function Index(props) {
  let user = useSelector((state) => state.user.data);
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";

  let [activeOption, setActiveOption] = useState(0);

  // for search
  const [search, setSearch] = useState("");

  let navigate = useNavigate();
  const handleAddClick = () => {
    navigate("/addTarget");
  };
  return (
    <div style={{ padding: "1%" }}>
      <BackToCompanyList>
        <div className="flex justify-between items-center mb-1p dark:text-white">
          <Search search={search} setSearch={setSearch} />
          {user?.role == "MANAGER" && (
            <div style={{ display: "flex" }}>
              <button
                className={
                  blueGradient1 +
                  " flex items-center justify-center cursor-pointer w-60 rounded-10 h-15 font-bold"
                }
                style={{ margin: "0 10px" }}
                onClick={handleAddClick}
              >
                Add Targets
              </button>
              <button
                className={
                  "w-60 h-15 text-blue1 text-opacity-50 font-bold rounded-10 border-2 border-[#4B6DF1] bg-transparent"
                }
                disabled
              >
                Scan Targets
              </button>
            </div>
          )}
        </div>

        <div className="rounded overflow-hidden bg-white dark:bg-lightBlack3 dark:text-white">
          {user?.role == "MANAGER" && (
            <div
              style={
                activeOption == 0
                  ? {
                      display: "flex",
                      flexGrow: "1",
                      // justifyContent: "space-between",
                      alignItems: "center",

                      fontSize: "16px",
                      fontWeight: "400",
                      height: "60px",
                      borderRadius: "10px",

                      cursor: "default",
                    }
                  : {
                      display: "flex",
                      flexGrow: "1",
                      // justifyContent: "space-between",
                      alignItems: "center",

                      fontSize: "16px",
                      fontWeight: "400",
                      height: "60px",
                      borderTopRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                      cursor: "default",
                    }
              }
              className="bg-white dark:bg-lightBlack dark:text-white"
            >
              <div
                className={
                  activeOption == 0
                    ? inactiveButtonStyle +
                      " " +
                      blueGradient1 +
                      " rounded-10 font-bold"
                    : inactiveButtonStyle
                }
                onClick={() => {
                  setActiveOption(() => 0);
                }}
              >
                All
              </div>
              <div
                className={
                  activeOption == 1
                    ? inactiveButtonStyle +
                      " " +
                      blueGradient1 +
                      " rounded-10 font-bold"
                    : inactiveButtonStyle
                }
                onClick={() => {
                  setActiveOption(() => 1);
                }}
              >
                Bin
              </div>
            </div>
          )}
          {activeOption == 0 && <TargetTable search={search} />}
          {activeOption == 1 && <BinTargetTable search={search} />}
        </div>
      </BackToCompanyList>
    </div>
  );
}

export default Index;
