import React, { useState } from "react";
import Menu, { MenuItem, Select, Avatar } from "@mui/material";
import { Modal } from "@mui/material";
import SecondaryButton from "components/ui/buttons/SecondaryButton";
import SeverityButton from "components/ui/buttons/SeverityButton";
import ColorDot from "components/ui/buttons/ColorDot";
import GreyBgButton from "components/ui/buttons/GreyBgButton";
import { useRef } from "react";
import { postFetch } from "lib/fetch";
import { useDispatch, useSelector } from "react-redux";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";
import { toast } from "react-toastify";
import { CamelCase } from "Util/CamelCase";

function StatusSelection({ bugsData }) {
  let remarkRef = useRef();

  const [option, setOption] = useState(0);
  const handleChange = (event) => {
    setOption(event.target.value);
  };

  const selectedBugs = useSelector(
    (state) => state.targetDashboard.selectedBugs
  );

  const dispatch = useDispatch();
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const text = remarkRef.current.value;

    const newStatus = options[option];

    const res = await postFetch(`/bug/${newStatus}`, {
      bug_id: bugsData._id,
    });

    if (res.status == "ok") {
      try {
        dispatch(
          targetDashboardSliceActions.setSelectedBugs(
            selectedBugs.filter((bugId) => bugId != bugsData._id)
          )
        );
        toast.success(`New bug status :  ${newStatus}`);

        handleModalClose();
      } catch (e) {}
    } else {
      toast.error("Some error occured, try later !");
    }
  };

  let options =
    bugsData.duplicate == true
      ? ["Resubmit", "Deleted"]
      : ["Valid", "Resubmit", "Deleted"];

  // current option

  // modal for status change

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = (e) => {
    setModalOpen(() => false);
  };

  const handleDoneClick = (e) => {
    setModalOpen(() => true);
  };

  return (
    <div className="bg-white dark:bg-lightBlack dark:text-white  flex items-center p-2p border-t border-b1 dark:border-b9">
      <div className="w-3/5 mr-2">
        <Select
          labelId="demo-simple-select"
          sx={{
            width: "100%",
            height: "60px",
            backgroundColor: "#D9D9D940",
            border: "0px solid rgba(0, 0, 0, 0.25)",
            borderRadius: "5px",
          }}
          value={option}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          {options.map((item, i) => {
            return <MenuItem value={i}>{item}</MenuItem>;
          })}
        </Select>
      </div>
      <div className="w-2/5">
        <SecondaryButton onClick={handleDoneClick}>Done</SecondaryButton>
      </div>

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="w-[800px] dark:bg-lightBlack dark:text-white bg-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-10">
          <div className="text-xl font-bold text-center mt-2p mb-1p">
            {options[option]} Bug
          </div>
          <div className="bg-lightBlue text-sm p-2p pr-3p">
            <div className="mt-1">
              <span className="mr-2 font-medium">Created On : </span>
              <span>{bugsData?.created_date}</span>
            </div>

            <div className="flex justify-between mt-1p">
              <div className="flex flex-col">
                <div className="font-medium">Status</div>
                <div className="mt-4">
                  <ColorDot
                    type={
                      bugsData?.status.slice(0, 1).toUpperCase() +
                      bugsData?.status.slice(1, bugsData?.status.length)
                    }
                  />
                  <span className="ml-2">
                    {bugsData?.status.slice(0, 1).toUpperCase() +
                      bugsData?.status.slice(1, bugsData?.status.length)}
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="font-medium">Title</div>
                <div className="mt-4">{bugsData?.name}</div>
              </div>
              <div className="flex flex-col">
                <div className="font-medium">Type</div>
                <div className="mt-4">
                  <GreyBgButton>{bugsData?.type}</GreyBgButton>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="font-medium">Severity</div>
                <div className="mt-4">
                  {SeverityButton(CamelCase(bugsData?.severity))}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="font-medium">Team Member</div>
                <div className="mt-2">
                  <Avatar alt="Travis Howard" src="/avatar2.svg" />
                </div>
              </div>
            </div>
          </div>
          <form action="" onSubmit={handleFormSubmit}>
            <div className="mx-2p pb-2">
              <div>
                <div className="my-2 font-medium">Remark</div>
                <textarea
                  required
                  name=""
                  ref={remarkRef}
                  id=""
                  rows="5"
                  style={{
                    resize: "none",
                    width: "100%",
                    boxSizing: "border-box",
                    padding: "1%",
                    background: "rgba(0, 0, 0, 0.08)",
                    border: " 1px solid rgba(0, 0, 0, 0.25)",
                    borderRadius: "10px",
                    fontSize: "16px",
                  }}
                  placeholder="Type Here"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
              className="border-t-2 border-b2 mx-2p py-2p"
            >
              <button
                style={{
                  // marginTop: "3.2%",
                  textDecoration: "none",
                  color: "#2D3B51 !important",
                  marginRight: "5vw",
                  fontWeight: "500",
                }}
                onClick={() => {
                  // setState(() => "");
                  setModalOpen(() => false);
                }}
              >
                Cancel
              </button>
              <button className="button-1-step" type="submit">
                Done
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default StatusSelection;
