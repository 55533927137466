import React from "react";
import { DashboardComponent } from "features/dashboard/Index";
import { ScannerDashboardComponent } from "features/scanner/dashboard/Index";
import { useSelector } from "react-redux";

function Dashboard(props) {
  const isScanner =
    useSelector((state) => state.general.currentApp) == "SCANNER";

  return isScanner ? <ScannerDashboardComponent /> : <DashboardComponent />;
}

export default Dashboard;
