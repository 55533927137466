import React, { useState } from "react";
import Edit from "./Edit/Index";
import Profile from "./Profile";
import { useSelector } from "react-redux";

function Index(props) {
  const [edit, setEdit] = useState(false);
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  const user = useSelector((state) => state.user.data);
  return (
    <div>
      {edit == false && (
        <>
          <div className="rounded bg-white dark:bg-lightBlack dark:text-white flex justify-between p-2p">
            <div className="text-lg  ">{user?.companyName}</div>
            <div>
              <img
                src={isDarkTheme ? "/editPenDark.svg" : "/editPen.svg"}
                alt=""
                className=" cursor-pointer"
                onClick={() => {
                  setEdit(() => true);
                }}
              />
            </div>
          </div>
          <Profile />
        </>
      )}
      {edit == true && (
        <>
          <Edit setEdit={setEdit} />
        </>
      )}
    </div>
  );
}

export default Index;
