import React from "react";
import Dashboard from "./components/Dashboard/Index";
import TargetDashboard from "./components/TargetDashboard/Index";
import CompareReports from "./components/TargetDashboard/Bug/compareReports/Index";

export const DashboardComponent = Dashboard;
export const TargetDashboardComponent = TargetDashboard;
export const CompareReportsComponent = CompareReports;

function Index(props) {
  return <div></div>;
}

export default Index;
