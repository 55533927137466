import { combineReducers } from "redux";
import addScanEngineSlice from "./addScanEngineSlice";
import editScanEngineSlice from "./editScanEngineSlice";
import scanEngineSlice from "./scanEngineSlice";
import addScanProfileSlice from "./addScanProfileSlice";
import editScanProfileSlice from "./editScanProfileSlice";
import dashboardSlice from "./dashboardSlice";
import scanProfileSlice from "./scanProfileSlice";
import targetDashboardSlice from "./targetDashboardSlice";


const rootReducer = combineReducers({
  addScanEngine: addScanEngineSlice.reducer,
  editScanEngine: editScanEngineSlice.reducer,
  scanEngine: scanEngineSlice.reducer,
  addScanProfile: addScanProfileSlice.reducer,
  editScanProfile: editScanProfileSlice.reducer,
  dashboard: dashboardSlice.reducer,
  scanProfile: scanProfileSlice.reducer,
  targetDashboard: targetDashboardSlice.reducer,
});

export default rootReducer;
