import React, { useState } from "react";
import { useSelector } from "react-redux";

import ColoredPieChart from "./ColoredPieChart";
import ColoredBarChart from "./ColoredBarChart";

function Index({ type = "vulnerability", values, width = 52, onSide = false }) {
  const [chartType, setChartType] = useState(false);

  // new informative triaged resolve
  // critical high medium low

  const isDarkMode = useSelector((state) => state.general.theme);

  const handleIconClick = (e) => {
    setChartType(() => !chartType);
  };

  return (
    <div
      className={`flex ${
        onSide ? "" : "flex-col"
      } items-center justify-center relative`}
    >
      <div>
        <div
          className="absolute right-2 top-2 cursor-pointer"
          onClick={handleIconClick}
        >
          {chartType &&
            (isDarkMode ? (
              <img src="/barChartIconDark.svg" alt="" />
            ) : (
              <img src="/barChartIcon.svg" alt="" />
            ))}
          {!chartType &&
            (isDarkMode ? (
              <img src="/pieChartIconDark.svg" alt="" />
            ) : (
              <img src="/pieChartIcon.svg" alt="" />
            ))}
        </div>
        <div className={"p-5 pb-0 " + `w-${width}`}>
          {type == "vulnerability" && (
            <>
              {chartType && (
                <div className="mb-5 ">
                  <ColoredPieChart
                    type={type}
                    values={[
                      values?.critical,
                      values?.high,
                      values?.medium,
                      values?.low,
                    ]}
                  />
                </div>
              )}
              {!chartType && (
                <ColoredBarChart
                  type={type}
                  values={[
                    values?.critical,
                    values?.high,
                    values?.medium,
                    values?.low,
                  ]}
                />
              )}
            </>
          )}
          {type === "status" && (
            <>
              {chartType && (
                <div className="mb-5 ">
                  <ColoredPieChart
                    type={type}
                    values={[
                      values?.new,
                      values?.informative,
                      values?.triaged,
                      values?.resolve,
                    ]}
                  />
                </div>
              )}
              {!chartType && (
                <ColoredBarChart
                  type={type}
                  values={[
                    values?.new,
                    values?.informative,
                    values?.triaged,
                    values?.resolve,
                  ]}
                />
              )}
            </>
          )}
        </div>
      </div>

      {type == "vulnerability" && (
        <div className="flex justify-evenly font-semibold w-full dark:text-white">
          <div className="flex mr-2">
            <div className="flex flex-col mr-1 ">
              <div>
                <img
                  src="/criticalDot.svg"
                  alt=""
                  className="inline mr-2 w-3"
                />
                Critical
              </div>
              <div>
                <img src="/highDot.svg" alt="" className="inline mr-2 w-3" />
                High
              </div>
            </div>
            <div className="flex flex-col">
              <div>{values.critical}</div>
              <div>{values.high}</div>
            </div>
          </div>

          <div className="flex mr-2">
            <div className="flex flex-col mr-1 ">
              <div>
                <img src="/mediumDot.svg" alt="" className="inline mr-2 w-3" />
                Medium
              </div>
              <div>
                <img src="/lowDot.svg" alt="" className="inline mr-2 w-3" />
                Low
              </div>
            </div>
            <div className="flex flex-col">
              <div>{values?.medium}</div>
              <div>{values?.low}</div>
            </div>
          </div>
        </div>
      )}
      {type == "status" && (
        <div className="flex justify-evenly font-semibold w-full dark:text-white">
          <div className="flex mr-2">
            <div className="flex flex-col mr-1 ">
              <div>
                <img src="/newDot.svg" alt="" className="inline mr-2 w-3" />
                New
              </div>
              <div>
                <img src="/triagedDot.svg" alt="" className="inline mr-2 w-3" />
                Triaged
              </div>
            </div>
            <div className="flex flex-col">
              <div>{values?.new}</div>
              <div>{values?.triaged}</div>
            </div>
          </div>

          <div className="flex mr-2">
            <div className="flex flex-col mr-1 ">
              <div>
                <img
                  src="/informativeDot.svg"
                  alt=""
                  className="inline mr-2 w-3"
                />
                Informative
              </div>
              <div>
                <img src="/resolveDot.svg" alt="" className="inline mr-2 w-3" />
                Resolve
              </div>
            </div>
            <div className="flex flex-col">
              <div>{values?.informative}</div>
              <div>{values?.resolve}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Index;
