import React, { useState, useEffect } from "react";
import { MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { input1 } from "components/tailwindClasses";
import { addScanEngineSliceActions } from "store/scanner/addScanEngineSlice";
import { addScanProfileSliceActions } from "store/scanner/addScanProfileSlice";
import { Link } from "react-router-dom";
import { getFetch, pythonPostFetch } from "lib/fetch";
import { toast } from "react-toastify";
import Loader from "components/ui/loaders/Loader";

export const scanTypes = [
  { id: "active", label: "Active" },
  { id: "reconnaissance", label: "Reconnaissance" },
  { id: "darkWebMonitoring", label: "Dark Web Monitoring" },
];

export const scanSpeeds = [
  {
    id: "quick",
    label: "Quick",
  },
  {
    id: "medium",
    label: "Medium",
  },
];

function Index(props) {
  const dispatch = useDispatch();
  const [webUrl, setWebUrl] = useState("");

  const asset = useSelector(
    (state) => state.scanner.addScanProfile.step1.asset
  );
  const scanName = useSelector(
    (state) => state.scanner.addScanProfile.step1.scanName
  );

  // user can override the value of web app url
  // issue : the problem was ki app url is not associated with the data so nothing is set
  const webAppUrl = useSelector(
    (state) => state.scanner.addScanProfile.step1.webAppUrl
  );

  const tag = useSelector((state) => state.scanner.addScanProfile.step1.tag);

  // const tags = useSelector((state) => state.scanner.addScanProfile.step1.tags);
  const tags = ["web"];
  const assets = useSelector(
    (state) => state.scanner.addScanProfile.step1.assets
  );

  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    // get assets and tags

    const getAssetUrls = async () => {
      setIsloading(() => true);
      const res = await getFetch("/manager/assetDetailsv2"); // Get the list of asets from the node server
    
      if (res?.assets?.length > 0) {
        dispatch(addScanProfileSliceActions.setAssets(res?.assets));
      } else if (res?.error && res?.message) {
        toast.error(res.message);
      } else {
        toast.error("Could not fetch assets, try later!");
      }

      setIsloading(() => false);
    };

    getAssetUrls();
  }, []);

  return (
    <form id="step1-form">
      <div
        style={{
          padding: "2%",
          paddingBottom: "3%",
          borderRadius: "10px",
          marginBottom: "1%",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        <div className="avatar-text ">Select Asset</div>

        <div>
          {/* Select component -> select an asset while creating a new scan in 
              [Scan Profile] section */}
          <Select
            sx={{
              width: "100%",
              boxSizing: "border-box",
              height: "45px",
              backgroundColor: "#D9D9D940",
              border: "0px solid rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
            }}
            className="dark:text-white bg-inputBg dark:bg-inputBgDark"
            required
            value={asset}
            onChange={(e) => {
              dispatch(addScanProfileSliceActions.setAsset(e.target.value));
              dispatch(
                addScanProfileSliceActions.setWebAppUrl(
                  assets.find((a) => a.title === e.target.value).website_url // since target url is not present with the asset its not set automatically
                )
              );
            }}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            MenuProps={{
              PaperProps: { sx: { maxHeight: 400 } },
            }}
          >
            <MenuItem value="">
              <em>Select </em>
            </MenuItem>

            {isLoading ? (
              <Loader />
            ) : (
              assets?.map((a, i) => {
                return <MenuItem value={a.title}>{a.title}</MenuItem>;
              })
            )}
          </Select>
        </div>

        <div className="avatar-text mt-1">Scan Name</div>
        <input
          value={scanName}
          onChange={(e) => {
            dispatch(addScanProfileSliceActions.setScanName(e.target.value));
          }}
          style={{ height: "50px" }}
          className={input1}
          placeholder="Type Here"
          required
        />

        <div className="avatar-text mt-3p pb-2p border-b dark:border-b9 font-medium w-full">
          Target Definition
        </div>

        <div className="avatar-text mt-1">Web Application URL</div>
        <input
          value={webAppUrl}
          onChange={(e) => {
            // cant edit this
            dispatch(addScanProfileSliceActions.setWebAppUrl(webAppUrl));
          }}
          type="url"
          style={{ height: "50px" }}
          className={input1}
          placeholder="Type Here"
          required
        />

        <div className=" mt-1p flex justify-between items-center">
          <div className="avatar-text">Tag</div>
          <Link aria-disabled className="text-sm">
            Add New Tag
          </Link>
        </div>

        <div>
          <Select
            sx={{
              width: "100%",
              boxSizing: "border-box",
              height: "45px",
              backgroundColor: "#D9D9D940",
              border: "0px solid rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
            }}
            className="dark:text-white bg-inputBg dark:bg-inputBgDark"
            // required
            value={tag}
            onChange={(e) => {
              dispatch(addScanProfileSliceActions.setTag(e.target.value));
            }}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            MenuProps={{
              PaperProps: { sx: { maxHeight: 400 } },
            }}
          >
            <MenuItem value="">
              <em>Select </em>
            </MenuItem>
            {tags?.map((t, i) => {
              // return <MenuItem value={t.id}>{t.label}</MenuItem>;
              return <MenuItem value={t}>{t}</MenuItem>; // value - replace with index if needed
            })}
          </Select>
        </div>
      </div>
    </form>
  );
}

export default Index;
