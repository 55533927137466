import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activePageActions } from "store/activePageSlice";
import { Box, Modal, Checkbox } from "@mui/material";
import AddReport from "./AddReport/Index";
import ReportTable from "./ReportTable";
import { getFetch } from "lib/fetch";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { blueGradient1, button1 } from "components/tailwindClasses";
import { toast } from "react-toastify";
import { addReportSliceActions } from "store/addReportSlice";
import { reportSliceActions } from "store/reportSlice";
import { Search } from "components/ui/inputs/Search";

import { saveAs } from "file-saver";
import axios from "axios";

function CheckBoxHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  return (
    <TableCell
      padding="checkbox"
      sx={{ bgcolor: "rgb(217, 217, 217)" }}
      className="dark:bg-lightBlack3"
      align="left"
    >
      <Checkbox
        color="primary"
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllClick}
        inputProps={{
          "aria-label": "select all desserts",
        }}
      />
    </TableCell>
  );
}

export const columns = [
  { id: "name", label: "Report Name", minWidth: 300, align: "left" },
  { id: "format", label: "Format", minWidth: 170, align: "left" },
  { id: "type", label: "Type", minWidth: 170, align: "left" },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Size",
    minWidth: 150,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "date",
    label: "Generation Date",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "download",
    label: "",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function Index(props) {
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  let dispatch = useDispatch();

  const reports = useSelector((state) => state.report.reports);

  const user = useSelector((state) => state.user.data);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let getData = async () => {
      setIsLoading(() => true);

      if (user.role == "MANAGER" || user.role == "S_MANAGER") {
        const res = await getFetch("/manager/reports");
        if (res.status == "ok") {
          dispatch(reportSliceActions.setReports(res?.reports));
        } else if (res?.error && res?.message) {
          toast.error(res.message);
          dispatch(reportSliceActions.setReports([]));
        } else {
          toast.error("some error occured in loading reports, try later");
          dispatch(reportSliceActions.setReports([]));
        }
      } else toast.error("user not authorised");

      setIsLoading(() => false);
    };
    getData();
  }, [user]);

  // group reports by asset_id and get the latest report for each asset
  const groupedReports = Object.values(
    reports.reduce((acc, report) => {
      if (
        !acc[report.asset_id] ||
        report.createdDate > acc[report.asset_id].createdDate
      ) {
        acc[report.asset_id] = report;
      }
      return acc;
    }, {})
  );
  const [search, setSearch] = useState("");

  let rows = [];
  rows = groupedReports
    .filter((searchItem) =>
      searchItem?.title?.toLowerCase()?.includes?.(search?.toLowerCase())
    )
    ?.map?.((item) => {
      return {
        id: item?._id,
        name: item?.title,
        format: item?.format,
        type: item?.type,
        status: item?.status,
        size: item?.size,
        date: new Date(item?.createdDate)?.toDateString(),
        download: (
          <img
            src={isDarkTheme ? "/downloadDark.svg" : "/download.svg"}
            alt=""
          />
        ),
        link: item?.file_link,
        assetId: item?.asset_id,
      };
    });

  // handling incoming download state -------------------------------------------------------

  // active page
  const location = useLocation();

  const incomingState = location?.state;
  //  { downloadReport: true, assetId: id}

  // modal popup for add report
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // modal popup for download

  const [openDownloadModal, setOpenDownloadModal] = useState(false);

  const handleDownloadModalOpen = () => setOpenDownloadModal(true);
  const handleDownloadModalClose = () => setOpenDownloadModal(false);

  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [selectedAssetReports, setSelectedAssetReports] = useState([]);

  const [selectedReportsToDownload, setSelectedReportsToDownload] = useState(
    []
  );

  // Function to handle row click
  const handleDownloadClick = (assetId) => {
    handleDownloadModalOpen();
    setSelectedAssetId(() => assetId);

    // Filter the reports for the selected asset
    const reportsForAsset = reports.filter(
      (report) => report.asset_id === assetId
    );

    setSelectedAssetReports(() =>
      reportsForAsset?.map((item) => {
        return {
          id: item?._id,
          name: item?.title,
          format: item?.format,
          type: item?.type,
          status: item?.status,
          size: item?.size,
          date: new Date(item?.createdDate)?.toDateString(),
          download: (
            <img
              src={isDarkTheme ? "/downloadDark.svg" : "/download.svg"}
              alt=""
            />
          ),
          link: item?.file_link,
          assetId: item?.asset_id,
        };
      })
    );
  };

  useEffect(() => {
    // add report logic
    const openAddReportModal =
      reports?.length > 0
        ? incomingState?.downloadReport
          ? !reports.find((i) => i.asset_id == incomingState?.assetId)
          : false
        : false;

    // download report logic
    const openDownloadReportModal =
      reports?.length > 0
        ? incomingState?.downloadReport
          ? !openAddReportModal
          : false
        : false;

    if (openAddReportModal) {
      handleOpen();
    } else if (openDownloadReportModal) {
      handleDownloadClick(incomingState?.assetId);
    }
  }, [incomingState, reports]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = selectedAssetReports?.map((n) => n.link);

      setSelectedReportsToDownload(() => newSelected);

      return;
    }
    setSelectedReportsToDownload(() => []);
  };

  // handle click of row
  const handleCheckClick = (event, link) => {
    const selectedIndex = selectedReportsToDownload.indexOf(link);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedReportsToDownload, link);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedReportsToDownload.slice(1));
    } else if (selectedIndex === selectedReportsToDownload.length - 1) {
      newSelected = newSelected.concat(selectedReportsToDownload.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedReportsToDownload.slice(0, selectedIndex),
        selectedReportsToDownload.slice(selectedIndex + 1)
      );
    }

    setSelectedReportsToDownload(() => newSelected);
  };

  // to check if option is selcted
  const isSelected = (link) => selectedReportsToDownload.indexOf(link) !== -1;

  const handleFinalDownloadButtonClick = async () => {
    if (selectedReportsToDownload?.length > 0) {
      let toDownload = selectedReportsToDownload?.filter((item) => item);

      toDownload.forEach(function (e) {
        fetch(e)
          .then((res) => res.blob())
          .then((blob) => {
            saveAs(blob, "file.pdf");
          })
          .catch((err) => toast.error(err.message));
      });

      if (toDownload?.length != selectedReportsToDownload?.length) {
        toast.info(
          `${
            selectedReportsToDownload.length - toDownload.length
          } reports are not available for download, please try again later`
        );
      }
      handleDownloadModalClose();

      setSelectedReportsToDownload(() => []);
    } else {
      toast.info("Select some reports to download");
    }
  };

  return (
    <div className="p-1p">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="grow ">
          <Search search={search} setSearch={setSearch} />
        </div>
        {(user.role == "S_MANAGER" || user.role == "MANAGER") && (
          <button
            onClick={handleOpen}
            className={
              blueGradient1 +
              " flex items-center justify-center cursor-pointer w-60 rounded-10 h-15 font-bold ml-1p"
            }
          >
            New Report
          </button>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <AddReport handleClose={handleClose} />
        </Modal>

        <Modal
          open={openDownloadModal}
          onClose={handleDownloadModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: 24,
            }}
            className="w-[800px] shadow rounded-10 bg-white dark:bg-lightBlack dark:text-white"
          >
            <form
              action=""
              //  onSubmit={handleSubmit}
            >
              <div className="text-2xl text-center font-bold mt-3p pb-2p mx-3p ">
                Download Report
              </div>

              <div className="bg-lightGreen dark:bg-lightBlack5 dark:text-white text-sm p-2p border-l-8 border-lightGreen2">
                <span className="font-medium mr-2">Report Name : </span>
                <span className="text-lg font-bold">
                  {selectedAssetReports?.[0]?.name}
                </span>
              </div>

              <div className="px-2p font-medium text-lg my-2">
                Select No of Report{" "}
              </div>

              <TableContainer sx={{ maxHeight: "350px", overflowY: "scroll" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <CheckBoxHead
                        numSelected={selectedReportsToDownload?.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={selectedAssetReports?.length}
                      />

                      {columns
                        .filter((c) => c.id != "download")
                        .map((column) => {
                          return (
                            <TableCell
                              key={column.id}
                              // align={column.align}
                              style={{
                                // minWidth: column.minWidth,
                                fontWeight: "500",
                                // padding: "1% 2%",
                              }}
                              sx={{ bgcolor: "rgb(217, 217, 217)" }}
                              className=" dark:bg-lightBlack3 dark:text-white"
                            >
                              {column.label}{" "}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedAssetReports?.map((row, index) => {
                      const isItemSelected = isSelected(row.link);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          // aria-checked={isItemSelected}
                          // onClick={(event) => {
                          //   handleBugClick(event, row.name);
                          // }}
                          tabIndex={-1}
                          key={row.name}
                          // selected={isItemSelected}
                        >
                          <TableCell
                            padding="checkbox"
                            onClick={(event) => {
                              handleCheckClick(event, row.link);
                            }}
                          >
                            <Checkbox
                              color={"primary"}
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>

                          {columns
                            .filter((c) => c.id != "download")
                            ?.map((column) => {
                              const value = row[column.id];

                              return (
                                <TableCell
                                  key={column.id}
                                  // align={column.align}
                                  style={
                                    {
                                      // padding: "1% 2%",
                                    }
                                  }
                                >
                                  {column.format &&
                                  typeof value === "number" ? (
                                    <p className="dark:text-white">
                                      {column.format(value)}
                                    </p>
                                  ) : (
                                    <p className="dark:text-white"> {value}</p>
                                  )}
                                </TableCell>
                              );
                            })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
                className=" mx-2p py-2p"
              >
                <button
                  style={{
                    // marginTop: "3.2%",
                    textDecoration: "none",
                    color: "#2D3B51 !important",
                    marginRight: "5vw",
                    fontWeight: "500",
                  }}
                  onClick={handleDownloadModalClose}
                >
                  Cancel
                </button>
                <div
                  // className={
                  //   button1 +
                  //   " cursor-pointer w-60 flex items-center justify-center"
                  // }
                  className={
                    blueGradient1 +
                    " flex items-center justify-center cursor-pointer w-60 rounded-10 font-bold h-12"
                  }
                  onClick={handleFinalDownloadButtonClick}
                >
                  <div>Download</div>
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      </div>

      <ReportTable
        rows={rows}
        onDownloadClick={handleDownloadClick}
        isLoading={isLoading}
      />
    </div>
  );
}

export default Index;
