import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activePage: 0,
};

let activePageSlice = createSlice({
  name: "activePageSlice",
  initialState: initialState,
  reducers: {
    setActivePage(state, action) {
      state.activePage = action.payload;
    },
  },
});

export const activePageActions = activePageSlice.actions;

export default activePageSlice;
