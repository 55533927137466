import React, { useRef } from "react";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import { AvatarGroup, Avatar } from "@mui/material";
import ColorDot from "components/ui/buttons/ColorDot";

import Chat2 from "./Chat";

import GreyBgButton from "components/ui/buttons/GreyBgButton";
import SeverityButton from "components/ui/buttons/SeverityButton";
import StatusButton from "components/ui/buttons/StatusButton";
import { useEffect } from "react";
import { postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { useState } from "react";
import Weakness from "features/report/components/BugReport/BugReportSteps/Weakness";

import "./Chat.css";
import { useSelector } from "react-redux";
import { MultipleUserImage } from "components/ui/ImageComponent";

function Chat({ closeChat, data }) {
  const { id, title, type, severity, status, createdOn, team } = data;

  let handleCrossClick = () => {
    closeChat();
  };

  const targetInfoData = useSelector((state) => state?.targetInfo?.data);

  return (
    <div className="w-[800px]  rounded-10 overflow-hidden bg-opacity-100 bg-white shadow-lg  ">
      <div className="p-2p flex justify-end bg-[rgb(169,192,66)] ">
        {/* <div className="flex items-center">
          <Avatar src="/avatar.svg" className="mr-2" />
          <span className="font-bold text-lg text-white">Sidhu Moosewala</span>
        </div> */}
        <div className="flex">
          <img src="/chatOpen.svg" alt="" className="w-6 mr-6" />
          <img
            src="/crossWhite.svg"
            alt=""
            className="w-4 "
            onClick={handleCrossClick}
          />
        </div>
      </div>
      <div className="bg-lightGreen dark:bg-[#3B3E4E] dark:text-white text-sm p-2p">
        <div>
          {targetInfoData?.asset_name && (
            <span className="font-bold mr-2">{targetInfoData?.asset_name}</span>
          )}
          {targetInfoData?.website_url && (
            <Link className="no-underline font-medium">
              {targetInfoData?.website_url}
            </Link>
          )}
        </div>
        {createdOn && (
          <div className="mt-1">
            <span className="mr-2 font-medium">Created On : </span>
            <span>{new Date(createdOn).toDateString()}</span>
          </div>
        )}

        <div className="flex justify-between mt-1p">
          <div className="flex flex-col">
            <div className="font-medium">Status</div>
            <div className="mt-4">
              <ColorDot type={status} />
              <span className="ml-2">{status}</span>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="font-medium">Title</div>
            <Link className="no-underline font-medium mt-4">
              {title?.slice(0, 35)}
            </Link>
          </div>
          <div className="flex flex-col">
            <div className="font-medium">Type</div>
            <div className="mt-4">
              <GreyBgButton width={"max-content"} padding={"4px"}>
                {type?.slice(0, 20)}
              </GreyBgButton>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="font-medium">Severity</div>
            <div className="mt-4">{SeverityButton(severity)}</div>
          </div>
          <div className="flex flex-col">
            <div className="font-medium">Team Member</div>
            <div className="mt-2">
              <MultipleUserImage team={team} position={"top right"} />
            </div>
          </div>
        </div>
      </div>

      <div className="dark:bg-lightBlack2">
        <Chat2 vuln_id={id} />
      </div>
      {/* <div className="bg-greyBg4 p-2p  flex  ">
        <div className="grow flex bg-inputBg rounded mr-4">
          <input
            type="text"
            className="p-2p grow bg-transparent border-r border-b2"
            placeholder="Type Here"
          />
          <div className="flex px-2p">
            <img src="/addFileChat.svg" alt="" className="w-8 mr-4" />
            <img src="/meetLogo.svg" alt="" className="w-8" />
          </div>
        </div>

        <img src="/chatSubmit.svg" alt="" className="w-50 " />
      </div> */}
    </div>
  );
}

export default Chat;
