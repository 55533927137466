import { UserImage } from "components/ui/ImageComponent";
import { getFetch } from "lib/fetch";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { handleFileUpload } from "services/uploadFile";
import { profileSliceActions } from "store/profileSectionSlice";

function Index(props) {
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  const user = useSelector((state) => state.user.data);

  const profile = useSelector((state) => state.profile.companyDetails);

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      const res = await getFetch("/customer/profile");
      if (res?.status == "ok") {
        dispatch(
          profileSliceActions.setCompanyDetails({
            name: res?.data?.cname,
            profileImage: res?.data?.profile_image,
            description: res?.data?.description,
            location: res?.data?.location,
            createdDate: res?.data?.createdDate,
            founder: res?.data?.founder,
            websiteUrl: res?.data?.website_url,
            email: res?.data?.email,
            facebookUrl: res?.data?.facebook_url,
            linkedinUrl: res?.data?.linkedin_url,
            instagramUrl: res?.data?.instagram_url,
            twitterUrl: res?.data?.twitter_url,
            policy: res?.data?.policy,
            foundedDate: new Date(
              res?.data?.founded_date
            )?.toLocaleDateString?.("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }),

            phoneNumber: res?.data?.phone_number,
            address: res?.data?.address,
          })
        );
      }
    };
    getData();
  }, []);

  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showFullPolicy, setShowFullPolicy] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription((prevState) => !prevState);
  };
  const togglePolicy = () => {
    setShowFullPolicy((prevState) => !prevState);
  };

  return (
    <div
      style={{
        marginTop: "2%",
        marginBottom: "2%",
        boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
      }}
      className="py-3p bg-white dark:bg-lightBlack dark:text-white"
    >
      <div className="flex px-3p ">
        <div className="mr-2p">
          <UserImage
            name={profile?.name || "-"}
            image={profile?.profileImage}
            style={{ width: "100px", height: "100px" }}
          />
        </div>
        <div className="flex flex-col text-lg">
          <div className="font-bold">{profile?.name || "-"}</div>
          <div>
            {profile?.description &&
              (showFullDescription
                ? profile?.description
                : `${profile?.description?.slice(0, 300)}...`)}
            {profile?.description &&
              (profile?.description?.length > 300 ? (
                <button onClick={toggleDescription} className="text-blue-500">
                  {showFullDescription ? "Less" : "More"}
                </button>
              ) : null)}
          </div>
          <div className="flex items-center my-2">
            <img
              src={isDarkTheme ? "/locationDark.svg" : "/location.svg"}
              alt=""
              className="inline mr-2"
            />
            <span>{profile?.location || "-"}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-lightBlue dark:bg-lightBlack3 mt-2p py-2p px-3p">
        <div className="flex border-b3 border-b px-2p pb-2p">
          <div className="flex flex-col w-[20%]">
            <div className="font-bold flex items-center">
              <img
                src={
                  isDarkTheme
                    ? "/categoryProfileDark.svg"
                    : "/categoryProfile.svg"
                }
                alt=""
                className="inline mr-2 w-4"
              />
              <span>Category</span>
            </div>
            <div className="font-bold flex items-center mt-3">
              <img
                src={isDarkTheme ? "/dateDark.svg" : "/date.svg"}
                alt=""
                className="inline mr-2 w-4"
              />
              <span>Founded Date</span>
            </div>
            <div className="font-bold flex items-center mt-3">
              <img
                src={isDarkTheme ? "/founderDark.svg" : "/founder.svg"}
                alt=""
                className="inline mr-2 w-4"
              />
              <span>Founder</span>
            </div>
          </div>
          <div className="flex flex-col ">
            <div>{profile?.category || "-"}</div>
            <div className="mt-3">
              {new Date(profile?.foundedDate)?.toDateString() || "-"}
            </div>
            <div className="mt-3">{profile?.founder || "-"}</div>
          </div>
        </div>
        <div className="flex border-b3 border-b px-2p py-2p">
          <div className="flex flex-col w-[20%]">
            <div className="font-bold flex items-center">
              <img
                src={isDarkTheme ? "/websiteDark.svg" : "/website.svg"}
                alt=""
                className="inline mr-2 w-4"
              />
              <span>Website</span>
            </div>
            <div className="font-bold flex items-center mt-3">
              <img
                src={isDarkTheme ? "/emailDark.svg" : "/email.svg"}
                alt=""
                className="inline mr-2 w-4"
              />
              <span>Email</span>
            </div>
          </div>
          <div className="flex flex-col ">
            <Link to={profile?.websiteUrl || "-"} className="no-underline">
              {profile?.websiteUrl || "-"}
            </Link>
            <div className="mt-3">{profile?.email || "-"}</div>
          </div>
        </div>
        <div className="flex  px-2p pt-2p">
          {profile?.facebookUrl && (
            <div className="mr-2p">
              <Link className="no-underline" to={profile?.facebookUrl}>
                <img
                  src={isDarkTheme ? "/facebookDark.svg" : "/facebook.svg"}
                  alt=""
                  className="inline mr-2"
                />
              </Link>
            </div>
          )}
          {profile?.linkedinUrl && (
            <div className="mr-2p">
              <Link className="no-underline" to={profile?.linkedinUrl}>
                <img
                  src={isDarkTheme ? "/linkedInDark.svg" : "/linkedIn.svg"}
                  alt=""
                  className="inline mr-2"
                />
              </Link>
            </div>
          )}
          {profile?.instagramUrl && (
            <div className="mr-2p">
              <Link className="no-underline" to={profile?.instagramUrl}>
                <img
                  src={isDarkTheme ? "/instagramDark.svg" : "/instagram.svg"}
                  alt=""
                  className="inline mr-2"
                />
              </Link>
            </div>
          )}
          {profile?.twitterUrl && (
            <div className="mr-2p">
              <Link className="no-underline" to={profile?.twitterUrl}>
                <img
                  src={isDarkTheme ? "/twitterDark.svg" : "/twitter.svg"}
                  alt=""
                  className="inline mr-2"
                />
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="mt-2p px-3p">
        <div className="font-bold">Policy</div>
        <div>
          {profile?.policy &&
            (showFullPolicy
              ? profile?.policy
              : `${profile?.policy?.slice(0, 500)}...`)}
          {profile?.policy &&
            (profile?.policy?.length > 500 ? (
              <button onClick={togglePolicy} className="text-blue-500">
                {showFullPolicy ? "Less" : "More"}
              </button>
            ) : null)}
        </div>
      </div>
    </div>
  );
}

export default Index;
