import { Textarea } from "@mui/joy";
import { Input, MenuItem, Select, TextareaAutosize } from "@mui/material";
import { input1 } from "components/tailwindClasses";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addReportSliceActions } from "store/addReportSlice";

const docFormatOptions = { pdf: "Portable Document Format (pdf)" };

function Index(props) {
  const dispatch = useDispatch();

  const reportFormat = useSelector((state) => state?.addReport?.reportFormat);
  const secure = useSelector((state) => state?.addReport?.secure);
  const password = useSelector((state) => state?.addReport?.password);
  const confirmPassword = useSelector(
    (state) => state?.addReport?.confirmPassword
  );
  const emails = useSelector((state) => state?.addReport?.emails);

  useEffect(() => {
    !reportFormat && dispatch(addReportSliceActions.setReportFormat("pdf"));
    !secure && dispatch(addReportSliceActions.setSecure("No"));
  }, [secure, reportFormat]);

  return (
    <form id="step3-form">
      <div
        style={{
          padding: "2%",
          paddingBottom: "3%",
          borderRadius: "10px",
          marginBottom: "1%",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        <div>
          <div className="avatar-text" style={{ fontWeight: "500" }}>
            Select Report Format
          </div>

          <div>
            <Select
              sx={{
                width: "100%",
                boxSizing: "border-box",
                height: "45px",
                border: "0px solid rgba(0, 0, 0, 0.25)",
                borderRadius: "5px",
              }}
              className="dark:text-white bg-inputBg dark:bg-inputBgDark"
              value={reportFormat}
              onChange={(e) => {
                dispatch(addReportSliceActions.setReportFormat(e.target.value));
              }}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              required
            >
              {Object.keys(docFormatOptions).map((key) => {
                return <MenuItem value={key}>{docFormatOptions[key]}</MenuItem>;
              })}
            </Select>
          </div>
        </div>

        <div className="mt-4">
          <div className="avatar-text" style={{ fontWeight: "500" }}>
            Secure Report
          </div>

          <div>
            <Select
              sx={{
                width: "100%",
                boxSizing: "border-box",
                height: "45px",
                border: "0px solid rgba(0, 0, 0, 0.25)",
                borderRadius: "5px",
              }}
              className="dark:text-white bg-inputBg dark:bg-inputBgDark"
              value={secure}
              onChange={(e) => {
                dispatch(addReportSliceActions.setSecure(e.target.value));
                if (e.target.value == "No") {
                  dispatch(addReportSliceActions.setPassword(null));
                  dispatch(addReportSliceActions.setConfirmPassword(null));
                }
              }}
              inputProps={{ "aria-label": "Without label" }}
              displayEmpty
              required
            >
              <MenuItem value={"Yes"}>Yes</MenuItem>
              <MenuItem value={"No"}>No</MenuItem>
            </Select>
          </div>
        </div>
        {secure == "Yes" && (
          <div className="mt-4 flex">
            <div className="grow mr-1">
              <div className="avatar-text" style={{ fontWeight: "500" }}>
                Password
              </div>

              <div>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => {
                    dispatch(addReportSliceActions.setPassword(e.target.value));
                  }}
                  className="dark:text-white"
                  required
                  name="thirdStepAddReportPassword"
                />
              </div>
            </div>
            <div className="grow ml-1">
              <div className="avatar-text" style={{ fontWeight: "500" }}>
                Confirm Password
              </div>

              <div>
                <Input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => {
                    dispatch(
                      addReportSliceActions.setConfirmPassword(e.target.value)
                    );
                  }}
                  className="dark:text-white"
                  required
                  name="thirdStepAddReportConfirmPassword"
                />
              </div>
            </div>
          </div>
        )}

        <div className="mt-4">
          <div className="avatar-text" style={{ fontWeight: "500" }}>
            Add emails to share this report
          </div>

          <div>
            <input
              // disabled={reportViewType == "update" ? true : false}
              style={{ height: "50px" }}
              className={input1}
              type="email"
              multiple
              placeholder="a@b.com , b@c.com"
              value={emails}
              onChange={(e) => {
                dispatch(addReportSliceActions.setEmails(e.target.value));
              }}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default Index;
