import React from "react";
import MdEditor from "@uiw/react-md-editor";
import { useSelector } from "react-redux";
import rehypeSanitize from "rehype-sanitize";

import "./MdEditor.css";

const Index = ({ defaultValue, preview, value, onChange }) => {
  let isDarkMode = useSelector((state) => state.general.theme) == "dark";
  return (
    <div
      className="markdown-editor"
      data-color-mode={isDarkMode ? "dark" : "light"}
    >
      <MdEditor
        defaultValue={defaultValue}
        value={value}
        onChange={(latestValue) => {
          onChange(latestValue);
        }}
        preview={preview}
        style={{
          background: isDarkMode ? "#2C394D" : "white",
          color: isDarkMode ? "white" : "black",
          border: isDarkMode && "2px solid rgba(255, 255, 255, 0.2)",
        }}
        previewOptions={{
          rehypePlugins: [[rehypeSanitize]], // to check xss attacks
        }}
      />
    </div>
  );
};

export default Index;
