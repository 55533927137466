import React from "react";
import Skeleton from "@mui/material/Skeleton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

export default function TableLoadingSkeleton({
  columnCount = 6,
  rowCount = 10,
  tableRowSx = null,
  tableRowClassName = null,
  tableCellSx = null,
  tableCellClassName = null,
}) {
  const skeletonRows = Array.from({ length: rowCount }); // Assuming 10 rows
  const skeletonSections = Array.from({ length: columnCount }); // Assuming 6 sections
  //  + 1 is for the first column which is dropdown column

  return (
    <TableBody>
      {skeletonRows.map((_, rowIndex) => (
        <TableRow key={rowIndex} sx={tableRowSx} className={tableRowClassName}>
          {skeletonSections.map((_, sectionIndex) => (
            <TableCell
              key={sectionIndex}
              sx={tableCellSx}
              className={tableCellClassName}
            >
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}
