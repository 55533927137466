import { CamelCase } from "Util/CamelCase";
import ColorDot from "components/ui/buttons/ColorDot";
import StatusButton from "components/ui/buttons/StatusButton";
import { MarkdownText } from "components/imports/MarkdownEditor/CustomMdEdtor";
import { UserImage } from "components/ui/ImageComponent";

export const vulnReportMessage = (commentorName, chat, profileImage) => {
  return (
    <div>
      <div className="font-semibold mb-2 flex items-center">
        {commentorName != "You" && (
          <UserImage
            name={commentorName}
            image={profileImage}
            style={{ marginRight: "5px" }}
          />
        )}
        {`${commentorName} commented in the report : `}
      </div>

      {chat.length > 0 && (
        <div className="mt-1">
          {chat.map((item) => {
            return (
              <div className="mt-1">
                {<MarkdownText data={item?.content} />}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const updateReportMessage = (
  weaknessChat = [],
  severityChat = [],
  parameterChat = [],
  pocChat = [],
  isEngineer = false
) => {
  return (
    <div>
      <div className="font-semibold mb-2">
        {isEngineer
          ? "Updated : "
          : "Update the following instructions in the report ! "}
      </div>

      {weaknessChat.length > 0 && (
        <div className="mt-1">
          <div className="font-semibold">Weakness : </div>
          {weaknessChat.map((item) => {
            return <div>{item?.content}</div>;
          })}
        </div>
      )}
      {severityChat.length > 0 && (
        <div>
          <div className="font-semibold">Severity : </div>
          {severityChat.map((item) => {
            return <div>{item?.content}</div>;
          })}
        </div>
      )}
      {parameterChat.length > 0 && (
        <div>
          <div className="font-semibold">Url and Parameter : </div>
          {parameterChat.map((item) => {
            return <div>{item?.content}</div>;
          })}
        </div>
      )}
      {pocChat.length > 0 && (
        <div>
          <div className="font-semibold">Proof of Concept : </div>
          {pocChat.map((item) => {
            return <div>{item?.content}</div>;
          })}
        </div>
      )}
    </div>
  );
};

export const modifiedReportMessage = (
  whatChanged = "",
  whoChanged = null,
  previous = null,
  latest = null,
  isSuggestion = false,
  profileImage = null,
  customActionText = null,
  content = null
) => {
  return (
    <>
      <div className="flex items-center">
        <span className="font-bold mr-1 flex items-center">
          {whoChanged && whoChanged != "You" && (
            <UserImage
              name={whoChanged || "Someone"}
              image={profileImage}
              style={{ marginRight: "5px" }}
            />
          )}
          {whoChanged || "Someone"}
        </span>{" "}
        {isSuggestion ? "suggested change " : customActionText || "changed "}
        <span className="font-bold mx-1">{whatChanged}</span>{" "}
        {previous && (
          <>
            from{" "}
            <span className="font-bold flex items-center mx-1">
              {whatChanged?.toLowerCase() == "status" && (
                <span className="mr-1">{ColorDot(previous, 3)}</span>
              )}
              {CamelCase(previous)}
            </span>{" "}
          </>
        )}
        to
        <span className="font-bold flex items-center mx-1">
          {whatChanged?.toLowerCase() == "status" && (
            <span className="mr-1">{ColorDot(latest, 3)}</span>
          )}

          {CamelCase(latest)}
        </span>
      </div>

      {content && (
        <div>
          <span className="font-bold mx-1">Comment : </span> {content}
        </div>
      )}
    </>
  );
};
