import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { Divider, Rating } from "@mui/material";

export default function TeamMemberSkeleton({ teamMembersCount = 3 }) {
  const teamMembers = Array.from({ length: teamMembersCount });

  return teamMembers.map((_, index) => (
    <div className="shadow mt-1p bg-white dark:bg-lightBlack dark:text-white rounded overflow-clip">
      <div className="">
        <Grid container>
          <div
            className={
              "flex items-center justify-between w-full bg-lightGreen dark:bg-lightBlack5 px-1p pt-1p"
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Grid>
                <Skeleton
                  variant="circular"
                  width={40}
                  height={40}
                  sx={{ marginRight: "15px" }}
                />
              </Grid>
              <div className="avatar-text" style={{ display: "inline" }}>
                <div style={{ fontSize: "20px" }}>
                  <Skeleton variant="text" width={100} />
                </div>
                <div style={{ fontSize: "18px" }} className="opacity-60">
                  <Skeleton variant="text" width={150} />
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </div>
      <div className="user-blank-strip px-1p pb-[.5%]">
        <Grid container>
          <Grid xs={4}>
            <Skeleton variant="text" />
          </Grid>
          <Grid xs={2}>
            <Skeleton variant="text" />
          </Grid>
          <Grid xs={2}>
            <Skeleton variant="text" />
          </Grid>
          <Grid xs={4}>
            <Skeleton variant="text" />
          </Grid>
        </Grid>
      </div>
      <div style={{ padding: "1% 2%", width: "100%" }}>
        {Array.from({ length: Math.floor(Math.random() * 4) }).map(
          (_, index) => (
            <React.Fragment key={index}>
              <Grid container>
                <Grid xs={4}>
                  <Skeleton variant="text" />
                </Grid>
                <Grid xs={2}>
                  <Skeleton variant="text" />
                </Grid>
                <Grid xs={2}>
                  <Skeleton variant="text" />
                </Grid>
                <Grid xs={4}>
                  <Rating name={`rating-${index}`} value={null} readOnly />
                </Grid>
              </Grid>
            </React.Fragment>
          )
        )}
      </div>
    </div>
  ));
}
