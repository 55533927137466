import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "./Stepper";

import Step1 from "./Step1/Index";
import Step2 from "./Step2/Index";
import Step3 from "./Step3/Index";
import { useDispatch, useSelector } from "react-redux";
import { postFetch, pythonPostFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { addReportSliceActions } from "store/addReportSlice";
import Loader from "components/ui/loaders/Loader";
import { blueGradient1 } from "components/tailwindClasses";
import { editScanEngineSliceActions } from "store/scanner/editScanEngineSlice";

const crawlScopes = {
  "Limit at or below URL hostname(url)": 1,
  "Limit to content located at or below URL subdirectory": 2,
  "Limit to URL hostname and specified sub-domain": 3,
  "Limit to URL hostname and specified domains": 4,
};

function Index({ row, handleClose }) {
  let [activeStep, setActiveStep] = useState(0);
  const [twoFaInput, setTwoFaInput] = useState("");

  const editEngineData = useSelector((state) => state.scanner.editScanEngine);

  const dispatch = useDispatch();

  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    // setActiveStep(() => 0);

    dispatch(editScanEngineSliceActions.setName(row.name));
    dispatch(editScanEngineSliceActions.setScanType(row.scan_type));
    dispatch(editScanEngineSliceActions.setScanSpeed(row.scan_speed));
    dispatch(editScanEngineSliceActions.setThreads(row.threads));

    dispatch(
      editScanEngineSliceActions.setCrawlScope(crawlScopes[row.crawl_scope])
    );
    dispatch(editScanEngineSliceActions.setCrawlDepth(row.crawl_depth));
    dispatch(
      editScanEngineSliceActions.setMaxLinksToCrawl(row.max_links_to_scroll)
    );
    dispatch(editScanEngineSliceActions.setRobotsFile(row.robots_file));
    dispatch(editScanEngineSliceActions.setSitemapFile(row.sitemap_file));
  }, []);

  const handleSubmitClick = async (e) => {
 

    const postData = {
      scan_engine_id : row._id,
      engine_schema: {
        scan_details: { scan_engine_name: editEngineData.step1.name },
        scan_settings: {
          scan_type: editEngineData.step1.scanType,
          scan_speed: editEngineData.step1.scanSpeed,
          threads: editEngineData.step1.threads,
        },
        crawl_settings: {
          max_depth: editEngineData.step2.crawlDepth,
          max_childs: editEngineData.step2.maxLinksToCrawl,
          crawl_scope: editEngineData.step2.crawlScope,
          robots_file: editEngineData.step2.robotsFile,
          sitemap_file: editEngineData.step2.sitemapFile,
        },
      },
      input_token: twoFaInput,
    };


    if(twoFaInput?.length === 6 ){

    const res = await pythonPostFetch("/scanengine/edit", postData);
    setIsloading(() => true);  
    if (res?.status == "ok") {
      toast.success("Engine Created ! ");
      dispatch(editScanEngineSliceActions.reset(""));

      handleClose();
    } else if (res?.error && res?.message) {
      toast.error(res.message);
    } else {
      toast.error("Some error occured, try later!");
    }

    setIsloading(() => false);
  }else{
    toast.error("Please enter 2FA Code before proceeding.");
  }
    // e.target.disabled = false;
  };

  const handleNextClick = () => {
    if (activeStep === 2) {
      handleSubmitClick();

      return;
    }
    const currentStepForm = document.getElementById(
      `step${activeStep + 1}-form`
    );

    if (currentStepForm && currentStepForm.checkValidity()) {
      if (
        activeStep == 0 &&
        editEngineData?.step1.scanType?.toLowerCase?.() == "active"
      ) {
        setActiveStep(() => 1);
      } else {
        activeStep !== 2 && setActiveStep((prevStep) => prevStep + 1);
      }
    } else {
      currentStepForm?.reportValidity?.();
    }
  };

  const handleBackClick = () => {
    if (activeStep == 0) {
      handleClose();
    } else {
      if (editEngineData.step1.scanType == "active" && activeStep == 2) {
        setActiveStep(() => 0);
      } else setActiveStep((activeStep) => activeStep - 1);
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "850px",
        height: "97%",
        // p: "1% 2%",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}
      className="bg-white dark:bg-lightBlack rounded-10 overflow-hidden"
    >
      <div
        style={{
          position: "fixed",
          width: "100%",
          top: "0",
          left: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
          height: "8vh",
          padding: "0 2%",
          zIndex: "10",
          fontSize: "20px",
          fontWeight: "700",
        }}
        className="bg-white dark:bg-lightBlack text-blue1"
      >
        Edit Engine
      </div>

      <div style={{ padding: "8vh 0 10vh 0", position: "relative" }}>
        <div
          style={{
            position: "fixed",
            top: "8vh",
            left: "0",
            width: "20%",
            height: "calc(100% - 8vh)",
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.6)",
          }}
          className="flex  justify-center bg-[#ECECEC] dark:bg-lightBlack2 "
        >
          {/* stepper here */}
          <div className="mt-5">
            <Stepper activeStep={activeStep} />
          </div>
        </div>
        <div
          style={{
            marginLeft: "20%",
            height: "calc(97% - 10% - 8%)",
            width: "calc(100% - 20%)",
          }}
          className="bg-white dark:bg-lightBlack flex-grow"
        >
          {/* make stuff here */}

          {activeStep == 0 && <Step1 row={row} />}
          {activeStep == 1 && <Step2 row={row} />}
          {activeStep == 2 && <Step3 row={row} setTwoFaInput={setTwoFaInput} twoFaInput={twoFaInput}/>}
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: "0",
          left: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          borderTop: "1px solid rgba(0, 0, 0, 0.2)",
          height: "10vh",
          padding: "0 2%",
          zIndex: "10",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        <button
          style={{
            // marginTop: "3.2%",
            textDecoration: "none",
            color: "#2D3B51 !important",
            marginRight: "5vw",
            fontWeight: "500",
          }}
          onClick={handleBackClick}
        >
          {activeStep == 0 && "Cancel"}
          {activeStep != 0 && "Back"}
        </button>
        <button
          style={{ width: "200px", height: "50px" }}
          type="submit"
          onClick={(e) => {
            handleNextClick();
          }}
          // className="w-full flex items-center justify-center text-base font-bold text-white h-15
          //  bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10 disabled:opacity-50 "

          className={
            blueGradient1 +
            " flex items-center justify-center cursor-pointer w-60 rounded-10  font-bold"
          }
        >
          {activeStep != 2 && "Next"}
          {activeStep == 2 && (isLoading ? <Loader /> : "Done")}
        </button>
      </div>
    </Box>
  );
}

export default Index;
