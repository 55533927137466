import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

// facade pattern

let config = {
  headers: { Authorization: "" },
};

// axios.defaults.headers.common["Authorization"] is set;

const makeQueryString = (paramsObj) => {
  return Object.entries(paramsObj).map((param) => {
    return `${param[0]}=${param[1]}`;
  });
};

const bearer_token = window.localStorage.getItem("bearer_token");
axios.defaults.headers.common["Authorization"] = bearer_token;

// for get requests to common api endpoint
export const getFetch = async (url, params = {}, headers = {}) => {
  const queryString = makeQueryString(params);

  // exa of url  : "/", "/login", "/whateber/whater"
  try {
    let response = await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}${url}`,
      method: "GET",
      params: params,
      headers: { ...axios.defaults.headers.common, ...headers },
      withCredentials: true,
    });

    return response.data || null;
  } catch (e) {
    return e?.response?.data || { error: true, message: e?.message };
  }
};

// for post requests to common api endpoint
export const postFetch = async (url, data = {}, params = {}, headers = {}) => {
  const queryString = makeQueryString(params);
  try {
    let response = await axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}${url}`,
      // url: `${process.env.REACT_APP_API_ENDPOINT}${url}?${queryString}`,
      method: "POST",
      data: data,
      params: params,
      headers: { ...axios.defaults.headers.common, ...headers },
      withCredentials: true,
    });

    return response?.data || null;
  } catch (e) {
    return e?.response?.data || { error: true, message: e?.message };
  }
};

// for get requests to django api endpoint

export const pythonGetFetch = async (url, params = {}, headers = {}) => {
  const queryString = makeQueryString(params);

  // exa of url  : "/", "/login", "/whateber/whater"
  try {
    let response = await axios({
      url: `${process.env.REACT_APP_DJANGO_API_ENDPOINT}/api${url}`,
      method: "GET",
      params: params,
      headers: { ...axios.defaults.headers.common, ...headers },

      headers: {
        Authorization: window.localStorage.getItem("bearer_token"),
        ...headers,
      },
      withCredentials: true,
    });
    if (response.data.notAuth) {
      window.location.href = "/login";
    } else return response.data || null;
  } catch (e) {
    return e?.response?.data || { error: true, message: e?.message };
  }
};

// for post requests to django api endpoint

export const pythonPostFetch = async (
  url,
  data = {},
  params = {},
  headers = {}
) => {
  const queryString = makeQueryString(params);
  try {
    let response = await axios({
      url: `${process.env.REACT_APP_DJANGO_API_ENDPOINT}/api${url}`,
      method: "POST",
      data: data,
      params: params,
      headers: { ...axios.defaults.headers.common, ...headers },
      headers: {
        Authorization: window.localStorage.getItem("bearer_token"),
        ...headers,
      },

      withCredentials: true,
    });

    if (response.data.notAuth) {
      window.location.href = "/login";
    } else return response?.data || null;
  } catch (e) {
    return e?.response?.data || { error: true, message: e?.message };
  }
};


// ========================================= [ LOcal Fetch ] 

export const localPostFetch = async (
  url,
  data = {},
  params = {},
  headers = {}
) => {
  const queryString = makeQueryString(params);
  try {
    let response = await axios({
        url: `http://localhost:5002/api${url}`,
      method: "POST",
      data: data,
      params: params,
      headers: { ...axios.defaults.headers.common, ...headers },
      headers: {
        Authorization: window.localStorage.getItem("bearer_token"),
        ...headers,
      },

      withCredentials: true,
    });

    if (response.data.notAuth) {
      window.location.href = "/login";
    } else return response?.data || null;
  } catch (e) {
    return e?.response?.data || { error: true, message: e?.message };
  }
};



