import React, { useState, useEffect } from "react";
import { Select, MenuItem } from "@mui/material";
import { getFetch } from "lib/fetch";
import Popup from "reactjs-popup";
import { useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import addVulnerabilityReportSlice, {
  addVulnerabilityReportSliceActions,
} from "store/vulnerabilityReport";

import { cvssStringToObject } from "lib/cvss";
import { input1 } from "components/tailwindClasses";

function Index(props) {
  const dispatch = useDispatch();

  const reportViewType = useSelector(
    (state) => state.addVulnerabilityReport.reportViewType
  );

  const [search, setSearch] = useState("");
  const [searching, setSearching] = useState(false);

  // for delay b/w search change
  const [timer, setTimer] = useState(null);

  let innerRef = useRef();
  let innerRef2 = useRef();
  let outerRef = useRef();

  const existingData = useSelector((state) => state.addVulnerabilityReport);

  const [data, setData] = useState([]);

  const [filterData, setFilteredData] = useState([]);

  useEffect(() => {
    let getData = async () => {
      // if (reportViewType == "update") {
      //   return;
      // }
      const res = await getFetch("/bug/getCategories");
      if (res.status == "ok") {
        setData(() => res?.category_data?.content?.children);
      }
    };
    getData();
  }, []);

  const getfilterData = (curSearch) => {
    let filData = [];

    data.forEach((parent) => {
      if (parent?.name?.toLowerCase()?.includes(curSearch)) {
        filData.push({ parent: { name: parent.name, id: parent.id } });
      }
      parent?.children?.forEach((child1) => {
        if (child1?.name?.toLowerCase()?.includes(curSearch)) {
          filData.push({
            parent: { name: parent.name, id: parent.id },
            child1: { name: child1.name, id: child1.id },
          });
        }
        child1?.children?.forEach((child2) => {
          if (child2?.name?.toLowerCase()?.includes(curSearch)) {
            filData.push({
              parent: { name: parent.name, id: parent.id },
              child1: { name: child1.name, id: child1.id },
              child2: { name: child2.name, id: child2.id },
            });
          }
        });
      });
    });

    setFilteredData(() => filData);
  };

  const handleSearchChange = (e) => {
    //valiue gets updated later, so use current value
    const curSearch = e.target.value;

    setSearch(() => curSearch?.toLowerCase());

    // adding delay
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      getfilterData(curSearch);
    }, 500);
    setTimer(newTimer);
  };

  const handleVulnChange = async (vuln, parent, child1, child2) => {
    // console.log("CHILD : ", child);

    // vuln is the level selected, could be parent, child1 or child2
    // level 0 : parent, 1 : child1, 2 : child2

    const curWeaknessChain = { parent: { name: parent.name, id: parent.id } };
    dispatch(
      addVulnerabilityReportSliceActions.setParent(curWeaknessChain.parent)
    );
    if (child1) {
      curWeaknessChain.child1 = { name: child1.name, id: child1.id };
      dispatch(
        addVulnerabilityReportSliceActions.setChild1(curWeaknessChain.child1)
      );
    } else {
      dispatch(
        addVulnerabilityReportSliceActions.setChild1({ name: "", id: "" })
      );
    }
    if (child2) {
      curWeaknessChain.child2 = { name: child2.name, id: child2.id };
      dispatch(
        addVulnerabilityReportSliceActions.setChild2(curWeaknessChain.child2)
      );
    } else {
      dispatch(
        addVulnerabilityReportSliceActions.setChild2({ name: "", id: "" })
      );
    }

    outerRef.current.close();
    setSearching(() => false);

    // there has to be atleast parent level
    if (vuln?.id == "") return;

    const res = await getFetch("/bug/getMappedData", { id: vuln.id });

    if (res.status == "ok") {
      const data = res.mapped_data;

      if (data == null) {
        dispatch(addVulnerabilityReportSliceActions.reset(""));
      }

      // weakness
      try {
        dispatch(addVulnerabilityReportSliceActions.setName(data?.name));
        dispatch(addVulnerabilityReportSliceActions.setCwe(data?.cwe));
        dispatch(addVulnerabilityReportSliceActions.setType(data?.name));
      } catch (e) {
        console.log(e);
      }

      // severity

      try {
        const cvssString = data?.cvss_v3;
        const cvssObj = cvssStringToObject(cvssString);

        // dispatch(cvssCalculatorSliceActions.setCvssObject(cvssObj));
        dispatch(addVulnerabilityReportSliceActions.setCvssObject(cvssObj));

        // console.log(cvssObj);
      } catch (e) {
        console.log(e);
      }

      // proof of concept

      try {
        const desTemplate = data?.template;
        const remediation = data?.remediation_advice;
        const references = data?.references;
        const impact = data?.impact;

        dispatch(
          addVulnerabilityReportSliceActions.setDescription(desTemplate)
        );
        dispatch(
          addVulnerabilityReportSliceActions.setRemediation(remediation)
        );
        dispatch(addVulnerabilityReportSliceActions.setImpact(impact));
        references?.length > 0 &&
          dispatch(
            addVulnerabilityReportSliceActions.setReferences(
              references.join(",")
            )
          );
      } catch (e) {
        console.log(e);
      }
    }
  };

  const isDarkMode = useSelector((state) => state.general.theme) == "dark";

  return (
    <div className="mt-2p">
      <Popup
        ref={outerRef}
        trigger={
          <div>
            <MenuItem
              disableRipple
              sx={{
                borderRadius: "4px",
                height: "60px",
                padding: "0px",
                bgcolor: isDarkMode ? "#3D495B" : "rgba(0, 0, 0, 0.08)",
                ":hover": {
                  bgcolor: isDarkMode ? "#3D495B" : "rgba(0, 0, 0, 0.08)",
                },
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: isDarkMode
                  ? "rgba(255, 255, 255, 0.2)"
                  : "rgba(0, 0, 0, 0.15)",
              }}
              className={"relative  "}
              onClick={() => {
                setSearching(() => true);
              }}
              // onBlur={() => {
              //   setSearching(() => false);
              // }}
            >
              {searching == false && (
                <div className="p-1p ">
                  {" "}
                  {existingData.parent.name
                    ? existingData.parent.name
                    : "Select weakness"}
                  {existingData?.child1?.name && (
                    <span>
                      <img
                        src={
                          isDarkMode ? "/leftArrowDark.svg" : "/leftArrow.svg"
                        }
                        alt=""
                        className="rotate-180 inline mx-4 h-4"
                      />
                      {existingData?.child1?.name}
                    </span>
                  )}
                  {existingData?.child2?.name && (
                    <span>
                      <img
                        src={
                          isDarkMode ? "/leftArrowDark.svg" : "/leftArrow.svg"
                        }
                        alt=""
                        className="rotate-180 inline mx-4 h-4"
                      />
                      {existingData?.child2?.name}
                    </span>
                  )}
                </div>
              )}
              {searching == true && (
                <input
                  // disabled={reportViewType == "update" ? true : false}
                  type="text"
                  className={input1 + " border-none bg-transparent"}
                  value={search}
                  onChange={handleSearchChange}
                  placeholder="Search weakness"
                  autoFocus
                />
              )}
            </MenuItem>
          </div>
        }
        // position="left top"
        nested
      >
        {search == "" && (
          <div className="overflow-y-scroll max-h-96 shadow-md absolute top-0 right-0 -translate-x-1/2">
            {data.map((parent) => {
              return (
                <div className="bg-white dark:bg-inputBgDark  dark:text-white rounded p-2p shadow-md ">
                  <Popup
                    ref={innerRef}
                    trigger={
                      <MenuItem
                        data-id={parent.id}
                        onClick={(e) => {
                          handleVulnChange(parent, parent);
                        }}
                      >
                        {parent.name}
                      </MenuItem>
                    }
                    position="right center"
                    on={"hover"}
                    nested
                  >
                    <div className="bg-white dark:bg-inputBgDark  dark:text-white rounded p-2p shadow-md overflow-y-scroll max-h-96">
                      {parent.children &&
                        parent.children.map((child1) => {
                          return (
                            <Popup
                              ref={innerRef2}
                              trigger={
                                <MenuItem
                                  onClick={(e) => {
                                    handleVulnChange(child1, parent, child1);
                                  }}
                                  data-id={child1.id}
                                >
                                  {child1.name}
                                </MenuItem>
                              }
                              position="right center"
                              on={"hover"}
                              nested
                            >
                              <div className="bg-white dark:bg-inputBgDark  dark:text-white rounded p-2p shadow-md overflow-y-scroll max-h-96">
                                {child1.children &&
                                  child1.children.map((child2) => {
                                    return (
                                      <MenuItem
                                        onClick={(e) => {
                                          handleVulnChange(
                                            child2,
                                            parent,
                                            child1,
                                            child2
                                          );
                                        }}
                                        data-id={child2.id}
                                      >
                                        {child2.name}
                                      </MenuItem>
                                    );
                                  })}
                              </div>
                            </Popup>
                          );
                        })}
                    </div>
                  </Popup>
                </div>
              );
            })}
          </div>
        )}
        {search != "" && (
          <div className="overflow-y-scroll max-h-96 shadow-md absolute top-0 left-0 bg-white rounded p-2p -translate-x-1/2">
            <div>
              {filterData.map((item) => {
                const parent = item?.parent;
                const child1 = item?.child1;
                const child2 = item?.child2;
                return (
                  <MenuItem
                    onClick={() => {
                      handleVulnChange(
                        child2 || child1 || parent,
                        parent,
                        child1,
                        child2
                      );
                    }}
                  >
                    {parent?.name}

                    {child1 && (
                      <span>
                        <img
                          src={
                            isDarkMode ? "/leftArrowDark.svg" : "/leftArrow.svg"
                          }
                          alt=""
                          className="rotate-180 inline mx-4 h-4"
                        />
                        {child1.name}
                      </span>
                    )}
                    {child2 && (
                      <span>
                        <img
                          src={
                            isDarkMode ? "/leftArrowDark.svg" : "/leftArrow.svg"
                          }
                          alt=""
                          className="rotate-180 inline mx-4 h-4"
                        />
                        {child2?.name}
                      </span>
                    )}
                  </MenuItem>
                );
              })}
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default Index;
