import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Avatar, Box } from "@mui/material";

import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link, useLocation, useNavigate } from "react-router-dom";

import SeverityButton from "components/ui/buttons/SeverityButton";
import StatusButton from "components/ui/buttons/StatusButton";
import GreyBgButton from "components/ui/buttons/GreyBgButton";
import { Checkbox } from "@mui/material";
import ColorDot from "components/ui/buttons/ColorDot";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";

import Chat from "./Chat/Index";
import DuplicateTable from "./DuplicateTable";
import { cvssStringToObject } from "lib/cvss";
import Columns from "./Columns";
import { getFetch, postFetch } from "lib/fetch";
import { addVulnerabilityReportSliceActions } from "store/vulnerabilityReport";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";
import { toast } from "react-toastify";
import { ArrowDownward } from "@mui/icons-material";
import { GridArrowDownwardIcon, GridArrowUpwardIcon } from "@mui/x-data-grid";
import { UserImage } from "components/ui/ImageComponent";
import TableLoadingSkeleton from "components/ui/loaders/TableSkeleton";

function CheckBoxHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  return (
    <TableCell
      padding="checkbox"
      style={{
        background: "rgba(217, 217, 217, 0.25)",
      }}
      align="left"
    >
      <Checkbox
        color="primary"
        indeterminate={numSelected > 0 && numSelected < rowCount}
        checked={rowCount > 0 && numSelected === rowCount}
        onChange={onSelectAllClick}
        inputProps={{
          "aria-label": "select all desserts",
        }}
      />
    </TableCell>
  );
}

function SelectTable({
  data,
  isDuplicateTable,
  isAllTable,
  currentOption,
  isLoading,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc"); // or "desc"

  const renderSortIcon = () => {
    return sortDirection === "desc" ? (
      <GridArrowDownwardIcon fontSize="small" />
    ) : (
      <GridArrowUpwardIcon fontSize="small" />
    );
  };
  const handleSort = (columnId) => {
    if (sortColumn === columnId) {
      // If the same column is clicked again, toggle the sort direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If a new column is clicked, set it as the sort column and default to ascending order
      setSortColumn(columnId);
      setSortDirection("asc");
    }
  };

  const columns = Columns(user.role, currentOption);

  const isDarkMode = useSelector((state) => state.general.theme);

  let groupOfRows = [];

  // Create a separate sortedData array
  const sortedData = [...data].sort((a, b) => {
    if (sortColumn) {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      if (sortDirection === "asc") {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    }
    return 0;
  });

  const location = useLocation();
  const incomingState = location?.state;

  // State to keep track of opened chat popups
  const [isChatOpen, setIsChatOpen] = useState([]);
  // Open chat popup if openChat = true and bugId exists

  const [bugIdToOpenChat, setBugIdToOpenChat] = useState(null);

  // Effect to handle incoming state and set bugIdToOpenChat
  useEffect(() => {
    // Check if incomingState has openChat = true and bugId exists
    if (incomingState?.openChat && incomingState?.bugId) {
      const bugId = incomingState.bugId;
      // Find the index of the bug with the specified bugId in the sortedData
      const bug = sortedData.find((item) => item?.id === bugId);

      // If the bug is found, open the chat popup
      if (bug) {
        setBugIdToOpenChat(() => bug?.id);
      } else {
        setIsChatOpen(() => []);
      }
    }
  }, [incomingState, sortedData]);

  useEffect(() => {
    if (bugIdToOpenChat != null) {
      setIsChatOpen(() => bugIdToOpenChat);

      incomingState.openChat = false;
      incomingState.bugId = null;
    }
  }, [bugIdToOpenChat]);

  // all bugs
  const allBugs = useSelector((state) => state.targetDashboard.bugs);

  const markChatRead = async (bugId, hasNewChat) => {
    if (!hasNewChat) return;

    const toSet = allBugs.map((item) => {
      let newItem = { ...item };
      if (String(newItem.id) == String(bugId)) {
        newItem.hasNewChat = false;
      }
      return newItem;
    });

    dispatch(targetDashboardSliceActions.setBugs(toSet));
  };

  const rows = sortedData?.map((item, index) => {
    return {
      name: item.id,
      status: (
        <>
          <span className="mr-2">
            <ColorDot type={item.status} />
          </span>
          <span className="inline">{item.status}</span>
        </>
      ),
      type: item.type,
      title: <span className="text-blue1 ">{item?.title}</span>,
      severity: SeverityButton(item.severity),
      cvssScore: item.cvssScore,
      cve:
        item?.cve?.length > 1 ? (
          <Popup
            trigger={
              <div>
                <GreyBgButton>Multiple</GreyBgButton>
              </div>
            }
            position={["left bottom"]}
            on="hover"
            closeOnDocumentClick
          >
            <div className="rounded shadow p-2p bg-white flex">
              {item.cve.map((cveItem, i) => {
                return (
                  <div className={i != item.cve.length - 1 ? "mr-1" : "mr-4"}>
                    <GreyBgButton>{cveItem}</GreyBgButton>
                  </div>
                );
              })}
            </div>
          </Popup>
        ) : item?.cve?.length == 1 ? (
          <GreyBgButton>{item.cve}</GreyBgButton>
        ) : (
          <GreyBgButton>None</GreyBgButton>
        ),
      researcher: (
        <UserImage
          image={item?.researcher?.profileImage}
          name={item?.researcher?.name}
          email={item?.researcher?.email}
        />
      ),
      createdOn: new Date(item.createdOn).toDateString(),
      hasNewChat: currentOption != "Drafts" &&
        item?.status?.toLowerCase() != "draft" && (
          <Popup
            open={isChatOpen == item?.id}
            onOpen={() => {
              markChatRead(item?.id, item?.hasNewChat);
            }}
            trigger={
              <div>
                <div>
                  <img
                    src={
                      item?.hasNewChat
                        ? isDarkMode
                          ? "/chatYesDark.svg"
                          : "/chatYes.svg"
                        : isDarkMode
                        ? "/chatDark.svg"
                        : "/chat.svg"
                    }
                    className="inline w-6"
                    alt=""
                  />
                </div>
              </div>
            }
            modal
            overlayStyle={{ background: "rgba(0,0,0,0.5)" }}
            lockScroll
            nested
            closeOnDocumentClick
          >
            {(closeChat) => {
              return (
                <div className="fixed right-0 bottom-0">
                  <Chat data={item} closeChat={closeChat} />
                </div>
              );
            }}
          </Popup>
        ),

      statusProp: item?.status,
    };
  });

  // pagination of table
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // < ----------------------handling selection of rows-------------------->

  // array of selected rows

  const selectedBugs = useSelector(
    (state) => state.targetDashboard.selectedBugs
  );

  // to check if option is selcted
  const isSelected = (name) => selectedBugs.indexOf(name) !== -1;

  // handle click of checkbox in table head
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      dispatch(targetDashboardSliceActions.setSelectedBugs(newSelected));
      return;
    }
    dispatch(targetDashboardSliceActions.setSelectedBugs([]));
  };

  // handle click of row
  const handleCheckClick = (event, name) => {
    event.stopPropagation();
    const selectedIndex = selectedBugs.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedBugs, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedBugs.slice(1));
    } else if (selectedIndex === selectedBugs.length - 1) {
      newSelected = newSelected.concat(selectedBugs.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedBugs.slice(0, selectedIndex),
        selectedBugs.slice(selectedIndex + 1)
      );
    }

    dispatch(targetDashboardSliceActions.setSelectedBugs(newSelected));
  };

  // handle when the bug is clicked
  const handleBugClick = async (e, id, status) => {
    dispatch(addVulnerabilityReportSliceActions.reset());
    dispatch(addVulnerabilityReportSliceActions.setId(id));

    const reportType =
      user.role == "S_MANAGER"
        ? "update"
        : status == "Draft"
        ? "draft"
        : "edit";

    dispatch(addVulnerabilityReportSliceActions.setReportViewType(reportType));
    navigate("/bugReport", { state: { fromApp: true } });
  };

  let navigate = useNavigate();

  let handleCompareClick = () => {
    // this is for duplicate , handle later
    navigate("/compareReports");
  };

  const targetId = useSelector(
    (state) => state.targetDashboard.currentTarget.id
  );

  let getSimilarDuplicates = async () => {
    let simBugs = await getFetch("/bug/getDuplicateSuggestions", {
      targetId,
    });
    setSimilarBugs(() => simBugs);
  };
  const [similarBugs, setSimilarBugs] = useState([]);
  useEffect(() => {
    isDuplicateTable && getSimilarDuplicates();
  }, [isDuplicateTable]);

  //

  return (
    <div className="border-t">
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {user.role == "S_MANAGER" && (
                <CheckBoxHead
                  numSelected={selectedBugs.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rows.length}
                />
              )}

              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontWeight: "500",
                    // padding: "1% 2%",
                    cursor: "pointer", // Add cursor pointer
                    backgroundColor: "rgba(217, 217, 217, 0.25)",
                  }}
                  className=" dark:bg-lightBlack3 dark:text-white"
                  onClick={() => {
                    handleSort(column.id);
                  }}
                >
                  {column.label} {sortColumn === column.id && renderSortIcon()}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading && allBugs?.length == 0 ? (
            <TableLoadingSkeleton
              rowCount={10}
              columnCount={
                user?.role == "S_MANAGER"
                  ? columns?.length + 1
                  : columns?.length
              }
            />
          ) : (
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      // onClick={(event) => {
                      //   handleBugClick(event, row.name);
                      // }}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      {user.role == "S_MANAGER" && (
                        <TableCell
                          padding="checkbox"
                          onClick={(event) => {
                            handleCheckClick(event, row.name);
                          }}
                        >
                          <Checkbox
                            color={"primary"}
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                      )}
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              // padding: "1% 2%",
                              cursor:
                                column.id == "title" ||
                                column.id == "hasNewChat"
                                  ? "pointer"
                                  : "default",
                            }}
                            onClick={(event) => {
                              column.id == "title" &&
                                handleBugClick(event, row.name, row.statusProp);
                            }}
                          >
                            {column.format && typeof value === "number" ? (
                              <p className="dark:text-white">
                                {column.format(value)}
                              </p>
                            ) : (
                              <p className="dark:text-white"> {value}</p>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[2, 5, 10, 25, 100]}
        component="div"
        count={isDuplicateTable ? groupOfRows.length : rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="dark:bg-lightBlack dark:text-white"
      />
    </div>
  );
}

export default SelectTable;
