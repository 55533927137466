import React, { useState } from "react";
import Menu, { MenuItem, Select, Avatar } from "@mui/material";
import SecondaryButton from "components/ui/buttons/SecondaryButton";
import { FormControlLabel, FormControl, InputLabel } from "@mui/material";

function FilterDropdown(props) {
  const [option, setOption] = useState("");
  const handleChange = (event) => {
    setOption(event.target.value);
  };

  let options = ["Status", "Last Scanned"];

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <Select
        value={option}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          width: "100%",
          backgroundColor: "#D9D9D940",
          border: "0px solid rgba(0, 0, 0, 0.25)",
          borderRadius: "4px",
          height: "36px",
        }}
        className="dark:bg-lightBlack3 dark:text-white"
      >
        <MenuItem value="">
          <em>Filter</em>
        </MenuItem>
        {options.map((item, i) => {
          return <MenuItem value={i}>{item}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
}

export default FilterDropdown;
