import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step1: {
    name: null,
    scanType: null,
    scanSpeed: null,
    threads: null,
  },

  step2: {
    crawlScope: null,
    crawlDepth: null,
    maxLinksToCrawl: null,
    robotsFile: null,
    sitemapFile: null,
  },
};

let editScanEngineSlice = createSlice({
  name: "editScanEngineSlice",
  initialState: initialState,
  reducers: {
    setName(state, action) {
      state.step1.name = action.payload;
    },
    setScanType(state, action) {
      state.step1.scanType = action.payload;
    },
    setScanSpeed(state, action) {
      state.step1.scanSpeed = action.payload;
    },
    setThreads(state, action) {
      state.step1.threads = action.payload;
    },

    setCrawlScope(state, action) {
      state.step2.crawlScope = action.payload;
    },
    setCrawlDepth(state, action) {
      state.step2.crawlDepth = action.payload;
    },
    setMaxLinksToCrawl(state, action) {
      state.step2.maxLinksToCrawl = action.payload;
    },
    setRobotsFile(state, action) {
      state.step2.robotsFile = action.payload;
    },
    setSitemapFile(state, action) {
      state.step2.sitemapFile = action.payload;
    },

    resetStep2(state) {
      state.step2 = initialState.step2;
    },

    reset(state) {
      return initialState;
    },
  },
});

export const editScanEngineSliceActions = editScanEngineSlice.actions;

export default editScanEngineSlice;
