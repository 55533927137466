import React from "react";
import ChartBox from "./Chartbox/Index";

import Charts from "./Charts/Index";
import { useSelector } from "react-redux";

function Index({ currentSection }) {
  const user = useSelector((state) => state.user.data);

  return (
    <div>
      <div style={{ marginBottom: "1%" }}>
        <ChartBox />
      </div>
      <div style={{ marginBottom: "1%" }}>
        <Charts />
      </div>
    </div>
  );
}

export default Index;
