import React, { useState } from "react";
import RegistrantTable from "./RegistrantTable";
import AdminTable from "./AdminTable";
import TechnicalTable from "./TechnicalTable";
import { useSelector } from "react-redux";

let optionStyle = {
  display: "flex",
  justifyContent: "center",
  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  padding: "1% 2%",
  width: "50%",
  cursor: "pointer",
};
let optionStyleSelect = {
  ...optionStyle,
  fontWeight: "700",
  borderBottom: " 4px solid #539487",
};

function Index(props) {
  let [activeOption, setActiveOption] = useState(0);

  const dataObj = useSelector(
    (state) => state?.targetDashboard?.home?.domainInfo
  );

  const data = dataObj?.dig_data;

  let options = ["A", "AAAA", "MX Records", "SOA", "TXT"];

  let dataToShow;

  if (activeOption == 0) {
    dataToShow = data?.A;
  } else if (activeOption == 1) {
    dataToShow = data?.AAAA;
  } else if (activeOption == 2) {
    dataToShow = data?.MX;
  } else if (activeOption == 3) {
    dataToShow = data?.SOA;
  } else if (activeOption == 4) {
    dataToShow = data?.TXT;
  }

  return (
    <div style={{ height: "380px", overflowY: "scroll" }}>
      <div style={{ display: "flex" }}>
        {options.map((item, i) => {
          let optionStyle = {
            display: "flex",
            justifyContent: "center",
            padding: "1% 2%",
            width: "50%",
            cursor: "pointer",
          };
          return (
            <div
              onClick={() => {
                setActiveOption(() => i);
              }}
              style={
                activeOption == i
                  ? {
                      ...optionStyle,
                      fontWeight: "700",
                      borderBottom: " 4px solid #4B65ED",
                      color: "#4B65ED",
                    }
                  : optionStyle
              }
              className="border-b border-b3 dark:border-b9"
            >
              {item}
            </div>
          );
        })}
      </div>
      <div>
        {(activeOption === 0 || activeOption === 1) && (
          <>
            {dataToShow.map((item, index) => {
              return (
                <div>
                  <div
                    className={` ${
                      index % 2 === 0 ? "bg-[#F4F5F7]" : ""
                    }   min-h-[60px] flex items-center p-2p`}
                  >
                    {item}
                  </div>
                </div>
              );
            })}
          </>
        )}

        {activeOption === 2 && (
          <>
            <div>
              <div
                className={`min-h-[60px]  items-center p-2p flex justify-evenly`}
              >
                <p className="font-bold flex-1">Address</p>
                <p className="font-bold flex-1">Priority</p>
              </div>
            </div>
            {dataToShow.map((item, index) => {
              return (
                <div>
                  <div
                    className={` ${
                      index % 2 === 0 ? "bg-[#F4F5F7]" : ""
                    }   min-h-[60px] flex items-center p-2p justify-evenly`}
                  >
                    <p className="font-medium flex-1">{item?.address}</p>
                    <p className=" flex-1">{item?.priority}</p>
                  </div>
                </div>
              );
            })}
          </>
        )}

        {activeOption === 3 && (
          <>
            <div>
              <div
                className={`min-h-[60px]  items-center p-2p flex justify-evenly`}
              >
                <p className="font-bold flex-1">Primary NS</p>
                <p className="font-bold flex-1">Responsible Party Email</p>
              </div>
            </div>
            {dataToShow.map((item, index) => {
              return (
                <div>
                  <div
                    className={` ${
                      index % 2 === 0 ? "bg-[#F4F5F7]" : ""
                    }   min-h-[60px] flex items-center p-2p justify-evenly`}
                  >
                    <p className="font-medium flex-1">{item?.primary_ns}</p>
                    <p className=" flex-1">{item?.responsible_party_email}</p>
                  </div>
                </div>
              );
            })}
          </>
        )}

        {activeOption === 4 && (
          <>
            {dataToShow.map((item, index) => {
              // const listdata = item?.split("=");
              return (
                <>
                  <div
                    className={` ${
                      index % 2 === 0 ? "bg-[#F4F5F7]" : ""
                    }   min-h-[60px] flex items-center p-2p justify-evenly`}
                  >
                    
                    <p className="font-medium flex-1">{item}</p>
                    {/* <p className=" flex-1">{listdata[1]}</p> */}
                  </div>
                </>
              );
            })}
          </>
        )}

        {/* {dataToShow?.map?.((i, index) => {
          return index % 2 == 1 ? (
            <div className="min-h-[60px] flex items-center p-2p">
              <div className="w-1/3 px-1p break-words">{i?.[0]}</div>
              <div className="w-1/3 px-1p break-words">{i?.[1]}</div>
              <div className="w-1/3 px-1p break-words">{i?.[2]}</div>
            </div>
          ) : (
            <div className="bg-[#F4F5F7] dark:bg-lightBlack6 min-h-[60px] flex items-center p-2p ">
              <div className="w-1/3 px-1p  break-words">{i?.[0]}</div>
              <div className="w-1/3 px-1p break-words">{i?.[1]}</div>
              <div className="w-1/3 px-1p break-words">{i?.[2]}</div>
            </div>
          );
        })} */}

        {/* {activeOption == 0 && <RegistrantTable />} */}
        {/* {activeOption == 1 && <AdminTable />} */}
        {/* {activeOption == 1 && <TechnicalTable />} */}
      </div>
    </div>
  );
}

export default Index;
