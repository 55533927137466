import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // step1
  assets: [], // keep id and name
  currentAsset: {}, // keep all info

  //step2
  reportType: null,

  //step3
  reportFormat: null,
  secure: null,
  password: null,
  confirmPassword: null,
  emails: [],
};

let addReportSlice = createSlice({
  name: "addReportSlice",
  initialState: initialState,
  reducers: {
    setAssets(state, action) {
      state.assets = action.payload;
    },
    setCurrentAsset(state, action) {
      state.currentAsset = action.payload;
    },
    setReportType(state, action) {
      state.reportType = action.payload;
    },
    setReportFormat(state, action) {
      state.reportFormat = action.payload;
    },
    setSecure(state, action) {
      state.secure = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
    setConfirmPassword(state, action) {
      state.confirmPassword = action.payload;
    },
    setEmails(state, action) {
      state.emails = action.payload;
    },
    reset(state, action) {
      state = initialState;
    },
  },
});

export const addReportSliceActions = addReportSlice.actions;

export default addReportSlice;
