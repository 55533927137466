import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";

export default function NotificationLoadingSkeleton({ rowCount = 5 }) {
  return Array.from({ length: rowCount }).map(() => (
    <div className="flex items-center mb-1p">
      <Skeleton
        variant="circular"
        width={40}
        height={40}
        className="mr-2 w-10"
      />
      <div className="flex flex-col grow">
        {Array.from({ length: Math.floor(Math.random() * 3) + 1 }).map(() => (
          <div className="">
            <Skeleton
              variant="text"
              width={`${Math.floor(Math.random() * 80 + 20)}%`}
              height={20}
            />
          </div>
        ))}
      </div>
    </div>
  ));
}
