import React, { useState } from "react";
import { Box, MenuItem, Slider } from "@mui/material";
import { Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addReportSliceActions } from "store/addReportSlice";
import { useEffect } from "react";
import { addScanEngineSliceActions } from "store/scanner/addScanEngineSlice";
import { input1 } from "components/tailwindClasses";
import { addScanProfileSliceActions } from "store/scanner/addScanProfileSlice";
import { Link } from "react-router-dom";
import { pythonGetFetch, pythonPostFetch } from "lib/fetch";
import { toast } from "react-toastify";
import Loader from "components/ui/loaders/Loader";

export const crawlScopes = [
  {
    id: 1,
    label: "Limit at or below URL hostname(url)",
  },
  {
    id: 2,
    label: "Limit to content located at or below URL subdirectory",
  },
  {
    id: 3,
    label: "Limit to URL hostname and specified sub-domain",
  },
  {
    id: 4,
    label: "Limit to URL hostname and specified domains",
  },
];
export const robotsFiles = [
  { id: 1, label: "Do not use robots.txt" },
  {
    id: 2,
    label: "Crawl all links and directories found in robots.txt, if present",
  },
  {
    id: 3,
    label:
      "Do not crawl links or directories excluded by robots.txt, if present",
  },
];

export const sitemapFiles = [
  { id: 1, label: "Do not use sitemap.xml" },
  {
    id: 2,
    label: "Crawl all links and directories found in sitemap.xml, if present",
  },
];

export const depths = [
  { value: -5, label: "-5" },
  { value: -4, label: "-4" },
  { value: -3, label: "-3" },
  { value: -1, label: "-1" },
  { value: 0, label: "0 (unlimited)" },
];

function Index(props) {
  const dispatch = useDispatch();

  const engineName = useSelector(
    (state) => state.scanner.addScanProfile.step2.engineName
  );
  const engineData = useSelector((state) => state.scanner.addScanProfile.step2);
  const engines = useSelector(
    (state) => state.scanner.addScanProfile.step2.engines
  );

  const [isLoadingEngines, setIsLoadingEngines] = useState(false);

  useEffect(() => {
    // get scan engines

  

    const getEngines = async () => {
      setIsLoadingEngines(() => true);
      const res = await pythonGetFetch("/scanprofile/scan_engine_list");
      if (res?.status == "ok") {
        dispatch(addScanProfileSliceActions.setEngines(res?.data));
        setIsLoadingEngines(() => false);
      } else if (res?.error && res?.message) {
        toast.error(res.message);
        setIsLoadingEngines(() => false);
      } else {
        toast.error("could not fetch engines, try later!");
        setIsLoadingEngines(() => false);
      }
    };

  

    getEngines();
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const onEngineChange = async (e) => {
    dispatch(addScanProfileSliceActions.setEngineName(e.target.value));

    setIsLoading(() => true);
    const res = await pythonPostFetch("/scanprofile/scanengine", {
      scan_engine_name: e.target.value,
    });

    if (res?.status == "ok") {
      dispatch(
        addScanProfileSliceActions.setEngineData({
          scanType: res?.data?.scan_settings?.scan_type,
          scanSpeed: res?.data?.scan_settings?.scan_speed,
          threads: res?.data?.scan_settings?.threads,
          engineId: res?.data?.id,
          crawlScope: res?.data?.crawl_settings?.crawl_scope,
          crawlDepth: res?.data?.crawl_settings?.max_depth,
          maxLinksToCrawl: res?.data?.crawl_settings?.max_childs,
          robotsFile: res?.data?.crawl_settings?.robots_file,
          sitemapFile: res?.data?.crawl_settings?.sitemap_file,
        })
      );
    } else if (res?.error && res?.message) {
      toast.error(res.message);

      dispatch(
        addScanProfileSliceActions.setEngineData({
          scanType: null,
          scanSpeed: null,
          threads: null,

          crawlScope: null,
          crawlDepth: null,
          maxLinksToCrawl: null,
          robotsFile: null,
          sitemapFile: null,
        })
      );
    } else {
      toast.error("could not fetch engine details, try later!");

      dispatch(
        addScanProfileSliceActions.setEngineData({
          scanType: null,
          scanSpeed: null,
          threads: null,

          crawlScope: null,
          crawlDepth: null,
          maxLinksToCrawl: null,
          robotsFile: null,
          sitemapFile: null,
        })
      );
    }

    setIsLoading(() => false);
  };

  return (
    <form id="step2-form">
      <div
        style={{
          padding: "2%",
          paddingBottom: "3%",
          borderRadius: "10px",
          marginBottom: "1%",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        <div className="flex justify-between items-center">
          <div className="avatar-text ">Select Engine</div>
          <Link aria-disabled className="text-sm">
            Add New Engine
          </Link>
        </div>

        <div>
          <Select
            sx={{
              width: "100%",
              boxSizing: "border-box",
              height: "45px",
              backgroundColor: "#D9D9D940",
              border: "0px solid rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
            }}
            className="dark:text-white bg-inputBg dark:bg-inputBgDark"
            // required
            value={engineName}
            onChange={onEngineChange}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            MenuProps={{
              PaperProps: { sx: { maxHeight: 400 } },
            }}
          >
            <MenuItem value="">
              <em>Select </em>
            </MenuItem>

            {isLoadingEngines ? (
              <Loader />
            ) : (
              engines?.map((e, i) => {
                return <MenuItem value={e.name}>{e.name}</MenuItem>;
              })
            )}
          </Select>
        </div>
        {isLoading ? (
          <div className="p-3p">
            {" "}
            <Loader />
          </div>
        ) : (
          engineName && (
            <>
              <div>
                <div className="flex mt-2p">
                  <div className="text-sm w-[25%]">Scan engine name</div>
                  <div className="mr-3p">-</div>

                  <div className="break-all text-[15px] font-bold">
                    {engineData.engineName}
                  </div>
                </div>
                <div className="flex mt-1p">
                  <div className="text-sm w-[25%]">Scan Type</div>
                  <div className="mr-3p">-</div>

                  <div className="break-all text-[15px] font-bold">
                    {engineData.scanType}
                  </div>
                </div>
                <div className="flex mt-1p">
                  <div className="text-sm w-[25%]">Threads</div>
                  <div className="mr-3p">-</div>

                  <div className="break-all text-[15px] font-bold">
                    {engineData.threads}
                  </div>
                </div>
                <div className="flex mt-1p">
                  <div className="text-sm w-[25%]">Scan Speed</div>
                  <div className="mr-3p">-</div>

                  <div className="break-all text-[15px] font-bold">
                    {engineData.scanSpeed}
                  </div>
                </div>
              </div>
              {engineData.scanType?.toLowerCase?.() != "active" && (
                <>
                  <div className="text-sm mt-3p pb-1p border-b dark:border-b9">
                    Crawl Setting
                  </div>

                  <div>
                    <div className="flex mt-2p">
                      <div className="text-sm w-[25%]">Crawl Scope</div>
                      <div className="mr-3p">-</div>

                      <div className="break-all text-[15px] font-bold">
                        {engineData.crawlScope}
                      </div>
                    </div>

                    <div className="flex mt-1p">
                      <div className="text-sm w-[25%]">Crawl Depth</div>
                      <div className="mr-3p">-</div>

                      <div className="break-all text-[15px] font-bold">
                        {engineData.crawlDepth}
                      </div>
                    </div>
                    <div className="flex mt-1p">
                      <div className="text-sm w-[25%]">Max links to crawl</div>
                      <div className="mr-3p">-</div>

                      <div className="break-all text-[15px] font-bold">
                        {engineData.maxLinksToCrawl}
                      </div>
                    </div>
                    <div className="flex mt-1p">
                      <div className="text-sm w-[25%]">robots.txt file</div>
                      <div className="mr-3p">-</div>

                      <div className="break-all text-[15px] font-bold">
                        {engineData.robotsFile}
                      </div>
                    </div>
                    <div className="flex mt-1p">
                      <div className="text-sm w-[25%]">sitemap.xml file</div>
                      <div className="mr-3p">-</div>

                      <div className="break-all text-[15px] font-bold">
                        {engineData.sitemapFile}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )
        )}
      </div>
    </form>
  );
}

export default Index;
