import { riskScoreColor } from "Util/RiskScoreColors";
import React from "react";

function Performance({
  percentage = 0,
  circleSize = 30,
  strokeWidth = 5,
  text,
}) {
  if (isNaN(percentage)) percentage = 0;

  // const circleSize = 30; // Size of the circle in pixels
  // const strokeWidth = 5; // Width of the stroke in pixels
  const radius = (circleSize - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference - (percentage / 100) * circumference;

  return (
    <div style={{ width: circleSize, height: circleSize }}>
      <svg
        className="w-full h-full"
        viewBox={`0 0 ${circleSize} ${circleSize}`}
      >
        <circle
          cx={circleSize / 2}
          cy={circleSize / 2}
          r={radius}
          fill="none"
          stroke="#e0e0e0"
          strokeWidth={strokeWidth}
        />
        <circle
          className="transition-all duration-500 ease-in-out"
          cx={circleSize / 2}
          cy={circleSize / 2}
          r={radius}
          fill="none"
          stroke={riskScoreColor((100 - percentage) / 10)}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={progress}
        />
        {text && (
          <text
            x={circleSize / 2}
            y={circleSize / 2}
            fontSize={circleSize / 4} // Reduced font size
            textAnchor="middle"
            dominantBaseline="middle"
            fill={riskScoreColor((100 - percentage) / 10)} // Add color to text
            className="font-bold"
          >
            {Math.floor(percentage)}%
          </text>
        )}
      </svg>
    </div>
  );
}

export default Performance;
