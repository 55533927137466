import React from "react";
import { useSelector } from "react-redux";

function Index(props) {
  const dataAllObj = useSelector(
    (state) => state.targetDashboard.home.domainInfo
  );
  const dataObj = dataAllObj?.dig_data['NS'];


  // const data = dataAllObj?.name_servers;

  return (
    <div className="" style={{ height: "380px", overflowY: "scroll" }}>
      {dataObj.map((item,index)=>{
        return <div>
          <div className={` ${index%2 === 0 ? "bg-[#F4F5F7]" : "" }   min-h-[60px] flex items-center p-2p`}>
            {item}
          </div>
        </div>
      })}
      {/* {dataObj?.map?.((i, index) => {
        return index % 2 == 1 ? (
          <div className="min-h-[60px] flex items-center p-2p">
            <div className="w-1/3 px-1p break-words">{i?.[0]}</div>
            <div className="w-1/3 px-1p break-words">{i?.[1]}</div>
            <div className="w-1/3 px-1p break-words">{i?.[2]}</div>
          </div>
        ) : (
          <div className="bg-[#F4F5F7] dark:bg-lightBlack6 min-h-[60px] flex items-center p-2p ">
            <div className="w-1/3 px-1p  break-words">{i?.[0]}</div>
            <div className="w-1/3 px-1p break-words">{i?.[1]}</div>
            <div className="w-1/3 px-1p break-words">{i?.[2]}</div>
          </div>
        );
      })} */}
    </div>
  );
}

export default Index;
