import React, { useEffect,useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
// import ButtonGroup from "@mui/material/ButtonGroup";
// import DotButton from "../../DotButton";
import { Box, Typography } from "@mui/material";
import SeverityButton from "components/ui/buttons/SeverityButton";
import { useDispatch, useSelector } from "react-redux";
import TableLoadingSkeleton from "components/ui/loaders/TableSkeleton";
import { pythonPostFetch, pythonGetFetch, postFetchLocal } from "lib/fetch";
import { targetDashboardSliceActions } from "store/scanner/targetDashboardSlice";
import targetDashboardSlice from "store/targetDashboardSlice";

export default function Vulnerability(props) {
  // get the name of the current scan
  const dispatch = useDispatch();

  const currentScan = useSelector(
    (state) => state.scanner.targetDashboard.currentScan
  );

  const topVulnerabilities =
    useSelector(
      (state) => state.scanner.targetDashboard.home.topVulnerabilities
    ) || [];


  let rows = [];

  if (topVulnerabilities.length > 0 && typeof topVulnerabilities != 'string') {
    rows =
      topVulnerabilities &&
      topVulnerabilities?.map((vuln) => {
        return {
          id: vuln._id,
          severity: SeverityButton(vuln[0]),
          name: vuln[1],
          box: (
            <div className="w-6 h-6 text-center bg-greyBg4 border-b4 border rounded">
              {vuln[2]}
            </div>
          ),
        };
      });
  }

  // const isLoading = useSelector(
  //   (state) => state?.scanner?.targetDashboard?.home?.isLoading
  // );

  if (topVulnerabilities.length === 0 || typeof topVulnerabilities === 'string' ) {

    const dummyrows = [1,2,3,4,5,6,7,8,9,10];
    return (
      <TableContainer
        sx={{ height: "480px", overflowY: "scroll" }}
        className="dark:bg-lightBlack "
      >
        <Table>
          <TableBody>
            {dummyrows?.map((row, i) => (
              <TableRow key={i} className="dark:bg-lightBlack ">
                <TableCell
                  component="th"
                  scope="row"
                  className="dark:border-b dark:border-b9"
                >
                  {""}
                </TableCell>
                <TableCell
                  align="left"
                  className=" dark:text-white dark:border-b dark:border-b9"
                >
                  {""}
                </TableCell>
                <TableCell
                  align="left"
                  className=" dark:text-white dark:border-b dark:border-b9"
                >
                  {""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <TableContainer
        sx={{ height: "480px", overflowY: "scroll" }}
        className="dark:bg-lightBlack "
      >
        <Table>
          {props.vulnerabilitiesLoading || rows?.length === 0 ? (
            <TableLoadingSkeleton
              columnCount={3}
              rowCount={10}
              tableRowClassName={"dark:bg-lightBlack "}
              tableCellClassName={
                " dark:text-white dark:border-b dark:border-b9"
              }
            />
          ) : (
            <TableBody>
              {rows?.map((row, i) => (
                <TableRow key={i} className="dark:bg-lightBlack ">
                  <TableCell
                    component="th"
                    scope="row"
                    className="dark:border-b dark:border-b9"
                  >
                    {row?.severity}
                  </TableCell>
                  <TableCell
                    align="left"
                    className=" dark:text-white dark:border-b dark:border-b9"
                  >
                    {row?.name}
                  </TableCell>
                  <TableCell
                    align="left"
                    className=" dark:text-white dark:border-b dark:border-b9"
                  >
                    {row?.box}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    );
  }
}
