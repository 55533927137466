import React from "react";

import { CompareReportsComponent } from "features/dashboard/Index";
import UserNavbar from "Layouts/userNavbar/Index";

function CompareReports(props) {
  return (
    <UserNavbar>
      <CompareReportsComponent />
    </UserNavbar>
  );
}

export default CompareReports;
