import React from "react";
import { TargetDashboardComponent } from "features/dashboard/Index";
import { TargetDashboardComponent as ScannerTargetDashboardComponent } from "features/scanner/dashboard/Index";
import { useSelector } from "react-redux";

function TargetDashboard(props) {
  const currentApp = useSelector((state) => state.general.currentApp);
  return currentApp == "VMDR" ? (
    <TargetDashboardComponent />
  ) : currentApp == "SCANNER" ? (
    <ScannerTargetDashboardComponent />
  ) : (
    ""
  );
}

export default TargetDashboard;
