import GaugeChart from "react-gauge-chart";

import React from "react";
import { useSelector } from "react-redux";

function Gauge({ rating = 0, isPerformance = false }) {
  if (isNaN(rating)) {
    rating = 0;
  }

  const angle = isPerformance ? 1 - rating / 100.0 : rating / 10.0;

  return (
    <GaugeChart
      nrOfLevels={100}
      percent={angle}
      arcPadding={0}
      hideText={true}
      needleBaseColor={"#DDDDDD"}
      needleColor={"#9F9B9B"}
      arcWidth={0.45}
      cornerRadius={0}
      animateDuration={0}
    />
  );
}

export default Gauge;
