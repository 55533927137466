import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profiles: [],
  headers: [],
  headerData:{},
  //   deletedProfiles: [],
};

let scanProfileSlice = createSlice({
  name: "scanProfileSlice",
  initialState: initialState,
  reducers: {
    setProfiles(state, action) {
      state.profiles = action.payload;
    },
    // setDeletedEngines(state, action) {
    //   state.deletedEngines = action.payload;
    // },
    setHeaders(state,action){
      
        state.headers = action.payload;
    },

    setHeaderData(state,action){
     state.headerData = action.payload;
    },
    reset(state) {
      return initialState;
    },
  },
});

export const scanProfileSliceActions = scanProfileSlice.actions;

export default scanProfileSlice;
