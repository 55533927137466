import { blueGradient1 } from "components/tailwindClasses";
import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";
import { targetInfoSliceActions } from "store/targetInfoSlice";

let inactiveOptionStyle = {};

let activeOptionStyle = {};
let activeButtonStyle = {};

let topInactiveSection = {
  color: "#2C394D",
  width: "200px",
};
let bottomInactiveSection = {
  color: "#2C394D",
  width: "200px",
};

// let inactiveOptionStyle = {
//   marginBottom: "10%",
//   color: "rgba(0, 0, 0, 0.5)",
//   cursor: "pointer",
//   paddingLeft: "8px",
// };

// let activeOptionStyle = { marginBottom: "10%" };
// let activeButtonStyle = {
//   width: "200px",
//   fontSize: "16px",
//   fontWeight: "700",
//   color: "#fff",
//   height: "60px",
//   paddingLeft: "8px",
//   background: "linear-gradient(180deg, #303e55 0%, #18202c 100%)",

//   borderRadius: "10px",
//   textAlign: "left",
// };

function TargetLayout({ setReload, isLoading, children }) {
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  let dispatch = useDispatch();
  let activeOption = useSelector(
    (state) => state.targetDashboard.home.activeDomainInfoOption
  );
  const isDarkMode = useSelector((state) => state.general.theme) == "dark";

  let handleOptionClick = (i) => {
    dispatch(targetDashboardSliceActions.setActiveDomainInfoOption(i));
  };

  let options = [
    "Description",
    "Domain Info",
    "Whois",
    "Name Servers",
    // "Related Domains",
    // "Related TLDs",
  ];

  const handleReloadClick = () => {
    setReload((r) => !r);
  };

  return (
    <div
      style={{
        position: "relative",

        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        overflow: "hidden",
      }}
      className="dark:bg-lightBlack dark:text-white bg-white "
    >
      <div className=" flex justify-between px-2p py-1p font-semibold items-center bg-greyBg4 dark:bg-lightBlack3 ">
        <div>Target Information</div>
        {!isLoading && (
          <div onClick={handleReloadClick}>
            <img src={"/targetInfoReload.svg"} alt="" />
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
        }}
      >
        <div className="flex flex-col h-[410px]">
          <div
            style={{
              width: "200px",
              borderBottomRightRadius: "10px",
              // paddingLeft: "16px",
              marginTop: "10px",
            }}
            className="dark:bg-lightBlack bg-white dark:text-white"
          >
            {options?.map((name, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    handleOptionClick(i);
                  }}
                  className="cursor-pointer"
                >
                  <div
                    style={{
                      // marginBottom: "4vh",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "20px",
                      fontSize: "16px",
                      cursor: "default",
                      height: "60px",
                    }}
                    onClick={() => {
                      handleOptionClick(i);
                    }}
                    className={
                      // activeOptionClass
                      activeOption == i &&
                      "w-full text-base font-bold h-15  rounded-10 " +
                        blueGradient1
                    }
                  >
                    <span
                      className={
                        activeOption == i
                          ? "font-bold text-white"
                          : isDarkTheme
                          ? "text-opacity-60 text-white"
                          : "text-opacity-60 "
                      }
                    >
                      {name}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="grow p-1p">{children}</div>
      </div>
      {/* <div
        style={{
          display: "flex",
        }}
      >
        <div className="flex flex-col h-[410px]">
          <div
            style={{
              backgroundColor: "#2C394D",
              width: "200px",
              borderBottomRightRadius: "10px",
              paddingLeft: "16px",
            }}
          >
            {options?.slice(0, activeOption)?.map((name, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    handleOptionClick(i);
                  }}
                  style={{ color: "white", padding: "12px 0" }}
                  className="cursor-pointer"
                >
                  <span className="dark:text-lightBlack2">{name}</span>
                </div>
              );
            })}
          </div>
          <div className="text-lightGreen2 font-bold py-3 pl-4 cursor-pointer border-l-8 border-lightGreen2">
            {options[activeOption]}
          </div>
          <div
            style={{
              backgroundColor: "#2C394D",
              width: "200px",
              borderTopRightRadius: "10px",
              paddingLeft: "16px",
            }}
            className="grow"
          >
            {options
              ?.slice(activeOption + 1, options.length)
              ?.map((name, i) => {
                return (
                  <div
                    key={i + activeOption + 1}
                    onClick={() => {
                      handleOptionClick(i + activeOption + 1);
                    }}
                    style={{ color: "white", padding: "12px 0" }}
                    className="cursor-pointer"
                  >
                    <span className="dark:text-lightBlack2">{name}</span>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="grow p-1p">{children}</div>
      </div> */}
    </div>
  );
}

export default TargetLayout;
