import React from "react";
import ChartBox from "./Chartbox/Index";
import TargetInfo from "./TargetInfo/Index";
import ActivityFeed from "./ActivityFeed/Index";
import EngineerCharts from "./EngineerCharts/Index";
import { useSelector } from "react-redux";

function Index({ currentSection }) {
  const user = useSelector((state) => state.user.data);

  return (
    <div>
      <div style={{ marginBottom: "1%" }}>
        <ChartBox />
      </div>
      {user.role == "S_ENGINEER" && (
        <div style={{ marginBottom: "1%" }}>
          <EngineerCharts />
        </div>
      )}
      {(user.role == "S_MANAGER" ||
        user.role == "MANAGER" ||
        user.role == "ENGINEER") && (
        <div style={{ marginBottom: "1%" }}>
          <TargetInfo />
        </div>
      )}
      <div style={{ marginBottom: "1%" }}>
        <ActivityFeed />
      </div>
    </div>
  );
}

export default Index;
