import React, { useState, useEffect } from "react";
import Home from "./Home/Index";
import Vulnerabilities from "./Vulnerabilities/Index";
import Bug from "./Bug/Index";
import Report from "./Report/Index";
import { Modal } from "@mui/material";
import {Box} from "@mui/material";

import { BackToCompanyListLayout } from "features/companies";
import TargetInfoLayout from "Layouts/TargetInfoLayout";
import { useDispatch, useSelector } from "react-redux";
import { postFetch } from "lib/fetch";
import SecondaryButton from "components/ui/buttons/SecondaryButton";
import { useLocation, useNavigate } from "react-router-dom";
import { addVulnerabilityReportSliceActions } from "store/vulnerabilityReport";
import { targetDashboardSliceActions } from "store/scanner/targetDashboardSlice";
import {z} from 'zod';

import {
  button1 as activeOptionClass,
  blueGradient1,
} from "components/tailwindClasses";
import { toast } from "react-toastify";

let inactiveOptionStyle = {
  cursor: "pointer",
  paddingLeft: "8px",
  width: "200px",
  textAlign: "center",
};
let activeOptionStyle = { width: "200px" };
let activeButtonStyle = {
  width: "100%",
  fontSize: "16px",
  fontWeight: "700",
  color: "#fff",
  height: "59px",
  background: "linear-gradient(180deg, #303e55 0%, #18202c 100%)",

  borderRadius: "10px",
};
function Index(props) {
  const location = useLocation();

  const [openShareModal,setOpenShareModal] = useState(false);
  const [email,setEmail] = useState('');

  const currentTarget = useSelector(
    (state) => state.targetDashboard.currentTarget
  );
  const currentScan = useSelector(
    (state) => state.scanner.targetDashboard.currentScan
  );

  const digitsLoading = useSelector((state)=>state.scanner.targetDashboard.home.isLoading);

          //     dispatch(targetDashboardSliceActions.setMainGraphData(mainRes?.data?.graph_data));

  const handleShareClick = async ()=>{

    try{
    z.string().email().parse(email);
  
    const res = await postFetch('/scanner/companyBug/shareToSecurityManager',{scan_id:currentScan.id , security_manager_email:email});
    //todo: share modal loading , backend res add, accept risk in vmdr  
    if(res.status === "success"){
      setOpenShareModal(false);
      toast.success("Shared Successfully !");
    }
  }catch(e){
    toast.error("Invalid Email");   
  }
  }

  const navigate = useNavigate();

  if (!location?.state?.fromApp) {
    navigate("/");
  }

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const currentSection = useSelector(
    (state) => state.scanner?.targetDashboard.currentSection
  );

    const vulncount = useSelector((state)=>state.scanner?.targetDashboard?.vulnerabilityCount); 


  const targetDashboardChartData = useSelector(
    (state) => state.scanner.targetDashboard.home.chartBoxData
  );

  let vulnerabilityCount =
    targetDashboardChartData?.critical +
    targetDashboardChartData?.high +
    targetDashboardChartData?.medium +
    targetDashboardChartData?.low;

   
  if(vulnerabilityCount === 0 ){
    vulnerabilityCount = vulncount;

  }

  const bugCount = useSelector(
    (state) => state?.scanner?.targetDashboard?.bugCount
  );
  const reportCount = useSelector(
    (state) => state?.scanner?.targetDashboard?.reportCount
  );

  let options = {
    Home: {
      title: "Home",
      option: 0,
      hidden: false,
    },
    Vulnerabilities: {
      title: "Scanner Result",
      option: 1,
      hidden: false,
      count:   vulnerabilityCount, // Add count property
    },
    // Bug: {
    //   title: "False Positive",
    //   option: 2,
    //   hidden: false,
    //   count: bugCount, // Add count property
    // },
    Report: {
      title: "Report",
      option: 2,
      hidden: false,
      count: digitsLoading ? 0 :  reportCount,
    },
  };

  useEffect(() => {
    // coz link brings it center of page
    window.scrollTo(0, 0);

    let savedState = sessionStorage.getItem("scannerTargetDashboardState");

    if (savedState) {
      savedState = JSON.parse(savedState);
      if (!currentSection && savedState?.currentSection) {
        dispatch(
          targetDashboardSliceActions.setCurrentSection(
            savedState?.currentSection
          )
        );
      }
      if (!currentScan?.id && savedState?.scanId) {
        dispatch(
          targetDashboardSliceActions.setCurrentScan({
            id: savedState.scanId,
            name: savedState.scanName,
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    let toSave = {
      currentSection: currentSection,
      scanId: currentScan?.id,
      scanName: currentScan?.name,
    };
    sessionStorage.setItem(
      "scannerTargetDashboardState",
      JSON.stringify(toSave)
    );
  }, [currentSection, currentScan]);

  // modal popup
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // let [role, setRole] = useState(0);

  const handleNewBugClick = (e) => {
    dispatch(addVulnerabilityReportSliceActions.reset());
    sessionStorage.removeItem("bugReportState");

    dispatch(addVulnerabilityReportSliceActions.setReportViewType("create"));

    navigate("/bugReport", { state: { fromApp: true, reportType: "create" } });
  };

  return (
    <div style={{ padding: "1%" }} className="">
      <TargetInfoLayout goBack={true}>
        <div className="rounded-t-10  bg-white dark:text-lightBlack2  dark:bg-lightBlack  flex justify-between mt-1p border-b border-b2 dark:border-b9">
          <div className="flex items-center">
            {Object.keys(options)
              .filter((key) => options[key].hidden === false)
              .map((key, i) => {
                const option = options[key];
                return (
                  <div
                    key={option.option}
                    onClick={() => {
                      dispatch(
                        targetDashboardSliceActions.setCurrentSection(
                          option.option
                        )
                      );
                    }}
                    style={
                      currentSection === option.option
                        ? activeOptionStyle
                        : inactiveOptionStyle
                    }
                  >
                    {currentSection === option.option ? (
                      <button
                        className={
                          // activeOptionClass
                          "w-full text-base font-bold text-white h-15  rounded-10 dark:bg-white dark:border-2 dark:border-white" +
                          blueGradient1
                        }
                      >
                        {option.title}
                        {!isNaN(option?.count) && (
                          <span className="ml-1 text-white bg-red-500 rounded-full px-2 py-1 text-xs">
                            {option.count}
                          </span>
                        )}
                      </button>
                    ) : (
                      <div>
                        <span>{option.title}</span>

                        {!isNaN(option?.count) && (
                          <span className="ml-1 text-white bg-red-500 rounded-full px-2 py-1 text-xs opacity-70">
                            {option.count}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <div className=" flex items-center">
        <button onClick={()=>{setOpenShareModal(true)}} className="flex items-center gap-2 mr-7 hover:bg-slate-200 rounded-md px-2 py-1 dark:hover:bg-slate-800"><img src="/shareIcon.svg"/> Share Result</button>
          </div>
          {user.role == "S_ENGINEER" && currentSection == options.Home.option && (
            <div className="w-64 flex">
              <div
                className={
                  "w-full cursor-pointer flex items-center justify-center text-base font-bold text-white h-15  rounded-10" +
                  blueGradient1
                }
                onClick={handleNewBugClick}
              >
                Add New Bug
              </div>
            </div>
          )}
        </div>

        {options.Home.hidden == false &&
          currentSection == options.Home.option && (
            <Home currentSection={options.Home.option} />
          )}
        {options.Vulnerabilities.hidden == false &&
          currentSection == options.Vulnerabilities.option && (
            <Vulnerabilities currentSection={options.Vulnerabilities.option} />
          )}
        {/* {options.Bug.hidden == false &&
          currentSection == options.Bug.option && (
            <Bug currentSection={options.Bug.option} />
          )} */}
        {options.Report.hidden == false &&
          currentSection == options.Report.option && (
            <Report currentSection={options.Report.option} />
          )}
      </TargetInfoLayout>
         {/* Delete Scan Profile Modal */}
         <Modal
        open={openShareModal}
        onClose={()=>{setOpenShareModal(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            width: "50%",
          }}
          className="bg-white rounded-10 shadow dark:bg-lightBlack dark:text-white p-1p"
        >
          <div>
            <div className="text-2xl font-bold flex items-center justify-center pb-2p border-b border-b2 dark:border-b9">
              Share Scan Results
            </div>

            <div style={{ paddingTop: "2%" }}>
              Provide the email id of Security Manager{" "}
            </div>
                <input
                  value={email}
                  onChange={(e)=>{setEmail(e.target.value)}}
                  type="text"
                  className="second-step-input bg-inputBg dark:bg-inputBgDark h-15"     
                  required
                  
                />

            <div className="flex items-center justify-end mt-2p">
              {/* for showing no and cancle button */}
              <div
                className="cursor-pointer mr-8"
                onClick={()=>{setOpenShareModal(false)}}
              >
               Cancel
              </div>

              <div
                className={
                  "h-15 w-60 font-bold text-white rounded-10 cursor-pointer flex items-center justify-center" +
                  blueGradient1
                }
                onClick={() => {
                    handleShareClick(); 
                }}
              >
                Share
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Index;
