import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "./Stepper";

import Step1 from "./Step1/Index";
import Step2 from "./Step2/Index";
import Step3 from "./Step3/Index";
import { useDispatch, useSelector } from "react-redux";
import { postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { addReportSliceActions } from "store/addReportSlice";
import Loader from "components/ui/loaders/Loader";
import { blueGradient1 } from "components/tailwindClasses";

function Index({ handleClose }) {
  let [activeStep, setActiveStep] = useState(0);

  const addReportData = useSelector((state) => state.addReport);

  const dispatch = useDispatch();

  const [isLoading, setIsloading] = useState(false);

  const handleSubmitClick = async (e) => {
    // e.preventDefault();
    // e.target.disabled = true;

    setIsloading(() => true);

    const postData = {
      asset_id: addReportData?.currentAsset?.id,
      type: addReportData?.reportType,
      format: addReportData?.reportFormat,
      file_password: addReportData?.password,
      file_password_confirm: addReportData?.confirmPassword,
      email_to_users: addReportData?.emails,
    };

    const res = await postFetch("/manager/createReport", postData);

    if (res?.status == "ok") {
      toast.success("Report generation started ! ");
      dispatch(addReportSliceActions.reset());

      handleClose();
    } else if (res?.error && res?.message) {
      toast.error(res.message);
    } else {
      toast.error("Some error occured, try later!");
    }

    setIsloading(() => false);

    // e.target.disabled = false;
  };

  const password = useSelector((state) => state?.addReport?.password);
  const confirmPassword = useSelector(
    (state) => state?.addReport?.confirmPassword
  );

  const handleNextClick = () => {
    const currentStepForm = document.getElementById(
      `step${activeStep + 1}-form`
    );

    if (activeStep == 2 && password && confirmPassword) {
      if (password != confirmPassword) {
        // Set a custom validation message for confirmPassword field
        currentStepForm.thirdStepAddReportConfirmPassword.setCustomValidity(
          "Passwords do not match."
        );
        currentStepForm.reportValidity(); // Report the custom validity to show the error message

        return;
      } else {
        currentStepForm.thirdStepAddReportConfirmPassword.setCustomValidity(""); // Reset the custom validation message
      }
    }

    if (currentStepForm && currentStepForm.checkValidity()) {
      activeStep !== 2 && setActiveStep((prevStep) => prevStep + 1);

      if (activeStep === 2) {
        handleSubmitClick();
      }
    } else {
      currentStepForm.reportValidity();
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "750px",
        height: "97%",
        // p: "1% 2%",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}
      className="bg-[#ECECEC] dark:bg-lightBlack2 rounded-10 overflow-hidden"
    >
      <div
        style={{
          position: "fixed",
          width: "100%",
          top: "0",
          left: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
          height: "8vh",
          padding: "0 2%",
          zIndex: "10",
          fontSize: "20px",
          fontWeight: "700",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        Report Creation
      </div>

      <div style={{ padding: "8vh 0 10vh 0", position: "relative" }}>
        <div
          style={{
            position: "fixed",
            top: "8vh",
            left: "0",
            width: "15%",
            height: "calc(100% - 8vh)",
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.6)",
          }}
          className="flex  justify-center bg-white dark:bg-lightBlack "
        >
          {/* stepper here */}
          <div className="mt-5">
            <Stepper activeStep={activeStep} />
          </div>
        </div>
        <div
          style={{
            marginLeft: "15%",
            height: "calc(97% - 10% - 8%)",
            width: "calc(100% - 15%)",
            padding: "2%",
          }}
        >
          {/* make stuff here */}

          {activeStep == 0 && <Step1 />}
          {activeStep == 1 && <Step2 />}
          {activeStep == 2 && <Step3 />}
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: "0",
          left: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          borderTop: "1px solid rgba(0, 0, 0, 0.2)",
          height: "10vh",
          padding: "0 2%",
          zIndex: "10",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        <button
          style={{
            // marginTop: "3.2%",
            textDecoration: "none",
            color: "#2D3B51 !important",
            marginRight: "5vw",
            fontWeight: "500",
          }}
          onClick={() => {
            if (activeStep == 0) {
              handleClose();
            } else {
              setActiveStep((activeStep) => activeStep - 1);
            }
          }}
        >
          {activeStep == 0 && "Cancel"}
          {activeStep != 0 && "Back"}
        </button>
        <button
          style={{ width: "200px", height: "50px" }}
          type="submit"
          onClick={(e) => {
            handleNextClick();
          }}
          // className="w-full flex items-center justify-center text-base font-bold text-white h-15
          //  bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10 disabled:opacity-50 "

          className={
            blueGradient1 +
            " flex items-center justify-center cursor-pointer w-60 rounded-10  font-bold"
          }
        >
          {activeStep != 2 && "Next"}
          {activeStep == 2 && (isLoading ? <Loader /> : "Done")}
        </button>
      </div>
    </Box>
  );
}

export default Index;
