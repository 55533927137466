import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import InfoCard from "components/ui/cards/InfoCard";
import InfoCardColored from "components/ui/cards/InfoCardColored";
import { getFetch } from "lib/fetch";
import { useDispatch, useSelector } from "react-redux";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";

function InfoCards(props) {
  const dispatch = useDispatch();

  const targetDashboardChartData = useSelector(
    (state) => state.targetDashboard.home.chartBoxData
  );

  let totalVulnerabilities =
    targetDashboardChartData?.critical +
    targetDashboardChartData?.high +
    targetDashboardChartData?.medium +
    targetDashboardChartData?.low;

  return (
    <div className="text-center">
      <Grid
        container
        spacing={{ xs: 1, md: 2 }}
        justifyContent="space-around"
        alignItems="center"
      >
        {/* <Grid item xs={2}>
          <InfoCard title={"Total Assets"} count={dashboardData?.totalAssets} />
        </Grid> */}
        <Grid item xs={3}>
          <InfoCard
            title={"Total Vulnerabilities"}
            count={totalVulnerabilities}
            topColor={"bg-[#6922D6] text-white "}
            bottomColor={"dark:bg-[#6922D6] dark:bg-opacity-50"}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoCardColored
            type={"critical"}
            title={"Critical"}
            count={targetDashboardChartData?.critical}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoCardColored
            type={"high"}
            title={"High"}
            count={targetDashboardChartData?.high}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoCardColored
            type={"medium"}
            title={"Medium"}
            count={targetDashboardChartData?.medium}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoCardColored
            type={"low"}
            title={"Low"}
            count={targetDashboardChartData?.low}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default InfoCards;
