import React from "react";
import TimelineGraph from "features/dashboard/components/ui/charts/TimelineGraph";
import { useSelector } from "react-redux";

function Index(props) {
  const timelineData = useSelector(
    (state) => state?.targetDashboard?.home?.timelineData
  );

  return (
    <div className="p-2p  bg-white dark:bg-lightBlack rounded ">
      <div className="p-4 pt-0 ">
        <div className="text-lg font-medium dark:text-white">
          Timeline Graph
        </div>
      </div>

      <div className="">
        <TimelineGraph
          timeline_data={timelineData}
          aspectRatio={4}
          // timelineOption={timelineOption}
        />
      </div>
    </div>
  );
}

export default Index;
