import React, { useEffect } from "react";
import { Divider, Table } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { getFetch } from "lib/fetch";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TableLoadingSkeleton from "components/ui/loaders/TableSkeleton";

const currentIcon = (os, isMobile, isDarkTheme) => {
  if (os?.toLowerCase()?.includes?.("windows")) {
    return "/windows.svg";
  } else if (os?.toLowerCase()?.includes?.("mac")) {
    return isDarkTheme ? "/macDark.svg" : "/mac.svg";
  } else if (os?.toLowerCase()?.includes?.("linux")) {
    return "/linux.svg";
  } else if (os?.toLowerCase()?.includes?.("ubuntu")) {
    return "/ubuntu.svg";
  } else {
    return isMobile
      ? isDarkTheme
        ? "/mobileDark.svg"
        : "/mobile.svg"
      : isDarkTheme
      ? "/laptopDark.svg"
      : "/laptop.svg";
  }
};

function Index(props) {
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  const [devicesData, setDevicesData] = useState([]);

  const [isLaoading, setIsLoading] = useState(false);

  // need the correct format in data
  useEffect(() => {
    let getData = async () => {
      setIsLoading(() => true);

      const res = await getFetch("/getLoginActivity");
      if (res?.status == "ok") {
        setDevicesData(() =>
          res?.activities?.map((i) => {
            return {
              lastActivity: i?.createdDate || "",
              browser: i?.browser,
              ipAddress: i?.ip_address,
              city: i?.city,
              country: i?.country_name,
              os: i?.os,
              isMobile: i?.is_mobile,
              deviceType: i?.device_type,
              browserName: i?.browser_name,
            };
          })
        );
      } else if (res?.error && res?.message) {
        setDevicesData(() => []);
        toast.error(res?.message);
      } else {
        setDevicesData(() => []);
        toast.error("error loading login activity, try later");
      }

      setIsLoading(() => false);
    };
    getData();
  }, []);

  return (
    <div
      style={{
        boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
      }}
      className="dark:bg-lightBlack bg-white dark:text-white"
    >
      <div
        style={{
          padding: "2%",
          background: "rgba(217, 217, 217, 0.25)",
          fontWeight: "500",
        }}
      >
        Device Status
      </div>
      <div>
        {isLaoading && devicesData?.length == 0 ? (
          <Table>
            <TableLoadingSkeleton columnCount={3} rowCount={10} />
          </Table>
        ) : (
          devicesData?.map((item, i) => {
            return (
              <div
                key={i}
                style={{
                  alignItems: "center",
                  padding: "1% 0%",
                  margin: "0% 2%",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                }}
              >
                <div
                  style={{ fontWeight: "600", fontSize: "18px" }}
                  className="flex items-center"
                >
                  <img
                    src={currentIcon(
                      item?.os?.toLowerCase(),
                      item?.isMobile,
                      isDarkTheme
                    )}
                    alt=""
                    className="mr-2"
                  />{" "}
                  {item?.os} {item?.browserName && `( ${item?.browserName} )`}
                  {!item?.os && !item?.browserName && item?.browser}
                </div>
                <div className="flex items-center opacity-60 mt-2">
                  <div
                    style={{ fontWeight: "400", fontSize: "16px" }}
                    className=" flex w-1/3"
                  >
                    <img
                      src={isDarkTheme ? "/clockDark.svg" : "/clock.svg"}
                      alt=""
                      className="mr-2"
                    />
                    {new Date(item?.lastActivity)?.toDateString()}{" "}
                    {new Date(item?.lastActivity)?.toLocaleTimeString()}
                  </div>
                  <div
                    style={{ fontWeight: "400", fontSize: "16px" }}
                    className="w-1/3"
                  >
                    {item?.ipAddress && <span>IP {item?.ipAddress}</span>}
                  </div>
                  {(item?.city || item?.country) && (
                    <div
                      style={{ fontWeight: "400", fontSize: "16px" }}
                      className=" flex w-1/3"
                    >
                      <img
                        src={
                          isDarkTheme ? "/locationDark.svg" : "/location.svg"
                        }
                        alt=""
                        className="mr-2"
                      />
                      {item?.city && (
                        <span>
                          {item.city}
                          {item?.country && ", "}{" "}
                        </span>
                      )}
                      {item?.country && (
                        <span className="ml-1"> {item.country}</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default Index;
