import React from "react";

import { useRef } from "react";
import "components/style.css";
import { getFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { blueGradient1 } from "components/tailwindClasses";

function Step1({ mobileNumber, setMobileNumber, setCurrentStep }) {
  const Navigate = useNavigate();

  let handleNextClick = async (e) => {
    e.preventDefault();

    const genOTPres = await getFetch("/send_phonenumber_updateOTP");

    if (genOTPres.status == "ok") {
      toast.success("OTP sent to your mobile Number !");
      setCurrentStep(() => false);
    } else if (genOTPres?.error && genOTPres?.message) {
      toast.error(genOTPres.message);
    } else {
      toast.error("Error generating OTP, try later");
      Navigate("/profile");
    }
  };

  return (
    <div className="flex h-full">
      <div className={"relative w-[58%]  h-full text-white " + blueGradient1}>
        <div className="absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="text-4xl w-fit mb-2">
            <b>
              Change <br />
              Mobile Number
            </b>
          </div>
        </div>
        <img
          src={"/loginBgLines.svg"}
          className="  absolute top-0 left-0 w-1/3  -scale-y-100"
          alt="anim"
        />
      </div>

      <div className="relative w-[42%] bg-[#ececec] h-full flex flex-col ">
        <div className="h-[10%] p-10 ">
          <div className="text-xl font-medium">
            <img src={"/cyteHackLogo.svg"} className=" h-10 inline mr-2" />
            CyeNET
          </div>
        </div>
        <div className=" flex justify-center items-center p-10">
          <form onSubmit={handleNextClick} className="w-full">
            <h1 className="mb-10 text-center text-3xl">
              <b>Change Mobile Number</b>
            </h1>

            <label htmlFor="tel">
              <p style={{ margin: 0, fontsize: "22px", fontWeight: "500" }}>
                New Mobile Number
              </p>
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              pattern="^[1-9][0-9]{9}$"
              className="text-area w-full"
              placeholder="XXXX XX XXXX"
              required
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(() => e.target.value);
              }}
            ></input>

            <button className="w-full">
              <button
                type="submit"
                className={blueGradient1 + " w-full h-15 rounded-10 font-bold"}
              >
                Next Step
              </button>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Step1;
