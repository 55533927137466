import React, { useState } from "react";
import PerformanceChart from "../../ui/charts/PerformanceChart";
import TimelineGraph from "../../ui/charts/TimelineGraph";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, InputLabel, Menu, MenuItem, Select } from "@mui/material";
import { getFetch } from "lib/fetch";
import dashboardInfoSlice, {
  dashboardInfoSliceActions,
} from "store/dashboardInfoSlice";
import { toast } from "react-toastify";
import Loader from "components/ui/loaders/Loader";

function Index(props) {
  const user = useSelector((state) => state?.user?.data);
  const dispatch = useDispatch();
  const [timelineOption, setTimelineOption] = useState("weekly"); // State to track the selected option

  const performanceData = useSelector(
    (state) => state?.dashboardInfo?.performanceGraph
  );
  const timelineData = useSelector(
    (state) => state?.dashboardInfo?.timelineGraph
  );

  const [isLoading, setIsLoading] = useState(false);

  const handleTimelineOptionChange = async (event) => {
    setIsLoading(true); // Show loader

    if (user.role == "S_ENGINEER") {
      try {
        const res = await getFetch(
          `/researcher/timeline:${event.target.value}`
        );

        if (res?.status === "ok") {
          dispatch(dashboardInfoSliceActions?.setTimelineGraph(res?.data));
          setTimelineOption(event.target.value); // Update the selected option
        } else {
          toast.info("Cannot change currently, try later");
        }
      } catch (error) {
        toast.error("An error occurred. Please try again later.");
      }
    }

    setIsLoading(false); // Hide loader
  };

  const isDarkTheme = useSelector((state) => state?.general?.theme) == "dark";

  const isLoadingDashboardData = useSelector(
    (state) => state?.dashboardInfo?.isLoading
  );

  return (
    <div className="flex   justify-between rounded mt-1p">
      <div className="w-1/2 p-2p bg-white dark:bg-lightBlack mr-[.5%] overflow-hidden rounded">
        <div className="p-4 pt-0 flex justify-between items-center dark:text-white">
          <div className="text-lg font-medium">Timeline Graph</div>
          <div className="flex items-center text-xs">
            <FormControl variant="outlined" size="small">
              <InputLabel
                id="timeline-option-label"
                className="dark:text-white "
              >
                Timeline
              </InputLabel>

              <Select
                labelId="timeline-option-label"
                value={timelineOption}
                onChange={handleTimelineOptionChange}
                label="Timeline"
                className={`dark:text-white ${isDarkTheme ? "dark" : ""}`}
                sx={{
                  "&.dark": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white",
                    },
                  },
                }}
              >
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="yearly">Yearly</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {(isLoading || isLoadingDashboardData) && timelineData?.length == 0 ? (
          <div className="h-60">
            <Loader />
          </div>
        ) : (
          <TimelineGraph
            timeline_data={timelineData}
            timelineOption={timelineOption}
          />
        )}
      </div>

      <div className="w-1/2 p-2p bg-white dark:bg-lightBlack ml-[.5%] overflow-hidden rounded">
        <div className="p-4 pt-0 flex justify-between items-center dark:text-white">
          <div className="text-lg font-medium ">Performance Graph</div>
          <div className="flex items-center text-xs">
            <div className="flex items-center mr-4 ">
              <img src="/greenCircle.svg" alt="" className="inline mr-2" />{" "}
              Selected
            </div>
            <div className="flex items-center">
              <img src="/redCircle.svg" alt="" className="inline mr-2" /> Not
              Selected
            </div>
          </div>
        </div>
        {isLoadingDashboardData && performanceData?.length == 0 ? (
          <div className="h-60">
            <Loader />
          </div>
        ) : (
          <PerformanceChart dataToPlot={performanceData} />
        )}
      </div>
    </div>
  );
}

export default Index;
