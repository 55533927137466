import React, { useEffect, useRef, useState } from "react";
import OtpSection from "components/imports/OtpSection/OtpSection";

import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { postFetch } from "lib/fetch";
import { useDispatch, useSelector } from "react-redux";
import { blueGradient1 } from "components/tailwindClasses";
import { profileSliceActions } from "store/profileSectionSlice";
import Loader from "components/ui/loaders/Loader";

export default function Step2({ password, capchaRef, oldPassword }) {
  const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  const handleCaptchaChange2 = () => {
    // toast(capchaRef.current.getValue());
  };

  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);

  const [loading, setLoading] = useState(false);

  let handleResendClick = async () => {
    if (!oldPassword) {
      toast.error("could not get old Password, try adding password again");
      return;
    }

    setLoading(() => true);

    const sendOtpRes = await postFetch("/sendPasswordChangeEmailOTP", {
      old_password: oldPassword,
    });

    if (sendOtpRes?.status == "ok") {
      toast.success("otp was sent to your phone number");
    } else if (sendOtpRes?.error && sendOtpRes?.message) {
      toast.error(sendOtpRes.message);
    } else {
      toast.error("some error occured in sending otp, try later");
    }

    setLoading(() => false);
  };

  let handleDoneClick = async (e) => {
    e.preventDefault();

    if (otp?.length != 6) {
      return toast.warn("please enter otp");
    }
    if (!capchaRef.current.getValue()) {
      return toast.warn("please verify captcha");
    }
    if (!password) {
      return toast.warn("could not get password, try adding password again");
    }

    setLoading(() => true);

    const res = await postFetch("/resetPasswordwithEmailOTP", {
      new_password: password,
      otp: otp,
    });

    if (res?.status == "ok") {
      toast.success("password was reset succesfully");

      dispatch(profileSliceActions.setSelected(0));
    } else if (res?.error && res?.message) {
      toast.error(res.message);
    } else {
      toast.error("some error occured in sending otp, try later");
    }

    setLoading(() => false);
  };

  return (
    <div
      style={{
        padding: "3%",
        boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
      }}
      className="dark:bg-lightBlack bg-white dark:text-white"
    >
      <div style={{ fontSize: "20px", fontWeight: "700", marginBottom: "1%" }}>
        OTP Authentication
      </div>
      <div className="text-lg w-2/3">
        We’ve sent you One Time Password OTP on your email for verification.
        Please enter your 6-digit OTP{" "}
      </div>
      <div className="my-1p">
        <OtpSection
          OTP={otp}
          setOTP={setOtp}
          getTimeLeft={setTimeLeft}
          onResendClick={handleResendClick}
        />
      </div>
      <div>
        <ReCAPTCHA
          ref={capchaRef}
          sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
          onChange={handleCaptchaChange2}
        />
      </div>

      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        {timeLeft > 0 && (
          <div
            className={
              "w-60 text-base cursor-pointer flex items-center justify-center font-bold text-white h-15  rounded-10 dark:bg-white dark:border-2 dark:border-white" +
              blueGradient1
            }
            onClick={(e) => {
              !loading && handleDoneClick(e);
            }}
          >
            {loading ? <Loader /> : "Done"}
          </div>
        )}
      </div>
    </div>
  );
}
