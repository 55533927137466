import React from "react";

function PageNotFound(props) {
  return (
    <div className="flex absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 dark:text-white w-full justify-center items-center">
      <img src="/notFoundLogo.svg" alt="" className="w-[35%]" />
      <div className="ml-[10%] flex flex-col justify-center items-center">
        <div className="text-blue1 font-bold text-3xl mb-2p">
          Page Not Found
        </div>
        <div>The resource requested could not be found on this server</div>
      </div>
    </div>
  );
}

export default PageNotFound;
