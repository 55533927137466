import BarChart from "components/ui/charts/Bar";
import LineChart from "components/ui/charts/LineChart";
import Loader from "components/ui/loaders/Loader";
import { pythonGetFetch, pythonPostFetch } from "lib/fetch";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { targetDashboardSliceActions } from "store/scanner/targetDashboardSlice";

function Index(props) {
  const [owaspIsLoading, setOwaspIsLoading] = useState(false);
  const [cweIsLoading, setCweIsLoading] = useState(false);
  const [mainIsLoading, setMainIsLoading] = useState(false);

  const dispatch = useDispatch();


  const openedAsseturl = useSelector((state)=>state.targetInfo.data.website_url);

  const currentScan = useSelector(
    (state) => state.scanner.targetDashboard.currentScan
  );

  const currentAssetURL = useSelector((state)=>state.scanner.targetDashboard.home.websiteUrl);

  const cweGraphData = useSelector(
    (state) => state.scanner.targetDashboard.cweGraphData
  );
  const owaspGraphData = useSelector(
    (state) => state.scanner.targetDashboard.owaspGraphData
  );
  const mainGraphData = useSelector(
    (state) => state.scanner.targetDashboard.mainGraphData
  );


  //      asset_name: currentScan?.name,
  // asset_url : currentAssetURL,
  useEffect(()=>{
    if(currentScan.name && currentAssetURL ){
      sessionStorage.setItem('dashboardPayload',JSON.stringify({"asset_name": currentScan?.name, "asset_url":currentAssetURL}));
    }
  });
  

  useEffect(() => {
    const payload = sessionStorage.getItem('dashboardPayload');
    const getCweData = async () => {


      if(openedAsseturl !== JSON.parse(payload).asset_url ){
      setCweIsLoading(true);
      }

      try {
        const cweRes = await pythonPostFetch("/asset/graph", {
        asset_name: payload ? JSON.parse(payload).asset_name : currentScan?.name,
        asset_url: payload ? JSON.parse(payload).asset_url : currentAssetURL,
          graph_type: "cwe",
          graph_duration: 0,
        });

        if (cweRes?.status == "ok") {
          dispatch(targetDashboardSliceActions.setCweGraphData(cweRes?.data));
        }
        setCweIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    };

    currentScan?.name && getCweData();
  }, [currentScan]);

  useEffect(() => {
    const payload = sessionStorage.getItem('dashboardPayload');
    const getOwaspData = async () => {

      if(openedAsseturl !== JSON.parse(payload).asset_url ){
      setOwaspIsLoading(true);}
      try {
        const owaspRes = await pythonPostFetch("/asset/graph", {
        asset_name: payload ? JSON.parse(payload).asset_name : currentScan?.name,
        asset_url: payload ? JSON.parse(payload).asset_url : currentAssetURL,
          graph_duration: 0,
          graph_type: "owasp",
        });
        if (owaspRes?.status == "ok") {
          dispatch(
            targetDashboardSliceActions.setOwaspGraphData(
              owaspRes?.data?.graph_data
            )
          );
        }
        setOwaspIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    currentScan?.name && getOwaspData();
  }, [currentScan]);

  useEffect(() => {
    const payload = sessionStorage.getItem('dashboardPayload');
    const getMainData = async () => {

      if(openedAsseturl !== JSON.parse(payload).asset_url ){
      setMainIsLoading(true);}
      try {
        const mainRes = await pythonPostFetch("/asset/graph", {
          asset_name: payload ? JSON.parse(payload).asset_name : currentScan?.name,
          asset_url: payload ? JSON.parse(payload).asset_url : currentAssetURL,
          graph_type: "main",
          graph_span: 0,

          graph_duration: 0,
        });
        if (mainRes?.status == "ok") {
          dispatch(
            targetDashboardSliceActions.setMainGraphData(
              mainRes?.data?.graph_data
            )
          );
        }
        setMainIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    };
    currentScan?.name && getMainData();
  }, [currentScan]);

  return (
    <>
      <div className="flex  rounded mt-1p">
        <div className="w-1/2 p-2p bg-white dark:bg-lightBlack mr-[.5%] overflow-hidden rounded">
          <div className="p-4 pt-0 flex justify-between items-center dark:text-white">
            <div className="text-lg font-medium ">CWE Graph</div>
            <div className="flex items-center text-xs"></div>
          </div>
          {cweIsLoading ? (
            <div className="h-60">
              <Loader />
            </div>
          ) : cweGraphData.length > 0 ? (
            <BarChart
              dataToUse={{
                values: cweGraphData?.map?.((i) => i[1]),
                backgroundColors: cweGraphData?.map?.(() => {
                  return {
                    red: Math.random() * 255,
                    green: Math.random() * 255,
                    blue: Math.random() * 255,
                  };
                }),
                labels: cweGraphData?.map?.((i) => i[0]),
              }}
              aspectRatio={2}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="w-1/2 p-2p bg-white dark:bg-lightBlack ml-[.5%] overflow-hidden rounded">
          <div className="p-4 pt-0 dark:text-white">
            <div className="text-lg font-medium ">OWASP TOP 10</div>
          </div>
          {owaspIsLoading ? (
            <div className="h-60">
              <Loader />
            </div>
          ) : owaspGraphData.values.length > 0 &&
            owaspGraphData.labels.length > 0 ? (
            <BarChart
              dataToUse={{
                values: owaspGraphData?.values,
                backgroundColors: owaspGraphData?.values?.map?.(() => {
                  return {
                    red: Math.random() * 255,
                    green: Math.random() * 255,
                    blue: Math.random() * 255,
                  };
                }),
                labels: owaspGraphData?.labels,
              }}
              aspectRatio={2}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="flex rounded mt-1p">
        <div className="p-1p w-full bg-white dark:bg-lightBlack  overflow-hidden rounded">
          <div className="p-4 pt-0 flex justify-between items-center dark:text-white">
            <div className="text-lg font-medium ">Performance Graph</div>
            <div className="flex items-center text-xs">{/* WEEKLY */}</div>
          </div>
          {
            mainIsLoading ? (
              <div className="h-60">
                <Loader />
              </div>
            ) : mainGraphData?.labels?.length > 0 ? (
              <LineChart
                aspectRatio={4}
                dataToUse={{
                  labels: mainGraphData.labels,
                  datasets: [
                    {
                      label: "Critical",
                      data: mainGraphData.values.map((i) => {
                        return i.critical;
                      }),
                      borderColor: "rgb(206, 55, 51)",
                      backgroundColor: "rgba(206, 55, 51, 0.5)",
                    },
                    {
                      label: "High",
                      data: mainGraphData.values.map((i) => {
                        return i.high;
                      }),
                      borderColor: "rgb(236, 136, 48)",
                      backgroundColor: "rgba(236, 136, 48, 0.5)",
                    },
                    {
                      label: "Medium",
                      data: mainGraphData.values.map((i) => {
                        return i.medium;
                      }),

                      borderColor: "rgb(253, 188, 44)",
                      backgroundColor: "rgba(253, 188, 44, 0.5)",
                    },
                    {
                      label: "Low",
                      data: mainGraphData.values.map((i) => {
                        return i.low;
                      }),
                      borderColor: "rgb(55, 164, 64)",
                      backgroundColor: "rgba(55, 164, 64, 0.5)",
                    },
                    // {
                    //   label: "Best Practice",
                    //   data: bottomLabels.map((i) => Math.random() * 10),
                    //   borderColor: "rgba(21, 29, 202, 1)",
                    //   backgroundColor: "rgba(21, 29, 202, .5)",
                    // },
                    // {
                    //   label: "Information",
                    //   data: bottomLabels.map((i) => Math.random() * 10),
                    //   borderColor: "rgba(159, 155, 155, 1)",
                    //   backgroundColor: "rgba(159, 155, 155,.5)",
                    // },
                  ],
                }}
              />
            ) : (
              <></>
            ) //
          }
        </div>
      </div>
    </>
  );
}

export default Index;
