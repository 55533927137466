export const columns = (role) => {
  let columns = [
    { id: "assetName", label: "Targets", minWidth: 100 },
    { id: "description", label: "Description", minWidth: 170, maxWidth: 300 },
    {
      id:
        role == "S_ENGINEER" || role == "ENGINEER" ? "performace" : "riskScore",
      label:
        role == "S_ENGINEER" || role == "ENGINEER"
          ? "Performace"
          : "Risk Score",
      minWidth: 20,
      // align: "center",
    },
    {
      id: "totalVulnerabilities",
      label: "Total Vulnerabilities",
      minWidth: 10,
      // align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "criticalSeverity",
      label: "Severity",
      minWidth: 0,
      // align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "teamMembers",
      label: "Team Members",
      minWidth: 0,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "reAdd",
      label: "",
      minWidth: 0,
      align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];

  return role == "MANAGER" ? columns : [];
};
