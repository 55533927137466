import SeverityButton from "components/ui/buttons/SeverityButton";
import StatusButton from "components/ui/buttons/StatusButton";

export const EmailFormat = (type, data = {}, user, navigate, dispatch) => {
  switch (type) {
    case 3:
      return Type3();
    case 4:
      return Type4(data);
    case 5:
      return Type5();
    case 6:
      return Type6();
    case 7:
      return Type7();
    case 8:
      return Type8(data);
    case 9:
      return Type9(data);
    case 10:
      return Type10(data);
    case 11:
      return Type11(data);
    case 13:
      return Type13(data);
    case 15:
      return Type15(data);
    case 16:
      return Type16(data);
    case 17:
      return Type17(data);
    case 18:
      return Type18(data);

    default:
      return null;
  }

  // will return component if suitable else null
};

// Forgot  your password?

// USE :
// EmailFormatCE(3)
export const Type3 = () => {
  return (
    <div>
      <div className="border border-black border-opacity-20  w-4/5 mx-auto p-2p">
        <div className=" ">
          <div>
            <span className="font-bold">Forgot your password?</span> It happens
            to the best of us.
          </div>
          <div className="mt-2p">
            To reset your password, click the button below. The link will
            self-destruct after 24 hours.
          </div>
        </div>
      </div>

      <div className="w-[275px] my-6 mx-auto cursor-pointer flex items-center justify-center text-sm font-bold text-white h-12 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10">
        Reset Your Password
      </div>
      <div className="my-6 mx-auto w-3/5 text-xs text-center">
        If you do not want to change your password or didn't request a reset,
        you can ignore and delete this email.
      </div>
    </div>
  );
};

// One Time Password (OTP) to reset Passcode for Cyethack Portal

// USE :
// EmailFormatCE(4 ,{otp : "873337"})
export const Type4 = (data) => {
  const { otp = null } = data || {};

  return (
    <div>
      <div className="border border-black border-opacity-20  w-4/5 mx-auto p-2p">
        <div className=" ">
          <div>
            You have been requested to reset your password. The password will
            expire in 2 minutes if not used.
          </div>
          <div className="mt-2p">
            Your one-time password is <span className="font-bold">{otp}</span>
          </div>
        </div>
      </div>

      <div className="w-[275px] my-6 mx-auto cursor-pointer flex items-center justify-center text-sm font-bold text-white h-12 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10">
        Reset Your Password
      </div>
      <div className="my-6 mx-auto w-3/5 text-xs text-center">
        If you do not want to change your password or didn't request a reset,
        you can ignore and delete this email.
      </div>
    </div>
  );
};

// You have successfully changed your password

// USE :
// EmailFormatCE(5, {   })
export const Type5 = (data) => {
  return (
    <div>
      <div className="border border-black border-opacity-20  w-4/5 mx-auto p-2p">
        <div className="flex items-center ">
          <img src="/lock.svg" alt="" className=" w-5 mr-2" />
          <span>
            You have successfully changed your{" "}
            <span className="font-bold"> password</span>
          </span>
        </div>
      </div>
      <div className="w-[275px] my-6 mx-auto cursor-pointer flex items-center justify-center text-sm font-bold text-white h-12 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10">
        View on CyteHack Portal
      </div>
    </div>
  );
};

// You have successfully changed your mobile number
// USE :
// EmailFormatCE(6, {   })
export const Type6 = (data) => {
  return (
    <div>
      <div className="border border-black border-opacity-20  w-4/5 mx-auto p-2p">
        <div className="flex items-center ">
          <img src="/mobile.svg" alt="" className=" w-5 mr-2" />
          <span>
            You have successfully changed your{" "}
            <span className="font-bold"> mobile number</span>
          </span>
        </div>
      </div>
      <div className="w-[275px] my-6 mx-auto cursor-pointer flex items-center justify-center text-sm font-bold text-white h-12 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10">
        View on CyteHack Portal
      </div>
    </div>
  );
};

// Sign in attempt - Cyethack Portal
// USE :
// EmailFormatCE(7, {   })
export const Type7 = (data) => {
  return (
    <div>
      <div className="border border-black border-opacity-20  w-4/5 mx-auto p-2p">
        Your account was just signed in to Cyethack Portal . You’re getting this
        email to make sure that it was you.
      </div>
      <div className="w-[275px] my-6 mx-auto cursor-pointer flex items-center justify-center text-sm font-bold text-white h-12 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10">
        Check Activity
      </div>
    </div>
  );
};

// ----------------------------------------------------------Personal Notifications-------------------------------------------------

// Aayushi accepted your invitation to CYETHACK PORTAL
// use  :
// EmailFormatSM(8, {
//   userImage: "/avatar.svg",
//   userName: "name",
//   assetName: "name",
// })
export const Type8 = (data) => {
  const { userImage = null, userName = null, assetName = null } = data || {};

  return (
    <div>
      <div className="border border-black border-opacity-20  w-4/5 mx-auto p-2p">
        <div className=" flex items-center ">
          {userImage && <img src={userImage} className="mr-2 w-10" />}
          <span>
            <span>{userName || "User"} </span> accepted your invitation to
            <span className="font-bold ml-1">{assetName}</span>
          </span>
        </div>
      </div>
      <div className="w-[275px] my-6 mx-auto cursor-pointer flex items-center justify-center text-sm font-bold text-white h-12 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10">
        Go to Cyethack Portal
      </div>
    </div>
  );
};

// Aayushi accepted your invitation to CYETHACK PORTAL
// use  :
// EmailFormatSM(9, {
//   userName: "name",
//   assetName: "name",
// })
export const Type9 = (data) => {
  const { userName = null, assetName = null } = data || {};

  return (
    <div>
      <div className="border border-black border-opacity-20  w-4/5 mx-auto p-2p">
        <div className=" flex items-center ">
          <img src={"/emailUserAdded.svg"} className="mr-2 w-6" />
          <span>
            You <span className="font-bold mx-1">added</span>{" "}
            <span>{userName}</span> in a asset
            <span className="font-bold ml-1">{assetName}</span>
          </span>
        </div>
      </div>
      <div className="w-[275px] my-6 mx-auto cursor-pointer flex items-center justify-center text-sm font-bold text-white h-12 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10">
        View on Cyethack Portal
      </div>
    </div>
  );
};

// Aayushi accepted your invitation to CYETHACK PORTAL
// use  :
// EmailFormatSM(10, {
//   userName: "name",
//   assetName: "name",
// })
export const Type10 = (data) => {
  const { userName = null, assetName = null } = data || {};

  return (
    <div>
      <div className="border border-black border-opacity-20  w-4/5 mx-auto p-2p">
        <div className=" flex items-center ">
          <img src={"/emailUserRemoved.svg"} className="mr-2 w-6" />
          <span>
            You <span className="font-bold mx-1">removed</span>{" "}
            <span>{userName}</span> in a asset
            <span className="font-bold ml-1">{assetName}</span>
          </span>
        </div>
      </div>
      <div className="w-[275px] my-6 mx-auto cursor-pointer flex items-center justify-center text-sm font-bold text-white h-12 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10">
        View on Cyethack Portal
      </div>
    </div>
  );
};
//--------------------------------------------------------------------Bug Tracker(internal discussion)-----------------------------

// Aakash Gupta suggested the change of state of vulnerability  #A1234  Exposed Prometheus metrics IIT Kanpur
// use  :
// EmailFormatCE(11, {
//   commentorImage: "/avatar.svg",
//   commentorName: "name",
//   companyName: "name",
//   vulnId: "name",
//   vulnName: "name",
//   assetName: "name",
//   oldState: "new",
//   newState: "triaged",

//   comment: "this is comment",
// })

// for both comment and no comment
export const Type11 = (data) => {
  const {
    commentorImage = null,
    commentorName = null,
    companyName = null,
    vulnId = null,
    vulnName = null,
    assetName = null,
    newState = null,
    oldState = null,
    comment = null,
  } = data || {};

  return (
    <div>
      <div className="border border-black border-opacity-20  w-4/5 mx-auto p-2p">
        <div className=" flex  ">
          {commentorImage && (
            <img src={commentorImage} className="mr-2 w-10 self-start " />
          )}

          <div>
            <div className="flex flex-wrap items-center ">
              <span className="mx-1">{commentorName || "Manager"} </span>{" "}
              {companyName && (
                <span
                  style={{
                    background: "#d9d9d9",
                    borderRadius: "40px",
                    border: "1px solid #303E55",
                    padding: "4px 10px",
                    color: "#303E55",
                    fontWeight: "700",
                  }}
                  className="mr-1"
                >
                  {companyName}{" "}
                </span>
              )}
              <span className="font-bold mr-1">suggested</span> the change of
              state of vulnerability{" "}
              {vulnId && (
                <span
                  style={{
                    color: "white",
                    background: "#539486",
                    borderRadius: "5px",
                    padding: "4px 8px",
                  }}
                  className="mx-1 my-[1px]"
                >
                  #{vulnId}
                </span>
              )}
              <span>{vulnName}</span>{" "}
              <span className="font-bold mx-1">{assetName}</span>
              from{" "}
              {oldState && (
                <span className="font-bold mx-1">
                  {StatusButton(oldState)}{" "}
                </span>
              )}
              to{" "}
              {newState && (
                <span className="font-bold mx-1">
                  {StatusButton(newState)}{" "}
                </span>
              )}
            </div>
            {comment && (
              <div className="bg-[#ECF1F1] p-1p mt-3 ">
                <div className="font-semibold italic">
                  {commentorName || "Someone"} commented :
                </div>
                <div className="mt-2">{comment}</div>
              </div>
            )}
            <div className="flex justify-start">
              <div className="w-[150px] my-6 mr-2 cursor-pointer flex items-center justify-center text-sm font-bold  h-12 rounded bg-[#E0E2E5] border-[#2C394E] border text-[#202020]">
                Deny
              </div>
              <div className="w-[150px] my-6 cursor-pointer flex items-center justify-center text-sm font-bold text-white h-12 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded">
                Accept
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-[275px] my-6 mx-auto cursor-pointer flex items-center justify-center text-sm font-bold text-white h-12 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10">
        View on Cyethack Portal
      </div>
    </div>
  );
};

// Aarushi Sharma sent a message on vulnerability #A1234  Exposed Prometheus metrics IIT Kanpur
// use :
// EmailFormatSE(13, {
//   commentorImage: "/avatar.svg",
//   commentorName: "name",
//   companyName: "name",
//   vulnId: "name",
//   vulnName: "name",
//   assetName: "name",
//   comment: "name this is comment",
// })

export const Type13 = (data) => {
  const {
    commentorImage = null,
    commentorName = null,
    companyName = null,
    vulnId = null,
    vulnName = null,
    assetName = null,
    comment = null,
  } = data || {};

  return (
    <div>
      <div className="border border-black border-opacity-20  w-4/5 mx-auto p-2p">
        <div className=" flex  ">
          {commentorImage && (
            <img src={commentorImage} className="mr-2 w-10 self-start " />
          )}

          <div>
            <div className="flex flex-wrap items-center ">
              <span className="mx-1">{commentorName || "Someone"} </span>{" "}
              {companyName && (
                <span
                  style={{
                    background: "#d9d9d9",
                    borderRadius: "40px",
                    border: "1px solid #303E55",
                    padding: "4px 10px",
                    color: "#303E55",
                    fontWeight: "700",
                  }}
                  className="mr-1"
                >
                  {companyName}{" "}
                </span>
              )}
              sent a message on vulnerability{" "}
              {vulnId && (
                <span
                  style={{
                    color: "white",
                    background: "#539486",
                    borderRadius: "5px",
                    padding: "4px 8px",
                  }}
                  className="mx-1 my-[1px]"
                >
                  #{vulnId}
                </span>
              )}
              <span>{vulnName}</span>{" "}
              <span className="font-bold mx-1">{assetName}</span>
            </div>
            {comment && (
              <div className="bg-[#ECF1F1] p-1p mt-3 ">
                <div className="font-semibold italic">
                  {commentorName || "Someone"} sent :
                </div>
                <div className="mt-2">{comment}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-[275px] my-6 mx-auto cursor-pointer flex items-center justify-center text-sm font-bold text-white h-12 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10">
        View on Cyethack Portal
      </div>
    </div>
  );
};

// ------------------------------------------------------------------------Vulnerability------------------------------------------

// Aarushi Sharma submitted new vulnerability  #A1234  Exposed Prometheus metrics IIT Kanpur
// use :
// EmailFormatSE(15, {
//   commentorImage: "/avatar.svg",
//   commentorName: "name",
//   companyName: "name",
//   vulnId: "name",
//   vulnName: "name",
//   severity: "high",
//   assetName: "name",
// })
export const Type15 = (data) => {
  const {
    commentorImage = null,
    commentorName = null,
    companyName = null,
    vulnId = null,
    severity = null,
    vulnName = null,
    assetName = null,
  } = data || {};

  return (
    <div>
      <div className="border border-black border-opacity-20  w-4/5 mx-auto p-2p">
        <div className=" flex items-center ">
          {commentorImage && (
            <img src={commentorImage} className="mr-2 w-10  " />
          )}

          <div className="flex flex-wrap items-center ">
            <span className="mx-1">{commentorName || "Someone"} </span>{" "}
            {companyName && (
              <span
                style={{
                  background: "#d9d9d9",
                  borderRadius: "40px",
                  border: "1px solid #303E55",
                  padding: "4px 10px",
                  color: "#303E55",
                  fontWeight: "700",
                }}
                className="mr-1"
              >
                {companyName}{" "}
              </span>
            )}
            submitted <span className="mx-1">{StatusButton("New")}</span>{" "}
            {severity && (
              <span className="mr-1">{SeverityButton(severity)}</span>
            )}{" "}
            vulnerability
            {vulnId && (
              <span
                style={{
                  color: "white",
                  background: "#539486",
                  borderRadius: "5px",
                  padding: "4px 8px",
                }}
                className="mx-1 my-[1px]"
              >
                {/* #-{bugId.slice(bugId.length - 3, bugId.length)} */}#{vulnId}
              </span>
            )}
            <span>{vulnName}</span>{" "}
            <span className="font-bold mx-1">{assetName}</span>
          </div>
        </div>
      </div>

      <div className="w-[275px] my-6 mx-auto cursor-pointer flex items-center justify-center text-sm font-bold text-white h-12 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10">
        View on Cyethack Portal
      </div>
    </div>
  );
};

// Aakash Gupta commented on vulnerability  #A1234  Exposed Prometheus metrics IIT Kanpur
// use :
// EmailFormatSE(16, {
//   commentorImage: "/avatar.svg",
//   commentorName: "name",
//   companyName: "name",
//   vulnId: "name",
//   vulnName: "name",
//   severity: "high",
//   assetName: "name",
//   comment: "this is the comment",
// })

export const Type16 = (data) => {
  const {
    commentorImage = null,
    commentorName = null,
    companyName = null,
    vulnId = null,
    vulnName = null,
    assetName = null,
    comment = null,
  } = data || {};

  return (
    <div>
      <div className="border border-black border-opacity-20  w-4/5 mx-auto p-2p">
        <div className=" flex  ">
          {commentorImage && (
            <img src={commentorImage} className="mr-2 w-10 self-start " />
          )}

          <div>
            <div className="flex flex-wrap items-center ">
              <span className="mx-1">{commentorName || "Someone"} </span>{" "}
              {companyName && (
                <span
                  style={{
                    background: "#d9d9d9",
                    borderRadius: "40px",
                    border: "1px solid #303E55",
                    padding: "4px 10px",
                    color: "#303E55",
                    fontWeight: "700",
                  }}
                  className="mr-1"
                >
                  {companyName}{" "}
                </span>
              )}
              commented on vulnerability{" "}
              {vulnId && (
                <span
                  style={{
                    color: "white",
                    background: "#539486",
                    borderRadius: "5px",
                    padding: "4px 8px",
                  }}
                  className="mx-1 my-[1px]"
                >
                  {/* #-{bugId.slice(bugId.length - 3, bugId.length)} */}# #
                  {vulnId}
                </span>
              )}
              <span>{vulnName}</span>{" "}
              <span className="font-bold mx-1">{assetName}</span>
            </div>
            {comment && (
              <div className="bg-[#ECF1F1] p-1p mt-3 ">
                <div className="font-semibold italic">
                  {commentorName || "Someone"} commented :
                </div>
                <div className="mt-2">{comment}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-[275px] my-6 mx-auto cursor-pointer flex items-center justify-center text-sm font-bold text-white h-12 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10">
        View on Cyethack Portal
      </div>
    </div>
  );
};

// Aakash Gupta change the state of vulnerability  #A1234  Exposed Prometheus metrics IIT Kanpur
// use :
// EmailFormatSE(17, {
//   stateChangerImage: "/avatar.svg",
//   stateChangerName: "name",
//   companyName: "name",
//   vulnId: "name",
//   vulnName: "name",
//   assetName: "name",
//   oldState: "triaged",
//   newState: "new",
//   comment: "this is comment",
// })
export const Type17 = (data) => {
  const {
    stateChangerImage = null,
    stateChangerName = null,
    companyName = null,
    vulnId = null,
    vulnName = null,
    assetName = null,
    oldState = null,
    newState = null,
    comment = null,
  } = data || {};

  return (
    <div>
      <div className="border border-black border-opacity-20  w-4/5 mx-auto p-2p">
        <div className=" flex  items-center">
          {stateChangerImage && (
            <img src={stateChangerImage} className="mr-2 w-10 self-start  " />
          )}

          <div>
            <div className="flex flex-wrap items-center ">
              <span className="mx-1">{stateChangerName || "Manager"} </span>{" "}
              {companyName && (
                <span
                  style={{
                    background: "#d9d9d9",
                    borderRadius: "40px",
                    border: "1px solid #303E55",
                    padding: "4px 10px",
                    color: "#303E55",
                    fontWeight: "700",
                  }}
                  className="mr-1"
                >
                  {companyName}{" "}
                </span>
              )}
              changed the of state of vulnerability{" "}
              {vulnId && (
                <span
                  style={{
                    color: "white",
                    background: "#539486",
                    borderRadius: "5px",
                    padding: "4px 8px",
                  }}
                  className="mx-1 my-[1px]"
                >
                  #{vulnId}
                </span>
              )}
              <span>{vulnName}</span>{" "}
              <span className="font-bold mx-1">{assetName}</span>
              from{" "}
              <span className="font-bold mx-1">{StatusButton(oldState)} </span>
              to{" "}
              <span className="font-bold ml-1">{StatusButton(newState)} </span>
            </div>

            {comment && (
              <div className="bg-[#ECF1F1] p-1p mt-3 ">
                <div className="font-semibold italic">
                  {stateChangerName || "Someone"} commented :
                </div>
                <div className="mt-2">{comment}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-[275px] my-6 mx-auto cursor-pointer flex items-center justify-center text-sm font-bold text-white h-12 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10">
        View on Cyethack Portal
      </div>
    </div>
  );
};

// Aakash Gupta added a file in vulnerability  #A1234  Exposed Prometheus metrics IIT Kanpur
// use:
// EmailFormatSE(18, {
//   userImage: "/avatar.svg",
//   userName: "name",
//   companyName: "name",
//   vulnId: "name",
//   vulnName: "name",
//   assetName: "name",

//   files: [
//     { name: "config.js", link: "google.com", size: "10KB" },
//     { name: "config.js", link: "google.com", size: "10KB" },
//     { name: "config.js", link: "google.com", size: "10KB" },
//   ],
// })
export const Type18 = (data) => {
  const {
    userImage = null,
    userName = null,
    companyName = null,
    vulnId = null,
    vulnName = null,
    assetName = null,
    files = null,
  } = data || {};

  return (
    <div>
      <div className="border border-black border-opacity-20  w-4/5 mx-auto p-2p">
        <div className=" flex  items-center">
          {userImage && (
            <img src={userImage} className="mr-2 w-10 self-start  " />
          )}

          <div>
            <div className="flex flex-wrap items-center ">
              <span className="mx-1">{userName || "Manager"} </span>{" "}
              {companyName && (
                <span
                  style={{
                    background: "#d9d9d9",
                    borderRadius: "40px",
                    border: "1px solid #303E55",
                    padding: "4px 10px",
                    color: "#303E55",
                    fontWeight: "700",
                  }}
                  className="mr-1"
                >
                  {companyName}{" "}
                </span>
              )}
              added a file in vulnerability{" "}
              {vulnId && (
                <span
                  style={{
                    color: "white",
                    background: "#539486",
                    borderRadius: "5px",
                    padding: "4px 8px",
                  }}
                  className="mx-1 my-[1px]"
                >
                  #{vulnId}
                </span>
              )}
              <span>{vulnName}</span>{" "}
              <span className="font-bold mx-1">{assetName}</span>
            </div>

            {files && (
              <div className="bg-[#ECF1F1] p-1p mt-3 ">
                {files?.map((f) => {
                  return (
                    <div className="mt-2 flex justify-between">
                      <a href={f?.link} className="font-bold">
                        {f?.name}
                      </a>
                      <span className="text-black">{f?.size}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="w-[275px] my-6 mx-auto cursor-pointer flex items-center justify-center text-sm font-bold text-white h-12 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10">
        View on Cyethack Portal
      </div>
    </div>
  );
};
