import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import InfoCard from "components/ui/cards/InfoCard";
import InfoCardColored from "components/ui/cards/InfoCardColored";
import { getFetch } from "lib/fetch";
import { useDispatch, useSelector } from "react-redux";
import { dashboardInfoSliceActions } from "store/dashboardInfoSlice";

import PieAndBarChart from "components/ui/charts/PieAndBarChart";

function Report({ data }) {
  return (
    <>
      <div>
        {/* <InfoCards
          data={{
            totalVulnerabilities: data.asset_vuln_count,
            criticalVulnerabilities: data.critical_vuln_count,
            highVulnerabilities: data.high_vuln_count,
            mediumVulnerabilities: data.medium_vuln_count,
            lowVulnerabilities: data.low_vuln_count,
          }}
          hideTotalAssets
          totalVulnerabilitiesText={"Total"}
        /> */}
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item xs={2}>
            <InfoCard
              title={"Total"}
              count={data?.asset_vuln_count}
              topColor={"dark:bg-[#3CCBF7]"}
              bottomColor={"dark:bg-[#3CCBF7] dark:bg-opacity-50"}
            />
          </Grid>

          <Grid item xs={2}>
            <InfoCardColored
              type={"critical"}
              title={"Critical"}
              count={data?.critical_vuln_count}
            />
          </Grid>
          <Grid item xs={2}>
            <InfoCardColored
              type={"high"}
              title={"High"}
              count={data?.high_vuln_count}
            />
          </Grid>
          <Grid item xs={2}>
            <InfoCardColored
              type={"medium"}
              title={"Medium"}
              count={data?.medium_vuln_count}
            />
          </Grid>
          <Grid item xs={2}>
            <InfoCardColored
              type={"low"}
              title={"Low"}
              count={data?.low_vuln_count}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <div className="flex justify-evenly">
          <div className="w-52">
            <PieAndBarChart
              values={{
                critical: data?.critical_vuln_count,
                high: data?.high_vuln_count,
                medium: data?.medium_vuln_count,
                low: data?.low_vuln_count,
              }}
              type="vulnerability"
              width={52}
            />
          </div>
          <div>
            <PieAndBarChart
              values={{
                new: data?.new_status,
                informative: data?.informative_status,
                triaged: data?.triaged_status,
                resolve: data?.resolved_status,
              }}
              type="status"
              width={52}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Report;
