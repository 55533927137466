import { Tooltip } from "@mui/material";
import { NoMaxWidthTooltip } from "components/ui/NoMaxWidthTooltip";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { addAssetSliceActions } from "store/addAssetSlice";

function FourthData(props) {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  let assetData = useSelector((state) => state.addAsset.asset);

  const handleChange = (event) => {
    dispatch(
      addAssetSliceActions.setAsset({
        ...assetData,
        inviteCompanyEmail: event.target.value,
      })
    );
  };
  return (
    <>
      <div
        className="flex w-full"
        style={{ fontSize: "24px", fontWeight: "600" }}
      >
        <div> Invite Company</div>

        <Tooltip
          placement="right"
          describeChild
          // in title describe the step
          title="Invite a Security company to collaborate on this target. Provide Security Managers email that you want to invite."
        >
          <img
            src={isDarkTheme ? "/infoDark.svg" : "/info.svg"}
            alt=""
            className="w-5"
            style={{ marginLeft: "10px" }}
          />
        </Tooltip>
      </div>

      <div style={{ marginTop: "1%" }}>
        <label style={{}}>
          <b>Email</b>
        </label>
        <input
          style={{ height: "60px" }}
          className="second-step-input dark:bg-lightBlack3"
          value={assetData?.inviteCompanyEmail}
          onChange={handleChange}
          type="email"
        />
        <label style={{}}>
          <b>Message</b>
        </label>
        <textarea
          required={assetData?.inviteCompanyEmail ? true : false}
          className="second-step-input  dark:bg-lightBlack3"
          placeholder="Type Here"
          value={assetData?.inviteCompanyMessage}
          onChange={(e) => {
            dispatch(
              addAssetSliceActions.setInviteCompanyMessage(e.target.value)
            );
          }}
          type="text"
        />

        {/* <div
          style={{
            marginTop: "2%",
            paddingBottom: "2%",
          }}
        >
          <input type="checkbox" style={{}} />
          <span style={{ marginLeft: "1%" }}>
            <b>Share Target Summary</b>
          </span>
        </div> */}
      </div>
    </>
  );
}

export default FourthData;
