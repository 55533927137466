import { Textarea } from "@mui/joy";
import { Input, MenuItem, Select, TextareaAutosize } from "@mui/material";
import { input1 } from "components/tailwindClasses";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addReportSliceActions } from "store/addReportSlice";

import { scanTypes, scanSpeeds } from "../Step1/Index";
import { robotsFiles, sitemapFiles, crawlScopes, depths } from "../Step2/Index";

function Index({ twoFaInput, setTwoFaInput }) {
  const dispatch = useDispatch();

  const step1Data = useSelector((state) => state.scanner.editScanProfile.step1);
  const step2Data = useSelector((state) => state.scanner.editScanProfile.step2);
  const step3Data = useSelector((state) => state.scanner.editScanProfile.step3);
  const step4Data = useSelector((state) => state.scanner.editScanProfile.step4);

  return (
    <div
      style={{
        padding: "2%",
        paddingBottom: "3%",
        borderRadius: "10px",
        marginBottom: "10%",
      }}
      className="bg-white dark:bg-lightBlack dark:text-white"
    >
      <div className="mb-2">
        <p className="font-bold">
          Preview the changes, enter 2FA auth then proceed further.{" "}
        </p>
        <Input
          value={twoFaInput}
          onChange={(e) => {
            setTwoFaInput(e.target.value);
          }}
          type="password"
          variant="outlined"
          required
          className="border border-gray-300"
        />
      
      </div>
      <div className="font-semibold text-lg py-1p border-b dark:border-b9">
        Review & Confirm Scan Profile Setting
      </div>

      <div>
        <div className="flex mt-2p">
          <div className="text-sm w-[25%]">Scan name</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {step1Data.scanName}
          </div>
        </div>
        <div className="flex mt-1p">
          <div className="text-sm w-[25%]">Web Application URL</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {step1Data.webAppUrl}
          </div>
        </div>
        <div className="flex mt-1p">
          <div className="text-sm w-[25%]">Tag</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">{step1Data.tag}</div>
        </div>
      </div>

      <div className="flex mt-1p">
        <div className="text-sm w-[25%]">Scan Engine</div>
        <span className="mr-3p">-</span>
        <div className="break-all text-[15px] font-bold">
          {step2Data.engineId}
        </div>
      </div>

      <div>
        <div className="flex mt-2p border-b dark:border-b9i pb-2p">
          <div className="text-sm w-[25%]">Scan Engine Name</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {step2Data.engineName}
          </div>
        </div>

        <div className="flex mt-1p">
          <div className="text-sm w-[25%]">Scan Type</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {step2Data.scanType}
          </div>
        </div>
        <div className="flex mt-1p">
          <div className="text-sm w-[25%]">Threads</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {step2Data.threads}
          </div>
        </div>
        <div className="flex mt-1p">
          <div className="text-sm w-[25%]">Scan Speed</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {step2Data.scanSpeed}
          </div>
        </div>
      </div>

      <div className="text-sm mt-3p pb-1p border-b dark:border-b9">
        Crawl Setting
      </div>
      <div>
        <div className="flex mt-2p">
          <div className="text-sm w-[25%]">Crawl Scope</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {step2Data.crawlScope}
          </div>
        </div>

        <div className="flex mt-1p">
          <div className="text-sm w-[25%]">Crawl Depth</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {step2Data.crawlDepth}
          </div>
        </div>
        <div className="flex mt-1p">
          <div className="text-sm w-[25%]">Max links to crawl</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {step2Data.maxLinksToCrawl}
          </div>
        </div>
        <div className="flex mt-1p">
          <div className="text-sm w-[25%]">robots.txt file</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {step2Data.robotsFile}
          </div>
        </div>
        <div className="flex mt-1p">
          <div className="text-sm w-[25%]">sitemap.xml file</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {step2Data.sitemapFile}
          </div>
        </div>
        <div className="flex mt-1p">
          <div className="text-sm w-[25%]">Scan Time</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {step3Data.scanNow == 1 ? "Scan Now" : "Schedule Scan"}
          </div>
        </div>
        {step3Data.scanNow == 0 && (
          <div className="flex mt-1p">
            <div className="text-sm w-[25%]">Date, Time</div>
            <div className="mr-3p">-</div>

            <div className="break-all text-[15px] font-bold">
              {new Date(step3Data.date)?.toString?.()}
            </div>
          </div>
        )}
        {step3Data.scanNow == 0 && (
          <div className="flex mt-1p">
            <div className="text-sm w-[25%]">Frequency</div>
            <div className="mr-3p">-</div>

            <div className="break-all text-[15px] font-bold">
              {step3Data.frequency}
            </div>
          </div>
        )}
        {step4Data.email && (
          <>
            <div className="text-sm mt-3p pb-1p border-b dark:border-b9">
              Send Report
            </div>

            <div className="flex mt-1p">
              <div className="text-sm w-[25%]">Email</div>
              <div className="mr-3p">-</div>

              <div className="break-all text-[15px] font-bold">
                {step4Data.email}
              </div>
            </div>
            <div className="flex mt-1p">
              <div className="text-sm w-[25%]">Report Format</div>
              <div className="mr-3p">-</div>

              <div className="break-all text-[15px] font-bold">
                {step4Data.reportFormat}
              </div>
            </div>
            {step4Data.password && (
              <div className="flex mt-1p">
                <div className="text-sm w-[25%]">Password</div>
                <div className="mr-3p">-</div>

                <div className="break-all text-[15px] font-bold">
                  {step4Data.password}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      
    </div>
  );
}

export default Index;
