import React, { useState } from "react";
import { Avatar, Box, Modal } from "@mui/material";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import Editor from "@monaco-editor/react";

import MDEditor from "@uiw/react-md-editor";
import { MarkdownText } from "components/imports/MarkdownEditor/CustomMdEdtor";
import { timeSince } from "Util/TimeSince";
import SeverityButton from "components/ui/buttons/SeverityButton";
import StatusButton from "components/ui/buttons/StatusButton";
import { UserImage } from "components/ui/ImageComponent";
import { useSelector } from "react-redux";
import { blueGradient1 } from "components/tailwindClasses";
import { postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import Loader from "components/ui/loaders/Loader";

function Index({ data, setData }) {
  let [open, setOpen] = useState(false);

  const user = useSelector((state) => state.user.data);
  const isDarkMode = useSelector((state) => state.general.theme) == "dark";

  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);

  const handleRejectClick = async () => {
    setLoadingReject(() => true);

    const res = await postFetch(
      "/vulnerability/rejectStatusChangeSuggestionFromComment",
      { comment_id: data?._id, rejectionComment: message }
    );
    if (res?.status == "ok") {
      toast.success("Status change suggestion rejected");
      setData((d) => {
        let updatedComments = [...d.comments];
        let updatedComment = updatedComments.find((c) => c._id == data?._id);
        updatedComment.resolvedStatus = res?.updatedComment?.resolvedStatus;

        return {
          ...d,
          comments: updatedComments,
        };
      });
    } else if (res?.error && res?.message) {
      toast.error(res?.message);

      if (res?.new_state) {
        setData((d) => {
          let updatedComments = [...d.comments, res?.newComment];
          let updatedComment = updatedComments.find((c) => c._id == data?._id);
          updatedComment.resolvedStatus = res?.new_state;

          return {
            ...d,
            comments: updatedComments,
          };
        });
      }
    } else {
      toast.error("Something went wrong");
    }

    setLoadingReject(() => false);
    setOpenRejectModal(() => false);
  };
  const handleAcceptClick = async () => {
    setLoadingAccept(() => true);
    const res = await postFetch(
      "/vulnerability/acceptStatusChangeSuggestionFromComment",
      { comment_id: data?._id }
    );
    if (res?.status == "ok") {
      setData((d) => {
        let updatedComments = [...d.comments, res?.newComment];
        let updatedComment = updatedComments.find((c) => c._id == data?._id);
        updatedComment.resolvedStatus = res?.updatedComment?.resolvedStatus;

        return {
          ...d,
          comments: updatedComments,
        };
      });
    } else if (res?.error && res?.message) {
      toast.error(res?.message);

      if (res?.new_state) {
        setData((d) => {
          let updatedComments = [...d.comments, res?.newComment];
          let updatedComment = updatedComments.find((c) => c._id == data?._id);
          updatedComment.resolvedStatus = res?.new_state;

          return {
            ...d,
            comments: updatedComments,
          };
        });
      }
    } else {
      toast.error("Something went wrong");
    }
    setLoadingAccept(() => false);
  };

  let [openRejectModal, setOpenRejectModal] = useState(false);
  let [message, setMessage] = useState("");

  const handleOpen = () => setOpenRejectModal(true);
  const handleClose = () => setOpenRejectModal(false);

  const handleRejectFinalClick = async () => {
    const getForm = document.getElementById("form02582-09345ndf52");
    if (getForm && getForm.checkValidity()) {
      !loadingAccept && !loadingReject && handleRejectClick();
    } else {
      getForm.reportValidity();
      return;
    }
  };

  return (
    <div className="border-b border-opacity-20 dark:border-b9 ">
      <div
        style={{
          padding: "1% 0",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="cursor-pointer"
          onClick={() => {
            setOpen((open) => !open);
          }}
        >
          <div className="flex items-center">
            <UserImage
              name={data?.commentorName}
              image={data?.commentorProfileImage}
              company={data?.commentorCompanyName}
              email={data?.commentorEmail}
              style={{
                width: "50px",
                height: "50px",
                marginRight: "10px",
              }}
              position={"right center"}
            />

            <div className="flex items-center">
              <div style={{ fontWeight: "700" }}>{data?.commentorName} </div>
              <div
                style={{
                  borderRadius: "43px",
                  padding: "6px 10px",
                  color: "white",
                }}
                className={"mx-1 " + blueGradient1}
              >
                {data?.commentorCompanyName}
              </div>
              {data?.comment_type == 0 && <span> commented </span>}
              {data?.comment_type == 1 && (
                <>
                  <div className="mr-1">
                    {data?.isSuggestion
                      ? "suggested status change to"
                      : "changed the status to"}
                  </div>
                  {StatusButton(data?.new_state)}
                </>
              )}
              {data?.comment_type == 1 &&
                data?.isSuggestion &&
                data?.resolvedStatus != "pending" && (
                  <>
                    <div
                      className={
                        data?.resolvedStatus == "rejected"
                          ? "font-bold ml-2 text-critical"
                          : data?.resolvedStatus == "accepted"
                          ? " font-bold ml-2 text-low"
                          : data?.resolvedStatus == "invalid"
                          ? "font-bold ml-2 text-gray-500"
                          : "" // this is pending
                      }
                    >
                      {data?.resolvedStatus}
                    </div>
                  </>
                )}
            </div>
          </div>
          <div style={{ position: "relative", width: "30%" }} className="">
            <div
              style={{
                textAlign: "right",

                fontSize: "20px",
                fontWeight: "700",
                paddingRight: "2%",
              }}
              className="flex justify-end"
            >
              {open == true && (
                <img
                  src={isDarkMode ? "/upArrowDark.svg" : "/upArrow.svg"}
                  alt=""
                />
              )}
              {open == false && (
                <img
                  src={isDarkMode ? "/downArrowDark.svg" : "/downArrow.svg"}
                  alt=""
                />
              )}
            </div>
            <div
              style={{
                position: "absolute",
                right: "0",
                top: "20px",
                fontSize: "12px",
                // color: "rgba(0, 0, 0, 0.5)",
              }}
              className="dark:text-white opacity-50"
            >
              {new Date(data?.createdDate)?.toDateString()} (
              {timeSince(new Date(data?.createdDate))} ago)
            </div>
          </div>
        </div>
      </div>

      {open == true && (
        <div className="ml-[60px]">
          <div
            style={{
              // fontSize: "14px",
              padding: "1% 0",
            }}
          >
            <MarkdownText data={data?.content} />
          </div>

          {user?.role == "MANAGER" &&
            data?.comment_type == 1 &&
            data?.isSuggestion &&
            data?.resolvedStatus == "pending" && (
              <div className="py-1p flex">
                <div
                  onClick={handleOpen}
                  className="w-40 flex items-center  cursor-pointer justify-center text-sm font-bold text-[#202020] h-12 bg-[#E0E2E5] bg-opacity-100 rounded mr-2p"
                >
                  {loadingReject ? <Loader /> : "Deny"}
                </div>
                <div
                  onClick={
                    !loadingAccept && !loadingReject
                      ? handleAcceptClick
                      : () => {}
                  }
                  className={
                    blueGradient1 +
                    " w-40 flex items-center cursor-pointer justify-center text-sm font-bold text-white h-12  rounded"
                  }
                >
                  {loadingAccept ? <Loader /> : "Accept"}
                </div>
              </div>
            )}
          <Modal
            open={openRejectModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "800px",
                p: "1% 2%",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                borderRadius: "5px",
              }}
              className="bg-white dark:bg-lightBlack dark:text-white"
            >
              <form action="" id="form02582-09345ndf52">
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    padding: "1%",
                    textAlign: "center",
                  }}
                >
                  Add a message
                </div>

                <div style={{ fontWeight: "500", fontSize: "16px" }}>
                  <textarea
                    name=""
                    id=""
                    rows="5"
                    style={{
                      resize: "none",
                      width: "100%",
                      boxSizing: "border-box",
                      padding: "1%",
                      background: "rgba(0, 0, 0, 0.08)",
                      border: " 1px solid rgba(0, 0, 0, 0.25)",
                      borderRadius: "10px",
                      fontSize: "16px",
                    }}
                    required
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                  className="pt-2 mt-2  "
                >
                  <div
                    style={{
                      // marginTop: "3.2%",
                      textDecoration: "none",
                      color: "#2D3B51 !important",
                      cursor: "pointer",
                      marginRight: "32px",
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </div>
                  <div
                    onClick={handleRejectFinalClick}
                    className={
                      blueGradient1 +
                      " flex items-center justify-center  w-60 rounded-10 h-15 font-bold cursor-pointer"
                    }
                  >
                    {loadingReject ? <Loader /> : "Done"}
                  </div>
                </div>
              </form>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default Index;
