import React, { useEffect, useState } from "react";
import PrimaryButton from "components/ui/buttons/SecondaryButton";
import { useNavigate } from "react-router-dom";
import FiberManualRecordRounded from "@mui/icons-material/FiberManualRecordRounded";
import ColorDot from "components/ui/buttons/ColorDot";
import DuplicateTable from "./DuplicateTable";
import TableFilterSection from "../../ui/TableFilterSection";
import SelectTable from "./SelectTable";
import userEvent from "@testing-library/user-event";
import { useDispatch, useSelector } from "react-redux";
import { getFetch, postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";
import { Modal, Box } from "@mui/material";
import { blueGradient1 } from "components/tailwindClasses";
import Loader from "components/ui/loaders/Loader";

let ops =
  "flex justify-center h-12 w-40 cursor-pointer items-center text-base dark:text-lightBlack1";
let opsSelect =
  "flex justify-center h-12 w-40 cursor-pointer items-center text-base " +
  "font-bold border-b-2 border-[#303E55] dark:text-white dark:bg-gradient-to-b dark:from-[#404246]/0 dark:to-[#404246]/100";

function Index({ currentSection }) {
  const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareSecEngLoader, setShareEngLoader] = useState(false);
  const [email, setEmail] = useState("");

  const filterOptions = [
    {
      name: "Valid",
      component: (
        <div className="flex items-center ml-2">
          <ColorDot type={"Valid"} />
          <span className="ml-2">Valid</span>
        </div>
      ),
    },
    {
      name: "Resubmit",
      component: (
        <div className="flex items-center ml-2">
          <ColorDot type={"Resubmit"} />
          <span className="ml-2">Resubmit</span>
        </div>
      ),
    },
    {
      name: "Duplicate",
      component: (
        <div className="flex items-center ml-2">
          <ColorDot type={"Duplicate"} />
          <span className="ml-2">Duplicate</span>
        </div>
      ),
    },
    {
      name: "Deleted",
      component: (
        <div className="flex items-center ml-2">
          <ColorDot type={"Deleted"} />
          <span className="ml-2">Deleted</span>
        </div>
      ),
    },
  ];

  if (user?.role == "S_ENGINEER") {
    filterOptions.push({
      name: "Drafts",
      component: (
        <div className="flex items-center ml-2">
          <span className="ml-2">Drafts</span>
        </div>
      ),
    });
  }

  const currentTarget = useSelector(
    (state) => state.targetDashboard.currentTarget
  );

  const data = useSelector((state) => state.targetDashboard.bugs);
  const checked = useSelector((state) => state.switch.checked); // for toggle switch

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const getData = async () => {
      setIsLoading(() => true);

      // change api according to switch ( which will show shared bugs)
      let res;
      if (!checked) {
        res = await getFetch("/bug/listBugs", {
          parent_asset: currentTarget.id,
        });
      } else {
        console.log(user.role);
        if (user.role === "S_ENGINEER") {
          res = await postFetch("/scanner/securityEngineer/companyBugs", {
            asset_id: currentTarget.id,
          });
        } else {
          res = await getFetch("/scanner/securityManager/companyBugs", {
            asset_id: currentTarget.id,
          });
        }
      }

      if (res?.status == "ok" || res?.status == "success") {
        let dataObj;
        res?.status == "ok"
          ? (dataObj = res?.data)
          : (dataObj = res?.company_bugs);
        const dataToSet = dataObj?.map((bug) => {
          if (bug.severity) {
            bug.severity =
              bug.severity.slice(0, 1).toUpperCase() +
              bug.severity.slice(1, bug.length).toLowerCase();
          }
          if (bug.status) {
            bug.status =
              bug.status.slice(0, 1).toUpperCase() +
              bug.status.slice(1, bug.length).toLowerCase();
          }
          return {
            id: bug?._id,
            status: bug?.status,
            type: bug?.type,
            title: bug?.name,
            severity: bug?.severity,
            cvssScore: bug?.cvss,
            cve: bug?.cwe,
            researcher: {
              id: bug?.researcherId,
              name: bug?.researcherUsername,
              email: bug?.researcherEmail,
              profileImage: bug?.researcherProfileImage,
            },
            createdOn: bug?.created_date,
            hasNewChat: bug?.hasNewChat,
          };
        });

        dispatch(targetDashboardSliceActions.setBugs(dataToSet));
        dispatch(targetDashboardSliceActions.setBugCount(dataToSet?.length));
      } else if (res?.error && res?.message) {
        dispatch(targetDashboardSliceActions.setBugs([]));
        dispatch(targetDashboardSliceActions.setBugCount(0));

        toast.error(res?.message);
      } else {
        toast.error("Error loading Bugs, try later !");

        dispatch(targetDashboardSliceActions.setBugs([]));
        dispatch(targetDashboardSliceActions.setBugCount(0));
      }

      setIsLoading(() => false);
    };

    getData();
  }, [checked]);

  let options = !checked
    ? {
        All: { title: "All", data: data, hidden: false },
        Initial: {
          title: "Initial",
          data: data.filter((item) => item?.status?.toLowerCase() == "initial"),
          hidden: false,
        },
        Valid: {
          title: "Valid",
          data: data.filter((item) => item?.status?.toLowerCase() == "valid"),
          hidden: false,
        },
        Drafts: {
          title: "Drafts",
          data: data.filter((item) => item?.status?.toLowerCase() == "draft"),
          hidden: false,
        },
        Resubmit: {
          title: "Resubmit",
          data: data.filter(
            (item) => item?.status?.toLowerCase() == "resubmit"
          ),
          hidden: false,
        },
        Duplicate: {
          title: "Duplicate",
          data: data.filter(
            (item) => item?.status?.toLowerCase() == "duplicate"
          ),
          hidden: false,
        },
        Bin: {
          title: "Bin",
          data: data.filter((item) => item?.status?.toLowerCase() == "deleted"),
          hidden: false,
        },
      }
    : {
        All: { title: "All", data: data, hidden: false },
        Initial: {
          title: "Initial",
          data: data.filter((item) => item?.status?.toLowerCase() == "initial"),
          hidden: false,
        },
        "Accepted Risk": {
          title: "Accepted Risk",
          data: data?.filter?.(
            (item) => item?.status?.toLowerCase() === "acceptedrisk"
          ),
          // data:data,
          hidden: false,
        },

        "False Positive": {
          title: "False Positive",
          data: data?.filter?.(
            (item) => item?.status?.toLowerCase() === "falsepositive"
          ),
          hidden: false,
        },
      };

  if (!checked) {
    if (user.role != "S_ENGINEER") options.Drafts.hidden = true;
    if (user.role == "S_ENGINEER") options.Duplicate.hidden = true;
  }
  let [activeOption, setActiveOption] = useState("All");

  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState("");

  let currentData = options[activeOption].data;

  // handling filtering for all option
  if (activeOption == "All" && filters.length > 0) {
    currentData = currentData.filter((item) => filters.includes(item.status));
  }

  // handling search

  if (search != "") {
    currentData = currentData.filter((item) =>
      item.title.toLowerCase().includes(search.toLowerCase())
    );
  }

  const bugs = useSelector((state) => state.targetDashboard.bugs);
  const selectedBugs = useSelector(
    (state) => state.targetDashboard.selectedBugs
  );

  let navigate = useNavigate();
  let handleCompareClick = (e) => {
    if (selectedBugs.length >= 2)
      navigate("/compareReports", { state: { fromApp: true } });
    else toast.warn("Select more than 1 report to compare !");
  };

  const [validSelectedBugs, setValidSelectedBugs] = useState([]);
  let handleMoveToVulnerability = (e) => {
    if (selectedBugs.length > 0) {
      // find only valid bugs out of selected bugs
      setValidSelectedBugs(() =>
        selectedBugs.filter(
          (id) =>
            bugs.find((bug) => bug.id == id).status.toLowerCase() == "valid" ||
            bugs.find((bug) => bug.id == id).status.toLowerCase() ==
              "acceptedrisk"
        )
      );
      setMoveToVulnerabilityOpen(() => true);
    } else toast.warn("Select valid reports to move to Vulnerability ");
  };

  const vulnCount = useSelector(
    (state) => state?.targetDashboard?.vulnerabilityCount
  );
  const bugCount = useSelector((state) => state?.targetDashboard?.bugCount);

  const handleMoveDoneClick = async () => {
    const res = await postFetch("/bug/moveToVulnerability", {
      bugIds: validSelectedBugs,
    });

    if (res?.status == "ok") {
      toast.success(
        validSelectedBugs > 1
          ? "New Vulnerabilities Added"
          : "New Vulnerability Added"
      );

      dispatch(
        targetDashboardSliceActions.setBugs(
          bugs.filter((b) => !validSelectedBugs.includes(b.id))
        )
      );
      dispatch(
        targetDashboardSliceActions.setBugCount(
          bugCount - validSelectedBugs.length
        )
      );
      dispatch(
        targetDashboardSliceActions.setVulnerabilityCount(
          vulnCount + validSelectedBugs.length
        )
      );

      setValidSelectedBugs(() => []);
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else toast.error("Some error occured, try later");

    closeMoveToVulnerability();
  };

  const handleShareSecEngClick = async () => {
    console.log("Sharing to sec engineer " + email);
    setShareEngLoader(true);
    const res = await postFetch(
      "/scanner/companyBug/assignToSecurityEngineer",
      {
        bug_id: selectedBugs,
        sec_eng_id: email,
      }
    );

    if (res.status === "success") {
      setShareEngLoader(false);
      setOpenShareModal(false);
      setEmail("");
    }
  };

  const handleMoveCompanyBugDoneClick = async () => {
    const res = await postFetch("/scanner/companyBug/moveToVulnerability", {
      bugIds: validSelectedBugs,
    });

    if (res?.status == "ok") {
      toast.success(
        validSelectedBugs > 1
          ? "New Vulnerabilities Added"
          : "New Vulnerability Added"
      );

      dispatch(
        targetDashboardSliceActions.setBugs(
          bugs.filter((b) => !validSelectedBugs.includes(b.id))
        )
      );
      dispatch(
        targetDashboardSliceActions.setBugCount(
          bugCount - validSelectedBugs.length
        )
      );
      dispatch(
        targetDashboardSliceActions.setVulnerabilityCount(
          vulnCount + validSelectedBugs.length
        )
      );

      setValidSelectedBugs(() => []);
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else toast.error("Some error occured, try later");

    closeMoveToVulnerability();
  };

  ///companyBug/moveToVulnerability

  const [moveToVulnerabilityOpen, setMoveToVulnerabilityOpen] = useState(false);
  const closeMoveToVulnerability = () => setMoveToVulnerabilityOpen(false);

  return (
    <div className="bg-white dark:bg-lightBlack">
      <div className="flex items-center border-b border-b2 dark:border-b9 justify-between">
        <div className="flex grow">
          {Object.keys(options)
            .filter((key) => options[key].hidden == false)
            .map((option, i) => {
              return (
                <div
                  onClick={() => {
                    setActiveOption(() => options[option].title);
                  }}
                  className={
                    activeOption == options[option].title ? opsSelect : ops
                  }
                >
                  <span>{options[option].title}</span>{" "}
                  <span className="text-sm ml-1">
                    ({options[option].data.length})
                  </span>
                </div>
              );
            })}
        </div>

        {user.role == "S_MANAGER" && activeOption == "Valid" && (
          <div className="w-60 mr-2p">
            <div
              onClick={handleMoveToVulnerability}
              className={
                "w-52 mr-2p cursor-pointer h-12 flex items-center justify-center font-bold rounded-10 " +
                blueGradient1
              }
            >
              Move To Vulnerability
            </div>
          </div>
        )}
        {/* console.log("Active option " + activeOption); */}
        {user.role == "S_MANAGER" && activeOption == "Accepted Risk" && (
          <div className="w-60 mr-2p">
            <div
              onClick={handleMoveToVulnerability}
              className={
                "w-52 mr-2p cursor-pointer h-12 flex items-center justify-center font-bold rounded-10 " +
                blueGradient1
              }
            >
              Move To Vulnerability
            </div>
          </div>
        )}
        <Modal
          open={moveToVulnerabilityOpen}
          onClose={closeMoveToVulnerability}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="bg-white dark:bg-lightBlack dark:text-white w-1/2 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-1p rounded-10">
            <div className="font-bold text-xl text-center border-b-2 dark:border-b9 pb-1p">
              Move to Vulnerability
            </div>
            <div className="text-lg mt-1p">
              Are you sure, you want to move these {validSelectedBugs.length}{" "}
              <span className="font-bold">Valid</span> bugs to vulnerability
              list as status <span className="font-bold">New</span> ?
            </div>
            <div className="flex justify-end items-center mt-1p">
              <div
                className="mr-3p cursor-pointer "
                onClick={closeMoveToVulnerability}
              >
                No
              </div>
              <div className="w-60 ">
                <div
                  onClick={
                    checked
                      ? handleMoveCompanyBugDoneClick
                      : handleMoveDoneClick
                  }
                  className={
                    "w-52 mr-2p cursor-pointer h-12 flex items-center justify-center font-bold rounded-10 " +
                    blueGradient1
                  }
                >
                  Yes
                </div>
              </div>
            </div>
          </div>
        </Modal>

        {user.role == "S_MANAGER" && (
          <>
            <div
              className={
                "w-52 mr-2p cursor-pointer h-12 flex items-center justify-center font-bold rounded-10 " +
                blueGradient1
              }
              onClick={handleCompareClick}
            >
              Compare
            </div>
            <div
              className={
                " px-5 mr-2p cursor-pointer h-12 flex items-center justify-center font-bold rounded-10 " +
                blueGradient1
              }
              onClick={() => {
                setOpenShareModal(true);
              }}
            >
              Share to Security Engineer
            </div>
          </>
        )}
      </div>

      <TableFilterSection
        All={activeOption == "All"}
        filters={filters}
        setFilters={setFilters}
        search={search}
        setSearch={setSearch}
        options={filterOptions}
      />
      <SelectTable
        isAllTable={activeOption != "Duplicate"}
        isDuplicateTable={activeOption == "Duplicate"}
        currentOption={activeOption}
        data={currentData}
        isLoading={isLoading}
      />
      <Modal
        open={openShareModal}
        onClose={() => {
          setOpenShareModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            width: "50%",
          }}
          className="bg-white rounded-10 shadow dark:bg-lightBlack dark:text-white p-1p"
        >
          <div>
            <div className="text-2xl font-bold flex items-center justify-center pb-2p border-b border-b2 dark:border-b9">
              Share Scan Results
            </div>

            <div style={{ paddingTop: "2%" }}>
              Provide the email id of Security Engineer{" "}
            </div>
            <input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              type="text"
              className="second-step-input bg-inputBg dark:bg-inputBgDark h-15"
              required
            />

            <div className="flex items-center justify-end mt-2p">
              {/* for showing no and cancle button */}
              <div
                className="cursor-pointer mr-8"
                onClick={() => {
                  setOpenShareModal(false);
                }}
              >
                Cancel
              </div>

              <div
                className={
                  "h-15 w-60 font-bold text-white rounded-10 cursor-pointer flex items-center justify-center" +
                  blueGradient1
                }
                onClick={() => {
                  handleShareSecEngClick();
                }}
              >
                {shareSecEngLoader ? <Loader /> : "Share"}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Index;
