import React, { useEffect, useState } from "react";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import { addAssetSliceActions } from "store/addAssetSlice";
import { Checkbox, List, Modal, Tooltip } from "@mui/material";
import SecondaryButton from "components/ui/buttons/SecondaryButton";
import Loader from "components/ui/loaders/Loader";
import { NoMaxWidthTooltip } from "components/ui/NoMaxWidthTooltip";
import { pythonPostFetch } from "lib/fetch";
import { blueGradient1 } from "components/tailwindClasses";
import { Search } from "components/ui/inputs/Search";

function SecondData(props) {
  const dispatch = useDispatch();
  let assetData = useSelector((state) => state.addAsset.asset);

  const handleChange = (event) => {
    dispatch(
      addAssetSliceActions.setAsset({
        ...assetData,
        type: event.target.value,
      })
    );
  };

  // console.log("ASSET DATA : ", assetData);

  function API() {
    return (
      <>
        <div
          className="avatar-text"
          style={{
            display: "inline",
            // marginLeft: "2%",
            // fontSize: "20px",
            fontWeight: "600",
            marginTop: "2%",
          }}
        >
          Upload Postman API
        </div>
        <label style={{}}>
          <input
            style={{ height: "150px", border: "dashed" }}
            className="second-step-input "
            type="file"
            value={assetData.postmanApiFile}
            onChange={(e) => {
              dispatch(
                addAssetSliceActions.setAsset({
                  ...assetData,
                  postmanApiFile: e.target.value,
                })
              );
            }}
          />
        </label>
      </>
    );
  }
  function NST() {
    return (
      <>
        <div
          className="avatar-text"
          style={{
            display: "inline",
            // marginLeft: "2%",
            // fontSize: "20px",
            fontWeight: "600",
            marginTop: "2%",
          }}
        >
          IP Range (CIDR)
        </div>
        <br />
        <label>
          <input
            style={{ height: "50px" }}
            className="second-step-input"
            placeholder="127.0.0.1 or 127.0.01/22"
            value={assetData.ipRange}
            onChange={(e) => {
              dispatch(
                addAssetSliceActions.setAsset({
                  ...assetData,
                  ipRange: e.target.value,
                })
              );
            }}
          />
        </label>
      </>
    );
  }
  function IOT() {
    return (
      <>
        <div
          className="avatar-text"
          style={{
            display: "inline",
            // marginLeft: "2%",
            // fontSize: "20px",
            fontWeight: "600",
            marginTop: "2%",
          }}
        >
          URL
        </div>
        <br />
        <label>
          <input
            style={{ height: "50px" }}
            className="second-step-input"
            placeholder="https://play.google.com/store/apps/details?id=us.zoom.videomeetings&hl=en_IN&gl=US&pli=1"
            value={assetData.appStoreUrl}
            onChange={(e) => {
              dispatch(
                addAssetSliceActions.setAsset({
                  ...assetData,
                  appStoreUrl: e.target.value,
                })
              );
            }}
          />
        </label>
      </>
    );
  }
  function WT() {
    let websiteUrlInput = document.querySelector("#secondDataWebsiteUrl923");

    const [websiteDomainCheckbox, setWebsiteDomainCheckbox] = useState(
      assetData?.websiteSubdomains?.length > 1 ? "subdomain" : "domain"
    );

    // 1 coz subdomain will always have domain

    const [domains, setDomains] = useState([]);

    const [loading, setLoading] = useState(false);

    const [searchSubdomain, setSearchSubdomain] = useState("");

    let handleOptionChange = async (e) => {
      setWebsiteDomainCheckbox(() => e.target.value);
      if (e.target.value != "subdomain") {
        dispatch(
          addAssetSliceActions.setAsset({
            ...assetData,
            websiteSubdomains: [assetData.websiteUrl],
          })
        );
        return;
      }
      //
      setModalOpen(() => true);

      setLoading(() => true);

      let res = await pythonPostFetch(`/add_target`, {
        url: assetData.websiteUrl,
        process: "1",
      });

      setLoading(() => false);

      if (res?.res && res?.res?.length > 0) {
        setDomains(() => res.res);
      }

      setSelectedOptions(() => [assetData.websiteUrl]);
    };

    let subdomainsToShow = domains.filter((s) =>
      s?.toLowerCase?.().includes(searchSubdomain?.toLowerCase?.())
    );

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleCheckedChange = (event, value) => {
      if (event.target.checked) {
        // if (selectedOptions?.length < 4) {
        setSelectedOptions(() => [...selectedOptions, value]);
        // } else {
        //   setSelectedOptions(() => [...selectedOptions.slice(0, 3), value]);
        // }
      } else {
        setSelectedOptions(() =>
          selectedOptions.filter((item) => item !== value)
        );
      }
    };

    const handleLabelClick = (value) => {
      if (selectedOptions.includes(value)) {
        setSelectedOptions(() =>
          selectedOptions.filter((option) => option !== value)
        );
      } else {
        setSelectedOptions(() => [...selectedOptions, value]);
      }
    };

    const [urlStateValue, setUrlStateValue] = useState("");

    useEffect(() => {
      setUrlStateValue(() => assetData.websiteUrl);
    }, [assetData.websiteUrl]);

    const [isWebsiteUrlValid, setIsWebsiteUrlValid] = useState(false);

    useEffect(() => {
      if (websiteUrlInput) {
        if (websiteUrlInput.checkValidity()) {
          setIsWebsiteUrlValid(() => true);
        } else {
          setIsWebsiteUrlValid(() => false);
        }
      }
    }, [websiteUrlInput]);

    return (
      <>
        <div
          className="avatar-text"
          style={{
            display: "inline",
            // marginLeft: "2%",
            // fontSize: "20px",
            fontWeight: "600",
            marginTop: "2%",
          }}
        >
          Website URL
        </div>
        <input
          id="secondDataWebsiteUrl923"
          style={{ height: "50px" }}
          className="second-step-input"
          placeholder="https://docs.google.com"
          type={"url"}
          value={urlStateValue}
          onChange={(e) => {
            setUrlStateValue(() => e.target.value);
          }}
          onBlur={(e) => {
            dispatch(
              addAssetSliceActions.setAsset({
                ...assetData,
                websiteUrl: urlStateValue,
                websiteSubdomains: [urlStateValue],
              })
            );
          }}
          required
        />
        <FormControl>
          {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={websiteDomainCheckbox}
            name="radio-buttons-group"
            onChange={handleOptionChange}
          >
            <FormControlLabel
              value="domain"
              control={<Radio />}
              label="Only this domain"
            />
            <FormControlLabel
              value="subdomain"
              control={<Radio />}
              label="Want to add subdomain"
              disabled={!isWebsiteUrlValid}
            />
          </RadioGroup>
          <Modal
            open={modalOpen}
            // onClose={() => {
            //   setOpen(() => false);
            // }}
            // this prevents it from closing on click outside, and only closes on cancel click now
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="w-[800px] py-1p bg-white dark:bg-lightBlack dark:text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-10">
              <div
                style={{
                  background: " rgba(217, 217, 217, 0.25)",
                }}
                className="my-2 px-2p py-3 text-sm font-medium flex justify-between"
              >
                <div> Subdomains List</div>
              </div>
              <div
              //  className="h-9 p-4 mx-2p text-sm border border-b2 rounded bg-inputBg flex items-center justify-start"
              >
                <Search
                  search={searchSubdomain}
                  setSearch={setSearchSubdomain}
                />
              </div>

              {subdomainsToShow && subdomainsToShow?.length > 0 && (
                <div className="mx-2p">
                  <List className="max-h-60 overflow-y-scroll overflow-x-hidden">
                    {subdomainsToShow?.map((subdomain, index) => {
                      const id = subdomain;
                      return (
                        <div>
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                checked={selectedOptions.includes(id)}
                                onChange={(event) =>
                                  id != assetData?.websiteUrl &&
                                  handleCheckedChange(event, id)
                                }
                                value={subdomain}
                                sx={{
                                  marginRight: "20px",
                                  display: " flex",
                                }}
                              />
                            }
                            sx={{
                              padding: "10px",
                              borderBottom: "1px solid rgba(0, 0, 0, .1)",
                              width: "100%",
                              // boxSizing: "border-box",
                              display: "flex",
                            }}
                            label={
                              <div
                                onClick={() =>
                                  id != assetData?.websiteUrl &&
                                  handleLabelClick(id)
                                }
                                className="font-medium text-sm flex  items-center justify-between whitespace-normal break-all"
                              >
                                {subdomain}
                              </div>
                            }
                          />
                        </div>
                      );
                    })}
                  </List>
                </div>
              )}
              {domains?.length == 0 && loading && (
                <div className="h-60">
                  <Loader />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
                className="border-t-2 border-b2 mx-2p py-2p"
              >
                <button
                  style={{
                    // marginTop: "3.2%",
                    textDecoration: "none",
                    color: "#2D3B51 !important",
                    marginRight: "5vw",
                    fontWeight: "500",
                  }}
                  onClick={() => {
                    dispatch(
                      addAssetSliceActions.setAsset({
                        ...assetData,
                        websiteSubdomains: [assetData.websiteUrl],
                      })
                    );
                    setModalOpen(() => false);
                  }}
                >
                  Cancel
                </button>
                <div className="w-60">
                  <div
                    className={
                      "cursor-pointer flex items-center justify-center w-60 h-15 font-bold text-white rounded-10 " +
                      blueGradient1
                    }
                    onClick={() => {
                      dispatch(
                        addAssetSliceActions.setAsset({
                          ...assetData,
                          websiteSubdomains: selectedOptions,
                        })
                      );
                      setModalOpen(() => false);
                    }}
                  >
                    Done
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </FormControl>
      </>
    );
  }
  function CS() {
    return (
      <>
        <div
          className="avatar-text"
          style={{
            display: "inline",
            // marginLeft: "2%",
            // fontSize: "20px",
            fontWeight: "600",
            marginTop: "2%",
          }}
        >
          URL
        </div>
        <label>
          <input
            style={{ height: "50px" }}
            className="second-step-input"
            placeholder="https://play.google.com/store/apps/details?id=us.zoom.videomeetings&hl=en_IN&gl=US&pli=1"
            value={assetData.appStoreUrl}
            onChange={(e) => {
              dispatch(
                addAssetSliceActions.setAsset({
                  ...assetData,
                  appStoreUrl: e.target.value,
                })
              );
            }}
          />
        </label>
      </>
    );
  }
  function IOS() {
    return (
      <>
        <div
          className="avatar-text"
          style={{
            display: "inline",
            // marginLeft: "2%",
            // fontSize: "20px",
            fontWeight: "600",
            marginTop: "2%",
          }}
        >
          App Apple Store URL
        </div>
        <br />
        <label>
          <input
            style={{ height: "50px" }}
            className="second-step-input"
            value={assetData.appStoreUrl}
            placeholde=""
            onChange={(e) => {
              dispatch(
                addAssetSliceActions.setAsset({
                  ...assetData,
                  appStoreUrl: e.target.value,
                })
              );
            }}
          />
        </label>
        <div
          className="avatar-text"
          style={{
            display: "inline",
            // marginLeft: "2%",
            // fontSize: "20px",
            fontWeight: "600",
            marginTop: "2%",
          }}
        >
          Upload File
        </div>
        <label>
          <input
            style={{ height: "150px", border: "dashed" }}
            className="second-step-input"
            type="file"
            value={assetData.appFile}
            onChange={(e) => {
              dispatch(
                addAssetSliceActions.setAsset({
                  ...assetData,
                  appFile: e.target.value,
                })
              );
            }}
          />
        </label>
      </>
    );
  }
  function Android() {
    return (
      <>
        <div
          className="avatar-text"
          style={{
            display: "inline",
            // marginLeft: "2%",
            // fontSize: "20px",
            fontWeight: "600",
            marginTop: "2%",
          }}
        >
          App Google Play Store URL
        </div>
        <br />
        <label>
          <input
            style={{ height: "50px" }}
            className="second-step-input"
            value={assetData.appStoreUrl}
            placeholde=""
            onChange={(e) => {
              dispatch(
                addAssetSliceActions.setAsset({
                  ...assetData,
                  appStoreUrl: e.target.value,
                })
              );
            }}
          />
        </label>
        <div
          className="avatar-text"
          style={{
            display: "inline",
            // marginLeft: "2%",
            // fontSize: "20px",
            fontWeight: "600",
            marginTop: "2%",
          }}
        >
          <b>Upload .APK File</b>
          <br />
        </div>
        <br />
        <label>
          <input
            style={{ height: "150px", border: "dashed" }}
            className="second-step-input"
            type="file"
            value={assetData.appFile}
            onChange={(e) => {
              dispatch(
                addAssetSliceActions.setAsset({
                  ...assetData,
                  appFile: e.target.value,
                })
              );
            }}
          />
        </label>
      </>
    );
  }

  function DropDownOption(props) {
    const isNum = props.value;
    switch (isNum) {
      case "API":
        return <API />;
      case "Website":
        return <WT />;

      case "Network":
        return <NST />;

      case "IOS":
        return <IOS />;

      case "Android":
        return <Android />;

      case "IOT":
        return <IOT />;

      case "Cloud":
        return <CS />;

      default:
        break;
    }
  }

  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";

  return (
    <div className="avatar-text w-full">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "24px",
          fontWeight: "600",
          marginBottom: "1%",
        }}
      >
        <div>Asset Type</div>

        <Tooltip
          placement="right"
          describeChild
          title=" Please select the type of asset you want to add. If you are not sure,

        select 'Other'."
        >
          <img
            src={isDarkTheme ? "/infoDark.svg" : "/info.svg"}
            alt=""
            className="w-5"
            style={{ marginLeft: "10px" }}
          />
        </Tooltip>
      </div>

      <div>
        <div className="avatar-text">
          <b>Category</b>
        </div>

        <div>
          <Select
            sx={{
              width: "100%",
              boxSizing: "border-box",
              height: "45px",
              backgroundColor: "#D9D9D940",
              border: "0px solid rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
            }}
            labelId="demo-simple-select-label"
            value={assetData.type}
            defaultValue={"Other"}
            onChange={handleChange}
            className="dark:text-white dark:bg-lightBlack3"
            required
          >
            <MenuItem value={"API"}>API Testing</MenuItem>
            <MenuItem value={"Website"}>Website Testing</MenuItem>
            <MenuItem value={"Network"}>Network Security Testing</MenuItem>
            <MenuItem value={"IOS"}>iOS</MenuItem>
            <MenuItem value={"Android"}>Android</MenuItem>
            <MenuItem value={"IOT"}>IOT</MenuItem>
            <MenuItem value={"Hardware"}>Hardware Testing</MenuItem>
            <MenuItem value={"Cloud"}>Cloud Solution</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </Select>
        </div>
      </div>

      <div>
        <DropDownOption value={assetData.type} />
      </div>

      <div>
        <div style={{ marginTop: "2%" }}>
          <b>Tags (Optional)</b>
        </div>

        <input
          style={{ height: "60px" }}
          className="second-step-input  dark:bg-lightBlack3"
          multiple
          value={assetData.tags}
          onChange={(e) => {
            dispatch(
              addAssetSliceActions.setAsset({
                ...assetData,
                tags: e.target.value,
              })
            );
          }}
        />
      </div>
    </div>
  );
}

export default SecondData;
