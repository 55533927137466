import * as React from "react";
import Box from "@mui/joy/Box";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";

import { Divider } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addVulnerabilityReportSliceActions } from "store/vulnerabilityReport";
import { cvssObjectToString } from "lib/cvss";

import { ThemeProvider } from "@mui/material/styles";

export default function SeverityInput({ options, cvssName, isMainController }) {
  const dispatch = useDispatch();

  let cvssObject = useSelector(
    (state) => state?.addVulnerabilityReport?.cvssCalculator
  );
  const currentState = cvssObject?.data?.[cvssName];

  const mainController = cvssObject?.mainController;

  const handleChange = (e) => {
    // if (reportViewType == "update") return;

    // console.log("called down");

    let obj = { ...cvssObject.data };
    obj[cvssName] = e.target.value;
    // dispatch(cvssCalculatorSliceActions.setCvssObject(obj));
    dispatch(addVulnerabilityReportSliceActions.setCvssObject(obj));
    dispatch(
      addVulnerabilityReportSliceActions.setCvssVector(cvssObjectToString(obj))
    );

    mainController &&
      // dispatch(cvssCalculatorSliceActions.setMainController(""));
      dispatch(addVulnerabilityReportSliceActions.setMainController(""));
  };

  const handleChangeForMainController = (e) => {
    // console.log("called up");
    try {
      if (Object.keys(cvssObject)?.length > 0) {
        // dispatch(cvssCalculatorSliceActions.setCvssObject(""));
        dispatch(addVulnerabilityReportSliceActions.setCvssObject(""));
        dispatch(addVulnerabilityReportSliceActions.setCvssVector(""));
      }

      // dispatch(cvssCalculatorSliceActions.setMainController(e.target.value));
      dispatch(
        addVulnerabilityReportSliceActions.setMainController(e.target.value)
      );
    } catch (e) {
      console.log(e);
    }
  };

  const isDarkMode = useSelector((state) => state.general.theme) == "dark";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        flexDirection: "row",
      }}
    >
      <RadioGroup
        row
        aria-labelledby="segmented-controls-example"
        name="currentState"
        value={isMainController ? mainController : currentState}
        onChange={
          isMainController ? handleChangeForMainController : handleChange
        }
        sx={{
          minHeight: 48,
          padding: "4px",
          borderRadius: "md",
          border: "",
          "--RadioGroup-gap": "4px",
          "--Radio-action-radius": "8px",
          display: "flex",
          flexDirection: "row",
        }}
        className="bg-white dark:bg-lightBlack border border-[#1D26EF] dark:border-[#4B65ED] "
      >
        {options?.map((item, index) => {
          let styling = {
            px: 2,
            alignItems: "center",
            fontSize: "18px",
          };

          return (
            <>
              <div className="flex">
                <Radio
                  checked={
                    isMainController
                      ? mainController == item.slice(0, 1)
                      : currentState == item.slice(0, 1)
                  }
                  key={item}
                  value={item.slice(0, 1)}
                  disableIcon
                  label={item}
                  variant="plain"
                  slotProps={{
                    action: ({ checked }) => ({
                      sx: {
                        ...(checked
                          ? {
                              "&:hover": {
                                bgcolor: isDarkMode ? "#4B65ED" : "#1D26EF", // bg color hover selected
                              },
                            }
                          : {
                              "&:hover": {
                                bgcolor: "transparent", // if not selected then hover bg color
                              },
                            }),
                      },
                    }),
                  }}
                  sx={
                    isMainController
                      ? mainController == item.slice(0, 1)
                        ? {
                            color: "#ffffff",
                            fontWeight: 700,
                            bgcolor: isDarkMode ? "#4B65ED" : "#1D26EF",
                            borderRadius: "10px",
                            ...styling,
                          }
                        : {
                            color: isDarkMode ? "#4B65ED" : "#1D26EF",
                            ...styling,
                          }
                      : currentState == item.slice(0, 1)
                      ? {
                          color: "#ffffff",
                          fontWeight: 700,
                          bgcolor: isDarkMode ? "#4B65ED" : "#1D26EF",
                          borderRadius: "10px",
                          ...styling,
                        }
                      : {
                          color: isDarkMode ? "#4B65ED" : "#1D26EF", // text color if not selected
                          ...styling,
                        }
                  }
                />

                {index != options.length - 1 && (
                  <Divider
                    orientation="vertical"
                    sx={{
                      mx: 0.5,
                      borderColor: isDarkMode ? "#4B65ED" : "#1D26EF",
                      height: "100%",
                    }}
                  />
                )}
              </div>
            </>
          );
        })}
      </RadioGroup>
    </Box>
  );
}
