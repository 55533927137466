export const columns = (role) => {
  let columns = [
    { id: "assetName", label: "Targets", minWidth: 100 },
    { id: "description", label: "Description", minWidth: 170, maxWidth: 300 },
    {
      id:
        role == "S_ENGINEER" || role == "ENGINEER" ? "performace" : "riskScore",
      label:
        role == "S_ENGINEER" || role == "ENGINEER"
          ? "Performace"
          : "Risk Score",
      minWidth: 20,
      // align: "center",
    },
    {
      id: "totalVulnerabilities",
      label: "Total Vulnerabilities",
      minWidth: 10,
      // align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "criticalSeverity",
      label: "Severity",
      minWidth: 0,
      // align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "teamMembers",
      label: "Team Members",
      minWidth: 0,
      // align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];
  if (role == "MANAGER" || role == "S_MANAGER") {
    columns = [
      ...columns,
      {
        id: "btnItem2",
        label: "",
        minWidth: 50,
        // align: "right",
      },
    ];
  } else if (role == "S_ENGINEER") {
    columns = [
      ...columns,
      // {
      //   id: "reviewExpand2",
      //   label: "",
      //   minWidth: 10,
      //   // align: "right",
      //   format: (value) => value.toLocaleString("en-US"),
      // },
    ];
  }

  return columns;
};
