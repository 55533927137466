import React from "react";
import ScanEngine from "./components/Engine";

export const ScanEngineComponent = ScanEngine;

function Index(props) {
  return <div></div>;
}

export default Index;
