import React from "react";

import InviteCompany from "./components/InviteCompany";

import Invite from "./components/Index";

export const InviteCompanyComponent = InviteCompany;
export const InviteComponent = Invite;
function index(props) {
  return <div></div>;
}

export default index;
