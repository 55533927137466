import React from "react";
import AddTarget from "./components/AddTarget/Index";
import Target from "./components/Target";

export const AddTargetComponent = AddTarget;
export const TargetComponent = Target;

function index(props) {
  return <div></div>;
}

export default index;
