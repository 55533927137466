import React, { useEffect, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { List, FormControlLabel, Checkbox } from "@mui/material";

import Select from "@mui/material/Select";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SingleReport from "./SingleReport";
import SingleComment from "./SingleComment";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import { colorMap } from "components/ui/buttons/StatusButton";
import { Link, useNavigate } from "react-router-dom";
import ColorDot from "components/ui/buttons/ColorDot";
import GreyBgButton from "components/ui/buttons/GreyBgButton";
import SeverityButton from "components/ui/buttons/SeverityButton";
import { Modal } from "@mui/material";
import clipboardCopy from "clipboard-copy";

import CustomMdEditor from "components/imports/MarkdownEditor/CustomMdEdtor";
import { useDispatch, useSelector } from "react-redux";
import { postFetch } from "lib/fetch";
import { toast } from "react-toastify";

import { CamelCase } from "Util/CamelCase";
import ButtonWithLoader from "components/ui/buttons/ButtonWithLoader";
import Loader from "components/ui/loaders/Loader";
import { UserImage } from "components/ui/ImageComponent";
import Popup from "reactjs-popup";
import { handleFileUpload } from "services/uploadFile";
import { blueGradient1 } from "components/tailwindClasses";
import VulnerabilityReportSkeleton from "components/ui/loaders/VulnerabilityReportSkeleton";
import { Search } from "components/ui/inputs/Search";
import SimilarityCircle from "components/ui/buttons/SimilarityCircle";

function Index({ data, setData, loading = false }) {

  const user = useSelector((state) => state.user.data);
  const currentTargetName = useSelector(
    (state) => state.targetDashboard.currentTarget.name
  );

  const [state, setState] = useState("");
  const [stateChangeDescription, setStateChangeDescription] = useState("");

  useEffect(() => {
    data.status && setState(() => data.status);
  }, [data]);

  // for changing state to duplicate

  const [dataDuplicate, setDataDuplicate] = useState([]);
  const [selectedDuplicate, setSelectedDuplicate] = useState("");
  const [duplicateSearch, setDuplicateSearch] = useState("");

  const handleCheckedChange = (id) => {
    setSelectedDuplicate(() => id);
  };

  const handleChange = async (event) => {
    const newState = event.target.value;

    setState(newState);

    if (newState == "Duplicate") {
      const res = await postFetch("/vulnerability/listSimilarVulnerabilities", {
        vuln_id: data._id,
      });
      if (res?.status == "ok") {
        setDataDuplicate(() => res.data);
      }
    }
  };

  // const states = {
  //   New: "New",
  //   Informative: "Informative",
  //   Triaged: "Triage",
  //   Resolve: "Resolve",
  //   Duplicate: "Duplicate",
  //   Retest: "Retest",
  //   NotApplicable: "Not Applicable",
  //   WontFix: "Won't Fix",
  // };

  const states = {
    Initial: "Initial",
    AcceptedRisk: "Accepted Risk",
    FalsePositive: "False Positive",
  };

  const [open, setOpen] = useState(false);

  const handleStateChange = (e) => {
    e.preventDefault();
    if (state == "Duplicate") {
      if (!selectedDuplicate) {
        toast.info("please select a vulnerability to mark duplicate against");
        return;
      }
    }
    setOpen(() => false);
  };

  // state for post button
  const [postLoading, setPostLoading] = useState(false);

  const handlePostClick = async () => {
    if (!markdownValue) {
      toast.info("please add comment before posting");
      return;
    }
    if (!Object.keys(states).includes(state)) {
      toast.info("please select a state");
      return;
    }
    if (!data.company_bug_id) {
      toast.error("Some error occured, try later");
      return;
    }

    setPostLoading(() => true);

    if (data?.status?.toLowerCase() === state?.toLowerCase()) {
      // this means status was not changed

      const res = await postFetch("/vulnerability/addComment", {
        vuln_id: data.company_bug_id,
        content: markdownValue,
      });

      if (res.status == "ok" && res.newComment) {
        let updatedComments = data.comments || [];
        updatedComments.push(res.newComment);

        setData((data) => {
          return { ...data, comments: updatedComments };
        });
        setMarkdownValue(() => "");
      }

      setPostLoading(() => false);

      return;
    }

    if (user.role === "MANAGER" || user.role === "ENGINEER") {
      let isSuggestion = user.role == "MANAGER" ? false : true;

      let res;
      if (isSuggestion) {
        res = await postFetch("/vulnerability/suggestStatusChange", {
          vuln_id: data.company_bug_id,
          new_status: state,
          description: markdownValue,
        });
      } else {
    
        res = await postFetch("/scanner/companyBug/changeStatus", {
          bug_id: data.company_bug_id,
          newStatus: state,
          content:markdownValue 
        });
      }

      if (res?.status == "ok") {
        toast.success(
          `Vulnerability state ${
            isSuggestion ? "suggested" : "updated"
          } : ${state}`
        );

          
        let updatedComments = data.comments || [];
        if(updatedComments === undefined){
          updatedComments = [];
        }

    
        res.data = {
          ...res.data,
          message: res.message
        }

        updatedComments.push(res.data);

        setData((data) => {
          return isSuggestion
            ? {
                ...data,
                comments: updatedComments,
              }
            : {
                ...data,
                status: state,
                comments: updatedComments,
              };
        });

        setMarkdownValue(() => "");
      } else toast.error("Some error occured, try later");
    }

    setPostLoading(() => false);
  };

  // state for markdown
  const [preview, setPreview] = useState("edit");

  // for comment markdown
  const [markdownValue, setMarkdownValue] = useState("");

  const dispatch = useDispatch();

  const isDarkMode = useSelector((state) => state.general.theme) == "dark";

  // for comment
  const [filesAttached, setFilesAttached] = useState([]);

  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files);

    const res = await handleFileUpload(files);

    res.forEach((file_url)=>{
      
    setMarkdownValue((current)=>current+"\n![image]("+file_url+")");
    })
    setFilesAttached((filesAttached) => [...filesAttached, ...res]);
  };

  const handleFileRemove = (file) => {
    setFilesAttached(() =>
      filesAttached.filter((prevFile) => prevFile !== file)
    );
  };
  // for image
  const [copied, setCopied] = useState({});

  const handleCopyToClipboard = async (imageUrl, index) => {
    try {
      await clipboardCopy(imageUrl);

      setCopied(() => ({ [index]: true }));

      setTimeout(() => {
        setCopied(() => ({ [index]: false }));
      }, 1000);
    } catch (error) {
      console.error("Failed to copy:", error);
    }
  };

  const navigate = useNavigate();
  const handleBackClick = (e) => {
    navigate(-1);
  };

  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";

  return (
    <>
      <div
        style={{
          width: "75%",
          marginRight: "1%",
          //   border: "1px solid blue",
          padding: "1%",
          borderRadius: "10px",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        <div
          style={{
            fontSize: "18px",
            paddingBottom: "20px",
          }}
          className="border-b border-opacity-20 dark:border-b9 flex items-center"
        >
          <span>
            <Link
              onClick={handleBackClick}
              className="no-underline self-center mr-2"
            >
              <img
                src={isDarkTheme ? "/leftArrowDark.svg" : "/leftArrow.svg"}
                alt=""
                className="inline mr-3 text-center fill-[#1D26EF]"
              />
            </Link>
          </span>
          <div
            style={{
              color: "white",
              background: "#539486",
              borderRadius: "5px",
              padding: "6px 12px",
              marginRight: "1%",
            }}
          >
            {loading ? (
              <Skeleton variant="text" width={200} />
            ) : (
              "#" + data.company_bug_id
            )}
          </div>
          <span
            style={{
              marginRight: "1%",
            }}
          >
            {currentTargetName}
          </span>
          <Link
            style={{
              marginRight: "1%",
            }}
            to={!loading && data?.url}
            className="no-underline"
          >
            {loading ? (
              <Skeleton variant="text" width={150} />
            ) : data?.url?.length < 30 ? (
              data.url
            ) : (
              data?.url?.slice(0, 30) + "..."
            )}
          </Link>
        </div>

        <div>
          {loading ? (
            <VulnerabilityReportSkeleton />
          ) : (
            <SingleReport data={data} />
          )}
        </div>

        {data?.comments && data.comments.length > 0 && (
          <div>
            {data.comments.map((comment) => {
              return <SingleComment data={comment} setData={setData} />;
            })}
          </div>
        )}

        <div style={{ display: "flex", padding: "1% 0" }}>
          <div>
            <UserImage
              name={user?.name}
              image={user?.profileImage}
              company={user?.companyName}
              email={user?.email}
              style={{
                width: "50px",
                height: "50px",
                marginRight: "10px",
              }}
              position={"right center"}
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "80%", paddingRight: "1%" }}>
                <MenuItem
                  sx={{
                    width: "100%",
                    height: "45px",
                    backgroundColor: "#D9D9D940",
                    border: "0px solid rgba(0, 0, 0, 0.25)",
                    borderRadius: "5px",
                    border: "1px solid rgba(0, 0, 0, .1)",
                  }}
                  disabled={
                    !(user.role == "ENGINEER" || user.role == "MANAGER")
                  }
                  onClick={() => {
                    // only manager n engineer can effect it
                    // (user.role == "ENGINEER" || user.role == "MANAGER") &&
                    // role gets handled by disabled
                    setOpen(() => true);
                  }}
                  // className="border-b1"
                >
                  <em>
                    {console.log(state)}
                    {state ? (
                      <span>
                        <FiberManualRecordRoundedIcon
                          sx={{
                            color: `${colorMap[state]}`,
                            fontSize: "20px",
                            marginRight: "5px",
                          }}
                        />
                        {state}
                      </span>
                    ) : user?.role == "MANAGER" ? (
                      "Change State"
                    ) : (
                      "Suggest State Change"
                    )}
                  </em>
                </MenuItem>
                {(user.role == "ENGINEER" || user.role == "MANAGER") && (
                  <Modal
                    open={open}
                    // onClose={() => {
                    //   setOpen(() => false);
                    // }}
                    // this prevents it from closing on click outside, and only closes on cancel click now
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <div className="w-[870px] bg-white dark:bg-lightBlack dark:text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-10">
                      <div className="text-xl font-bold text-center mt-2p mb-1p">
                        {user?.role == "MANAGER" && "Change State"}
                        {user?.role == "ENGINEER" && "Suggest State Change"}
                      </div>
                      <div className="bg-lightBlue dark:bg-[#3B3E4E] text-sm p-2p pr-3p">
                        <div className="mt-1">
                          <span className="mr-2 font-medium">
                            Created On :{" "}
                          </span>
                          <span>
                            {new Date(data?.created_on).toDateString()}
                          </span>
                        </div>

                        <div className="flex justify-between mt-1p">
                          <div className="flex flex-col">
                            <div className="font-medium">Status</div>
                            <div className="mt-4">
                              <ColorDot type={data?.status} />
                              <span className="ml-2">{data?.status}</span>
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <div className="font-medium">Title</div>
                            <div className="mt-4">{data?.name}</div>
                          </div>
                          <div className="flex flex-col">
                            <div className="font-medium">Type</div>
                            <div className="mt-4">
                              <GreyBgButton width={"auto"} padding={"0 5px"}>
                                {data?.type}
                              </GreyBgButton>
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <div className="font-medium">Severity</div>
                            <div className="mt-4">
                              {SeverityButton(data?.severity)}
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <div className="font-medium">Team Member</div>
                            <div className="mt-2">
                              <Avatar
                                alt={data?.researcherName}
                                src={data?.researcherProfileImage}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <form action="" onSubmit={handleStateChange}>
                        <div className="mx-2p pt-2p ">
                          <Select
                            sx={{
                              width: "100%",
                              height: "45px",
                              backgroundColor: "#D9D9D940",
                              border: "0px solid rgba(0, 0, 0, 0.25)",
                              borderRadius: "5px",
                            }}
                            value={state}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            required
                            className="dark:text-white dark:bg-lightBlack3"
                          >
                            <MenuItem value="">
                              <em>Change State</em>
                            </MenuItem>

                            {Object.keys(states)
                              ?.map((item, i) => {
                                return (
                                  <MenuItem value={item}>
                                    <span className="mr-2">
                                      {" "}
                                      <ColorDot type={item} />
                                    </span>
                                    {states[item]}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </div>
                        {state == "Duplicate" && (
                          <div>
                            <div
                              style={{
                                background: " rgba(217, 217, 217, 0.25)",
                              }}
                              className="my-2 px-2p py-3 text-sm font-medium flex justify-between"
                            >
                              <div> Duplicate Bug List</div>
                              <div className="opacity-50 mr-2">
                                Maximum Selections : 1
                              </div>
                            </div>
                            <div className="h-9 p-4 mx-2p text-sm  rounded bg-inputBg flex items-center justify-start">
                              <div className="mr-2">
                                <img src="/searchIcon.svg" alt="" />
                              </div>
                              <input
                                type="text"
                                className="bg-transparent focus:outline-none border-none grow"
                                placeholder="Search"
                                value={duplicateSearch}
                                onChange={(e) => {
                                  setDuplicateSearch(() => e.target.value);
                                }}
                              />
                            </div>
                            <div className="mx-2p">
                              <TableContainer
                                sx={{
                                  maxHeight: "200px",
                                }}
                              >
                                <Table stickyHeader aria-label="sticky table">
                                  <TableBody>
                                    {dataDuplicate
                                      ?.filter((s) =>
                                        s.name
                                          .toLowerCase()
                                          .includes(
                                            duplicateSearch.toLowerCase()
                                          )
                                      )
                                      ?.map((option, index) => {
                                        const isItemSelected =
                                          selectedDuplicate == option._id;

                                        return (
                                          <TableRow className="font-medium text-sm flex  items-center justify-between">
                                            <TableCell
                                              padding="checkbox"
                                              onClick={(event) => {
                                                handleCheckedChange(option._id);
                                              }}
                                            >
                                              <Checkbox
                                                color={"primary"}
                                                checked={isItemSelected}
                                                inputProps={{
                                                  "aria-labelledby": option._id,
                                                }}
                                              />
                                            </TableCell>

                                            <TableCell className="mr-10">
                                              <span>
                                                <FiberManualRecordRoundedIcon
                                                  sx={{
                                                    color: `${
                                                      colorMap[
                                                        option.status
                                                      ]
                                                    }`,
                                                    fontSize: "20px",
                                                    marginRight: "5px",
                                                  }}
                                                />
                                              </span>
                                              {option.status}
                                            </TableCell>
                                            <TableCell className="mr-10">
                                              {option.name}
                                            </TableCell>
                                            <TableCell className="mr-10 ">
                                              {option.type}
                                            </TableCell>
                                            <TableCell className="mr-10">
                                              {SeverityButton(option.severity)}
                                            </TableCell>
                                            <TableCell>
                                              <SimilarityCircle
                                                percentage={
                                                  option?.matched_percentage
                                                }
                                                circleSize={50}
                                                fontSizeText={10}
                                                fontSizeMatch={6}
                                              />
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        )}
                        <div className="mx-2p pb-2">
                          {state != "" && state != "Duplicate" && (
                            <div>
                              <div className="my-2 font-medium">Comment</div>
                              <textarea
                                value={markdownValue}
                                onChange={(e) => {
                                  setMarkdownValue(() => e.target.value);
                                }}
                                required
                                name=""
                                id=""
                                rows="4"
                                style={{
                                  resize: "none",
                                  width: "100%",
                                  boxSizing: "border-box",
                                  padding: "2%",
                                  background: "rgba(0, 0, 0, 0.08)",
                                  border: " 1px solid rgba(0, 0, 0, 0.25)",
                                  borderRadius: "10px",
                                  fontSize: "16px",
                                }}
                                placeholder="Add a comment"
                              />
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                          className="border-t-2 border-b2 dark:border-b9 mx-2p py-2p"
                        >
                          <button
                            style={{
                              // marginTop: "3.2%",
                              textDecoration: "none",
                              color: "#2D3B51 !important",
                              marginRight: "5vw",
                              fontWeight: "500",
                            }}
                            onClick={() => {
                              setState(() => "");
                              setOpen(() => false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className={
                              blueGradient1 +
                              " w-60 flex items-center justify-center h-15 font-bold text-white rounded-10"
                            }
                            type="submit"
                          >
                            Done
                          </button>
                        </div>
                      </form>
                    </div>
                  </Modal>
                )}
              </div>

              <div style={{ width: "20%" }}>
                <button
                  onClick={handlePostClick}
                  className={
                    blueGradient1 +
                    " flex items-center justify-center w-full rounded-10 h-12 font-bold"
                  }
                  disabled={postLoading}
                >
                  {postLoading ? <Loader /> : "Post"}
                </button>
              </div>
            </div>

            {/* <textarea
              name=""
              id=""
              rows="5"
              style={{
                resize: "vertical",
                width: "100%",
                boxSizing: "border-box",
                margin: "1% 0",
                padding: "1%",
                background: "rgba(0, 0, 0, 0.08)",
                border: " 1px solid rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
                fontSize: "16px",
              }}
              placeholder="Add a Comment"
            ></textarea> */}

            <div
              className="my-2"
              // data-color-mode="light"
            >
              <CustomMdEditor
                defaultValue={"Add a Comment"}
                preview={preview}
                setPreview={setPreview}
                value={markdownValue}
                onChange={(value) => {
                  setMarkdownValue(() => value);
                }}
                required={true}
              />
            </div>

            <div className="bg-white  dark:bg-lightBlack  dark:text-white   rounded-10 mt-1p">
              <div className="font-semibold mb-1p">Upload File</div>
              {filesAttached && filesAttached?.length > 0 && (
                <div className="mb-1">
                  {filesAttached?.map((imageUrl, index) => {
                    return (
                      <div key={index} className="relative min-h-[50px] mt-2p">
                        <div className="relative flex justify-between">
                          {/* <ImageModal
                      small={imageUrl}
                      large={imageUrl}
                      className="max-w-[90%] h-auto rounded"
                    /> */}
                          <img
                            src={imageUrl}
                            alt="img"
                            className="max-w-[90%] h-auto rounded"
                          />
                          <div className="">
                            <div
                              onClick={() => {
                                handleFileRemove(imageUrl);
                              }}
                              className="p-1 bg-black rounded-full hover:bg-gray-900 transition duration-300 text-white cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="w-4 h-4"
                              >
                                <path d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-between mt-2">
                          <div className="text-gray-600 break-words flex-wrap w-[90%] dark:text-white italic">
                            {imageUrl}
                          </div>
                          <div className="relative ">
                            <div
                              onClick={() => {
                                handleCopyToClipboard(imageUrl, index);
                              }}
                              className="cursor-pointer"
                            >
                              <img src="/copy.svg" alt="" className="w-5  " />
                            </div>
                            <Popup
                              trigger={<div />}
                              position="bottom center"
                              contentStyle={{
                                padding: "8px",
                                background: "#000",
                                color: "#fff",
                                borderRadius: "4px",
                                fontSize: "14px",
                              }}
                              closeOnDocumentClick
                              open={copied?.[index]}
                            >
                              Copied!
                            </Popup>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {filesAttached?.length > 0 && (
                    <div className="text-xs mt-2p">
                      You can copy this url and use it in your report (ctrl +
                      k), or using add image icon{" "}
                    </div>
                  )}
                </div>
              )}
              <label style={{}}>
                <label htmlFor="cpInput0323">
                  <input
                    onChange={handleFileSelect}
                    id={"cpInput0323"}
                    type="file"
                    multiple
                    className="hidden bg-inputBg dark:bg-inputBgDark"
                  />
                  <div className="flex  justify-center items-center h-28 bg-inputBg dark:bg-inputBgDark rounded border-dashed border border-black">
                    <img src="/fileUpload.svg" alt="" sizes="" />
                    <span className="text-[#1D26EF] dark:text-[#4B65ED] font-bold ml-1p">
                      Browse files from your computer
                    </span>
                  </div>
                </label>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
