import React, { useState, useEffect } from "react";
import { Input, MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { input1 } from "components/tailwindClasses";
import { addScanEngineSliceActions } from "store/scanner/addScanEngineSlice";
import { addScanProfileSliceActions } from "store/scanner/addScanProfileSlice";
import { Link } from "react-router-dom";

export const reportFormats = [
  { id: "pdf", label: "Portable Document Format (PDF)" },
  { id: "zip", label: "Compressed HTML pages (ZIP) " },
  { id: "html", label: "Web Archive (HTML) " },
  { id: "E-PDF", label: " Encrypted Portable Document Format (PDF)" },
  { id: "ppt", label: "Microsoft PowerPoint Document (PPT) " },
  { id: "xml", label: "Extensible Markup Language (XML)  " },
  { id: "csv", label: "Comma-Separated Value (CSV) " },
  { id: "csv2", label: "Comma-Separated Value (CSV) V2 " },
];

export const scanSpeeds = [
  {
    id: "quick",
    label: "Quick",
  },
  {
    id: "medium",
    label: "Medium",
  },
];

function Index(props) {
  const dispatch = useDispatch();

  const email = useSelector(
    (state) => state.scanner.addScanProfile.step4.email
  );
  const reportFormat = useSelector(
    (state) => state.scanner.addScanProfile.step4.reportFormat
  );
  const password = useSelector(
    (state) => state.scanner.addScanProfile.step4.password
  );
  const confirmPassword = useSelector(
    (state) => state.scanner.addScanProfile.step4.confirmPassword
  );

  return (
    <form id="step4-form">
      <div
        style={{
          padding: "2%",
          paddingBottom: "3%",
          borderRadius: "10px",
          marginBottom: "1%",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        <div className="avatar-text mt-1">Email</div>
        <input
          value={email}
          onChange={(e) => {
            dispatch(addScanProfileSliceActions.setEmail(e.target.value));
          }}
          required={reportFormat || password || confirmPassword}
          type="email"
          style={{ height: "50px" }}
          className={input1}
          placeholder="Type Here"
        />

        <div className="avatar-text mt-1">Report Format</div>

        <div>
          <Select
            sx={{
              width: "100%",
              boxSizing: "border-box",
              height: "45px",
              backgroundColor: "#D9D9D940",
              border: "0px solid rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
            }}
            className="dark:text-white bg-inputBg dark:bg-inputBgDark"
            required={email || password || confirmPassword}
            value={reportFormat}
            onChange={(e) => {
              dispatch(
                addScanProfileSliceActions.setReportFormat(e.target.value)
              );
            }}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            MenuProps={{
              PaperProps: { sx: { maxHeight: 400 } },
            }}
          >
            <MenuItem value="">
              <em>Select </em>
            </MenuItem>

            {reportFormats?.map((f, i) => {
              return <MenuItem value={f.id}>{f.label}</MenuItem>;
            })}
          </Select>
        </div>

        <div className="avatar-text mt-3p pb-2p border-b dark:border-b9 font-medium w-full">
          Secure Report
        </div>

        <div className="mt-4 flex">
          <div className="grow mr-1">
            <div className="avatar-text" style={{ fontWeight: "500" }}>
              Password
            </div>

            <div>
              <input
                type="password"
                value={password}
                onChange={(e) => {
                  dispatch(
                    addScanProfileSliceActions.setPassword(e.target.value)
                  );
                }}
                required={confirmPassword}
                className={input1}
                name="fourthStepPassword"
                placeholder="Type Here"
                pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$" // Add the regex pattern here
              />
            </div>
          </div>
          <div className="grow ml-1">
            <div className="avatar-text" style={{ fontWeight: "500" }}>
              Confirm Password
            </div>

            <div>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => {
                  dispatch(
                    addScanProfileSliceActions.setConfirmPassword(
                      e.target.value
                    )
                  );
                }}
                className={input1}
                required={password}
                name="fourthStepConfirmPassword"
                placeholder="Type Here"
                pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$" // Add the regex pattern here
              />
            </div>
          </div>
        </div>
        <div className="text-xs mt-2">
          Include all of the following:
          <ul class="list-disc">
            <li>o An uppercase character</li>
            <li>o A lowercase character</li>
            <li>o A number</li>
            <li>o A special character</li>
          </ul>
        </div>
      </div>
    </form>
  );
}

export default Index;
