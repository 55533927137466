import React from "react";
import PieChart from "./Doughnut";

function Index({ type = "vulnerability", values = [0, 0, 0, 0] }) {
  // type can be vulnerability or status

  // new informative triaged resolve
  // critical high medium low

  let toPlot =
    type == "status"
      ? {
          values: values,
          backgroundColors: [
            { red: 25, green: 195, blue: 230 },
            { red: 159, green: 155, blue: 155 },
            { red: 228, green: 31, blue: 114 },
            { red: 178, green: 198, blue: 21 },
          ],
        }
      : {
          values: values,
          backgroundColors: [
            { red: 206, green: 55, blue: 51 },
            { red: 236, green: 136, blue: 48 },
            { red: 253, green: 188, blue: 44 },
            { red: 55, green: 164, blue: 64 },
          ],
        };

  return <PieChart data={toPlot} />;
}

export default Index;
