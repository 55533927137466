import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { columns } from "./Report";
import Loader from "components/ui/loaders/Loader";
import TableLoadingSkeleton from "components/ui/loaders/TableSkeleton";

function ReportTable({ rows, onDownloadClick, isLoading }) {
  // table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div
      style={{ background: "white", marginTop: "1%" }}
      className="rounded overflow-hidden"
    >
      <div
        style={{ fontSize: "14px", padding: "1%" }}
        className="dark:bg-lightBlack dark:text-white"
      >
        {rows.length} Reports
      </div>
      <TableContainer style={{}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  // style={{
                  // minWidth: column.minWidth,
                  // fontWeight: "600",
                  // paddingX: "3%",
                  // background: "rgba(217, 217, 217, 0.25)",
                  // }}
                  sx={{ bgcolor: "rgba(217, 217, 217, 0.25)" }}
                  className=" dark:bg-lightBlack3 dark:text-lightBlack1"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading && rows?.length == 0 ? (
            <TableLoadingSkeleton
              columnCount={columns?.length}
              tableCellClassName={"dark:bg-lightBlack dark:text-white "}
            />
          ) : (
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      {columns.map((column) => {
                        const value = row[column.id];

                        const isDownloadCell = column.id == "download";

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className={
                              isDownloadCell
                                ? "dark:bg-lightBlack dark:text-white cursor-pointer"
                                : "dark:bg-lightBlack dark:text-white "
                            }
                            onClick={() => {
                              isDownloadCell && onDownloadClick(row?.assetId);
                            }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="dark:bg-lightBlack dark:text-white"
      />
    </div>
  );
}

export default ReportTable;
