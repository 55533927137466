import React, { useState, useEffect, useRef } from "react";
import { postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import DifferenceViewer from "components/imports/DifferenceVeiwer";
import FileSelection from "./FileSelection";
import StatusSelection from "./StatusSelection";
import CompareReportsLayout from "features/dashboard/layouts/CompareReportLayout";
import { useDispatch, useSelector } from "react-redux";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";

import { createReport } from "./CreateReportFile";
import StatusButton from "components/ui/buttons/StatusButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import { MarkdownText } from "components/imports/MarkdownEditor/CustomMdEdtor";

function Index(props) {
  const location = useLocation();

  const navigate = useNavigate();

  if (!location?.state?.fromApp) {
    navigate("/dashboard");
  }
  const dispatch = useDispatch();
  const [bugsData, setBugsData] = useState([]);
  const [firstOption, setFirstOption] = useState("");
  const [secondOption, setSecondOption] = useState("");

  const selectedBugs = useSelector(
    (state) => state.targetDashboard.selectedBugs
  );
  const statusChangeAllowed = useSelector(
    (state) => state.targetDashboard.statusChangeAllowed
  );

  useEffect(() => {
    let storedSelectedBugs = sessionStorage.getItem("selectedBugs");

    if (!selectedBugs && storedSelectedBugs) {
      storedSelectedBugs = JSON.parse(storedSelectedBugs);
      storedSelectedBugs?.length > 0 &&
        dispatch(
          targetDashboardSliceActions.setSelectedBugs(storedSelectedBugs)
        );
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      sessionStorage.setItem("selectedBugs", JSON.stringify(selectedBugs));

      if (!selectedBugs?.length >= 2) {
        return;
      }

      const res = await postFetch("/bug/getBugsData", { ids: selectedBugs });

      if (res.status === "ok") {
        setBugsData(res.data);
      } else {
        toast.error("Some error occurred. Please try again later!");
      }
    };
    getData();
  }, [selectedBugs]);

  useEffect(() => {
    if (selectedBugs.length > 0) {
      setFirstOption(selectedBugs[0]);
      setSecondOption(selectedBugs[1]);
    }
  }, [selectedBugs]);

  const firstOptionData = bugsData?.find((bug) => bug._id === firstOption);
  const secondOptionData = bugsData?.find((bug) => bug._id === secondOption);

  const [similarity, setSimilarity] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const res = await postFetch("/bug/checkSimilarityOfTwo", {
        bug1: firstOption,
        bug2: secondOption,
      });

      if (res?.status == "ok") {
        setSimilarity(() => Math.floor(res?.score));
      } else setSimilarity(() => null);
    };

    if (firstOption && secondOption) getData();
    else setSimilarity(() => null);
  }, [firstOption, secondOption]);

  return (
    <div className="p-1p ">
      <CompareReportsLayout
        firstOptionData={firstOptionData}
        secondOptionData={secondOptionData}
        markDuplicate={(id) => {
          setBugsData(() => {
            return bugsData.map((bug, i) => {
              if (bug._id == id) {
                bug.duplicate = true;
              }
              return bug;
            });
          });
        }}
        percentage={similarity}
      >
        <div className=" rounded-t overflow-hidden box-border dark:border-b  dark:text-white dark:border-b9">
          <div className="flex">
            <div className="w-1/2 mr-[.5%]">
              <FileSelection
                option={firstOption}
                optionData={firstOptionData}
                setOption={setFirstOption}
                bugsData={bugsData.filter((bug) => bug._id != secondOption)}
              />
            </div>
            <div className="w-1/2 ml-[.5%]">
              <FileSelection
                option={secondOption}
                optionData={secondOptionData}
                setOption={setSecondOption}
                bugsData={bugsData.filter((bug) => bug._id != firstOption)}
              />
            </div>
          </div>

          <div className="flex">
            <div className="mr-[.5%] w-1/2 dark:bg-lightBlack3 bg-lightBlack1 p-1p">
              <div className="flex justify-between">
                <div className="max-w-[80%]">
                  <div className="flex flex-wrap">
                    <span className="mr-2">
                      {StatusButton(firstOptionData?.status)}
                    </span>
                    {firstOptionData?._id && (
                      <span
                        style={{
                          color: "white",
                          background:
                            firstOptionData?.status?.toLowerCase() ===
                            "duplicate"
                              ? "#EFD237"
                              : "#539486",
                          borderRadius: "5px",
                          padding: "0px 10px",
                          marginRight: "8px",
                          height: "27px",
                        }}
                      >
                        #{firstOptionData?._id}
                      </span>
                    )}
                    <span className="font-bold">{firstOptionData?.name}</span>
                  </div>
                  <div className="mt-1 flex flex-wrap">
                    <span className="font-semibold">Target URL : </span>
                    <Link
                      className="no-underline"
                      style={{
                        wordWrap: "break-word",
                        maxWidth: "100%",
                        display: "inline-block",
                      }}
                    >
                      {firstOptionData?.url}
                    </Link>
                  </div>
                  <div className="mt-1">
                    <span className="font-semibold">Asset Name : </span>
                    <span>{firstOptionData?.parent_asset}</span>
                  </div>
                </div>
                <div>
                  <div
                    className={
                      firstOptionData?.severity === "None"
                        ? "rounded-full w-20 h-20  flex flex-col items-center justify-center font-bold text-white bg-gray-500"
                        : `rounded-full w-20 h-20  flex flex-col items-center justify-center font-bold text-white bg-${firstOptionData?.severity?.toLowerCase()}`
                    }
                  >
                    <div className="text-2xl">{firstOptionData?.cvss}</div>
                    <div className="font-semibold leading-3 text-xs">
                      {firstOptionData?.severity}
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-1p border border-b1 dark:border-b9"></div>
              <div className="flex flex-wrap">
                <div className="w-1/2">
                  <div className="flex">
                    <span className="font-semibold">Category:</span>
                    <span className="ml-1">{firstOptionData?.category}</span>
                  </div>
                  <div className="flex mt-1">
                    <span className="font-semibold">Parameter:</span>
                    <span className="ml-1">
                      {firstOptionData?.vulnerable_parameter}
                    </span>
                  </div>
                  <div className="flex mt-1">
                    <span className="font-semibold">Target IP:</span>
                    <span className="ml-1"></span>
                  </div>
                  <div className="flex mt-1">
                    <span className="font-semibold">Severity:</span>
                    <span className="ml-1">{firstOptionData?.severity}</span>
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="flex">
                    <span className="font-semibold">CWE ID:</span>
                    <span className="ml-1">{firstOptionData?.cwe}</span>
                  </div>
                  <div className="flex mt-1">
                    <span className="font-semibold">OWASP:</span>
                    <span className="ml-1"></span>
                  </div>
                  <div className="flex mt-1">
                    <span className="font-semibold">CVE ID:</span>
                    <span className="ml-1"></span>
                  </div>
                  <div className="flex mt-1">
                    <span className="font-semibold">Weakness:</span>
                    <span className="ml-1">{firstOptionData?.type}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-[.5%] w-1/2 dark:bg-lightBlack3 bg-lightBlack1 p-1p">
              <div className="flex justify-between">
                <div className="">
                  <div className="flex flex-wrap">
                    <span className="mr-2">
                      {StatusButton(secondOptionData?.status)}
                    </span>
                    {secondOptionData?._id && (
                      <span
                        style={{
                          color: "white",
                          background:
                            secondOptionData?.status?.toLowerCase() ===
                            "duplicate"
                              ? "#EFD237"
                              : "#539486",
                          borderRadius: "5px",
                          padding: "0px 10px",
                          marginRight: "8px",
                          height: "27px",
                        }}
                      >
                        #{secondOptionData?._id}
                      </span>
                    )}
                    <span className="font-bold">{secondOptionData?.name}</span>
                  </div>
                  <div className="mt-1">
                    <span className="font-semibold">Target URL : </span>
                    <Link
                      className="no-underline"
                      style={{
                        wordWrap: "break-word",
                        maxWidth: "100%",
                        display: "inline-block",
                      }}
                    >
                      {secondOptionData?.url}
                    </Link>
                  </div>
                  <div className="mt-1">
                    <span className="font-semibold">Asset Name : </span>
                    <span>{secondOptionData?.parent_asset}</span>
                  </div>
                </div>
                <div>
                  <div
                    className={
                      secondOptionData?.severity === "None"
                        ? "rounded-full w-20 h-20  flex flex-col items-center justify-center font-bold text-white bg-gray-500"
                        : `rounded-full w-20 h-20  flex flex-col items-center justify-center font-bold text-white bg-${secondOptionData?.severity?.toLowerCase()}`
                    }
                  >
                    <div className="text-2xl">{secondOptionData?.cvss}</div>
                    <div className="font-semibold leading-3 text-xs">
                      {secondOptionData?.severity}
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-1p border border-b1 dark:border-b9"></div>
              <div className="flex flex-wrap">
                <div className="w-1/2">
                  <div className="flex">
                    <span className="font-semibold">Category:</span>
                    <span className="ml-1">{secondOptionData?.category}</span>
                  </div>
                  <div className="flex mt-1">
                    <span className="font-semibold">Parameter:</span>
                    <span className="ml-1">
                      {secondOptionData?.vulnerable_parameter}
                    </span>
                  </div>
                  <div className="flex mt-1">
                    <span className="font-semibold">Target IP:</span>
                    <span className="ml-1"></span>
                  </div>
                  <div className="flex mt-1">
                    <span className="font-semibold">Severity:</span>
                    <span className="ml-1">{secondOptionData?.severity}</span>
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="flex">
                    <span className="font-semibold">CWE ID:</span>
                    <span className="ml-1">{secondOptionData?.cwe}</span>
                  </div>
                  <div className="flex mt-1">
                    <span className="font-semibold">OWASP:</span>
                    <span className="ml-1"></span>
                  </div>
                  <div className="flex mt-1">
                    <span className="font-semibold">CVE ID:</span>
                    <span className="ml-1"></span>
                  </div>
                  <div className="flex mt-1">
                    <span className="font-semibold">Weakness:</span>
                    <span className="ml-1">{secondOptionData?.type}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex">
            <div className="w-1/2 mr-[.5%] p-1p dark:bg-lightBlack bg-white">
              <div className="font-bold">Proof Of Concept</div>
              <div className="font-bold mt-1p">Description</div>
            </div>
            <div className="w-1/2 ml-[.5%] p-1p dark:bg-lightBlack bg-white">
              <div className="font-bold">Proof Of Concept</div>
              <div className="font-bold mt-1p">Description</div>
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 mr-[.5%] p-1p dark:bg-lightBlack bg-white">
              <MarkdownText data={firstOptionData?.description} />
            </div>
            <div className="w-1/2 ml-[.5%] p-1p dark:bg-lightBlack bg-white">
              <MarkdownText data={secondOptionData?.description} />
            </div>
          </div>

          <div className="flex">
            <div className="w-1/2 mr-[.5%] p-1p dark:bg-lightBlack bg-white">
              <div className="font-bold mt-1p">Impact</div>
            </div>
            <div className="w-1/2 ml-[.5%] p-1p dark:bg-lightBlack bg-white">
              <div className="font-bold mt-1p">Impact</div>
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 mr-[.5%] p-1p dark:bg-lightBlack bg-white">
              <MarkdownText data={firstOptionData?.impact} />
            </div>
            <div className="w-1/2 ml-[.5%] p-1p dark:bg-lightBlack bg-white">
              <MarkdownText data={secondOptionData?.impact} />
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 mr-[.5%] p-1p dark:bg-lightBlack bg-white">
              <div className="font-bold mt-1p">Remediation</div>
            </div>
            <div className="w-1/2 ml-[.5%] p-1p dark:bg-lightBlack bg-white">
              <div className="font-bold mt-1p">Remediation</div>
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 mr-[.5%] p-1p dark:bg-lightBlack bg-white">
              <MarkdownText data={firstOptionData?.remediation} />
            </div>
            <div className="w-1/2 ml-[.5%] p-1p dark:bg-lightBlack bg-white">
              <MarkdownText data={secondOptionData?.remediation} />
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 mr-[.5%] p-1p dark:bg-lightBlack bg-white">
              <div className="font-bold mt-1p">References</div>
            </div>
            <div className="w-1/2 ml-[.5%] p-1p dark:bg-lightBlack bg-white">
              <div className="font-bold mt-1p ital">References</div>
            </div>
          </div>
          <div className="flex">
            <div className="w-1/2 mr-[.5%] p-1p dark:bg-lightBlack bg-white">
              <MarkdownText data={firstOptionData?.references} />
            </div>
            <div className="w-1/2 ml-[.5%] p-1p dark:bg-lightBlack bg-white">
              <MarkdownText data={secondOptionData?.references} />
            </div>
          </div>
        </div>

        {/* <div className="bg-white dark:bg-lightBlack dark:text-white p-1p">
            {(firstOptionData?.name || secondOptionData?.name) && (
              <>
                <div className="text-xl font-semibold my-1p">Title</div>
                <DifferenceViewer
                  oldValue={JSON.stringify(firstOptionData?.name)}
                  newValue={JSON.stringify(secondOptionData?.name)}
                  splitView={true}
                />
              </>
            )}
            {(firstOptionData?.description || secondOptionData?.description) && (
              <>
                <div className="text-xl font-semibold my-1p">Description</div>
                <DifferenceViewer
                  oldValue={JSON.stringify(firstOptionData?.description)}
                  newValue={JSON.stringify(secondOptionData?.description)}
                  splitView={true}
                />
              </>
            )}
            {(firstOptionData?.impact || secondOptionData?.impact) && (
              <>
                <div className="text-xl font-semibold my-1p">Impact</div>
                <DifferenceViewer
                  oldValue={JSON.stringify(firstOptionData?.impact)}
                  newValue={JSON.stringify(secondOptionData?.impact)}
                  splitView={true}
                />
              </>
            )}
            {(firstOptionData?.remediation || secondOptionData?.remediation) && (
              <>
                <div className="text-xl font-semibold my-1p">Remediation</div>
                <DifferenceViewer
                  oldValue={JSON.stringify(firstOptionData?.remediation)}
                  newValue={JSON.stringify(secondOptionData?.remediation)}
                  splitView={true}
                />
              </>
            )}
            {(firstOptionData?.references || secondOptionData?.references) && (
              <>
                <div className="text-xl font-semibold my-1p">References</div>
                <DifferenceViewer
                  oldValue={JSON.stringify(firstOptionData?.references)}
                  newValue={JSON.stringify(secondOptionData?.references)}
                  splitView={true}
                />
              </>
            )}
            {(firstOptionData?.files_attached ||
              secondOptionData?.files_attached) && (
              <>
                <div className="text-xl font-semibold my-1p">Files Attached</div>
                <DifferenceViewer
                  oldValue={JSON.stringify(firstOptionData?.files_attached)}
                  newValue={JSON.stringify(secondOptionData?.files_attached)}
                  splitView={true}
                />
              </>
            )}
          </div> */}

        {statusChangeAllowed && bugsData.length > 2 && (
          <div className="flex">
            <div className="w-1/2 border-r">
              <StatusSelection bugsData={firstOptionData} />
            </div>
            <div className="w-1/2">
              <StatusSelection bugsData={secondOptionData} />
            </div>
          </div>
        )}
      </CompareReportsLayout>
    </div>
  );
}

export default Index;
