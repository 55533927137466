import React from "react";
import { Avatar } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Box } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import StatusButton from "components/ui/buttons/StatusButton";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { teamSliceActions } from "store/teamSlice";

const columns = [
  { id: "refered6", label: "Refered", minWidth: 200, align: "left" },
  { id: "assets6", label: "Asset", minWidth: 200, align: "left" },
  {
    id: "vulnerabilities6",
    label: "Vulnerabilities",
    minWidth: 150,
    align: "left",
  },
  {
    id: "status6",
    label: "Status",
    minWidth: 50,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "comment6",
    label: "Comment",
    minWidth: 200,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function RequestCard({ pending, accepted, rejected, data }) {
  const isDarkMode = useSelector((state) => state.general.theme) == "dark";

  data = {
    referralId: data?.referral_id || null,
    referrer: {
      name: data?.referrer_name || "NA",

      email: data?.referrer_email || "NA",
      image: data?.referrer_profile_image || "",
    },
    refered: {
      name: data?.referred_name || "NA",

      email: data?.referred_email || "NA",
      image: data?.referred_profile_image || "",
    },
    asset: data?.parent_asset,

    vulnerabilities: data?.vuln_id
      ? data?.vuln_id.length > 25
        ? "#" + data?.vuln_id.slice(0, 25) + "..."
        : "#" + data.vuln_id
      : "NA",
    status: data?.vuln_status || "NA",
    comment: data?.description || "",

    assetId: data?.parentAssetId,
  };
  let row = {
    refered6: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar
          alt={data?.refered?.name}
          src={data?.refered?.image || ".png"}
        />
        <div
          className="avatar-text"
          style={{ display: "inline", paddingLeft: "15px" }}
        >
          <div style={{ fontSize: "18px" }} className="dark:text-white">
            <b>{data?.refered?.name}</b>
          </div>
          <div
            style={{ fontSize: "16px" }}
            className="dark:text-white opacity-60"
          >
            {data?.refered?.email}
          </div>
        </div>
      </div>
    ),
    assets6: data.asset,
    vulnerabilities6: (
      <div
        style={{
          color: "white",
          background: "#539486",
          borderRadius: "5px",
          height: "35px",
          maxWidth: "250px",
          marginRight: "1%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {data.vulnerabilities}
      </div>
    ),
    status6: StatusButton(data.status, "100px", "35px", "16px"),
    comment6: data.comment,
  };

  const dispatch = useDispatch();
  const pendingRequests = useSelector((state) => state.team.pendingRequests);

  const approveReferral = async () => {
    let res = await postFetch("/manager/approveReferral", {
      referral_id: data.referralId,
    });
    if (res?.status == "ok") {
      dispatch(
        teamSliceActions.setPendingRequests(
          pendingRequests.filter((i) => i.referral_id != data.referralId)
        )
      );
    } else toast.error("some error occured, try later");
  };

  const rejectReferral = async () => {
    let res = await postFetch("/manager/rejectReferral", {
      referral_id: data.referralId,
    });
    if (res?.status == "ok") {
      dispatch(
        teamSliceActions.setPendingRequests(
          pendingRequests.filter((i) => i.referral_id != data.referralId)
        )
      );
    } else toast.error("some error occured, try later");
  };

  const handleAddAgainClick = async () => {};

  return (
    <div
      style={{ marginTop: "1.5%", borderRadius: "10px" }}
      className="bg-white dark:bg-lightBlack dark:text-white"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1.5% 2% 0 2%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar alt={data?.referrer?.name} src={data?.referrer?.image} />
          <div
            className="avatar-text"
            style={{ display: "inline", paddingLeft: "15px" }}
          >
            <div style={{ fontSize: "20px" }}>
              <b>{data?.referrer?.name}</b>
            </div>
            <div style={{ fontSize: "18px" }} className="opacity-60">
              {data?.referrer?.email}
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {accepted && (
            <div>
              <img src="/bin.svg" alt="" />
            </div>
          )}

          {pending && (
            <>
              <div
                className="flex cursor-pointer items-center justify-center rounded-full w-12 h-12 text-4xl bg-gradient-to-b from-[#303e55] to-[#18202c] mr-1p text-white"
                onClick={approveReferral}
              >
                <img src="/tick.svg" alt="" />
              </div>
              <div
                style={{
                  borderRadius: "100%",
                  width: "50px",
                  height: "50px",
                  fontSize: "40px",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={rejectReferral}
                className="cursor-pointer"
              >
                <img
                  src={isDarkMode ? "/crossWhite.svg" : "/cross.svg"}
                  alt=""
                  style={{}}
                />
              </div>{" "}
            </>
          )}

          {rejected && (
            <Link
              className="text-black font-bold "
              onClick={handleAddAgainClick}
            >
              Add Again
            </Link>
          )}
        </div>
      </div>
      <div style={{ marginTop: ".5%" }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      padding: "1% 2%",
                      background: "rgba(217, 217, 217, 0.25)",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                    className="dark:text-white"
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow tabIndex={-1} key={row.code}>
                {columns.map((column) => {
                  const value = row[column.id];

                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        padding: "2%",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                      className="dark:text-white"
                    >
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default RequestCard;
