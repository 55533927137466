import React from "react";
import ReportManager from "./components/report/Report";
import ReportTable from "./components/report/ReportTable";

import BugReport from "./components/BugReport/Index";
import VulnerabilityReport from "./components/vulnerabilityReport/Index";

export const ReportManagerComponent = ReportManager;
export const ReportTableComponent = ReportTable;

export const BugReportComponent = BugReport;
export const VulnerabilityReportComponent = VulnerabilityReport;

function index(props) {
  return <div></div>;
}

export default index;
