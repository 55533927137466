import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invites: [],
};

let inviteSlice = createSlice({
  name: "inviteSlice",
  initialState: initialState,
  reducers: {
    setInvites(state, action) {
      state.invites = action.payload;
    },
  },
});

export const inviteSliceActions = inviteSlice.actions;

export default inviteSlice;
