import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentSection: 0,

  currentScan: {
    name: "",
    id: "",
  },

  currentAssetId: null,
  currentTargetId: null,

  home: {
    isLoading: false,

    chartBoxData: {
      critical: 0,
      high: 0,
      medium: 0,
      low: 0,
      new: 0,
      informative: 0,
      triaged: 0,
      resolve: 0,
      duplicate: 0,
      rating: 0,
      riskScore: 0,
    },
    topVulnerabilities: [],
    topCve: [],

    activityFeed: [],

    timelineData: [],

    domainInfo: {},
    activeDomainInfoOption: 0,
    websiteUrl: "",
  },

  bugCount: 0,
  vulnerabilityCount: 0,
  reportCount: 0,

  bugs: [],
  selectedBugs: [],
  statusChangeAllowed: false, // this is for compare report , status can only be changed after taking action

  vulnerabilities: [],
  similarDuplicateVulnerabilitiesIds: [],

  currentVulnerability: "", //

  reports: [],

  //
  cweGraphData: [],
  owaspGraphData: [],
  mainGraphData: [],
};

let targetDashboardSlice = createSlice({
  name: "scannerTargetDashboardSlice",
  initialState: initialState,
  reducers: {
    setCurrentAssetId(state, action) {
      state.currentAssetId = action.payload;
    },
    setCurrentScan(state, action) {
      state.currentScan = action.payload;
    },
    setCurrentSection(state, action) {
      state.currentSection = action.payload;
    },
    setCurrentTargetID(state, action) {
      state.currentTargetId = action.payload;
    },

    setHomeIsLoading(state, action) {
      state.home.isLoading = action.payload;
    },
    setBugs(state, action) {
      state.bugs = action.payload;
    },
    setReports(state, action) {
      state.reports = action.payload;
    },
    setVulnerabilities(state, action) {
      state.vulnerabilities = action.payload;
    },
    setSimilarDuplicateVulnerabilitiesIds(state, action) {
      state.similarDuplicateVulnerabilitiesIds = action.payload;
    },
    setSelectedBugs(state, action) {
      state.selectedBugs = action.payload;
    },
    setStatusChangeAllowed(state, action) {
      state.statusChangeAllowed = action.payload;
    },
    setCurrentVulnerability(state, action) {
      state.currentVulnerability = action.payload;
    },
    setChartBoxData(state, action) {
      state.home.chartBoxData = action.payload;
    },
    setTopVulnerabilities(state, action) {
      state.home.topVulnerabilities = action.payload;
    },
    setTopCve(state, action) {
      state.home.topCve = action.payload;
    },
    setDomainInfo(state, action) {
      state.home.domainInfo = action.payload;
    },
    setActiveDomainInfoOption(state, action) {
      state.home.activeDomainInfoOption = action.payload;
    },
    setWebsiteUrl(state, action) {
      state.home.websiteUrl = action.payload;
    },
    setActivityFeed(state, action) {
      state.home.activityFeed = action.payload;
    },
    setBugCount(state, action) {
      state.bugCount = action.payload;
    },
    setVulnerabilityCount(state, action) {
      state.vulnerabilityCount = action.payload;
    },
    setReportCount(state, action) {
      state.reportCount = action.payload;
    },
    setTimelineData(state, action) {
      state.home.timelineData = action.payload;
    },

    setCweGraphData(state, action) {
      state.cweGraphData = action.payload;
    },
    setOwaspGraphData(state, action) {
      state.owaspGraphData = action.payload;
    },
    setMainGraphData(state, action) {
      state.mainGraphData = action.payload;
    },
  },
});

export const targetDashboardSliceActions = targetDashboardSlice.actions;

export default targetDashboardSlice;
