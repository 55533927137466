import React from "react";
import UserNavbar from "Layouts/userNavbar/Index";

import CompanyCardSManager from "./ui/cards/CompanyCardSManager";
import { useNavigate } from "react-router-dom";

let data = require("../data/companiesListSManager.json");

function CompaniesListSManager(props) {
  let navigate = useNavigate();

  let handleCompanyClick = (e) => {
    navigate("/dashboard");
  };

  return (
    <UserNavbar>
      <div className="p-2p pt-1p">
        <div className="font-medium text-lg mb-1p">Comapies List</div>
        <div>
          {data?.map((companyData) => (
            <CompanyCardSManager
              data={companyData}
              onClick={handleCompanyClick}
            />
          ))}
        </div>
      </div>
    </UserNavbar>
  );
}

export default CompaniesListSManager;
