import React from "react";
import { Line } from "react-chartjs-2";

export default function TimelineGraph({ timeline_data = [], aspectRatio }) {
  const labels = timeline_data?.map((data) => {
    const date = new Date(data?.interval_starting_date);
    return date?.toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
    });
  });

  const datapoints = timeline_data?.map((data) => data?.bug_count);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: datapoints,
        borderColor: "#1E24B8",
        backgroundColor: "rgba(29, 38, 239, 0.11)",
        cubicInterpolationMode: "monotone",
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    aspectRatio: aspectRatio ? aspectRatio : null,
    plugins: {
      title: {
        display: false,
        text: "Chart.js Line Chart - Cubic interpolation mode",
      },
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        ticks: {
          display: true,
        },
        grid: {
          display: false,
        },
        title: {
          display: false,
          text: "Time",
        },
      },
      y: {
        display: true,
        ticks: {
          display: true,
        },

        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Vulnerabilities",
        },
        suggestedMin: 0,
        suggestedMax: Math.max(...datapoints) + 2,
      },
    },
  };

  return <Line options={options} data={data} />;
}
