import React, { useEffect, useState } from "react";

import TopSection from "./TopSection/Index";
import LeftSection from "./LeftSection/Index";
import RightSection from "./RightSection/Index";
import { getFetch, postFetchLocal, pythonPostFetch } from "lib/fetch";
import { useDispatch, useSelector } from "react-redux";
import { targetDashboardSliceActions } from "store/scanner/targetDashboardSlice";
import { toast } from "react-toastify";

export default function ChartBox() {
  const dispatch = useDispatch();
  const [infoCardLoading , setInfoCardLoading] = useState(false);
  const [vulnerabilitiesLoading , setVulnerabilitiesLoading] = useState(false);
  let currentAsset = useSelector(
    (state) => state?.targetDashboard?.currentTarget?.id
  );
  const currentScan = useSelector(
    (state) => state.scanner.targetDashboard.currentScan
  );
  const currentAssetURL = useSelector((state)=>state.scanner.targetDashboard.home.websiteUrl);
  const openedAsseturl = useSelector((state)=>state.targetInfo.data.website_url);

  useEffect(()=>{
    if(currentScan.name && currentAssetURL ){
      sessionStorage.setItem('dashboardPayload',JSON.stringify({"asset_name": currentScan?.name, "asset_url":currentAssetURL}));
    }
  });

  useEffect(() => {
    let getData = async () => {
      dispatch(targetDashboardSliceActions.setHomeIsLoading(true));
//   const isLoading = useSelector(
  // (state) => state?.scanner?.targetDashboard?.home?.isLoading
  // );
      const payload = sessionStorage.getItem('dashboardPayload');
     
      if(JSON.parse(payload).asset_url !== openedAsseturl ){
      setInfoCardLoading(()=>true);
      setVulnerabilitiesLoading(()=>true);
      }
      const res = await pythonPostFetch("/asset/dashboard", {
        asset_name: payload ? JSON.parse(payload).asset_name : currentScan?.name,
        asset_url: payload ? JSON.parse(payload).asset_url : currentAssetURL,
        only_bugs: true,
      });

     

      const res2 = await pythonPostFetch("/asset/dashboard", {
        asset_name: payload ? JSON.parse(payload).asset_name : currentScan?.name,
        asset_url: payload ? JSON.parse(payload).asset_url : currentAssetURL,
        only_bugs: false,
      });
      
      dispatch(targetDashboardSliceActions.setHomeIsLoading(false));
      

      let res2data = res2?.data?.bug_variants;
      res2data = { ...res2data, riskScore: res2?.data?.risk_score };

      if (res?.status == "ok" && res2.status == "ok") {
        // below dispatch is for info cards & left graph values
        dispatch(targetDashboardSliceActions.setChartBoxData(res2data));
     
        //below dispatch is for setting up the website url of target dashboard
        dispatch(targetDashboardSliceActions.setWebsiteUrl(res2?.data?.url));
        

        //below dispatch is for top vulnerabilities
        dispatch(targetDashboardSliceActions.setTopVulnerabilities(res?.data));

        setInfoCardLoading(()=>false);
        setVulnerabilitiesLoading(()=>false);

        // dispatch(
        //   targetDashboardSliceActions.setChartBoxData(res?.data?.chartBoxData)
        // );
        // dispatch(
        //   targetDashboardSliceActions.setTopVulnerabilities(
        //     res?.data?.topVulnerabilities
        //   )
        // );
        // dispatch(targetDashboardSliceActions.setTopCve(res?.data?.topCve));
        // dispatch(
        //   targetDashboardSliceActions.setWebsiteUrl(res?.data?.websiteUrl)
        // );
        // dispatch(
        //   targetDashboardSliceActions.setActivityFeed(res?.data?.activityFeed)
        // );
        // dispatch(
        //   targetDashboardSliceActions.setTimelineData(res?.data?.timeline_data)
        // );
      } else {
        toast.error("some error occured, try later");
      }

    };
    currentScan?.name && getData();
  }, [currentScan]);




  return (
    <div className="rounded-b bg-white dark:bg-lightBlack p-1p">
      <div className="mb-2p">
        <TopSection  infoCardLoading={infoCardLoading}/>
      </div>

      <div style={{ display: "flex" }}>
        <div className="w-1/2 mr-1p">
          <LeftSection />
        </div>
        <div className="w-1/2 ml-1p">
          <RightSection vulnerabilitiesLoading={vulnerabilitiesLoading}/>
        </div>
      </div>
    </div>
  );
}
