import React from "react";
import SeverityInput from "./SeverityInput";
import Popup from "reactjs-popup";

import CommentBox from "../BugReportSidebar/CommentBox";
import { useState } from "react";
import { useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import { addVulnerabilityReportSliceActions } from "store/vulnerabilityReport";
import { blueGradient1, input1 } from "components/tailwindClasses";

function Parameter(props) {
  let commentRef = useRef();
  const reportViewType = useSelector(
    (state) => state.addVulnerabilityReport.reportViewType
  );

  const dispatch = useDispatch();

  let url = useSelector((state) => state.addVulnerabilityReport.url);
  let parameter = useSelector(
    (state) => state.addVulnerabilityReport.vulnerable_parameter
  );

  return (
    <div className="bg-white dark:bg-lightBlack  dark:text-white  p-2p rounded-10">
      <div className="flex justify-between border-b-2 border-b1 dark:border-b9 items-center">
        <div className="flex">
          <div className="font-bold text-xl mr-1">3.</div>
          <div className="flex grow flex-col pb-2">
            <div className="font-bold text-xl"> URL & Vulnerable Parameter</div>
          </div>
        </div>

        {(reportViewType == "edit" || reportViewType == "update") && (
          <Popup
            ref={commentRef}
            trigger={
              <div
                className={
                  blueGradient1 +
                  " flex items-center justify-center cursor-pointer rounded-full w-10 h-10 text-white font-bold text-4xl "
                }
              >
                <div>+</div>
              </div>
            }
            on="click"
            position={"left center"}
          >
            <CommentBox
              type={"input"}
              commentRef={commentRef}
              section={"parameter"}
              addComment={(comment, id, createdOn, isCreator) => {
                dispatch(
                  addVulnerabilityReportSliceActions.setParameterComment({
                    comment: comment,
                    id,
                    createdOn,
                    isCreator,
                  })
                );
              }}
            />
          </Popup>
        )}
      </div>

      <div>
        <div className="mt-4">
          <div className="font-semibold text-lg">
            Full URL<span className="text-[#FDBC2C]">*</span>
          </div>
          <input
            // disabled={reportViewType == "update" ? true : false}
            style={{ height: "50px" }}
            required
            type="url"
            className={input1 + " mt-1"}
            placeholder="Example: https://www.example.com/api/v1/file/?node-id=295"
            value={url}
            onChange={(e) => {
              dispatch(
                addVulnerabilityReportSliceActions.setUrl(e.target.value)
              );
            }}
          />
        </div>
        <div className="mt-4">
          <div className="font-semibold text-lg">
            Vulnerable Parameter<span className="text-[#FDBC2C]">*</span>
          </div>
          <input
            // disabled={reportViewType == "update" ? true : false}
            style={{ height: "50px" }}
            className={input1 + " mt-1"}
            placeholder="Example: node-id"
            value={parameter}
            onChange={(e) => {
              dispatch(
                addVulnerabilityReportSliceActions.setVulnerableParameter(
                  e.target.value
                )
              );
            }}
            required
          />
        </div>
      </div>
    </div>
  );
}

export default Parameter;
