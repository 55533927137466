import React from "react";
import ChartBox from "./Chartbox/Index";
import { useDispatch, useSelector } from "react-redux";

import ActiveVuln from "./ActivityFeed/Index";
import EngineerCharts from "./EngineerCharts/Index";
import AssetTable from "./AssetsList/Index";
import { activePageActions } from "store/activePageSlice";

import BackToCompanyList from "Layouts/BackToCompanyList";

export default function Index() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.data);

  return (
    <>
      <div className="p-1p">
        <BackToCompanyList>
          <ChartBox />
          {user.role == "S_ENGINEER" && <EngineerCharts />}
          <AssetTable />

          <ActiveVuln />
        </BackToCompanyList>
      </div>
    </>
  );
}
