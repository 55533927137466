import React, { useState } from "react";
import { Avatar, Skeleton } from "@mui/material";
import SeverityButton from "components/ui/buttons/SeverityButton";
import Popup from "reactjs-popup";
import StatusButton from "components/ui/buttons/StatusButton";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import AddMember from "./AddMemberModal";
import SecondaryButton from "components/ui/buttons/SecondaryButton";
import GreyBgButton from "components/ui/buttons/GreyBgButton";
import { useSelector } from "react-redux";
import { MultipleUserImage, UserImage } from "components/ui/ImageComponent";
import { blueGradient1 } from "components/tailwindClasses";

function Index({ data, setData, loading = false }) {

  const user = useSelector((state) => state.user.data);

  let [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div
      style={{
        width: "25%",
        // border: "1px solid blue",
        padding: "1% 0% 1% 0%",
        borderRadius: "10px",
      }}
      className="popupContainer1 bg-white dark:bg-lightBlack dark:text-white"
    >
      <div style={{ padding: "0 5% 0 5%" }}    className="border-b dark:border-b9 border-b3">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.83)",
          }}
          className="py-1 "
        >
          <span className="dark:text-white">
            Reported {new Date(data?.created_on).toDateString()}{" "}
          </span>
          <span className="dark:text-white">
            {new Date(data?.created_on).toLocaleTimeString()}
          </span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }} className="py-1">
          <span style={{ marginRight: "1%" }}>
            <UserImage
              name={data?.researcherName}
              image={data?.researcherProfileImage}
              company={data?.researcherCompany}
              email={data?.researcherEmail}
              style={{
                width: "50px",
                height: "50px",
                marginRight: "10px",
              }}
            />
          </span>

          <span style={{ fontWeight: "700" }}>{data?.researcherName}</span>
        </div>
      </div>

      {/* <div
        style={{
          fontWeight: "700",
          background: " rgba(217, 217, 217, 0.25)",
          padding: "5% 3%",
        }}
        className="my-2"
      >
        Participants
      </div> */}
      <div
        style={{
          padding: "0 5% 2% 5%",
        }}
        className="border-b dark:border-b9 border-b3 mb-7"
      >
        {/* <div className="profileContainer flex items-center">
          {data?.involved_researchers?.length > 0 &&
            (data?.involved_researchers?.length > 5 ? (
              <div className="mr-2">
                <MultipleUserImage
                  team={data?.involved_researchers?.map((i) => {
                    return {
                      name: i?.name,
                      companyName: i?.company,
                      email: i?.email,
                      profileimage: i?.profileImage,
                    };
                  })}
                  max={6}
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
            ) : (
              <div className="flex flex-wrap">
                {data?.involved_researchers?.map((i) => {
                  return (
                    <div className="mr-2">
                      <UserImage
                        name={i?.name}
                        company={i?.company}
                        email={i?.email}
                        image={i?.profileImage}
                        style={{ width: "50px", height: "50px" }}
                      />
                    </div>
                  );
                })}
              </div>
            ))}
          {(user.role == "ENGINEER" || user.role == "MANAGER") && (
            <button
              style={{
                borderRadius: "100%",
                width: "50px",
                height: "50px",
                fontSize: "40px",
                color: "white",
              }}
              className={"flex items-center justify-center " + blueGradient1}
              onClick={handleOpen}
            >
              +
            </button>
          )}

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <AddMember handleClose={handleClose} />
          </Modal>
        </div> */}
        <div style={{ margin: "30px 0 20px 0  " }} >
          <div style={{ display: "flex" }} className="my-1">
            <div style={{ width: "50%", fontWeight: "600" }}>Status</div>
            <div style={{ width: "50%" }}>
              {loading ? (
                <Skeleton
                  variant="text"
                  width={70}
                  height={30}
                  style={{ background: "#058AA6" }}
                />
              ) : (
                StatusButton(data?.status)
              )}
            </div>
          </div>
          <div style={{ display: "flex" }} className="my-1">
            <div style={{ width: "50%", fontWeight: "600" }}>Report To</div>
            <div style={{ width: "50%" }}>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                user?.companyName
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "0 5% 0 5%",
        }}
      >
        <div style={{ display: "flex" }} className="my-1">
          <div style={{ width: "50%", fontWeight: "600" }}>Weakness</div>
          <div style={{ width: "50%" }}>
            {loading ? (
              <Skeleton variant="text" width={100} height={30} />
            ) : (
              data?.type
            )}
          </div>
        </div>
        <div style={{ display: "flex" }} className="my-1">
          <div style={{ width: "50%", fontWeight: "600" }}>Severity</div>
          <div style={{ width: "50%" }}>
            {loading ? (
              <Skeleton
                variant="text"
                width={70}
                height={30}
                style={{ background: "#37A440" }}
              />
            ) : (
              SeverityButton(data?.severity)
            )}
          </div>
        </div>
        <div style={{ display: "flex" }} className="my-1">
          <div style={{ width: "50%", fontWeight: "600" }}>CVSS Score</div>
          <div style={{ width: "50%" }}>
            {loading ? (
              <Skeleton variant="text" width={100} height={30} />
            ) : data.cvss ? (
              data.cvss
            ) : (
              "NA"
            )}
          </div>
        </div>
        <div style={{ display: "flex" }} className="my-1">
        <div style={{ width: "50%", fontWeight: "600" }}>CWE ID</div>
          <div style={{ width: "50%" }}>
            {loading ? (
              <Skeleton variant="text" width={100} height={30} />
            ) : data.cwe ? (
              <GreyBgButton>{ data.cwe }</GreyBgButton>
            ) : (
              "NONE"
            )}
          </div>
        </div>
        <div style={{ display: "flex" }} className="my-1">
          <div style={{ width: "50%", fontWeight: "600" }}>CVE ID </div>
          <div style={{ width: "50%" }}>
            {data?.cve?.length > 1 ? (
              <Popup
                trigger={
                  <div>
                    <GreyBgButton>Multiple</GreyBgButton>
                  </div>
                }
                position={["top center"]}
                on="hover"
                closeOnDocumentClick
              >
                <div className="rounded shadow p-2p bg-white flex">
                  {data.cve.map((cveItem, i) => {
                    return (
                      <div
                        className={i != data.cve.length - 1 ? "mr-1" : "mr-4"}
                      >
                        <GreyBgButton>{cveItem}</GreyBgButton>
                      </div>
                    );
                  })}
                </div>
              </Popup>
            ) : data?.cve?.length == 1 ? (
              <GreyBgButton>{data.cve}</GreyBgButton>
            ) : (
              <GreyBgButton>None</GreyBgButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
