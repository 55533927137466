import React, { useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { blueGradient1 } from "components/tailwindClasses";
import { useEffect } from "react";

function UserSidebar(props) {
  let navigate = useNavigate();

  let activePage = useSelector((state) => state.activePage.activePage);

  let user = useSelector((state) => state.user.data);

  const isDarkMode = useSelector((state) => state.general.theme);

  const currentApp = useSelector((state) => state.general.currentApp);

  // can use map for pages below to minimize code
  return (
    <>
      {currentApp == "VMDR" && (
        <div className="fixed top-0 left-0 bg-white dark:bg-lightBlack w-[7vw] pt-[8vh] h-full text-sm text-opacity-60 cursor-default">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2vh",

                width: "90px",
                height: "80px",
                borderRadius: "10px",
              }}
              onClick={() => {
                navigate("/dashboard");
              }}
              className={activePage == 0 ? blueGradient1 : ""}
            >
              <img
                src={
                  activePage == 0
                    ? "/dashboard2.svg"
                    : isDarkMode
                    ? "/dashboardOutlineDark.svg"
                    : "/dashboardOutline.svg"
                }
                alt=""
                style={{ marginBottom: ".4vw" }}
              />
              <div
                className={
                  activePage == 0
                    ? "font-bold text-white"
                    : " text-black dark:text-lightBlack2"
                }
              >
                Dashboard
              </div>
            </div>
            {(user.role == "S_MANAGER" || user.role == "MANAGER") && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2vh",

                  width: "90px",
                  height: "80px",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  navigate("/team");
                }}
                className={activePage == 1 ? blueGradient1 : ""}
              >
                <img
                  src={
                    activePage == 1
                      ? "/team2.svg"
                      : isDarkMode
                      ? "/teamOutlineDark.svg"
                      : "/teamOutline.svg"
                  }
                  alt=""
                  style={{ marginBottom: ".4vw" }}
                />
                <div
                  className={
                    activePage == 1
                      ? "font-bold text-white"
                      : " text-black dark:text-lightBlack2"
                  }
                >
                  Team
                </div>
              </div>
            )}
            {(user.role == "S_MANAGER" || user.role == "MANAGER") && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2vh",

                  width: "90px",
                  height: "80px",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  navigate("/report");
                }}
                className={activePage == 2 ? blueGradient1 : ""}
              >
                <img
                  src={
                    activePage == 2
                      ? "/report2.svg"
                      : isDarkMode
                      ? "/reportOutlineDark.svg"
                      : "/reportOutline.svg"
                  }
                  alt=""
                  style={{ marginBottom: ".4vw" }}
                />
                <div
                  className={
                    activePage == 2
                      ? "font-bold text-white"
                      : " text-black  dark:text-lightBlack2"
                  }
                >
                  Report
                </div>
              </div>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2vh",

                width: "90px",
                height: "80px",
                borderRadius: "10px",
              }}
              onClick={() => {
                navigate("/target");
              }}
              className={activePage == 3 ? blueGradient1 : ""}
            >
              <img
                src={
                  activePage == 3
                    ? "/target2.svg"
                    : isDarkMode
                    ? "/targetOutlineDark.svg"
                    : "/targetOutline.svg"
                }
                alt=""
                style={{ marginBottom: ".4vw" }}
              />

              <div
                className={
                  activePage == 3
                    ? "font-bold text-white"
                    : " text-black dark:text-lightBlack2"
                }
              >
                Target
              </div>
            </div>
            {(user?.role == "S_MANAGER" || user?.role == "MANAGER") && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2vh",

                  width: "90px",
                  height: "80px",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  navigate("/invite");
                }}
                className={activePage == 4 ? blueGradient1 : ""}
              >
                <img
                  src={
                    activePage == 4
                      ? "/companies2.svg"
                      : isDarkMode
                      ? "/companiesOutlineDark.svg"
                      : "/companiesOutline.svg"
                  }
                  alt=""
                  style={{ marginBottom: ".4vw" }}
                />
                <div
                  className={
                    activePage == 4
                      ? "font-bold text-white"
                      : " text-black dark:text-lightBlack2"
                  }
                >
                  Invite
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {currentApp == "SCANNER" && (
        <div className="fixed top-0 left-0 bg-white dark:bg-lightBlack w-[7vw] pt-[8vh] h-full text-sm text-opacity-60 cursor-default">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2vh",

                width: "90px",
                height: "80px",
                borderRadius: "10px",
              }}
              onClick={() => {
                navigate("/dashboard");
              }}
              className={activePage == 0 ? blueGradient1 : ""}
            >
              <img
                src={
                  activePage == 0
                    ? "/dashboard2.svg"
                    : isDarkMode
                    ? "/dashboardOutlineDark.svg"
                    : "/dashboardOutline.svg"
                }
                alt=""
                style={{ marginBottom: ".4vw" }}
              />
              <div
                className={
                  activePage == 0
                    ? "font-bold text-white"
                    : " text-black dark:text-lightBlack2"
                }
              >
                Dashboard
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2vh",

                width: "90px",
                height: "80px",
                borderRadius: "10px",
              }}
              onClick={() => {
                navigate("/scanProfile");
              }}
              className={activePage == 1 ? blueGradient1 : ""}
            >
              <img
                src={
                  activePage == 1
                    ? "/scanProfile2.svg"
                    : isDarkMode
                    ? "/scanProfileOutlineDark.svg"
                    : "/scanProfileOutline.svg"
                }
                alt=""
                style={{ marginBottom: ".4vw" }}
              />
              <div
                className={
                  activePage == 1
                    ? "font-bold text-white"
                    : " text-black dark:text-lightBlack2"
                }
              >
                Scan Profile
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2vh",

                width: "90px",
                height: "80px",
                borderRadius: "10px",
              }}
              onClick={() => {
                navigate("/scanEngine");
              }}
              className={activePage == 2 ? blueGradient1 : ""}
            >
              <img
                src={
                  activePage == 2
                    ? "/scanEngine2.svg"
                    : isDarkMode
                    ? "/scanEngineOutlineDark.svg"
                    : "/scanEngineOutline.svg"
                }
                alt=""
                style={{ marginBottom: ".4vw" }}
              />

              <div
                className={
                  activePage == 2
                    ? "font-bold text-white"
                    : " text-black dark:text-lightBlack2"
                }
              >
                Scan Engine
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "2vh",

                width: "90px",
                height: "80px",
                borderRadius: "10px",
              }}
              onClick={() => {
                navigate("/report");
              }}
              className={activePage == 3 ? blueGradient1 : ""}
            >
              <img
                src={
                  activePage == 3
                    ? "/report2.svg"
                    : isDarkMode
                    ? "/reportOutlineDark.svg"
                    : "/reportOutline.svg"
                }
                alt=""
                style={{ marginBottom: ".4vw" }}
              />
              <div
                className={
                  activePage == 3
                    ? "font-bold text-white"
                    : " text-black  dark:text-lightBlack2"
                }
              >
                Report
              </div>
            </div>
          </div>
        </div>
      )}

      <div style={{ flexGrow: 1, paddingLeft: "7vw", width: "100%" }}>
        {props.children}
      </div>
    </>
  );
}

export default UserSidebar;
