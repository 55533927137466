import React from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { useState } from "react";

// need to add navbar

function Index(props) {
  let [currentStep, setCurrentStep] = useState(true);

  let [mobileNumber, setMobileNumber] = useState("");
  return (
    <>
      {currentStep && (
        <Step1
          setCurrentStep={setCurrentStep}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
        />
      )}
      {!currentStep && <Step2 mobileNumber={mobileNumber} />}
    </>
  );
}

export default Index;
