import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  asset: {
    title: null,
    description: null,
    type: null,
    tags: [],
    inviteCompanyEmail: null,
    inviteCompanyMessage: null,

    description_file: null,

    websiteUrl: null,
    websiteSubdomains: [],

    postmanApiFile: null,
    ipRange: null,
    appStoreUrl: null,
    appFile: null,

    teamMemberEmails: [],
  },
};

let addAssetSlice = createSlice({
  name: "addAssetSlice",
  initialState: initialState,
  reducers: {
    setAsset(state, action) {
      state.asset = action.payload;
    },
    setWebsiteUrl(state, action) {
      state.asset.websiteUrl = action.payload;
    },
    setWebsiteSubdomains(state, action) {
      state.asset.websiteSubdomains = action.payload;
    },
    setTeamMemberEmails(state, action) {
      state.asset.teamMemberEmails = action.payload;
    },
    setInviteCompanyEmail(state, action) {
      state.asset.inviteCompanyEmail = action.payload;
    },
    setInviteCompanyMessage(state, action) {
      state.asset.inviteCompanyMessage = action.payload;
    },
  },
});

export const addAssetSliceActions = addAssetSlice.actions;

export default addAssetSlice;
