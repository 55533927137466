import React from "react";

import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export default function PerformanceChart({ dataToPlot = [] }) {
  const DATA_COUNT = dataToPlot?.length;
  const labels = dataToPlot?.map((d) => d?.asset_title);
  // for (let i = 0; i < DATA_COUNT; ++i) {
  //   labels?.push("Asset " + (i + 1));
  // }
  const datapoints1 = dataToPlot?.map((d) => d?.valid_count);
  //  [0, 20, 20, 60, 60, 120, 180, 120];
  const datapoints2 = dataToPlot?.map((d) => d?.invalid_count);

  // [0, 0, 0, 10, 10, 20, 20, 15, 10];
  // const labels = ["January", "February", "March", "April", "May", "June", "July"];
  const data = {
    labels: labels,

    datasets: [
      {
        label: "",
        data: datapoints1,
        borderColor: "rgb(75, 192, 192)",
        //   backgroundColor: "rgba(255, 99, 132, 0?.5)",
        //   fill: true,
        cubicInterpolationMode: "monotone",
        tension: 0.4,
      },
      {
        label: "",

        data: datapoints2,
        borderColor: "rgb(255, 99, 132)",
        cubicInterpolationMode: "monotone",

        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "Chart.js Line Chart - Cubic interpolation mode",
      },
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: false,
          text: "Assets",
        },
        ticks: {
          callback: function (value) {
            return labels[value]?.length > 10
              ? labels[value]?.substring?.(0, 10) + "..."
              : labels[value];
          },
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Vulnerabilities",
        },
        suggestedMin: 0,
        suggestedMax: Math.max(...datapoints1, ...datapoints2) + 2,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let label = data.labels[tooltipItem.index];
          return label;
        },
      },
    },
  };

  return <Line options={options} data={data} />;
}
