import React from "react";
import { LinkComponent } from "features/login";

function Link(props) {
  return (
    <LinkComponent heading={"Link has been sent to your Email address!"} />
  );
}

export default Link;
