import { postFetch } from "lib/fetch";
import { toast } from "react-toastify";

// export const convertToBase64 = (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();

//     reader.onloadend = () => {
//       const base64String = reader.result.split(",")[1];
//       resolve(base64String);
//     };

//     reader.onerror = (error) => {
//       reject(error);
//     };

//     reader.readAsDataURL(file);
//   });
// };

// export const handleFileUpload = async (file) => {
//   // Convert the file to base64 using the helper function

//   const base64String = await convertToBase64(file);

//   const { name: filename } = file;
//   const imageExtension = filename.split(".").pop().toLowerCase();

//   // Create an object with the base64 image, filename, and extension
//   const convertedFile = {
//     imageData: base64String,
//     filename,
//     filetype: imageExtension,
//   };

//   const uploadRes = await postFetch("/uploadFiles", convertedFile);

//   if (uploadRes?.status == "ok") {
//     return uploadRes?.url;
//   } else if (uploadRes?.error && uploadRes?.message) {
//     toast.error(uploadRes?.message);
//   } else {
//     toast.error("could not upload file");
//   }
//   return null;
// };

export const handleFileUpload = async (selectedFiles) => {
  const formData = new FormData();

  selectedFiles.forEach((file) => {
    formData.append("files", file);
  });

  const uploadRes = await postFetch("/NEW_uploadFiles", formData);

  if (uploadRes?.status == "ok") {
    let toReturn = [];
    uploadRes?.result.forEach((obj, i) => {
      if (obj.status == "ok" && obj.url) toReturn.push(obj.url);
      else if (obj.error && obj.message)
        toast.error(`for file ${i + 1}, ${obj.message}`);
      else toast.error(`could not upload file ${i + 1}`);
    });

    return toReturn;
  } else if (uploadRes?.error && uploadRes?.message) {
    toast.error(uploadRes?.message);
  } else {
    toast.error("could not upload file");
  }

  return null;
};
