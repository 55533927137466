import React from "react";
import Dashboard from "./components/Dashboard/Index";
import TargetDashboard from "./components/TargetDashboard/Index";

export const ScannerDashboardComponent = Dashboard;
export const TargetDashboardComponent = TargetDashboard;

function Index(props) {
  return <div></div>;
}

export default Index;
