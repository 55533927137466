import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Index(props) {
  const dataObj = useSelector(
    (state) => state?.targetDashboard?.home?.domainInfo
  );

  const data = dataObj?.domain_info;
  const address = dataObj?.whois_data?.Registrant?.Country + " , " + dataObj?.whois_data?.Registrant['State/Province']

  // const renderKeyValuePairs = () => {
  //   if (typeof data === "object") {
  //     return Object?.entries(data)?.map(([key, value], i) => (
  //       <div key={i} className="mb-2 flex-wrap">
  //         <span className="font-bold">{key}</span>: <span>{value}</span>
  //       </div>
  //     ));
  //   }

  //   return null;
  // };

  return (
    <div
      style={{ height: "380px", overflowY: "scroll" }}
      className="text-[#303E55] dark:text-lightBlack1"
    >
      {/* {renderKeyValuePairs()} */}
      <div className="bg-[#F4F5F7] dark:bg-lightBlack6 min-h-[60px] flex items-center pl-2p">
        <div className="w-[10%] opacity-90">Title</div>
        <div className="font-semibold py-1p">{data?.title}</div>
      </div>
      <div className=" min-h-[60px] flex items-center pl-2p ">
        <div className="w-1/2 flex items-center">
          <div className="w-[20%] opacity-90">URL</div>
          <div className="font-semibold py-1p">
            <Link className="no-underline ">{data?.url}</Link>
          </div>
        </div>
        <div className="w-1/2 flex items-center">
          <div className="w-[20%] opacity-90">Status Code</div>
          <div className="font-semibold py-1p">{data?.status_code}</div>
        </div>
      </div>
      <div className="bg-[#F4F5F7] dark:bg-lightBlack6 min-h-[60px] flex items-center pl-2p ">
        <div className="w-1/2 flex items-center">
          <div className="w-[20%] opacity-90">IP Address</div>
          <div className="font-semibold py-1p">{data?.ip_address}</div>
        </div>
        <div className="w-1/2 flex items-center">
          <div className="w-[20%] opacity-90">Location</div>
          <div className="font-semibold py-1p">
            {address}
          </div>
        </div>
      </div>
      <div className=" min-h-[60px] flex items-center pl-2p">
        <div className="w-[10%] opacity-90">ASN</div>
        <div className="font-semibold flex break-words w-[90%] py-1p">
          {data?.asn?.join(", ")}
        </div>
      </div>
      <div className="bg-[#F4F5F7] dark:bg-lightBlack6 min-h-[60px] flex items-center pl-2p">
        <div className="w-[10%] opacity-90">Port</div>
        <div className="font-semibold py-1p">{data?.port}</div>
      </div>
      <div className="min-h-[60px] flex items-center pl-2p">
        <div className="w-[10%] opacity-90">Tech</div>
        <div className="font-semibold py-1p">
          <div className="flex flex-wrap ">
            {/* {data?.tech?.map?.((arr) => (
              <img src={arr?.[0]} alt={arr?.[1]} className="w-10 mr-4 my-1" />
            ))} */}
            {data?.tech_info}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
