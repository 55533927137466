import React, { useRef } from "react";
import { toast } from "react-toastify";

import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { blueGradient1, button1 } from "components/tailwindClasses";
import { getFetch, postFetch } from "lib/fetch";
import Loader from "components/ui/loaders/Loader";
import { useState } from "react";

export default function Step1({
  setCurrentStep,
  password,
  password2,
  oldPassword,
  setOldPassword,
  setPassword,
  setPassword2,
  capchaRef,
}) {
  const handleCaptchaChange = () => {
    // toast(capchaRef.current.getValue());
  };

  const [loading, setLoading] = useState(false);

  let handleNextClick = async (e) => {
    e.preventDefault();

    const formEle = document.getElementById("resetPasswordStep1-0953");

    if (formEle && formEle.checkValidity()) {
      if (password2 != password) {
        toast.warn("confirm password doesn't match");
      } else if (!capchaRef.current.getValue()) {
        toast.warn("please verify captcha");
      } else {
        setLoading(() => true);

        const sendOtpRes = await postFetch("/sendPasswordChangeEmailOTP", {
          old_password: oldPassword,
        });

        if (sendOtpRes?.status == "ok") {
          toast.success("otp was sent to your email");
          setCurrentStep(() => 1);
        } else if (sendOtpRes?.error && sendOtpRes?.message) {
          toast.error(sendOtpRes.message);
        } else {
          toast.error("some error occured in sending otp, try later");
        }

        setLoading(() => false);
      }
    } else {
      formEle.reportValidity();
    }
  };

  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";

  return (
    <form id="resetPasswordStep1-0953">
      <div
        style={{
          padding: "3%",
          boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
        }}
        className="dark:bg-lightBlack bg-white dark:text-white"
      >
        <div>
          <div className="text-lg font-semibold">Old Password</div>
          <input
            type="text"
            className="first-step-input  dark:bg-lightBlack3"
            placeholder="Type Here"
            style={{ marginTop: "1%" }}
            required
            value={oldPassword}
            onChange={(e) => {
              setOldPassword(() => e.target.value);
            }}
          />
        </div>
        <div>
          <div className="text-lg font-semibold">New Password</div>
          <input
            type="text"
            className="first-step-input dark:bg-lightBlack3"
            placeholder="Type Here"
            style={{ marginTop: "1%" }}
            required
            minLength={8}
            maxLength={12}
            pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$" // Add the regex pattern here
            value={password}
            onChange={(e) => {
              setPassword(() => e.target.value);
            }}
          />
          <div className="text-sm">
            Include at least two of the following:
            <ul className="list-disc pl-3">
              <li>o An uppercase character</li>
              <li>o A lowercase character</li>
              <li>o A number</li>
              <li>o A special character</li>
            </ul>
          </div>
        </div>
        <div className="mt-1p">
          <div className="text-lg font-semibold">Confirm Password</div>
          <input
            type="text"
            className="first-step-input dark:bg-lightBlack3"
            placeholder="Type Here"
            minLength={8}
            maxLength={12}
            required
            style={{ marginTop: "1%" }}
            value={password2}
            onChange={(e) => {
              setPassword2(() => e.target.value);
            }}
          />
        </div>
        <div>
          {" "}
          <ReCAPTCHA
            ref={capchaRef}
            sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
            onChange={handleCaptchaChange}
            theme={isDarkTheme ? "dark" : "light"}
          />
        </div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <div
            className={
              "w-60 text-base cursor-pointer flex items-center justify-center font-bold text-white h-15  rounded-10 dark:bg-white dark:border-2 dark:border-white" +
              blueGradient1
            }
            onClick={(e) => {
              !loading && handleNextClick(e);
            }}
          >
            {loading ? <Loader /> : <div> Next Step</div>}
          </div>
        </div>
      </div>
    </form>
  );
}
