import React, { useEffect } from "react";
import RightSection from "./RIghtSection/Index";
import LeftSection from "./LeftSection/Index";
import UserNavbar from "Layouts/userNavbar/Index";
import { useState } from "react";

import { postFetch, pythonPostFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { targetDashboardSliceActions } from "store/scanner/targetDashboardSlice";

// top navbar i ll add later
function Index(props) {
  const location = useLocation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const websiteURL  = useSelector((state)=>state.scanner.targetDashboard.home.websiteUrl);
  const currentVulnId  = useSelector((state)=>state.scanner.targetDashboard.currentVulnerability); 


  if (!location?.state?.fromApp) {
    navigate("/");
  }

  // const currentVulnId = useSelector(
  //   (state) => state.targetDashboard.currentVulnerability
  // );

  const [vulnerabilityData, setVulnerabilityData] = useState({});

  // handling reload

  // this useEffect checks if state information is present is session id for 
  // reload purposes => because no proper routing is done 
  // useEffect(() => {

  //   let savedState = sessionStorage.getItem("vulnerabilityReportState");

  //   if (savedState) {
  //     savedState = JSON.parse(savedState);
  //     if (!currentVulnId && savedState?.vulnerabilityId) {
  //       dispatch(
  //         targetDashboardSliceActions.setCurrentVulnerability(
  //           savedState.vulnerabilityId
  //         )
  //       );
  //       dispatch(targetDashboardSliceActions.setWebsiteUrl(savedState.websiteURL));
        
  //     }
  //   }
  // }, []);

  // this use effect take the current state info and stores it in the 
  // session storage so that it could be used after a reload 
  // since you wont be having any url params that could be used for fetching data 
  // we are not gonna go beyond one route .... no url params ... no url queries ...
  useEffect(() => {
  
    // let toSave = {
    //   vulnerabilityId: currentVulnId,
    //   websiteURL:websiteURL,
    // };

    if(currentVulnId && websiteURL){
      sessionStorage.setItem("vulnerabilityReportState", JSON.stringify({vulnerabilityId:currentVulnId,websiteURL:websiteURL}));
    }
  }, []);


  // finally we have the main use effect 
  // this makes the api call and fetched the required data for the page
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // coz link brings it center of page
    window.scrollTo(0, 0);
    const getData = async () => {
      setLoading(() => true);
      const reportReqParams = JSON.parse(sessionStorage.getItem('vulnerabilityReportState'));
      
    const res = await pythonPostFetch("/asset/result", {
        url: JSON.parse(sessionStorage.getItem('dashboardPayload')).asset_url,
        id:  currentVulnId || reportReqParams?.vulnerabilityId 
      });

     

      if (res.status === "ok") {
        
        const res2 = await postFetch("/scanner/companyBug/getStatusChangeComments",{
          bug_id: res.data.company_bug_id
        });

        if(res2.status === 'ok'){

          res.data = {
            ...res.data,
            comments : res2.comments
          }

          setVulnerabilityData({
            ...res.data,
            // comments: res?.data?.comments?.sort(
            //   (a, b) => new Date(a?.createdDate) - new Date(b?.createdDate)
            // ),
          });

        }

      } else toast.error("Some error occured, try later !");

      setLoading(() => false);
    };

     getData();
  }, []);


  return (
    <UserNavbar>
      <div
        style={{
          display: "flex",
          padding: "1% 1%",
        }}
      >
        <LeftSection
          data={vulnerabilityData}
          setData={setVulnerabilityData}
          loading={loading}
        />
        <RightSection
          data={vulnerabilityData}
          setData={setVulnerabilityData}
          loading={loading}
        />
      </div>
    </UserNavbar>
  );
}

export default Index;
