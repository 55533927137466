import React from "react";
import PropTypes from "prop-types";
import useResendOTP from "./ResendOtpHook";

function ResendOTP({
  getRemainingTime,
  renderTime,
  renderButton,
  style,
  className,
  resetClickPassed = null,
  maxTime = null,
  ...props
}) {
  const { remainingTime, handelResendClick } = useResendOTP({
    maxTime: maxTime || ResendOTP.defaultProps.maxTime,
    ...props,
  });

  getRemainingTime(remainingTime);

  return (
    <div
      className={className || ""}
      data-testid="otp-resend-root"
      style={{
        display: "flex",
        justifyContent: "space-between",
        ...style,
      }}
    >
      {remainingTime != 0 && <span>{remainingTime} sec</span>}

      {remainingTime == 0 && (
        <button
          disabled={remainingTime !== 0}
          onClick={() => {
            handelResendClick();
            resetClickPassed && resetClickPassed();
          }}
          type="button"
        >
          Resend OTP
        </button>
      )}
    </div>
  );
}

ResendOTP.defaultProps = {
  maxTime: 60,
  timeInterval: 1000,
  style: {},
};

ResendOTP.propTypes = {
  onTimerComplete: PropTypes.func,
  onResendClick: PropTypes.func,
  renderTime: PropTypes.func,
  renderButton: PropTypes.func,
  maxTime: PropTypes.number,
  timeInterval: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default ResendOTP;
