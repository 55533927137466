export const Columns = (user) => {
  let columns = [
    { id: "status", label: "Status", minWidth: 150, align: "left" },
    { id: "title", label: "Title", minWidth: 200, align: "left" },
    { id: "type", label: "Type", minWidth: 100, align: "left" },

    {
      id: "severity",
      label: "Severity",
      minWidth: 100,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "cvssScore",
      label: "CVSS Score",
      minWidth: 50,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "cve",
      label: "CVE/CWE",
      minWidth: 120,
      align: "left",
    },
    {
      id: "team",
      label: "Team Members",
      minWidth: 50,
      align: "left",
    },
    {
      id: "createdOn",
      label: "Created On",
      minWidth: 150,
      align: "left",
    },
  ];

  if (user?.role === "MANAGER" || user?.role == "ENGINEER") {
    columns.push({ id: "hasNewChat", label: "", minWidth: 90, align: "left" });
  }

  return columns;
};
