import React from "react";

import Grid from "@mui/material/Grid";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ThirdData from "./ThirdData";
import { stepsSliceActions } from "store/stepsSlice";
import { backButton, blueGradient1, button1 } from "components/tailwindClasses";

function createData(assets, rating, position, review) {
  return { assets, rating, position, review };
}

export default function Third() {
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.steps.activeStep);

  const handleNextClick = (e) => {
    e.preventDefault();

    dispatch(stepsSliceActions.setActiveStep(activeStep + 1));
  };

  const handleBackClick = (e) => {
    dispatch(stepsSliceActions.setActiveStep(activeStep - 1));
  };

  const handleSkipClick = (e) => {
    dispatch(stepsSliceActions.setActiveStep(activeStep + 1));
  };

  return (
    <div>
      <div
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
        }}
        className="bg-white py-3p px-[5%] dark:bg-lightBlack dark:text-white"
      >
        <Grid container>
          <div className="w-full">
            <ThirdData />

            <div style={{ borderTop: "2px solid rgba(0, 0, 0, 0.2)" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "2%",
                }}
              >
                <button
                  className={backButton}
                  style={{ width: "240px" }}
                  onClick={handleBackClick}
                >
                  Back
                </button>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    onClick={handleSkipClick}
                    style={{
                      // marginTop: "3.2%",
                      // padding: "",
                      textDecoration: "none",
                      // marginRight: "10%",
                      color: "#2D3B51 !important",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      margin: "0 50px",
                    }}
                  >
                    Skip
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <div
                      className={
                        blueGradient1 +
                        " w-60 h-15 rounded-10 font-bold flex items-center justify-center cursor-pointer"
                      }
                      onClick={handleNextClick}
                    >
                      <div>Next Step</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
}
