import React from "react";
import { TargetComponent } from "features/target";

import { useSelector } from "react-redux";

function Target(props) {
  let user = useSelector((state) => state.user.data);

  return <TargetComponent />;
}

export default Target;
