import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step1: {
    asset: null,
    scanName: null,
    webAppUrl: null,
    tag: null,

    tags: [], // to select from
    assets: [], // to select from
  },

  step2: {
    engineName: null,
    scanType: null,
    scanSpeed: null,
    threads: null,

    crawlScope: null,
    crawlDepth: null,
    maxLinksToCrawl: null,
    robotsFile: null,
    sitemapFile: null,

    engines: [], // to select from
  },

  step3: {
    scanNow: null, // true or false
    // if schedule is true, then set the following
    date: null,
    frequency: null, // once, weekly, biweekly, monthly
  },

  step4: {
    email: null,
    reportFormat: null,
    password: null,
    confirmPassword: null,
  },
};

let editScanProfileSlice = createSlice({
  name: "editScanProfileSlice",
  initialState: initialState,
  reducers: {
    setAsset(state, action) {
      state.step1.asset = action.payload;
    },
    setScanName(state, action) {
      state.step1.scanName = action.payload;
    },
    setWebAppUrl(state, action) {
      state.step1.webAppUrl = action.payload;
    },
    setTag(state, action) {
      state.step1.tag = action.payload;
    },
    setTags(state, action) {
      state.step1.tags = action.payload;
    },
    setAssets(state, action) {
    
      state.step1.assets = action.payload;
    },
    setEngineName(state, action) {
      state.step2.engineName = action.payload;
    },
    setEngineData(state, action) {
      state.step2.scanType = action.payload.scanType;
      state.step2.scanSpeed = action.payload.scanSpeed;
      state.step2.threads = action.payload.threads;
      state.step2.engineId = action.payload.engineId;
      state.step2.crawlScope = action.payload.crawlScope;
      state.step2.crawlDepth = action.payload.crawlDepth;
      state.step2.maxLinksToCrawl = action.payload.maxLinksToCrawl;
      state.step2.robotsFile = action.payload.robotsFile;
      state.step2.sitemapFile = action.payload.sitemapFile;
    },
    setEngines(state, action) {
      state.step2.engines = action.payload;
    },

    setScanNow(state, action) {
      state.step3.scanNow = action.payload;
    },
    setDate(state, action) {
      state.step3.date = action.payload;
    },
    setFrequency(state, action) {
      state.step3.frequency = action.payload;
    },
    setEmail(state, action) {
      state.step4.email = action.payload;
    },
    setReportFormat(state, action) {
      state.step4.reportFormat = action.payload;
    },
    setPassword(state, action) {
      state.step4.password = action.payload;
    },
    setConfirmPassword(state, action) {
      state.step4.confirmPassword = action.payload;
    },
    reset(state) {
      return initialState;
    },
  },
});

export const editScanProfileSliceActions = editScanProfileSlice.actions;

export default editScanProfileSlice;
