import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { Box, Modal, TableSortLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { inviteSliceActions } from "store/inviteSlice";
import TableLoadingSkeleton from "components/ui/loaders/TableSkeleton";
import { blueGradient1 } from "components/tailwindClasses";
import Loader from "components/ui/loaders/Loader";

export default function TableComponent({
  option = 0,
  isLoading = false,
  search = "",
}) {
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  const user = useSelector((state) => state.user.data);

  const columns = [
    { id: "targetName", label: "Target Name", minWidth: 270, align: "left" },
    {
      id: "description",
      label: "Description",
      minWidth: 170,
      maxWidth: 400,
      align: "left",
    },
    {
      id: "category",
      label: "Category",
      minWidth: 170,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "tag",
      label: "Tag",
      minWidth: 270,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "status",
      label: "Status",
      minWidth: 270,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "date",
      label: "Date",
      minWidth: 270,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    },
  ];

  if (user.role == "MANAGER") {
    columns.push({
      id: "lock",
      label: "",
      minWidth: 270,
      align: "left",
      format: (value) => value.toLocaleString("en-US"),
    });
  }

  const dispatch = useDispatch();
  let invites = useSelector((state) => state.invite.invites);

  // console.log(invites);

  const acceptInvite = async (id) => {
    if (!id) {
      toast.error("could not recognise request, try later");
      return;
    }
    const res = await postFetch("/companyInvite/acceptInvite", {
      invite_id: id,
    });
    if (res?.status == "ok") {
      toast.success("Invite accepted successfully !");

      let toSet = [...invites];
      let elementToUpdate = toSet.find((i) => i.inviteId == id);
      if (elementToUpdate) {
        let updatedElement = { ...elementToUpdate, status: "accepted" };
        toSet[toSet.indexOf(elementToUpdate)] = updatedElement;
      }

      dispatch(inviteSliceActions.setInvites(toSet));
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else {
      toast.error("some error occured, try later");
    }
  };
  const rejectInvite = async (id) => {
    if (!id) {
      toast.error("could not identify invitation, try later");
      return;
    }
    const res = await postFetch("/companyInvite/rejectInvite", {
      invite_id: id,
    });

    if (res?.status == "ok") {
      toast.success("Invite rejected successfully !");

      let toSet = [...invites];
      let elementToUpdate = toSet.find((i) => i.inviteId == id);
      if (elementToUpdate) {
        let updatedElement = { ...elementToUpdate, status: "rejected" };
        toSet[toSet.indexOf(elementToUpdate)] = updatedElement;
      }

      dispatch(inviteSliceActions.setInvites(toSet));
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else {
      toast.error("some error occured, try later");
    }
  };

  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (columnId) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnId);
      setSortOrder("asc");
    }
  };

  const sortedInvites = invites.slice().sort((a, b) => {
    const aValue = a[sortBy];
    const bValue = b[sortBy];
    if (sortOrder === "asc") {
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
    } else {
      if (aValue > bValue) return -1;
      if (aValue < bValue) return 1;
    }
    return 0;
  });

  let rows = sortedInvites
    ?.filter((i) => {
      if (option == 1) {
        return i?.status == "accepted";
      } else if (option == 2) {
        return i?.status == "pending";
      } else if (option == 3) {
        return i?.status == "rejected" || i?.accessAllowed == false;
      }
      return true;
    })
    ?.filter((searchName) => {
      return searchName?.targetName
        ?.toLowerCase()
        .includes(search?.toLowerCase());
    })
    ?.map((item) => {
      return {
        targetName: <Link className="no-underline">{item?.targetName}</Link>,
        description: item?.description,
        category: item?.category,
        tag: item?.tag,
        status:
          item?.status == "pending" ? (
            user?.role == "MANAGER" ? (
              "pending"
            ) : (
              <div className="flex">
                <div
                  className={
                    blueGradient1 +
                    " flex cursor-pointer items-center justify-center rounded-full w-12 h-12 text-4xl mr-1p text-white"
                  }
                  onClick={() => {
                    acceptInvite(item?.inviteId);
                  }}
                >
                  <img src="/tick.svg" alt="" />
                </div>
                <div
                  style={{
                    borderRadius: "100%",
                    width: "50px",
                    height: "50px",
                    fontSize: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "white",
                    border: "2px solid #303e55",
                  }}
                  onClick={() => {
                    rejectInvite(item?.inviteId);
                  }}
                  className="cursor-pointer "
                >
                  <img src={"/cross.svg"} alt="" style={{}} />
                </div>{" "}
              </div>
            )
          ) : item?.status == "accepted" ? (
            <span className="text-low font-semibold">Accepted</span>
          ) : (
            <span className="text-critical font-semibold">Rejected</span>
          ),
        date: item?.date && new Date(item?.date)?.toDateString(),
        lock: item?.status == "accepted" && (
          <img
            className="cursor-pointer"
            src={item?.accessAllowed ? "/lockOpen.svg" : "/lock.svg"}
            alt=""
            onClick={() => {
              setCurrentRevokeData(() => {
                return { id: item?.inviteId, name: item?.targetName };
              });
              handleLockClick(item?.accessAllowed, item?.inviteId);
            }}
          />
        ),
        accessAllowedProp: item?.accessAllowed,
        statusProp: item?.status,
        nameProp: item?.targetName,
      };
    });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // revoke access modal
  const [openRevokeAccessModal, setOpenRevokeAccessModal] = useState(false);

  const handleRevokeAccessModalOpen = () => {
    setOpenRevokeAccessModal(() => true);
  };
  const handleRevokeAccessModalClose = () =>
    setOpenRevokeAccessModal(() => false);

  const [currentRevokeData, setCurrentRevokeData] = useState({
    id: "",
    name: "",
  });

  const [twoFaInput, setTwoFaInput] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLockClick = async (isAccessAllowed, id) => {
    if (!id) {
      toast.error("could not identify request !");
      return;
    }
    if (isAccessAllowed) {
      handleRevokeAccessModalOpen();
    } else {
      setLoading(() => true);

      const res = await postFetch("/companyInvite/grantAccess", {
        invite_id: id,
      });
      if (res?.status == "ok") {
        toast.success("access granted successfully !");

        const toSet = [...invites];
        let elementToUpdate = toSet.find((i) => i.inviteId == id);
        if (elementToUpdate) {
          elementToUpdate.accessAllowed = true;
        }

        dispatch(inviteSliceActions.setInvites(toSet));
      } else if (res?.error && res?.message) {
        toast.error(res?.message);
      } else {
        toast.error("some error occured, try later!");
      }

      setLoading(() => false);
    }
  };

  const revokeAccess = async () => {
    const formEle = document.getElementById("revokeAccessForm9234sd");

    if (!(formEle && formEle.checkValidity())) {
      formEle.reportValidity();
      return;
    }

    setLoading(() => true);

    const res = await postFetch("/companyInvite/revokeAccess", {
      invite_id: currentRevokeData?.id,
      token: twoFaInput,
    });
    if (res?.status == "ok") {
      toast.success("access revoked successfully !");
      const toSet = [...invites];
      let elementToUpdate = toSet.find(
        (i) => i.inviteId == currentRevokeData?.id
      );
      if (elementToUpdate) {
        elementToUpdate.accessAllowed = false;
      }
      dispatch(inviteSliceActions.setInvites(toSet));

      handleRevokeAccessModalClose();
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else {
      toast.error("some error occured, try later!");
    }
    setLoading(() => false);
  };

  return (
    <div>
      <TableContainer className="rounded border-2 dark:border-0">
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="">
            <TableRow>
              {columns &&
                columns?.length > 0 &&
                columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    // style={{
                    //   minWidth: column.minWidth,
                    //   fontWeight: "600",
                    // }}
                    sx={{ bgcolor: "rgba(217, 217, 217, 0.25)" }}
                    className="font-semibold dark:bg-lightBlack3  dark:text-lightBlack1 dark:border-b9 "
                  >
                    <TableSortLabel
                      active={sortBy === column.id}
                      direction={sortBy === column.id ? sortOrder : "asc"}
                      onClick={() => handleSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          {isLoading && rows?.length == 0 ? (
            <TableLoadingSkeleton
              columnCount={columns?.length}
              tableCellClassName="dark:text-white dark:border-b9 bg-white dark:bg-lightBlack"
            />
          ) : (
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      tabIndex={-1}
                      key={row.code}
                      sx={{
                        opacity:
                          row?.statusProp == "accepted"
                            ? row?.accessAllowedProp
                              ? 1
                              : 0.5
                            : 1,
                      }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className="dark:text-white dark:border-b9 bg-white dark:bg-lightBlack break-words"
                            style={{ maxWidth: column.maxWidth }}
                          >
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="dark:bg-lightBlack dark:text-white bg-white rounded"
      />

      <Modal
        open={openRevokeAccessModal}
        onClose={handleRevokeAccessModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
          }}
          className="bg-white rounded-10 shadow dark:bg-lightBlack dark:text-white p-1p w-[800px]"
        >
          <form id="revokeAccessForm9234sd">
            <div className="text-2xl font-bold flex justify-center pb-2p border-b dark:border-b9 border-b2">
              Revoke Access
            </div>

            <div className="mt-2p">
              Are you sure, you want to revoke access of{" "}
              <span className="font-bold">{currentRevokeData?.name}</span>?
            </div>
            <div>
              <div className="font-bold">Two Factor Authentication:</div>
              <input
                type="text"
                className="second-step-input dark:bg-lightBlack4 h-15"
                value={twoFaInput}
                onChange={(e) => {
                  setTwoFaInput(() => e.target.value);
                }}
                required
              />
            </div>
            <div className="flex items-center justify-end mt-2p">
              <div
                className="cursor-pointer mr-10"
                onClick={handleRevokeAccessModalClose}
              >
                No
              </div>
              <div
                className={
                  " cursor-pointer flex items-center justify-center w-60 h-15 font-bold text-white rounded-10 " +
                  blueGradient1
                }
                onClick={(e) => {
                  !loading && revokeAccess(e);
                }}
              >
                {loading ? <Loader /> : <div>Yes</div>}
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
