import { useRef, useState, useEffect } from "react";
import { getFetch, postFetch } from "lib/fetch";

import { textMessage, meetingLinkMessage } from "./MessageTypes";
import { MessageList, Input, Button } from "react-chat-elements";

import {
  updateReportMessage,
  modifiedReportMessage,
} from "features/dashboard/util/FormatChatMessage";

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import { handleFileUpload } from "services/uploadFile";
import Loader from "components/ui/loaders/Loader";

let clearRef = () => {};

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue(() => value + 1);
}

function MessageListExample({ bug_id }) {
  const user = useSelector((state) => state.user.data);

  const [messageListArray, setMessageListArray] = useState([]);
  const messageListReferance = useRef();
  const inputReferance = useRef();

  const addImage = async (link) => {
    const res = await postFetch("/bug/addComment", {
      bug_id: bug_id,
      content: link,
      isChat: true,
      isImageLink: true,
    });

    if (res.status == "ok") {
      const newComment = res.data;
      setMessageListArray(() => [
        ...messageListArray,
        textMessage(
          newComment._id,
          "right",
          <img src={newComment.content} alt="" />
        ),
      ]);
    } else {
      toast.error("could not msg, try later");
    }
  };

  const addMessage = async (e) => {
    if (loadingComments) {
      toast.info("Please wait for previous messages to load");
      return;
    }

    // here i will call backend and get id of added msg

    const newMessageText = inputReferance.current.value;

    if (!newMessageText) return;

    const res = await postFetch("/bug/addComment", {
      bug_id: bug_id,
      content: newMessageText,
      isChat: true,
    });

    if (res.status == "ok") {
      const newComment = res.data;
      setMessageListArray(() => [
        ...messageListArray,
        textMessage(newComment._id, "right", newComment.content),
      ]);
      // clearRef();
      // forceUpdate();
      // forceUpdate();

      inputReferance.current.value = "";
    } else {
      toast.error("could not msg, try later");
    }
  };

  //

  const [loadingComments, setLoadingComments] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoadingComments(true);

      const res = await postFetch("/bug/getComments", { bug_id: bug_id });
      if (res?.status == "ok") {
        let toSetComments = [];
        let reportChat = res.comments?.sort?.(
          (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
        );

        // we get sorted comments by date

        let commentChat = [];

        // console.log(reportChat.length);
        for (let i = 0; i < reportChat.length; i++) {
          let currentChat = reportChat[i];

          if (currentChat.comment_type == 1 || currentChat.comment_type == 2) {
            if (commentChat.length > 0) {
              let temp = commentChat;

              toSetComments.push(
                textMessage(
                  temp[0]._id,
                  user.id == temp[0].user_id ? "right" : "left",
                  updateReportMessage(
                    temp.filter((c) => c.corresponding_section == "weakness"),
                    temp.filter((c) => c.corresponding_section == "severity"),
                    temp.filter((c) => c.corresponding_section == "parameter"),
                    temp.filter((c) => c.corresponding_section == "poc"),
                    temp[0].role == "Engineer" || temp[0].role == "102"
                  ),
                  temp[temp.length - 1].createdDate
                )
              );
              commentChat = [];
            }
            if (currentChat.comment_type == 1) {
              toSetComments.push(
                textMessage(
                  currentChat?._id,
                  "center",
                  modifiedReportMessage(
                    currentChat?.whatChanged || "bug",
                    currentChat?.user_id == user.id
                      ? "You"
                      : currentChat?.fname,
                    currentChat.prev_state,
                    currentChat.new_state
                  ),
                  currentChat?.createdDate
                )
              );
            } else if (currentChat.comment_type == 2) {
              toSetComments.push(
                textMessage(
                  currentChat?._id,
                  user.id == currentChat.user_id ? "right" : "left",
                  currentChat?.isImageLink ? (
                    <img src={currentChat.content} alt="" />
                  ) : (
                    currentChat?.content
                  ),
                  currentChat?.createdDate
                )
              );
            }
          } else if (currentChat.comment_type == 0) {
            if (commentChat.length > 0) {
              if (commentChat[0].role == currentChat.role) {
                commentChat.push(currentChat);
              } else {
                let temp = commentChat;

                toSetComments.push(
                  textMessage(
                    temp[0]._id,
                    user.id == temp[0].user_id ? "right" : "left",
                    updateReportMessage(
                      temp.filter((c) => c.corresponding_section == "weakness"),
                      temp.filter((c) => c.corresponding_section == "severity"),
                      temp.filter(
                        (c) => c.corresponding_section == "parameter"
                      ),
                      temp.filter((c) => c.corresponding_section == "poc"),
                      temp[0].role == "Engineer" || temp[0].role == "102"
                    ),
                    temp[temp.length - 1].createdDate
                  )
                );

                commentChat = [];
                commentChat.push(currentChat);
              }
            } else commentChat.push(currentChat);
          }
        }
        if (commentChat.length > 0) {
          let temp = commentChat;

          // console.log(temp);

          toSetComments.push(
            textMessage(
              temp[0]._id,
              user.id == temp[0].user_id ? "right" : "left",
              updateReportMessage(
                temp.filter((c) => c.corresponding_section == "weakness"),
                temp.filter((c) => c.corresponding_section == "severity"),
                temp.filter((c) => c.corresponding_section == "parameter"),
                temp.filter((c) => c.corresponding_section == "poc"),
                temp[0].role == "Engineer" || temp[0].role == "102"
              ),
              temp[temp.length - 1].createdDate
            )
          );

          commentChat = [];
        }

        setMessageListArray(() => toSetComments);
      } else if (res?.error && res?.message) {
        toast.error(res.message);
      } else {
        toast.error("Some error occured loading chat, try later !");
      }

      setLoadingComments(false);
    };
    getData();
  }, [bug_id]);

  // console.log(messageListArray);
  //
  //

  useEffect(() => {
    // to scroll chat to bottom
    let chatContainer = document.getElementById("chatContainer0cv89df");
    if (chatContainer) chatContainer.scrollTop = chatContainer?.scrollHeight;
  }, [messageListArray]);

  //
  const handleMeetClick = async () => {
    const getLink = await getFetch("/generate-meet-link");

    if (getLink?.status == "ok") {
      window.open(getLink?.link, "_blank");
    } else {
      toast.error("error in generating meet, try later");
    }
  };

  //
  const [selectedImage, setSelectedImage] = useState(null); // this is show image
  const [selectedImageFile, setSelectedImageFile] = useState(null); // this is upload image

  const [showImagePreview, setShowImagePreview] = useState(false);
  const fileInputRef = useRef(null);

  const openImagePreview = () => {
    setShowImagePreview(true);
  };

  const closeImagePreview = () => {
    setSelectedImageFile(() => null);
    setSelectedImage(() => null);

    setShowImagePreview(false);
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const file = files[0];

    setSelectedImageFile(() => file);

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage(reader.result);
        setShowImagePreview(true);
      };

      reader.readAsDataURL(file);
    }

    // closePopup();
  };

  const handleUpload = async () => {
    // Implement your upload logic here

    if (loadingComments) {
      toast.info("Please wait for previous messages to load");
      return;
    }

    const res = await handleFileUpload([selectedImageFile]);

    res?.length > 0 && (await addImage(res?.[0]));

    closeImagePreview();
  };

  // let closePopup;

  return (
    <div className="h-[450px] overflow-y-scroll " id="chatContainer0cv89df">
      <div className="right-panel rce-example-messageList relative pb-24 ">
        {loadingComments ? (
          <div className="absolute top-1/2 left-1/2">
            <Loader />
          </div>
        ) : (
          <MessageList
            className="message-list"
            referance={messageListReferance}
            dataSource={messageListArray}
            lockable={true}
            downButton={true}
            downButtonBadge={10}
            sendMessagePreview={true}
            // messageBoxStyles={{ backgroundColor: "red" }}
            notchStyle={{ fill: "red", width: "10px" }}
            // notch={false}
          />
        )}
      </div>
      <div className="bg-gray-100 dark:bg-lightBlack  p-2p flex w-full absolute bottom-0 ">
        <div className="grow flex bg-inputBg dark:bg-lightBlack3 dark:text-white rounded mr-4">
          <Input
            type="text"
            className="p-2p grow bg-transparent border-r border-b2 w-full"
            placeholder="Write your message here."
            defaultValue=""
            referance={inputReferance}
            clear={(clear) => (clearRef = clear)}
            maxHeight={50}
            onKeyPress={(e) => {
              if (e.shiftKey && e.charCode === 13) {
                return true;
              }
              if (e.key === "Enter") {
                // clearRef();
                addMessage(e);
              }
            }}
            // rightButtons={
            //   <Button text="Submit" onClick={() => addMessage()}></Button>
            // }
          />
          <div className="flex px-2p">
            <img
              src="/addFileChat.svg"
              alt=""
              className="w-8 mr-4 cursor-pointer"
              onClick={() => fileInputRef.current.click()}
            />

            <Popup
              open={showImagePreview}
              closeOnDocumentClick={false}
              closeOnEscape={false}
              onClose={closeImagePreview}
              nested
            >
              <div className="popup-content fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="max-w-full max-h-full">
                  {selectedImage && (
                    <img
                      src={selectedImage}
                      alt="Image Preview"
                      className="mx-auto max-w-full max-h-[80vh]"
                    />
                  )}
                  <div className="flex justify-center mt-4">
                    <button
                      onClick={handleUpload}
                      className="px-4 py-2 mr-2 bg-green-500 text-white rounded"
                    >
                      Upload
                    </button>
                    <button
                      onClick={closeImagePreview}
                      className="px-4 py-2 bg-red-500 text-white rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Popup>

            <input
              type="file"
              accept="image/*"
              className="hidden"
              ref={fileInputRef}
              onChange={handleImageChange}
            />

            <img
              src="/meetLogo.svg"
              alt=""
              className="w-8 cursor-pointer"
              onClick={handleMeetClick}
            />
          </div>
        </div>

        <img
          src="/chatSubmit.svg"
          alt=""
          className="w-50 "
          onClick={() => {
            addMessage();
          }}
        />
      </div>
    </div>
  );
}

export default MessageListExample;
