import React, { useRef } from "react";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "components/style.css";
import axios from "axios";
import { toast } from "react-toastify";
import PrimaryButton from "components/ui/buttons/SecondaryButton";
import { blueGradient1 } from "components/tailwindClasses";
import { postFetch } from "lib/fetch";

function ChangePass2() {
  let navigate = useNavigate();

  let passwordRef = useRef("");
  let passwordRef2 = useRef("");

  let [searchParams, setSearchParams] = useSearchParams();
  let email = searchParams.get("email");
  let resetpswd_token = searchParams.get("token");

  let handleFormSubmit = async (e) => {
    e.preventDefault();

    const res = await postFetch(`/resetPassword`, {
      email,
      token: resetpswd_token,
      password: passwordRef.current.value,
      password2: passwordRef2.current.value,
    });
    if (res?.status === "ok") {
      toast.success("password updated !");
      navigate("/login");
    } else if (res?.error && res?.message) {
      toast.error(res.message);
    } else {
      toast.error("some error occured !");
    }
  };

  return (
    <>
      <div className="flex h-full">
        <div className={"relative w-[58%]  h-full text-white " + blueGradient1}>
          <div className="absolute top-1/2 left-[45%] -translate-x-1/2 -translate-y-1/2">
            <div className="text-4xl w-fit mb-2">
              <b>
                Enter Your <br /> New Password
              </b>
            </div>
          </div>
          <div>
            <img src={"/loginBgLines.svg"} className="anim" alt="anim" />
          </div>
          <img
            src={"/resetScreenLogo2.svg"}
            className="  absolute bottom-0 left-0 w-1/3 "
            alt="anim"
          />
        </div>

        <div className="relative w-[42%] bg-[#ececec] h-full flex flex-col justify-center items-center">
          <div className="absolute top-[5%] left-[5%]">
            <div className="text-xl font-medium">
              <img src={"cyteHackLogo.svg"} className="logo h-10 inline" />
              &nbsp; CyeNET
            </div>
          </div>
          <div className="flex justify-center items-center w-full p-10">
            <form className="w-full">
              <h1 className="mb-10 text-center text-3xl">
                <b>Reset Your Password</b>
              </h1>
              <label htmlFor="pass">
                <p className="font-medium text-base">New Password</p>
              </label>
              <input
                ref={passwordRef}
                type="password"
                pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$" // Add the regex pattern here
                className="text-base mt-1p mb-3p w-full p-4 h-16 bg-inputBg border-b2 border rounded-10"
                required
              />

              <label htmlFor="pass">
                <p style={{ margin: 0, fontsize: "22px", fontWeight: "500" }}>
                  Confirm Password
                </p>
              </label>
              <input
                ref={passwordRef2}
                type="password"
                pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$" // Add the regex pattern here
                className="text-base mt-1p mb-3p w-full p-4 h-16 bg-inputBg border-b2 border rounded-10"
                required
              />

              <div className="text-xs">
                Include all of the following:
                <ul class="list-disc">
                  <li>o An uppercase character</li>
                  <li>o A lowercase character</li>
                  <li>o A number</li>
                  <li>o A special character</li>
                </ul>
              </div>

              <br />
              <PrimaryButton type="submit" onClick={handleFormSubmit}>
                Next Step
              </PrimaryButton>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePass2;
