import React, { useState } from "react";
import anim from "components/Union.png";
import axios from "axios";
import { useRef } from "react";
import "components/style.css";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PrimaryButton from "components/ui/buttons/SecondaryButton";
import { blueGradient1 } from "components/tailwindClasses";
import { postFetch } from "lib/fetch";
import Loader from "components/ui/loaders/Loader";

function ChangePass1() {
  let navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");

  const [loading, setLoading] = useState(false);

  let handleSubmit = async (e) => {
    e.preventDefault();

    const formEle = document.getElementById("resetPassOutside82934jksf");

    if (formEle && formEle.checkValidity()) {
      setLoading(() => true);

      const res = await postFetch("/sendResetPasswordMail", { email });
      if (res?.status == "ok") {
        navigate("/link");
      } else if (res?.error && res?.message) {
        toast.error(res.message);
      } else {
        toast.error("some error occured !");
      }

      setLoading(() => false);
    } else {
      formEle.reportValidity();
    }
  };

  return (
    <form className="flex h-full" id="resetPassOutside82934jksf">
      <div className={"relative w-[58%]  h-full text-white " + blueGradient1}>
        <div className="absolute top-1/2 left-[45%] -translate-x-1/2 -translate-y-1/2">
          <div className="text-4xl  mb-2">
            <b>
              Reset <br /> Your Password
            </b>
          </div>
          <div className="text-2xl text-[#c3d2d2] w-fit ">
            Please enter your email, mobile number and we’ll send the reset
            password link to your email password
          </div>
        </div>

        <img
          src={"/resetScreenLogo1.svg"}
          className="  absolute bottom-0 left-0 w-1/3 "
          alt="anim"
        />
        <img
          src={"/loginBgLines.svg"}
          className="  absolute top-0 left-0 w-1/3  -scale-y-100"
          alt="anim"
        />
      </div>

      <div className="relative w-[42%] bg-[#ececec] h-full  flex flex-col justify-center items-center">
        <div className="absolute top-[5%] left-[5%]">
          <div className="text-xl font-medium">
            <img
              src={"cyteHackLogo.svg"}
              alt="anim"
              className="logo h-10 inline"
            />
            &nbsp; CyeNET
          </div>
        </div>
        <div className="flex justify-center items-center w-full p-10">
          <div className="w-full">
            <h1 className="mb-5 text-center text-3xl">
              <b>Reset Your Password</b>
            </h1>
            <label htmlFor="email">
              <p className="font-medium text-base">Email</p>
            </label>
            <input
              value={email}
              onChange={(e) => {
                setEmail(() => e.target.value);
              }}
              type="email"
              className="text-base mt-1p mb-3p w-full p-4 h-16 bg-inputBg border-b2 border rounded-10"
              placeholder="john@cena.com"
              required
            />
            {/* <label htmlFor="tel">
              <p className="font-medium text-base">Mobile Number</p>
            </label>
            <input
              value={mobile}
              onChange={(e) => {
                setMobile(() => e.target.value);
              }}
              type="tel"
              id="phone"
              name="phone"
              pattern="[0-9]{10}"
              className="text-base mt-1p mb-3p w-full p-4 h-16 bg-inputBg border-b2 border rounded-10"
              placeholder="XXXX XX XXXX"
            ></input> */}

            <br />
            <div
              className={
                blueGradient1 +
                " font-bold rounded-10 text-white w-full h-15 flex justify-center items-center cursor-pointer"
              }
              onClick={(e) => {
                !loading && handleSubmit(e);
              }}
            >
              {loading ? <Loader /> : "Done"}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default ChangePass1;
