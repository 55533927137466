import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selected: 0,

  companyDetails: {
    name: null,
    profileImage: null,
    description: null,
    location: null,
    createdDate: null,
    founder: null,
    foundedDate: null,
    phoneNumber: null,
    websiteUrl: null,
    email: null,
    facebookUrl: null,
    linkedinUrl: null,
    instagramUrl: null,
    twitterUrl: null,
    policy: null,
    address: null,
  },
};

let profileSlice = createSlice({
  name: "profileSlice",
  initialState: initialState,
  reducers: {
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setCompanyDetails(state, action) {
      state.companyDetails = action.payload;
    },
  },
});

export const profileSliceActions = profileSlice.actions;

export default profileSlice;
