import { Avatar } from "@mui/material";
import { blueGradient1 } from "components/tailwindClasses";
import ColorDot from "components/ui/buttons/ColorDot";
import SecondaryButton from "components/ui/buttons/SecondaryButton";
import StatusButton from "components/ui/buttons/StatusButton";
import { postFetch } from "lib/fetch";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";
import { addVulnerabilityReportSliceActions } from "store/vulnerabilityReport";

// type 1 ( personal )
// type 1 : manager => 1.changed password, 2.mobiel, 3.add / 4.remove member in team, 5.engineer accepted invitation (5)
// 	 engineer => changed password, mobiel, added / removed from team (4)

// type 2 ( Bug tracker )
// type 2 :
// 	s_manager =>  6.engineer sent initial bug,  7.sent msg on bug (2)
// 	manager => 8.sent msg on vuln, 9. someone suggested change status of vuln (2)
// 	engineer => 9.accepted/10.declined change state of vuln, someone sent msg on vuln, someone suggested change in
// 			state of vuln (3)
// 	s_engineer => 11.manager changed state for submitted bug of engineer,
// 			 someone commented on their bug ,someone modified their bug (3)

// Doubt : message v comment

// type 3 (vuln )
// type 3 :
// 	S_manager => someone commented on vuln, someone changed state of vuln (2)
// 	S_engineer => someone commented on vuln, someone changed state of vuln, moved bug to vuln as new (3)
// 	engineer => someone added new vuln , someone commented on vuln, someone changed state of vuln
// 	manager => someone added new vuln , someone commented on vuln (2)

// 12. engineer suggested state change on vuln
// 13. manager changed state of vuln
// 14. someone commented on vuln
// 15. submitted new vuln

export const NotificationFormat = (
  notification,
  user,
  navigate,
  dispatch,
  location,
  isDarkMode = false
) => {
  switch (notification.type) {
    case "1":
      return Type1(navigate);
    case "2":
      return Type2(navigate);
    case "3":
      return Type3(
        notification,
        user,
        navigate,
        dispatch,
        location,
        isDarkMode
      );
    case "4":
      return Type4(notification, user, navigate, isDarkMode);
    case "5":
      return Type5(notification, navigate);
    case "6":
      return Type6(notification, navigate, dispatch);
    case "7":
      return Type7(notification, navigate, dispatch, user, location);
    case "8":
      return Type8(notification, navigate, dispatch, user);
    case "11":
      return Type11(notification, user, dispatch, navigate);
    case "15":
      return Type15(notification, dispatch, navigate);
    case "14":
      return Type14(notification, navigate, dispatch, location);
    case "12":
      return Type12(notification, user, navigate, dispatch);
    case "13":
      return Type13(notification, navigate, dispatch);
    case "16":
      return Type16(notification, user, navigate);
    case "17":
      return Type17(notification, navigate);
    case "18":
      return Type18(notification, user, navigate);

    default:
      return null;
  }

  // will return component if suitable else null
};

export const Type1 = (navigate) => {
  const handleClick = () => {
    navigate("/profile");
  };
  return (
    <div className="flex items-center cursor-pointer" onClick={handleClick}>
      <img src="/lock.svg" alt="" className=" w-5 mr-2" />
      <span>
        You have successfully changed your{" "}
        <span className="font-bold"> password</span>
      </span>
    </div>
  );
};
export const Type2 = (navigate) => {
  const handleClick = () => {
    navigate("/profile");
  };
  return (
    <div
      className="text-sm flex items-center cursor-pointer"
      onClick={handleClick}
    >
      <img src="/mobile.svg" alt="" className=" w-5 mr-2" />
      <span>
        You have successfully changed your{" "}
        <span className="font-bold"> mobile number</span>
      </span>
    </div>
  );
};
export const Type3 = (
  notification,
  user,
  navigate,
  dispatch,
  location,
  isDarkMode
) => {
  const {
    assetId,
    assetName,
    companyName,
    managerName,
    managerProfileImage = "",
    researcherName,
  } = notification.content;

  const handleClick = () => {
    if (user.role == "S_ENGINEER" || user.role == "ENGINEER") {
      dispatch(targetDashboardSliceActions.setCurrentTarget({ id: assetId }));

      if (location.pathname != "/target-dashboard") {
        navigate("/target-dashboard", { state: { fromApp: true } });
      }
    } else {
      navigate("/team");
    }
  };

  return (
    <div className=" flex items-center cursor-pointer " onClick={handleClick}>
      {(user.role == "S_ENGINEER" || user.role == "ENGINEER") && (
        <>
          <Avatar
            src={managerProfileImage || ".png"}
            alt={managerName}
            className="mr-2 w-10"
          />
          <span>
            <span>{managerName || "Manager"} </span>{" "}
            <span
              style={{
                background: "#d9d9d9",
                borderRadius: "40px",
                border: "1px solid #303E55",
                padding: "4px 10px",
                color: "#303E55",
                fontWeight: "700",
              }}
            >
              {companyName}
            </span>{" "}
            <span className="font-bold">added</span> you in{" "}
            <span className="font-bold">{assetName}</span>
          </span>
        </>
      )}
      {(user.role == "S_MANAGER" || user.role == "MANAGER") && (
        <>
          <img
            src={isDarkMode ? "/userAddedDark.svg" : "/userAdded.svg"}
            alt=""
            className=" w-6 mr-2"
          />
          <span>
            You <span className="font-bold">added</span>{" "}
            <span>{researcherName || "researcher"} </span>{" "}
            <span
              style={{
                background: "#d9d9d9",
                borderRadius: "40px",
                border: "1px solid #303E55",
                padding: "4px 10px",
                color: "#303E55",
                fontWeight: "700",
              }}
            >
              {companyName}
            </span>{" "}
            in <span className="font-bold">{assetName}</span>
          </span>
        </>
      )}
    </div>
  );
};
export const Type4 = (notification, user, navigate, isDarkMode) => {
  const {
    assetId,
    assetName,
    companyName,
    managerName,
    managerProfileImage = "",
    researcherName,
  } = notification.content;

  const handleClick = () => {
    if (user.role == "S_ENGINEER" || user.role == "ENGINEER") {
      navigate("/target");
    } else {
      navigate("/team");
    }
  };

  return (
    <div className=" flex items-center cursor-pointer" onClick={handleClick}>
      {(user.role == "S_ENGINEER" || user.role == "ENGINEER") && (
        <>
          <Avatar
            src={managerProfileImage || ".png"}
            alt={managerName}
            className="mr-2 w-10"
          />
          <span>
            <span>{managerName || "Manager"} </span>{" "}
            <span
              style={{
                background: "#d9d9d9",
                borderRadius: "40px",
                border: "1px solid #303E55",
                padding: "4px 10px",
                color: "#303E55",
                fontWeight: "700",
              }}
            >
              {companyName}
            </span>{" "}
            <span className="font-bold">removed</span> you from{" "}
            <span className="font-bold">{assetName}</span>
          </span>
        </>
      )}
      {(user.role == "S_MANAGER" || user.role == "MANAGER") && (
        <>
          <img
            src={isDarkMode ? "/userRemovedDark.svg" : "/userRemoved.svg"}
            alt=""
            className=" w-6 mr-2"
          />
          <span>
            You <span className="font-bold">removed</span>{" "}
            <span>{researcherName || "researcher"} </span>{" "}
            <span
              style={{
                background: "#d9d9d9",
                borderRadius: "40px",
                border: "1px solid #303E55",
                padding: "4px 10px",
                color: "#303E55",
                fontWeight: "700",
              }}
            >
              {companyName}
            </span>{" "}
            from <span className="font-bold">{assetName}</span>
          </span>
        </>
      )}
    </div>
  );
};
export const Type5 = (notification, navigate) => {
  const {
    accepted,
    companyId,
    companyName,
    managerId,
    researcherId,
    researcherName,
    researcherProfileImage,
    userAddedInAssets,
  } = notification.content;

  if (!accepted) return null;

  const handleClick = () => {
    navigate("/team");
  };

  return (
    <div className=" flex items-center cursor-pointer" onClick={handleClick}>
      <Avatar
        src={researcherProfileImage || ".png"}
        alt={researcherName}
        className="mr-2 w-10"
      />
      <span>
        <span>{researcherName || "researcher"} </span>{" "}
        <span
          style={{
            background: "#d9d9d9",
            borderRadius: "40px",
            border: "1px solid #303E55",
            padding: "4px 10px",
            color: "#303E55",
            fontWeight: "700",
          }}
        >
          {companyName}
        </span>{" "}
        <span className="font-bold">accepted</span> your invitation{" "}
        <span className="font-bold">
          {" "}
          {(userAddedInAssets && userAddedInAssets[0]?.name) || ""}
        </span>
      </span>
    </div>
  );
};
export const Type6 = (notification, navigate, dispatch) => {
  const {
    assetName,
    assetId,
    companyName,
    initialBugId,
    initialBugName,
    managerId,
    researcherId,
    researcherImage = "",
    researcherName,
  } = notification?.content;

  const handleClick = () => {
    // these are states needed for that page

    dispatch(addVulnerabilityReportSliceActions.setId(initialBugId));
    dispatch(addVulnerabilityReportSliceActions.setReportViewType("update"));

    dispatch(targetDashboardSliceActions.setCurrentTarget({ id: assetId }));

    navigate("/bugReport", { state: { fromApp: true } });
  };

  return (
    <div className=" flex items-center cursor-pointer " onClick={handleClick}>
      <Avatar
        src={researcherImage || ".png"}
        alt={researcherName}
        className="mr-2 w-10"
      />

      <div className="flex flex-wrap items-center">
        <span className="mx-1">{researcherName || "researcher"} </span>{" "}
        <span
          style={{
            background: "#d9d9d9",
            borderRadius: "40px",
            border: "1px solid #303E55",
            padding: "4px 10px",
            color: "#303E55",
            fontWeight: "700",
          }}
          className="mr-1"
        >
          {companyName}
        </span>{" "}
        sent{" "}
        <span className="font-bold mx-1">
          {" "}
          <ColorDot type={"initial"} width={3} /> Initial
        </span>{" "}
        Bug{" "}
        {initialBugId && (
          <span
            style={{
              color: "white",
              background: "#539486",
              borderRadius: "5px",
              padding: "4px 8px",
            }}
            className="mx-1 "
          >
            {/* #-
            {InitialBugId.slice(InitialBugId.length - 3, InitialBugId.length)} */}
            #{initialBugId}
          </span>
        )}
        <Link>{initialBugName}</Link>{" "}
        <span className="font-bold ml-1">{assetName}</span>
      </div>
    </div>
  );
};
export const Type7 = (notification, navigate, dispatch, user, location) => {
  const {
    assetId,
    assetName,
    bugId,
    bugName,
    commentorId,
    commentorImage = "",
    commentorName,
    companyName,
    isChat,
  } = notification?.content;

  const handleClick = () => {
    // these are states needed for that page

    dispatch(targetDashboardSliceActions.setCurrentTarget({ id: assetId }));

    if (isChat) {
      dispatch(targetDashboardSliceActions.setCurrentSection(2));

      navigate("/target-dashboard", {
        state: { fromApp: true, bugId: bugId, openChat: true },
      });
    } else {
      dispatch(addVulnerabilityReportSliceActions.setId(bugId));
      const reportType =
        user.role == "S_MANAGER"
          ? "update"
          : user.role == "S_ENGINEER"
          ? "edit"
          : "";

      dispatch(
        addVulnerabilityReportSliceActions.setReportViewType(reportType)
      );
      navigate("/bugReport", { state: { fromApp: true } });
    }
  };

  return (
    <div className=" flex items-center cursor-pointer" onClick={handleClick}>
      <Avatar
        src={commentorImage || ".png"}
        alt={commentorName}
        className="mr-2 w-10"
      />

      <div className="flex flex-wrap items-center ">
        <span className="mx-1">{commentorName || "Someone"} </span>{" "}
        {companyName && (
          <span
            style={{
              background: "#d9d9d9",
              borderRadius: "40px",
              border: "1px solid #303E55",
              padding: "4px 10px",
              color: "#303E55",
              fontWeight: "700",
            }}
            className="mr-1"
          >
            {companyName}{" "}
          </span>
        )}
        <span className="font-bold mx-1">
          {isChat ? "sent a message " : "commented "}
        </span>{" "}
        on bug{" "}
        {bugId && (
          <span
            style={{
              color: "white",
              background: "#539486",
              borderRadius: "5px",
              padding: "4px 8px",
            }}
            className="mx-1 my-[1px]"
          >
            #{bugId}
          </span>
        )}
        <Link>{bugName}</Link>{" "}
        <span className="font-bold ml-1">{assetName}</span>
      </div>
    </div>
  );
};
export const Type8 = (notification, navigate, dispatch, user) => {
  const {
    modifierCompany,
    modifierName,
    modifierId,
    modifierImage,
    modifiedFields,
    companyName,
    bugId,
    bugName,
    assetName,
    assetId,
  } = notification?.content;

  const handleClick = () => {
    // these are states needed for that page

    dispatch(targetDashboardSliceActions.setCurrentTarget({ id: assetId }));

    dispatch(addVulnerabilityReportSliceActions.setId(bugId));
    const reportType =
      user.role == "S_MANAGER"
        ? "update"
        : user.role == "S_ENGINEER"
        ? "edit"
        : "";

    dispatch(addVulnerabilityReportSliceActions.setReportViewType(reportType));
    navigate("/bugReport", { state: { fromApp: true } });
  };

  return (
    <div className=" flex items-center cursor-pointer " onClick={handleClick}>
      <Avatar
        src={modifierImage || ".png"}
        alt={modifierName}
        className="mr-2 w-10"
      />

      <div className="flex flex-wrap items-center ">
        <span className="mx-1">{modifierName || "Someone"} </span>{" "}
        {companyName && (
          <span
            style={{
              background: "#d9d9d9",
              borderRadius: "40px",
              border: "1px solid #303E55",
              padding: "4px 10px",
              color: "#303E55",
              fontWeight: "700",
            }}
            className="mr-1"
          >
            {companyName}{" "}
          </span>
        )}
        <span className="font-bold mx-1">modified</span> bug{" "}
        {bugId && (
          <span
            style={{
              color: "white",
              background: "#539486",
              borderRadius: "5px",
              padding: "4px 8px",
            }}
            className="mx-1 my-[1px]"
          >
            #{bugId}
          </span>
        )}
        <Link>{bugName}</Link>{" "}
        <span className="font-bold ml-1">{assetName}</span>
      </div>
    </div>
  );
};

export const Type11 = (notification, user, dispatch, navigate) => {
  const {
    companyName,
    stateChangerName,
    stateChangerId,
    stateChangerImage = "",
    bugId,
    bugName,
    assetId,
    assetName,
    isSuggestion = false,
    whatChanged,
    prevState,
    newState,
  } = notification?.content;

  const handleClick = () => {
    // these are states needed for that page

    dispatch(targetDashboardSliceActions.setCurrentTarget({ id: assetId }));
    dispatch(addVulnerabilityReportSliceActions.setId(bugId));

    const reportType =
      user.role == "S_MANAGER"
        ? "update"
        : user.role == "S_ENGINEER"
        ? "edit"
        : "";

    dispatch(addVulnerabilityReportSliceActions.setReportViewType(reportType));

    navigate("/bugReport", { state: { fromApp: true } });
  };

  return (
    <div className=" flex items-center cursor-pointer" onClick={handleClick}>
      <Avatar
        src={stateChangerImage || ".png"}
        alt={stateChangerName}
        className="mr-2 w-10"
      />

      <div className="flex flex-wrap items-center ">
        <span className="mx-1">{stateChangerName || "Manager"} </span>{" "}
        {companyName && (
          <span
            style={{
              background: "#d9d9d9",
              borderRadius: "40px",
              border: "1px solid #303E55",
              padding: "4px 10px",
              color: "#303E55",
              fontWeight: "700",
            }}
            className="mr-1"
          >
            {companyName}{" "}
          </span>
        )}
        <span className="font-bold ">
          {" "}
          <ColorDot type={newState} width={3} /> {newState}
        </span>{" "}
        {bugId && (
          <span
            style={{
              color: "white",
              background: "#539486",
              borderRadius: "5px",
              padding: "4px 8px",
            }}
            className="mx-1 my-[1px]"
          >
            #{bugId}
          </span>
        )}
        <Link>{bugName}</Link>{" "}
        <span className="font-bold ml-1">{assetName}</span>
      </div>
    </div>
  );
};
export const Type15 = (notification, dispatch, navigate) => {
  const {
    companyName,
    creatorName,
    creatorId,
    creatorImage = "",
    vulnId,
    vulnName,
    vulnSeverity,
    vulnStatus,
    assetId,
    assetName,
  } = notification?.content;

  const handleClick = () => {
    // these are states needed for that page

    dispatch(targetDashboardSliceActions.setCurrentVulnerability(vulnId));

    navigate("/vulnerabilityReport", { state: { fromApp: true } });
  };

  return (
    <div className=" flex items-center cursor-pointer" onClick={handleClick}>
      <Avatar
        src={creatorImage || ".png"}
        alt={creatorName}
        className="mr-2 w-10"
      />

      <div className="flex flex-wrap items-center ">
        <span className="mx-1">{creatorName || "Security Engineer"} </span>{" "}
        {companyName && (
          <span
            style={{
              background: "#d9d9d9",
              borderRadius: "40px",
              border: "1px solid #303E55",
              padding: "4px 10px",
              color: "#303E55",
              fontWeight: "700",
            }}
            className="mr-1"
          >
            {companyName}{" "}
          </span>
        )}
        <span className="mr-1 font-bold">submitted</span>
        <span className="font-bold mr-1">{StatusButton(vulnStatus)}</span>{" "}
        vulnerability{" "}
        {vulnId && (
          <span
            style={{
              color: "white",
              background: "#539486",
              borderRadius: "5px",
              padding: "4px 8px",
            }}
            className="ml-1 my-[1px]"
          >
            #{vulnId}
          </span>
        )}
        <Link className="mx-1">{vulnName}</Link>{" "}
        <span className="font-bold ">{assetName}</span>
      </div>
    </div>
  );
};
export const Type14 = (notification, navigate, dispatch, location) => {
  const {
    companyName,
    commentorName,
    commentorId,
    commentorImage = "",
    vulnId,
    vulnName,
    assetId,
    assetName,
    isChat,
  } = notification?.content;

  const handleClick = () => {
    // these are states needed for that page

    if (isChat) {
      dispatch(targetDashboardSliceActions.setCurrentTarget({ id: assetId }));
      dispatch(targetDashboardSliceActions.setCurrentSection(1));

      navigate("/target-dashboard", {
        state: { fromApp: true, vulnId: vulnId, openChat: true },
      });
    } else {
      dispatch(targetDashboardSliceActions.setCurrentVulnerability(vulnId));
      navigate("/vulnerabilityReport", { state: { fromApp: true } });
    }
  };

  return (
    <div className=" flex items-center cursor-pointer " onClick={handleClick}>
      <Avatar
        src={commentorImage || ".png"}
        alt={commentorName}
        className="mr-2 w-10"
      />

      <div className="flex flex-wrap items-center ">
        <span className="mx-1">{commentorName || "Someone"} </span>{" "}
        {companyName && (
          <span
            style={{
              background: "#d9d9d9",
              borderRadius: "40px",
              border: "1px solid #303E55",
              padding: "4px 10px",
              color: "#303E55",
              fontWeight: "700",
            }}
            className="mr-1"
          >
            {companyName}{" "}
          </span>
        )}
        <span className="font-bold mx-1">
          {isChat ? "sent a message " : "commented "}
        </span>{" "}
        on vulnerability{" "}
        {vulnId && (
          <span
            style={{
              color: "white",
              background: "#539486",
              borderRadius: "5px",
              padding: "4px 8px",
            }}
            className="mx-1 my-[1px]"
          >
            #{vulnId}
          </span>
        )}
        <Link>{vulnName}</Link>{" "}
        <span className="font-bold ml-1">{assetName}</span>
      </div>
    </div>
  );
};
export const Type12 = (notification, user, navigate, dispatch) => {
  const {
    companyName,
    stateChangerName,
    stateChangerId,
    stateChangerImage = "",
    vulnId,
    vulnName,
    assetId,
    assetName,
    isSuggestion,
    whatChanged,
    prevState,
    newState,
    accepted = false,
    resolvedStatus = "pending",
  } = notification?.content;

  const notificationId = notification?._id;

  if (!isSuggestion) return null;

  const handleClick = () => {
    dispatch(targetDashboardSliceActions.setCurrentVulnerability(vulnId));
    navigate("/vulnerabilityReport", { state: { fromApp: true } });
  };

  const handleAcceptClick = async () => {
    const res = await postFetch(
      "/vulnerability/acceptStatusChangeFromNotification",
      {
        notification_id: notificationId,
      }
    );
    if (res?.status == "ok") {
      toast.success("Status changed successfully");
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else {
      toast.error("Something went wrong, try later");
    }
  };
  const handleRejectClick = async () => {
    const res = await postFetch(
      "/vulnerability/rejectStatusChangeFromNotification",
      {
        notification_id: notificationId,
      }
    );
    if (res?.status == "ok") {
      toast.success("Status change declined successfully");
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else {
      toast.error("Something went wrong, try later");
    }
  };

  return (
    <>
      <div className=" flex items-center  cursor-pointer" onClick={handleClick}>
        <Avatar
          src={stateChangerImage || ".png"}
          alt={stateChangerName}
          className="mr-2 w-10"
        />

        <div className="flex flex-wrap items-center ">
          <span className="mx-1">{stateChangerName || "Someone"} </span>{" "}
          {companyName && (
            <span
              style={{
                background: "#d9d9d9",
                borderRadius: "40px",
                border: "1px solid #303E55",
                padding: "4px 10px",
                color: "#303E55",
                fontWeight: "700",
              }}
              className="mr-1"
            >
              {companyName}{" "}
            </span>
          )}
          <span className="font-bold mr-1">suggested</span>
          change of state of vulnerability{" "}
          {vulnId && (
            <span
              style={{
                color: "white",
                background: "#539486",
                borderRadius: "5px",
                padding: "4px 8px",
              }}
              className="mx-1 my-[1px]"
            >
              #{vulnId}
            </span>
          )}
          <Link>{vulnName}</Link>{" "}
          <span className="font-bold mx-1">{assetName}</span>
          from <span className="font-bold mx-1">
            {StatusButton(prevState)}
          </span>{" "}
          to <span className="font-bold mx-1">{StatusButton(newState)}</span>
        </div>
      </div>
      {user?.role == "MANAGER" && resolvedStatus == "pending" && (
        <div className="flex mt-2 ml-12">
          <div
            onClick={handleRejectClick}
            className="w-40 flex items-center  cursor-pointer justify-center text-sm font-bold text-[#202020] h-12 bg-[#E0E2E5] bg-opacity-100 rounded mr-2p"
          >
            Deny
          </div>
          <div
            onClick={handleAcceptClick}
            className={
              blueGradient1 +
              " w-40 flex items-center cursor-pointer justify-center text-sm font-bold text-white h-12  rounded "
            }
          >
            Accept
          </div>
        </div>
      )}
      {user.role == "MANAGer" && resolvedStatus != "pending" && (
        <div
          className={
            resolvedStatus == "rejected"
              ? "font-bold ml-12 mt-2 text-critical"
              : resolvedStatus == "accepted"
              ? " font-bold ml-12 mt-2 text-low"
              : "font-bold ml-12 mt-2 text-gray-500"
          }
        >
          {resolvedStatus}
        </div>
      )}
    </>
  );
};
export const Type13 = (notification, navigate, dispatch) => {
  const {
    companyName,
    stateChangerName,
    stateChangerId,
    stateChangerImage = "",
    vulnId,
    vulnName,
    assetId,
    assetName,
    isSuggestion,
    whatChanged,
    prevState,
    newState,
  } = notification?.content;

  if (isSuggestion) return null;

  const handleClick = () => {
    dispatch(targetDashboardSliceActions.setCurrentVulnerability(vulnId));
    navigate("/vulnerabilityReport", { state: { fromApp: true } });
  };

  return (
    <>
      <div className=" flex items-center cursor-pointer" onClick={handleClick}>
        <Avatar
          src={stateChangerImage || ".png"}
          alt={stateChangerName}
          className="mr-2 w-10"
        />

        <div className="flex flex-wrap items-center ">
          <span className="mx-1">{stateChangerName || "Someone"} </span>{" "}
          {companyName && (
            <span
              style={{
                background: "#d9d9d9",
                borderRadius: "40px",
                border: "1px solid #303E55",
                padding: "4px 10px",
                color: "#303E55",
                fontWeight: "700",
              }}
              className="mr-1"
            >
              {companyName}{" "}
            </span>
          )}
          changed the of state of vulnerability{" "}
          {vulnId && (
            <span
              style={{
                color: "white",
                background: "#539486",
                borderRadius: "5px",
                padding: "4px 8px",
              }}
              className="ml-1 my-[1px]"
            >
              #{vulnId}
            </span>
          )}
          <Link className="mx-1">{vulnName}</Link>{" "}
          <span className="font-bold mx-1">{assetName}</span>
          from <span className="font-bold mx-1">
            {StatusButton(prevState)}
          </span>{" "}
          to <span className="font-bold mx-1">{StatusButton(newState)}</span>
        </div>
      </div>
    </>
  );
};

export const Type16 = (notification, user, navigate, dispatch) => {
  let {
    invitorName,
    invitorCompany,
    invitorImage,
    assetId,
    assetName,
    inviteMessage,
    inviteId,
    status,
    accessAllowed,
  } = notification?.content;

  const handleClick = () => {
    navigate("/invite");
  };
  const handleAcceptClick = async () => {
    const res = await postFetch("/companyInvite/acceptInvite", {
      invite_id: inviteId,
    });
    if (res?.status == "ok") {
      toast.success("Invite accepted successfully");
    } else if (res?.error && res?.message) {
      toast.error(res.message);
    } else {
      toast.error("Something went wrong, try later");
    }
  };
  const handleDenyClick = async () => {
    const res = await postFetch("/companyInvite/rejectInvite", {
      invite_id: inviteId,
    });
    if (res?.status == "ok") {
      toast.success("Invite rejected successfully");
    } else if (res?.error && res?.message) {
      toast.error(res.message);
    } else {
      toast.error("Something went wrong, try later");
    }
  };

  return (
    <div>
      <div className=" flex items-center cursor-pointer" onClick={handleClick}>
        <Avatar
          src={invitorImage || ".png"}
          alt={invitorName}
          className="mr-2 w-10"
        />

        <div className="flex flex-wrap items-center ">
          <span className="mx-1">{invitorName || "Someone"} </span>{" "}
          {invitorCompany && (
            <span
              style={{
                background: "#d9d9d9",
                borderRadius: "40px",
                border: "1px solid #303E55",
                padding: "4px 10px",
                color: "#303E55",
                fontWeight: "700",
              }}
              className="mr-1"
            >
              {invitorCompany}{" "}
            </span>
          )}
          <span className="font-bold mr-1">invited</span>
          you to pentest <span className="font-bold mx-1">{assetName}</span>
        </div>
      </div>
      {inviteMessage && (
        <div className="flex mt-1 ml-12 italic whitespace-normal">
          {inviteMessage?.slice?.(0, 150)}
        </div>
      )}
      {status == "pending" && user?.role == "S_MANAGER" && (
        <div className="flex mt-2 ml-12">
          <div
            onClick={handleDenyClick}
            className="w-40 cursor-pointer flex items-center  justify-center text-sm font-bold text-[#202020] h-12 bg-[#E0E2E5] bg-opacity-100 rounded mr-2p"
          >
            Deny
          </div>
          <div
            onClick={handleAcceptClick}
            className={
              blueGradient1 +
              " w-40 flex items-center cursor-pointer justify-center text-sm font-bold text-white h-12  rounded "
            }
          >
            Accept
          </div>
        </div>
      )}
      {status != "pending" && user?.role == "S_MANAGER" && (
        <div
          className={
            status == "rejected"
              ? "font-bold ml-12 mt-2 text-critical"
              : " font-bold ml-12 mt-2 text-low"
          }
        >
          {status == "accepted" ? "Invite accepted" : "Invite rejected"}
        </div>
      )}
    </div>
  );
};

export const Type17 = (notification, navigate) => {
  const {
    invitedManagerName,
    invitedManagerCompany,
    invitedManagerImage,
    assetId,
    assetName,
    status,
    accessAllowed,
    inviteId,
  } = notification.content;

  const handleClick = () => {
    navigate("/invite");
  };

  return (
    <div className=" flex items-center cursor-pointer" onClick={handleClick}>
      <Avatar
        src={invitedManagerImage || ".png"}
        alt={invitedManagerName}
        className="mr-2 w-10"
      />
      <div>
        <span>{invitedManagerName || "Security Manager"} </span>{" "}
        <span
          style={{
            background: "#d9d9d9",
            borderRadius: "40px",
            border: "1px solid #303E55",
            padding: "4px 10px",
            color: "#303E55",
            fontWeight: "700",
          }}
        >
          {invitedManagerCompany}
        </span>{" "}
        <span
          className={
            status == "rejected"
              ? "font-bold ml-1 text-critical"
              : " font-bold ml-1 text-low"
          }
        >
          {status}
        </span>{" "}
        <span className="font-bold mx-1"> your invitation </span> for pentesting{" "}
        <span className="font-bold ml-1 "> {assetName}</span>
      </div>
    </div>
  );
};

// this notification is for when a manager revokes access of a researcher
export const Type18 = (notification, user, navigate) => {
  const {
    invitorManagerName,
    invitorManagerCompany,
    invitorManagerImage,
    assetId,
    assetName,
    inviteId,
    status,
    accessAllowed,
  } = notification.content;

  const handleClick = () => {
    navigate("/invite");
  };

  return (
    <>
      {user?.role == "S_MANAGER" && (
        <div
          className=" flex items-center cursor-pointer"
          onClick={handleClick}
        >
          <Avatar
            src={invitorManagerImage || ".png"}
            alt={invitorManagerName}
            className="mr-2 w-10"
          />

          <div>
            <span className="mx-1">
              {invitorManagerName || "Company Manager"}{" "}
            </span>{" "}
            <span
              style={{
                background: "#d9d9d9",
                borderRadius: "40px",
                border: "1px solid #303E55",
                padding: "4px 10px",
                color: "#303E55",
                fontWeight: "700",
              }}
            >
              {invitorManagerCompany}
            </span>{" "}
            <span
              className={
                accessAllowed
                  ? " font-bold ml-1 text-low"
                  : "font-bold ml-1 text-critical"
              }
            >
              {accessAllowed ? "granted" : "revoked"} access
            </span>{" "}
            of
            <span className="font-bold ml-1 "> {assetName || "an asset"}</span>
          </div>
        </div>
      )}
      {user?.role == "MANAGER" && (
        <div className="flex items-center cursor-pointer" onClick={handleClick}>
          <img
            src={accessAllowed ? "/restore.svg" : "/lock.svg"}
            alt=""
            className=" w-5 mr-2"
          />
          <span>
            You {accessAllowed ? "restored asset " : "revoked access of "}{" "}
            <span className="font-bold"> {assetName}</span>
          </span>
        </div>
      )}
    </>
  );
};
