import React from "react";
import { MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { timeSince } from "Util/TimeSince";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationFormatComponent } from "features/Profile";
import NotificationLoadingSkeleton from "components/ui/loaders/NotificationSkeleton";

export default function Active() {
  let activities = useSelector(
    (state) => state.targetDashboard.home.activityFeed
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const isLoading = useSelector(
    (state) => state.targetDashboard.home.isLoading
  );
  const location = useLocation();
  const isDarkMode = useSelector((state) => state.general.theme) == "dark";

  activities = activities
    ?.map((notification) => {
      return {
        data: notification,
        component: NotificationFormatComponent(
          notification,
          user,
          navigate,
          dispatch,
          location,
          isDarkMode
        ),
      };
    })
    .filter((n) => n != null);

  if (!isLoading && activities?.length == 0) {
    return (
      <div
        className="p-4 bg-[rgba(244, 248, 255, 0.79)] dark:bg-lightBlack dark:text-white italic"
        style={{ fontSize: "16px" }}
      >
        No recent activity
      </div>
    );
  }

  return (
    <div className="">
      {isLoading && activities?.length == 0 ? (
        <NotificationLoadingSkeleton />
      ) : (
        activities?.map?.((n, i) => (
          <MenuItem
            key={i}
            sx={{
              paddingY: "10px",
              fontSize: "14px",
              position: "relative",
              paddingX: "0",
              cursor: "default",
            }}
            className="  dark:bg-lightBlack dark:text-white dark:hover:bg-lightBlack2 border-b border-[#E0E0E0] dark:border-b9"
          >
            <div className="mx-4">
              <div className="py-3 mr-10">{n?.component}</div>
              <div className="absolute right-2 bottom-1 text-xs font-bold opacity-50 leading-[14.5px]">
                {timeSince(n?.data?.createdDate)} ago
              </div>
            </div>
          </MenuItem>
        ))
      )}
    </div>
  );
}
