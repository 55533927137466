import React from "react";
import { LinkComponent } from "features/login/index";

function Link2(props) {
  return (
    <LinkComponent
      heading={"You’re all set!"}
      content={"Now you are directed to the login portal !"}
    />
  );
}

export default Link2;
