import { createSlice } from "@reduxjs/toolkit";

const bugTrackerSwitchSlice = createSlice({
  name: "bugTrackerSwitch",
  initialState: {
    checked: false,
  },
  reducers: {
    toggleSwitch: (state, action) => {
      state.checked = action.payload;
    },
  },
});

export const { toggleSwitch } = bugTrackerSwitchSlice.actions;

export default bugTrackerSwitchSlice;
