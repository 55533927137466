import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    id: null,
    name: null,
    email: null,
    profileImage: null,
    cname: null,
    role: null,
    roleId: null,
    isAuth: false,
    isSecProvider: null,
    companyName: null,
    phoneNumber: null,
    twoFaEnabled: null,
  },
};

let userSlice = createSlice({
  name: "userSlice",
  initialState: initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setId(state, action) {
      state.id = action.payload;
    },

    setName(state, action) {
      state.data.name = action.payload;
    },
    setEmail(state, action) {
      state.data.email = action.payload;
    },
    setRole(state, action) {
      state.data.role = action.payload;
    },
    setIsAuth(state, action) {
      state.data.isAuth = action.payload;
    },
    setProfileImage(state, action) {
      state.data.profileImage = action.payload;
    },
    setTWoFaEnabled(state, action) {
      state.data.twoFaEnabled = action.payload;
    },
  },
});

export const userSliceActions = userSlice.actions;

export default userSlice;
