import React, { useState } from "react";
import "components/style.css";

import { useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PrimaryButton from "components/ui/buttons/SecondaryButton";
import { Link } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";
import { postFetch } from "lib/fetch";
import { blueGradient1 } from "components/tailwindClasses";

// credentials  : {email : "aviralsrivastava@mailnesia.com", pass : "aviralsrivastava"}
//  {email : "akhilraj@mailnesia.com", pass : "akhil"}

function SignUp() {
  const [searchParams] = useSearchParams();

  const urlToken = searchParams.get("token");
  const urlEmail = searchParams.get("email");

  let navigate = useNavigate();

  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [password2, setPassword2] = useState("");
  let [number, setNumber] = useState("");
  let [name, setName] = useState("");

  let capchaRef = useRef(null);

  let signUpCall = async () => {
    if (!urlEmail || !urlToken) {
      toast.error("invalid request");
      return;
    }

    let requestData = {
      email: urlEmail,
      token: urlToken,
      password,
      password2,
      name,
      phoneNumber: number,
    };

    const response = await postFetch("/validatePendingUser", requestData);

    if (response?.status == "ok") {
      window.localStorage.setItem("email", `${urlEmail}`);
      toast.success("signed up successfully");
      navigate("/login");
    } else if (response?.error && response?.message) {
      toast.error(response.message);
    } else {
      toast.error("some error occured, try later");
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!capchaRef.current?.getValue()?.length > 0) {
      toast.warn("Please verify captcha");
      return;
    }

    try {
      signUpCall();
    } catch (e) {
      console.log(e);
      toast.error("Error ! Try Later");
    }
  };

  const handleCaptchaChange = () => {
    // toast(capchaRef.current?.getValue());
  };

  return (
    <div className="flex h-full overflow-hidden">
      <div className={"relative w-[58%]  h-full text-white " + blueGradient1}>
        <div className="absolute top-1/2 left-[45%] -translate-x-1/2 -translate-y-1/2">
          <div className="text-4xl w-fit mb-2">
            <b>Engineer Portal</b>
            <div className="text-2xl text-[#c3d2d2] w-fit ">
              <p>Welcome to the team!</p>
            </div>
          </div>
        </div>
        <img
          src="/engineerLoginLogo.svg"
          alt=""
          className="absolute right-0 bottom-0 mb-2p mr-3p w-2/5"
        />
        <div>
          <img src={"/loginBgLines.svg"} className="anim" alt="anim" />
        </div>
      </div>

      <div className="relative w-[42%] bg-[#ececec] h-full flex flex-col justify-center items-center">
        <div className="absolute top-[0%] left-[5%]">
          <div className="text-xl font-medium">
            <img
              src={"cyteHackLogo.svg"}
              className="logo h-10 inline"
              alt="anim"
            />
            &nbsp; CyeNET
          </div>
        </div>
        <div className="flex justify-center items-center w-full p-10">
          <form onSubmit={handleFormSubmit} id="loginForm" className="w-full">
            <h1 className="mb-2 text-center text-xl">
              <b>Sign Up</b>
            </h1>
            <label htmlFor="name">
              <p className="font-medium text-base">Name</p>
            </label>
            <input
              value={name}
              onChange={(e) => {
                setName(() => e.target.value);
              }}
              type="text"
              className="text-sm  w-full p-3 h-10 bg-inputBg border-b2 border rounded-10"
              required
            />
            <label htmlFor="contact">
              <p className="font-medium text-base">Contact number</p>
            </label>
            <input
              value={number}
              onChange={(e) => {
                setNumber(() => e.target.value);
              }}
              placeholder="XXXX XXXX XX"
              type="tel"
              className="text-sm  w-full p-3 h-10 bg-inputBg border-b2 border rounded-10"
              pattern="^[1-9][0-9]{9}$"
              required
            />
            <label htmlFor="email">
              <p className="font-medium text-base">Recovery Email (Optional)</p>
            </label>
            <input
              value={email}
              onChange={(e) => {
                setEmail(() => e.target.value);
              }}
              type="email"
              className="text-sm  w-full p-3 h-10 bg-inputBg border-b2 border rounded-10"
            />
            <label htmlFor="pass">
              <p className="font-medium text-base">Create Password</p>
            </label>
            <input
              value={password}
              onChange={(e) => {
                setPassword(() => e.target.value);
              }}
              type="password"
              className="text-sm  w-full p-3 h-10 bg-inputBg border-b2 border rounded-10"
              required
              pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$" // Add the regex pattern here
            />
            <div className="text-xs">
              Include all of the following:
              <ul class="list-disc">
                <li>o An uppercase character</li>
                <li>o A lowercase character</li>
                <li>o A number</li>
                <li>o A special character</li>
              </ul>
            </div>
            <label htmlFor="pass">
              <p className="font-medium text-base">Confirm Password</p>
            </label>
            <input
              value={password2}
              onChange={(e) => {
                setPassword2(() => e.target.value);
              }}
              type="password"
              className="text-sm  w-full p-3 h-10 bg-inputBg border-b2 border rounded-10"
              required
              pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$" // Add the regex pattern here
            />

            {/* for capthca */}
            <div className="mt-1p mb-2p">
              <ReCAPTCHA
                ref={capchaRef}
                sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY || null}
                onChange={handleCaptchaChange}
              />
            </div>

            <button
              type="submit"
              className={blueGradient1 + " w-full h-12 rounded-10 font-bold"}
            >
              Sign Up
            </button>

            <p className="text-sm mt-1">
              Already have an account?{" "}
              <Link to={"/login"} className="underline text-black font-bold">
                Log In
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
