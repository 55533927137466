import { Avatar, AvatarGroup } from "@mui/material";
import React from "react";
import Popup from "reactjs-popup";

export function UserImage({
  name = null,
  email = null,
  image = null,
  company = null,
  style = null,
  position = null,
}) {
  if (!name && !email && !image && !company) return null;

  return (
    <Popup
      keepTooltipInside={!position && "#root"}
      trigger={(open) => (
        <Avatar alt={name} src={image || ".jpg"} style={style} />
      )}
      on={["hover", "focus"]}
      closeOnDocumentClick
      arrow={true}
      position={position ? position : []}
    >
      <div
        style={{
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
          borderRadius: "5px",
          padding: "20px",
        }}
        className="bg-[#F5F5F5] dark:bg-lightBlack3 dark:text-white"
      >
        {name && (
          <div style={{ fontWeight: "600", fontSize: "20px" }}>{name}</div>
        )}
        {email && (
          <div style={{ fontSize: "18px", marginBottom: "10px" }}>{email}</div>
        )}
        {company && (
          <div
            style={{
              fontWeight: "600",
              fontSize: "20px",
              marginBottom: "5px",
            }}
          >
            {company}
          </div>
        )}
      </div>
    </Popup>
  );
}

export function MultipleUserImage({ team = [], position, max, style }) {
  // team = [{
  //   name, email,
  //   companyName, profileImage;
  // }]

  if (team?.length == 1) {
    return (
      <UserImage
        company={team[0]?.companyName}
        email={team[0]?.email}
        name={team[0]?.name}
        image={team[0]?.profileImage}
      />
    );
  }

  return (
    <div className="flex items-center justify-start">
      <Popup
        keepTooltipInside="#root"
        trigger={
          <span style={{ marginRight: "1%" }}>
            <AvatarGroup max={max ? max : 3} className="flex items-center">
              {team.map((member) => (
                <Avatar
                  alt={member?.name}
                  src={member?.profileImage || ".png"}
                  style={style}
                />
              ))}
            </AvatarGroup>
          </span>
        }
        on="hover"
        position={position ? position : "top center"}
        nested
      >
        <div
          style={{
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
          }}
          className="bg-white dark:bg-lightBlack4  flex p-3 rounded"
        >
          {team?.map((member) => {
            return (
              <div className="mr-1">
                <UserImage
                  name={member?.name}
                  email={member?.email}
                  company={member?.companyName}
                  image={member?.profileImage}
                  style={style}
                />
              </div>
            );
          })}
        </div>
      </Popup>
    </div>
  );
}
