export const riskScoreColor = (risk = null) => {
  if (risk == null) {
    return ""; // Default color if the risk is not available
  }

  risk = Math.max(0, risk); // Make sure the risk is not negative
  risk = Math.min(9, risk); // Make sure the risk is not greater than 9

  const floorValue = Math.floor(risk);

  // Define the color values based on the provided pattern
  const colors = [
    "rgb(0, 255, 0)",
    "rgb(57, 255, 0)",
    "rgb(113, 255, 0)",
    "rgb(170, 255, 0)",
    "rgb(227, 255, 0)",
    "rgb(255, 227, 0)",
    "rgb(255, 170, 0)",
    "rgb(255, 113, 0)",
    "rgb(255, 57, 0)",
    "rgb(255, 0, 0)",
  ];

  return colors[floorValue];
};
