import { createSlice } from "@reduxjs/toolkit";

// this is actaullt bug report not vulnerabily report

const initialState = {
  // this is for how page will be seen by different users
  reportViewType: "", //  types : "create",->for S_ENGINEER "edit",->S_ENGINEER "update"->S_AMM, "draft"

  id: "",
  name: "",
  type: "",
  user_id: "",
  severity: "",
  url: "",
  parent_asset: "",
  cwe: "",
  cvss: "",
  cve: "",
  description: "",
  lastModifiedByUser: "",
  cvss_vector: "",
  vulnerable_parameter: "",
  summary: "",
  impact: "",
  remediation: "",
  references: "",
  filesAttached: [], // it will have objects { blob, url }

  status: "",
  researcher: { name: "", email: "", profileImage: "", company: "" },
  createdOn: "",

  comments: {
    weakness: [], // comment, id , createdOn
    severity: [],
    parameter: [],
    poc: [],
  },

  // more data
  parent: { name: "", id: "" },
  child1: { name: "", id: "" },
  child2: { name: "", id: "" },

  //

  cvssCalculator: {
    data: { A: "", AC: "", AV: "", C: "", I: "", PR: "", S: "", UI: "" },
    mainController: "",
  },
};

let addVulnerabilityReportSlice = createSlice({
  name: "addVulnerabilityReportSlice",
  initialState: initialState,
  reducers: {
    setName(state, action) {
      state.name = action.payload;
    },
    setId(state, action) {
      state.id = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    setUserId(state, action) {
      state.user_id = action.payload;
    },
    setSeverity(state, action) {
      state.severity = action.payload;
    },
    setUrl(state, action) {
      state.url = action.payload;
    },
    setParentAsset(state, action) {
      state.parent_asset = action.payload;
    },
    setCwe(state, action) {
      state.cwe = action.payload;
    },
    setCve(state, action) {
      state.cve = action.payload;
    },
    setCvss(state, action) {
      state.cvss = action.payload;
    },
    setDescription(state, action) {
      state.description = action.payload;
    },
    setLastModifiedByUser(state, action) {
      state.lastModifiedByUser = action.payload;
    },
    setCvssVector(state, action) {
      state.cvss_vector = action.payload;
    },
    setVulnerableParameter(state, action) {
      state.vulnerable_parameter = action.payload;
    },
    setSummary(state, action) {
      state.summary = action.payload;
    },
    setImpact(state, action) {
      state.impact = action.payload;
    },
    setRemediation(state, action) {
      state.remediation = action.payload;
    },
    setReferences(state, action) {
      state.references = action.payload;
    },
    setFilesAttached(state, action) {
      state.filesAttached = action.payload;
    },
    setParent(state, action) {
      state.parent = action.payload;
    },
    setChild1(state, action) {
      state.child1 = action.payload;
    },
    setChild2(state, action) {
      state.child2 = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    setResearcher(state, action) {
      state.researcher = action.payload;
    },
    setCreatedOn(state, action) {
      state.createdOn = action.payload;
    },

    resetComments(state, action) {
      state.comments = initialState.comments;
    },
    setWeaknessComment(state, action) {
      // i will send an empty comment to show delete comment

      if (action.payload.id == undefined) return;

      let eleFound = false;

      /// this is to check if comment already exists
      let toSet = state.comments.weakness.map((com) => {
        if (String(com.id) == String(action.payload.id)) {
          eleFound = true;
          return {
            ...com,
            ...action.payload,
          };
        }
        return com;
      });

      if (eleFound) {
        if (action.payload.comment == "") {
          // this is for deleting
          toSet = toSet.filter((com) => {
            return String(com.id) != String(action.payload.id);
          });
        }
        // this is to edit
        state.comments.weakness = toSet;
      } else {
        // this is for new comment
        state.comments.weakness = [...state.comments.weakness, action.payload];
      }
    },
    setPocComment(state, action) {
      let eleFound = false;

      let toSet = state.comments.poc.map((com) => {
        if (String(com.id) == String(action.payload.id)) {
          eleFound = true;
          return {
            ...com,
            ...action.payload,
          };
        }
        return com;
      });

      if (eleFound) {
        if (action.payload.comment == "") {
          // this is for deleting
          toSet = toSet.filter((com) => {
            return String(com.id) != String(action.payload.id);
          });
        }
        // this is to edit
        state.comments.poc = toSet;
      } else {
        state.comments.poc = [...state.comments.poc, action.payload];
      }
    },
    setParameterComment(state, action) {
      let eleFound = false;

      let toSet = state.comments.parameter.map((com) => {
        if (String(com.id) == String(action.payload.id)) {
          eleFound = true;
          return {
            ...com,
            ...action.payload,
          };
        }
        return com;
      });

      if (eleFound) {
        if (action.payload.comment == "") {
          // this is for deleting
          toSet = toSet.filter((com) => {
            return String(com.id) != String(action.payload.id);
          });
        }
        // this is to edit
        state.comments.parameter = toSet;
      } else {
        state.comments.parameter = [
          ...state.comments.parameter,
          action.payload,
        ];
      }
    },
    setSeverityComment(state, action) {
      let eleFound = false;

      let toSet = state.comments.severity.map((com) => {
        if (String(com.id) == String(action.payload.id)) {
          eleFound = true;
          return {
            ...com,
            ...action.payload,
          };
        }
        return com;
      });

      if (eleFound) {
        if (action.payload.comment == "") {
          // this is for deleting
          toSet = toSet.filter((com) => {
            return String(com.id) != String(action.payload.id);
          });
        }
        // this is to edit
        state.comments.severity = toSet;
      } else {
        state.comments.severity = [...state.comments.severity, action.payload];
      }
    },

    setReportViewType(state, action) {
      state.reportViewType = action.payload;
    },

    setCvssObject(state, action) {
      state.cvssCalculator.data = action.payload;
    },
    setMainController(state, action) {
      state.cvssCalculator.mainController = action.payload;
    },

    reset(state, action) {
      // send empty string to reset

      return initialState;

      // state.name = initialState.name;
      // state.type = initialState.type;
      // state.severity = initialState.severity;
      // state.user_id = initialState.user_id;
      // state.url = initialState.url;
      // state.parent_asset = initialState.parent_asset;
      // state.cwe = initialState.cwe;
      // state.cvss = initialState.cvss;
      // state.description = initialState.description;
      // state.lastModifiedByUser = initialState.lastModifiedByUser;
      // state.cvss_vector = initialState.cvss_vector;
      // state.vulnerable_parameter = initialState.vulnerable_parameter;
      // state.summary = initialState.summary;
      // state.impact = initialState.impact;
      // state.remediation = initialState.remediation;
      // state.references = initialState.references;
      // state.filesAttached = initialState.filesAttached;
      // state.parent = initialState.parent;
      // state.child1 = initialState.child1;
      // state.child2 = initialState.child2;
      // state.status = initialState.status;
      // state.researcher = initialState.researcher;
      // state.createdOn = initialState.createdOn;
      // state.comments = initialState.comments;
    },
  },
});

export const addVulnerabilityReportSliceActions =
  addVulnerabilityReportSlice.actions;

export default addVulnerabilityReportSlice;
