import React, { useState } from "react";
import { Box, MenuItem, Slider } from "@mui/material";
import { Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addReportSliceActions } from "store/addReportSlice";
import { useEffect } from "react";
import { addScanEngineSliceActions } from "store/scanner/addScanEngineSlice";
import { input1 } from "components/tailwindClasses";

export const crawlScopes = [
  {
    id: 1,
    label: "Limit at or below URL hostname(url)",
  },
  {
    id: 2,
    label: "Limit to content located at or below URL subdirectory",
  },
  {
    id: 3,
    label: "Limit to URL hostname and specified sub-domain",
  },
  {
    id: 4,
    label: "Limit to URL hostname and specified domains",
  },
];
export const robotsFiles = [
  { id: 1, label: "Do not use robots.txt" },
  {
    id: 2,
    label: "Crawl all links and directories found in robots.txt, if present",
  },
  {
    id: 3,
    label:
      "Do not crawl links or directories excluded by robots.txt, if present",
  },
];

export const sitemapFiles = [
  { id: 1, label: "Do not use sitemap.xml" },
  {
    id: 2,
    label: "Crawl all links and directories found in sitemap.xml, if present",
  },
];

export const depths = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
];
export const maxLinksValues = [
  { value: 3, label: "3" },
  { value: 6, label: "6" },
  { value: 9, label: "9" },
  { value: 13, label: "13" },
  { value: 17, label: "17" },
  { value: 20, label: "20" },
];

function Index(props) {
  const dispatch = useDispatch();

  const scope = useSelector(
    (state) => state.scanner.addScanEngine.step2.crawlScope
  );
  const depth = useSelector(
    (state) => state.scanner.addScanEngine.step2.crawlDepth
  );
  const maxLinks = useSelector(
    (state) => state.scanner.addScanEngine.step2.maxLinksToCrawl
  );
  const robotsFile = useSelector(
    (state) => state.scanner.addScanEngine.step2.robotsFile
  );
  const sitemapFile = useSelector(
    (state) => state.scanner.addScanEngine.step2.sitemapFile
  );

  useEffect(() => {
    !depth &&
      dispatch(addScanEngineSliceActions.setCrawlDepth(depths[0].value));
    !maxLinks &&
      dispatch(
        addScanEngineSliceActions.setMaxLinksToCrawl(maxLinksValues[0].value)
      );
  }, []);

  return (
    <form id="step2-form">
      <div
        style={{
          padding: "2%",
          paddingBottom: "3%",
          borderRadius: "10px",
          marginBottom: "1%",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        <div className="avatar-text ">Crawl Scope</div>

        <div>
          <Select
            sx={{
              width: "100%",
              boxSizing: "border-box",
              height: "45px",
              backgroundColor: "#D9D9D940",
              border: "0px solid rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
            }}
            className="dark:text-white bg-inputBg dark:bg-inputBgDark"
            required
            value={scope}
            onChange={(e) => {
              dispatch(addScanEngineSliceActions.setCrawlScope(e.target.value));
            }}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            MenuProps={{
              PaperProps: { sx: { maxHeight: 400 } },
            }}
          >
            <MenuItem value="">
              <em>Select </em>
            </MenuItem>

            {crawlScopes?.map((cs, i) => {
              return <MenuItem value={cs.id}>{cs.label}</MenuItem>;
            })}
          </Select>
        </div>

        <div className="avatar-text mt-3p">Crawl Depth</div>

        <Box sx={{ width: "60%" }} className="mt-1p ml-4">
          <Slider
            getAriaValueText={(v) => v}
            step={null}
            marks={depths}
            min={depths[0].value}
            max={depths[depths.length - 1].value}
            value={depth}
            onChange={(e) => {
              dispatch(addScanEngineSliceActions.setCrawlDepth(e.target.value));
            }}
          />
        </Box>
        <div className="avatar-text mt-1p">Max links to crawl</div>

        <Box sx={{ width: "60%" }} className="mt-1p ml-4">
          <Slider
            getAriaValueText={(v) => v}
            step={null}
            marks={maxLinksValues}
            min={maxLinksValues[0].value}
            max={maxLinksValues[depths.length - 1].value}
            value={maxLinks}
            onChange={(e) => {
              dispatch(
                addScanEngineSliceActions.setMaxLinksToCrawl(e.target.value)
              );
            }}
          />
        </Box>

        <div className="avatar-text mt-1p">robots.txt file</div>

        <div>
          <Select
            sx={{
              width: "100%",
              boxSizing: "border-box",
              height: "45px",
              backgroundColor: "#D9D9D940",
              border: "0px solid rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
            }}
            className="dark:text-white bg-inputBg dark:bg-inputBgDark"
            required
            value={robotsFile}
            onChange={(e) => {
              dispatch(addScanEngineSliceActions.setRobotsFile(e.target.value));
            }}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            MenuProps={{
              PaperProps: { sx: { maxHeight: 400 } },
            }}
          >
            <MenuItem value="">
              <em>Select </em>
            </MenuItem>
            {robotsFiles?.map((rf, i) => {
              return <MenuItem value={rf.id}>{rf.label}</MenuItem>;
            })}
          </Select>
        </div>
        <div className="avatar-text mt-1p">sitemap.xml file</div>

        <div>
          <Select
            sx={{
              width: "100%",
              boxSizing: "border-box",
              height: "45px",
              backgroundColor: "#D9D9D940",
              border: "0px solid rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
            }}
            className="dark:text-white bg-inputBg dark:bg-inputBgDark"
            required
            value={sitemapFile}
            onChange={(e) => {
              dispatch(
                addScanEngineSliceActions.setSitemapFile(e.target.value)
              );
            }}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            MenuProps={{
              PaperProps: { sx: { maxHeight: 400 } },
            }}
          >
            <MenuItem value="">
              <em>Select </em>
            </MenuItem>
            {sitemapFiles?.map((sf, i) => {
              return <MenuItem value={sf.id}>{sf.label}</MenuItem>;
            })}
          </Select>
        </div>
      </div>
    </form>
  );
}

export default Index;
