import React from "react";

import { Box } from "@mui/material";
import { CamelCase } from "Util/CamelCase";

export let colorMap = {
  Critical: "#CE3733",
  High: "#EC8830",
  Medium: "#FDBC2C",
  Low: "#37A440",
  None: "#6B7280",
};

let SeverityButton = (type) => {
  type = CamelCase(type);

  return (
    <>
      <div>
        <Box
          sx={{
            color: "#fff",
            backgroundColor: `${colorMap[type]}`,
            height: "27px",
            width: "90px",
            textAlign: "center",
            verticalAlign: "center",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "14px",
            fontWeight: "600",
          }}
          variant="contained"
        >
          <span>{type}</span>
        </Box>
      </div>
    </>
  );
};

export default SeverityButton;
