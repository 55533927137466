import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";

import Paper from "@mui/material/Paper";
import Active from "./Active";
import { Typography } from "@mui/material";
import Vulnerability from "./Vulnerability";
function createData(name) {
  return { name };
}

export default function ActiveVuln() {
  return (
    <div className=" bg-white dark:bg-lightBlack mt-1p overflow-hidden rounded">
      <div className="p-5  font-semibold dark:text-white bg-greyBg4 dark:bg-lightBlack3">
        Activity Feed
      </div>

      <div className=" overflow-y-scroll max-h-80 m-2">
        <Active />
      </div>
    </div>
  );
}
