import React, { useState } from "react";
import {
  MenuItem,
  Select,
  Avatar,
  TextField,
  InputAdornment,
} from "@mui/material";
import SecondaryButton from "components/ui/buttons/SecondaryButton";
import ColorDot from "components/ui/buttons/ColorDot";
import {
  FormControlLabel,
  FormControl,
  OutlinedInput,
  Checkbox,
  ListItemText,
  InputLabel,
} from "@mui/material";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function TableFilterSection({
  All,
  filters,
  setFilters,
  search,
  setSearch,
  options,
}) {
  // const [filters, setFilters] = useState([]);

  // console.log(filters);
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  const handleChangeFilter = (event) => {
    const {
      target: { value },
    } = event;
    setFilters(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "5px 1%",
      }}
      className=""
    >
      <div>
        {All && (
          <FormControl
            sx={{ minWidth: 120, height: 36 }}
            className="dark:bg-lightBlack3 dark:border dark:border-b9  "
          >
            <InputLabel
              className="dark:text-white"
              sx={{
                transform: "translate(12px, 8px) scale(1)",
                backgroundColor: "transparent",
              }}
            >
              {!filters?.length > 0 && "Filter"}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              className="h-[36px]"
              multiple
              value={filters}
              onChange={handleChangeFilter}
              input={<OutlinedInput label="Filter" />}
              renderValue={(selected) => {
                // return selected
                //   .map((item) => {
                //     console.log(item.props);
                //     return item.props.name;
                //   })
                //   .join(", ");
                return <div className="dark:text-white">Filters Used</div>;
              }}
              MenuProps={MenuProps}
            >
              {options.map((filter) => {
                const name = filter.name;
                return (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={filters.indexOf(name) > -1} />
                    <ListItemText primary={filter.component} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </div>
      <div className="bg-greyBg4 dark:bg-lightBlack3 dark:text-white dark:border-b9 border-b2 border px-2 rounded">
        <TextField
          id="input-with-icon-textfield"
          // label="Search Title"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src={isDarkTheme ? "/searchIconDark.svg" : "/searchIcon.svg"}
                  alt=""
                />
              </InputAdornment>
            ),
          }}
          variant="standard"
          placeholder="Search Title"
          value={search}
          onChange={(e) => {
            setSearch(() => e.target.value);
          }}
        />
      </div>
    </div>
  );
}

export default TableFilterSection;
