import React from "react";

import { Box } from "@mui/material";

export let colorMap = {
  // New: "#058AA6",
  // Triaged: "#E32273",
  // Informative: "#9F9B9B",
  // Resolve: "#B2C615",
  // Duplicate: "#EFD237",
  // Resubmit: "#EC8830",
  // Deleted: "#CE3733",
  // Retest: "#46F655",
  // NotApplicable: "#FF0700",
  // WontFix: "#FF0700",
  Initial: "#A6D5F4",
  Valid: "#058AA6",
  AcceptedRisk:"#37A440",
  FalsePositive:"#CE3733",
};

let StatusButton = (type, width, height, fontSize, className) => {
  console.log(type);
  return (
    <>
      <div className={className || ""}>
        <Box
          sx={{
            color: "#fff",
            backgroundColor: `${colorMap[type]}`,
            height: height || "27px",
            width: width || "90px",
            textAlign: "center",
            verticalAlign: "center",
            borderRadius: "5px",
            paddingX:'4px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: fontSize || "14px",
            fontWeight: "600",
          }}
          variant="contained"
        >
          <span>{type}</span>
        </Box>
      </div>
    </>
  );
};

export default StatusButton;
