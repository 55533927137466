import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, Divider, Modal, Rating } from "@mui/material";
import { isNumber } from "util";
import { postFetch } from "lib/fetch";
import { toast } from "react-toastify";

function Assets({ data, researcherId }) {
  const [value, setValue] = useState(data?.map((d) => d?.review));
  const [functionParams, setFunctionParams] = useState(null);

  const [openModal, setOpenModal] = React.useState(false);
  const [message, setMessage] = useState("");

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleRatingChange = async (e, newValue, index, assetId) => {
    const res = await postFetch("/review/modifyRating", {
      researcher_id: researcherId,
      asset_id: assetId,
      stars: newValue,
      message: message,
    });
    if (res?.status == "ok") {
      setValue((v) => {
        let newV = [...v];
        newV[index] = newValue;
        return newV;
      });
      setOpenModal(() => false);
      setMessage(() => "");
      setFunctionParams(() => null);
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else {
      toast.error("some error occured, try later");
    }
  };

  const handleRatingDoneClick = async () => {
    const getFormEle = document.getElementById("formRatingChange923");

    if (getFormEle && getFormEle.checkValidity()) {
      await handleRatingChange(
        null,
        functionParams?.newValue,
        functionParams?.index,
        functionParams?.assetId
      );
    } else getFormEle.reportValidity();
  };

  return (
    <>
      {data?.map((row, index) => {
        return (
          <div style={{ padding: "1% 2%", width: "100%" }}>
            <Grid container>
              <Grid xs={4}>{row?.title}</Grid>
              <Grid xs={2}>
                {isNumber(row?.rating) && (row?.rating / 10)?.toFixed(1)} / 10
              </Grid>
              <Grid xs={2}>{row?.role || "researcher"} </Grid>
              <Grid xs={4}>
                <Rating
                  name="simple-controlled"
                  value={value[index]}
                  onChange={(e, newValue) => {
                    setOpenModal(() => true);
                    setFunctionParams(() => {
                      return { e, newValue, index, assetId: row._id };
                    });
                  }}
                />
              </Grid>
            </Grid>
            {index != data?.length - 1 && (
              <Divider sx={{ pt: 2, width: "100%" }} />
            )}

            <Modal
              open={openModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  boxShadow: 24,
                }}
                className="w-[800px] shadow rounded-10 bg-white dark:bg-lightBlack dark:text-white"
              >
                <form id="formRatingChange923">
                  <div className="text-2xl text-center font-bold mt-3p pb-2p mx-3p border-b ">
                    Add Message
                  </div>
                  <div className="px-3p mt-2">
                    <input
                      className="bg-inputBg border-b2 border rounded p-2p w-full dark:bg-greyBg4"
                      type={"text"}
                      value={message}
                      onChange={(e) => {
                        setMessage(() => e.target.value);
                      }}
                      required
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                    className="border-t-2 border-b2 mx-2p py-2p"
                  >
                    <button
                      style={{
                        // marginTop: "3.2%",
                        textDecoration: "none",
                        color: "#2D3B51 !important",
                        marginRight: "5vw",
                        fontWeight: "500",
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <div
                      className="button-1-step cursor-pointer flex items-center justify-center"
                      onClick={handleRatingDoneClick}
                    >
                      <div>Add</div>
                    </div>
                  </div>
                </form>
              </Box>
            </Modal>
          </div>
        );
      })}
    </>
  );
}

export default Assets;
