import React from "react";

import Grid from "@mui/material/Grid";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FirstData from "./FirstData";
import { stepsSliceActions } from "store/stepsSlice";
import { backButton, blueGradient1, button1 } from "components/tailwindClasses";

export default function First() {
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.steps.activeStep);

  let navigate = useNavigate();

  const handleNextClick = (e) => {
    e.preventDefault();
    dispatch(stepsSliceActions.setActiveStep(activeStep + 1));
  };
  const handleBackClick = (e) => {
    navigate(-1);
  };

  return (
    <div>
      <div
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
        }}
        className="bg-white py-3p px-[5%] dark:bg-lightBlack dark:text-white"
      >
        <Grid container>
          <FirstData />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <button
              className={backButton}
              style={{ width: "240px" }}
              onClick={handleBackClick}
            >
              Back
            </button>
            <div
              className={
                blueGradient1 +
                " w-60 h-15 rounded-10 font-bold flex items-center justify-center cursor-pointer"
              }
              onClick={handleNextClick}
            >
              <div>Next Step</div>
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
}
