import React from "react";

import ManagerTeam from "./components/Index";

export const ManagerTeamComponent = ManagerTeam;
function index(props) {
  return <div></div>;
}

export default index;
