import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import InfoCard from "components/ui/cards/InfoCard";
import InfoCardColored from "components/ui/cards/InfoCardColored";
import { getFetch } from "lib/fetch";
import { useDispatch, useSelector } from "react-redux";
import { dashboardInfoSliceActions } from "store/dashboardInfoSlice";

function InfoCards(props) {
  const dispatch = useDispatch();

  const dashboardData = useSelector((state) => state.dashboardInfo);

  const isLoading = useSelector((state) => state.dashboardInfo.isLoading);

  return (
    <div className="text-center">
      <Grid
        container
        spacing={{ xs: 1, md: 2 }}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item xs={2}>
          <InfoCard
            title={"Total Assets"}
            count={dashboardData?.totalAssets}
            topColor={"bg-[#3CCBF7] text-white "}
            bottomColor={"dark:bg-[#3CCBF7] dark:bg-opacity-50"}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoCard
            title={"Total Vulnerabilities"}
            count={dashboardData?.totalVulnerabilities}
            topColor={"bg-[#6922D6] text-white "}
            bottomColor={"dark:bg-[#6922D6] dark:bg-opacity-50"}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoCardColored
            type={"critical"}
            title={"Critical"}
            count={dashboardData?.critical}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoCardColored
            type={"high"}
            title={"High"}
            count={dashboardData?.high}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoCardColored
            type={"medium"}
            title={"Medium"}
            count={dashboardData?.medium}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoCardColored
            type={"low"}
            title={"Low"}
            count={dashboardData?.low}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default InfoCards;
