import React from "react";

function SimilarityCircle({
  percentage = 0,
  circleSize = 100, // Size of the circle in pixels
  strokeWidth = 5, // Width of the stroke in pixels
  outsideRadius = (circleSize - strokeWidth) / 2,
  insideRadius = outsideRadius - 6, // Adjust the spacing here
  outsideCircumference = 2 * Math.PI * outsideRadius,
  insideCircumference = 2 * Math.PI * insideRadius,
  progress = outsideCircumference - (percentage / 100) * outsideCircumference,

  fontSizeText = 24,
  fontSizeMatch = 14,
  hideMatchText,
}) {
  if (isNaN(percentage)) percentage = 0;

  percentage = Math.floor(percentage);

  return (
    <div style={{ width: circleSize, height: circleSize }}>
      <svg
        className="w-full h-full"
        viewBox={`0 0 ${circleSize} ${circleSize}`}
      >
        <circle
          cx={circleSize / 2}
          cy={circleSize / 2}
          r={outsideRadius}
          fill="none"
          stroke="#e0e0e0"
          strokeWidth={strokeWidth}
        />
        <circle
          className="transition-all duration-500 ease-in-out"
          cx={circleSize / 2}
          cy={circleSize / 2}
          r={outsideRadius}
          fill="none"
          stroke="url(#gradient)"
          strokeWidth={strokeWidth}
          strokeDasharray={outsideCircumference}
          strokeDashoffset={progress}
        />
        <circle
          cx={circleSize / 2}
          cy={circleSize / 2}
          r={insideRadius}
          fill="url(#gradient)" // Apply the linear gradient here
        />
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={fontSizeText}
          fill="#ffffff" // White color for the percentage text
          fontWeight="bold"
        >
          {percentage}%
        </text>
        {!hideMatchText && (
          <text
            x="50%"
            y="70%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize={fontSizeMatch}
            fontWeight="600"
            fill="#ffffff" // White color for the "Match" text
          >
            Match
          </text>
        )}
        <defs>
          <linearGradient id="gradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#303E55" />
            <stop offset="100%" stopColor="#539487" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default SimilarityCircle;
