import React, { useState } from "react";
import { MenuItem, Box, Select } from "@mui/material";
import { useEffect } from "react";
import { getFetch, postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { MultipleUserImage, UserImage } from "components/ui/ImageComponent";
import SeverityGroupButton from "components/ui/buttons/SeverityGroupButton";
import Loader from "components/ui/loaders/Loader";
import { inviteSliceActions } from "store/inviteSlice";
import { blueGradient1 } from "components/tailwindClasses";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 400,
  //   bgcolor: 'background.paper',
  //   border: '2px solid #000',
  boxShadow: 24,
  //   backDrop:'10px',
  //   p: 4,
};

function Index({ handleClose, row = {} }) {
  const [currentAssetId, setCurrentAssetId] = useState(row?.id || "");
  const [assetIds, setAssetIds] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(row || null);

  useEffect(() => {
    const getAssetIds = async () => {
      setIsloading(true);

      try {
        const response = await getFetch("/companyInvite/listAssets"); // Replace "api/assetIds" with your actual API endpoint to fetch the asset IDs

        if (response?.status == "ok") setAssetIds(response?.data);
        // Assuming the response contains an array of asset IDs
        else {
          toast.error("some error occured, try later");
        }
      } catch (error) {
        toast.error("some error occured, try later");

        console.error("Failed to fetch asset IDs:", error);
      }
      setIsloading(false);
    };

    if (!currentAssetId) {
      getAssetIds();
    }
  }, []);

  useEffect(() => {
    const getAssetData = async () => {
      setIsloading(true);

      try {
        const response = await postFetch("/companyInvite/getAssetData", {
          asset_id: currentAssetId,
        }); // Replace "api/assets" with your actual API endpoint to fetch asset data
        // Assuming the response contains the asset data

        if (response?.status == "ok") {
          // Set the fetched asset data as the selectedRow state
          setSelectedRow(() => {
            const assetData = response?.data;
            return {
              id: assetData?.asset_id,
              assetName: assetData?.title,
              description: assetData?.description,
              riskScore: assetData?.risk_score,
              totalVulnerabilities: assetData?.total_vuln_count,
              criticalSeverity: assetData?.critical_vuln_count,
              highSeverity: assetData?.high_vuln_count,
              mediumSeverity: assetData?.medium_vuln_count,
              lowSeverity: assetData?.low_vuln_count,
              teamMembers: assetData?.team?.map((i) => {
                return {
                  name: i?.username || i?.fname,
                  email: i?.email,
                  profileImage: i?.profile_image,
                };
              }),
            };
          });
        } else {
          toast.error("some error occured, try later");
        }
      } catch (error) {
        console.error("Failed to fetch asset data:", error);
        toast.error("some error occured, try later");
      }
      setIsloading(false);
    };

    if (currentAssetId && !row?.id) {
      getAssetData();
    }
  }, [currentAssetId]);

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [shareSummary, setShareSummary] = useState(false);

  const invites = useSelector((state) => state?.invite?.invites);

  const [inviteLoading, setInviteLoading] = useState(false);

  const handlePost = async () => {
    // Implement your logic to handle the API call and post data

    const formData = {
      email: email,
      asset_id: currentAssetId,
      message: message,
    };

    setInviteLoading(() => true);

    const res = await postFetch("/companyInvite/invite", formData);

    if (res?.status == "ok") {
      toast.success("Invite sent successfully");

      const toSet = [...invites];
      toSet.push({
        inviteId: res?.invite?.invite_id,
        targetName: res?.invite?.asset_title,
        description: res?.invite?.description,
        category: res?.invite?.type,
        tag: res?.invite?.tag,
        status: res?.invite?.status,
        date:
          res?.invite?.created_date &&
          new Date(res?.invite?.created_date)?.toDateString(),
        isAccepted: res?.invite?.status == "accepted",
        accessAllowed: res?.invite?.access_allowed,
      });

      dispatch(inviteSliceActions.setInvites(toSet));

      handleClose();
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else {
      toast.error("some error occured, try later");
    }

    setInviteLoading(() => false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentStepForm = document.getElementById("form-inviteCompany934294");
    if (currentStepForm && currentStepForm.checkValidity()) {
      await handlePost();
    } else {
      currentStepForm.reportValidity();
    }
  };

  return (
    <Box
      sx={style}
      className="w-[800px] shadow rounded-10 bg-white dark:bg-lightBlack dark:text-white"
    >
      <form action="" onSubmit={handleSubmit} id="form-inviteCompany934294">
        <div className="text-2xl text-center font-bold mt-2p pb-1p mx-3p border-b ">
          Invite Company
        </div>

        {!row?.id && (
          <div className="flex justify-center">
            {isLoading ? (
              <div className="dark:bg-lightBlack3 p-2p">
                <Loader />
              </div>
            ) : (
              <Select
                sx={{
                  boxSizing: "border-box",
                  height: "60px",
                  backgroundColor: "#D9D9D940",
                  border: "0px solid rgba(0, 0, 0, 0.25)",
                  borderRadius: "5px",
                }}
                className="dark:text-white dark:bg-lightBlack3 m-2p grow"
                required
                value={currentAssetId}
                onChange={(event) => setCurrentAssetId(event.target.value)}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                MenuProps={{
                  PaperProps: { sx: { maxHeight: 400 } },
                }}
              >
                <MenuItem value="">
                  <em>Select Asset</em>
                </MenuItem>

                {assetIds?.map((assetInfo) => (
                  <MenuItem value={assetInfo?._id}>{assetInfo?.title}</MenuItem>
                ))}
              </Select>
            )}
          </div>
        )}

        {currentAssetId && (
          <>
            <div className="bg-lightGreen dark:bg-[#3B3E4E] dark:text-white text-sm p-2p">
              <div className="flex items-center">
                <div className="mr-2">
                  <UserImage
                    name={"someone"}
                    style={{ width: "50px", height: "50px" }}
                  />{" "}
                </div>
                <div>
                  <div className="font-bold">{selectedRow?.assetName}</div>
                  <div className="break-all">{selectedRow?.description}</div>
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="grow">
                  <div className="grid grid-cols-11 ">
                    <div className="flex flex-col col-span-2">
                      <div className="font-medium">Risk Score</div>
                      <div className="mt-4">
                        {selectedRow?.riskScore?.toFixed?.(2)}
                      </div>
                    </div>
                    <div className="flex flex-col col-span-3">
                      <div className="font-medium">Total Vulnerabilities</div>
                      <div className="mt-4">
                        {selectedRow?.totalVulnerabilities}
                      </div>
                    </div>
                    <div className="flex flex-col col-span-3">
                      <div className="font-medium">Severity</div>
                      <div className="mt-4">
                        <SeverityGroupButton
                          critical={selectedRow?.criticalSeverity}
                          high={selectedRow?.highSeverity}
                          medium={selectedRow?.mediumSeverity}
                          low={selectedRow?.lowSeverity}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col col-span-3">
                      <div className="font-medium">Team Members</div>
                      <div className="mt-4">
                        {/* this is going behind the Modal */}
                        <MultipleUserImage
                          team={selectedRow?.teamMembers?.map((i) => {
                            return {
                              name: i?.username || i?.fname,
                              email: i?.email,
                              profileImage: i?.profile_image,
                            };
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  {selectedRow?.screenshot && (
                    <img
                      src={selectedRow?.screenshot}
                      alt=""
                      className="max-h-32 max-w-[128px] "
                    />
                  )}
                </div>
              </div>
            </div>

            <div style={{ marginTop: "1%" }} className="px-3p">
              <label style={{}}>
                <b>Email</b>
              </label>
              <input
                style={{ height: "60px" }}
                className="second-step-input dark:bg-lightBlack3"
                value={email}
                type="email"
                onChange={(e) => {
                  setEmail(() => e.target.value);
                }}
                required
              />

              <div
                style={{
                  marginTop: "1%",
                  paddingBottom: "1%",
                }}
              >
                <input
                  type="checkbox"
                  style={{}}
                  className=""
                  value={shareSummary}
                  onChange={(e) => {
                    setShareSummary(() => e.target.value);
                  }}
                />
                <span style={{ marginLeft: "1%" }}>
                  <b>Share Target Summary</b>
                </span>
              </div>

              <label style={{}} className="flex justify-between">
                <b>Message</b>
                <span>Optional</span>
              </label>
              <textarea
                style={{ height: "120px", maxHeight: "120px" }}
                className="second-step-input dark:bg-lightBlack3"
                // value={assetData?.inviteCompanyEmail}
                onChange={(e) => {
                  setMessage(() => e.target.value);
                }}
              />
            </div>
          </>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          className="border-t-2 border-b2 mx-2p py-1p"
        >
          <div
            style={{
              // marginTop: "3.2%",
              textDecoration: "none",
              color: "#2D3B51 !important",
              marginRight: "5vw",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            Cancel
          </div>
          <button
            className={
              blueGradient1 + " text-white font-bold rounded-10 w-60 h-15"
            }
            type="submit"
            disabled={inviteLoading}
          >
            {inviteLoading ? <Loader /> : "Done"}
          </button>
        </div>
      </form>
    </Box>
  );
}

export default Index;
