import React from "react";
import ModalImage from "react-modal-image";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { postFetch, pythonPostFetch } from "lib/fetch";
import { gridDateFormatter } from "@mui/x-data-grid";
import generalSlice from "store/generalSlice";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";
import { UserImage } from "components/ui/ImageComponent";
import { targetInfoSliceActions } from "store/targetInfoSlice";
import TargetInfoLoadingSkeleton from "components/ui/loaders/TargetInfoSkeleton";
import axios from "axios";

function TargetInfoLayout({ children, goBack }) {
  const dispatch = useDispatch(); // created dispatch function

  const headerDetails = useSelector((state) => state.targetInfo?.data);

  const screenshotUrl = useSelector((state) => state.targetInfo?.screenshot);

  const currentTarget = useSelector(
    (state) => state.targetDashboard.currentTarget
  );

  // else
  // if its a scanner then make use selector from scanner/targetdashboard

  // if currentTarget.id is not there
  // then load the previously saved state from sessionSorage
  // session se load ho raha hai state
  // useEffect(() => {
  //   const savedState = sessionStorage.getItem("targetDashboardState");

  //   if (!currentTarget?.id && savedState) {
  //     const parsedState = JSON.parse(savedState);
  //     if (parsedState.targetId) {
  //       dispatch(
  //         targetDashboardSliceActions.setCurrentTarget({
  //           id: parsedState.targetId,
  //         })
  //       );
  //     }
  //   }
  // }, []);
  // console.log(currentTarget.id);

  // saving state to session storage
  useEffect(() => {
    // only save to session storage if current target id is presnet otherwise dont override it 
    if(currentTarget?.id){
    const stateToSave = { targetId: currentTarget?.id };
    sessionStorage.setItem("targetDashboardState", JSON.stringify(stateToSave));
    }
  }, [currentTarget?.id]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getHeaderDetails = async () => {
      setIsLoading(() => true);
      const res = await postFetch("/asset/getHeaderDetails", {
        asset_id:  currentTarget?.id || JSON.parse(sessionStorage.getItem("targetDashboardState")).targetId 
      });

      if (res?.status == "ok") {
        const resData = res?.header_info;

        dispatch(
          targetInfoSliceActions.setData({
            asset_name: resData?.asset_name,
            asset_type: resData?.asset_type,
            company_name: resData?.company_name,
            created_date: resData?.created_date,
            company_Profile_image: resData?.company_Profile_image,
            asset_description: resData?.asset_description,
            website_url: resData?.website_url,
          })
        );

        dispatch(
          targetDashboardSliceActions.setBugCount(
            res?.header_info?.bug_tracker_tab_count
          )
        );
        dispatch(
          targetDashboardSliceActions.setVulnerabilityCount(
            res?.header_info?.vulnerability_tab_count
          )
        );
        dispatch(
          targetDashboardSliceActions.setReportCount(
            res?.header_info?.report_tab_count
          )
        );

        dispatch(
          targetDashboardSliceActions.setCurrentTarget({
            ...currentTarget,
            name: res?.header_info?.asset_name,
          })
        );
      }else{

        dispatch(
          targetInfoSliceActions.setData({
            asset_name: "",
            asset_type: "",
            company_name:"",
            created_date: "",
            company_Profile_image: "",
            asset_description:"",
            website_url:"",
          })
        );

        dispatch(
          targetDashboardSliceActions.setBugCount(0))
          
        dispatch(
          targetDashboardSliceActions.setVulnerabilityCount(0
          )
        );
        dispatch(
          targetDashboardSliceActions.setReportCount(
          0
          )
        );

        dispatch(
          targetDashboardSliceActions.setCurrentTarget({
            ...currentTarget,
            name: ""
          })
        );

      }
      setIsLoading(() => false);
    };

    currentTarget?.id && getHeaderDetails();
  }, [currentTarget?.id]);

  useEffect(() => {
    const getSs = async () => {
      const res = await pythonPostFetch("/asset_screenshot", {
        target: headerDetails.website_url,
      });

      if (res?.status == "success") {
        dispatch(targetInfoSliceActions.setScreenshotUrl(res?.data));
      }
    };

    headerDetails?.website_url && getSs();
  }, [headerDetails?.website_url]);

  const navigate = useNavigate();
  const handleBackClick = (e) => {
    navigate(-1);
  };

  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";

  return (
    <>
      {isLoading && !headerDetails?.asset_name ? (
        <TargetInfoLoadingSkeleton />
      ) : (
        <div className="bg-white dark:bg-lightBlack dark:text-white   p-1p flex justify-between items-center rounded-10 relative">
          <div className=" flex">
            <div className=" flex mr-4 h-fit">
              {goBack && (
                <Link
                  onClick={handleBackClick}
                  className="no-underline self-center mr-2"
                >
                  <img
                    src={isDarkTheme ? "/leftArrowDark.svg" : "/leftArrow.svg"}
                    alt=""
                    className="inline mr-3 text-center fill-[#1D26EF]"
                  />
                </Link>
              )}
              {headerDetails?.company_Profile_image ? (
                <img
                  src={headerDetails?.company_Profile_image}
                  alt=""
                  className="w-15 h-15 rounded-full"
                />
              ) : (
                <UserImage
                  name={headerDetails?.company_name || "-"}
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "100%",
                  }}
                />
              )}
            </div>

            <div className="flex flex-col ">
              <div className="inline font-bold text-xl ">
                <span>{headerDetails?.asset_name}</span>{" "}
                {headerDetails?.company_name && (
                  <span className="text-base ">
                    ({headerDetails?.company_name})
                  </span>
                )}{" "}
              </div>
              <div>
                {headerDetails?.asset_description && (
                  <span className="break-all">
                    {headerDetails?.asset_description}
                  </span>
                )}

                {headerDetails?.website_url && (
                  <>
                    <span className="mx-2">|</span>
                    <Link
                      className="no-underline font-medium"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(headerDetails?.website_url, "_blank");
                      }}
                    >
                      {headerDetails?.website_url}
                    </Link>
                  </>
                )}
              </div>

              {headerDetails?.website_url && (
                <button
                  className="w-28 h-8  text-white font-semibold rounded mt-5 text-sm  bg-[#539487]"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(headerDetails?.website_url, "_blank");
                  }}
                >
                  Website
                </button>
              )}
            </div>
          </div>
          <div className="flex items-end h-28">
            <div className="text-xs mr-4 ">
              <span className="font-medium">Created On : </span>
              <span className="font-semibold">
                {new Date(headerDetails?.created_date).toDateString()}
              </span>
            </div>

            <div>
              <ModalImage
                small={screenshotUrl}
                large={screenshotUrl}
                className="h-28"
                showRotate={true}
                // alt="screenshot"
              />
            </div>
          </div>
        </div>
      )}

      {children}
    </>
  );
}

export default TargetInfoLayout;
