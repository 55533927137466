import React, { useEffect } from "react";
import {
  LoginComponent,
  CompanyManagerLoginComponent,
  SecurityEngineerLoginComponent,
  SecurityManagerLoginComponent,
  CompanyEngineerLoginComponent,
} from "features/login";
import { useLocation, useNavigate } from "react-router-dom";
import { getFetch } from "lib/fetch";
import { toast } from "react-toastify";

function Login(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoggeIn, setIsLoggedIn] = React.useState(true);

  useEffect(() => {
    const getUserData = async () => {
      const res = await getFetch("/profile");

      if (res?.isAuth) {
        navigate("/dashboard");
        toast.info("You are already logged in");
      } else {
        setIsLoggedIn(false);
      }
    };
    getUserData();
  }, []);

  if (!isLoggeIn) {
    if (location.pathname === "/login/CompanyManager") {
      return <CompanyManagerLoginComponent />;
    } else if (location.pathname === "/login/SecurityEngineer") {
      return <SecurityEngineerLoginComponent />;
    } else if (location.pathname === "/login/SecurityManager") {
      return <SecurityManagerLoginComponent />;
    } else if (location.pathname === "/login/CompanyEngineer") {
      return <CompanyEngineerLoginComponent />;
    } else {
      return <LoginComponent />;
    }
  }
}

export default Login;
