import React, { useEffect, useState } from "react";
import SecondaryButton from "components/ui/buttons/SecondaryButton";
import CompanyDetails from "./CompanyDetails";
import Contact from "./Contact";
import Services from "./Services";
import AddAssets from "./AddAssets/Index";
import { useSelector } from "react-redux";
import { blueGradient1 } from "components/tailwindClasses";
import { postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import Loader from "components/ui/loaders/Loader";

function Index({ setEdit }) {
  const user = useSelector((state) => state.user.data);

  let [activeOption, setactiveOption] = useState(0);
  let options = {
    companyDetails: {
      title: "Company Details",
      option: 0,
      hidden: false,
    },
    contact: {
      title: "Contact",
      option: 1,
      hidden: false,
    },
    // services: {
    //   title: "Services",
    //   option: 2,
    //   hidden: user.role == "S_MANAGER" ? false : true,
    // },
    // addAssets: {
    //   title: "Add Assets",
    //   option: 3,
    //   hidden: user.role == "MANAGER" ? false : true,
    // },
  };

  const companyData = useSelector((state) => state?.profile?.companyDetails);

  const [editableData, setEditableData] = useState({});

  useEffect(() => {
    setEditableData(() => companyData);
  }, [companyData]);

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const handleUpdateClick = async () => {
    const formEle = document.getElementById("companyProfileId02348823");
    if (formEle && formEle.checkValidity()) {
      setLoadingUpdate(() => true);

      // console.log(editableData);

      const res = await postFetch("/customer/editProfile", editableData);
      if (res?.status == "ok") {
        toast.success("Company Profile Updated Successfully");
        setEdit(() => false);
      } else if (res?.error && res?.message) {
        toast.error(res?.message);
      } else {
        toast.error("Something went wrong, try later");
      }

      setLoadingUpdate(() => false);
    } else {
      formEle.reportValidity();
      toast.warn("Please fill in all required fields in all sections");
    }
  };

  return (
    <form action="" id="companyProfileId02348823">
      <div style={{}}>
        <div className="rounded-10 bg-white dark:bg-lightBlack flex justify-between ">
          <div className="flex items-center">
            {Object.keys(options)
              .filter((key) => options[key].hidden == false)
              .map((key, i) => {
                return (
                  <div
                    onClick={() => {
                      setactiveOption(() => options[key].option);
                    }}
                    className={
                      activeOption == options[key].option
                        ? "w-52"
                        : "cursor-pointer pl-2 w-40 text-center"
                    }
                  >
                    {activeOption == options[key].option ? (
                      <button
                        className={
                          "w-full text-base font-bold text-white h-14  rounded-lg " +
                          blueGradient1
                        }
                      >
                        {options[key].title}
                      </button>
                    ) : (
                      <span className="dark:text-white dark:text-opacity-50">
                        {options[key].title}
                      </span>
                    )}
                  </div>
                );
              })}
          </div>
          <div className="w-60 flex">
            <div
              onClick={() => {
                !loadingUpdate && handleUpdateClick();
              }}
              className={
                blueGradient1 +
                " w-60 rounded-10 h-15 text-white font-bold flex justify-center items-center cursor-pointer"
              }
            >
              {loadingUpdate ? <Loader /> : "Update"}
            </div>
          </div>
        </div>

        <div
          className={
            activeOption != options.companyDetails.option &&
            "invisible max-h-0 overflow-hidden"
          }
        >
          <CompanyDetails
            editableData={editableData}
            setEditableData={setEditableData}
            goNext={() => {
              setactiveOption(() => options.contact.option);
            }}
            goBack={() => {
              setEdit(() => false);
            }}
          />
        </div>

        <div
          className={
            activeOption != options.contact.option &&
            "invisible max-h-0 overflow-hidden"
          }
        >
          <Contact
            hasNext={user.role == "S_MANAGER"}
            editableData={editableData}
            setEditableData={setEditableData}
            goBack={() => {
              setactiveOption(() => options.companyDetails.option);
            }}
            goNext={() => {
              user.role == "MANAGER" &&
                setactiveOption(() => options.addAssets.option);
              user.role == "S_MANAGER" &&
                setactiveOption(() => options.services.option);
            }}
          />
        </div>

        {/* <div
          className={
            activeOption != options.services.option &&
            "invisible max-h-0 overflow-hidden"
          }
        >
          <Services
            editableData={editableData}
            setEditableData={setEditableData}
            goBack={() => {
              setactiveOption(() => options.contact.option);
            }}
          />
        </div> */}
        {/* {activeOption == options.addAssets.option && (
          <AddAssets
            goBack={() => {
              setactiveOption(() => options.contact.option);
            }}
          />
        )} */}
      </div>
    </form>
  );
}

export default Index;
