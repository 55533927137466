import React, { useState } from "react";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { activePageActions } from "store/activePageSlice";
import { getFetch } from "lib/fetch";

import TeamMembers from "./TeamMembers/Index";
import Requests from "./Requests/Index";
import DisabledMembers from "./DisabledMembers/Index";

import BackToCompanyListLayout from "Layouts/BackToCompanyList";
import AddNewMember from "./AddNewMember";

import { blueGradient1 } from "components/tailwindClasses";

const inactiveButtonStyle =
  "flex justify-center items-center mr-[2vw] h-15 w-60";
const activeButtonStyle =
  "font-bold text-white bg-blue1 rounded-10 dark:border-2 dark:bg-lightBlack3 dark:border-white " +
  inactiveButtonStyle;

export default function Index() {
  let user = useSelector((state) => state.user.data);

  // team member or requests
  let [activeOption, setActiveOption] = useState(0);

  const [openModal, setOpenModal] = React.useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenModal(false);
  };

  return (
    <div className="p-1p">
      <BackToCompanyListLayout>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={
              activeOption == 0
                ? {
                    display: "flex",
                    flexGrow: "1",
                    // justifyContent: "space-between",
                    alignItems: "center",

                    fontSize: "16px",
                    fontWeight: "400",
                    height: "60px",
                    marginRight: "1%",
                    borderRadius: "10px",

                    cursor: "default",
                  }
                : {
                    display: "flex",
                    flexGrow: "1",
                    // justifyContent: "space-between",
                    alignItems: "center",

                    fontSize: "16px",
                    fontWeight: "400",
                    height: "60px",
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                    cursor: "default",
                  }
            }
            className="bg-white dark:bg-lightBlack dark:text-white"
          >
            <div
              className={
                activeOption == 0
                  ? inactiveButtonStyle +
                    " " +
                    blueGradient1 +
                    " rounded-10 font-bold"
                  : inactiveButtonStyle
              }
              // className={
              //   activeOption == 0 ? activeButtonStyle : inactiveButtonStyle
              // }
              onClick={() => {
                setActiveOption(() => 0);
              }}
            >
              Team Members
            </div>
            {(user.role == "MANAGER" || user.role == "S_MANAGER") && (
              <div
                // className={
                //   activeOption == 1 ? activeButtonStyle : inactiveButtonStyle
                // }
                className={
                  activeOption == 1
                    ? inactiveButtonStyle +
                      " " +
                      blueGradient1 +
                      " rounded-10 font-bold"
                    : inactiveButtonStyle
                }
                onClick={() => {
                  setActiveOption(() => 1);
                }}
              >
                Requests
              </div>
            )}
            <div
              // className={
              //   activeOption == 2 ? activeButtonStyle : inactiveButtonStyle
              // }
              className={
                activeOption == 2
                  ? inactiveButtonStyle +
                    " " +
                    blueGradient1 +
                    " rounded-10 font-bold"
                  : inactiveButtonStyle
              }
              onClick={() => {
                setActiveOption(() => 2);
              }}
            >
              Disabled Members
            </div>
          </div>
          {activeOption == 0 && (
            <div
              onClick={handleModalOpen}
              className={
                blueGradient1 +
                " flex items-center justify-center cursor-pointer w-60 rounded-10 h-15 font-bold"
              }
            >
              Add New
            </div>
          )}
        </div>
        <div style={{ float: "right" }}>
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <AddNewMember handleClose={handleClose} />
          </Modal>
        </div>
        {activeOption == 0 && <TeamMembers />}
        {activeOption == 1 && <Requests />}
        {activeOption == 2 && <DisabledMembers />}
      </BackToCompanyListLayout>
    </div>
  );
}
