import React from "react";
import { Select, MenuItem } from "@mui/material";
import { useState, useRef } from "react";
import SecondaryButton from "components/ui/buttons/SecondaryButton";
import { backButton, blueGradient1 } from "components/tailwindClasses";
import { useDispatch, useSelector } from "react-redux";
import { profileSliceActions } from "store/profileSectionSlice";
import { useEffect } from "react";
import { handleFileUpload } from "services/uploadFile";
import { UserImage } from "components/ui/ImageComponent";
import Popup from "reactjs-popup";

function Index({ editableData, setEditableData, goNext, goBack }) {
  const dispatch = useDispatch();

  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";

  const [selectedImage, setSelectedImage] = useState(null); // this is show image
  const [selectedImageFile, setSelectedImageFile] = useState(null); // this is upload image

  const [showImagePreview, setShowImagePreview] = useState(false);
  const fileInputRef = useRef(null);

  const openImagePreview = () => {
    setShowImagePreview(true);
  };

  const closeImagePreview = () => {
    setSelectedImageFile(() => null);
    setSelectedImage(() => null);

    setShowImagePreview(false);
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const file = files[0];

    setSelectedImageFile(() => file);

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage(reader.result);
        setShowImagePreview(true);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    // Implement your upload logic here

    const res = await handleFileUpload([selectedImageFile]);

    res?.length > 0 &&
      setEditableData(() => {
        return { ...editableData, profileImage: res?.[0] };
      });

    setSelectedImageFile(() => null);
    setSelectedImage(() => null);

    closeImagePreview();
  };

  return (
    <div>
      <div
        style={{
          marginTop: "2%",
          marginBottom: "2%",
          padding: "3%",
          boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
        }}
        className="dark:bg-lightBlack dark:text-white bg-white"
      >
        <div
          style={{
            fontSize: "18px",
            fontWeight: "600",
            paddingBottom: "1%",
          }}
          className="border-b border-b2 dark:border-b9"
        >
          Basic Information
        </div>

        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Logo
          </p>
          <div className="mr-10">
            <div className="relative inline-block">
              <UserImage
                image={editableData?.profileImage}
                name={editableData?.name || "-"}
                style={{ width: "96px", height: "96px" }}
              />

              <div
                className="absolute top-0 right-0 cursor-pointer w-8 h-8 flex justify-center items-center rounded-full bg-white shadow-md"
                onClick={() => fileInputRef.current.click()}
              >
                <img src={"/editPen.svg"} alt="" className="w-4" />
              </div>
            </div>

            <Popup
              open={showImagePreview}
              closeOnDocumentClick={false}
              closeOnEscape={false}
              onClose={closeImagePreview}
              nested
            >
              <div className="popup-content fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="max-w-full max-h-full">
                  {selectedImage && (
                    <img
                      src={selectedImage}
                      alt="Image Preview"
                      className="mx-auto max-w-full max-h-[80vh]"
                    />
                  )}
                  <div className="flex justify-center mt-4">
                    <button
                      onClick={handleUpload}
                      className="px-4 py-2 mr-2 bg-green-500 text-white rounded"
                    >
                      Upload
                    </button>
                    <button
                      onClick={closeImagePreview}
                      className="px-4 py-2 bg-red-500 text-white rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Popup>

            <input
              type="file"
              accept="image/*"
              className="hidden"
              ref={fileInputRef}
              onChange={handleImageChange}
            />
          </div>
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Company name<span className="text-[#FDBC2C]">*</span>
          </p>
          <div>
            <input
              required
              className="p-2p bg-inputBg h-15 w-full rounded dark:text-white dark:bg-inputBgDark"
              style={{ margin: "0" }}
              value={editableData?.name}
              onChange={(e) => {
                setEditableData(() => {
                  return { ...editableData, name: e.target.value };
                });
              }}
            />
          </div>
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Description<span className="text-[#FDBC2C]">*</span>
          </p>
          <div>
            <textarea
              required
              name=""
              id=""
              rows="7"
              style={{
                resize: "vertical",
                width: "100%",
                boxSizing: "border-box",

                fontSize: "16px",
              }}
              className="bg-inputBg dark:bg-inputBgDark rounded p-2p"
              placeholder=""
              value={editableData?.description}
              onChange={(e) => {
                setEditableData(() => {
                  return { ...editableData, description: e.target.value };
                });
              }}
            ></textarea>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: "2%",
          marginBottom: "2%",
          padding: "3%",
          boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
        }}
        className="dark:bg-lightBlack dark:text-white bg-white"
      >
        <div
          style={{
            fontSize: "18px",
            fontWeight: "600",
            paddingBottom: "1%",
          }}
          className="border-b border-b2 dark:border-b9"
        >
          Company Details
        </div>

        {/* <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Category
          </p>
          <div>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={category}
              onChange={(e) => {
                setCategory(() => e.target.value);
              }}
              className="bg-inputBg w-full dark:bg-lightBlack3 dark:text-white"
            >
              <MenuItem value="None">
                <em>Select</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </div>
        </div> */}
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Founder name
          </p>
          <div>
            <input
              className="p-2p bg-inputBg h-15 w-full rounded dark:text-white dark:bg-inputBgDark"
              style={{ margin: "0" }}
              value={editableData?.founder}
              onChange={(e) => {
                setEditableData(() => {
                  return { ...editableData, founder: e.target.value };
                });
              }}
            />
          </div>
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Founded Date
          </p>
          <div>
            <input
              className="p-2p bg-inputBg h-15 w-full rounded dark:text-white dark:bg-inputBgDark"
              type="date"
              style={{ margin: "0" }}
              value={new Date(editableData?.foundedDate)
                .toLocaleDateString("en-GB", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  formatMatcher: "basic",
                })
                .split("/")
                .reverse()
                .join("-")}
              onChange={(e) => {
                setEditableData(() => {
                  return {
                    ...editableData,
                    foundedDate: e.target.value,
                  };
                });
              }}
            />
          </div>
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Policy
          </p>
          <div>
            <textarea
              name=""
              id=""
              rows="7"
              style={{
                resize: "vertical",
                width: "100%",
                boxSizing: "border-box",

                fontSize: "16px",
              }}
              className="bg-inputBg dark:bg-inputBgDark rounded p-2p"
              placeholder=""
              value={editableData?.policy}
              onChange={(e) => {
                setEditableData(() => {
                  return { ...editableData, policy: e.target.value };
                });
              }}
            ></textarea>
          </div>
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Location
          </p>
          <div>
            <input
              className="p-2p bg-inputBg h-15 w-full rounded dark:text-white dark:bg-inputBgDark"
              style={{ margin: "0" }}
              value={editableData?.location}
              onChange={(e) => {
                setEditableData(() => {
                  return { ...editableData, location: e.target.value };
                });
              }}
            />
          </div>
        </div>

        <div className=" flex justify-between mt-2p">
          <div onClick={goBack} className="w-52 ">
            <div
              className={
                backButton + " cursor-pointer flex items-center justify-center"
              }
              style={{}}
            >
              Back
            </div>
          </div>
          <div className="w-52">
            <div
              className={
                "w-full cursor-pointer flex items-center justify-center text-base font-bold text-white h-15 bg-gradient-to-b  rounded-10 " +
                blueGradient1
              }
              onClick={goNext}
            >
              Next
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
