import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TargetRow from "./TableRow/TargetRow";
import { Button, Paper, TableSortLabel } from "@mui/material";

import { columns } from "./Columns";
import { useDispatch, useSelector } from "react-redux";
import { getFetch } from "lib/fetch";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import SeverityGroupButton from "components/ui/buttons/SeverityGroupButton";
import { MultipleUserImage } from "components/ui/ImageComponent";
import Popup from "reactjs-popup";
import Performance from "components/ui/buttons/Performance";
import DotButton from "./DotButton";
import { riskScoreColor } from "Util/RiskScoreColors";
import { targetSliceActions } from "store/targetSlice";
import TargetTableLoadingSkeleton from "components/ui/loaders/TableSkeleton";

function TargetTable({ search, bin = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  const user = useSelector((state) => state.user.data);

  const data = useSelector((state) => state.target.targets);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let getDataAll = async () => {
      setIsLoading(() => true);

      const res = await getFetch("/getAssets");

      if (res?.status == "ok") {
        // console.log("rows target res : ", res);
        dispatch(
          targetSliceActions.setTargets(
            res?.data?.assets?.map((item) => {
              return {
                id: item.id,
                assetName: item.title,
                riskScore: item?.risk_score?.toFixed?.(1),
                userPerformance: item?.user_performance,
                totalVulnerabilities: item?.asset_vuln_count,
                criticalSeverity: item?.critical_vuln_count,
                highSeverity: item?.high_vuln_count,
                mediumSeverity: item?.medium_vuln_count,
                lowSeverity: item?.low_vuln_count,
                newStatus: item?.new_vuln_count,
                triagedStatus: item?.triaged_vuln_count,
                resolveStatus: item?.resolve_vuln_count,
                duplicateStatus: item?.duplicate_vuln_count,
                informativeStatus: item?.informative_vuln_count,

                description: item?.description,

                teamMembers: item?.teamMembers || [],

                websiteUrl: item?.website_url,
                companyInfo: item?.companyInfo,
                hasReport: res?.data?.has_generated_report,
                screenshot: null,
              };
            })
          )
        );

        // }
      } else if (res?.error && res?.message) {
        toast.error(res?.message);

        dispatch(targetSliceActions.setTargets([]));
      } else {
        toast.error("some error occured in getting assets, try later");
        dispatch(targetSliceActions.setTargets([]));
      }

      setIsLoading(() => false);
    };

    getDataAll();
  }, [bin]);

  const [sortBy, setSortBy] = useState(""); // column to be sorted by the table values
  const [sortOrder, setSortOrder] = useState("asc"); // ascending or descending

  const handleSort = (columnId) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnId);
      setSortOrder("asc");
    }
  };

  let sortedData = data?.slice()?.sort((a, b) => {
    const aValue = a[sortBy];
    const bValue = b[sortBy];
    if (sortOrder === "asc") {
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
    } else {
      if (aValue > bValue) return -1;
      if (aValue < bValue) return 1;
    }
    return 0;
  });

  if (search !== "") {
    sortedData = sortedData?.filter((item) =>
      item?.assetName?.toLowerCase()?.includes(search.toLowerCase())
    );
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let rows =
    sortedData && sortedData?.length > 0
      ? sortedData?.map((item) => {
          // console.log(item.id);
          return {
            id: item.id,
            rating: item.rating,
            name: item.assetName,
            critical: item.criticalSeverity,
            high: item.highSeverity,
            medium: item.mediumSeverity,
            low: item.lowSeverity,
            new: item.newStatus,
            resolve: item.resolveStatus,
            triaged: item.triagedStatus,
            duplicate: item.duplicateStatus,
            informative: item.informativeStatus,
            assetName: <Link className="no-underline">{item.assetName}</Link>,
            description: (
              <span className="break-all "> {item?.description}</span>
            ),
            performace: <Performance percentage={item.userPerformance} />,
            screenshot: item?.screenshot,
            riskScore: item?.riskScore ? (
              <Button
                variant="contained"
                disableRipple
                sx={{
                  bgcolor: riskScoreColor(item?.riskScore),
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                {item?.riskScore}
              </Button>
            ) : (
              "-"
            ),

            totalVulnerabilities: (
              <Button
                variant="contained"
                sx={{ bgcolor: "#6922D6", color: "white" }}
              >
                {item.totalVulnerabilities}
              </Button>
            ),
            criticalSeverity: (
              <SeverityGroupButton
                critical={item.criticalSeverity}
                high={item.highSeverity}
                medium={item.mediumSeverity}
                low={item.lowSeverity}
              />
            ),
            teamMembers: (
              <MultipleUserImage
                team={item?.teamMembers?.map((member) => {
                  return {
                    name: member?.username,
                    email: member?.email,
                    profileImage: member?.profile_image,
                  };
                })}
              />
            ),
            reviewExpand2: (
              <Popup
                // ref={chatPopupRef}
                position={"left center"}
                // trigger={
                //   <div>
                //     <img src="/chat.svg" className="inline" alt="" />
                //   </div>
                // }
              >
                {/* <Chat chatPopupRef={chatPopupRef} /> */}
              </Popup>
            ),
            btnItem2:
              user.role == "MANAGER" ? (
                <DotButton id={item.id} row={item} />
              ) : (
                <img
                  onClick={() => {
                    item?.hasReport
                      ? navigate("/report")
                      : navigate("/report", {
                          state: { downloadReport: true, assetId: item.id },
                        });
                  }}
                  src={isDarkTheme ? "/downloadDark.svg" : "/download.svg"}
                  alt=""
                  className="cursor-pointer"
                />
              ),

            websiteUrl: item?.websiteUrl,
            companyInfo: item?.companyInfo,

            hasReport: item?.hasReport,
            riskScoreProp: item?.riskScore,
            userPerformanceProp: item?.userPerformance,
          };
        })
      : [];

  const Cols = columns(user?.role, bin);

  return (
    <div>
      {/* <Paper className="w-full  dark:bg-lightBlack "> */}
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="">
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "600",
                }}
                sx={{ bgcolor: "rgba(217, 217, 217, 0.25)" }}
                className=" dark:bg-lightBlack3"
              ></TableCell>
              {Cols &&
                Cols?.length > 0 &&
                Cols?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={
                      {
                        // fontWeight: "600",
                      }
                    }
                    sx={{ bgcolor: "rgba(217, 217, 217, 0.25)" }}
                    className=" dark:bg-lightBlack3 dark:text-lightBlack1"
                  >
                    <TableSortLabel
                      active={sortBy === column.id}
                      direction={sortBy === column.id ? sortOrder : "asc"}
                      onClick={() => handleSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          {isLoading && rows?.length == 0 ? (
            <TargetTableLoadingSkeleton
              columnCount={Cols?.length + 1}
              tableCellClassName={
                "dark:text-white dark:border-b9 dark:bg-lightBlack border-b"
              }
            />
          ) : (
            <TableBody>
              {rows
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => (
                  <TargetRow key={row.id} row={row} />
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="dark:bg-lightBlack dark:text-white"
      />
      {/* </Paper> */}
    </div>
  );
}

export default TargetTable;
