import { backButton, blueGradient1 } from "components/tailwindClasses";
import React, { useState } from "react";

function Index({ editableData, setEditableData, goBack, goNext, hasNext }) {
  return (
    <div>
      <div
        style={{
          marginTop: "2%",

          padding: "3%",
          boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
        }}
        className="dark:bg-lightBlack dark:text-white bg-white"
      >
        <div
          style={{
            fontSize: "18px",
            fontWeight: "600",
            paddingBottom: "1%",
          }}
          className="border-b border-b2 dark:border-b9"
        >
          Contact Details
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Mobile Number
          </p>
          <div>
            <input
              className="p-2p bg-inputBg h-15 w-full rounded dark:text-white dark:bg-inputBgDark"
              style={{ margin: "0" }}
              type="tel"
              value={editableData?.phoneNumber}
              onChange={(e) => {
                setEditableData(() => {
                  return { ...editableData, phoneNumber: e.target.value };
                });
              }}
            />
          </div>
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Email
          </p>
          <div>
            <input
              type="email"
              className="p-2p bg-inputBg h-15 w-full rounded dark:text-white dark:bg-inputBgDark"
              style={{ margin: "0" }}
              value={editableData?.email}
              onChange={(e) => {
                setEditableData(() => {
                  return { ...editableData, email: e.target.value };
                });
              }}
            />
          </div>
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Address
          </p>
          <div>
            <input
              type="text"
              className="p-2p bg-inputBg h-15 w-full rounded dark:text-white dark:bg-inputBgDark"
              style={{ margin: "0" }}
              value={editableData?.address}
              onChange={(e) => {
                setEditableData(() => {
                  return { ...editableData, address: e.target.value };
                });
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: "2%",

          padding: "3%",
          boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "5px",
        }}
        className="dark:bg-lightBlack dark:text-white bg-white"
      >
        <div
          style={{
            fontSize: "18px",
            fontWeight: "600",
            paddingBottom: "1%",
          }}
          className="border-b border-b2 dark:border-b9"
        >
          Links
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Website URL
          </p>
          <div>
            <input
              type="url"
              className="p-2p bg-inputBg h-15 w-full rounded dark:text-white dark:bg-inputBgDark"
              style={{ margin: "0" }}
              value={editableData?.websiteUrl}
              onChange={(e) => {
                setEditableData(() => {
                  return { ...editableData, websiteUrl: e.target.value };
                });
              }}
            />
          </div>
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Facebook
          </p>
          <div>
            <input
              type="urk"
              className="p-2p bg-inputBg h-15 w-full rounded dark:text-white dark:bg-inputBgDark"
              style={{ margin: "0" }}
              value={editableData?.facebookUrl}
              onChange={(e) => {
                setEditableData(() => {
                  return { ...editableData, facebookUrl: e.target.value };
                });
              }}
            />
          </div>
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Instagram
          </p>
          <div>
            <input
              type="urk"
              className="p-2p bg-inputBg h-15 w-full rounded dark:text-white dark:bg-inputBgDark"
              style={{ margin: "0" }}
              value={editableData?.instagramUrl}
              onChange={(e) => {
                setEditableData(() => {
                  return { ...editableData, instagramUrl: e.target.value };
                });
              }}
            />
          </div>
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Twitter
          </p>
          <div>
            <input
              type="urk"
              className="p-2p bg-inputBg h-15 w-full rounded dark:text-white dark:bg-inputBgDark"
              style={{ margin: "0" }}
              value={editableData?.twitterUrl}
              onChange={(e) => {
                setEditableData(() => {
                  return { ...editableData, twitterUrl: e.target.value };
                });
              }}
            />
          </div>
        </div>
        <div>
          <p
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Linked In
          </p>
          <div>
            <input
              type="urk"
              className="p-2p bg-inputBg h-15 w-full rounded dark:text-white dark:bg-inputBgDark"
              style={{ margin: "0" }}
              value={editableData?.linkedinUrl}
              onChange={(e) => {
                setEditableData(() => {
                  return { ...editableData, linked: e.target.value };
                });
              }}
            />
          </div>
        </div>
        <div className=" flex justify-between mt-2p">
          <div className="w-52">
            <div
              className={
                backButton + " cursor-pointer flex items-center justify-center"
              }
              style={{}}
              onClick={goBack}
            >
              Back
            </div>
          </div>
          {/* {hasNext && (
            <div className="w-52">
              <div
                className={
                  "w-full cursor-pointer flex items-center justify-center text-base font-bold text-white h-15 bg-gradient-to-b  rounded-10 " +
                  blueGradient1
                }
                onClick={goNext}
              >
                Next
              </div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default Index;
