import React, { useState } from "react";
import MarkdownEditor from "./Index";
import MDEditor from "@uiw/react-md-editor";

import "./MdEditor.css";
import { useSelector } from "react-redux";

export function MarkdownText({ data }) {
  let isDarkMode = useSelector((state) => state.general.theme) == "dark";

  return (
    <MDEditor.Markdown
      data-color-mode={isDarkMode ? "dark" : "light"}
      style={{
        background: isDarkMode ? "#2C394D" : "white",
        color: isDarkMode ? "white" : "black",
      }}
      source={data}
      // style={{ whiteSpace: "pre-wrap" }}
    />
  );
}

function Index({ preview, setPreview, value, onChange, defaultValue }) {
  // console.log(preview);

  return (
    <>
      <MarkdownEditor
        defaultValue={defaultValue}
        value={value}
        onChange={(latestValue) => {
          onChange(latestValue);
        }}
        preview={preview}
      />
      <div className="flex justify-between border-x-2 border-b-2 dark:border-b9 p-2 rounded">
        <div className="flex">
          <div
            className={
              preview == "preview"
                ? "w-16 border-r text-center cursor-pointer"
                : "w-16 border-r text-center font-bold cursor-pointer"
            }
            onClick={() => {
              setPreview(() => "edit");
            }}
          >
            Write
          </div>
          <div
            className={
              preview == "edit"
                ? "w-16 text-center mx-2 cursor-pointer"
                : "w-16 text-center font-bold mx-2 cursor-pointer"
            }
            onClick={() => {
              setPreview(() => "preview");
            }}
          >
            Preview
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
