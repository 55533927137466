import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Assets from "./Assets";
import EditAssetOption from "./EditAssetOption";
import { postFetch } from "lib/fetch";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SecondaryButton from "components/ui/buttons/SecondaryButton";
import { teamSliceActions } from "store/teamSlice";
import { blueGradient1 } from "components/tailwindClasses";
import Loader from "components/ui/loaders/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const styleEdit = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};

function UserNode({ data, deleted }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.data);

  const team = useSelector((state) => state.team.team);

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);

  // for listing assets in popup modal
  let [assetsAssigned, setAssetsAssigned] = useState([]);
  let [assetsUnassigned, setAssetsUnassigned] = useState([]);

  const handleDeleteModalOpen = () => {
    setOpenDeleteModal(true);
  };
  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const handleEditModalOpen = async () => {
    // setOpenEdit(false);

    if (!(data && data?._id)) return;

    let listData = { unassigned_assets: [], assigned_assets: [] };

    let response = await postFetch("/listMemberAssets", {
      researcher_id: data?._id,
    });
    if (response?.status == "ok") {
      listData.unassigned_assets = response?.unassigned_assets;
      listData.assigned_assets = response?.assigned_assets;
    }

    // addding role in obj to return back if edited

    let asAssets = [],
      unasAssets = [];

    listData?.assigned_assets.forEach((assetObj, index) => {
      asAssets.push({ ...assetObj, role: "Researcher" });
    });

    listData?.unassigned_assets.forEach((assetObj, index) => {
      unasAssets.push({ ...assetObj, role: "" });
    });

    setAssetsAssigned(() => asAssets);
    setAssetsUnassigned(() => unasAssets);

    // response?.unassigned_assets?.length > 0 &&
    //   response?.Unassigned_assets.map((assetObj, index) => {
    //     // setAssetsUnassigned((array) =>
    //     //   array.push({ ...assetObj, role: "None" })
    //     // );
    //   });

    setOpenEditModal(true);
  };
  const handleEditModalClose = () => {
    setOpenEditModal(false);
  };

  const [twoFaInput, setTwoFaInput] = useState("");

  const [deleteLoading, setDeleteLoading] = useState(false);
  // handleDeleteClick
  let handleDeleteClick = async () => {
    const formEle = document.getElementById("deletePermanentlyMember29343");

    if (!(formEle && formEle.checkValidity())) {
      formEle.reportValidity();
      return;
    }

    if (data._id) {
      setDeleteLoading(() => true);
      const res = await postFetch("/manager/removeResearcher", {
        researcher_id: data._id,
        token: twoFaInput,
      });

      if (res?.status == "ok" && res?.data) {
        let updatedTeam = team.filter((i) => i._id != data._id);
        dispatch(teamSliceActions.setTeam(updatedTeam));

        handleDeleteModalClose();
        handleEditModalClose();

        toast.success("team member removed successfully");
      } else if (res?.error && res?.message) {
        toast.error(res?.message);
      } else {
        toast.error("some error occured, try later");
      }
    } else {
      toast.error("Member not identified, Try later!");
    }
    setDeleteLoading(() => false);
  };

  // implement loading state
  let [loadingUpdate, setLoadingUpdate] = useState(false);
  // handle ipdate
  let handleUpdateClick = async () => {
    setLoadingUpdate(() => true);

    let removed = assetsAssigned
      .filter((asset) => asset.role == "None")
      .map((asset) => asset._id);
    let added = assetsUnassigned
      .filter((asset) => asset.role == "Researcher")
      .map((asset) => asset._id);

    if (data?._id) {
      const res = await postFetch("/member/assignUnassignAsset", {
        researcher_id: data._id,
        added: added,
        removed: removed,
      });

      if (res?.status == "ok" && res?.data) {
        let updatedTeam = team.map((i, index) => {
          let toReturn = { ...i };
          if (String(i._id) == String(data._id)) {
            toReturn.assets = res.data;
          }
          // console.log(toReturn, index);

          return toReturn;
        });

        dispatch(teamSliceActions.setTeam(updatedTeam));
      }

      // console.log("AYA");

      handleEditModalClose();
    } else {
      toast.error("Member not identified, Try later!");
    }

    setLoadingUpdate(() => false);
  };

  const disabledMembers = useSelector((state) => state.team.disabledMembers);

  // for deleted user from team

  const [loadingAddAgain, setLoadingAddAgain] = useState(false);
  let handleAddAgainClick = async () => {
    setLoadingAddAgain(() => true);
    if (!data?._id) {
      toast.error("could not identify user, try later");
      return;
    }
    const res = await postFetch("/manager/readdTeamMember", {
      researcher_id: data._id,
    });
    if (res?.status == "ok" && res?.data) {
      dispatch(
        teamSliceActions.setDisabledMembers({
          rejected: disabledMembers.rejected,
          deleted: disabledMembers.deleted.filter(
            (dm) => dm._id != res.data._id
          ),
        })
      );
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else {
      toast.error("some error occured, try later");
    }
    setLoadingAddAgain(() => false);
  };

  const handleDeletePermaClick = () => {
    if (!user.twoFaEnabled) {
      toast.error("please enable two factor Authentication to delete member");
    } else handleDeleteModalOpen();
  };

  const isDarkTheme = useSelector((state) => state?.general?.theme) == "dark";

  return (
    <div className="shadow mt-1p bg-white dark:bg-lightBlack dark:text-white rounded overflow-clip">
      <div className="">
        <Grid container>
          <div
            className={
              isDarkTheme
                ? "flex items-center justify-between w-full bg-lightBlack5 p-1p"
                : "flex items-center justify-between w-full bg-lightGreen p-1p"
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Grid>
                <Avatar
                  alt={data?.username || data?.fname}
                  src={data?.profile_image || ".png"}
                />
              </Grid>
              <div
                className="avatar-text"
                style={{ display: "inline", paddingLeft: "15px" }}
              >
                <div style={{ fontSize: "20px" }}>
                  <b>{data?.fname || data?.username || "name NA"}</b>
                </div>
                <div style={{ fontSize: "18px" }} className="opacity-60">
                  {data?.email || "email NA"}
                </div>
              </div>
            </div>
            {!deleted && (
              <Button onClick={handleEditModalOpen} sx={{ padding: "0 3%" }}>
                <EditIcon />
              </Button>
            )}
            {deleted && (
              <div
                className="text-black font-bold cursor-pointer dark:text-white"
                onClick={!loadingAddAgain && handleAddAgainClick}
              >
                {loadingAddAgain ? <Loader /> : "Add Again"}
              </div>
            )}
          </div>
          {!deleted && (
            <div className="edit-button ">
              <Modal
                open={openEditModal}
                onClose={handleEditModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={styleEdit}
                  className="bg-white dark:bg-lightBlack dark:text-white w-[800px] rounded-10 flex flex-col justify-between"
                >
                  <div>
                    <div className="text-2xl font-bold text-center p-2p">
                      Edit Member
                    </div>
                    <div
                      className={
                        isDarkTheme
                          ? "flex items-center justify-between w-full bg-lightBlack5 p-1p "
                          : "flex items-center justify-between w-full bg-lightGreen p-1p"
                      }
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Grid>
                          <Avatar
                            alt={data?.username || data?.fname}
                            src={data?.profile_image || ".png"}
                          />
                        </Grid>
                        <div
                          className="avatar-text"
                          style={{ display: "inline", paddingLeft: "15px" }}
                        >
                          <div style={{ fontSize: "20px" }}>
                            <b>{data?.fname || data?.username || "name NA"}</b>
                          </div>
                          <div
                            style={{ fontSize: "18px" }}
                            className="opacity-60"
                          >
                            {data?.email || "email NA"}
                          </div>
                        </div>
                      </div>
                    </div>
                    {assetsAssigned?.length > 0 && (
                      <>
                        {" "}
                        <div className="bg-greyBg2 p-2p font-medium text-lg">
                          Asset List
                        </div>
                        <div className="max-h-[120px] overflow-y-scroll px-3p my-1p">
                          {assetsAssigned.map((asset, index) => {
                            return (
                              <>
                                <li
                                  style={{ paddingBottom: "40px" }}
                                  data-id={asset?._id}
                                >
                                  <div
                                    style={{ float: "left", paddingTop: "2%" }}
                                  >
                                    {asset?.title || "Name Not Available"}
                                  </div>
                                  <div
                                    style={{ float: "right", height: "45px" }}
                                  >
                                    <EditAssetOption
                                      current={index}
                                      role="Researcher"
                                      updateAssetList={setAssetsAssigned}
                                    />
                                  </div>
                                </li>
                                <br />
                              </>
                            );
                          })}
                        </div>
                      </>
                    )}
                    <div className="bg-greyBg2 p-2p font-medium text-lg">
                      Add More Assets
                    </div>
                    <div className="max-h-[180px] overflow-y-scroll px-3p my-1p">
                      {assetsUnassigned.map((asset, index) => {
                        return (
                          <>
                            <li
                              style={{ paddingBottom: "40px" }}
                              data-id={asset?._id}
                            >
                              <div style={{ float: "left", paddingTop: "2%" }}>
                                {asset?.title || "Name Not Available"}
                              </div>
                              <div style={{ float: "right" }}>
                                <EditAssetOption
                                  current={index}
                                  role="None"
                                  updateAssetList={setAssetsUnassigned}
                                />
                              </div>
                            </li>
                            <br />
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className=" p-2p">
                    <div className="">
                      <Button onClick={handleDeletePermaClick}>
                        <DeleteOutlineOutlinedIcon sx={{ color: "#F10606" }} />
                        <div>Delete Permanently</div>
                      </Button>
                      <Modal
                        open={openDeleteModal}
                        onClose={handleDeleteModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box
                          sx={style}
                          className="bg-white rounded-10 shadow dark:bg-lightBlack dark:text-white"
                        >
                          <form
                            id="deletePermanentlyMember29343"
                            className=" w-[700px] p-2p"
                          >
                            <div className="text-2xl font-bold text-center p-2p border-b brorder-b2 dark:border-b9">
                              Delete Member
                            </div>

                            <div className="mt-2p">
                              Are you sure, you want to delete member
                              permanently ?
                            </div>
                            <div className="mt-1p">
                              <div className="font-bold">
                                Two Factor Authentication:
                              </div>
                              <input
                                type="text"
                                className="bg-inputBg w-full dark:bg-inputBgDark rounded-10 h-15 mt-1p p-2p"
                                value={twoFaInput}
                                onChange={(e) => {
                                  setTwoFaInput(() => e.target.value);
                                }}
                                required
                              />
                            </div>
                            <div className="flex items-center justify-end mt-2p">
                              <div
                                className="cursor-pointer mr-8"
                                onClick={handleDeleteModalClose}
                              >
                                No
                              </div>
                              <div
                                className={
                                  blueGradient1 +
                                  " w-60 font-bold text-white h-15 rounded-10 flex items-center justify-center cursor-pointer"
                                }
                                onClick={!deleteLoading && handleDeleteClick}
                              >
                                {deleteLoading ? <Loader /> : <div>Yes</div>}
                              </div>
                            </div>
                          </form>
                        </Box>
                      </Modal>
                    </div>
                    <div className="flex items-center justify-end ">
                      <div
                        className="mr-10 cursor-pointer"
                        onClick={handleEditModalClose}
                      >
                        Cancel
                      </div>
                      <div className="w-60">
                        <div
                          onClick={!loadingUpdate && handleUpdateClick}
                          className={
                            blueGradient1 +
                            " w-60 font-bold text-white h-15 rounded-10 flex items-center justify-center cursor-pointer"
                          }
                        >
                          {loadingUpdate ? <Loader /> : "Update"}
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>

              {/* //Delete member */}
            </div>
          )}
        </Grid>
      </div>
      {!deleted && (
        <>
          {" "}
          <div className="user-blank-strip px-1p pb-[.5%]">
            <Grid container>
              <Grid xs={4}>Assets</Grid>
              <Grid xs={2}>Rating</Grid>
              <Grid xs={2}>Position</Grid>
              <Grid xs={4}>Review</Grid>
            </Grid>
          </div>
          <Assets data={data?.assets} researcherId={data?._id} />
        </>
      )}
    </div>
  );
}

export default UserNode;
