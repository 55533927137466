import React from "react";
import { createPortal } from "react-dom";
import TargetInfoLayout from "Layouts/TargetInfoLayout";
import Weakness from "./BugReportSteps/Weakness";
import Severity from "./BugReportSteps/Severity";
import Parameter from "./BugReportSteps/Parameter";
import ProofOfConcept from "./BugReportSteps/ProofOfConcept";
import Sidebar from "./BugReportSidebar/Index";
import { useRef, useState, useEffect } from "react";
import {
  Link,
  unstable_HistoryRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import vulnerabilityReportSlice, {
  addVulnerabilityReportSliceActions,
} from "store/vulnerabilityReport";
import { colorMap } from "components/ui/buttons/SeverityButton";
import ColorDot from "components/ui/buttons/ColorDot";
import SeverityButton from "components/ui/buttons/SeverityButton";
import CommentBox from "./BugReportSidebar/CommentBox";

import GreyBgButton from "components/ui/buttons/GreyBgButton";
import {
  List,
  FormControlLabel,
  Checkbox,
  Modal,
  Avatar,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { cvssStringToObject, isValidCvssVector } from "lib/cvss";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";
import { UserImage } from "components/ui/ImageComponent";
import { blueGradient1 } from "components/tailwindClasses";
import Loader from "components/ui/loaders/Loader";
import { CatchingPokemonSharp } from "@mui/icons-material";

function VulnerabilityReport(props) {
  const location = useLocation();
  const checked = useSelector((state) => state.switch.checked);
  const navigate = useNavigate();

  if (!location?.state?.fromApp) {
    navigate("/dashboard");
  }

  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";

  // Get data from the state
  const bugId = useSelector((state) => state.addVulnerabilityReport.id);
  const reportViewType = useSelector(
    (state) => state.addVulnerabilityReport.reportViewType
  );
  const parentAsset = useSelector(
    (state) => state.addVulnerabilityReport.parentAsset
  );

  // Scroll to the top of the page when the component is mounted
  useEffect(() => {
    // coz link brings it center of page
    window.scrollTo(0, 0);

    // Get saved state from session storage
    let savedState = sessionStorage.getItem("bugReportState");

    // Set the state if there is a saved state
    if (!bugId && savedState) {
      savedState = JSON.parse(savedState);
      if (savedState?.bugId) {
        dispatch(addVulnerabilityReportSliceActions.setId(savedState.bugId));
      }
      if (savedState?.reportViewType) {
        dispatch(
          addVulnerabilityReportSliceActions.setReportViewType(
            savedState.reportViewType
          )
        );
      }
    }
  }, []);

  // Save the state in session storage
  useEffect(() => {
    let toSave = {
      bugId: bugId,
      reportViewType: reportViewType,
      parentAsset: parentAsset,
    };
    sessionStorage.setItem("bugReportState", JSON.stringify(toSave));
  }, [bugId, reportViewType]);

  // Fetch data from the API and set the state
  useEffect(() => {
    const getData = async () => {
      let res;
      if (checked) {
        res = await postFetch("/scanner/getCompanyBugData", { id: bugId });
      } else {
        res = await postFetch(
          reportViewType == "draft" ? "/draft/reportData" : "/bug/reportData",
          { id: bugId }
        );
      }

      // Set the state with the response data
      if (res?.status == "ok") {
        let resData;
        if (checked) {
          resData = res?.data?.data;
        } else {
          resData = res?.data;
        }

        // const resData = res?.data?.data; // responsde data for shared scanner bugs ( correction )
        // const resData = res?.data; // response data for the normal manual security side bugs

        // if severity exists then make it uppercase
        if (resData?.severity) {
          resData.severity =
            resData.severity.slice(0, 1).toUpperCase() +
            resData.severity.slice(1, resData.length).toLowerCase();
        }

        try {
          if (checked) {
            // for scanner bugs
            dispatch(
              addVulnerabilityReportSliceActions.setResearcher({
                name: resData?.researcherName,
                email: resData?.researcherEmail,
                profileImage: "",
                company: resData?.researcherCompany,
              })
            );
          } else {
            // for manual bug reports
            dispatch(
              addVulnerabilityReportSliceActions.setResearcher({
                name: resData?.user_name,
                email: resData?.user_email,
                profileImage: "",
                company: resData?.user_company,
              })
            );
          }

          dispatch(
            addVulnerabilityReportSliceActions.setParent({
              name: resData?.type,
              id: null,
            })
          );

          dispatch(addVulnerabilityReportSliceActions.setChild1(""));
          dispatch(addVulnerabilityReportSliceActions.setChild2(""));
          dispatch(addVulnerabilityReportSliceActions.setName(resData?.name));
          dispatch(addVulnerabilityReportSliceActions.setCwe(resData?.cwe));
          dispatch(addVulnerabilityReportSliceActions.setCve(resData?.cve));
          dispatch(addVulnerabilityReportSliceActions.setType(resData?.type));

          dispatch(addVulnerabilityReportSliceActions.setUrl(resData?.url));
          dispatch(
            addVulnerabilityReportSliceActions.setVulnerableParameter(
              resData?.vulnerable_parameter
            )
          );

          const cvssString = resData?.cvss_vector;

          // Check if the cvss_vector is valid
          /////////  Error prone part : no cvssString available
          let isValidCvssVec;
          if (cvssString) {
            isValidCvssVec = isValidCvssVector(cvssString);
          } else {
            isValidCvssVec = false;
          }

          if (isValidCvssVec) {
            // Valid CVSS vector
            // Do something with the cvss_vector
            const cvssObj = cvssStringToObject(cvssString);

            // dispatch(cvssCalculatorSliceActions.setCvssObject(cvssObj));
            dispatch(addVulnerabilityReportSliceActions.setCvssObject(cvssObj));
          } else {
            // Invalid CVSS vector
            // Handle the error or take appropriate action

            resData?.severity &&
              // dispatch(
              //   cvssCalculatorSliceActions.setMainController(
              //     resData?.severity?.slice(0, 1)
              //   )
              // );
              dispatch(
                addVulnerabilityReportSliceActions.setMainController(
                  resData?.severity?.slice(0, 1)
                )
              );
            // dispatch(cvssCalculatorSliceActions.setCvssObject(null));
            dispatch(addVulnerabilityReportSliceActions.setCvssObject(null));
          }
          //====
          dispatch(
            addVulnerabilityReportSliceActions.setSeverity(resData?.severity)
          );

          dispatch(
            addVulnerabilityReportSliceActions.setDescription(
              resData?.description
            )
          );

          dispatch(
            addVulnerabilityReportSliceActions.setRemediation(
              resData?.remediation
            )
          );
          dispatch(
            addVulnerabilityReportSliceActions.setImpact(resData?.impact)
          );
          dispatch(
            addVulnerabilityReportSliceActions.setReferences(
              resData?.references
            )
          );

          dispatch(
            addVulnerabilityReportSliceActions.setCreatedOn(
              resData?.created_date
            )
          );

          dispatch(
            addVulnerabilityReportSliceActions.setFilesAttached(
              resData?.files_attached || []
            )
          );

          if (resData?.severity) {
            dispatch(
              addVulnerabilityReportSliceActions.setSeverity(resData?.severity)
            );
          }

          if (resData?.status) {
            resData.status =
              resData.status.slice(0, 1).toUpperCase() +
              resData.status.slice(1, resData.length).toLowerCase();

            dispatch(
              addVulnerabilityReportSliceActions.setStatus(resData?.status)
            );
          }
        } catch (e) {
          console.log(e);
        }
      }
    };

    // Call the function if bugId exists
    bugId && getData();
  }, [bugId]);

  let weaknessPosRef = useRef();

  let parameterPosRef = useRef();

  let severityPosRef = useRef();

  let pocPosRef = useRef();

  const notesWeakness = useSelector(
    (state) => state.addVulnerabilityReport.comments.weakness
  );
  const notesSeverity = useSelector(
    (state) => state.addVulnerabilityReport.comments.severity
  );
  const notesParameter = useSelector(
    (state) => state.addVulnerabilityReport.comments.parameter
  );
  const notesPoc = useSelector(
    (state) => state.addVulnerabilityReport.comments.poc
  );

  const containerRef = document.getElementById("bugReportSideComments");

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.data);
  const vulnReportData = useSelector((state) => state.addVulnerabilityReport);

  // state change section

  const [state, setState] = useState("");

  const states = {
    Valid: "Valid",
    Resubmit: "Resubmit",
    Duplicate: "Duplicate",
    Deleted: "Delete",
    Acceptedrisk: "Accepted Risk",
    FalsePositive: "False Positive"
  };

  const handleChange = (event) => {
    setState(event.target.value);
  };
  const [open, setOpen] = useState(false);
  const handleStateChange = async (e) => {
    // if checked then update the status
    if (checked) {
      const statusChangeres = await postFetch(
        "/scanner/companyBug/changeStatus",
        {
          bug_id: bugId,
          newStatus: state,
        }
      );
    }

    setOpen(() => false);
  };

  // for duplicate
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckedChange = (event, value) => {
    if (event.target.checked) {
      if (selectedOptions.length < 4) {
        setSelectedOptions(() => [...selectedOptions, value]);
      } else {
        setSelectedOptions(() => [...selectedOptions.slice(0, 3), value]);
      }
    } else {
      setSelectedOptions(() =>
        selectedOptions.filter((item) => item !== value)
      );
    }
  };
  const handleLabelClick = (value) => {
    if (selectedOptions.includes(value)) {
      setSelectedOptions(() =>
        selectedOptions.filter((option) => option !== value)
      );
    } else {
      setSelectedOptions(() => [...selectedOptions, value]);
    }
  };

  // for update

  const [stateChangeComment, setStateChangeComment] = useState("");

  const [updateLoading, setUpdateLoading] = useState(false);

  const handleUpdateClick = async (e, isDraft = false) => {
    e.preventDefault();

    const bugReportForm = document.getElementById("bugReportForm9034");

    if (!(bugReportForm && bugReportForm.checkValidity())) {
      bugReportForm.reportValidity();
      return;
    }

    const dataPost = { ...vulnReportData };

    // dataPost["parent_asset"] = currentTarget;
    dataPost["user_id"] = user.id;
    dataPost["lastModifiedByUser"] = "" + Date.now();
    dataPost["summary"] = "random summary";

    if (!dataPost?.type) {
      toast.warning("Please select a valid weakness");
      return;
    } else if (!dataPost?.severity) {
      toast.warning("Please provide the severity");
      return;
    } else if (!dataPost?.description) {
      toast.warning("Please provide the description");
      return;
    }
    //  else if (!dataPost?.url) {
    //   toast.warning("Please provide a valid url");
    //   return;
    // }

    setUpdateLoading(() => true);

    try {
      
      let updateRes = await postFetch(
        isDraft ? "/draft/update" : checked ?   "/scanner/companyBug/update" : "/bug/update",
        {
          bug_id: dataPost.id,
          name: dataPost.name,
          type: dataPost.type,
          user_id: dataPost.user_id,
          severity: dataPost.severity,
          url: dataPost.url,
          cwe: dataPost.cwe,
          cve: dataPost.cve,
          cvss: dataPost.cvss,
          description: dataPost.description,
          lastModifiedByUser: dataPost.lastModifiedByUser,
          cvss_vector: dataPost.cvss_vector,
          vulnerable_parameter: dataPost.vulnerable_parameter,
          summary: dataPost.summary,
          impact: dataPost.impact,
          remediation: dataPost.remediation,
          references: dataPost.references,
          files_attached: dataPost.filesAttached,
          status: state,
        }
      );

      if (updateRes?.status == "ok") {
        toast.success(isDraft ? "Draft updated" : "Bug updated ");
      } else if (updateRes?.message && updateRes?.error) {
        toast.error(updateRes.message);
      } else {
        toast.error("Some error updating, try later !");
      }
    } catch (e) {
      toast.error("Some error updating, try later !");
    }

    setUpdateLoading(() => false);

    if (!Object.keys(states).includes(state)) {
      return;
    }

    setUpdateLoading(() => true);

    try {
      const bug_id = vulnReportData.id;

      // let url = "/bug/changeStatus";

      let res;
      // if (state == "Duplicate") {
      //   res = await postFetch("/bug/markDuplicate", {
      //     bug_id,
      //     similar_list: selectedOptions,
      //   });
      // } else {
      // res = await postFetch(url, {
      //   bug_id,
      //   newStatus: state,
      //   comment: stateChangeComment,
      // });
      // }

      // if (res.status == "ok") {
      //   toast.success("Bug Updated To : " + state);
      //   navigate("/target-Dashboard", { state: { fromApp: true } });
      // } else {
      //   if (res.error == true) {
      //     toast.error(res.message);
      //   } else toast.error("Some error occured, try later !");
      // }
    } catch (e) {
      toast.error("Some error occured, try later !");
    }

    setUpdateLoading(() => false);
  };

  //

  const currentTarget = useSelector(
    (state) => state.targetDashboard.currentTarget.id
  );

  // to mark duplicate , listing
  const bugs = useSelector((state) => state.targetDashboard.bugs);

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmitClick = (e, isDraft = false, draftToBug = false) => {
    e.preventDefault();

    setSubmitLoading(() => true);

    const bugReportForm = document.getElementById("bugReportForm9034");

    if (bugReportForm && bugReportForm.checkValidity()) {
      handleFormSubmit(isDraft, draftToBug);
    } else {
      bugReportForm.reportValidity();
    }

    setSubmitLoading(() => false);
  };
  const handleFormSubmit = async (isDraft, draftToBug) => {
    const dataPost = { ...vulnReportData };

    dataPost["parent_asset"] = currentTarget;
    dataPost["user_id"] = user.id;
    dataPost["lastModifiedByUser"] = "" + Date.now();
    dataPost["summary"] = "random summary";

    // if (!dataPost?.name) {
    //   toast.warning("Please fill out the title of bug");
    //   return;
    // }
    if (!dataPost?.type) {
      toast.warning("Please select a valid weakness");
      return;
    } else if (!dataPost?.severity) {
      toast.warning("Please provide the severity");
      return;
    }
    //  else if (!dataPost?.url) {
    //   toast.warning("Please provide a valid url");
    //   return;
    // }
    else if (!dataPost?.parent_asset) {
      toast.error("Could not determine parent asset, try later");
      return;
    }
    // else if (!dataPost?.vulnerable_parameter) {
    //   toast.error("Please provide a vulnerable parameter");
    //   return;
    // }

    const res = await postFetch(
      isDraft
        ? draftToBug
          ? "/draft/moveToBug"
          : "/draft/create"
        : "/bug/create",
      dataPost
    );

    if (res?.status == "ok") {
      toast.success(
        isDraft
          ? draftToBug
            ? "Bug Created !"
            : "Draft Created !"
          : "Bug Created !"
      );

      dispatch(addVulnerabilityReportSliceActions.reset(""));
      // dispatch(cvssCalculatorSliceActions.setCvssObject({}));
      dispatch(addVulnerabilityReportSliceActions.setCvssObject({}));

      dispatch(targetDashboardSliceActions.setCurrentSection(2));
      navigate("/target-Dashboard", { state: { fromApp: true } });
    } else if (res?.message && res?.error) {
      toast.error(res.message);
    } else {
      toast.error("Some error occured, try later !");
    }
  };

  //
  const [searchMarkDuplicate, setSearchMarkDuplicate] = useState("");

  let filteredBugs = bugs.filter((item) =>
    item.title.toLowerCase().includes(searchMarkDuplicate.toLowerCase())
  );

  return (
    <form action="" aria-disabled id="bugReportForm9034">
      <div className="p-1p  flex">
        <div className="w-4/5  grow">
          <TargetInfoLayout goBack={true}>
            <div className="">
              <div>
                <div className="flex mt-1p">
                  <div className="font-bold text-xl dark:text-white">
                    {reportViewType?.toLowerCase() == "create" &&
                      "Submit Vulnerability Report"}
                    {/* {reportViewType?.toLowerCase() == "edit" &&
                    "Edit Vulnerability Report"}
                  {reportViewType?.toLowerCase() == "update" &&
                    "Update Vulnerability Report"} */}
                  </div>
                  {reportViewType?.toLowerCase() == "create" && (
                    <Tooltip
                      placement="right"
                      describeChild
                      title="You’re about to submit a report to Hiro. Provide as much
                      information as possible about the potential issue you have
                      discovered. The more information youprovide, the quicker
                      Hiro will be able to validate the issue."
                    >
                      <img
                        src={isDarkTheme ? "/infoDark.svg" : "/info.svg"}
                        alt=""
                        className="w-5"
                        style={{ marginLeft: "10px" }}
                      />
                    </Tooltip>
                  )}
                </div>
                {(reportViewType?.toLowerCase() == "edit" ||
                  reportViewType?.toLowerCase() == "update") && (
                  <div className="bg-white dark:bg-lightBlack dark:text-white rounded-10 mt-1p text-sm pl-2p pr-3p py-1p">
                    <div className="mt-1">
                      <span className="mr-2 font-medium">Created On : </span>
                      <span className="text-sm font-semibold">
                        {new Date(vulnReportData?.createdOn).toDateString()}
                      </span>
                    </div>

                    <div className="flex justify-between mt-1p">
                      <div className="flex flex-col">
                        <div className="font-medium">Status</div>
                        <div className="mt-4">
                          <ColorDot type={vulnReportData?.status} />
                          <span className="ml-2">{vulnReportData?.status}</span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="font-medium">Title</div>
                        <div className="mt-4">{vulnReportData?.name}</div>
                      </div>
                      <div className="flex flex-col">
                        <div className="font-medium">Type</div>
                        <div className="mt-4">
                          <div className="bg-greyBg3 dark:bg-lightBlack3 h-7 px-2 text-center font-semibold text-sm flex justify-center items-center rounded">
                            {vulnReportData?.type?.length > 40
                              ? vulnReportData.type.slice(0, 40) + " .."
                              : vulnReportData.type}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="font-medium">Severity</div>
                        <div className="mt-4">
                          {SeverityButton(vulnReportData?.severity)}
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="font-medium">Team Member</div>
                        <div className="mt-2">
                          <UserImage
                            company={vulnReportData?.researcher?.company}
                            email={vulnReportData?.researcher?.email}
                            image={vulnReportData?.researcher?.profileImage}
                            name={vulnReportData?.researcher?.name}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-1p" ref={weaknessPosRef}>
                <Weakness />
                {containerRef &&
                  weaknessPosRef?.current &&
                  createPortal(
                    <>
                      <div
                        className="absolute w-full p-1p "
                        style={{
                          top:
                            weaknessPosRef?.current?.getBoundingClientRect()
                              .top - containerRef?.getBoundingClientRect()?.top,
                          left: 0,
                          maxHeight:
                            weaknessPosRef?.current?.getBoundingClientRect()
                              .height,
                          overflowY: "scroll",
                        }}
                      >
                        {notesWeakness.map((noteElement, index) => (
                          <div key={index} className="mb-2">
                            <CommentBox
                              text={noteElement?.comment}
                              createdOn={noteElement?.createdOn}
                              commentType=""
                              isCreator={noteElement?.isCreator}
                              personName={noteElement?.personName}
                              id={noteElement.id}
                              addComment={(comment) => {
                                dispatch(
                                  addVulnerabilityReportSliceActions.setWeaknessComment(
                                    {
                                      comment: comment,
                                      id: noteElement.id,
                                      createdOn: new Date().toDateString(),
                                    }
                                  )
                                );
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </>,
                    containerRef
                  )}{" "}
              </div>
              <div className="mt-1p" ref={severityPosRef}>
                <Severity />
                {containerRef &&
                  severityPosRef?.current &&
                  createPortal(
                    <>
                      <div
                        className="absolute w-full p-1p "
                        style={{
                          top:
                            severityPosRef?.current?.getBoundingClientRect()
                              .top - containerRef?.getBoundingClientRect()?.top,
                          left: 0,
                          maxHeight:
                            severityPosRef?.current?.getBoundingClientRect()
                              .height,
                          overflowY: "scroll",
                        }}
                      >
                        {notesSeverity.map((noteElement, index) => (
                          <div key={index} className="mb-2">
                            <CommentBox
                              text={noteElement?.comment}
                              createdOn={noteElement?.createdOn}
                              commentType=""
                              isCreator={noteElement?.isCreator}
                              personName={noteElement?.personName}
                              id={noteElement.id}
                              addComment={(comment) => {
                                dispatch(
                                  addVulnerabilityReportSliceActions.setSeverityComment(
                                    {
                                      comment: comment,
                                      id: noteElement.id,
                                      createdOn: new Date().toDateString(),
                                    }
                                  )
                                );
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </>,
                    containerRef
                  )}{" "}
              </div>
              <div className="mt-1p" ref={parameterPosRef}>
                <Parameter />
                {containerRef &&
                  parameterPosRef?.current &&
                  createPortal(
                    <>
                      <div
                        className="absolute w-full p-1p "
                        style={{
                          top:
                            parameterPosRef?.current?.getBoundingClientRect()
                              .top - containerRef?.getBoundingClientRect()?.top,
                          left: 0,
                          maxHeight:
                            parameterPosRef?.current?.getBoundingClientRect()
                              .height,
                          overflowY: "scroll",
                        }}
                      >
                        {notesParameter.map((noteElement, index) => (
                          <div key={index} className="mb-2">
                            <CommentBox
                              text={noteElement?.comment}
                              createdOn={noteElement?.createdOn}
                              commentType=""
                              isCreator={noteElement?.isCreator}
                              personName={noteElement?.personName}
                              id={noteElement.id}
                              addComment={(comment) => {
                                dispatch(
                                  addVulnerabilityReportSliceActions.setParameterComment(
                                    {
                                      comment: comment,
                                      id: noteElement.id,
                                      createdOn: new Date().toDateString(),
                                    }
                                  )
                                );
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </>,
                    containerRef
                  )}{" "}
              </div>
              <div className="mt-1p" ref={pocPosRef}>
                <ProofOfConcept />
                {containerRef &&
                  pocPosRef?.current &&
                  createPortal(
                    <>
                      <div
                        className="absolute w-full p-1p "
                        style={{
                          top:
                            pocPosRef?.current?.getBoundingClientRect().top -
                            containerRef?.getBoundingClientRect()?.top,

                          left: 0,
                          maxHeight:
                            pocPosRef?.current?.getBoundingClientRect()?.height,
                          overflowY: "scroll",
                        }}
                      >
                        {notesPoc.map((noteElement, index) => (
                          <div key={index} className="mb-2">
                            <CommentBox
                              text={noteElement?.comment}
                              createdOn={noteElement?.createdOn}
                              commentType=""
                              isCreator={noteElement?.isCreator}
                              personName={noteElement?.personName}
                              id={noteElement.id}
                              addComment={(comment) => {
                                dispatch(
                                  addVulnerabilityReportSliceActions.setPocComment(
                                    {
                                      comment: comment,
                                      id: noteElement.id,
                                      createdOn: new Date().toDateString(),
                                      personName: "You",
                                    }
                                  )
                                );
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </>,
                    containerRef
                  )}{" "}
              </div>

              <div className="bg-white  dark:bg-lightBlack  dark:text-white  p-2p mt-1p rounded-10">
                {reportViewType == "create" && (
                  <>
                    <div>
                      <div className="text-lg font-bold">
                        Submit your report
                      </div>
                      <div>
                        By clicking ‘Submit Report’, you agree to our{" "}
                        <Link className="no-underline">
                          Terms and Conditions
                        </Link>{" "}
                        and acknowledge that you have read our{" "}
                        <Link className="no-underline">Code of Conduct</Link>,
                        <Link className="no-underline"> Privacy Policy </Link>
                        and{" "}
                        <Link className="no-underline">
                          {" "}
                          Disclosure Guidelines
                        </Link>
                        .
                      </div>
                    </div>
                    <div className="mt-6 flex justify-end">
                      <div className="flex ">
                        <div className="mr-2">
                          <button
                            disabled={submitLoading}
                            onClick={(e) => {
                              handleSubmitClick(e, true);
                            }}
                            className="bg-greyBg3 dark:text-blue1 h-15 w-60 text-center font-bold flex justify-center items-center rounded border-2 border-[#303E55]"
                          >
                            {submitLoading ? <Loader /> : "Create Draft"}
                          </button>
                        </div>
                        <div className="w-60">
                          <button
                            disabled={submitLoading}
                            onClick={handleSubmitClick}
                            className={
                              "w-full flex items-center justify-center text-base font-bold text-white h-15  rounded-10 " +
                              blueGradient1
                            }
                          >
                            {submitLoading ? <Loader /> : "Submit Report"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {reportViewType == "draft" && (
                  <>
                    <div>
                      <div className="text-lg font-bold">
                        Submit your report
                      </div>
                      <div>
                        By clicking ‘Submit Report’, you agree to our{" "}
                        <Link className="no-underline">
                          Terms and Conditions
                        </Link>{" "}
                        and acknowledge that you have read our{" "}
                        <Link className="no-underline">Code of Conduct</Link>,
                        <Link className="no-underline"> Privacy Policy </Link>
                        and{" "}
                        <Link className="no-underline">
                          {" "}
                          Disclosure Guidelines
                        </Link>
                        .
                      </div>
                    </div>
                    <div className="mt-6 flex justify-end">
                      <div className="flex ">
                        <div className="mr-2">
                          <button
                            disabled={updateLoading}
                            onClick={(e) => {
                              handleUpdateClick(e, true);
                            }}
                            className="bg-greyBg3 dark:text-blue1 h-15 w-60 text-center font-bold flex justify-center items-center rounded border-2 border-[#303E55]"
                          >
                            {updateLoading ? <Loader /> : "Update Draft"}
                          </button>
                        </div>
                        <div className="w-60">
                          <button
                            disabled={submitLoading}
                            onClick={(e) => {
                              handleSubmitClick(e, true, true);
                            }}
                            className={
                              blueGradient1 +
                              " flex items-center justify-center cursor-pointer w-60 rounded-10 h-15 font-bold"
                            }
                          >
                            {submitLoading ? <Loader /> : "Submit Report"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {(reportViewType == "update" || reportViewType == "edit") && (
                  <div>
                    <div className="flex justify-end">
                      {reportViewType == "update" && (
                        <MenuItem
                          sx={{
                            width: "100%",
                            height: "60px",
                            backgroundColor: "#D9D9D940",
                            border: "0px solid rgba(0, 0, 0, 0.25)",
                            borderRadius: "5px",
                            border: "1px solid rgba(0, 0, 0, .1)",
                          }}
                          onClick={() => {
                            setOpen(() => true);
                          }}
                          className="flex justify-between items-center grow"
                          // className="border-b1"
                        >
                          <div className="grow">
                            <em>
                              {state ? (
                                <span>
                                  <span className="mr-4">
                                    <ColorDot type={state} />
                                  </span>
                                  {state}
                                </span>
                              ) : (
                                "change state"
                              )}
                            </em>
                          </div>
                          <div>
                            <img src="/downArrow.svg" alt="" />
                          </div>
                        </MenuItem>
                      )}
                      <div className=" flex ml-1p">
                        <div className="flex ">
                          <div className="mr-2">
                            <div
                              onClick={() => {
                                navigate(-1);
                              }}
                              className="cursor-pointer dark:text-black bg-greyBg3 h-15 w-60 text-center font-bold flex justify-center items-center rounded border-2 border-[#303E55]"
                            >
                              Cancel
                            </div>
                          </div>
                          <div className="w-60">
                            <button
                              disabled={updateLoading}
                              onClick={handleUpdateClick}
                              className={
                                blueGradient1 +
                                " flex items-center justify-center cursor-pointer w-60 rounded-10 h-15 font-bold"
                              }
                            >
                              {updateLoading ? <Loader /> : "Update"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Modal
                      open={open}
                      // onClose={() => {
                      //   setOpen(() => false);
                      // }}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <div className="w-[800px]  bg-white dark:bg-lightBlack dark:text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-10">
                        <div className="text-xl font-bold text-center mt-2p mb-1p">
                          Change State
                        </div>
                        <div className="bg-lightBlue dark:bg-[#3B3E4E] text-sm p-2p pr-3p">
                          <div className="mt-1">
                            <span className="mr-2 font-medium">
                              Created On :{" "}
                            </span>
                            <span>
                              {new Date(
                                vulnReportData?.createdOn
                              ).toDateString()}
                            </span>
                          </div>

                          <div className="flex justify-between mt-1p">
                            <div className="flex flex-col">
                              <div className="font-medium">Status</div>
                              <div className="mt-4">
                                <ColorDot type={vulnReportData?.status} />
                                <span className="ml-2">
                                  {vulnReportData?.status}
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div className="font-medium">Title</div>
                              <div className="mt-4">{vulnReportData?.name}</div>
                            </div>
                            <div className="flex flex-col">
                              <div className="font-medium">Type</div>
                              <div className="mt-4">
                                <div className="bg-greyBg3 dark:bg-lightBlack4 h-7 w-24 text-center font-semibold text-sm flex justify-center items-center rounded">
                                  {vulnReportData?.type?.length > 5
                                    ? vulnReportData.type.slice(0, 5) + " .."
                                    : vulnReportData.type}
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div className="font-medium">Severity</div>
                              <div className="mt-4">
                                {SeverityButton(vulnReportData?.severity)}
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <div className="font-medium">Team Member</div>
                              <div className="mt-2">
                                <Avatar
                                  alt={vulnReportData?.name}
                                  src="/avatar2.svg"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="mx-2p pt-2p ">
                            <Select
                              sx={{
                                width: "100%",
                                height: "45px",
                                border: "0px solid rgba(0, 0, 0, 0.25)",
                                borderRadius: "5px",
                              }}
                              className="bg-[#D9D9D940] dark:bg-lightBlack4 dark:text-white"
                              value={state}
                              onChange={handleChange}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              required
                            >
                              <MenuItem value="">
                                <em>Change State</em>
                              </MenuItem>

                              {Object.keys(states)
                                .filter(
                                  (s) =>
                                    s?.toLowerCase?.() !=
                                    vulnReportData?.status?.toLowerCase?.()
                                )
                                ?.map((item, i) => {
                                  return (
                                    <MenuItem value={item}>
                                      <span className="mr-4">
                                        <ColorDot type={item} />
                                      </span>
                                      {states[item]}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </div>
                          {/* {state == "Duplicate" && (
                            <div>
                              <div
                                style={{
                                  background: " rgba(217, 217, 217, 0.25)",
                                }}
                                className="my-2 px-2p py-3 text-sm font-medium flex justify-between"
                              >
                                <div> Duplicate Bug List</div>
                                <div className="opacity-50 mr-2">
                                  Maximum Selections : 4
                                </div>
                              </div>
                              <div className="h-9 p-4 mx-2p text-sm border border-b2 rounded bg-inputBg flex items-center justify-start">
                                <div className="mr-2">
                                  <img src="/searchIcon.svg" alt="" />
                                </div>
                                <input
                                  type="text"
                                  className="bg-transparent focus:outline-none grow"
                                  placeholder="Search"
                                  value={searchMarkDuplicate}
                                  onChange={(e) => {
                                    setSearchMarkDuplicate(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="mx-2p">
                                <List className="max-h-60 overflow-y-scroll overflow-x-hidden">
                                  {filteredBugs.map((option, index) => {
                                    let id = option.id;
                                    return (
                                      <div>
                                        <FormControlLabel
                                          key={index}
                                          control={
                                            <Checkbox
                                              checked={selectedOptions.includes(
                                                id
                                              )}
                                              onChange={(event) =>
                                                handleCheckedChange(event, id)
                                              }
                                              value={id}
                                              sx={{
                                                marginRight: "20px",
                                                display: " flex",
                                              }}
                                            />
                                          }
                                          sx={{
                                            padding: "10px",
                                            borderBottom:
                                              "1px solid rgba(0, 0, 0, .1)",
                                            width: "100%",
                                            boxSizing: "border-box",
                                            display: "flex",
                                          }}
                                          label={
                                            <div
                                              onClick={() =>
                                                handleLabelClick(id)
                                              }
                                              className="font-medium text-sm flex w-full  items-center justify-between "
                                            >
                                              <div className="w-32 ">
                                                <span className="mr-2">
                                                  <ColorDot
                                                    type={option?.status}
                                                  />
                                                </span>
                                                {option?.status}
                                              </div>
                                              <div className="w-52 mx-2 grow">
                                                {option?.title}
                                              </div>
                                              <div className="mx-2 w-32 bg-greyBg3 h-7 px-1p text-center font-semibold text-sm flex justify-center items-center rounded">
                                                {option?.type.slice(0, 15)}
                                              </div>
                                              <div className="w-32 mx-2">
                                                {SeverityButton(
                                                  option?.severity
                                                )}
                                              </div>
                                              <div className="w-10 h-10 rounded-full bg-lightGreen flex items-center justify-center">
                                                {option?.similar}%
                                              </div>
                                            </div>
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                </List>
                              </div>
                            </div>
                          )} */}
                          <div className="mx-2p pb-2">
                            {state != "" && state != "Duplicate" && (
                              <div>
                                <div className="my-2 font-medium">Comment</div>
                                <textarea
                                  value={stateChangeComment}
                                  onChange={(e) => {
                                    setStateChangeComment(() => e.target.value);
                                  }}
                                  required
                                  id=""
                                  name=""
                                  rows="4"
                                  style={{
                                    resize: "none",
                                    width: "100%",
                                    boxSizing: "border-box",
                                    padding: "2%",
                                    borderRadius: "10px",
                                    fontSize: "16px",
                                  }}
                                  className="bg-inputBg dark:bg-lightBlack4 border border-b4 dark:border-b9"
                                  placeholder="Add a comment"
                                />
                              </div>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                            className="border-t-2 border-b2 dark:border-b9 mx-2p py-2p"
                          >
                            <button
                              style={{
                                // marginTop: "3.2%",
                                textDecoration: "none",
                                color: "#2D3B51 !important",
                                marginRight: "5vw",
                                fontWeight: "500",
                              }}
                              onClick={() => {
                                setState(() => "");
                                setOpen(() => false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className={
                                blueGradient1 +
                                " flex items-center justify-center cursor-pointer w-60 rounded-10 h-15 font-bold"
                              }
                              onClick={handleStateChange}
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                )}
              </div>
            </div>
          </TargetInfoLayout>
        </div>
        {(reportViewType == "edit" || reportViewType == "update") && (
          <div className="w-1/5 ml-1p bg-white rounded-10">
            <Sidebar
            // weaknessPos={weaknessPos}
            // severityPos={severityPos}
            // parameterPos={parameterPos}
            // pocPos={pocPos}
            />
          </div>
        )}
      </div>
    </form>
  );
}

export default VulnerabilityReport;
