import React from "react";
import { Avatar } from "@mui/material";
import { TextareaAutosize } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { addVulnerabilityReportSliceActions } from "store/vulnerabilityReport";
import { useEffect } from "react";
import { postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { UserImage } from "components/ui/ImageComponent";

function CommentBox({
  text,
  type,
  commentRef,
  addComment,
  section,
  id,
  createdOn,
  isCreator,
  personName,
  personImage,
}) {
  const isDarkMode = useSelector((state) => state.general.theme) == "dark";
  const user = useSelector((state) => state.user.data);
  const bugId = useSelector((state) => state.addVulnerabilityReport.id);
  const checked = useSelector((state) => state.switch.checked);
  // for adding comment
  let [comment, setComment] = useState("");

  let [commentType, setCommentType] = useState("");

  useEffect(() => {
    setCommentType(type || "");
  }, [type]);

  let handleAddComment = async (e) => {
    if (comment == "") return;

    let addCommentURL = checked
      ? "/scanner/companyBug/addComment"
      : "/bug/addComment";

    const res = await postFetch(addCommentURL, {
      content: comment,
      bug_id: bugId,
      corresponding_section: section,
    });

    if (res?.status == "ok") {
      res?.data?._id &&
        addComment(comment, res.data?._id, res?.data?.createdDate, true);

      commentRef.current.close();
    } else {
      toast.error("couldn't comment, try later !");
    }
  };

  let handleAddCancelClick = () => {
    commentRef.current.close();
  };

  // for handling added comment
  const dispatch = useDispatch();

  const [hover, setHover] = useState(false);

  const handleEditClick = () => {
    // this finds existign comment and edits it
    setComment(() => text);
    setCommentType("edit");
  };

  const handleDeleteClick = async () => {
    // http://localhost:3000/api/scanner/companyBug/deleteComment
    let deleteCommentURL = checked
      ? "/scanner/companyBug/deleteComment"
      : "/bug/deleteComment";
    const res = await postFetch(deleteCommentURL, { comment_id: id });

    if (res.status == "ok") {
      addComment("", id);
      setCommentType("");
    } else toast.error("error deleting comment, try later !");
  };

  const handleEditCancel = () => {
    setCommentType("");
  };
  const handleDoneClick = async () => {
    if (comment == "") return;
    // http://localhost:3000/api/scanner/companyBug/editComment

    let editCommentURL = checked
      ? "/scanner/companyBug/editComment"
      : "/bug/editComment";
    const res = await postFetch(editCommentURL, {
      comment_id: id,
      content: comment,
    });

    if (res?.status == "ok") {
      addComment(comment, id);
      setCommentType("");
    }
  };

  return (
    <div
      className="shadow rounded-10 bg-gray-100 dark:bg-lightBlack dark:text-white  p-4 w-full hover:shadow-lg"
      onMouseOver={() => {
        setHover(() => true);
      }}
      onMouseOut={() => {
        setHover(() => false);
      }}
    >
      <div className=" flex justify-between items-center">
        <div className="flex">
          <div className=" flex mr-2 h-fit">
            {commentType == "input" ? (
              <UserImage
                name={user?.name}
                style={{ width: "40px", height: "40px" }}
              />
            ) : (
              <UserImage
                name={personName}
                style={{ width: "40px", height: "40px" }}
              />
            )}
          </div>

          <div className="flex flex-col">
            <div className="inline text-sm font-medium">
              {commentType == "input"
                ? "You"
                : isCreator
                ? "You"
                : personName || "Someone"}
            </div>
            <div className="text-xs">
              {commentType != "input" &&
                createdOn &&
                new Date(createdOn)?.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              {commentType != "input" && createdOn && (
                <>
                  ,{" "}
                  {new Date(createdOn)?.toLocaleDateString([], {
                    month: "short",
                    day: "numeric",
                  })}
                </>
              )}
              {commentType == "input" &&
                new Date()?.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              {commentType == "input" && (
                <>
                  ,{" "}
                  {new Date()?.toLocaleDateString([], {
                    month: "short",
                    day: "numeric",
                  })}
                </>
              )}
            </div>
          </div>
        </div>
        {hover && commentType != "input" && isCreator && (
          <div className="flex">
            <img
              src={isDarkMode ? "/editPenDark.svg" : "/editPen.svg"}
              alt=""
              className="mr-4 w-4 cursor-pointer"
              onClick={handleEditClick}
            />
            <img
              src="/bin.svg"
              alt=""
              className="w-4 cursor-pointer"
              onClick={handleDeleteClick}
            />
          </div>
        )}
      </div>
      <div className="mt-1 text-sm ">
        {commentType == "input" || commentType == "edit" ? (
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Add Comment"
            value={comment}
            autoFocus
            maxLength={300}
            onChange={(e) => {
              setComment(() => e.target.value);
            }}
            className={
              commentType == "edit"
                ? "p-1 w-full overflow-hidden dark:text-black"
                : "p-1 w-72 dark:text-black"
            }
          />
        ) : (
          <span className="break-words">{text}</span>
        )}
      </div>
      {commentType == "input" && (
        <div className="flex mt-2 cursor-pointer ">
          <div
            onClick={handleAddComment}
            className="w-1/2 flex cursor-pointer items-center justify-center text-sm font-semibold text-white h-8 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10"
          >
            Comment
          </div>
          <div
            onClick={handleAddCancelClick}
            className="w-1/2 flex cursor-pointer items-center justify-center text-sm font-semibold text-[#303e55] dark:text-white h-8 rounded-10"
          >
            Cancel
          </div>
        </div>
      )}
      {commentType == "edit" && (
        <div className="flex mt-2 cursor-pointer ">
          <div
            onClick={handleDoneClick}
            className="w-1/2 cursor-pointer flex items-center justify-center text-sm font-semibold text-white h-8 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10"
          >
            Done
          </div>
          <div
            onClick={handleEditCancel}
            className="w-1/2 cursor-pointer flex items-center justify-center text-sm font-semibold text-[#303e55] dark:text-white h-8 rounded-10"
          >
            Cancel
          </div>
        </div>
      )}
    </div>
  );
}

export default CommentBox;
