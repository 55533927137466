import React, { useEffect } from "react";
import InfoCards from "./InfoCards";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Select } from "@mui/material";
import { dashboardInfoSliceActions } from "store/dashboardInfoSlice";
import { dashboardSliceActions } from "store/scanner/dashboardSlice";

const timeOptions = [
  { id: "today", title: "Today" },
  { id: "week", title: "This Week" },
  { id: "month", title: "This Month" },
  { id: "lastMonth", title: "Last Month" },
];

function Index(props) {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.general.theme) == "dark";

  const dashboardData = useSelector((state) => state.dashboardInfo);
  const targets = useSelector((state) => state.dashboardInfo.assets);

  const timeOption = useSelector((state) => state.scanner.dashboard.timeOption);

  useEffect(() => {
    !timeOption && dispatch(dashboardSliceActions.setTimeOption("week"));
  }, []);

  const currentTarget = useSelector(
    (state) => state.dashboardInfo.currentAsset
  );
  let handleTargetChange = (e) => {
    let curTarget = e.target?.value;

    // console.log(curTarget);

    dispatch(dashboardInfoSliceActions.setCurrentAsset());

    if (curTarget == "all") {
      dispatch(
        dashboardInfoSliceActions.setCurrentAsset({
          id: "all",
          title: "All",
        })
      );

      dispatch(
        dashboardSliceActions.setChartsData({
          critical: dashboardData?.critical,
          high: dashboardData?.high,
          medium: dashboardData?.medium,
          low: dashboardData?.low,
          new: dashboardData?.new,
          resolve: dashboardData?.resolve,
          triaged: dashboardData?.triaged,
          informative: dashboardData?.informative,
          duplicate: dashboardData?.duplicate,
          risk: dashboardData?.risk,
          performance: dashboardData?.performance,
        })
      );

      return;
    }

    const findCurrentAsset = targets.find((a) => a?.id == curTarget);

    /**
     * {"id":"64c7f0e47ca3fd41304a3d6c",
     * "title":"2-g",
     * "description":"desc2",
     * "asset_vuln_count":1,
     * "critical_vuln_count":0,
     * "high_vuln_count":0,
     * "medium_vuln_count":1,
     * "low_vuln_count":0,
     * "new_vuln_count":1,
     * "informative_vuln_count":0,
     * "triaged_vuln_count":0,
     * "duplicate_vuln_count":0,
     * "resolve_vuln_count":0,
     * "risk_score":5,
     * "website_url":"https://atom-installer.github.com",
     * "has_generated_report":false
     * }
     */

    if (!findCurrentAsset) return;

    dispatch(
      dashboardInfoSliceActions.setCurrentAsset({
        id: findCurrentAsset?.id,
        title: findCurrentAsset?.title,
      })
    );

    dispatch(
      dashboardInfoSliceActions.setChartsData({
        critical: findCurrentAsset?.critical_vuln_count,
        high: findCurrentAsset?.high_vuln_count,
        medium: findCurrentAsset?.medium_vuln_count,
        low: findCurrentAsset?.low_vuln_count,
        new: findCurrentAsset?.new_vuln_count,
        resolve: findCurrentAsset?.resolve_vuln_count,
        triaged: findCurrentAsset?.triaged_vuln_count,
        informative: findCurrentAsset?.informative_vuln_count,
        duplicate: findCurrentAsset?.duplicate_vuln_count,
        risk: findCurrentAsset?.risk_score,
        performance: findCurrentAsset?.user_performance,
      })
    );
  };

  const handleTimeOptionChange = (e) => {
    dispatch(dashboardSliceActions.setTimeOption(e.target.value));
  };

  return (
      
    <>
      
      <InfoCards />
      <div className="mb-1p mt-5 flex">

        <div className="w-1/2 mr-[.5%]">
         {/* <Select
            sx={{
              width: "100%",
              borderRadius: "4px",
            }}
            className="dark:text-white h-12 "
            MenuProps={{
              PaperProps: { sx: { maxHeight: 300 } },
            }}
            onChange={handleTargetChange}
            value={currentTarget?.id}
            defaultValue="all"
          >
            <MenuItem value="all" key={-1} className="dark:bg-lightBlack">
              All
            </MenuItem>
            {targets.map((target, i) => {
              return (
                <MenuItem key={i} value={target?.id} className="h-12">
                  {target?.title}
                </MenuItem>
              );
            })}
          </Select>*/}
        </div>{" "}
        <div className="w-1/2 ml-[.5%]">
      {/*  <Select
            sx={{
              width: "100%",
              borderRadius: "4px",
            }}
            className="h-12"
            MenuProps={{
              PaperProps: { sx: { maxHeight: 300 } },
            }}
            onChange={handleTimeOptionChange}
            value={timeOption}
          >
            {timeOptions.map((t, i) => {
              return (
                <MenuItem key={i} value={t.id}>
                  <div className="flex items-center">
                    {t.id == timeOption && (
                      <img
                        src={isDarkMode ? "/calendarDark.svg" : "/calendar.svg"}
                        alt=""
                        className="inline mr-2"
                      />
                    )}

                    <span>{t.title}</span>
                  </div>
                </MenuItem>
              );
            })}
          </Select>*/}
        </div>{" "}
      </div>
    </>
  );
}

export default Index;
