import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,

  totalAssets: 0,
  totalVulnerabilities: 0,
  critical: 0,
  high: 0,
  medium: 0,
  low: 0,
  new: 0,
  informative: 0,
  triaged: 0,
  resolve: 0,
  duplicate: 0,

  risk: 0,
  gauge: 0,
  performance: 0,

  timeOption: null, // fot weekly etc

  chartData: {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    new: 0,
    informative: 0,
    triaged: 0,
    resolve: 0,
    duplicate: 0,
    risk: 0,
    performance: 0,
    gauge: 0,
    performance: 0,
  },

  topVulnerabilities: [],
  topCve: [],

  currentAsset: {},

  //
  cweGraphData: [],
  owaspGraphData: [],
  mainGraphData: [],
};

let dashboardSlice = createSlice({
  name: "scannerDashboardSlice",
  initialState: initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setTotalAssets(state, action) {
      console.log(action.payload);
      state.totalAssets = action.payload;
    },
    setTotalVulnerabilities(state, action) {
      state.data.totalVulnerabilities = action.payload;
    },
    setCritical(state, action) {
      state.chartData.critical = action.payload;
    },
    setHigh(state, action) {
      state.chartData.high = action.payload;
    },
    setMedium(state, action) {
      state.chartData.medium = action.payload;
    },
    setLow(state, action) {
      state.chartData.low = action.payload;
    },
    setNew(state, action) {
      state.chartData.new = action.payload;
    },
    setInformative(state, action) {
      state.chartData.informative = action.payload;
    },
    setTriaged(state, action) {
      state.chartData.triaged = action.payload;
    },
    setResolve(state, action) {
      state.chartData.resolve = action.payload;
    },
    setDuplicate(state, action) {
      state.chartData.duplicate = action.payload;
    },
    setRisk(state, action) {
      state.risk = action.payload;
    },
    setGauge(state, action) {
      state.gauge = action.payload;
    },
    setPerformance(state, action) {
      state.performance = action.payload;
    },
    setGauge(state, action) {
      state.gauge = action.payload;
    },

    setCurrentAsset(state, action) {
      state.currentAsset = action.payload;
    },

    setChartsData(state, action) {
      state.chartData.critical = action.payload?.critical;
      state.chartData.high = action.payload?.high;
      state.chartData.medium = action.payload?.medium;
      state.chartData.low = action.payload?.low;
      state.chartData.new = action.payload?.new;
      state.chartData.resolve = action.payload?.resolve;
      state.chartData.triaged = action.payload?.triaged;
      state.chartData.informative = action.payload?.informative;
      state.chartData.duplicate = action.payload?.duplicate;

      state.chartData.risk = action.payload?.risk;
      state.chartData.gauge = action.payload?.gauge;
      state.chartData.performance = action.payload?.performance;
    },

    setInfoCardsData(state, action) {
      state.totalVulnerabilities = action.payload.totalVulnerabilities;
      state.totalAssets = action.payload.totalAssets;
      state.critical = action.payload.critical;
      state.high = action.payload.high;
      state.medium = action.payload.medium;
      state.low = action.payload.low;
    },
    setTopVulnerabilities(state, action) {
      state.topVulnerabilities = action.payload;
    },
    setTopCve(state, action) {
      state.topCve = action.payload;
    },

    setCweGraphData(state, action) {
      state.cweGraphData = action.payload;
    },
    setOwaspGraphData(state, action) {
      state.owaspGraphData = action.payload;
    },
    setMainGraphData(state, action) {
      state.mainGraphData = action.payload;
    },

    setTimeOption(state, action) {
      state.timeOption = action.payload;
    },
  },
});

export const dashboardSliceActions = dashboardSlice.actions;

export default dashboardSlice;
