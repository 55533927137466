import { Textarea } from "@mui/joy";
import { Input, MenuItem, Select, TextareaAutosize } from "@mui/material";
import { input1 } from "components/tailwindClasses";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addReportSliceActions } from "store/addReportSlice";

import { scanTypes, scanSpeeds } from "../Step1/Index";
import { robotsFiles, sitemapFiles, crawlScopes, depths } from "../Step2/Index";

function Index(props) {
  const dispatch = useDispatch();

  const step1Data = useSelector((state) => state.scanner.addScanEngine.step1);
  const step2Data = useSelector((state) => state.scanner.addScanEngine.step2);

  return (
    <div
      style={{
        padding: "2%",
        paddingBottom: "3%",
        borderRadius: "10px",
        marginBottom: "1%",
      }}
      className="bg-white dark:bg-lightBlack dark:text-white"
    >
      <div className="font-semibold text-lg py-1p border-b dark:border-b9">
        Review & Confirm New Engine Setting
      </div>

      <div>
        <div className="flex mt-2p">
          <div className="text-sm w-[25%]">Scan engine name</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {step1Data.name}
          </div>
        </div>
        <div className="flex mt-1p">
          <div className="text-sm w-[25%]">Scan Type</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {scanTypes.find((item) => item.id === step1Data.scanType)?.label}
          </div>
        </div>
        <div className="flex mt-1p">
          <div className="text-sm w-[25%]">Threads</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {step1Data.threads}
          </div>
        </div>
        <div className="flex mt-1p">
          <div className="text-sm w-[25%]">Scan Speed</div>
          <div className="mr-3p">-</div>

          <div className="break-all text-[15px] font-bold">
            {scanSpeeds.find((item) => item.id === step1Data.scanSpeed)?.label}
          </div>
        </div>
      </div>
      {step1Data.scanType != "active" && (
        <>
          <div className="text-sm mt-3p pb-1p border-b dark:border-b9">
            Crawl Setting
          </div>

          <div>
            <div className="flex mt-2p">
              <div className="text-sm w-[25%]">Crawl Scope</div>
              <div className="mr-3p">-</div>

              <div className="break-all text-[15px] font-bold">
                {
                  crawlScopes.find((item) => item.id === step2Data.crawlScope)
                    ?.label
                }
              </div>
            </div>

            <div className="flex mt-1p">
              <div className="text-sm w-[25%]">Crawl Depth</div>
              <div className="mr-3p">-</div>

              <div className="break-all text-[15px] font-bold">
                {step2Data.crawlDepth}
              </div>
            </div>
            <div className="flex mt-1p">
              <div className="text-sm w-[25%]">Max links to crawl</div>
              <div className="mr-3p">-</div>

              <div className="break-all text-[15px] font-bold">
                {step2Data.maxLinksToCrawl}
              </div>
            </div>
            <div className="flex mt-1p">
              <div className="text-sm w-[25%]">robots.txt file</div>
              <div className="mr-3p">-</div>

              <div className="break-all text-[15px] font-bold">
                {
                  robotsFiles.find((item) => item.id === step2Data.robotsFile)
                    ?.label
                }
              </div>
            </div>
            <div className="flex mt-1p">
              <div className="text-sm w-[25%]">sitemap.xml file</div>
              <div className="mr-3p">-</div>

              <div className="break-all text-[15px] font-bold">
                {
                  sitemapFiles.find((item) => item.id === step2Data.sitemapFile)
                    ?.label
                }
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Index;
