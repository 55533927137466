import cvss from "cvss";

// "CVSS:3.0/AV:P/AC:H/PR:N/UI:R/S:C/C:L/I:H/A:L"

// let obj = {
//   A: "L",
//   AC: "H",
//   AV: "P",
//   C: "H",
//   I: "H",
//   PR: "N",
//   S: "C",
//   UI: "R",
// };

export const isValidCvssVector = (cvssString) => {
  return cvssString.length == 44;
};

export const cvssStringToObject = (cvssString) => {
  let cvssObj = {};
  typeof cvssString == "string" &&
    cvssString.split("/").forEach((pair) => {
      const pairArr = pair.split(":");
      cvssObj[pairArr[0]] = pairArr[1];
    });

  // remove key CVSS from obj
  delete cvssObj.CVSS;

  return cvssObj;
};

export const cvssObjectToString = (cvssObj) => {
  let cvssString = "CVSS:3.0";

  for (const key in cvssObj) {
    if (cvssObj.hasOwnProperty(key)) {
      const value = cvssObj[key];
      cvssString += `/${key}:${value}`;
    }
  }

  return cvssString;
};

export const score = (obj1) => {
  try {
    return cvss.getScore(obj1);
  } catch (e) {
    console.log(e);
  }
};

export const rating = (inputScore) => {
  try {
    return cvss.getRating(inputScore);
  } catch (e) {
    console.log(e);
  }
};

// console.log(score); // => 6.2
// console.log(rating); // => Medium
