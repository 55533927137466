import React from "react";
import Countdown, { zeroPad } from "react-countdown";
import { useRef, useState } from "react";

import OTPInput from "otp-input-react";
import ResendOTP from "./ResendOtp";
import { useSelector } from "react-redux";

function OtpSection({
  OTP,
  setOTP,
  getTimeLeft = () => {},
  onResendClick = () => {},
}) {
  const isDarkMode = useSelector((state) => state.general.theme) == "dark";
  return (
    <>
      <OTPInput
        value={OTP}
        onChange={(v) => {
          setOTP(() => v);
        }}
        autoFocus
        OTPLength={6}
        otpType="any"
        disabled={false}
        // secure
        inputStyles={{
          width: "calc(100%/6)",
          height: "50px",
          background: isDarkMode ? "#3D495B" : "rgba(0, 0, 0, 0.08)",
          borderRadius: "10px",
        }}
      />
      <div className="px-2p mt-3">
        <ResendOTP
          getRemainingTime={(t) => {
            getTimeLeft(t);
          }}
          resetClickPassed={onResendClick}
          maxTime={120}
        />
      </div>
    </>
  );
}

export default OtpSection;
