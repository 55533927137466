import React, { useEffect, useState } from "react";
import Step1 from "./Steps/First";
import Step2 from "./Steps/Second";
import Step3 from "./Steps/Third";
import Step4 from "./Steps/Fourth";
import Step5 from "./Steps/Fifth";
import Stepper from "./Stepper";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

function StepsSideBarWithIndex(props) {
  const activeStep = useSelector((state) => state?.steps?.activeStep);

  const user = useSelector((state) => state?.user?.data);

  const navigate = useNavigate();

  return user?.role != "MANAGER" ? (
    navigate("/dashboard")
  ) : (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "fixed",
          top: "8vh",
          left: "0",
          width: "20vw",
          height: "calc(100% - 8vh)",
          fontSize: "14px",
          color: "rgba(0, 0, 0, 0.6)",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        {/* stepper here */}

        <div style={{ padding: "5vh 0 0 3vw" }}>
          <Stepper activeStep={activeStep} />
        </div>
      </div>
      <div
        style={{
          marginLeft: "20vw",
          minHeight: "calc(100vh - 8vh)",
        }}
      >
        {/* make stuff here */}
        <div className="mx-1p my-1p ">
          {activeStep == 0 && <Step1 />}
          {activeStep == 1 && <Step2 />}
          {activeStep == 2 && <Step3 />}
          {activeStep == 3 && <Step4 />}
          {activeStep == 4 && <Step5 />}
        </div>
      </div>
    </div>
  );
}

export default StepsSideBarWithIndex;
