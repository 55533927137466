import React, { useState } from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";

const inputField = {
  // boxSizing: "border-box",
  // position: "absolute",
  width: "189px",
  // height:'45px',
  // height: "44px",
  background: "rgba(217, 217, 217, 0.25)",
  border: "1px solid rgba(0, 0, 0, 0.25)",
  borderRadius: "5px",
};

function EditAssetOption({ current, updateAssetList, role }) {
  // it has id and title

  let [currentRole, setCurrentRole] = useState(role || "None");

  const handleChange = (event) => {
    setCurrentRole(() => event.target.value);
    updateAssetList((list) => {
      list[current].role = event.target.value;

      return list;
    });
  };
  return (
    <FormControl sx={inputField}>
      <Select
        value={currentRole}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        SelectDisplayProps
        className="dark:text-white dark:bg-greyBg44"
      >
        <MenuItem value="None" className="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="Researcher">Researcher</MenuItem>
      </Select>
    </FormControl>
  );
}

export default EditAssetOption;
