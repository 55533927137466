import React, { useEffect } from "react";
import { CompaniesListSManagerComponent } from "features/companies";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Main(props) {
  // let role = useSelector((state) => state.user.data.role);
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/dashboard");
  }, []);

  // return <>{role == "S_MANAGER" && <CompaniesListSManagerComponent />}</>;
}

export default Main;
