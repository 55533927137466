import React, { useEffect, useState } from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Gauge from "components/ui/charts/Gauge";
import { useSelector, useDispatch } from "react-redux";
import { dashboardInfoSliceActions } from "store/dashboardInfoSlice";
import { getFetch, postFetch } from "lib/fetch";
import PieAndBarChart from "components/ui/charts/PieAndBarChart";
import Performance from "components/ui/buttons/Performance";

import { riskScoreColor } from "Util/RiskScoreColors";

function Index(props) {
  let dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);

  const dashboardData = useSelector((state) => state.dashboardInfo);
  const dashboardChartData = useSelector(
    (state) => state.dashboardInfo.chartData
  );
  const currentTarget = useSelector(
    (state) => state.dashboardInfo.currentAsset
  );

  const targets = useSelector((state) => state.dashboardInfo.assets);

  let handleTargetChange = (e) => {
    let curTarget = e.target?.value;

    dispatch(dashboardInfoSliceActions.setCurrentAsset());

    if (curTarget == "all") {
      dispatch(
        dashboardInfoSliceActions.setCurrentAsset({
          id: "all",
          title: "All",
        })
      );

      dispatch(
        dashboardInfoSliceActions.setChartsData({
          critical: dashboardData?.critical,
          high: dashboardData?.high,
          medium: dashboardData?.medium,
          low: dashboardData?.low,
          new: dashboardData?.new,
          resolve: dashboardData?.resolve,
          triaged: dashboardData?.triaged,
          informative: dashboardData?.informative,
          duplicate: dashboardData?.duplicate,
          risk: dashboardData?.risk,
          performance: dashboardData?.performance,
        })
      );

      return;
    }

    const findCurrentAsset = targets.find((a) => a?.id == curTarget);

    if (!findCurrentAsset) return;

    dispatch(
      dashboardInfoSliceActions.setCurrentAsset({
        id: findCurrentAsset?.id,
        title: findCurrentAsset?.title,
      })
    );

    dispatch(
      dashboardInfoSliceActions.setChartsData({
        critical: findCurrentAsset?.critical_vuln_count,
        high: findCurrentAsset?.high_vuln_count,
        medium: findCurrentAsset?.medium_vuln_count,
        low: findCurrentAsset?.low_vuln_count,
        new: findCurrentAsset?.new_vuln_count,
        resolve: findCurrentAsset?.resolve_vuln_count,
        triaged: findCurrentAsset?.triaged_vuln_count,
        informative: findCurrentAsset?.informative_vuln_count,
        duplicate: findCurrentAsset?.duplicate_vuln_count,
        risk: findCurrentAsset?.risk_score,
        performance: findCurrentAsset?.user_performance,
      })
    );
  };

  useEffect(() => {
    const getData = async () => {
      dispatch(dashboardInfoSliceActions.setIsLoading(true));
      // let postData = { targets: "all" };
      let res = await getFetch("/dashboard");
      if (res?.status == "ok") {
        let data = res.data;
        dispatch(
          dashboardInfoSliceActions.setCurrentAsset({
            id: "all",
            title: "All",
          })
        );

        // this is for all assets

        dispatch(
          dashboardInfoSliceActions.setInfoCardsData({
            totalVulnerabilities: data?.total_vuln_count,
            totalAssets: data?.total_asset_count,
            critical: data?.total_critical_vuln_count,
            high: data?.total_high_vuln_count,
            medium: data?.total_medium_vuln_count,
            low: data?.total_low_vuln_count,
            new: data?.total_new_vuln_count,
            resolve: data?.total_resolve_vuln_count,
            triaged: data?.total_triaged_vuln_count,
            informative: data?.total_informative_vuln_count,
            duplicate: data?.total_duplicate_vuln_count,
          })
        );

        dispatch(
          dashboardInfoSliceActions.setChartsData({
            critical: data?.total_critical_vuln_count,
            high: data?.total_high_vuln_count,
            medium: data?.total_medium_vuln_count,
            low: data?.total_low_vuln_count,
            new: data?.total_new_vuln_count,
            resolve: data?.total_resolve_vuln_count,
            triaged: data?.total_triaged_vuln_count,
            informative: data?.total_informative_vuln_count,
            duplicate: data?.total_duplicate_vuln_count,
            risk: data?.allRiskScore,
            performance: data?.allPerformance,
          })
        );

        dispatch(dashboardInfoSliceActions.setAssets(data?.assets));

        dispatch(dashboardInfoSliceActions.setRisk(data?.allRiskScore));
        dispatch(
          dashboardInfoSliceActions.setPerformance(data?.allPerformance)
        );

        dispatch(
          dashboardInfoSliceActions.setTopVulnerabilities(
            data?.topVulnerabilities
          )
        );
        dispatch(dashboardInfoSliceActions.setTopCve(data?.topCve));

        dispatch(dashboardInfoSliceActions.setActivityFeed(data?.activityFeed));

        dispatch(
          dashboardInfoSliceActions.setPerformanceGraph(data?.performance_data)
        );
        dispatch(
          dashboardInfoSliceActions.setTimelineGraph(data?.timeline_data)
        );
      } else if (res?.error) {
        console.log("error : ", res?.message);
        dispatch(dashboardInfoSliceActions.reset());
      } else {
        console.log("error in getting dashboard data, try later");
        dispatch(dashboardInfoSliceActions.reset());
      }

      dispatch(dashboardInfoSliceActions.setIsLoading(false));
    };
    getData();
  }, []);

  //

  return (
    <div>
      <div>
        <Select
          sx={{
            width: "100%",
            // height: "48px",
            backgroundColor: "#D9D9D940",
            borderRadius: "4px",
          }}
          className="dark:text-white h-12"
          MenuProps={{
            PaperProps: { sx: { maxHeight: 300 } },
          }}
          labelId="demo-simple-select-label"
          onChange={handleTargetChange}
          value={currentTarget?.id}
          defaultValue="all"
        >
          <MenuItem value="all" key={-1} className="dark:bg-lightBlack">
            All
          </MenuItem>
          {targets.map((target, i) => {
            return (
              <MenuItem key={i} value={target?.id}>
                {target?.title}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <div className="flex flex-col my-2">
        <div className="flex">
          <div className="w-1/2">
            <Gauge
              rating={
                user.roleId == "100"
                  ? dashboardChartData?.risk
                  : dashboardChartData?.performance
              }
              isPerformance={user.roleId == "102"}
            />
          </div>

          <div className="flex justify-center items-center w-1/2">
            {user.role == "S_MANAGER" || user.role == "MANAGER" ? (
              <div className="flex flex-col items-center">
                <div
                  className="chart-risk-score text-white bg-greyBg4"
                  style={{
                    backgroundColor: riskScoreColor(dashboardChartData?.risk),
                  }}
                >
                  {dashboardChartData?.risk?.toFixed?.(1) || "-"}
                </div>
                <div className="risk-score-text dark:text-white">
                  Risk Score
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center ">
                <Performance
                  percentage={dashboardChartData?.performance}
                  circleSize={90}
                  strokeWidth={15}
                  text
                />
                <div className="risk-score-text dark:text-white">
                  Performance
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-end justify-evenly mt-4">
          <div className="w-1/2">
            <PieAndBarChart
              values={{
                critical: dashboardChartData?.critical,
                high: dashboardChartData?.high,
                medium: dashboardChartData?.medium,
                low: dashboardChartData?.low,
              }}
              type="vulnerability"
              width={72}
            />
          </div>
          <div className="w-1/2">
            <PieAndBarChart
              values={{
                new: dashboardChartData?.new,
                informative: dashboardChartData?.informative,
                triaged: dashboardChartData?.triaged,
                resolve: dashboardChartData?.resolve,
              }}
              type="status"
              width={72}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
