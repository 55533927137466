import React, { useEffect } from "react";
import RightSection from "./RIghtSection/Index";
import LeftSection from "./LeftSection/Index";
import UserNavbar from "Layouts/userNavbar/Index";
import { useState } from "react";

import { postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";

// top navbar i ll add later
function Index(props) {
  const location = useLocation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  if (!location?.state?.fromApp) {
    navigate("/");
  }

  const currentVulnId = useSelector(
    (state) => state.targetDashboard.currentVulnerability
  );

  const [vulnerabilityData, setVulnerabilityData] = useState({});

  // handling reload

  useEffect(() => {
    let savedState = sessionStorage.getItem("vulnerabilityReportState");

    if (savedState) {
      savedState = JSON.parse(savedState);
      if (!currentVulnId && savedState?.vulnerabilityId) {
        dispatch(
          targetDashboardSliceActions.setCurrentVulnerability(
            savedState.vulnerabilityId
          )
        );
      }
    }
  }, []);

  useEffect(() => {
    let toSave = {
      vulnerabilityId: currentVulnId,
    };
    sessionStorage.setItem("vulnerabilityReportState", JSON.stringify(toSave));
  }, [currentVulnId]);

  //

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // coz link brings it center of page
    window.scrollTo(0, 0);
    const getData = async () => {
      setLoading(() => true);

      const res = await postFetch("/getVulnerabilityData", {
        id: currentVulnId,
      });

      if (res.status == "ok") {
        setVulnerabilityData({
          ...res.data.data,
          comments: res?.data?.comments?.sort(
            (a, b) => new Date(a?.createdDate) - new Date(b?.createdDate)
          ),
        });
      } else toast.error("Some error occured, try later !");

      setLoading(() => false);
    };

    currentVulnId && getData();
  }, [currentVulnId]);

  console.log(vulnerabilityData.comments);

  return (
    <UserNavbar>
      <div
        style={{
          display: "flex",
          padding: "1% 1%",
        }}
      >
        <LeftSection
          data={vulnerabilityData}
          setData={setVulnerabilityData}
          loading={loading}
        />
        <RightSection
          data={vulnerabilityData}
          setData={setVulnerabilityData}
          loading={loading}
        />
      </div>
    </UserNavbar>
  );
}

export default Index;
