import React from "react";

function GreyBgButton({
  type,
  onClick,
  children,
  height,
  width,
  fontSize,
  padding,
}) {
  return (
    <div
      onClick={onClick}
      type={type}
      style={{
        padding: padding || 0,
        height: height || "28px",
        width: width || "96px",
        textAlign: "center",
        fontWeight: "600",
        fontSize: fontSize || "14px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px",
        cursor: "default",
      }}
      className="bg-[#E0E2E5] dark:bg-lightBlack3 dark:text-white"
    >
      {children}
    </div>
  );
}

export default GreyBgButton;
