import React from "react";

function InfoCard({ count, title, topColor, bottomColor }) {
  let topClassName = "h-20 flex justify-center items-center text-4xl font-bold";
  let bottomClassName =
    "w-full h-[26px] text-center bg-white dark:text-black  rounded-b";

  // for dark theme
  if (topColor) topClassName += " " + topColor;
  if (bottomColor) bottomClassName += " " + bottomColor;

  return (
    <div
      style={{ boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)" }}
      className="flex flex-col justify-between h-max font-semibold min-w-[95px] bg-[#f5f5f5] rounded"
    >
      <h1 className={topClassName}>{count}</h1>
      <div className={bottomClassName}>{title}</div>
    </div>
  );
}

export default InfoCard;
