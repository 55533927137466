import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

function SeverityGroupButton({ critical = 0, high = 0, medium = 0, low = 0 }) {
  return (
    <ButtonGroup variant="contained" aria-label="outlined primary button group">
      <Button
        sx={{
          backgroundColor: "#CE3733",
          borderWidth: "0 !important",
          color: "white",
        }}
      >
        <b>{critical}</b>
      </Button>
      <Button
        sx={{
          backgroundColor: "#EC8830",
          borderWidth: "0 !important",
          color: "white",
        }}
      >
        <b>{high}</b>
      </Button>
      <Button
        sx={{
          backgroundColor: "#FDBC2C",
          borderWidth: "0 !important",
          color: "white",
        }}
      >
        <b>{medium}</b>
      </Button>
      <Button
        sx={{
          backgroundColor: "#37A440",
          borderWidth: "0 !important",
          color: "white",
        }}
      >
        <b>{low}</b>
      </Button>
    </ButtonGroup>
  );
}

export default SeverityGroupButton;
