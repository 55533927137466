import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { MenuItem } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Avatar, AvatarGroup } from "@mui/material";
import ModalImage from "react-modal-image";

import { columns } from "../Columns";
import Gauge from "components/ui/charts/Gauge";
import Nut from "components/ui/charts/Doughnut";
import Performance from "components/ui/buttons/Performance";
import BarChart from "components/ui/charts/Bar";
import { useDispatch, useSelector } from "react-redux";
import Chat from "./Chat";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";
import { MultipleUserImage, UserImage } from "components/ui/ImageComponent";
import { riskScoreColor } from "Util/RiskScoreColors";
import axios from "axios";
import { toast } from "react-toastify";
import { targetSliceActions } from "store/targetSlice";
import Loader from "components/ui/loaders/Loader";
import { pythonPostFetch } from "lib/fetch";

let NutData = (critical = 0, high = 0, medium = 0, low = 0) => {
  return {
    values: [critical, high, medium, low],
    backgroundColors: [
      { red: 206, green: 55, blue: 51 },
      { red: 236, green: 136, blue: 48 },
      { red: 253, green: 188, blue: 44 },
      { red: 55, green: 164, blue: 64 },
    ],
  };
};

let BarData = (
  newV = 0,
  informative = 0,
  triaged = 0,
  resolve = 0,
  duplicate = 0
) => {
  return {
    values: [newV, informative, triaged, resolve, duplicate],
    backgroundColors: [
      { red: 25, green: 195, blue: 230 },
      { red: 159, green: 155, blue: 155 },
      { red: 228, green: 31, blue: 114 },
      { red: 178, green: 198, blue: 21 },
      { red: 239, green: 210, blue: 55 },
    ],
  };
};

export default function TargetRow({ row }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const user = useSelector((state) => state.user.data);
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";

  const handleTargetClick = () => {
    if (!row.id) return;
    dispatch(
      targetDashboardSliceActions.setCurrentTarget({
        name: row.assetname,
        id: row.id,
      })
    );
    navigate("/target-dashboard", { state: { fromApp: true } });
  };

  const handleDownloadClick = () => {
    if (!row.id) return;

    navigate("/report", { state: { downloadReport: true, assetId: row.id } });
  };

  const targets = useSelector((state) => state.target.targets);

  //
  const [loadingSs, setLoadingSs] = React.useState(false);
  useEffect(() => {
    const getSs = async () => {
      setLoadingSs(() => true);

      const res = await pythonPostFetch("/asset_screenshot", {
        target: row.websiteUrl,
      });

      // console.log(res);

      if (res?.data?.status == "success") {
        dispatch(
          targetSliceActions.setTargets(
            targets.map((t) => {
              let toReturn = { ...t };
              if (String(t.id) == String(row.id)) {
                toReturn.screenshot = res?.data?.data;
              }
              return toReturn;
            })
          )
        );
      }

      setLoadingSs(() => false);
    };

    open && getSs();
  }, [open]);

  return (
    <>
      {!open && (
        <TableRow>
          <TableCell
            className="dark:text-white dark:border-b9 dark:bg-lightBlack border-b cursor-pointer"
            onClick={() => setOpen(!open)}
          >
            {!open && (
              <img
                src={isDarkTheme ? "/downArrowDark.svg" : "/downArrow.svg"}
                alt=""
                className="min-w-[12px] "
              />
            )}
          </TableCell>
          {columns(user.role).map((column) => {
            const value = row[column.id];

            return (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ maxWidth: column.maxWidth }}
                // className="dark:bg-lightBlack"
                onClick={column.id == "assetName" ? handleTargetClick : null}
                className="dark:text-white dark:border-b9 dark:bg-lightBlack border-b"
              >
                {column.format && typeof value === "number"
                  ? column.format(value)
                  : value}
              </TableCell>
            );
          })}
        </TableRow>
      )}
      <TableRow>
        <TableCell style={{ padding: 0 , marginTop:"200px" }} colSpan={10} className="">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{ width: "100%", height: "100%", paddingY: "1%" }}
              className="bg-lightBlue p-2p pl-0 dark:bg-lightBlack dark:text-white"
            >
              <div className="flex justify-between ">
                <div className=" flex">
                  <div
                    className="mr-3 ml-1 h-full cursor-pointer flex items-center"
                    onClick={() => setOpen(!open)}
                  >
                    <IconButton aria-label="expand row" size="small">
                      {open && (
                        <img
                          src={
                            isDarkTheme ? "/upArrowDark.svg" : "/upArrow.svg"
                          }
                          alt=""
                          className="mt-1 ml-2 w-3"
                        />
                      )}
                    </IconButton>
                  </div>
                  <div className=" flex mr-4 h-fit">
                    <UserImage
                      name={row?.companyInfo?.company_name}
                      image={row?.companyInfo?.company_profile_image}
                      style={{ width: 50, height: 50 }}
                    />
                  </div>

                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <div
                        className="text-xl  font-bold mr-4 cursor-pointer"
                        onClick={handleTargetClick}
                      >
                        {row?.name}
                      </div>
                      {row?.websiteUrl && (
                        <div className="">
                          <Link
                            className="no-underline font-medium text-base"
                            onClick={() => {
                              window.open(row?.websiteUrl, "_blank");
                            }}
                          >
                            {row?.websiteUrl}
                          </Link>
                        </div>
                      )}
                    </div>

                    <div className="text-sm">{row?.description}</div>
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="mr-8 ml-5">{row?.teamMembers}</div>

                  {/* {user.role == "S_ENGINEER" && (
                    <div className="mr-8">
                      <img src="/chat.svg" alt="" />
                    </div>
                  )} */}
                  {(user?.role == "S_MANAGER" || user?.role == "MANAGER") && (
                    <div
                      className="cursor-pointer w-5"
                      onClick={handleDownloadClick}
                    >
                      <img
                        src={
                          isDarkTheme ? "/downloadDark.svg" : "/download.svg"
                        }
                        alt=""
                        className="inline"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="flex mt-6">
                <div className="w-1/4 flex border-r dark:border-b9 p-2p pl-0">
                  <div className="flex flex-col items-center justify-center">
                    <div className="w-52 ">
                      <Gauge
                        isPerformance={
                          user.role == "S_ENGINEER" || user.role == "ENGINEER"
                        }
                        rating={
                          user.role == "S_MANAGER" || user.role == "MANAGER"
                            ? row?.riskScoreProp
                            : row?.userPerformanceProp
                        }
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-around">
                    {(user.role == "S_ENGINEER" || user.role == "ENGINEER") && (
                      <div className="flex flex-col items-center">
                        <Performance percentage={row?.userPerformanceProp} />
                        <div className="text-xs font-semibold mt-1">
                          Performance
                        </div>
                      </div>
                    )}
                    {(user.role == "S_MANAGER" || user.role == "MANAGER") && (
                      <div className="flex flex-col items-center">
                        <div
                          className="w-28 h-12 bg-greyBg4 text-white border-2 rounded text-4xl font-bold flex justify-center items-center"
                          style={{
                            backgroundColor: riskScoreColor(row?.riskScoreProp),
                          }}
                        >
                          <div>{row?.riskScoreProp}</div>
                        </div>
                        <div className="font-semibold mt-3 ">Risk Score</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="w-1/4 flex grow items-center border-r dark:border-b9  p-2p">
                  <div className="w-2/5 -translate-y-2">
                    <Nut
                      data={NutData(
                        row?.critical,
                        row?.high,
                        row?.medium,
                        row?.low
                      )}
                      cutout={30}
                    />
                  </div>
                  <div className="flex mx-auto text-xs font-semibold">
                    <div className="flex mr-2">
                      <div className="flex flex-col mr-1 ">
                        <div>
                          <img
                            src="/criticalDot.svg"
                            alt=""
                            className="inline mr-2 w-2"
                          />
                          Critical
                        </div>
                        <div>
                          <img
                            src="/highDot.svg"
                            alt=""
                            className="inline mr-2 w-2"
                          />
                          High
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div>{row?.critical}</div>
                        <div>{row?.high}</div>
                      </div>
                    </div>

                    <div className="flex mr-2">
                      <div className="flex flex-col mr-1 ">
                        <div>
                          <img
                            src="/mediumDot.svg"
                            alt=""
                            className="inline mr-2 w-2"
                          />
                          Medium
                        </div>
                        <div>
                          <img
                            src="/lowDot.svg"
                            alt=""
                            className="inline mr-2 w-2"
                          />
                          Low
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div>{row?.medium}</div>
                        <div>{row?.low}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-1/4 flex grow items-center border-r dark:border-b9  p-2p">
                  <div className="w-2/5">
                    <BarChart
                      dataToUse={BarData(
                        row?.new,
                        row?.informative,
                        row?.triaged,
                        row?.resolve,
                        row?.duplicate
                      )}
                    />
                  </div>
                  <div className="flex mx-auto text-xs font-semibold">
                    <div className="mr-2">
                      <div>
                        <img
                          src="/newDot.svg"
                          alt=""
                          className="inline mr-2 w-2"
                        />
                        New
                      </div>
                      <div>
                        <img
                          src="/informativeDot.svg"
                          alt=""
                          className="inline mr-2 w-2"
                        />
                        Informative
                      </div>
                      <div>
                        <img
                          src="/triagedDot.svg"
                          alt=""
                          className="inline mr-2 w-2"
                        />
                        Triaged
                      </div>
                      <div>
                        <img
                          src="/resolveDot.svg"
                          alt=""
                          className="inline mr-2 w-2"
                        />
                        Resolve
                      </div>
                      <div>
                        <img
                          src="/duplicateDot.svg"
                          alt=""
                          className="inline mr-2 w-2"
                        />
                        Duplicate
                      </div>
                    </div>
                    <div>
                      <div>{row?.new}</div>
                      <div>{row?.informative}</div>
                      <div>{row?.triaged}</div>
                      <div>{row?.resolve}</div>
                      <div>{row?.duplicate}</div>
                    </div>
                  </div>
                </div>
                <div className="w-[25%] flex items-center justify-center p-2p">
                  {!row.screenshot && loadingSs ? (
                    <Loader />
                  ) : (
                    <ModalImage
                      small={row?.screenshot}
                      large={row?.screenshot}
                      alt=""
                      showRotate={true}
                    />
                  )}
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
