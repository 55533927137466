import React, { useEffect, useRef, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Avatar, AvatarGroup } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SeverityButton from "components/ui/buttons/SeverityButton";
import GreyBgButton from "components/ui/buttons/GreyBgButton";
import TableFilterSection from "../../ui/TableFilterSection";
import { useDispatch, useSelector } from "react-redux";
import ColorDot from "components/ui/buttons/ColorDot";
import { useLocation, useNavigate } from "react-router-dom";
import { getFetch, postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";
import Chat from "./Chat/Index";
import { GridArrowDownwardIcon, GridArrowUpwardIcon } from "@mui/x-data-grid";
import { MultipleUserImage } from "components/ui/ImageComponent";
import { CamelCase } from "Util/CamelCase";
import TableLoadingSkeleton from "components/ui/loaders/TableSkeleton";
import { Columns } from "./Columns";

let ops =
  "flex justify-center h-12 w-40 cursor-pointer items-center text-base dark:text-lightBlack1";
let opsSelect =
  "flex justify-center h-12 w-40 cursor-pointer items-center text-base " +
  "font-bold border-b-2 border-[#303E55] dark:text-white dark:bg-gradient-to-b dark:from-[#404246]/0 dark:to-[#404246]/100";

const filterOptions = [
  {
    name: "New",
    component: (
      <div className="flex items-center ml-2">
        <ColorDot type={"New"} />
        <span className="ml-2">New</span>
      </div>
    ),
  },
  {
    name: "Triaged",
    component: (
      <div className="flex items-center ml-2">
        <ColorDot type={"Triaged"} />
        <span className="ml-2">Triaged</span>
      </div>
    ),
  },
  {
    name: "Duplicate",
    component: (
      <div className="flex items-center ml-2">
        <ColorDot type={"Duplicate"} />
        <span className="ml-2">Duplicate</span>
      </div>
    ),
  },
  {
    name: "Resolve",
    component: (
      <div className="flex items-center ml-2">
        <ColorDot type={"Resolve"} />
        <span className="ml-2">Resolve</span>
      </div>
    ),
  },
  {
    name: "Informative",
    component: (
      <div className="flex items-center ml-2">
        <ColorDot type={"Informative"} />
        <span className="ml-2">Informative</span>
      </div>
    ),
  },
  {
    name: "Retest",
    component: (
      <div className="flex items-center ml-2">
        <ColorDot type={"Retest"} />
        <span className="ml-2">Retest</span>
      </div>
    ),
  },
  {
    name: "Not Applicable",
    component: (
      <div className="flex items-center ml-2">
        <ColorDot type={"notapplicable"} />
        <span className="ml-2">Not Applicable</span>
      </div>
    ),
  },
  {
    name: "Won't Fix",
    component: (
      <div className="flex items-center ml-2">
        <ColorDot type={"wontfix"} />
        <span className="ml-2">Won't Fix</span>
      </div>
    ),
  },
];

function Index() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.data);
  const data = useSelector((state) => state?.targetDashboard?.vulnerabilities);

  const isDarkMode = useSelector((state) => state.general.theme) == "dark";

  let columns = Columns(user);

  const currentTarget = useSelector(
    (state) => state.targetDashboard.currentTarget
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(() => true);

      const res = await postFetch("/vulnerability/listVulnerabilities", {
        parent_asset: currentTarget.id,
      });

      if (res?.status == "ok") {
        const dataToSet = res?.data?.map((vuln) => {
          return {
            id: vuln?._id,
            status: CamelCase(vuln?.status), // check all available options in screens for refercne
            type: vuln?.type,
            title: vuln?.name,
            severity: CamelCase(vuln?.severity),
            cvssScore: vuln?.cvss,
            cve: vuln?.cwe,
            team: vuln?.involved_researchers, //
            createdOn: new Date(vuln?.created_date).toDateString(),
            hasNewChat: vuln?.hasNewChat, // this is if a new message has not been read in char for company manager and engineer
            isOpened: false, // this is if option has been clicked on not
          };
        });

        dispatch(targetDashboardSliceActions.setVulnerabilities(dataToSet));

        dispatch(
          targetDashboardSliceActions.setVulnerabilityCount(dataToSet?.length)
        );
      } else if (res?.error && res?.message) {
        toast.error(res?.message);
        dispatch(targetDashboardSliceActions.setVulnerabilities([]));
        dispatch(targetDashboardSliceActions.setVulnerabilityCount(0));
      } else {
        toast.error("Error loading vulnerabilities, try later !");
        dispatch(targetDashboardSliceActions.setVulnerabilities([]));
        dispatch(targetDashboardSliceActions.setVulnerabilityCount(0));
      }

      setIsLoading(() => false);
    };

    getData();
  }, [currentTarget.id]);

  let rows = [];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // table options
  let options = {
    All: {
      title: "All",
      data: data,
    },
    New: {
      title: "New",
      data: data?.filter?.((item) => item?.status?.toLowerCase() == "new"),
    },

    Triaged: {
      title: "Triaged",
      data: data?.filter?.((item) => item?.status?.toLowerCase() == "triaged"),
    },

    Duplicate: {
      title: "Duplicate",
      data: data?.filter?.(
        (item) => item?.status?.toLowerCase() == "duplicate"
      ),
    },

    Retest: {
      title: "Retest",
      data: data?.filter?.((item) => item?.status?.toLowerCase() == "retest"),
    },

    Resolve: {
      title: "Resolve",
      data: data?.filter?.((item) => item?.status?.toLowerCase() == "resolve"),
    },

    Bin: {
      title: "Bin",
      data: data?.filter?.((item) => item?.status?.toLowerCase() == "bin"),
    },
  };
  const [activeOption, setActiveOption] = useState("All");

  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState("");

  let currentData = options[activeOption]?.data;

  // handling filtering for all option
  if (activeOption == "All" && filters.length > 0) {
    currentData = currentData.filter((item) => filters.includes(item.status));
  }

  // handling search

  if (search != "") {
    currentData = currentData.filter((item) =>
      item.title.toLowerCase().includes(search.toLowerCase())
    );
  }

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc"); // or "desc"

  const renderSortIcon = () => {
    return sortDirection === "desc" ? (
      <GridArrowDownwardIcon fontSize="small" />
    ) : (
      <GridArrowUpwardIcon fontSize="small" />
    );
  };
  const handleSort = (columnId) => {
    if (sortColumn === columnId) {
      // If the same column is clicked again, toggle the sort direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If a new column is clicked, set it as the sort column and default to ascending order
      setSortColumn(columnId);
      setSortDirection("asc");
    }
  };

  // Create a separate sortedData array
  const sortedData = [...currentData].sort((a, b) => {
    if (sortColumn) {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      if (sortDirection === "asc") {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    }
    return 0;
  });

  const location = useLocation();
  const incomingState = location?.state;

  // State to keep track of opened chat popups
  const [isChatOpen, setIsChatOpen] = useState(null);
  // Open chat popup if openChat = true and bugId exists

  const [vulnIdToOpenChat, setVulnIdToOpenChat] = useState(null);

  // Effect to handle incoming state and set bugIdToOpenChat
  useEffect(() => {
    // Check if incomingState has openChat = true and bugId exists
    if (incomingState?.openChat && incomingState?.vulnId) {
      const vulnId = incomingState.vulnId;
      // Find the index of the bug with the specified bugId in the sortedData
      const vuln = sortedData?.find((item) => item?.id === vulnId);

      // If the bug is found, open the chat popup
      if (vuln) {
        setVulnIdToOpenChat(() => vuln?.id);
      } else {
        setIsChatOpen(() => null);
      }
    }
  }, [incomingState, sortedData]);

  useEffect(() => {
    if (vulnIdToOpenChat != null) {
      setIsChatOpen(() => vulnIdToOpenChat);
      incomingState.openChat = false;
      incomingState.vulnId = null;
    }
  }, [vulnIdToOpenChat]);

  const markChatRead = async (vulnId, hasNewChat) => {
    if (!hasNewChat) return;

    const toSet = data.map((item) => {
      let newItem = { ...item };
      if (String(newItem.id) == String(vulnId)) {
        newItem.hasNewChat = false;
      }
      return newItem;
    });

    dispatch(targetDashboardSliceActions.setVulnerabilities(toSet));
  };

  // converting raw data into rows
  rows = sortedData.map((item, index) => {
    return {
      id: item.id,
      status: (
        <>
          <span className="mr-2">
            <ColorDot type={item?.status} />
          </span>
          <span className="inline">{item?.status}</span>
        </>
      ),
      type: item?.type,
      title: <span className="text-blue1 ">{item?.title}</span>,
      severity: SeverityButton(item?.severity),
      cvssScore: item?.cvssScore,
      cve:
        item?.cve?.length > 1 ? (
          <Popup
            trigger={
              <div>
                <GreyBgButton>Multiple</GreyBgButton>
              </div>
            }
            position={["left bottom"]}
            on="hover"
            closeOnDocumentClick
          >
            <div className="rounded shadow p-2p bg-white flex">
              {item?.cve?.map((cveItem, i) => {
                return (
                  <div className={i != item?.cve?.length - 1 ? "mr-1" : "mr-4"}>
                    <GreyBgButton>{cveItem}</GreyBgButton>
                  </div>
                );
              })}
            </div>
          </Popup>
        ) : item?.cve?.length == 1 ? (
          <GreyBgButton>{item?.cve}</GreyBgButton>
        ) : (
          <GreyBgButton>None</GreyBgButton>
        ),
      team: <MultipleUserImage team={item?.team} />,
      createdOn: item?.createdOn,
      hasNewChat: (
        <Popup
          open={isChatOpen == item?.id}
          onOpen={() => {
            markChatRead(item?.id, item?.hasNewChat);
          }}
          trigger={
            <div>
              <div>
                <img
                  src={
                    item?.hasNewChat
                      ? isDarkMode
                        ? "/chatYesDark.svg"
                        : "/chatYes.svg"
                      : isDarkMode
                      ? "/chatDark.svg"
                      : "/chat.svg"
                  }
                  className="inline w-6"
                  alt=""
                />
              </div>
            </div>
          }
          modal
          overlayStyle={{ background: "rgba(0,0,0,0.5)" }}
          lockScroll
          nested
          closeOnDocumentClick
        >
          {(closeChat) => (
            <div className="fixed right-0 bottom-0">
              <Chat data={item} closeChat={closeChat} />
            </div>
          )}
        </Popup>
      ),
      isOpened: item.isOpened,
    };
  });

  const similarDuplicateVulnerabilitiesIds = useSelector(
    (state) => state.targetDashboard.similarDuplicateVulnerabilitiesIds
  );

  // for duplicateTable
  useEffect(() => {
    const getData = async () => {
      const res = await postFetch(
        "/vulnerability/listDuplicateVulnerabilites",
        { asset_id: currentTarget.id }
      );

      if (res?.status == "ok") {
        dispatch(
          targetDashboardSliceActions.setSimilarDuplicateVulnerabilitiesIds(
            res?.data
          )
        );
        // i will be getting just the ids
      } else if (res?.error && res?.message) {
        toast.error(res?.message);
      } else {
        toast.error("Something went wrong");
      }
    };

    getData();
  }, [currentTarget.id]);

  const groupOfRows = similarDuplicateVulnerabilitiesIds.map((similarRow) => {
    return rows.filter((row) => similarRow.includes(row.id));
  });

  let handleVulnerabilityClick = (id) => {
    dispatch(targetDashboardSliceActions.setCurrentVulnerability(id));
    navigate("/vulnerabilityReport", { state: { fromApp: true } });
  };

  return (
    <div className="bg-white dark:bg-lightBlack">
      <div className="flex items-center border-b border-b2 dark:border-b9">
        <div className="flex">
          {Object.keys(options).map((option, i) => {
            return (
              <div
                onClick={() => {
                  setActiveOption(() => options[option].title);
                }}
                className={
                  activeOption == options[option].title ? opsSelect : ops
                }
              >
                <span>{options[option].title}</span>{" "}
                <span className="text-sm ml-1">
                  ({options[option].data.length})
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <TableFilterSection
        All={activeOption == "All"}
        filters={filters}
        setFilters={setFilters}
        search={search}
        setSearch={setSearch}
        options={filterOptions}
      />

      <div style={{}} className="border-t">
        {
          <>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "500",
                          // padding: "1% 2%",
                          cursor: "pointer", // Add cursor pointer
                          backgroundColor: "rgba(217, 217, 217, 0.25)",
                        }}
                        className=" dark:bg-lightBlack3 dark:text-white"
                        onClick={() => {
                          handleSort(column.id);
                        }}
                      >
                        {column.label}{" "}
                        {sortColumn === column.id && renderSortIcon()}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {isLoading && data?.length == 0 ? (
                  <TableLoadingSkeleton
                    rowCount={10}
                    columnCount={columns?.length}
                  />
                ) : activeOption != "Duplicate" ? (
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];

                              let styling = {
                                // padding: "1.5% 3%",
                              };
                              if (row.isOpened) {
                                styling = {
                                  ...styling,
                                  backgroundColor: "rgba(244, 248, 255, 0.79)",
                                  fontWeight: "700",
                                };
                              }

                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  sx={{
                                    ...styling,
                                    cursor:
                                      column.id == "title"
                                        ? "pointer"
                                        : "default",
                                  }}
                                  onClick={() => {
                                    column.id == "title" &&
                                      handleVulnerabilityClick(row.id);
                                  }}
                                >
                                  {column.format &&
                                  typeof value === "number" ? (
                                    <p className="dark:text-white">
                                      {column.format(value)}
                                    </p>
                                  ) : (
                                    <p className="dark:text-white">{value}</p>
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  <TableBody>
                    {groupOfRows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((rows) => {
                        return (
                          <>
                            <TableRow>
                              <span className="border-b p-2p ml-3 dark:text-white font-semibold">
                                {rows.length} similar bugs
                              </span>
                            </TableRow>
                            {rows?.map?.((row) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.code}
                                >
                                  {columns.map((column) => {
                                    const value = row[column.id];

                                    let styling = {
                                      // padding: "1.5% 3%",
                                    };
                                    if (row.isOpened) {
                                      styling = {
                                        ...styling,
                                        backgroundColor:
                                          "rgba(244, 248, 255, 0.79)",
                                        fontWeight: "700",
                                      };
                                    }
                                    return (
                                      <TableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{
                                          ...styling,
                                          cursor:
                                            column.id == "title"
                                              ? "pointer"
                                              : "default",
                                        }}
                                        onClick={() => {
                                          column.id == "title" &&
                                            handleVulnerabilityClick(row.id);
                                        }}
                                      >
                                        {column.format &&
                                        typeof value === "number" ? (
                                          <p className="dark:text-white">
                                            {column.format(value)}
                                          </p>
                                        ) : (
                                          <p className="dark:text-white">
                                            {value}
                                          </p>
                                        )}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                          </>
                        );
                      })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className="dark:text-white"
            />
          </>
        }
      </div>
    </div>
  );
}

export default Index;
