import React, { useState } from "react";
import MarkdownEditor from "components/imports/MarkdownEditor/Index";
import { Link } from "react-router-dom";
import GreyBgButton from "components/ui/buttons/GreyBgButton";
import SecondaryButton from "components/ui/buttons/SecondaryButton";
import { useSelector, useDispatch } from "react-redux";
import { addVulnerabilityReportSliceActions } from "store/vulnerabilityReport";
import CommentBox from "../BugReportSidebar/CommentBox";
import Popup from "reactjs-popup";
import CustomMdEditor from "components/imports/MarkdownEditor/CustomMdEdtor";
import { useRef } from "react";
import { getFetch, postFetch } from "lib/fetch";
import axios from "axios";
import ImageModal from "react-modal-image";
import { toast } from "react-toastify";
import clipboardCopy from "clipboard-copy";

import { handleFileUpload } from "services/uploadFile";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { blueGradient1, input1 } from "components/tailwindClasses";

function ProofOfConcept(props) {
  const dispatch = useDispatch();
  const reportViewType = useSelector(
    (state) => state.addVulnerabilityReport.reportViewType
  );

  let commentRef = useRef();

  let bugId = useSelector((state) => state.addVulnerabilityReport.id);

  let name = useSelector((state) => state.addVulnerabilityReport.name);
  let impact = useSelector((state) => state.addVulnerabilityReport.impact);
  let description = useSelector(
    (state) => state.addVulnerabilityReport.description
  );

  let remediation = useSelector(
    (state) => state.addVulnerabilityReport.remediation
  );
  let references = useSelector(
    (state) => state.addVulnerabilityReport.references
  );
  let filesAttached = useSelector(
    (state) => state.addVulnerabilityReport.filesAttached
  );

  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files);

    const res = await handleFileUpload(files);
    // only current res will come

    if (res) {
      dispatch(
        addVulnerabilityReportSliceActions.setFilesAttached([
          ...filesAttached,
          ...res,
        ])
      );
    }
  };

  const handleFileRemove = (file) => {
    dispatch(
      addVulnerabilityReportSliceActions.setFilesAttached(
        filesAttached.filter((prevFile) => prevFile !== file)
      )
    );
  };

  // state for markdown
  const [preview1, setPreview1] = useState("preview");
  const [preview2, setPreview2] = useState("preview");
  const [preview3, setPreview3] = useState("preview");

  // for image
  const [copied, setCopied] = useState({});

  const handleCopyToClipboard = async (imageUrl, index) => {
    try {
      await clipboardCopy(imageUrl);

      setCopied(() => ({ [index]: true }));

      setTimeout(() => {
        setCopied(() => ({ [index]: false }));
      }, 1000);
    } catch (error) {
      console.error("Failed to copy:", error);
    }
  };

  const isDarkMode = useSelector((state) => state.general.theme) == "dark";

  return (
    <>
      <div className="bg-white dark:bg-lightBlack  dark:text-white  p-2p rounded-10">
        <div className="flex justify-between items-center border-b-2 dark:border-b9 border-b1">
          <div className="flex">
            <div className="font-bold text-xl mr-1">4.</div>
            <div className="flex grow flex-col pb-2 ">
              <div className="font-bold text-xl"> Proof of Concept</div>
              <div>
                The proof of concept is the most important part of your report
                submission. Clear, reproducible steps will help us validate this
                issue as quickly as possible.
              </div>
            </div>
          </div>

          {(reportViewType == "edit" || reportViewType == "update") && (
            <Popup
              ref={commentRef}
              trigger={
                <div
                  className={
                    blueGradient1 +
                    " flex items-center justify-center cursor-pointer rounded-full w-10 h-10 text-white font-bold text-4xl "
                  }
                >
                  {" "}
                  +
                </div>
              }
              on="click"
              position={"left center"}
            >
              <CommentBox
                type={"input"}
                commentRef={commentRef}
                section={"poc"} // don't change
                addComment={(comment, id, createdOn, isCreator) => {
                  dispatch(
                    addVulnerabilityReportSliceActions.setPocComment({
                      comment: comment,
                      id,
                      createdOn,
                      isCreator,
                    })
                  );
                }}
              />
            </Popup>
          )}
        </div>

        <div>
          <div className="mt-4">
            <div>
              <div className="font-semibold text-lg">
                Title<span className="text-[#FDBC2C]">*</span>
              </div>
              <div className="">
                A clear and concise title includes the type of vulnerability and
                the impacted asset.
              </div>
            </div>

            <input
              // disabled={reportViewType == "update" ? true : false}
              value={name}
              onChange={(e) => {
                dispatch(
                  addVulnerabilityReportSliceActions.setName(e.target.value)
                );
              }}
              style={{ height: "50px" }}
              className={input1 + " mt-1p"}
              placeholder="Type Here"
              required
            />
          </div>
          <div className="mt-4">
            <div>
              <div className="font-semibold text-lg">
                Description<span className="text-[#FDBC2C]">*</span>
              </div>
              <div className="">
                What is the vulnerability? In clear steps, how do you reproduce
                it?
              </div>
            </div>

            <div className="mt-1p">
              <CustomMdEditor
                value={description}
                onChange={(latestValue) => {
                  dispatch(
                    addVulnerabilityReportSliceActions.setDescription(
                      latestValue
                    )
                  );
                }}
                preview={preview1}
                setPreview={setPreview1}
              />
            </div>
          </div>
          <div className="mt-4">
            <div>
              <div className="font-semibold text-lg">Impact</div>
              <div className="">
                What security impact could an attacker achieve?
              </div>
            </div>

            <div className="mt-1p">
              <CustomMdEditor
                defaultValue={"Add a Comment"}
                value={impact}
                required={true}
                onChange={(latestValue) => {
                  // if (reportViewType == "update") return;

                  dispatch(
                    addVulnerabilityReportSliceActions.setImpact(latestValue)
                  );
                }}
                preview={preview2}
                setPreview={setPreview2}
              />
            </div>
          </div>

          <div className="mt-4">
            <div>
              <div className="font-semibold text-lg">Remediation</div>
              {/* <div className="">
                What security impact could an attacker achieve?
              </div> */}
            </div>

            <div className="mt-1p">
              <CustomMdEditor
                defaultValue={"Add a Comment"}
                value={remediation}
                onChange={(latestValue) => {
                  // if (reportViewType == "update") return;

                  dispatch(
                    addVulnerabilityReportSliceActions.setRemediation(
                      latestValue
                    )
                  );
                }}
                preview={preview3}
                setPreview={setPreview3}
              />
            </div>
          </div>

          <div className="mt-4">
            <div className="font-semibold text-lg">Reference</div>

            <input
              // disabled={reportViewType == "update" ? true : false}
              style={{ height: "50px" }}
              className={input1 + " mt-1p flex-wrap"}
              placeholder="Type Here"
              value={references}
              onChange={(e) => {
                dispatch(
                  addVulnerabilityReportSliceActions.setReferences(
                    e.target.value
                  )
                );
              }}
            />
          </div>
        </div>
      </div>

      <div className="bg-white  dark:bg-lightBlack  dark:text-white  p-2p rounded-10 mt-1p">
        <div className="font-semibold mb-1p">Upload File</div>
        {filesAttached && filesAttached?.length > 0 && (
          <div className="mb-1">
            {filesAttached?.map((imageUrl, index) => {
              return (
                <div key={index} className="relative min-h-[50px] mt-2p">
                  <div className="relative flex justify-between">
                    {/* <ImageModal
                      small={imageUrl}
                      large={imageUrl}
                      className="max-w-[90%] h-auto rounded"
                    /> */}
                    <img
                      src={imageUrl}
                      alt="img"
                      className="max-w-[90%] h-auto rounded"
                    />
                    <div className="">
                      <div
                        onClick={() => handleFileRemove(imageUrl)}
                        className="p-1 bg-black rounded-full hover:bg-gray-900 transition duration-300 text-white cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="w-4 h-4"
                        >
                          <path d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-2">
                    <div className="text-gray-600 break-words flex-wrap w-[90%] dark:text-white italic">
                      {imageUrl}
                    </div>
                    <div className="relative ">
                      <div
                        onClick={() => {
                          handleCopyToClipboard(imageUrl, index);
                        }}
                        className="cursor-pointer"
                      >
                        <img src="/copy.svg" alt="" className="w-5  " />
                      </div>
                      <Popup
                        trigger={<div />}
                        position="bottom center"
                        contentStyle={{
                          padding: "8px",
                          background: "#000",
                          color: "#fff",
                          borderRadius: "4px",
                          fontSize: "14px",
                        }}
                        closeOnDocumentClick
                        open={copied?.[index]}
                      >
                        Copied!
                      </Popup>
                    </div>
                  </div>
                </div>
              );
            })}
            {filesAttached?.length > 0 && (
              <div className="text-xs mt-2p">
                You can copy this url and use it in your report (ctrl + k), or
                using add image icon{" "}
              </div>
            )}
          </div>
        )}
        <label style={{}}>
          <label htmlFor="cpInput032423">
            <input
              onChange={handleFileSelect}
              id={"cpInput032423"}
              type="file"
              multiple
              className="hidden dark:bg-lightBlack4"
            />
            <div
              className={
                input1 + " border-dashed h-28 flex items-center justify-center"
              }
            >
              <img
                src={isDarkMode ? "/fileUploadDark.svg" : "/fileUpload.svg"}
                alt=""
                sizes=""
              />
              <span className="text-[#1D26EF] dark:text-[#4B65ED] font-bold ml-1p">
                Browse files from your computer
              </span>
            </div>
          </label>
        </label>
      </div>
    </>
  );
}

export default ProofOfConcept;
