import React from "react";
import { useRef } from "react";
import "components/style.css";
import OtpSection from "components/imports/OtpSection/OtpSection";
import { postFetch } from "lib/fetch";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { blueGradient1 } from "components/tailwindClasses";

function Step2({ mobileNumber }) {
  const [OTP, setOTP] = useState("");

  const navigate = useNavigate();

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    if (OTP.length == 6 && mobileNumber) {
      const otpVerifyRes = postFetch("/verify-phonenumberchange-otp", {
        otp: OTP,
        new_number: mobileNumber,
      });

      if (otpVerifyRes.status == "ok") {
        toast.success("Mobile number updated successfully !");
        navigate("/profile");
      } else {
        toast.error("invalid OTP");
      }
    } else {
      if (OTP.length != 6) {
        toast.error("please enter valid OTP");
      } else if (!mobileNumber) {
        toast.error("some error with mobile number, try later");
      } else {
        toast.error("some error occured, try later");
      }
    }
  };

  return (
    <div style={{ display: " flex", height: "100%" }}>
      <div className={"relative w-[58%]  h-full text-white " + blueGradient1}>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="text-4xl w-fit mb-2">
            <b>
              OTP <br /> Authentication
            </b>
            <div className="text-2xl text-[#c3d2d2] w-fit ">
              <div className="">
                We’ve sent you One Time Password OTP on your mobile number for
                verification. <br /> Please enter your 6-digit OTP
              </div>
            </div>
          </div>
        </div>
        <img
          src={"/loginBgLines.svg"}
          className="  absolute top-0 left-0 w-1/3  -scale-y-100"
          alt="anim"
        />
      </div>

      <div className="relative w-[42%] bg-[#ececec] h-full flex flex-col ">
        <form action="" className="right-content" onSubmit={handleOtpSubmit}>
          <div
            style={{ display: "flex", flexDirection: "column", width: "80%" }}
          >
            <div
              style={{ textAlign: "center", marginBottom: "20px" }}
              className="font-bold text-2xl"
            >
              Mobile Number OTP
            </div>
            <div className="right-content-text" style={{}}>
              <OtpSection otpContent="OTP" OTP={OTP} setOTP={setOTP} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                className={
                  blueGradient1 + " w-full h-15 rounded-10 font-bold mt-2p"
                }
                type="submit"
              >
                Done
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Step2;
