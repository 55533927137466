import React from "react";

function InfoCardColored({ type, title, count }) {
  let styleClass =
    "flex flex-col justify-between h-max font-semibold min-w-[95px] rounded overflow-clip ";

  let bottomStyleClass =
    "w-full text-center bg-white dark:text-black rounded-b";

  if (type == "low") {
    styleClass += "bg-low";
    bottomStyleClass += "dark:bg-low dark:bg-opacity-50";
  } else if (type == "medium") {
    styleClass += "bg-medium";
    bottomStyleClass += "dark:bg-medium dark:bg-opacity-50";
  } else if (type == "high") {
    styleClass += "bg-high";
    bottomStyleClass += "dark:bg-high dark:bg-opacity-50";
  } else if (type == "critical") {
    styleClass += "bg-critical";
    bottomStyleClass += "dark:bg-critical dark:bg-opacity-50";
  }

  return (
    <div
      style={{ boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)" }}
      className={styleClass}
    >
      <h1 className="h-20 flex justify-center items-center text-4xl font-bold text-white ">
        <span>{count}</span>
      </h1>
      <div className={bottomStyleClass}>{title}</div>
    </div>
  );
}

export default InfoCardColored;
