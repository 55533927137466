import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart({ dataToUse, aspectRatio = 0.9 }) {
  // if (dataToUse?.values?.length > 0 && dataToUse?.labels?.length > 0) {
  return (
    <Bar
      options={{
        animation: {
            duration: 0 // general animation time
        },
        responsive: true,
        aspectRatio: aspectRatio,
        plugins: {
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.yLabel;
              },
            },
          },
          title: {
            display: false,
            text: "Chart.js Bar Chart",
          },
        },
      }}
      data={{
        labels: dataToUse.labels || dataToUse.values.map((i) => ""),
        datasets: [
          {
            label: "",
            data: dataToUse.values,

            backgroundColor: dataToUse.backgroundColors.map((color) => {
              return `rgba(${color.red}, ${color.green}, ${color.blue}, .9)`;
            }),
            borderColor: dataToUse.backgroundColors.map((color) => {
              return `rgba(${color.red}, ${color.green}, ${color.blue})`;
            }),
            borderWidth: 1,
            barPercentage: 0.9,
            // barThickness: 6,
          },
        ],
      }}
    />
  );
  // } else {
  //   <></>;
  // }
}

export default BarChart;
