import React, { useState } from "react";
import Menu, { MenuItem, Select, Avatar } from "@mui/material";
import { colorMap } from "components/ui/buttons/SeverityButton";
import { useDispatch } from "react-redux";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";

function FileSelection({ option, optionData, setOption, bugsData }) {
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setOption(() => event.target.value);

    // need to take action to open status change again
    dispatch(targetDashboardSliceActions.setStatusChangeAllowed(false));
  };

  // console.log(optionData);

  return (
    <div className="bg-white dark:bg-lightBlack dark:text-white p-2p border-b border-b1">
      <div className="font-semibold">Select Bug ID</div>
      <div className="pt-2">
        <Select
          labelId="demo-simple-select"
          sx={{
            width: "100%",
            height: "45px",
            backgroundColor: "#D9D9D940",
            border: "0px solid rgba(0, 0, 0, 0.25)",
            borderRadius: "5px",
          }}
          value={option}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          className="dark:bg-lightBlack3 dark:text-white"
        >
          {bugsData.map((item, i) => {
            return (
              <MenuItem value={item._id}>
                <span
                  style={{
                    color: "white",
                    background: item.duplicate ? "#EFD237" : "#539486",
                    borderRadius: "5px",
                    padding: "6px 12px",
                    marginRight: "1%",
                  }}
                >
                  #{item._id}
                </span>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <div style={{ display: "flex", alignItems: "center" }} className="pt-2">
        <Avatar
          alt={optionData?.researcherName}
          src={optionData?.researcherProfileImage}
          style={{
            width: "50px",
            height: "50px",
            marginRight: "10px",
          }}
        />
        <div>
          <span style={{ fontWeight: "700" }}>
            {optionData?.researcherName}{" "}
          </span>
          <span
            style={{
              background: "#303E55",
              borderRadius: "43px",
              padding: "6px 10px",
              color: "white",
            }}
          >
            {optionData?.researcherCompany}
          </span>
        </div>
      </div>
    </div>
  );
}

export default FileSelection;
