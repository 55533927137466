import React from "react";
import { Divider } from "@mui/material";
import FirstData from "./FirstData";
import SecondData from "./SecondData";
import ThirdData from "./ThirdData";
import FourthData from "./FourthData";
import { stepsSliceActions } from "store/stepsSlice";
import { useSelector, useDispatch } from "react-redux";
import { postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { addAssetSliceActions } from "store/addAssetSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ButtonWithLoader from "components/ui/buttons/ButtonWithLoader";
import Loader from "components/ui/loaders/Loader";
import { useState } from "react";
import { backButton, blueGradient1 } from "components/tailwindClasses";

function Fifth(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeStep = useSelector((state) => state.steps.activeStep);

  const [isLoading, setIsLoading] = useState(false);

  let assetData = useSelector((state) => state.addAsset.asset);

  const handelConfirmClick = async (e) => {
    // handle form submit here

    if (assetData?.websiteUrl) {
      let dataToSend = {
        ...assetData,
        team_members: assetData?.teamMemberEmails,
        websiteSubdomains: assetData.websiteSubdomains,
      };

      if (typeof assetData.title === "string") {
        dataToSend.title = [assetData.title];
      }
      if (typeof assetData.description === "string") {
        dataToSend.description = [assetData.description];
      }
      setIsLoading(() => true);

      if (assetData?.websiteUrl) {
        let response = await postFetch("/addMultipleWebsiteAssets", dataToSend);
        if (response?.status == "ok") {
          toast.success("Assets Added !");

          dispatch(addAssetSliceActions.setAsset({}));
          dispatch(stepsSliceActions.setActiveStep(0));

          navigate("/target");
        } else if (response?.error && response?.message) {
          toast.error(response?.message);
        } else {
          toast.error("Some error occured ! Try Later");
        }
      } else {
        let response = await postFetch("/addMultipleWebsiteAssets", dataToSend);
        if (response?.status == "ok") {
          // toast.success("Asset Added !");

          // if (assetData?.inviteCompanyEmail) {
          //   const inviteRes = await postFetch("/companyInvite/invite", {
          //     email: assetData?.inviteCompanyEmail,
          //     asset_id: response.asset_id,
          //     message: assetData?.inviteCompanyMessage,
          //   });

          //   if (inviteRes?.status == "ok") {
          //     toast.success("company invited successfully !");
          //   } else if (inviteRes?.error && inviteRes?.message) {
          //     toast.error(inviteRes?.message);
          //   } else {
          //     toast.error(
          //       "some error occured in inviting company, try later !"
          //     );
          //   }
          // }

          dispatch(addAssetSliceActions.setAsset({}));
          dispatch(stepsSliceActions.setActiveStep(0));

          navigate("/target");
        } else if (response?.error && response?.message) {
          toast.error(response?.message);
        } else {
          toast.error("Some error occured ! Try Later");
        }
      }
    }

    setIsLoading(() => false);
  };

  const handleBackClick = (e) => {
    dispatch(stepsSliceActions.setActiveStep(activeStep - 1));
  };

  const handleAddAssetClick = (e) => {
    const formEle = document.getElementById("addAssetForm239");
    if (!(formEle && formEle.checkValidity())) {
      formEle.reportValidity();
      return;
    }

    let assetTitles = assetData?.title;

    // Check if assetTitles is a string or an array
    if (typeof assetTitles === "string") {
      assetTitles = [assetTitles];
    }

    const uniqueTitles = new Set(assetTitles); // Convert titles to a Set to remove duplicates

    // Check if the number of unique titles matches the total number of titles
    if (uniqueTitles.size !== assetTitles.length) {
      // Show an error message or take appropriate action
      toast.info("Please enter unique titles");
      return;
    }

    handelConfirmClick();
  };

  return (
    <div
      style={{
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
      }}
      className="bg-white px-3p pt-3p pb-[5%] dark:bg-lightBlack dark:text-white"
    >
      <form id="addAssetForm239">
        <FirstData />
        <div className="my-3p border dark:border-b9"></div>

        <SecondData />
        <div className="my-3p border dark:border-b9"></div>

        <ThirdData />
        <div className="my-3p border dark:border-b9"></div>

        <FourthData />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "2% 0",
          }}
        >
          <button
            className={backButton}
            style={{ width: "240px" }}
            onClick={handleBackClick}
            disabled={isLoading}
          >
            Back
          </button>

          <ButtonWithLoader
            isLoading={isLoading}
            onClick={handleAddAssetClick}
            text="Submit"
            loadingText={<Loader />}
            className={
              blueGradient1 +
              " w-60 h-15 rounded-10 font-bold flex items-center justify-center cursor-pointer"
            }
            div
          />
        </div>
      </form>
    </div>
  );
}

export default Fifth;
