import React from "react";
import ScanProfile from "./components/ScanProfile";

export const ScanProfileComponent = ScanProfile;

function Index(props) {
  return <div></div>;
}

export default Index;
