import { Box, Button, Divider, Menu, MenuItem, Modal } from "@mui/material";
import Fade from "@mui/material/Fade";
import { blueGradient1 } from "components/tailwindClasses";
import { pythonPostFetch } from "lib/fetch";

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "components/ui/loaders/Loader";

// import AddProfile from "./AddProfile/Index"; // for the edit profile modal ( similar to Add profile )
import EditProfileBox from "../EditProfile/Index";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";

/** Row format 
   
   */

/*
    DotBtton ( provides three buttons -> start , edit , delete ( working ) ) for a specific profile 
    All the details for he specific profile are passed down as props 
    
    row
    {"scan_id":"09e2a3d9d52ed24563fc6284d2","version":1,"scan_engine_name":"tanishq doing testing","asset_id":"64b597d8499e90eafcae469e","progress":null,"data":{"bug_variants":[0,0,79,0],"the_risk_score":4.953271028037383,"risk_score_symbol":"C"},"schedule":[false],"is_paused":false,"is_stopped":false,"is_deleted":false,"next_scheduling":"-","scan_name":"Lovely Scan"},

    * this row will be further passed down to the editScanBox

*/
function DotButton({ row, header, isBin, status }) {

  
  const user = useSelector((state) => state.user.data); // getting the user data

  const [twoFaInput, setTwoFaInput] = useState(""); // 2fa input box inside modal
  const [anchorEl, setAnchorEl] = useState(null);
  const [opModalOpen, setOpModalOpen] = useState(false); // 
  const [opLoading, setOpLoading] = useState(false);
  const [operation ,setOperation] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false); // delete model (open: true/false state)
  const [openEditModal, setOpenEditModal] = React.useState(false); // edit model (open: true/false state)
  const [openEditProfileBoxModal, setOpenEditProfileBoxModal] =
    React.useState(false); // edit Profile Box model (open: true/false state)

  const [deleteModalStep, setDeleteModalStep] = useState(0); // steps through delete model

  // steps through edit model -> asking different questions and changing buttons text and handlers
  const [editModalStep, setEditModalStep] = useState(0);

  const handleDeleteModalOpen = () => {
    // clear the state first
    setTwoFaInput("");
    setDeleteModalStep(0);

    // if 2fa is enabled then only delete modal can be opened
    if (!user.twoFaEnabled) {
      toast.error("please enable two factor Authentication to delete scan profile");
    } else setOpenDeleteModal(true);
  };

  const handleEditModalOpen = () => {
    // if 2fa is enabled then only edit modal can be opened
    if (!user.twoFaEnabled) {
      toast.error("please enable two factor Authentication to edit profile");
    } else handleEditClick();
    //setOpenEditModal(true);
  };

  const handleEditProfileBoxModalOpen = () => {
    // if 2fa is enabled then only delete modal can be opened
    if (!user.twoFaEnabled) {
      toast.error("please enable two factor Authentication to edit profile");
    } else setOpenEditModal(true);
  };

  const handleDeleteModalClose = () => setOpenDeleteModal(false); // closing delete modal
  const handleEditModalClose = () => setOpenEditModal(false); // closing edit modal
  const handleEditProfileBoxModalClose = () =>
    setOpenEditProfileBoxModal(false); // closing edit modal

  // // dot button for starting the scan
  // const handleStartClick = async () => {
  //   // const get othe details related to asset
  //   const assetDetailsRes = pythonPostFetch("/asset/dashboard", {
  //     asset_name: row.scan_name,
  //     only_bugs: false,
  //   });

  //   if (assetDetailsRes.status === "ok") {
  //   }

  // const req = {
  //   schema: {
  //     asset_details: {
  //       scan_name: row.scan_name,
  //       target_definition: {
  //         web_app_url: assetDetailsRes?.data?.url || "some randoem ",
  //         tag: "web",
  //       },
  //     },
  //     scan_settings: {
  //       scan_engine: row.scanEngineId, // put engine id here
  //     },
  //     schedule: {
  //       schedule_scan: false,
  //       recurring_scan: true,
  //       date_time: "2023-12-23T04:02:00.000000Z",
  //       frequency: "scan weekly",
  //       scan_now: false,
  //     },
  //     send_report: {
  //       email: "blah@mailnesia.com",
  //       report_format: "Portable Document Format",
  //       secure_report: {
  //         password: "pass",
  //         confirm_password: "pass",
  //       },
  //     },
  //   },
  // };

  // const res = pythonPostFetch("/api/start_scan", {
  //   schema: {
  //     asset_details: {
  //       scan_name: "Lovely Scan",
  //       targe_definition: {
  //         web_app_url: "http",
  //         tag: "web",
  //       },
  //     },
  //     scan_settings: {
  //       scan_engine: "<engine-ID-here>",
  //     },
  //     schedule: {
  //       schedule_scan: false,
  //       recurring_scan: true,
  //       date_time: "<System-Date-Time here generated by Javascript>",
  //       frequency: "scan weekly",
  //       scan_now: false,
  //     },
  //     send_report: {
  //       email: "blah@mailnesia.com",
  //       report_format: "Portable Document Format",
  //       secure_report: {
  //         password: "pass",
  //         confirm_password: "pass",
  //       },
  //     },
  //   },
  // });

  //   if (res?.status === "ok") {
  //     toast.success("Scan Started Successfully");
  //   } else if (res?.error && res?.message) {
  //     toast.error(res?.message);
  //   } else {
  //     toast.error("Scan could not be started.");
  //   }
  // };

  const handleDeleteClick = async () => {
    if (!twoFaInput) {
      toast.error("Please enter two factor Authentication code");
      return;
    }

    // http://52.66.239.48:8000/api/scan/profile/delete
    const res = await pythonPostFetch("/scanprofile/delete", {
      scan_id: row.scanId,
      input_token: twoFaInput,
    });

    if (res?.status == "ok") {
      toast.success("profile deleted successfully");
      setOpenDeleteModal(() => false);
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else {
      toast.error("Could not delete profile. " + res?.message);
    }
  };

  /**
    This is handle Edit Click Modal ( 2fa input ) 
    1- ask for 2fa auth code
    2- pass down that code with the edit request
    3- it should be verified at the python backend 
    4- close this modal and open another one
  **/

  const handleEditClick = async () => {
    // if (!twoFaInput) {
    //   toast.error("Please enter two factor Authentication code");
    //   return;
    // }

    // setOpenEditModal(() => false); // close this modal
    setOpenEditProfileBoxModal(() => true); // open the other modal

    // http://52.66.239.48:8000/api/scan/profile/delete

    // http://52.66.239.48:8000/api/scan/profile/delete
    /* const res = await pythonPostFetch("/risk_score_symbolscan/profile/delete", {
      scan_id: row.scan_id,
      two_fa_code: twoFaInput,
    });


    if (res?.status == "ok") {
      toast.success("profile  edited successfully");
      setOpenEditModal(() => false);
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else {
      toast.error("could not edit engine, try later");
    }*/
  };

  const handleAddAgain = async () => {};

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disableRipple
      >
        <img src="/dotButtonOutline.svg" alt="" width={"20px"} />
      </Button>

      {!isBin && (
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {/* <MenuItem onClick={handleStartClick}>
            <PlayArrowIcon className="mr-2 text-green-700" /> Start
          </MenuItem>
          <Divider sx={{}} /> */}

          {/* Edit option onclick -> calls the handleEditModalOpen */}
          {/* <MenuItem onClick={handleEditModalOpen}>
            <img src="/editPenBlue.svg" alt="" className="mr-2" /> Edit
          </MenuItem> 
          <Divider sx={{}} />
*/}
          {/* Delete option onclick-> calls the handleDeleteModalOpen */}
          {status && (status === "Completed" || status ==="Stopped") && (
             <>
             {/* <MenuItem onClick={handleEditModalOpen}>
             <img src="/editPenBlue.svg" alt="" className="mr-2" /> Edit
           </MenuItem> */}
           {/* <Divider sx={{}}/> */}
            <MenuItem onClick={handleDeleteModalOpen}>
              <img src="/bin.svg" alt="" className="mr-2" /> Delete
            </MenuItem>
            </>
          )}

          {/* Running state => show pause and cancle option */}
          {status && status === "Running" && (
            <>
              <MenuItem onClick={()=>{
                
                if(user.twoFaEnabled){
                  setOpModalOpen(true); 
                  setOperation('Pause');
                }else{
                  toast.error("You need to enable 2FA auth before pausing a scan.")
                }

               }}>
                <img src="/pausescan.svg" alt="" className="mr-2" /> Pause
              </MenuItem>
              <Divider sx={{}}/>
              <MenuItem onClick={()=>{
                
                  if(user.twoFaEnabled){
                    setOpModalOpen(true); setOperation('Stop')
                  }else{
                    toast.error("You need to enable 2FA auth before stopping a scan.")
                  }
                
                }}>
                <img src="canclescan.svg" alt="" className="mr-3 ml-2" /> Stop
              </MenuItem>
            </>
          )}


          {/*Scheduled Scan => could be edited and deleted  */}
          {status && (status === "Scheduled" || status=== "Due" || status==="Idle") && (
            <>
              <MenuItem onClick={handleEditModalOpen}>
                <img src="/editPenBlue.svg" alt="" className="mr-2" /> Edit
              </MenuItem>
              <Divider sx={{}}/>
              <MenuItem onClick={handleDeleteModalOpen}>
                <img src="bin.svg" alt="" className="mr-2" /> Delete
              </MenuItem>
            </>
          )}

           {/*Scheduled Scan => could be edited and deleted  */}
           {status && (status === "Paused") && (
            <>
              <MenuItem onClick={()=>{setOpModalOpen(true); setOperation('Resume')}}>
                <img src="/resumescan.png" alt="" className="mr-2" /> Resume
              </MenuItem>
              <Divider sx={{}}/>
              <MenuItem onClick={handleDeleteModalOpen}>
                <img src="bin.svg" alt="" className="mr-2" /> Delete
              </MenuItem>
            </>
          )}

        </Menu>
      )}


      {isBin && (
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleAddAgain}>
            <img src="/plusBlue.svg" alt="" className="mr-2" /> Add Again
          </MenuItem>
          <Divider sx={{}} />
          <MenuItem onClick={() => {}}>
            <img src="/bin.svg" alt="" className="mr-2" /> Delete Permanently
          </MenuItem>
        </Menu>
      )}

      {/* Delete Scan Profile Modal */}
      <Modal
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            width: "50%",
          }}
          className="bg-white rounded-10 shadow dark:bg-lightBlack dark:text-white p-1p"
        >
          <div>
            <div className="text-2xl font-bold flex items-center justify-center pb-2p border-b border-b2 dark:border-b9">
              Delete Scan Profile
            </div>

            <div style={{ paddingTop: "2%" }}>
              Are you sure, you want to delete profile{" "}
              <span className="font-bold">{row?.nameData}</span> ?
            </div>

            {deleteModalStep == 1 && (
              <div>
                <div className="font-bold mt-2p">
                  Two Factor Authentication:
                </div>
                <input
                  type="text"
                  className="second-step-input bg-inputBg dark:bg-inputBgDark h-15"
                  value={twoFaInput}
                  onChange={(e) => {
                    setTwoFaInput(() => e.target.value);
                  }}
                />
              </div>
            )}

            <div className="flex items-center justify-end mt-2p">
              {/* for showing no and cancle button */}
              <div
                className="cursor-pointer mr-8"
                onClick={handleDeleteModalClose}
              >
                {deleteModalStep == 0 ? "No" : "Cancel"}
              </div>

              <div
                className={
                  "h-15 w-60 font-bold text-white rounded-10 cursor-pointer flex items-center justify-center" +
                  blueGradient1
                }
                onClick={() => {
                  deleteModalStep == 0 && setDeleteModalStep(() => 1); // if delete modal step is 0 then set it to 1

                  deleteModalStep == 1 && handleDeleteClick(); // if step is already 1 then call handleDeteteClick() function
                }}
              >
                {deleteModalStep == 0 ? "Yes" : "Done"}
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/*  Edit Scan Profile Modal 

          1- This modal ask the user if he/she is sure about editing the selected scan profile 
          2- Then 
      
      
      */}
      <Modal
        open={openEditModal}
        onClose={handleEditModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            width: "50%",
          }}
          className="bg-white rounded-10 shadow dark:bg-lightBlack dark:text-white p-1p"
        >
          {/*Uppermost div element > Edit Modal*/}
          <div>
            {/* Heading of the modal */}
            <div className="text-2xl font-bold flex items-center justify-center pb-2p border-b border-b2 dark:border-b9">
              Edit Scan Profile
            </div>

            {/* Text asking user if he wants to edit the profile */}
            <div style={{ paddingTop: "2%" }}>
              Are you sure, you want to Edit profile{" "}
              <span className="font-bold">{row?.nameData}</span> ?
            </div>

            {/* If edit modal step is 1 then provide user with an input box where he can enter the enter 2fa auth code */}
            {editModalStep == 1 && (
              <div>
                <div className="font-bold mt-2p">
                  Two Factor Authentication:
                </div>
                <input
                  type="text"
                  className="second-step-input bg-inputBg dark:bg-inputBgDark h-15"
                  value={twoFaInput}
                  onChange={(e) => {
                    setTwoFaInput(() => e.target.value);
                  }}
                />
              </div>
            )}

            {/* div element for holding two buttons 
                [leftbutton]  => No (editmodalstep 0) | Cancel (editmodalsetp 1) 
                [right button] => Yes (editmodalstep 0) | Done (editmodalstep 1)
             */}
            <div className="flex items-center justify-end mt-2p">
              {/* 
                  [LeftButton] => No | Cancle 
                  in both the cases onClick event remains the same
                  onClick => handleEditModalClose() 
              */}
              <div
                className="cursor-pointer mr-8"
                onClick={handleEditModalClose}
              >
                {editModalStep == 0 ? "No" : "Cancel"}
              </div>

              {/* 
                [RightButton] => Yes | Done 
                onClick event on step 0 => update the current edit modal step state from 0 to 1
                onClick event on step 1 => call handleEditClick() handler ( this will close this modal and open a new one ) 
              */}
              <div
                className={
                  "h-15 w-60 font-bold text-white rounded-10 cursor-pointer flex items-center justify-center" +
                  blueGradient1
                }
                onClick={() => {
                  editModalStep == 0 && setEditModalStep(() => 1); // if delete modal step is 0 then set it to 1

                  editModalStep == 1 && handleEditClick(); //  -- close this modal and open a new one ; handleDeleteClick(); // if step is already 1 then call handleDeteteClick() function
                }}
              >
                {editModalStep == 0 ? "Yes" : "Done"}
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* This is for opening EditProfileBox modal where user can make the changes to the scan profile */}
      <Modal
        open={openEditProfileBoxModal}
        onClose={handleEditProfileBoxModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditProfileBox
          row={row}
          header={header}
          twoFaInput={twoFaInput}
          handleClose={handleEditProfileBoxModalClose}
          type={"edit"}
        />
      </Modal>


      {/* for Resume , pause and stop scan  */}
      <Modal
        open={opModalOpen}
        onClose={()=>{setOpModalOpen(false)}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            width: "50%",
          }}
          className="bg-white rounded-10 shadow dark:bg-lightBlack dark:text-white p-1p"
        >
          {/*Uppermost div element > Edit Modal*/}
          <div>
            {/* Heading of the modal */}
            <div className="text-2xl font-bold flex items-center justify-center pb-2p border-b border-b2 dark:border-b9">
              {operation} Scan Profile
            </div>

            {/* Text asking user if he wants to edit the profile */}
            <div style={{ paddingTop: "2%" }}>
              Are you sure, you want to {operation} profile{" "}
              <span className="font-bold">{row?.nameData}</span> ?
            </div>

            {/* If edit modal step is 1 then provide user with an input box where he can enter the enter 2fa auth code */}
           <div>
                <div className="font-bold mt-2p">
                  Two Factor Authentication:
                </div>
                <input
                  type="text"
                  className="second-step-input bg-inputBg dark:bg-inputBgDark h-15"
                  value={twoFaInput}
                  onChange={(e) => {
                    setTwoFaInput(() => e.target.value);
                  }}
                />
              </div>
            

            {/* div element for holding two buttons 
                [leftbutton]  => No (editmodalstep 0) | Cancel (editmodalsetp 1) 
                [right button] => Yes (editmodalstep 0) | Done (editmodalstep 1)
             */}
            <div className="flex items-center justify-end mt-2p">
              {/* 
                  [LeftButton] => No | Cancle 
                  in both the cases onClick event remains the same
                  onClick => handleEditModalClose() 
              */}
              <div
                className="cursor-pointer mr-8"
                onClick={()=>{setOpModalOpen(false)}}
              >
                Cancel
              </div>

              {/* 
                [RightButton] => Yes | Done 
                onClick event on step 0 => update the current edit modal step state from 0 to 1
                onClick event on step 1 => call handleEditClick() handler ( this will close this modal and open a new one ) 
              */}
              <div
                className={
                  "h-15 w-60 font-bold text-white rounded-10 cursor-pointer flex items-center justify-center" +
                  blueGradient1
                }
                onClick={async () => { 
                  let api_url = "";

                  if(operation === "Pause"){
                    api_url = "/scanprofile/pause"
                  }else if(operation === "Resume"){
                    api_url = "/scanprofile/resume"
                  }else{
                    api_url = "/scanprofile/cancel"
                  }
                  setOpLoading(true);
                  const res = await pythonPostFetch(api_url, {
                    url:row.weburl,
                    version:row.version,
                    token:twoFaInput
                  });
                  setOpLoading(false);

                  if(res.status === "ok"){
                    toast.success("Scan " + operation + "successfull.")
                  }else{
                    toast.error("Some Error Occured");
                  }

                  setOpModalOpen(false);
                  setTwoFaInput("");




                }}
              >
              {opLoading ?  <Loader/> : "Done" }
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default DotButton;
