import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";

export default function VulnerabilityReportSkeleton() {
  return (
    <div className="dark:border-b9 border-b border-opacity-20">
      <div>
        <div
          style={{
            padding: "1% 0",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "default",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <div className="mr-2p">
                <Skeleton variant="circular" width={50} height={50} />
              </div>
              <div className="flex gap-2 w-full grow">
                <Skeleton width={"30%"} height={50} />
                <Skeleton width={"70%"} height={50} />
              </div>
            </div>
            <div style={{ position: "relative", width: "20%" }} className="">
              <div
                style={{
                  position: "absolute",
                  right: "0",
                  top: "20px",
                  fontSize: "12px",
                }}
                className="opacity-50 dark:text-white"
              >
                <Skeleton width={100} height={20} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          marginLeft: "60px",
          // fontSize: "14px",
          padding: "1% 0",
        }}
      >
        <Skeleton variant="text" width={200} />

        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
      </div>
      <div
        style={{
          marginLeft: "60px",
          // fontSize: "14px",
          padding: "1% 0",
        }}
      >
        <Skeleton variant="text" width={250} />

        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
      </div>
      <div
        style={{
          marginLeft: "60px",
          // fontSize: "14px",
          padding: "1% 0",
        }}
      >
        <Skeleton variant="text" width={250} />

        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
      </div>
      <div
        style={{
          marginLeft: "60px",
          // fontSize: "14px",
          padding: "1% 0",
        }}
      >
        <Skeleton variant="text" width={250} />

        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
        <Skeleton variant="text" width={`${Math.random() * 100}%`} />
      </div>
      <div
        style={{
          marginLeft: "60px",
          // fontSize: "14px",
          padding: "1% 0",
        }}
      >
        <Skeleton variant="text" width={200} />

        <Skeleton variant="rect" width={400} height={200} />
      </div>
    </div>
  );
}
