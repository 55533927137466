import React from "react";
import Box from "@mui/material/Box";
import SecondaryButton from "components/ui/buttons/SecondaryButton";
import { postFetch } from "lib/fetch";
import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { blueGradient1 } from "components/tailwindClasses";
import Loader from "components/ui/loaders/Loader";

function AddMember({ handleClose }) {
  const vuln_id = useSelector(
    (state) => state.targetDashboard.currentVulnerability
  );
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  let handleSubmit = async (e) => {
    setIsLoading(() => true);
    e.preventDefault();
    const formEle = document.getElementById("form02582-52");

    if (formEle && formEle.checkValidity()) {
    } else {
      formEle.reportValidity();
      return;
    }

    let res = await postFetch("/referResearcher", {
      email,
      description,
      vuln_id,
    });
    if (res?.status == "ok") {
      if (res?.isAdded) {
        toast.success("User added in vulnerability");
      } else if (res?.isAdded == false) {
        toast.success("User referred in vulnerability");
      }
      handleClose();
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else toast.error("Some error occured, try later");

    setIsLoading(() => false);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "800px",
        p: "1% 2%",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
      }}
      className="bg-white dark:bg-lightBlack dark:text-white"
    >
      <form action="" onSubmit={handleSubmit} id="form02582-52">
        <div
          style={{
            fontSize: "20px",
            fontWeight: "700",
            padding: "1%",
            textAlign: "center",
          }}
          className="border-b-2 border-b1 dark:border-b9"
        >
          Add New Member
        </div>

        <div style={{ fontWeight: "500", fontSize: "16px" }}>
          <div className="my-1">Email Address</div>
          <input
            className="first-step-input"
            type={"email"}
            style={{ margin: "0", height: "6vh" }}
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <div className="my-1">Comment</div>
          <textarea
            name=""
            id=""
            rows="5"
            style={{
              resize: "none",
              width: "100%",
              boxSizing: "border-box",
              padding: "1%",
              background: "rgba(0, 0, 0, 0.08)",
              border: " 1px solid rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
              fontSize: "16px",
            }}
            required
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          className="pt-2 mt-2 border-t-2 border-b1 dark:border-b9 "
        >
          <div
            style={{
              // marginTop: "3.2%",
              textDecoration: "none",
              color: "#2D3B51 !important",
              cursor: "pointer",
              marginRight: "32px",
            }}
            onClick={handleClose}
          >
            Cancel
          </div>
          <button
            type="submit"
            className={
              blueGradient1 +
              " flex items-center justify-center  w-60 rounded-10 h-15 font-bold"
            }
            disabled={isLoading}
          >
            {isLoading ? <Loader /> : "Add"}
          </button>
        </div>
      </form>
    </Box>
  );
}

export default AddMember;
