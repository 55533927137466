import React, { useEffect, useState } from "react";
import TargetLayout from "./TargetLayout";

import Description from "./Description/Index";
import DomainInfo from "./DomainInfo/Index";
import Whois from "./Whois/Index";
import NameServers from "./NameServers/Index";
import NsHistory from "./NsHistory/Index";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import targetDashboardSlice, {
  targetDashboardSliceActions,
} from "store/targetDashboardSlice";
import { targetInfoSliceActions } from "store/targetInfoSlice";
import Loader from "components/ui/loaders/Loader";
import { postFetch,pythonPostFetch } from "lib/fetch";

function Index(props) {
  let activeOption = useSelector(
    (state) => state.targetDashboard?.home?.activeDomainInfoOption
  );
  const domainInfoData = useSelector(
    (state) => state.targetDashboard?.home?.domainInfo
  );

  let websiteUrl = useSelector(
    (state) => state.targetDashboard.home.websiteUrl
  );

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(true); // this is flipped to cause reload

  const handleReload = () => {
    setReload((prev) => !prev);
    dispatch(targetDashboardSliceActions.setDomainInfo(null));
  };

  useEffect(() => {
    let getData = async () => {
      setIsLoading(() => true);

      let res = await postFetch("/domainDetails", {
        domain: websiteUrl.replaceAll("/","").split(":")[1],
      });


      if (res?.status === "OK" ) {
        let data = res?.data;
 
        dispatch(targetDashboardSliceActions.setDomainInfo(data.response));

        // dispatch(targetInfoSliceActions.setScreenshotUrl(data?.ss_data));
      } else {
        dispatch(targetDashboardSliceActions.setDomainInfo(null));
        // dispatch(targetInfoSliceActions.setScreenshotUrl(null));
      }

      setIsLoading(() => false);
    };

    websiteUrl && getData();
  }, [websiteUrl, reload]);

  return (
    <TargetLayout isLoading={isLoading} setReload={handleReload}>
      {isLoading && domainInfoData != {} ? (
        <Loader />
      ) : (
        <>
          {activeOption == 0 && <Description />}
          {activeOption == 1 && <DomainInfo />}
          {activeOption == 2 && <Whois />}
          {activeOption == 3 && <NameServers />}
          {/* {activeOption == 4 && <NsHistory />} */}
        </>
      )}
    </TargetLayout>
  );
}

export default Index;
