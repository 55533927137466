import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { activePageActions } from "../../../store/activePageSlice";

import Discover from "./Discover/Index";
import Shortlisted from "./Shortlisted/Index";

const inactiveButtonClass = "flex justify-center items-center mr-2w h-15 w-60";
const activeButtonClass =
  "flex justify-center items-center mr-2w h-15 w-60 font-bold text-white bg-blue1 rounded-10";

function Index(props) {
  // active page
  let dispatch = useDispatch();
  dispatch(activePageActions.setActivePage(3));

  let [activeOption, setActiveOption] = useState(0);

  return (
    <div className="p-2p">
      <div className="bg-white rounded-10">
        <div className="flex grow items-center text-base font-normal h-15 cursor-default">
          <div
            className={
              activeOption == 0 ? activeButtonClass : inactiveButtonClass
            }
            onClick={() => {
              setActiveOption(() => 0);
            }}
          >
            Discover
          </div>
          <div
            className={
              activeOption == 1 ? activeButtonClass : inactiveButtonClass
            }
            onClick={() => {
              setActiveOption(() => 1);
            }}
          >
            Shortlisted
          </div>
          <div
            className={
              activeOption == 2 ? activeButtonClass : inactiveButtonClass
            }
            onClick={() => {
              setActiveOption(() => 2);
            }}
          >
            Deal
          </div>
        </div>
        <div className="py-1p px-2p">
          <input
            className="w-full p-4 text-base h-15 border-b2 rounded bg-inputBg"
            placeholder="Search"
          />

          <div className="flex items-center mt-1p">
            <span>Filter :</span>
            <button className="py-1 px-2 text-sm text-filterText bg-filterBg border-2 border-b3 rounded mx-2">
              Mobile Application
            </button>
            <button className="py-1 px-2 text-sm text-filterText bg-filterBg border-2 border-b3 rounded mx-2">
              ISO
            </button>
            <button className="py-1 px-2 text-sm text-filterText bg-filterBg border-2 border-b3 rounded mx-2">
              Rating
            </button>
          </div>
        </div>
      </div>

      {activeOption == 0 && <Discover />}
      {activeOption == 1 && <Shortlisted />}
    </div>
  );
}

export default Index;
