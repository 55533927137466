import { Box, Button, Divider, Menu, MenuItem, Modal } from "@mui/material";
import Fade from "@mui/material/Fade";
import { blueGradient1 } from "components/tailwindClasses";
import { pythonPostFetch } from "lib/fetch";

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import EditScanEngineModal from "../EditEngine/Index";
import { editScanEngineSliceActions } from "store/scanner/editScanEngineSlice";

/*
  row keys: 
  _id,name,scan_type,threads,crawl_scope,crawl_depth,max_links_to_scroll,scan_speed
 */

function DotButton({ row, isBin }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);

  const [openEditModalBox, setOpenEditModalBox] = React.useState(false);
  const [openRestoreModal, setOpenRestoreModal] = React.useState(false);
  const [deleteModalStep, setDeleteModalStep] = useState(0);
  const [editModalStep, setEditModalStep] = useState(0);
  const [restoreModalStep, setRestoreModalStep] = useState(0);

  const handleDeleteModalOpen = () => {
    if (!user.twoFaEnabled) {
      toast.error("please enable two factor Authentication to delete engine");
    } else setOpenDeleteModal(true);
  };

  const handleEditModalOpen = () => {
    if (!user.twoFaEnabled) {
      toast.error("Please enable two factor Authentication to edit engine");
    } else setOpenEditModalBox(true);
  };

  const handleRestoreModalOpen = () => {
    if (!user.twoFaEnabled) {
      toast.error("please enable two factor Authentication to restore engine");
    } else setOpenRestoreModal(true);
  };

  const [twoFaInput, setTwoFaInput] = useState("");

  const handleDeleteModalClose = () => setOpenDeleteModal(false);
  const handleRestoreModalClose = () => setOpenRestoreModal(false);
  const handleEditModalClose = () => setOpenEditModal(false);
  const handleEditModalBoxClose = () => setOpenEditModalBox(false);

  const handleEditClick = () => {
    console.log("2fa done ");
    handleEditModalClose(); // close the 2fa input modal
    console.log("2fa closed");

    // before opening clear the previous state
    dispatch(editScanEngineSliceActions.reset());
    setOpenEditModalBox(true); // open edit box
    console.log("modal editor opened");
  };

  const handleDeleteClick = async () => {
    console.log("Delete button clicked.");
    // Dec1123 - push this on the server side. (1)
    /*
    if (!twoFaInput) {
      toast.error("please enter two factor Authentication code");
      return;
    }
 */
    const res = await pythonPostFetch("/scanengine/delete", {
      scan_engine_id: row?._id,
      input_token: twoFaInput,
    });

    if (res?.status == "ok") {
      toast.success("engine deleted successfully");
      setOpenDeleteModal(() => false);
      setTwoFaInput(() => null);
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else {
      toast.error("could not delete engine, try later");
    }
  };
  const handleAddAgain = async () => {
    if (!twoFaInput) {
      toast.error("please enter two factor Authentication code");
      return;
    }
    const res = await pythonPostFetch("/scanengine/restore", {
      scan_engine_name: row?.name,
    });

    if (res?.status == "ok") {
      toast.success("engine restored successfully");
      setOpenRestoreModal(() => false);
      setTwoFaInput(() => null);
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else {
      toast.error("could not restore engine, try later");
    }
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img src="/dotButtonOutline.svg" alt="" width={"20px"} />
      </Button>

      {!isBin && (
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleEditModalOpen}>
            <img src="/editPenBlue.svg" alt="" className="mr-2" /> Edit
          </MenuItem>
          <Divider sx={{}} />
          <MenuItem onClick={handleDeleteModalOpen}>
            <img src="/bin.svg" alt="" className="mr-2" /> Delete
          </MenuItem>
        </Menu>
      )}
      {isBin && (
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleRestoreModalOpen}>
            <img src="/plusBlue.svg" alt="" className="mr-2" /> Add Again
          </MenuItem>
          <Divider sx={{}} />
          <MenuItem onClick={() => {}}>
            <img src="/bin.svg" alt="" className="mr-2" /> Delete Permanently
          </MenuItem>
        </Menu>
      )}

      {/* This modal takes 2fa for edit scan engine and then opens another modal for editing and sends 2fa with updated data to the server */}
      <Modal
        open={openEditModal}
        onClose={handleEditModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            width: "50%",
          }}
          className="bg-white rounded-10 shadow dark:bg-lightBlack dark:text-white p-1p"
        >
          <div>
            <div className="text-2xl font-bold flex items-center justify-center pb-2p border-b border-b2 dark:border-b9">
              Edit Scan Engine
            </div>

            <div style={{ paddingTop: "2%" }}>
              Are you sure, you want to edit engine{" "}
              <span className="font-bold">{row?.name}</span> ?
            </div>

            {editModalStep == 1 && (
              <div>
                <div className="font-bold mt-2p">
                  Two Factor Authentication:
                </div>
                <input
                  type="text"
                  className="second-step-input bg-inputBg dark:bg-inputBgDark h-15"
                  value={twoFaInput}
                  onChange={(e) => {
                    setTwoFaInput(() => e.target.value);
                  }}
                />
              </div>
            )}

            <div className="flex items-center justify-end mt-2p">
              <div
                className="cursor-pointer mr-8"
                onClick={handleEditModalClose}
              >
                {editModalStep == 0 ? "No" : "Cancel"}
              </div>

              <div
                className={
                  "h-15 w-60 font-bold text-white rounded-10 cursor-pointer flex items-center justify-center" +
                  blueGradient1
                }
                onClick={() => {
                  editModalStep == 0 && setEditModalStep(() => 1);

                  editModalStep == 1 && handleEditClick();
                }}
              >
                {editModalStep == 0 ? "Yes" : "Done"}
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            width: "50%",
          }}
          className="bg-white rounded-10 shadow dark:bg-lightBlack dark:text-white p-1p"
        >
          <div>
            <div className="text-2xl font-bold flex items-center justify-center pb-2p border-b border-b2 dark:border-b9">
              Delete Scan Engine
            </div>

            <div style={{ paddingTop: "2%" }}>
              Are you sure, you want to delete engine{" "}
              <span className="font-bold">{row?.name}</span> ?
            </div>

            {deleteModalStep == 1 && (
              <div>
                <div className="font-bold mt-2p">
                  Two Factor Authentication:
                </div>
                <input
                  type="text"
                  className="second-step-input bg-inputBg dark:bg-inputBgDark h-15"
                  value={twoFaInput}
                  onChange={(e) => {
                    setTwoFaInput(() => e.target.value);
                  }}
                />
              </div>
            )}

            <div className="flex items-center justify-end mt-2p">
              <div
                className="cursor-pointer mr-8"
                onClick={handleDeleteModalClose}
              >
                {deleteModalStep == 0 ? "No" : "Cancel"}
              </div>

              <div
                className={
                  "h-15 w-60 font-bold text-white rounded-10 cursor-pointer flex items-center justify-center" +
                  blueGradient1
                }
                onClick={() => {
                  deleteModalStep == 0 && setDeleteModalStep(() => 1);

                  deleteModalStep == 1 && handleDeleteClick();
                }}
              >
                {deleteModalStep == 0 ? "Yes" : "Done"}
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openRestoreModal}
        onClose={handleRestoreModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            width: "50%",
          }}
          className="bg-white rounded-10 shadow dark:bg-lightBlack dark:text-white p-1p"
        >
          <div>
            <div className="text-2xl font-bold flex items-center justify-center pb-2p border-b border-b2 dark:border-b9">
              Restore Scan Engine
            </div>

            <div style={{ paddingTop: "2%" }}>
              Are you sure, you want to restore engine{" "}
              <span className="font-bold">{row?.name}</span> ?
            </div>

            {restoreModalStep == 1 && (
              <div>
                <div className="font-bold mt-2p">
                  Two Factor Authentication:
                </div>
                <input
                  type="text"
                  className="second-step-input bg-inputBg dark:bg-inputBgDark h-15"
                  value={twoFaInput}
                  onChange={(e) => {
                    setTwoFaInput(() => e.target.value);
                  }}
                />
              </div>
            )}

            <div className="flex items-center justify-end mt-2p">
              <div
                className="cursor-pointer mr-8"
                onClick={handleRestoreModalClose}
              >
                {restoreModalStep == 0 ? "No" : "Cancel"}
              </div>

              <div
                className={
                  "h-15 w-60 font-bold text-white rounded-10 cursor-pointer flex items-center justify-center" +
                  blueGradient1
                }
                onClick={() => {
                  restoreModalStep == 0 && setRestoreModalStep(() => 1);

                  restoreModalStep == 1 && handleAddAgain();
                }}
              >
                {restoreModalStep == 0 ? "Yes" : "Done"}
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openEditModalBox}
        onClose={handleEditModalBoxClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditScanEngineModal
          twoFaInput={twoFaInput}
          setTwoFaInput={setTwoFaInput}
          row={row}
          handleClose={handleEditModalBoxClose}
        />
      </Modal>
    </div>
  );
}

export default DotButton;
