import React from "react";

import OtpSection from "components/imports/OtpSection/OtpSection";
import PrimaryButton from "components/ui/buttons/SecondaryButton";
import "components/style.css";
import { useNavigate } from "react-router-dom";
import { blueGradient1 } from "components/tailwindClasses";
import { useState } from "react";

function EmailOtp() {
  let navigate = useNavigate();
  let handleSubmitClick = () => {
    navigate("/link2");
  };

  const [otp, setOtp] = useState("");

  return (
    <div className="flex h-full">
      <div className={"relative w-[58%]  h-full text-white " + blueGradient1}>
        <div className="absolute top-1/2 left-[45%] -translate-x-1/2 -translate-y-1/2">
          <div className="text-4xl w-fit">
            <b>
              OTP <br /> Authentication
            </b>
            <div className="text-2xl w-fit text-[#c3d2d2]">
              We’ve sent you One Time Password OTP on your email for
              verification. <br /> Please enter your 6-digit OTP
            </div>
          </div>
        </div>
        <img
          src="/otpScreenLogo.svg"
          alt=""
          className="absolute right-0 bottom-0 mr-3p mb-2p"
        />
        <img
          src={"/loginBgLines.svg"}
          className="  absolute top-0 left-0 w-1/3  -scale-y-100"
          alt="anim"
        />
      </div>

      <div className="relative w-[42%] bg-[#ececec] h-full">
        <div className="h-full flex flex-col justify-center items-center">
          <div className="absolute top-[5%] left-[5%]">
            <div className="text-xl font-medium">
              <img
                src={"cyteHackLogo.svg"}
                className="logo inline h-10"
                alt="anim"
              />
              &nbsp; CyeNET
            </div>
          </div>

          <div className="flex flex-col w-full p-10">
            <h1 className="text-3xl text-center mb-5">
              <b>Email OTP</b>
            </h1>
            <div className="flex justify-center items-center" style={{}}>
              <OtpSection OTP={otp} setOTP={setOtp} />
            </div>
            <div className="flex justify-end mt-2p">
              <div
                onClick={handleSubmitClick}
                className={
                  " w-full h-15 rounded-10 font-bold flex justify-center items-center" +
                  blueGradient1
                }
              >
                Done
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailOtp;
