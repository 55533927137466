import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "./Stepper";

import Step1 from "./Step1/Index";
import Step2 from "./Step2/Index";
import Step3 from "./Step3/Index";
import Step4 from "./Step4/Index";
import Step5 from "./Step5/Index";
import { useDispatch, useSelector } from "react-redux";
import { postFetch, pythonPostFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { addReportSliceActions } from "store/addReportSlice";
import Loader from "components/ui/loaders/Loader";
import { blueGradient1 } from "components/tailwindClasses";
import editScanProfileSlice, {
  editScanProfileSliceActions,
} from "store/scanner/editScanProfileSlice";
import {Modal} from "@mui/material";
import { getFetch, pythonGetFetch } from "lib/fetch";
import dayjs from "dayjs";

/*
  This index file exports the While edit Box modal 
       the row (containing data about asset to be edited) , handleClose funct, type = edit 

       row
    {"scan_id":"09e2a3d9d52ed24563fc6284d2","version":1,"scan_engine_name":"tanishq doing testing","asset_id":"64b597d8499e90eafcae469e","progress":null,"data":{"bug_variants":[0,0,79,0],"the_risk_score":4.953271028037383,"risk_score_symbol":"C"},"schedule":[false],"is_paused":false,"is_stopped":false,"is_deleted":false,"next_scheduling":"-","scan_name":"Lovely Scan"},
 */

function Index({ row, header, handleClose, type = "edit" }) {
  // this variable tracks the progress through edit scan profile
  // [ steps from 0 - 1 - 2 - 3 - 4 ]
 


  let [activeStep, setActiveStep] = useState(-1);
  const dispatch = useDispatch();
  const [twoFaInput,setTwoFaInput] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false); 
   // steps through edit model -> asking different questions and changing buttons text and handlers
   const [editModalStep, setEditModalStep] = useState(0);
  const [loadingDataText, setLoadingDataText] = useState([]);

  // Loading scan Profile data from the editScanProfileSlice
  let editScanProfileData = useSelector(
    (state) => state.scanner.editScanProfile
  );

  // useEffects for loading list of assets , list of engines and selected engine data
  useEffect(() => {
    const getAssetUrls = async () => {
      setLoadingData(() => true); // started loading asset list
      setLoadingDataText(() => ["Loading Assets ...", 1]);

      const res = await getFetch("/manager/assetDetailsv2"); // Get the list of asets from the node server
  

      if (res?.assets.length > 0) {
        dispatch(editScanProfileSliceActions.setAssets(res?.assets));
        getEngines();
      } else if (res?.error && res?.message) {
        toast.error(res.message);
      } else {
        toast.error("Could not fetch assets, try later!");
      }

      // below dispatch set the asset name
      // moved this out of function
      // dispatch(editScanProfileSliceActions.setAsset(header.asset_name)); // *** This name should be present inside the asset list in order to be automatically selected
    };

    const getEngines = async () => {
      try {
        setLoadingDataText(() => ["Loading Scan Engine List ... ", 2]);
        const res = await pythonGetFetch("/scanprofile/scan_engine_list");
        if (res?.status == "ok") {
          dispatch(editScanProfileSliceActions.setEngines(res?.data));
        } else if (res?.error && res?.message) {
          toast.error(res.message);
        } else {
          toast.error("could not fetch engines, try later!");
        }
      } catch (error) {
        console.error("Error fetching the scan engine list : " + error);
      } finally {
        fetchEngineDetails(row?.scanEngine);
      }
    };

    getAssetUrls();
  }, []);

  // use Effect engine list helper function
  const fetchEngineDetails = async (engineName) => {
    setLoadingDataText(() => ["Loading Engine Details ...", 3]);

    const res = await pythonPostFetch("/scanprofile/scanengine", {
      scan_engine_name: engineName,
    });

    if (res?.status == "ok") {
      setLoadingDataText("");
      setLoadingData(() => false);
      setActiveStep(() => 0);
      dispatch(
        editScanProfileSliceActions.setEngineData({
          scanType: res?.data?.scan_settings?.scan_type,
          scanSpeed: res?.data?.scan_settings?.scan_speed,
          threads: res?.data?.scan_settings?.threads,
          engineId: res?.data?.id,
          crawlScope: res?.data?.crawl_settings?.crawl_scope,
          crawlDepth: res?.data?.crawl_settings?.max_depth,
          maxLinksToCrawl: res?.data?.crawl_settings?.max_childs,
          robotsFile: res?.data?.crawl_settings?.robots_file,
          sitemapFile: res?.data?.crawl_settings?.sitemap_file,
        })
      );
    } else if (res?.error && res?.message) {
      toast.error(res.message);

      dispatch(
        editScanProfileSliceActions.setEngineData({
          scanType: null,
          scanSpeed: null,
          threads: null,

          crawlScope: null,
          crawlDepth: null,
          maxLinksToCrawl: null,
          robotsFile: null,
          sitemapFile: null,
        })
      );
    } else {
      toast.error("could not fetch engine details, try later!");

      dispatch(
        editScanProfileSliceActions.setEngineData({
          scanType: null,
          scanSpeed: null,
          threads: null,

          crawlScope: null,
          crawlDepth: null,
          maxLinksToCrawl: null,
          robotsFile: null,
          sitemapFile: null,
        })
      );
    }
  };

  /*
    called after the step 4 (final step)
    this functions creates a schema and sends it to the 
    api that will edit the current profile 
  */
  const handleSubmitClick = async (e) => {
    // if the date selected is past date then prompt the user to select a future date
    if (
      editScanProfileData.step3.date &&
      dayjs(editScanProfileData.step3.date).isBefore(dayjs(Date.now()))
    ) {
      toast.info("Please select a future date");
      return;
    }

    // object that will be sent in the request body
    const postData = {
      scan_id:row?.scanId,
      input_token : twoFaInput,
      schema: {
          asset_details: {
          scan_name: editScanProfileData.step1.scanName,
          target_definition: {
            web_app_url: editScanProfileData.step1.webAppUrl,
            tag: editScanProfileData.step1.tag,
          },
        },

        scan_settings: {
          //  scan_engine: editScanProfileData.step2.engineName, // since name was not required
          scan_engine: editScanProfileData.step2.engineId, // (replace) id which whatever new parameter in response of scan_engine_list
        },
        schedule: {
          schedule_scan: editScanProfileData.step3.scanNow == 1 ? false : true,
          recurring_scan:
            editScanProfileData.step3.scanNow == 0 &&
            editScanProfileData.step3.frequency != "once",
          date_time:
            new Date(editScanProfileData.step3.date).toISOString() ||
            new Date(Date.now() + 1000000 * 60 * 1000).toISOString(),
          frequency:
            editScanProfileData.step3.scanNow == 1
              ? "scanNow"
              : { once: "once", weekly: 7, biweekly: 14, monthly: 30 }[
                  editScanProfileData.step3.frequency
                ],
          scan_now: editScanProfileData.step3.scanNow == 1 ? true : false,
        },
        send_report: {
          email: editScanProfileData.step4.email,
          report_format: editScanProfileData.step4.reportFormat,
          secure_report: {
            password: editScanProfileData.step4.password,
            confirm_password: editScanProfileData.step4.confirmPassword,
          },
        },
      },
    };



    // we dont want to start a scan in editModalBox only update it
    // so replace the api /start_scan with /edit_scan (todo)
  
    // todo : replace this api endpoint

    if(twoFaInput?.length === 6){
      setIsloading(() => true);


    const res = await pythonPostFetch("/scanprofile/edit", postData);

    if (res?.status === "ok") {
      toast.success("Scan edited successfully ! ");

      //reload the scan list or figure out some way of updating the scan in place
      dispatch(editScanProfileSliceActions.reset(""));

      handleClose();
    } else if (res?.error && res?.message) {
      toast.error(res.message);
    } else {
      toast.error("Some error occured, try later!");
    }

    setIsloading(() => false);
  }else{
    toast.error("Enter the 2FA to edit the scan.")
  }
    // e.target.disabled = false;
  };
  // submit click handler ends ----

  const password = useSelector(
    (state) => state?.scanner?.editScanProfile?.step4?.password
  );
  const confirmPassword = useSelector(
    (state) => state?.scanner?.editScanProfile?.step4?.confirmPassword
  );

  /*
    [next] button => increment the step take through the stepper
  */
  const handleNextClick = () => {
    if (activeStep === 4) {
      handleSubmitClick();

      return;
    }
    const currentStepForm = document.getElementById(
      `step${activeStep + 1}-form`
    );

    if (activeStep == 2 && editScanProfileData.step3.scanNow == 0) {
      if (!editScanProfileData.step3.date) {
        toast.info("Please select a date");
        return;
      }
    }

    if (activeStep == 3 && password && confirmPassword) {
      if (password != confirmPassword) {
        // Set a custom validation message for confirmPassword field
        currentStepForm.fourthStepConfirmPassword.setCustomValidity(
          "Passwords do not match."
        );
        currentStepForm.reportValidity(); // Report the custom validity to show the error message

        return;
      } else {
        currentStepForm.fourthStepConfirmPassword.setCustomValidity(""); // Reset the custom validation message
      }
    }

    if (currentStepForm && currentStepForm.checkValidity()) {
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      currentStepForm?.reportValidity?.();
    }
  };

  const handleBackClick = () => {
    if (activeStep == 0 || activeStep == -1) {
      handleClose();
    } else {
      setActiveStep((activeStep) => activeStep - 1);
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "850px",
        height: "97%",
        // p: "1% 2%",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}
      className="bg-white dark:bg-lightBlack rounded-10 overflow-hidden"
    >
      <div
        style={{
          position: "fixed",
          width: "100%",
          top: "0",
          left: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
          height: "8vh",
          padding: "0 2%",
          zIndex: "10",
          fontSize: "20px",
          fontWeight: "700",
        }}
        className="bg-white dark:bg-lightBlack text-blue1"
      >
        {type === "edit" ? "Edit Scan Profile" : "Add New Scan Profile"}
      </div>

      <div style={{ padding: "8vh 0 10vh 0", position: "relative" }}>
        <div
          style={{
            position: "fixed",
            top: "8vh",
            left: "0",
            width: "20%",
            height: "calc(100% - 8vh)",
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.6)",
          }}
          className="flex  justify-center bg-[#ECECEC] dark:bg-lightBlack2 "
        >
          {/* stepper here */}
          <div className="mt-5">
            <Stepper activeStep={activeStep} />
          </div>
        </div>
        <div
          style={{
            marginLeft: "20%",
            maxHeight: "600px",
            width: "calc(100% - 20%)",
          }}
          className="bg-white dark:bg-lightBlack flex-grow overflow-y-scroll marker:"
        >
          {/* make stuff here */}
          {activeStep == -1 && (
            <div className="w-[100%]  pt-[250px] bg--400 flex flex-col justify-center items-center text-[#929292] text-bold">
              <Loader />
              <div className="h-[25px]"></div>
              {loadingDataText[0]}
              <br />
              <div className="flex items-center justify-center">
                {loadingDataText[1] + "/" + 3}
              </div>
            </div>
          )}
          {activeStep == 0 && <Step1 row={row} header={header} />}
          {activeStep == 1 && <Step2 row={row} />}
          {activeStep == 2 && <Step3 row={row} />}
          {activeStep == 3 && <Step4 />}
          {activeStep == 4 && <Step5 twoFaInput={twoFaInput} setTwoFaInput={setTwoFaInput}/>}
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: "0",
          left: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          borderTop: "1px solid rgba(0, 0, 0, 0.2)",
          height: "10vh",
          padding: "0 2%",
          zIndex: "10",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        <button
          style={{
            // marginTop: "3.2%",
            textDecoration: "none",
            color: "#2D3B51 !important",
            marginRight: "5vw",
            fontWeight: "500",
          }}
          onClick={handleBackClick}
        >
          {activeStep == 0 && "Cancel"}
          {activeStep != 0 && "Back"}
        </button>
        <button
          style={{ width: "200px", height: "50px" }}
          type="submit"
          disabled={isLoading}
          onClick={(e) => {
            handleNextClick();
          }}
          // className="w-full flex items-center justify-center text-base font-bold text-white h-15
          //  bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10 disabled:opacity-50 "

          className={
            blueGradient1 +
            " flex items-center justify-center cursor-pointer w-60 rounded-10  font-bold"
          }
        >
          {activeStep != 4 && "Next"}
          {activeStep == 4 && (isLoading ? <Loader /> : "Done")}
        </button>
      </div>
    
    </Box>
  );
}

export default Index;
