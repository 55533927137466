import React from "react";

function SecondaryButton({ type, onClick, children }) {
  return (
    <div
      onClick={onClick ? onClick : {}}
      type={type}
      className="w-full cursor-pointer flex items-center justify-center text-base font-bold text-white h-15 bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10"
    >
      {children}
    </div>
  );
}

export default SecondaryButton;
