import React from "react";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import { useSelector } from "react-redux";

export default function BackToCompanyList({
  isTargetDashboard,
  data,
  children,
}) {
  let user = useSelector((state) => state.user.data);
  const isDarkMode = useSelector((state) => state.general.theme) == "dark";

  return (
    <>
      {/* {user.role == "S_MANAGER" && (
        <div className="bg-white dark:bg-lightBlack py-1p px-2p flex justify-between items-center rounded-10 mb-1p">
          <div
            // style={{ display: "flex", alignItems: "center" }}
            className="flex items-center"
          >
            <Avatar alt="Remy Sharp" src="/avatar.svg" className="w-12 h-12" />
            <div className="inline font-bold text-xl pl-4 dark:text-lightBlack1">
              Company Name
            </div>

            {isTargetDashboard && (
              <div className="flex ml-4">
                <span className="font-medium">Target Summary : </span>{" "}
                <span className="ml-2">{data?.name}</span>
                <span className="ml-2">{data?.url}</span>
              </div>
            )}
          </div>
          <div>
            <Link to={"/"} className="no-underline flex items-center ">
              <img
                src={isDarkMode ? "/leftArrowDark.svg" : "/leftArrow.svg"}
                alt=""
                className="inline mr-3 text-center fill-[#1D26EF]"
              />
              Back to Company List
            </Link>
          </div>
        </div>
      )} */}
      {children}
    </>
  );
}
