import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FiberManualRecordRounded from "@mui/icons-material/FiberManualRecordRounded";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import SeverityButton from "components/ui/buttons/SeverityButton";
import { useDispatch, useSelector } from "react-redux";
import TableLoadingSkeleton from "components/ui/loaders/TableSkeleton";
import { pythonGetFetch, pythonPostFetch } from "lib/fetch";
import { dashboardSliceActions } from "store/scanner/dashboardSlice";

const columns = [
  { id: "cveName1", label: "CVE ID", minWidth: 100, align: "center" },
  { id: "severity1", label: "Severity", minWidth: 170 },
  { id: "total1", label: "Total", minWidth: 100, align: "center" },
];

function CVE(props) {
  const topCve = useSelector((state) => state.scanner.dashboard.topCve);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    // setIsLoading(() => true);
    const getData = async () => {
      const res = await pythonGetFetch("/dashboard/cwes");
      if (res?.status == "ok") {
        dispatch(dashboardSliceActions.setTopCve(res?.data));
      }
      setIsLoading(() => false);
    };
    getData();
  }, []);

  let rows = [];

  rows = topCve?.map?.((item) => {
    return {
      cveName1: item?.[0] ? item?.[0] : "NONE",
      severity1: SeverityButton(item?.[1]),
      total1: item?.[2],
    };
  });

  return (
    <div>
      <TableContainer
        sx={{ height: "465px", overflowY: "scroll" }}
        className="dark:bg-lightBlack "
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    minWidth: column.minWidth,
                    fontWeight: "600",
                  }}
                  className="dark:bg-lightBlack2 dark:text-white"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading && rows?.length == 0 ? (
            <TableLoadingSkeleton
              columnCount={3}
              rowCount={9}
              tableRowClassName={"dark:bg-lightBlack "}
              tableCellClassName={
                " dark:text-white dark:border-b dark:border-b9"
              }
            />
          ) : (
            <TableBody>
              {rows?.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    // sx={{
                    //   "&:last-child td, &:last-child th": {
                    //     border: 0,
                    //   },
                    // }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className=" dark:text-white"
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

export default CVE;
