import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import { getFetch } from "lib/fetch";
import { useDispatch } from "react-redux";
import { userSliceActions } from "store/userSlice";
import { logout as resetReduxState } from "store/index";

export default function DotButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  const navigate = useNavigate();

  let handleAccountClick = () => {
    handleClose();
    navigate("/profile");
  };
  let handleLogOut = async () => {
    handleClose();

    const data = await getFetch("/logout");

    if (data === "OK") {
      // deleting cookies

      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }

      // deleting local stroage data
      window.localStorage.clear();

      window.sessionStorage.clear();

      // clear all redux state
      resetReduxState();

      toast.success("Logged Out");
      navigate("/login");
    } else {
      toast.error("Error, Try Later !");
    }
  };
  return (
    <div>
      <div onClick={handleClick}>{props.children}</div>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleAccountClick} sx={{ width: "12vw" }}>
          Account Setting
        </MenuItem>
        <Divider sx={{}} />
        <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
      </Menu>
    </div>
  );
}
