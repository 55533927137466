export const columns = (isBin = false) => {
  let columns = [
    { id: "name", label: "Scan Engine Name", minWidth: 100 },
    { id: "type", label: "Scan Type", minWidth: 170, maxWidth: 300 },
    {
      id: "scope",
      label: "Crawl Scope",
      minWidth: 20,
      // align: "center",
    },
    {
      id: "depth",
      label: "Crawl Depth",
      minWidth: 10,
      // align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "maxLinks",
      label: "Max Links To Crawl",
      minWidth: 0,
      // align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "speed",
      label: "Scan Speed",
      minWidth: 0,
      // align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "threads",
      label: "Threads",
      minWidth: 0,
      // align: "center",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "options",
      label: "",
      minWidth: 50,
      // align: "right",
    },
  ];

  return columns;
};
