import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Modal } from "@mui/material";
import { useEffect } from "react";
import { getFetch, postFetch } from "lib/fetch";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";
import TableLoadingSkeleton from "components/ui/loaders/TableSkeleton";

function Index({ currentSection }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.data);
  const isDarkTheme = useSelector((state) => state?.general?.theme) == "dark";

  const currentTarget = useSelector(
    (state) => state.targetDashboard.currentTarget
  );

  // modal popup
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // data

  const data = useSelector((state) => state.targetDashboard.reports);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(() => true);

      const res = await getFetch("/targetReports", {
        asset_id: currentTarget.id,
      });
      if (res?.status == "ok") {
        dispatch(targetDashboardSliceActions.setReports(res?.data));
        dispatch(targetDashboardSliceActions.setReportCount(res?.data?.length));
      } else if (res?.error && res?.message) {
        toast.error(res.message);
        dispatch(targetDashboardSliceActions.setReports([]));
        dispatch(targetDashboardSliceActions.setReportCount(0));
      } else {
        toast.error("Couldn't load reports, try later !");
        dispatch(targetDashboardSliceActions.setReports([]));
        dispatch(targetDashboardSliceActions.setReportCount(0));
      }

      setIsLoading(() => false);
    };

    currentTarget?.id && getData();
  }, []);

  const Cols = [
    { id: "name", label: "Name" },
    { id: "format", label: "Format" },
    { id: "type", label: "Type" },
    { id: "status", label: "Status" },
    { id: "size", label: "Size" },
    { id: "date", label: "Generation Date" },
    { id: "downloadBtn", label: "", maxWidth: "50px" },
  ];

  let rows = [];
  rows = data.map((item) => {
    return {
      idProp: item?.id,
      name: item?.title,
      format: item?.format,
      type: item?.type,
      status: item?.status,
      size: item?.size,
      date: new Date(item?.createdDate).toDateString(),
      downloadBtn: item?.file_link ? (
        <img
          src={isDarkTheme ? "/downloadDark.svg" : "/download.svg"}
          alt=""
          className="cursor-pointer"
          onClick={() => {
            window.open(item?.file_link, "_blank");
          }}
        />
      ) : (
        ""
      ),
    };
  });

  return (
    <div>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="">
            <TableRow>
              {Cols?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column?.align}
                  sx={{
                    bgcolor: "rgba(217, 217, 217)",
                    maxWidth: column?.maxWidth,
                  }}
                  className=" dark:bg-lightBlack3 dark:text-lightBlack1"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading && rows?.length == 0 ? (
            <TableLoadingSkeleton rowCount={5} columnCount={Cols.length} />
          ) : (
            <TableBody>
              {rows?.map((row) => (
                <TableRow className="dark:bg-lightBlack bg-white">
                  {Cols.map((column) => {
                    const value = row[column.id];

                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className="dark:border-b9 border-b"
                      >
                        <p className="dark:text-white">
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </p>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

export default Index;
