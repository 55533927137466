import React, { useState, useEffect } from "react";
import { MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import ReportVisuals from "./ReportVisuals";
import { getFetch, postFetch } from "lib/fetch";
import { useSelector, useDispatch } from "react-redux";
import { addReportSliceActions } from "store/addReportSlice";
import Loader from "components/ui/loaders/Loader";
import { useLocation } from "react-router-dom";

function Index(props) {
  const location = useLocation();

  const [isLoading, setIsloading] = useState(false);
  const [isLoading2, setIsloading2] = useState(false);

  const assets = useSelector((state) => state.addReport?.assets);

  const currentAssetData = useSelector(
    (state) => state.addReport?.currentAsset
  );

  const [currentAsset, setCurrentAsset] = useState(
    currentAssetData?.id ? currentAssetData?.id : ""
  );

  let incomingState = location?.state;

  useEffect(() => {
    // this will be role based
    let getData = async () => {
      setIsloading(() => true);

      const res = await getFetch("/manager/assetDetailsv2");

      dispatch(addReportSliceActions.setAssets(res.assets));

      setIsloading(() => false);
    };
    getData();
  }, []);

  useEffect(() => {
    if (incomingState?.downloadReport && incomingState?.assetId) {
      handleChange(incomingState?.assetId);
      location.state = null;
    }
  }, []);

  const dispatch = useDispatch();

  const handleChange = async (assetId) => {
    setCurrentAsset(() => assetId);

    if (assetId != "") {
      setIsloading2(() => true);

      const res = await postFetch("/report/assetInfo", {
        asset_id: assetId,
      });
      if (res.status == "ok") {
        dispatch(addReportSliceActions.setCurrentAsset({ ...res.asset }));
      }

      setIsloading2(() => false);

      // console.log("res : ", res);
    } else dispatch(addReportSliceActions.setCurrentAsset({ id: null }));
  };

  return (
    <form id="step1-form">
      <div
        style={{
          padding: "2%",
          paddingBottom: "3%",
          borderRadius: "10px",
          marginBottom: "1%",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        <div className="avatar-text" style={{ fontWeight: "500" }}>
          Select Asset
        </div>

        <div>
          <Select
            sx={{
              width: "100%",
              boxSizing: "border-box",
              height: "45px",
              backgroundColor: "#D9D9D940",
              border: "0px solid rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
            }}
            className="dark:text-white bg-inputBg dark:bg-inputBgDark"
            required
            // value="7"
            //   id="demo-simple-select"
            value={currentAsset}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            MenuProps={{
              PaperProps: { sx: { maxHeight: 400 } },
            }}
          >
            <MenuItem value="">
              <em>Select Asset</em>
            </MenuItem>
            {isLoading ? (
              <div className="dark:bg-lightBlack3 p-2p">
                <Loader />
              </div>
            ) : (
              assets?.map((asset, i) => {
                return <MenuItem value={asset?.id}>{asset?.title}</MenuItem>;
              })
            )}
          </Select>
        </div>
      </div>
      {currentAsset != "" && (
        <div
          style={{
            borderRadius: "10px",

            // width: "603px",
            height: "431px",
            padding: "2%",
          }}
          className="bg-white dark:bg-lightBlack dark:text-white"
        >
          <div
            className="avatar-text"
            style={{
              borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
              paddingBottom: "1%",
              width: " 100%",
              fontWeight: "500",
            }}
          >
            Description
          </div>

          <div style={{ padding: "1%" }}>
            {isLoading2 ? (
              <Loader />
            ) : (
              <ReportVisuals data={currentAssetData} />
            )}
          </div>
        </div>
      )}
    </form>
  );
}

export default Index;
