import React, { useState, useEffect } from "react";
import Home from "./Home/Index";
import Vulnerabilities from "./Vulnerabilities/Index";
import Bug from "./Bug/Index";
import Report from "./Report/Index";
import { Modal, Switch } from "@mui/material";

import { BackToCompanyListLayout } from "features/companies";
import TargetInfoLayout from "Layouts/TargetInfoLayout";
import { useDispatch, useSelector } from "react-redux";
import SecondaryButton from "components/ui/buttons/SecondaryButton";
import { useLocation, useNavigate } from "react-router-dom";
import { addVulnerabilityReportSliceActions } from "store/vulnerabilityReport";
import { targetDashboardSliceActions } from "store/targetDashboardSlice";
import { toggleSwitch } from "store/bugTrackerSwitchSlice";

import {
  button1 as activeOptionClass,
  blueGradient1,
} from "components/tailwindClasses";

let inactiveOptionStyle = {
  cursor: "pointer",
  paddingLeft: "8px",
  width: "200px",
  textAlign: "center",
};
let activeOptionStyle = { width: "200px" };
let activeButtonStyle = {
  width: "100%",
  fontSize: "16px",
  fontWeight: "700",
  color: "#fff",
  height: "59px",
  background: "linear-gradient(180deg, #303e55 0%, #18202c 100%)",

  borderRadius: "10px",
};
function Index(props) {
  const location = useLocation();

  const navigate = useNavigate();

  if (!location?.state?.fromApp) {
    navigate("/");
  }

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const checked = useSelector((state) => state.switch.checked);

  const currentSection = useSelector(
    (state) => state.targetDashboard.currentSection
  );
  const targetId = useSelector(
    (state) => state.targetDashboard.currentTarget.id
  );

  const vulnerabilityCount = useSelector(
    (state) => state?.targetDashboard?.vulnerabilityCount
  );
  const bugCount = useSelector((state) => state?.targetDashboard?.bugCount);
  const reportCount = useSelector(
    (state) => state?.targetDashboard?.reportCount
  );

  let options = {
    Home: {
      title: "Home",
      option: 0,
      hidden: false,
    },
    Vulnerabilities: {
      title: "Vulnerabilities",
      option: 1,
      hidden: false,
      count: vulnerabilityCount, // Add count property
    },
    Bug: {
      title: "Bug Tracker",
      option: 2,
      hidden: false,
      count: bugCount, // Add count property
    },
    Report: {
      title: "Report",
      option: 3,
      hidden: false,
      count: reportCount,
    },
  };

  if (user.role == "MANAGER" || user.role == "ENGINEER") {
    options.Bug.hidden = true;
  }

  if (user.role == "ENGINEER" || user.role == "S_ENGINEER") {
    options.Report.hidden = true;
  }

  const handleChange = (event) => {
    dispatch(toggleSwitch(event.target.checked));
  };

  useEffect(() => {
    // coz link brings it center of page
    window.scrollTo(0, 0);

    let savedState = sessionStorage.getItem("targetDashboardState");

    if (savedState) {
      savedState = JSON.parse(savedState);
      if (!currentSection && savedState?.currentSection) {
        dispatch(
          targetDashboardSliceActions.setCurrentSection(
            savedState?.currentSection
          )
        );
      }
      if (!targetId && savedState?.targetId) {
        dispatch(
          targetDashboardSliceActions.setCurrentTarget({
            id: savedState.targetId,
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    let toSave = { currentSection: currentSection, targetId: targetId };
    sessionStorage.setItem("targetDashboardState", JSON.stringify(toSave));
  }, [currentSection, targetId]);

  // modal popup
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // let [role, setRole] = useState(0);

  const handleNewBugClick = (e) => {
    dispatch(addVulnerabilityReportSliceActions.reset());
    sessionStorage.removeItem("bugReportState");

    dispatch(addVulnerabilityReportSliceActions.setReportViewType("create"));

    navigate("/bugReport", { state: { fromApp: true, reportType: "create" } });
  };

  return (
    <div style={{ padding: "1%" }} className="">
      <TargetInfoLayout goBack={true}>
        <div className="rounded-t-10  bg-white dark:text-lightBlack2  dark:bg-lightBlack  flex justify-between mt-1p border-b border-b2 dark:border-b9">
          <div className="flex items-center">
            {Object.keys(options)
              .filter((key) => options[key].hidden === false)
              .map((key, i) => {
                const option = options[key];
                return (
                  <div
                    key={option.option}
                    onClick={() => {
                      dispatch(
                        targetDashboardSliceActions.setCurrentSection(
                          option.option
                        )
                      );
                    }}
                    style={
                      currentSection === option.option
                        ? activeOptionStyle
                        : inactiveOptionStyle
                    }
                  >
                    {currentSection === option.option ? (
                      <button
                        className={
                          // activeOptionClass
                          "w-full text-base font-bold text-white h-15  rounded-10 dark:bg-white dark:border-2 dark:border-white" +
                          blueGradient1
                        }
                      >
                        {option.title}
                        {!isNaN(option?.count) && (
                          <span className="ml-1 text-white bg-red-500 rounded-full px-2 py-1 text-xs">
                            {option.count}
                          </span>
                        )}
                      </button>
                    ) : (
                      <div>
                        <span>{option.title}</span>

                        {!isNaN(option?.count) && (
                          <span className="ml-1 text-white bg-red-500 rounded-full px-2 py-1 text-xs opacity-70">
                            {option.count}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          {user.role == "S_ENGINEER" &&
            currentSection == options.Home.option && (
              <div className="w-64 flex">
                <div
                  className={
                    "w-full cursor-pointer flex items-center justify-center text-base font-bold text-white h-15  rounded-10" +
                    blueGradient1
                  }
                  onClick={handleNewBugClick}
                >
                  Add New Bug
                </div>
              </div>
            )}
          <div>
            {currentSection === 2 ? (
              <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>

        {options.Home.hidden == false &&
          currentSection == options.Home.option && (
            <Home currentSection={options.Home.option} />
          )}
        {options.Vulnerabilities.hidden == false &&
          currentSection == options.Vulnerabilities.option && (
            <Vulnerabilities currentSection={options.Vulnerabilities.option} />
          )}
        {options.Bug.hidden == false &&
          currentSection == options.Bug.option && (
            <Bug currentSection={options.Bug.option} />
          )}
        {options.Report.hidden == false &&
          currentSection == options.Report.option && (
            <Report currentSection={options.Report.option} />
          )}
      </TargetInfoLayout>
    </div>
  );
}

export default Index;
