import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  targets: [],
  deletedTargets: [],
};

let targetSlice = createSlice({
  name: "targetSlice",
  initialState: initialState,
  reducers: {
    setTargets(state, action) {
      state.targets = action.payload;
    },
    setDeletedTargets(state, action) {
      state.deletedTargets = action.payload;
    },
  },
});

export const targetSliceActions = targetSlice.actions;

export default targetSlice;
