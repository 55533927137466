import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addAssetSliceActions } from "store/addAssetSlice";
import { NoMaxWidthTooltip } from "components/ui/NoMaxWidthTooltip";
import { Tooltip } from "@mui/material";

function SecondData(props) {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  let assetData = useSelector((state) => state.addAsset.asset);

  const category = assetData.type;

  let additionalWebsites =
    category == "Website" && assetData?.websiteSubdomains?.length - 1 > 0
      ? assetData.websiteSubdomains.length - 1
      : 0;

  const handleTitleChange = (e, index) => {
    if (additionalWebsites > 0) {
      let arr = Array.isArray(assetData.title)
        ? [...assetData?.title]
        : [assetData?.title];

      arr[index] = e.target.value;

      dispatch(addAssetSliceActions.setAsset({ ...assetData, title: arr }));
    } else {
      dispatch(
        addAssetSliceActions.setAsset({
          ...assetData,
          title: e.target.value,
        })
      );
    }
  };
  const handleDescriptionChange = (e, index) => {
    if (additionalWebsites > 0) {
      let arr = Array.isArray(assetData.description)
        ? [...assetData?.description]
        : [assetData?.description];

      arr[index] = e.target.value;

      dispatch(
        addAssetSliceActions.setAsset({ ...assetData, description: arr })
      );
    } else {
      dispatch(
        addAssetSliceActions.setAsset({
          ...assetData,
          description: e.target.value,
        })
      );
    }
  };

  // for changing from multiple websites to single website
  useEffect(() => {
    if (additionalWebsites == 0) {
      Array.isArray(assetData.title) &&
        dispatch(
          addAssetSliceActions.setAsset({
            ...assetData,
            title: assetData.title[0],
          })
        );
      Array.isArray(assetData.description) &&
        dispatch(
          addAssetSliceActions.setAsset({
            ...assetData,
            description: assetData.description[0],
          })
        );
    }
  }, [additionalWebsites]);

  const [sameDescriptionForAll, setSameDescriptionForAll] = useState(false);

  useEffect(() => {
    if (sameDescriptionForAll) {
      const firstDescription = Array.isArray(assetData.description)
        ? assetData?.description?.[0]
        : assetData?.description;

      const additionalDescriptions = assetData?.description?.slice?.(1);

      if (
        !additionalDescriptions?.every?.(
          (description) => description === firstDescription
        )
      ) {
        setSameDescriptionForAll(() => false);
      }
    }
  }, [assetData.description, sameDescriptionForAll]);

  const handleSameDescriptionChange = (e) => {
    const isSameDescriptionChecked = e.target.checked;
    setSameDescriptionForAll(() => isSameDescriptionChecked);

    if (isSameDescriptionChecked) {
      const firstDescription = Array.isArray(assetData.description)
        ? assetData?.description?.[0]
        : assetData?.description;

      const newDescriptions = Array.from(
        { length: additionalWebsites + 1 },
        () => firstDescription
      );

      dispatch(
        addAssetSliceActions.setAsset({
          ...assetData,
          description: newDescriptions,
        })
      );
    }
  };

  return (
    <>
      <div
        className=" flex w-full"
        style={{ fontSize: "24px", fontWeight: "600" }}
      >
        <div>Program Name</div>
        <Tooltip
          placement="right"
          describeChild
          // in title describe the step
          title="Provide a unique name for your program. Make it so that it is easy for you to identify the asset from its name. Description need not be unique."
        >
          <img
            src={isDarkTheme ? "/infoDark.svg" : "/info.svg"}
            alt=""
            className="w-5"
            style={{ marginLeft: "10px" }}
          />
        </Tooltip>
      </div>

      {additionalWebsites > 0 && (
        <div className="text-sm font-semibold mt-4">
          Website {1} : {assetData?.websiteSubdomains[0]}
        </div>
      )}
      <input
        required
        className="first-step-input dark:bg-lightBlack3"
        placeholder="Type Here"
        value={
          Array.isArray(assetData.title)
            ? assetData?.title[0]
            : assetData?.title
        }
        onChange={(e) => {
          handleTitleChange(e, 0);
        }}
      />

      <div className="text-lg font-semibold">Description</div>

      <textarea
        required
        className="second-step-input  dark:bg-lightBlack3"
        value={
          Array.isArray(assetData.description)
            ? assetData?.description[0]
            : assetData?.description
        }
        maxLength={200}
        placeholder={"Max limit : 200"}
        onChange={(e) => {
          handleDescriptionChange(e, 0);
        }}
      />

      {additionalWebsites > 0 && (
        <div className="mt-4">
          <label>
            <input
              type="checkbox"
              checked={sameDescriptionForAll}
              onChange={handleSameDescriptionChange}
            />{" "}
            Same description for all additional websites
          </label>
        </div>
      )}

      {additionalWebsites > 0 && (
        <div className="text-lg font-semibold mt-4">Additional Websites</div>
      )}
      {additionalWebsites > 0 &&
        assetData.websiteSubdomains.slice(1).map((website, index) => (
          <div key={index} className="mt-4">
            <div className="text-sm font-semibold mb-1">
              Website {index + 2} : {assetData?.websiteSubdomains[index + 1]}
            </div>
            <input
              required
              className="second-step-input dark:bg-lightBlack3"
              placeholder="Program Name"
              value={assetData?.title?.[index + 1]}
              onChange={(e) => {
                handleTitleChange(e, index + 1);
              }}
            />

            <textarea
              required
              className="second-step-input dark:bg-lightBlack3"
              maxLength={200}
              placeholder={"Max limit : 200"}
              value={assetData?.description?.[index + 1]}
              onChange={(e) => {
                handleDescriptionChange(e, index + 1);
              }}
            />
          </div>
        ))}
    </>
  );
}

export default SecondData;
