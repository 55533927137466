import React, { useRef } from "react";
import WeaknessTable from "./WeaknessTable";
import Popup from "reactjs-popup";
import CommentBox from "../BugReportSidebar/CommentBox";
import { useDispatch, useSelector } from "react-redux";
import { addVulnerabilityReportSliceActions } from "store/vulnerabilityReport";
import { blueGradient1 } from "components/tailwindClasses";

function Weakness(props) {
  const dispatch = useDispatch();
  let commentRef = useRef();

  const reportViewType = useSelector(
    (state) => state.addVulnerabilityReport.reportViewType
  );

  return (
    <div className="bg-white dark:bg-lightBlack  dark:text-white p-2p rounded-10">
      <div className="flex justify-between items-center border-b-2 dark:border-b9 border-b1">
        <div className="flex">
          <div className="font-bold text-xl mr-1">1.</div>
          <div className="flex grow flex-col pb-2">
            <div className="font-bold text-xl">Weakness</div>
            <div>
              Select the type of the potential issue you have discovered. Can’t
              pick just one? Select the best match or submit a separate report
              for each distinct weakness.
            </div>
          </div>
        </div>

        {(reportViewType == "edit" || reportViewType == "update") && (
          <Popup
            ref={commentRef}
            trigger={
              <div
                className={
                  blueGradient1 +
                  " flex items-center justify-center cursor-pointer rounded-full w-10 h-10 text-white font-bold text-4xl "
                }
              >
                {" "}
                +
              </div>
            }
            on="click"
            position={"left center"}
          >
            <CommentBox
              type={"input"}
              commentRef={commentRef}
              section={"weakness"}
              addComment={(comment, id, createdOn, isCreator) => {
                dispatch(
                  addVulnerabilityReportSliceActions.setWeaknessComment({
                    comment: comment,
                    id,
                    createdOn,
                    isCreator,
                  })
                );
              }}
            />
          </Popup>
        )}
      </div>

      <div>
        <WeaknessTable />
      </div>
    </div>
  );
}

export default Weakness;
