import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel } from "@mui/material";
import Stack from "@mui/material/Stack";
import { IconButton } from "@mui/material";
import { Button } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import { columns } from "./Columns";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronDown } from "react-icons/fa";
import Paper from "@mui/material/Paper";

import Skeleton from "@mui/material/Skeleton";
import {
  pythonGetFetch,
  pythonPostFetch,
  postFetch,
  localPostFetch,
} from "lib/fetch";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";

import DotButton from "./DotButton";
import TargetTableLoadingSkeleton from "components/ui/loaders/TableSkeleton";
import { scanEngineSliceActions } from "store/scanner/scanEngineSlice";
import { scanProfileSliceActions } from "store/scanner/scanProfileSlice";
import SeverityGroupButton from "components/ui/buttons/SeverityGroupButton";
import { targetDashboardSliceActions } from "store/scanner/targetDashboardSlice";
import { targetDashboardSliceActions as vmdrTargetDashboardSliceActions } from "store/targetDashboardSlice";

import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinearProgress from "@mui/material/LinearProgress";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import AccordionSkeleton from "components/ui/loaders/AccordionSkeleton";
import Gauge from "components/ui/charts/Gauge";
import PieAndBarChart from "components/ui/charts/PieAndBarChart";
import Menu from "@mui/material/Menu";

// universal semi circle gaugte for sm , md , lg screen size
const SemiCircleGauge = ({ value = 0 }) => {
  if (Number.isNaN(value)) {
    value = 0;
  }
  // value = 4;
  let degree = 0;
  if (value > 0) {
    degree = 180 * ((value * 10) / 100);
  }
  console.log("ee wala " + degree);
  const rotationStyle = {
    transform: `rotate(${degree}deg)`,
  };

  return (
    <div className="ml-10 mr-5 w-[200px] h-[100px]    m:w-[300px] m:h-[150px]  l:w-[400px] l:h-[200px] relative overflow-clip ">
      <div>
        {/* upper half */}
        <div
          className={`rounded-t-full block   w-[198px] h-[100px] m:w-[298px] m:h-[150px]  l:w-[398px] l:h-[200px] bg-gradient-to-r from-green-500 via-yellow-500 to-orange-500 to-red-500 border-[1px] border-[#F6F9FF] dark:border-[#32415E]`}
        ></div>
        {/* bottom half */}
        <div className="bg-gray-500  h-[100px] m:h-[150px]  l:h-[200px] rounded-b-full"></div>
      </div>

      <div
        className="relative  mt-[-200px] m:mt-[-300px]  l:mt-[-400px] "
        style={rotationStyle}
      >
        <div
          className={`rounded-t-full block w-[200px] h-[100px] m:w-[300px] m:h-[150px] l:w-[400px] l:h-[200px] bg-gray-200  border-[1px] border-[#F6F9FF] dark:border-[#32415E]`}
        ></div>
        <div className="h-[100px] m:h-[150px] l:h-[200px] bg-ink-500 rounded-b-full"></div>
        <div className="bg-[#F6F9FF] dark:bg-[#32415E] w-[100px] h-[100px]  m:w-[150px] m:h-[150px]  l:w-[200px] l:h-[200px] absolute mt-[-150px] ml-[50px] m:mt-[-225px] m:ml-[75px]  l:mt-[-300px] l:ml-[100px] rounded-full"></div>
      </div>

      <div className="relative  mt-[-170px] m:mt-[-260px] l:mt-[-340px] flex items-center justify-center h-full text-zinc-800 dark:text-gray-200 text-lg font-bold">
        {value.toFixed(2)}
      </div>
    </div>
  );
};

function Index({ search, bin = false, currentTab = 0 }) {
  const [isExpanded, setIsExpanded] = useState([]);
  const [progressStatusData, setProgressStatusData] = useState(
    { "amazon.in": [95, "active"] },
    { "facebook.com": [93, "recon"] }
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  const ishome = document.cookie;
  /**
 * 
 user data  : {"id":"642d070c0375274e22851dbf","name":"JagdishSingh","email":"jagdish@mailnesia.com","role":"MANAGER","roleId":"100","isSecProvider":false,"isAuth":true,"profileImage":"https://astrabucket00.s3.ap-northeast-1.amazonaws.com/uploads/2023-09-12_13-01-11sdfdsfsd.PNG?AWSAccessKeyId=AKIAQYO2HODBQXBL3QSC&Expires=1726059672&Signature=RgQVA3qi8R1dzE64PyusIEbn9fE%3D","companyName":"UrbanMart","phoneNumber":"7909458351","twoFaEnabled":false}
 */
  const user = useSelector((state) => state.user.data); // got the user data
  const data = useSelector((state) => state.scanner.scanProfile.profiles); // get a list of scan profiles in above format
  const headers = useSelector((state) => state.scanner.scanProfile.headers); // get all the updated headers corresponding to the data

  const [isLoading, setIsLoading] = useState(false);

  // use effect for fetching the scan profiles and filling up the state
  let getDataAll = async (setLoader) => {
    setLoader && setIsLoading(() => true);

    // calling the listing here
    const res = await pythonPostFetch("/scans/listing"); // returns the scan profile list array

    if (res?.status === "ok") {
      dispatch(scanProfileSliceActions.setProfiles(res?.data)); // data is set in simple array in store
    } else if (res?.error && res?.message) {
      toast.error(res?.message);
    } else {
      toast.error("could not fetch profiles, try later");
    }

    setLoader && setIsLoading(() => false);
  };

  useEffect(() => {
    if (data.length === 0) {
      getDataAll(true);
    }
  }, []);

  // this useeffect is getting all the header details

  useEffect(() => {
    setIsLoading(() => true);
    let getCorrespondingHeader = async (asset_id, scan_name, index) => {
      const res = await postFetch("/asset/getHeaderDetails", {
        asset_id: asset_id,
      });

      if (res?.status === "ok") {
        const headerData = res?.header_info;

        return { ...headerData, place: index, scan_name: scan_name };
      } else if (res?.error && res?.message) {
        console.log(res?.message);
        return {};
      } else {
        toast.error("could not fetch corresponding header details, try later");
        return {};
      }
    };

    // Create an array of promises
    const promises = data.map(async (item, index) => {
      const stuff = await getCorrespondingHeader(
        item.asset_id,
        item.scan_name,
        index
      );
      setIsLoading(() => false);
      return stuff;
    });

    // Use Promise.all to wait for all promises to resolve
    Promise.all(promises).then((headerDataArray) => {
      // headerDataArray.sort((a, b) => a.place - b.place);
      dispatch(scanProfileSliceActions.setHeaders(headerDataArray));
    });
  }, [data, dispatch]);

  // use effect for polling every 5 seconds and getting back the progress status
  // todo: make a variable out of timer
  // todo: replace localPostFetch with pythonPostFetch

  useEffect(() => {
    console.log("poll use effect triggered");
    let interval = setInterval(() => {
      const poll = async () => {
        const res = await pythonPostFetch("/scans/listing", {});

        if (res?.status === "ok") {
          // below line sets the progress status data

          dispatch(scanProfileSliceActions.setProfiles(res?.data)); // data is set in simple array in store
        } else {
          toast.error("Error fetching poll results");
        }
      };

      poll();
    }, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const [sortBy, setSortBy] = useState(""); // sortBy -> to sort by a particular field
  const [sortOrder, setSortOrder] = useState("asc"); // sortOrder -> stores a string could be ascending or desceding

  // Takes in the columnId
  const handleSort = (columnId) => {
    if (sortBy === columnId) {
      // if the clicked column is same as the current sorting column,
      // then toggel b/w asc and desc
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // if clicked column is not as same the current sorting column
      // then set the new sortby column
      // and initially sort order to be ascending
      setSortBy(columnId);
      setSortOrder("asc");
    }
  };

  // block for filtering scans according to the tab selected
  let currentData; // will hold data related to the specific tab

  if (currentTab === 0) {
    // if 0th (All) tab is selected then filter ( is_delted:true ) results
    currentData = data?.filter((i) => !i.is_deleted);
  } else if (currentTab === 1) {
    // else if 1st (Scheduled scan) tab is selected scans that are scheduled and not deleted
    // item.nextSchedule &&
    currentData = data?.filter((i) => {
      return (i.schedule & !i.is_deleted);
    });
  } else if (currentTab === 2) {
    // else if 2nd tab (bin) tab then show only those scans that were deleted
    currentData = data?.filter((i) => i.is_deleted);
  }

  // using currentData to sort data
  /**
   {"scan_id":"09e2a3d9d52ed24563fc6284d2","version":1,"scan_engine_name":"tanishq doing testing","asset_id":"64b597d8499e90eafcae469e","progress":null,"data":{"bug_variants":[0,0,79,0],"the_risk_score":4.953271028037383,"risk_score_symbol":"C"},"schedule":[false],"is_paused":false,"is_stopped":false,"is_deleted":false,"next_scheduling":"-","scan_name":"Lovely Scan"}, 
   */

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  /*
  {
    "scan_id":"09e2a3d9d52ed24563fc6284d2",
    "version":1
    ,"scan_engine_name":"tanishq doing testing",
    "asset_id":"64b597d8499e90eafcae469e",
    "progress":null,
    "data":{"bug_variants":[0,0,79,0],
            "the_risk_score":4.953271028037383,
            "risk_score_symbol":"C"},
    "schedule":[false],
    "is_paused":false,
    "is_stopped":false,
    "is_deleted":false,
    "next_scheduling":"-",
    "scan_name":"Lovely Scan"
  }
  */

  let rows =
    currentData && currentData?.length > 0
      ? currentData?.map((item, index) => {
          const headerinfo = headers.find((headeritem) => {
            return item.scan_name === headeritem.scan_name;
          });

          return {
            scanId: item.scan_id,
            headerInfo: headerinfo,
            assetId: item.asset_id,
            nameData: item.scan_name,
            weburl: item.web_app_url,
            targetIdData: item.asset_id,
            version: item.version,
            progress: item.progress,
            scanEngineId: item.scan_engine_id || "Dumm scan engine id", // scan engine id here

            name: (
              <Link
                className="no-underline text-[#4568EC] dark:text-gray-100  font-extrabold"
                aria-disabled
              >
                {item.scan_name}
              </Link>
            ),
            description: item.description,
            isPaused: item.is_paused,
            isStopped: item.is_stopped,
            isDeleted: item.is_deleted,
            nextSchedule: item.next_scheduling,
            scanEngine: item.scan_engine_name,
            riskScoreValue: item.data?.risk_score,
            riskScoreSymbol: item.data?.risk_score_symbol,
            totalVulnerabilities: isNaN(
              item?.data?.bug_variants?.critical +
                item?.data?.bug_variants?.high +
                item?.data?.bug_variants?.medium +
                item?.data?.bug_variants?.low
            )
              ? "-"
              : item?.data?.bug_variants?.critical +
                item?.data?.bug_variants?.high +
                item?.data?.bug_variants?.medium +
                item?.data?.bug_variants?.low,
            severityData: item?.data?.bug_variants || Array(4).fill(0),
            severity: (
              <SeverityGroupButton
                critical={item?.data?.bug_variants?.critical}
                high={item?.data?.bug_variants?.high}
                medium={item?.data?.bug_variants?.medium}
                low={item?.data?.bug_variants?.low}
              />
            ),

            options: <DotButton row={item} header={headerinfo} isBin={bin} />,
          };
        })
      : [];
  rows = rows.reverse();

  const Cols = columns(user?.role, bin);

  const handleNameClick = (scanId, name, website_url, targetId, assetId) => {
    dispatch(targetDashboardSliceActions.setCurrentScan({ id: scanId, name }));
    dispatch(targetDashboardSliceActions.setCurrentTargetID(targetId));
    dispatch(targetDashboardSliceActions.setWebsiteUrl(website_url));
    // // dispatch(targetDashboardSliceActions.setCurrentAssetId(assetId));
    let stuff = {
      name: "",
      id: assetId,
    };

    dispatch(vmdrTargetDashboardSliceActions.setCurrentTarget(stuff));
    navigate("/target-dashboard", { state: { fromApp: true } });
  };

  const theme = createTheme({
    palette: {
      cyan: {
        main: "#49DABD",
        light: "#49DABD",
        dark: "#49DABD",
      },
    },
  });

  const [optMenuOpen, setOptMenuOpen] = useState(false);

  //  <span className={"flex-1 max-w-[13%] pl-3"}>Scan Name</span>
  //   <span className={"flex-1 max-w-[19%]  pl-5 bg-gree-400"}>
  //   Descritption
  // </span>
  // <span className={"flex-1  max-w-[12%] pl-5 bg-yello-500"}>
  //   Next Schedule
  // </span>
  // <span className={"flex-1 max-w-[16%] pl-5 "}>Scan Engine</span>
  // <span className={"flex-1 max-w-[8%] "}>Risk Score</span>
  // <span className={"flex-1 max-w-[6%] ml-2 mr-24 bg-pik-500 "}>
  //   Total
  //   <br /> Vulnerabilities
  // </span>
  // <span className={"flex-1 "}>Severity</span>
  // <span className={"px-5 bg-reen-500 flex flex-col justify-center mr-5"}>
  //   <Button
  //     className="bg-blue-500"
  //     onClick={async (e) => {
  //       e.preventDefault();
  //       await getDataAll(false);
  //     }}
  //   >
  //     <ReplayIcon className="" />
  //   </Button>
  // </span>

  const numberOfSkeletonCells = 8;
  const numberOfRows = 7;

  // Create an array with the specified number of elements
  const cellArray = Array.from(
    { length: numberOfSkeletonCells },
    (_, index) => index + 1
  );
  const rowArray = Array.from(
    { length: numberOfRows },
    (_, index) => index + 1
  );

  return (
    <div className={""}>
      {/* table header */}
      {/* <div className={"bg-[#D9D9D940] flex h-[70px] pl-5 items-center "}>
        <span className={"flex-1 max-w-[13%] pl-3"}>Scan Name</span>
        <span className={"flex-1 max-w-[19%]  pl-5 bg-gree-400"}>
          Descritption
        </span>
        <span className={"flex-1  max-w-[12%] pl-5 bg-yello-500"}>
          Next Schedule
        </span>
        <span className={"flex-1 max-w-[16%] pl-5 "}>Scan Engine</span>
        <span className={"flex-1 max-w-[8%] "}>Risk Score</span>
        <span className={"flex-1 max-w-[6%] ml-2 mr-24 bg-pik-500 "}>
          Total
          <br /> Vulnerabilities
        </span>
        <span className={"flex-1 "}>Severity</span>
        <span className={"px-5 bg-reen-500 flex flex-col justify-center mr-5"}>
          <Button
            className="bg-blue-500"
            onClick={async (e) => {
              e.preventDefault();
              await getDataAll(false);
            }}
          >
            <ReplayIcon className="" />
          </Button>
        </span>
      </div> */}

      <>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow className="bg-[#D9D9D940] dark:bg-[#3D495B] h-[62px]">
                <TableCell className="">
                  <div className="font-bold">Scan Name</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">Target URL</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">Scan Engine</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">Status</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold">Risk Score</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">
                    Total
                    <br />
                    Vulnerabilities
                  </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">Severity</div>
                </TableCell>
                <TableCell>{""}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="dark:bg-[#2C394D]">
              {/*   const numberOfSkeletonCells = 8;
                  const numberOfRows = 7;


                  // Create an array with the specified number of elements
                  const cellArray = Array.from({ length: numberOfSkeletonCells }, (_, index) => index + 1);
                  const rowArray = Array.from({ length: numberOfRows }, (_, index) => index + 1);

               */}

              {rows.length === 0 && isLoading ? (
                <>
                  {rowArray.map((rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      className="bg-[#ffffff]dark:bg-[#2C394D]"
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      {cellArray.map((cellIndex) => (
                        <TableCell key={cellIndex}>
                          <Skeleton animation="wave" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </>
              ) : (
                <>
                  {rows.map((item, index) => {
                    // get scan progress and filter here
                    // possible states
                    // progress : null , string , number
                    let currentScanProgress;
                    if (item.progress) {
                      // alert(item.progress);
                      // progress is number or string
                      if (typeof item.progress === "string") {
                        if(item.progress === "in_queue"){
                        currentScanProgress = 0;
                        }else{
                        currentScanProgress = Number.parseInt(item.progress);
                        }
                      } else {
                        currentScanProgress = item.progress;
                      }
                    }

                    // fetch the accordion expand row data
                    const accordionDetail = headers.find((headeritem) => {
                      return item.nameData === headeritem.scan_name;
                    });

                    console.log(item);
                    console.log(accordionDetail);

                    const Status = {
                      IDLE: "Idle",
                      DELETED: "Deleted",
                      RUNNING: "Running",
                      PAUSED: "Paused",
                      DUE: "Due",
                      SCHEDULED: "Scheduled",
                      STOPPED: "Stopped",
                      COMPLETED: "Completed",
                      QUEUE: "in_queue",
                    };

                    let scanStatus = Status.IDLE;

                    if (item.isDeleted) {
                      scanStatus = Status.DELETED;
                    }

                    if (item.isPaused) {
                      scanStatus = Status.PAUSED;
                    }

                    if (item.isStopped) {
                      scanStatus = Status.STOPPED;
                    }

                    if (item.nextSchedule) {
                      if (new Date(item.nextSchedule) > new Date()) {
                        scanStatus = Status.SCHEDULED;
                        currentScanProgress = 0;
                      }

                      if (new Date(item.nextSchedule) < new Date()) {
                        if (!currentScanProgress) {
                          currentScanProgress = 0;
                        }
                        scanStatus = Status.DUE;
                      }
                    }

                    if (
                      scanStatus !== Status.SCHEDULED &&
                      !item.isDeleted &&
                      !item.isStopped &&
                      !item.isPaused &&
                      currentScanProgress === 0 &&
                      currentScanProgress >= 0 &&
                      currentScanProgress < 100
                    ) {
                      if (
                        item.schedule &&
                        item.nextSchedule &&
                        new Date(item.nextSchedule) < new Date() &&
                        !currentScanProgress
                      ) {
                        scanStatus = Status.DUE;
                      } else {
                        scanStatus = Status.RUNNING;
                      }
                    }

                    if (currentScanProgress === 100) {
                      scanStatus = Status.COMPLETED;
                    }

                    if (item.progress === Status.QUEUE){
                      scanStatus = "In Queue";  
                    }

                    console.log(scanStatus);

                    return (
                      <>
                        <TableRow
                          key={index}
                          className="bg-[#ffffff]dark:bg-[#2C394D]"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>
                            <div className="flex gap-2">
                              {" "}
                              <div
                                onClick={() => {
                                  handleNameClick(
                                    item.scanId,
                                    item.nameData,
                                    item.weburl,
                                    item.targetIdData,
                                    item.assetId
                                  );
                                }}
                              >
                                {item.name}
                              </div>
                              <button
                                onClick={() => {
                                  const el = document.getElementById(
                                    "expandableRow" + index
                                  );

                                  if (el) {
                                    if (el.style.display === "none") {
                                      el.style.display = "table-row";
                                    } else {
                                      el.style.display = "none";
                                    }
                                  }
                                }}
                              >
                                <FaChevronDown />
                              </button>
                            </div>
                          </TableCell>
                          <TableCell>
                            <a href={item.weburl} target="_blank">
                              {item.weburl}
                            </a>
                          </TableCell>

                          <TableCell>{item.scanEngine}</TableCell>
                          <TableCell>
                            {scanStatus === Status.SCHEDULED ? (
                              // <>{item.nextSchedule}</>
                              <>{scanStatus}</>
                            ) : (
                              <>{scanStatus}</>
                            )}
                            {/* {scanStatus} */}
                          </TableCell>

                          {scanStatus === Status.COMPLETED ? (
                            <>
                              <TableCell>
                                <p className="text-xl font-bold">
                                  {item.riskScoreSymbol}
                                </p>
                              </TableCell>
                              <TableCell>
                                <p className="font-medium text-xl">
                                  {item.totalVulnerabilities}
                                </p>
                              </TableCell>
                              <TableCell>{item.severity}</TableCell>
                            </>
                          ) : (
                            <TableCell colSpan={3}>
                              <div className="flex bg-pin-500 gap-2">
                                <p className="font-bold text-lg">
                                  {currentScanProgress} %
                                </p>
                                    {
                                      scanStatus === "In Queue" ? 
                                        <>
 <LinearProgress
                                  className="rounded-[50px] flex-1 dark:bg-[#D9D9D940] bg-gray-300"
                                  // color="cyan"
                                  sx={{
                                    my: "auto",
                                    height: "28px",
                                    width: "200px",
                                    background: "#EBEBEB",
                                    // marginBottom: "-23px",
                                    "& .MuiLinearProgress-bar": {
                                      background:
                                        "linear-gradient(90deg, #5B9488 -13.58%, #49DABD 44.42%, #2E927E 104.02%, #FFF 129.2%)",
                                      borderRadius: "50px 50px 50px 50px",
                                    },
                                  }}
                                />

                                        </>
                                      :
                                      <>
 <LinearProgress
                                  className="rounded-[50px] flex-1 dark:bg-[#D9D9D940] bg-gray-300"
                                  variant="determinate"
                                  value={currentScanProgress}
                                  // color="cyan"
                                  sx={{
                                    my: "auto",
                                    height: "28px",
                                    width: "200px",
                                    background: "#EBEBEB",
                                    // marginBottom: "-23px",
                                    "& .MuiLinearProgress-bar": {
                                      background:
                                        "linear-gradient(90deg, #5B9488 -13.58%, #49DABD 44.42%, #2E927E 104.02%, #FFF 129.2%)",
                                      borderRadius: "50px 50px 50px 50px",
                                    },
                                  }}
                                />

                                        </>

                                    }
                                                             </div>
                            </TableCell>
                          )}
                          <TableCell>
                              {scanStatus !== "In Queue" &&  
                            <DotButton
                              row={item}
                              header={item.headerInfo}
                              isBin={bin}
                              status={scanStatus}
                            />}
                          </TableCell>
                        </TableRow>
                        <TableRow
                          key={"expandable" + index}
                          id={"expandableRow" + index}
                          style={{ display: "none" }}
                        >
                          <TableCell colSpan={8} className="m-0">
                            <div className="my-0 bg-[#F6F9FF] dark:bg-[#32415E]">
                              <Box className="bg-can-500 px-7 py-1">
                                <div className="flex bg-pnk-500">
                                  <div className="bg-yllow-500 flex w-[95%]">
                                    <img
                                      src={
                                        accordionDetail?.company_Profile_image
                                      }
                                      className="inline mr-7 rounded-full  object-cover h-[52px] w-[52px]"
                                    />
                                    <Box>
                                      <p>
                                        <span className="font-bold">
                                          {accordionDetail?.asset_name}{" "}
                                        </span>
                                        <span className="ml-5 text-[#4568EC] font-bold">
                                          {item?.weburl}
                                        </span>
                                      </p>
                                      <p>
                                        {accordionDetail?.asset_description}
                                      </p>
                                    </Box>
                                    {/* icon collapse karne wala */}
                                  </div>
                                  <div>
                                    <div className="bg-pik-500">{""}</div>
                                  </div>
                                </div>
                                <div className="w-[100%] bg-gren-500 flex">
                                  {/* section 1 */}
                                  <div className=" w-[30%] mt-5 ml-[-28px] bg-gra-600 flex">
                                    <div className="bg-pnk-500 flex justify-center items-center">
                                      {" "}
                                      <SemiCircleGauge
                                        value={item?.riskScoreValue || 0}
                                      />
                                    </div>

                                    {/* <Gauge rating={item?.riskScoreValue || 0} /> */}
                                    <div className="flex flex-1 justify-center items-center bg-ink-500">
                                      <div>
                                        <p
                                          className={
                                            "w-[55px] h-[55px] text-[30px] rounded-[100%] font-extrabold px-5 py-2 bg-orange-400 text-white mx-2 flex justify-center items-center"
                                          }
                                        >
                                          {item.riskScoreSymbol || "-"}
                                        </p>
                                        <p className="mt-2 text-center">
                                          Risk Score
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  {/* section -2 divider  */}
                                  <div className="bg-gray-300 w-[1px] ml-10"></div>

                                  {/* section -3 after divider */}
                                  <div className=" w-[30%] mt-5 ml-10 bg-gry-600 flex">
                                    <PieAndBarChart
                                      values={{
                                        critical: item.severityData.critical,
                                        high: item.severityData.high,
                                        medium: item.severityData.medium,
                                        low: item.severityData.low,
                                      }}
                                      onSide={true}
                                    />
                                  </div>
                                </div>
                              </Box>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </div>
  );
}

export default Index;

// editPenBlue.svg  editPenDark.svg  editPen.svg
// pausescan.svg
// esumescan2.svg  resumescan.png
// resumescan2.svg  resumescan.png

const profileOptions = ({ open, onClose }) => {
  return (
    <>
      <Paper sx={{ width: 320, maxWidth: "100%" }}>
        <Menu open={open} onClose={onClose} className="relative">
          <MenuItem>
            <ListItemIcon>
              <img src="/editPenBlue.svg" />
            </ListItemIcon>
            <ListItemText>Cut</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <img src="/pausescan.svg" />
            </ListItemIcon>
            <ListItemText>Copy</ListItemText>
          </MenuItem>
        </Menu>
      </Paper>
    </>
  );
};
