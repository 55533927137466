import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reports: [],
};

let reportSlice = createSlice({
  name: "reportSlice",
  initialState: initialState,
  reducers: {
    setReports(state, action) {
      state.reports = action.payload;
    },
  },
});

export const reportSliceActions = reportSlice.actions;

export default reportSlice;
