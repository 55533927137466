import { Tooltip } from "@mui/material";
import { NoMaxWidthTooltip } from "components/ui/NoMaxWidthTooltip";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAssetSliceActions } from "store/addAssetSlice";

function ThirdData(props) {
  const dispatch = useDispatch();
  const teamMemberEmails = useSelector(
    (state) => state?.addAsset?.asset?.teamMemberEmails
  );
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";

  return (
    <>
      <div className="flex " style={{ fontSize: "24px", fontWeight: "600" }}>
        <div>Team</div>

        <Tooltip
          placement="right"
          describeChild
          // in title describe the step
          title="Add company engineers emails to add to this target. If member does not exist, they will be invited to join the platform."
        >
          <img
            src={isDarkTheme ? "/infoDark.svg" : "/info.svg"}
            alt=""
            className="w-5"
            style={{ marginLeft: "10px" }}
          />
        </Tooltip>
      </div>

      {/* <div style={{ marginTop: "1%" }}>
        <div style={{ fontWeight: "600" }}>Name</div>
        <input
          className="third-step-input dark:bg-lightBlack3"
          placeholder="Type here"
        />
      </div> */}

      <div style={{ marginTop: "1%" }}>
        <div style={{ fontWeight: "600" }}>Emails</div>
        <input
          style={{ height: "60px" }}
          className="third-step-input dark:bg-lightBlack3"
          placeholder="astha@gmail.com, karan@yahoo.com"
          type="email"
          multiple
          value={teamMemberEmails}
          onChange={(e) => {
            const emails = e.target.value
              .split(",")
              .map((email) => email.trim());
            dispatch(addAssetSliceActions.setTeamMemberEmails(emails));
          }}
        />
      </div>

      {/* <div
        style={{
          marginTop: "2%",
          paddingBottom: "2%",
        }}
      >
        <input type="checkbox" style={{}} />
        <span style={{ marginLeft: "1%" }}>
          <b>Share Target Summary</b>
        </span>
      </div> */}
    </>
  );
}

export default ThirdData;
