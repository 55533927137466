import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "./Stepper";

import Step1 from "./Step1/Index";
import Step2 from "./Step2/Index";
import Step3 from "./Step3/Index";
import Step4 from "./Step4/Index";
import Step5 from "./Step5/Index";
import { useDispatch, useSelector } from "react-redux";
import { postFetch, pythonPostFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { addReportSliceActions } from "store/addReportSlice";
import Loader from "components/ui/loaders/Loader";
import { blueGradient1 } from "components/tailwindClasses";
import { addScanEngineSliceActions } from "store/scanner/addScanEngineSlice";
import addScanProfileSlice, {
  addScanProfileSliceActions,
} from "store/scanner/addScanProfileSlice";
import dayjs from "dayjs";

function Index({ handleClose, type = "add" }) {
  let [activeStep, setActiveStep] = useState(0);

  let addScanProfileData = useSelector((state) => state.scanner.addScanProfile);

  // if (type == "edit") {
  //   // get the data from
  //   const listData = useSelector((state) => state.scanner.scanProfile.profiles);

  // }

  const dispatch = useDispatch();

  const [isLoading, setIsloading] = useState(false);

  const handleSubmitClick = async (e) => {
    if (
      addScanProfileData.step3.date &&
      dayjs(addScanProfileData.step3.date).isBefore(dayjs(Date.now()))
    ) {
      toast.info("Please select a future date");
      return;
    }

    const postData = {
      schema: {
        asset_details: {
          scan_name: addScanProfileData.step1.scanName,
          target_definition: {
            web_app_url: addScanProfileData.step1.webAppUrl,
            tag: addScanProfileData.step1.tag,
          },
        },

        scan_settings: {
          //  scan_engine: addScanProfileData.step2.engineName, // since name was not required
          scan_engine: addScanProfileData.step2.engineId, // (replace) id which whatever new parameter in response of scan_engine_list
        },
        schedule: {
          schedule_scan: addScanProfileData.step3.scanNow == 1 ? false : true,
          recurring_scan:
            addScanProfileData.step3.scanNow == 0 &&
            addScanProfileData.step3.frequency != "once",
          date_time:
            addScanProfileData.step3.date ||
            new Date(Date.now() + 1000000 * 60 * 1000).toISOString(),
          frequency:
            addScanProfileData.step3.scanNow == 1
              ? "scanNow"
              : { once: "once", weekly: 7, biweekly: 14, monthly: 30 }[
                  addScanProfileData.step3.frequency
                ],
          scan_now: addScanProfileData.step3.scanNow == 1 ? true : false,
        },
        send_report: {
          email: addScanProfileData.step4.email,
          report_format: addScanProfileData.step4.reportFormat,
          secure_report: {
            password: addScanProfileData.step4.password,
            confirm_password: addScanProfileData.step4.confirmPassword,
          },
        },
      },
    };

    setIsloading(() => true);

    const res = await pythonPostFetch("/start_scan", postData);

    if (res?.status === "ok") {
      toast.success("Scan started successfully ! ");
      dispatch(addScanProfileSliceActions.reset(""));

      handleClose();
    } else if (res?.error && res?.message) {
      toast.error(res.message);
    } else {
      toast.error("Some error occured, try later!");
    }

    setIsloading(() => false);

    // e.target.disabled = false;
  };

  const password = useSelector(
    (state) => state?.scanner?.addScanProfile?.step4?.password
  );
  const confirmPassword = useSelector(
    (state) => state?.scanner?.addScanProfile?.step4?.confirmPassword
  );

  const handleNextClick = () => {
    if (activeStep === 4) {
      handleSubmitClick();

      return;
    }
    const currentStepForm = document.getElementById(
      `step${activeStep + 1}-form`
    );

    if (activeStep == 2 && addScanProfileData.step3.scanNow == 0) {
      if (!addScanProfileData.step3.date) {
        toast.info("Please select a date");
        return;
      }
    }

    if (activeStep == 3 && password && confirmPassword) {
      if (password != confirmPassword) {
        // Set a custom validation message for confirmPassword field
        currentStepForm.fourthStepConfirmPassword.setCustomValidity(
          "Passwords do not match."
        );
        currentStepForm.reportValidity(); // Report the custom validity to show the error message

        return;
      } else {
        currentStepForm.fourthStepConfirmPassword.setCustomValidity(""); // Reset the custom validation message
      }
    }

    if (currentStepForm && currentStepForm.checkValidity()) {
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      currentStepForm?.reportValidity?.();
    }
  };

  const handleBackClick = () => {
    if (activeStep == 0) {
      handleClose();
    } else {
      setActiveStep((activeStep) => activeStep - 1);
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "850px",
        height: "97%",
        // p: "1% 2%",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}
      className="bg-white dark:bg-lightBlack rounded-10 overflow-hidden"
    >
      <div
        style={{
          position: "fixed",
          width: "100%",
          top: "0",
          left: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
          height: "8vh",
          padding: "0 2%",
          zIndex: "10",
          fontSize: "20px",
          fontWeight: "700",
        }}
        className="bg-white dark:bg-lightBlack text-blue1"
      >
        {type === "edit" ? "Edit Scan Profile" : "Add New Scan Profile"}
      </div>

      <div style={{ padding: "8vh 0 10vh 0", position: "relative" }}>
        <div
          style={{
            position: "fixed",
            top: "8vh",
            left: "0",
            width: "20%",
            height: "calc(100% - 8vh)",
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.6)",
          }}
          className="flex  justify-center bg-[#ECECEC] dark:bg-lightBlack2 "
        >
          {/* stepper here */}
          <div className="mt-5">
            <Stepper activeStep={activeStep} />
          </div>
        </div>
        <div
          style={{
            marginLeft: "20%",
            maxHeight: "600px",
            width: "calc(100% - 20%)",
          }}
          className="bg-white dark:bg-lightBlack flex-grow overflow-y-scroll"
        >
          {/* make stuff here */}

          {activeStep == 0 && <Step1 />}
          {activeStep == 1 && <Step2 />}
          {activeStep == 2 && <Step3 />}
          {activeStep == 3 && <Step4 />}
          {activeStep == 4 && <Step5 />}
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          width: "100%",
          bottom: "0",
          left: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          borderTop: "1px solid rgba(0, 0, 0, 0.2)",
          height: "10vh",
          padding: "0 2%",
          zIndex: "10",
        }}
        className="bg-white dark:bg-lightBlack dark:text-white"
      >
        <button
          style={{
            // marginTop: "3.2%",
            textDecoration: "none",
            color: "#2D3B51 !important",
            marginRight: "5vw",
            fontWeight: "500",
          }}
          onClick={handleBackClick}
        >
          {activeStep == 0 && "Cancel"}
          {activeStep != 0 && "Back"}
        </button>
        <button
          style={{ width: "200px", height: "50px" }}
          type="submit"
          disabled={isLoading}
          onClick={(e) => {
            handleNextClick();
          }}
          // className="w-full flex items-center justify-center text-base font-bold text-white h-15
          //  bg-gradient-to-b from-[#303e55] to-[#18202c] bg-opacity-100 rounded-10 disabled:opacity-50 "

          className={
            blueGradient1 +
            " flex items-center justify-center cursor-pointer w-60 rounded-10  font-bold"
          }
        >
          {activeStep != 4 && "Next"}
          {activeStep == 4 && (isLoading ? <Loader /> : "Done")}
        </button>
      </div>
    </Box>
  );
}

export default Index;
