import React from "react";

// creater container for it and aplly stlying to container
const Loader = () => {
  return (
    <div className="flex justify-center items-center h-full">
      <div className="border-4 border-opacity-30 border-black border-t-blue-500 dark:border-white dark:border-t-lightBlack1 rounded-full w-8 h-8 animate-spin"></div>
    </div>
  );
};

export default Loader;
