import { configureStore, combineReducers } from "@reduxjs/toolkit";
import stepsSlice from "./stepsSlice";
import dashboardInfoSlice from "./dashboardInfoSlice";
import targetInfoSlice from "./targetInfoSlice";
import profileSlice from "./profileSectionSlice";
import activePageSlice from "./activePageSlice";
import userSlice from "./userSlice";
import addAssetSlice from "./addAssetSlice";
import addReportSlice from "./addReportSlice";
import targetDashboardSlice from "./targetDashboardSlice";
import addVulnerabilityReportSlice from "./vulnerabilityReport";
import teamSlice from "./teamSlice";
import generalSlice from "./generalSlice";
import reportSlice from "./reportSlice";
import inviteSlice from "./inviteSlice";
import targetSlice from "./targetSlice";

import bugTrackerSwitchSlice from "./bugTrackerSwitchSlice";

import scannerReducer from "./scanner/index";

// Reset action creator
const resetAllState = () => ({
  type: "LOGOUT",
});

const rootReducer = combineReducers({
  steps: stepsSlice.reducer,
  dashboardInfo: dashboardInfoSlice.reducer,
  targetInfo: targetInfoSlice.reducer,
  profile: profileSlice.reducer,
  activePage: activePageSlice.reducer,
  user: userSlice.reducer,
  addAsset: addAssetSlice.reducer,
  addReport: addReportSlice.reducer,
  targetDashboard: targetDashboardSlice.reducer,
  addVulnerabilityReport: addVulnerabilityReportSlice.reducer,
  team: teamSlice.reducer,
  general: generalSlice.reducer,
  report: reportSlice.reducer,
  invite: inviteSlice.reducer,
  target: targetSlice.reducer,
  switch: bugTrackerSwitchSlice.reducer,

  scanner: scannerReducer,
});

const appReducer = (state, action) => {
  // Reset all state on LOGOUT action
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return rootReducer(state, action);
};

let store = configureStore({
  reducer: appReducer,
});

export const logout = () => {
  store.dispatch(resetAllState());
};

export default store;
