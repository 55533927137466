import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { TableSortLabel } from "@mui/material";

import { columns } from "./Columns";
import { useDispatch, useSelector } from "react-redux";
import { pythonGetFetch } from "lib/fetch";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import DotButton from "./DotButton";

import TargetTableLoadingSkeleton from "components/ui/loaders/TableSkeleton";
import { scanEngineSliceActions } from "store/scanner/scanEngineSlice";

function Index({ search, bin = false }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  const user = useSelector((state) => state.user.data);

  const data = useSelector((state) => state.scanner.scanEngine.engines);
  const deletedData = useSelector(
    (state) => state.scanner.scanEngine.deletedEngines
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let getDataAll = async () => {
      setIsLoading(() => true);

      const res = await pythonGetFetch("/scan_engines_list");

      if (res?.status == "ok") {
        dispatch(scanEngineSliceActions.setEngines(res?.data));
      } else if (res?.error && res?.message) {
        toast.error(res?.message);
      } else {
        toast.error("could not fetch engines, try later");
      }

      setIsLoading(() => false);
    };

    let getDataBin = async () => {
      setIsLoading(() => true);

      const res = await pythonGetFetch("/scanengine/bin_listing");

      if (res?.status == "ok") {
        dispatch(scanEngineSliceActions.setDeletedEngines(res?.data));
      } else if (res?.error && res?.message) {
        toast.error(res?.message);
      } else {
        toast.error("could not fetch engines, try later");
      }

      setIsLoading(() => false);
    };

    !bin && getDataAll();
    bin && getDataBin();
  }, [bin]);

  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (columnId) => {
    if (sortBy === columnId) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(columnId);
      setSortOrder("asc");
    }
  };

  let currentData = bin ? deletedData : data;

  let sortedData = currentData?.slice()?.sort((a, b) => {
    const aValue = a[sortBy];
    const bValue = b[sortBy];
    if (sortOrder === "asc") {
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
    } else {
      if (aValue > bValue) return -1;
      if (aValue < bValue) return 1;
    }
    return 0;
  });

  if (search !== "") {
    sortedData = sortedData?.filter((item) =>
      item?.name?.toLowerCase()?.includes(search.toLowerCase())
    );
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let rows =
    sortedData && sortedData?.length > 0
      ? sortedData?.map((item) => {
    
          return {
            id:item._id,
            name: item.name,
            depth: item.crawl_depth,
            type: item.scan_type,
            scope: item.crawl_scope,
            maxLinks: item.max_links_to_scroll,
            speed: item.scan_speed,
            threads: item.threads,

            options: <DotButton row={item} isBin={bin} />,
          };
        })
      : [];

  const Cols = columns(user?.role, bin);

  const handleEngineClick = (e) => {};

  return (
    <div>
      {/* <Paper className="w-full  dark:bg-lightBlack "> */}
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="">
            <TableRow>
              {Cols &&
                Cols?.length > 0 &&
                Cols?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={
                      {
                        // fontWeight: "600",
                      }
                    }
                    sx={{ bgcolor: "rgba(217, 217, 217, 0.25)" }}
                    className=" dark:bg-lightBlack3 dark:text-lightBlack1"
                  >
                    <TableSortLabel
                      active={sortBy === column.id}
                      direction={sortBy === column.id ? sortOrder : "asc"}
                      onClick={() => handleSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          {isLoading && rows?.length == 0 ? (
            <TargetTableLoadingSkeleton
              columnCount={Cols?.length}
              tableCellClassName={
                "dark:text-white dark:border-b9 dark:bg-lightBlack border-b"
              }
            />
          ) : (
            <TableBody>
              {rows
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => (
                  <TableRow>
                    {columns(user.role).map((column) => {
                      const value = row[column.id];

                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ maxWidth: column.maxWidth }}
                          // className="dark:bg-lightBlack"
                          onClick={
                            column.id == "name" ? handleEngineClick : null
                          }
                          className="dark:text-white dark:border-b9 dark:bg-lightBlack border-b"
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="dark:bg-lightBlack dark:text-white"
      />
      {/* </Paper> */}
    </div>
  );
}

export default Index;
