import React, { useState } from "react";
import RequestCard from "./RequestCard";
import Approved from "./Approved";
import Pending from "./Pending";

let optionStyle = {
  display: "flex",
  justifyContent: "center",
  padding: "1% 2%",
  width: "200px",
  cursor: "pointer",
};
let optionStyleSelect = {
  ...optionStyle,
  fontWeight: "700",
  color: "#4B65ED",

  borderBottom: " 4px solid #4B65ED",
};
function Index(props) {
  let [activeOption, setActiveOption] = useState(0);

  let options = ["Pending", "Approved"];

  return (
    <div>
      <div
        style={{ display: "flex" }}
        className="dark:text-white border-t rounded-b-10 overflow-hidden border-b2 dark:border-b9 dark:bg-lightBlack bg-white"
      >
        {options.map((item, i) => {
          return (
            <div
              onClick={() => {
                setActiveOption(() => i);
              }}
              style={activeOption == i ? optionStyleSelect : optionStyle}
            >
              {item}
            </div>
          );
        })}
      </div>

      {activeOption == 0 && <Pending />}
      {activeOption == 1 && <Approved />}
    </div>
  );
}

export default Index;
