import React, { useState } from "react";
import {
  MenuItem,
  Box,
  Typography,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useEffect } from "react";
import { getFetch, postFetch } from "lib/fetch";
import { toast } from "react-toastify";
import { fontWeight } from "@mui/system";

import { useSelector, useDispatch } from "react-redux";
import { teamSliceActions } from "store/teamSlice";
import { blueGradient1 } from "components/tailwindClasses";
import Loader from "components/ui/loaders/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 400,
  //   bgcolor: 'background.paper',
  //   border: '2px solid #000',
  boxShadow: 24,
  //   backDrop:'10px',
  //   p: 4,
};

function Index({ handleClose }) {
  const team = useSelector((state) => state.team.team);

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const [assetRoles, setAssetRoles] = useState({});

  const handleChange = (e) => {
    // console.log(e.target);
    setAssetRoles((state) => {
      const newState = { ...state };
      newState[e.target.name] = e.target.value;
      return newState;
    });
  };
  // console.log(assetRoles);

  const [assets, setAssets] = useState([]);

  useEffect(() => {
    let getData = async () => {
      const res = await getFetch("/listManagerAssets");
      if (res?.data) {
        let assetsInfoNeeded = [];

        try {
          assetsInfoNeeded = res.data.map((asset) => {
            return { id: asset._id, title: asset.title };
          });
          // console.log("add asset res ; ", assetsInfoNeeded);

          setAssets(() => assetsInfoNeeded);

          assetsInfoNeeded.forEach((asset) => {
            const assetId = asset.id;
            assetRoles[assetId] = "None";
          });
        } catch (e) {
          console.log("error in AddNewMember : ", e);
        }
      }
    };
    getData();
  }, []);

  // use loading state
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(() => true);

    const assignedAssets = Object.keys(assetRoles).filter(
      (assetId) => assetRoles[assetId] == "Researcher"
    );

    const res = await postFetch("/manager/addTeamMember", {
      email: email,
      asset_ids: assignedAssets,
    });
    // console.log("addmember res : ", res);
    if (res?.status == "ok") {
      if (res?.userCreated) {
        toast.success("Invitation sent to member");
      } else if (res?.userCreated == false) {
        toast.success("Member added ");
        if (res?.data) {
          let updatedTeam = [res.data, ...team];
          dispatch(teamSliceActions.setTeam(updatedTeam));
        }
      }

      handleClose();
    } else {
      toast.error("Some error ocurred, try later!");
    }

    setLoading(() => false);
  };

  return (
    <Box
      sx={style}
      className="w-[800px] shadow rounded-10 bg-white dark:bg-lightBlack dark:text-white"
    >
      <form action="" onSubmit={handleSubmit}>
        <div className="text-2xl text-center font-bold mt-3p pb-2p mx-3p border-b ">
          Add New Member
        </div>
        <div className="px-3p">
          <div className="my-2 font-medium"> Email Address</div>
          <input
            className="bg-inputBg border-b2 border rounded p-2p w-full dark:bg-greyBg4"
            type={"email"}
            value={email}
            onChange={(e) => {
              setEmail(() => e.target.value);
            }}
            required
          />
        </div>
        <div className="bg-greyBg2 dark:bg-lightBlack2 px-3p py-3 mt-4 font-medium ">
          Asset List
        </div>
        <div className="flex flex-col max-h-64 overflow-y-scroll px-3p my-1">
          {assets?.map?.((asset) => {
            const id = asset.id;
            return (
              <div className="flex justify-between items-center py-1p border-b border-b1">
                <div>{asset.title}</div>
                <div>
                  <FormControl>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={assetRoles[id]}
                      onChange={handleChange}
                      className="h-12 w-52 dark:bg-greyBg4 dark:text-white"
                      name={id}
                    >
                      <MenuItem value={"None"} className="font-semibold">
                        None
                      </MenuItem>
                      <MenuItem value={"Researcher"} className="font-semibold">
                        Researcher
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            );
          })}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          className="border-t-2 border-b2 dark:border-b9 mx-2p py-2p"
        >
          <button
            style={{
              // marginTop: "3.2%",
              textDecoration: "none",
              color: "#2D3B51 !important",
              marginRight: "5vw",
              fontWeight: "500",
            }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className={
              blueGradient1 +
              " flex items-center justify-center  w-60 rounded-10 h-15 font-bold"
            }
            type="submit"
            disabled={loading}
          >
            {loading ? <Loader /> : "Add"}
          </button>
        </div>
      </form>
    </Box>
  );
}

export default Index;
