import "./App.css";
import {
  Switch,
  Route,
  Routes,
  BrowserRouter,
  redirect,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ThemeProvider, createTheme } from "@mui/material/styles";
//
import ChangePass1 from "pages/ChangePass1";
import ChangePass2 from "pages/ChangePass2";
import Login from "pages/Login";
import EmailOtp from "pages/EmailOtp";
import Link from "pages/Link";
import Link2 from "pages/Link2";
import Dashboard from "pages/Dashboard";
import Companies from "pages/Companies";
import ChangeMobile from "pages/ChangeMobile";
import Profile from "pages/Profile";
import Report from "pages/Report";
import Target from "pages/Target";
import AddTarget from "pages/AddTarget";
import Team from "pages/Team";
import VulnerabiltyReport from "pages/VulnerabiltyReport";
import ScannerReport from "pages/ScannerReport";
import TargetDashboard from "pages/TargetDashboard";
import Main from "pages/Main";
import SignUp from "pages/SignUp";
import Invite from "pages/Invite";
import ScanEngine from "pages/ScanEngine";
import ScanProfile from "pages/ScanProfile";

//

import Protected from "./services/Protected";

//
import TeamSidebar from "./Layouts/TeamSidebar";
import UserNavbar from "./Layouts/userNavbar/Index";
import UserSidebar from "./Layouts/UserSidebar";
import ProfileSidebar from "./Layouts/ProfileSiderbar";

import CompareReports from "pages/CompareReports";
import LoginSecurityEngineer from "pages/LoginSecurityEngineer";
import BugReport from "pages/BugReport";

import { useEffect } from "react";
import { generalActions } from "store/generalSlice";
import axios from "axios";
import Email from "pages/Email";
import PageNotFound from "pages/PageNotFound";
import { Select } from "@mui/base";

function App() {
  const isDarkTheme = useSelector((state) => state?.general?.theme) == "dark";

  const muiTheme = createTheme({
    palette: {
      mode: isDarkTheme ? "dark" : "light",
    },

    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            overflow: "hidden",
            borderBottom: "2px solid #4B6DF1",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            // Change the background color of the select
            backgroundColor: isDarkTheme ? "#32415E" : "#E3E6F5",

            // Change the text color of the select
            // color: "darkblue",

            // Change the hover background color of the select
            // "&:hover": {
            //   backgroundColor: "lightcyan",
            // },

            // Change the focused outline color
            // "&.Mui-focused": {
            //   outline: "2px solid blue",
            // },
          },
        },
      },
    },
  });

  return (
    <Routes>
      <Route
        exact
        path="email"
        element={
          <ThemeProvider theme={muiTheme}>
            <Email />
          </ThemeProvider>
        }
      />
      <Route
        element={
          <ThemeProvider theme={muiTheme}>
            <Protected></Protected>
          </ThemeProvider>
        }
      >
        <Route
          exact
          path="compareReports"
          element={
            <ThemeProvider theme={muiTheme}>
              <CompareReports />
            </ThemeProvider>
          }
        ></Route>

        <Route
          path="/"
          element={
            <ThemeProvider theme={muiTheme}>
              <Main />
            </ThemeProvider>
          }
        />

        <Route
          path="dashboard"
          element={
            <ThemeProvider theme={muiTheme}>
              <UserNavbar>
                <UserSidebar>
                  <Dashboard />
                </UserSidebar>
              </UserNavbar>
            </ThemeProvider>
          }
        />
        <Route
          path="/target-dashboard"
          element={
            <ThemeProvider theme={muiTheme}>
              <UserNavbar>
                <TargetDashboard />
              </UserNavbar>
            </ThemeProvider>
          }
        ></Route>

        <Route
          path="team"
          element={
            <ThemeProvider theme={muiTheme}>
              <UserNavbar>
                {/* <TeamSidebar> */}
                <UserSidebar>
                  <Team />
                </UserSidebar>
                {/* </TeamSidebar> */}
              </UserNavbar>
            </ThemeProvider>
          }
        />

        <Route
          path="companies"
          element={
            // <UserNavbar>
            //   <UserSidebar>
            //     <Companies />
            //   </UserSidebar>
            // </UserNavbar>
            <ThemeProvider theme={muiTheme}>
              <Navigate to={"/dashboard"} />
            </ThemeProvider>
          }
        />
        <Route
          path="target"
          element={
            <ThemeProvider theme={muiTheme}>
              <UserNavbar>
                <UserSidebar>
                  <Target />
                </UserSidebar>
              </UserNavbar>
            </ThemeProvider>
          }
        />
        <Route
          path="invite"
          element={
            <ThemeProvider theme={muiTheme}>
              <UserNavbar>
                <UserSidebar>
                  <Invite />
                </UserSidebar>
              </UserNavbar>
            </ThemeProvider>
          }
        />
        <Route
          path="scanEngine"
          element={
            <ThemeProvider theme={muiTheme}>
              <UserNavbar>
                <UserSidebar>
                  <ScanEngine />
                </UserSidebar>
              </UserNavbar>
            </ThemeProvider>
          }
        />
        <Route
          path="scanProfile"
          element={
            <ThemeProvider theme={muiTheme}>
              <UserNavbar>
                <UserSidebar>
                  <ScanProfile />
                </UserSidebar>
              </UserNavbar>
            </ThemeProvider>
          }
        />
        <Route
          path="addTarget"
          element={
            <ThemeProvider theme={muiTheme}>
              <UserNavbar>
                <AddTarget />
              </UserNavbar>
            </ThemeProvider>
          }
        />

        <Route
          path="setting"
          element={
            // <UserNavbar>
            //   <UserSidebar></UserSidebar>
            // </UserNavbar>
            <ThemeProvider theme={muiTheme}>
              <Navigate to={"/dashboard"} />
            </ThemeProvider>
          }
        />

        <Route
          path="/vulnerabilityReport"
          element={
            <ThemeProvider theme={muiTheme}>
              <VulnerabiltyReport />
            </ThemeProvider>
          }
        ></Route>

        <Route
          path="/ScannerReport"
          element={
            <ThemeProvider theme={muiTheme}>
              <ScannerReport />
            </ThemeProvider>
          }
        ></Route>

        <Route
          path="/report"
          element={
            <ThemeProvider theme={muiTheme}>
              <UserNavbar>
                <UserSidebar>
                  <Report />
                </UserSidebar>
              </UserNavbar>
            </ThemeProvider>
          }
        ></Route>
      </Route>
      <Route
        exact
        path="/steps"
        element={
          <ThemeProvider theme={muiTheme}>
            <Protected />
          </ThemeProvider>
        }
      ></Route>
      <Route
        path="/reset-pass"
        element={
          <ThemeProvider theme={muiTheme}>
            <ChangePass1 />
          </ThemeProvider>
        }
      />
      <Route
        path="/resetpassword"
        element={
          <ThemeProvider theme={muiTheme}>
            <ChangePass2 />
          </ThemeProvider>
        }
      />
      <Route
        path="/login/*"
        element={
          // <ThemeProvider theme={muiTheme}>
          <Login />
          // </ThemeProvider>
        }
      />
      <Route
        path="login/securityEngineer"
        element={
          <ThemeProvider theme={muiTheme}>
            <LoginSecurityEngineer />
          </ThemeProvider>
        }
      />
      <Route
        path="/signup"
        element={
          <ThemeProvider theme={muiTheme}>
            <SignUp />
          </ThemeProvider>
        }
      />
      <Route
        path="/link"
        element={
          <ThemeProvider theme={muiTheme}>
            <Link />
          </ThemeProvider>
        }
      />
      <Route
        path="/link2"
        element={
          <ThemeProvider theme={muiTheme}>
            <Link2 />
          </ThemeProvider>
        }
      />
      <Route
        path="/emailotp"
        element={
          <ThemeProvider theme={muiTheme}>
            <EmailOtp />
          </ThemeProvider>
        }
      />
      {/* mobile otp is same as emailotp if needed */}
      <Route
        exact
        path="/profile"
        element={
          <ThemeProvider theme={muiTheme}>
            <Protected></Protected>
          </ThemeProvider>
        }
      >
        <Route
          exact
          path=""
          element={
            <ThemeProvider theme={muiTheme}>
              <UserNavbar hideSelect={true}>
                <ProfileSidebar>
                  <Profile />
                </ProfileSidebar>
              </UserNavbar>
            </ThemeProvider>
          }
        ></Route>
        <Route
          path="change-mobile"
          element={
            // <UserNavbar hideSelect={true}>
            // </UserNavbar>
            <ThemeProvider theme={muiTheme}>
              <ChangeMobile />
            </ThemeProvider>
          }
        ></Route>
      </Route>
      <Route
        path="bugReport"
        element={
          <UserNavbar>
            <BugReport />
          </UserNavbar>
        }
      />
      <Route
        path="*"
        element={
          <UserNavbar>
            <PageNotFound />
          </UserNavbar>
        }
      />

      {/* this is a catch all route */}
    </Routes>
  );
}

export default App;
