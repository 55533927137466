import React, { useEffect, useRef, useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { toast } from "react-toastify";
import { postFetch } from "lib/fetch";
function Index(props) {
  let [currentStep, setCurrentStep] = useState(0);

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  let capchaRef = useRef(null);
  let capchaRef2 = useRef(null);

  return (
    <>
      {currentStep == 0 && (
        <Step1
          setCurrentStep={setCurrentStep}
          password={password}
          password2={password2}
          oldPassword={oldPassword}
          setOldPassword={setOldPassword}
          setPassword={setPassword}
          setPassword2={setPassword2}
          capchaRef={capchaRef}
        />
      )}

      {currentStep == 1 && (
        <Step2
          password={password}
          oldPassword={oldPassword}
          capchaRef={capchaRef2}
        />
      )}
    </>
  );
}

export default Index;
