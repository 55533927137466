import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,

  totalAssets: 0,
  totalVulnerabilities: 0,
  critical: 0,
  high: 0,
  medium: 0,
  low: 0,
  new: 0,
  informative: 0,
  triaged: 0,
  resolve: 0,
  duplicate: 0,

  risk: 0,
  performance: 0,

  chartData: {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    new: 0,
    informative: 0,
    triaged: 0,
    resolve: 0,
    duplicate: 0,
    risk: 0,
    performance: 0,
    gauge: 0,
    performance: 0,
  },

  assets: [],
  topVulnerabilities: [],
  topCve: [],
  currentAsset: {},
  // { id: "all", title: "All" }

  activityFeed: [],

  performanceGraph: [],
  timelineGraph: [],
};

let dashboardInfoSlice = createSlice({
  name: "stepsSlice",
  initialState: initialState,
  reducers: {
    reset(state) {
      state = initialState;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setTotalAssets(state, action) {
      state.data.totalAssets = action.payload;
    },
    setTotalVulnerabilities(state, action) {
      state.data.totalVulnerabilities = action.payload;
    },
    setCritical(state, action) {
      state.chartData.critical = action.payload;
    },
    setHigh(state, action) {
      state.chartData.high = action.payload;
    },
    setMedium(state, action) {
      state.chartData.medium = action.payload;
    },
    setLow(state, action) {
      state.chartData.low = action.payload;
    },
    setNew(state, action) {
      state.chartData.new = action.payload;
    },
    setInformative(state, action) {
      state.chartData.informative = action.payload;
    },
    setTriaged(state, action) {
      state.chartData.triaged = action.payload;
    },
    setResolve(state, action) {
      state.chartData.resolve = action.payload;
    },
    setDuplicate(state, action) {
      state.chartData.duplicate = action.payload;
    },
    setRisk(state, action) {
      state.risk = action.payload;
    },
    setPerformance(state, action) {
      state.performance = action.payload;
    },
    setGauge(state, action) {
      state.gauge = action.payload;
    },

    setAssets(state, action) {
      state.assets = action.payload;
    },
    setCurrentAsset(state, action) {
      state.currentAsset = action.payload;
    },

    setChartsData(state, action) {
      state.chartData.critical = action.payload?.critical;
      state.chartData.high = action.payload?.high;
      state.chartData.medium = action.payload?.medium;
      state.chartData.low = action.payload?.low;
      state.chartData.new = action.payload?.new;
      state.chartData.resolve = action.payload?.resolve;
      state.chartData.triaged = action.payload?.triaged;
      state.chartData.informative = action.payload?.informative;
      state.chartData.duplicate = action.payload?.duplicate;

      state.chartData.risk = action.payload?.risk;
      state.chartData.gauge = action.payload?.gauge;
      state.chartData.performance = action.payload?.performance;
    },

    setInfoCardsData(state, action) {
      state.totalVulnerabilities = action.payload.totalVulnerabilities;
      state.totalAssets = action.payload.totalAssets;
      state.critical = action.payload.critical;
      state.high = action.payload.high;
      state.medium = action.payload.medium;
      state.low = action.payload.low;
      state.new = action.payload.new;
      state.informative = action.payload.informative;
      state.triaged = action.payload.triaged;
      state.resolve = action.payload.resolve;
      state.duplicate = action.payload.duplicate;
    },
    setTopVulnerabilities(state, action) {
      state.topVulnerabilities = action.payload;
    },
    setTopCve(state, action) {
      state.topCve = action.payload;
    },
    setActivityFeed(state, action) {
      state.activityFeed = action.payload;
    },
    setPerformanceGraph(state, action) {
      state.performanceGraph = action.payload;
    },
    setTimelineGraph(state, action) {
      state.timelineGraph = action.payload;
    },
  },
});

export const dashboardInfoSliceActions = dashboardInfoSlice.actions;

export default dashboardInfoSlice;
