import CommentBox from "./CommentBox";

import { useDispatch, useSelector } from "react-redux";
import { addVulnerabilityReportSliceActions } from "store/vulnerabilityReport";

import { useEffect } from "react";
import { postFetch } from "lib/fetch";

export default function Index({}) {
  const dispatch = useDispatch();

  const bugId = useSelector((state) => state.addVulnerabilityReport?.id);
  const checked = useSelector((state) => state.switch.checked);

  useEffect(() => {
    dispatch(addVulnerabilityReportSliceActions.resetComments());
    const getComments = async () => {
      let res;
      if (checked) {
        // http://localhost:3000/api/scanner/companyBug/getSideComments
        res = await postFetch("/scanner/companyBug/getSideComments", {
          bug_id: bugId,
        });
      } else {
        res = await postFetch("/bug/getSideComments", { bug_id: bugId });
      }

      if (res?.status == "ok") {
        res?.comments?.forEach((it, i) => {
          let item = it?.comment;
          if (item?.corresponding_section == "weakness") {
            dispatch(
              addVulnerabilityReportSliceActions.setWeaknessComment({
                id: item?._id,
                comment: item?.content,
                createdOn: item?.createdDate,
                isCreator: it?.isCreator,
                personName: it?.fname,
                personImage: it?.profile_image,
              })
            );
          } else if (item?.corresponding_section == "parameter") {
            dispatch(
              addVulnerabilityReportSliceActions.setParameterComment({
                id: item?._id,
                comment: item?.content,
                createdOn: item?.createdDate,
                isCreator: it?.isCreator,
                personName: it?.fname,
                personImage: it?.profile_image,
              })
            );
          } else if (item?.corresponding_section == "severity") {
            dispatch(
              addVulnerabilityReportSliceActions.setSeverityComment({
                id: item?._id,
                comment: item?.content,
                createdOn: item?.createdDate,
                isCreator: it?.isCreator,
                personName: it?.fname,
                personImage: it?.profile_image,
              })
            );
          } else if (item?.corresponding_section == "poc") {
            dispatch(
              addVulnerabilityReportSliceActions.setPocComment({
                id: item?._id,
                comment: item?.content,
                createdOn: item?.createdDate,
                isCreator: it?.isCreator,
                personName: it?.fname,

                personImage: it?.profile_image,
              })
            );
          }
        });
      } else {
        dispatch(addVulnerabilityReportSliceActions.resetComments());
      }
    };
    getComments();
  }, [bugId]);

  // comments get set here using createPortal from "src\features\report\components\BugReport\Index.js"

  return (
    <div
      className="h-full relative dark:bg-lightBlack3 rounded-10"
      id="bugReportSideComments"
    ></div>
  );
}
