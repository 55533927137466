import BarChart from "components/ui/charts/Bar";
import LineChart from "components/ui/charts/LineChart";
import Loader from "components/ui/loaders/Loader";
import { pythonPostFetch } from "lib/fetch";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardSliceActions } from "store/scanner/dashboardSlice";

function Index(props) {
  const [owaspIsLoading, setOwaspIsLoading] = useState(false);
  const [cweIsLoading, setCweIsLoading] = useState(false);
  const [mainIsLoading, setMainIsLoading] = useState(false);
  const dispatch = useDispatch();

  const cweGraphData = useSelector(
    (state) => state.scanner.dashboard.cweGraphData
  );
  const owaspGraphData = useSelector(
    (state) => state.scanner.dashboard.owaspGraphData
  );
  const mainGraphData = useSelector(
    (state) => state.scanner.dashboard.mainGraphData
  );

  useEffect(() => {
    const getCweData = async () => {
      setCweIsLoading(true);
      try {
        const cweRes = await pythonPostFetch("/dashboard/graph", {
          graph_type: "cwe",
          graph_duration: 0,
        });

        if (cweRes?.status == "ok") {
          dispatch(dashboardSliceActions.setCweGraphData(cweRes?.data));
        }
        setCweIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    };

    getCweData();
  }, []);
  useEffect(() => {
    const getOwaspData = async () => {
      setOwaspIsLoading(true);
      try {
        const owaspRes = await pythonPostFetch("/dashboard/graph", {
          graph_type: "owasp",
          graph_duration: 0,
        });
        if (owaspRes?.status == "ok") {
          dispatch(
            dashboardSliceActions.setOwaspGraphData(owaspRes?.data?.graph_data)
          );
        }
        setOwaspIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    };

    getOwaspData();
  }, []);
  useEffect(() => {
    const getMainData = async () => {
      setMainIsLoading(true);
      try {
        const mainRes = await pythonPostFetch("/dashboard/graph", {
          graph_type: "main",
          graph_span: 0,
          graph_duration: 0,
        });

        if (mainRes?.status == "ok") {
          dispatch(
            dashboardSliceActions.setMainGraphData(mainRes?.data?.graph_data)
          );
        }
        setMainIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    };

    getMainData();
  }, []);

  return (
    <>
      <div className="flex  rounded mt-1p">
        <div className="w-1/2 p-2p bg-white dark:bg-lightBlack mr-[.5%] overflow-hidden rounded">
          <div className="p-4 pt-0 flex justify-between items-center dark:text-white">
            <div className="text-lg font-medium ">CWE Graph</div>
            <div className="flex items-center text-xs"></div>
          </div>
          {cweIsLoading ? (
            <div className="h-60">
              <Loader />
            </div>
          ) : cweGraphData.length > 0 ? (
            <BarChart
              dataToUse={{
                values: cweGraphData?.map?.((i) => i[1]),
                backgroundColors: cweGraphData?.map?.(() => {
                  return {
                    red: Math.random() * 255,
                    green: Math.random() * 255,
                    blue: Math.random() * 255,
                  };
                }),
                labels: cweGraphData?.map?.((i) => i[0]),
              }}
              aspectRatio={2}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="w-1/2 p-2p bg-white dark:bg-lightBlack ml-[.5%] overflow-hidden rounded">
          <div className="p-4 pt-0 dark:text-white">
            <div className="text-lg font-medium ">OWASP TOP 100</div>
          </div>
          {owaspIsLoading ? (
            <div className="h-60">
              <Loader />
            </div>
          ) : owaspGraphData.values.length > 0 &&
            owaspGraphData.labels.length > 0 ? (
            <BarChart
              dataToUse={{
                values: owaspGraphData?.values,
                backgroundColors: owaspGraphData?.values?.map?.(() => {
                  return {
                    red: Math.random() * 255,
                    green: Math.random() * 255,
                    blue: Math.random() * 255,
                  };
                }),
                labels: owaspGraphData?.labels,
              }}
              aspectRatio={2}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="flex rounded mt-1p">
        <div className="p-1p w-full bg-white dark:bg-lightBlack  overflow-hidden rounded">
          <div className="p-4 pt-0 flex justify-between items-center dark:text-white">
            <div className="text-lg font-medium ">Performance Graph</div>
            <div className="flex items-center text-xs">{/* WEEKLY */}</div>
          </div>
          {mainIsLoading ? (
            <div className="h-60">
              <Loader />
            </div>
          ) : mainGraphData?.labels?.length > 0 ? (
            <LineChart
              aspectRatio={4}
              dataToUse={{
                labels: mainGraphData.labels,
                // labels: mainGraphData.labels;
                // data:{
                //     mainGraphData.
                // },
                datasets: [
                  {
                    label: "Critical",
                    data: mainGraphData.values.map((i) => {
                      return i.critical;
                    }),
                    borderColor: "rgb(206, 55, 51)",
                    backgroundColor: "rgba(206, 55, 51, 0.5)",
                  },
                  {
                    label: "High",
                    data: mainGraphData.values.map((i) => {
                      return i.high;
                    }),
                    borderColor: "rgb(236, 136, 48)",
                    backgroundColor: "rgba(236, 136, 48, 0.5)",
                  },
                  {
                    label: "Medium",
                    data: mainGraphData.values.map((i) => {
                      return i.medium;
                    }),

                    borderColor: "rgb(253, 188, 44)",
                    backgroundColor: "rgba(253, 188, 44, 0.5)",
                  },
                  {
                    label: "Low",
                    data: mainGraphData.values.map((i) => {
                      return i.low;
                    }),
                    borderColor: "rgb(55, 164, 64)",
                    backgroundColor: "rgba(55, 164, 64, 0.5)",
                  },
                  // {
                  //   label: "Best Practice",
                  //   data: bottomLabels.map((i) => Math.random() * 10),
                  //   borderColor: "rgba(21, 29, 202, 1)",
                  //   backgroundColor: "rgba(21, 29, 202, .5)",
                  // },
                  // {
                  //   label: "Information",
                  //   data: bottomLabels.map((i) => Math.random() * 10),
                  //   borderColor: "rgba(159, 155, 155, 1)",
                  //   backgroundColor: "rgba(159, 155, 155,.5)",
                  // },
                ],
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default Index;
