import React from "react";

import "components/style.css";
import { blueGradient1 } from "components/tailwindClasses";

function Link({ heading, content }) {
  return (
    <>
      <div className={"relative w-full h-full text-white " + blueGradient1}>
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="text-4xl w-fit mb-2">
            <b>{heading}</b>
            <div className="text-2xl text-[#c3d2d2] w-fit ">
              <div className="">{content}</div>
            </div>
          </div>
        </div>
        <img
          src={"/loginBgLines.svg"}
          className="  absolute top-0 left-0 w-1/4  -scale-y-100"
          alt="anim"
        />
        <img
          src="/linkScreenLogo.svg"
          alt=""
          className="  absolute bottom-0 right-0"
        />
      </div>
    </>
  );
}

export default Link;
