export const token = () => {
  return Math.floor((Math.random() * 10) % 9);
};

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const textMessage = (
  id = null,
  position = null,
  text = null,
  date = null,
  status = null,
  className = null,
  title = null
) => {
  return {
    type: "text",
    id: id,
    position: position || "right",
    text: text || "",
    title: title,
    focus: true,
    date: date,
    // dateString: date,
    //   avatar: `./avatar.svg`,
    titleColor: "blue",
    //   forwarded: true,
    //   replyButton: true,
    //   removeButton: true,
    status: status || "received",
    notch: false,
    copiableDate: true,
    retracted: false,
    className: position == "center" ? "translate-x-1/4 " : "  ",
    //   reply:
    //     token() >= 20
    //       ? {
    //           photoURL: token() >= 1 ? `/avatar2.svg` : null,
    //           title: "another random title",
    //           titleColor: getRandomColor(),
    //           message: "random message",
    //         }
    //       : undefined,
  };
};

export const meetingLinkMessage = () => {
  return {
    type: "meetingLink",
    actionButtons: [
      {
        onClickButton(id) {
          console.log(id);
        },
        Component: () => {
          // return MdOutlineVideoCall({ size: "25px" });
        },
      },
      {
        onClickButton(id) {
          console.log(id);
        },
        Component: () => {
          // return MdOutlineVideoCall({ size: "25px" })
        },
      },
    ],
    meetingID: String(Math.random()),
    id: String(Math.random()),
    position: "right",
    text: "text",
    title: "title",
    focus: true,
    date: +new Date(),
    dateString: "now",
    // avatar: `data:image/png;base64`,
    titleColor: getRandomColor(),
    forwarded: true,
    replyButton: true,
    removeButton: true,
    status: "received",
    notch: false,
    copiableDate: true,
    retracted: false,
    className: "",
    // reply:
    //   token() >= 1
    //     ? {
    //         photoURL: token() >= 1 ? `data:image/png;base64` : null,
    //         title: { count: 2, units: "words" },
    //         titleColor: getRandomColor(),
    //         message: { count: 1, units: "sentences" },
    //       }
    //     : undefined,
  };
};
