import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: "",
  currentApp: "VMDR", // VMDR or SCANNER
};

let generalSlice = createSlice({
  name: "generalSlice",
  initialState: initialState,
  reducers: {
    setTheme(state, action) {
      state.theme = action.payload;
    },

    setCurrentApp(state, action) {
      if (action.payload == "VMDR" || action.payload == "SCANNER")
        state.currentApp = action.payload;
    },
  },
});

export const generalActions = generalSlice.actions;

export default generalSlice;
