import React from "react";

function ColorDot({ type, width }) {
  
  let src = "/" + type?.toLowerCase() + "Dot.svg";
  console.log(src);
  let className = "inline ";
  if (width) {
    className += "w-" + width;
  }
  return (
    <>
      <img src={src} alt="" className={className} />
    </>
  );
}

export default ColorDot;
