import React, { useState } from "react";
import SeverityInput from "./SeverityInput";
import { FormGroup, List } from "@mui/material";
import { FormControl } from "@mui/material";
import { Divider } from "@mui/material";

import { score, rating, isValidCvssVector } from "lib/cvss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addVulnerabilityReportSliceActions } from "store/vulnerabilityReport";
import { useRef } from "react";
import Popup from "reactjs-popup";
import CommentBox from "../BugReportSidebar/CommentBox";
import { blueGradient1, input1 } from "components/tailwindClasses";

const cvssObjectToVector = (obj) => {
  let str = "CVSS:3.0/";

  obj &&
    Object?.keys?.(obj)?.forEach((key, i) => {
      str +=
        "" +
        key +
        ":" +
        obj?.[key] +
        (i == Object?.keys?.(obj)?.length - 1 ? "" : "/");
    });
  return str;
};

function Severity(props) {
  const dispatch = useDispatch();

  const cweId = useSelector((state) => state.addVulnerabilityReport.cwe);
  const cveId = useSelector((state) => state.addVulnerabilityReport.cve);

  const reportViewType = useSelector(
    (state) => state.addVulnerabilityReport.reportViewType
  );

  let commentRef = useRef();

  let cvssState = useSelector(
    (state) => state.addVulnerabilityReport.cvssCalculator
  );
  let cvssObject = cvssState.data;
  let cvssMainController = cvssState.mainController;

  const options1 = { None: 0, Low: 2, Medium: 5, High: 7, Critical: 9 };

  let cvssScore = useSelector((state) => state.addVulnerabilityReport.cvss);
  let cvssRating = useSelector(
    (state) => state.addVulnerabilityReport.severity
  );

  useEffect(() => {
    if (!cvssMainController) {
      cvssScore = score(cvssObject);
      cvssRating = rating(cvssScore);
    }
    let findOption = "";
    if (cvssMainController) {
      findOption = Object.keys(options1).find(
        (o) => o.slice(0, 1) == cvssMainController
      );
    }

    const cvssString = cvssObjectToVector(cvssObject);

    cvssMainController && findOption
      ? dispatch(
          addVulnerabilityReportSliceActions.setCvss("" + options1[findOption])
        )
      : dispatch(
          addVulnerabilityReportSliceActions.setCvss(
            isValidCvssVector(cvssString) ? "" + cvssScore : null
          )
        );

    cvssMainController && findOption
      ? dispatch(
          addVulnerabilityReportSliceActions.setSeverity("" + findOption)
        )
      : dispatch(
          addVulnerabilityReportSliceActions.setSeverity(
            isValidCvssVector(cvssString) ? "" + cvssRating : null
          )
        );
    cvssMainController
      ? addVulnerabilityReportSliceActions.setCvssVector(null)
      : dispatch(addVulnerabilityReportSliceActions.setCvssVector(cvssString));
  }, [cvssScore, cvssObject, cvssRating, cvssMainController]);

  let styling =
    "rounded-full w-24 h-16  flex flex-col items-center justify-center font-bold text-white ";
  styling +=
    cvssRating == "None" ? " bg-gray-500 " : ` bg-${cvssRating?.toLowerCase()}`;

  // console.log(cvssRating);

  let CvssInputs = [
    {
      title: "Attack Vector",
      cvssName: "AV",
      options: ["Network", "Adjacent", "Local", "Physical"],
    },
    { title: "Attack Complexity", cvssName: "AC", options: ["Low", "High"] },
    {
      title: "Privileges Required",
      cvssName: "PR",
      options: ["None", "Low", "High"],
    },
    {
      title: "User Interaction",
      cvssName: "UI",
      options: ["None", "Required"],
    },
    { title: "Scope", cvssName: "S", options: ["Unchanged", "Changed"] },
    {
      title: "Confidentiality",
      cvssName: "C",
      options: ["None", "Low", "High"],
    },
    { title: "Integrity", cvssName: "I", options: ["None", "Low", "High"] },
    { title: "Availability", cvssName: "A", options: ["None", "Low", "High"] },
  ];

  const isDarkMode = useSelector((state) => state.general.theme) == "dark";

  return (
    <div className="bg-white dark:bg-lightBlack  dark:text-white  p-2p rounded-10 ">
      <div className="flex justify-between items-center border-b-2 dark:border-b9 border-b1 ">
        <div className="flex ">
          <div className="font-bold text-xl mr-1">2.</div>
          <div className="flex grow flex-col pb-2">
            <div className="font-bold text-xl">Severity</div>
            <div>Estimate the severity of this issue.</div>
          </div>
        </div>
        {(reportViewType == "edit" || reportViewType == "update") && (
          <Popup
            ref={commentRef}
            trigger={
              <div
                className={
                  blueGradient1 +
                  " flex items-center justify-center cursor-pointer rounded-full w-10 h-10 text-white font-bold text-4xl "
                }
              >
                {" "}
                +
              </div>
            }
            on="click"
            position={"left center"}
          >
            <CommentBox
              type={"input"}
              commentRef={commentRef}
              section={"severity"}
              addComment={(comment, id, createdOn, isCreator) => {
                dispatch(
                  addVulnerabilityReportSliceActions.setSeverityComment({
                    comment: comment,
                    id,
                    createdOn,
                    isCreator,
                  })
                );
              }}
            />
          </Popup>
        )}
      </div>

      <div className="my-2p flex justify-between items-center">
        <SeverityInput options={Object.keys(options1)} isMainController />
        <div className={styling}>
          <div className="text-2xl">{cvssScore}</div>
          <div className=" font-semibold leading-3 text-xs">{cvssRating}</div>
        </div>
      </div>

      <div className="flex items-center">
        <div className="mr-2 ml-4">OR</div>{" "}
        <Divider
          sx={{
            my: 2,
            flexGrow: 1,
          }}
          className="dark:border-b9"
        />
      </div>

      <div className="text-xl mt-4">CVSS v3.1 Calculator</div>
      <div>
        <FormControl component="fieldset" className="w-full">
          <FormGroup className="max-h-96">
            {CvssInputs?.map((current, index) => {
              return (
                <div className="mt-4">
                  <div className="text-sm mb-2">{current.title}</div>
                  <SeverityInput
                    options={current.options}
                    isCvssInput={true}
                    cvssName={current.cvssName}
                  />
                </div>
              );
            })}
          </FormGroup>
        </FormControl>
      </div>
      <div className="mt-6">
        <div className="font-semibold text-lg">
          CWE ID<span className="text-[#FDBC2C]">*</span>
        </div>
        <input
          // disabled={reportViewType == "update" ? true : false}
          style={{ height: "50px" }}
          required
          multiple
          className={input1 + " mt-1"}
          placeholder="Please enter in this format : CWE-341, CWE-13"
          pattern="CWE-\d{1,4}(,\s*CWE-\d{1,4})*"
          value={cweId}
          onChange={(e) => {
            dispatch(addVulnerabilityReportSliceActions.setCwe(e.target.value));
          }}
        />
      </div>
      <div className="mt-4">
        <div className="font-semibold text-lg">
          CVE ID<span className="text-[#FDBC2C]">*</span>
        </div>
        <input
          // disabled={reportViewType == "update" ? true : false}
          style={{ height: "50px" }}
          required
          multiple
          className={input1 + " mt-1"}
          placeholder="Please enter in this format : CVE-year-id, CVE-2005-139"
          pattern="CVE-\d{4}-\d+([,\s]+CVE-\d{4}-\d+)*"
          value={cveId}
          onChange={(e) => {
            dispatch(addVulnerabilityReportSliceActions.setCve(e.target.value));
          }}
        />
      </div>
    </div>
  );
}

export default Severity;
