import { style } from "@mui/system";
import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { profileSliceActions } from "../store/profileSectionSlice";
import { blueGradient1 } from "components/tailwindClasses";

function ProfileSidebar(props) {
  let dispatch = useDispatch();
  let selected = useSelector((state) => state.profile.selected);
  const isDarkTheme = useSelector((state) => state.general.theme) == "dark";
  const role = useSelector((state) => state.user.data.role);
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "0",
          paddingTop: "8vh",
          left: "0",
          width: "20vw",
          height: "100vh",
          fontSize: "14px",
          // color: "rgba(0, 0, 0, 0.6)",
        }}
        className="bg-white dark:bg-lightBlack "
      >
        <div
          style={{
            //   height: "40%",
            display: "flex",
            flexDirection: "column",
            //   justifyContent: "space-around",
          }}
          className="p-[10px]"
        >
          <div
            className="flex mb-5 mt-2 cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src="/leftArrowBlue.svg" alt="" />
            <span className="text-blue1 ml-2 text-lg">Back</span>
          </div>
          <div
            style={{
              marginBottom: "4vh",
              display: "flex",
              alignItems: "center",
              fontSize: "18px",
              cursor: "default",
              height: "60px",
            }}
            onClick={() => {
              dispatch(profileSliceActions.setSelected(0));
            }}
            className={
              // activeOptionClass
              selected == 0 &&
              "w-full text-base font-bold text-white h-15  rounded-10 " +
                blueGradient1
            }
          >
            <img
              src={
                selected == 0
                  ? "/profileDark.svg"
                  : isDarkTheme
                  ? "/profileOutlineDark.svg"
                  : "/profileOutline.svg"
              }
              alt=""
              style={{ margin: "0 10px", width: "20px" }}
            />
            <span
              className={
                selected == 0
                  ? "font-bold text-white"
                  : isDarkTheme
                  ? "text-opacity-60 text-white"
                  : "text-opacity-60 "
              }
            >
              Profile
            </span>
          </div>
          {(role == "MANAGER" || role == "S_MANAGER") && (
            <div
              style={{
                marginBottom: "4vh",
                // fontWeight: "700",
                display: "flex",
                alignItems: "center",
                fontSize: "18px",
                cursor: "default",
                height: "60px",
              }}
              onClick={() => {
                dispatch(profileSliceActions.setSelected(1));
              }}
              className={
                // activeOptionClass
                selected == 1 &&
                "w-full text-base font-bold text-white h-15  rounded-10 " +
                  blueGradient1
              }
            >
              <img
                src={
                  selected == 1
                    ? "/companyDetailsDark.svg"
                    : isDarkTheme
                    ? "/companyDetailsOutlineDak.svg"
                    : "/companyDetailsOutline.svg"
                }
                alt=""
                style={{ margin: "0 10px", width: "20px" }}
              />
              <span
                className={
                  selected == 1
                    ? "font-bold text-white"
                    : isDarkTheme
                    ? "text-opacity-60 text-white"
                    : "text-opacity-60 "
                }
              >
                Company Details
              </span>
            </div>
          )}
          <div
            style={{
              marginBottom: "4vh",
              // fontWeight: "700",
              display: "flex",
              alignItems: "center",
              fontSize: "18px",
              cursor: "default",
              height: "60px",
            }}
            onClick={() => {
              dispatch(profileSliceActions.setSelected(2));
            }}
            className={
              // activeOptionClass
              selected == 2 &&
              "w-full text-base font-bold text-white h-15  rounded-10 " +
                blueGradient1
            }
          >
            <img
              src={
                selected == 2
                  ? "/changePasswordDark.svg"
                  : isDarkTheme
                  ? "/changePasswordOutlineDark.svg"
                  : "/changePasswordOutline.svg"
              }
              alt=""
              style={{ margin: "0 10px", width: "20px" }}
            />
            <span
              className={
                selected == 2
                  ? "font-bold text-white"
                  : isDarkTheme
                  ? "text-opacity-60 text-white"
                  : "text-opacity-60 "
              }
            >
              Change Password
            </span>
          </div>
          <div
            style={{
              marginBottom: "4vh",
              // fontWeight: "700",
              display: "flex",
              alignItems: "center",
              fontSize: "18px",
              cursor: "default",
              height: "60px",
            }}
            onClick={() => {
              dispatch(profileSliceActions.setSelected(3));
            }}
            className={
              // activeOptionClass
              selected == 3 &&
              "w-full text-base font-bold text-white h-15  rounded-10 " +
                blueGradient1
            }
          >
            <img
              src={
                selected == 3
                  ? "/loginActivityDark.svg"
                  : isDarkTheme
                  ? "/loginActivityOutlineDark.svg"
                  : "/loginActivityOutline.svg"
              }
              alt=""
              style={{ margin: "0 10px", width: "20px" }}
            />
            <span
              className={
                selected == 3
                  ? "font-bold text-white"
                  : isDarkTheme
                  ? "text-opacity-60 text-white"
                  : "text-opacity-60 "
              }
            >
              Login Activity
            </span>
          </div>
        </div>
      </div>

      <div
        style={{
          // flexGrow: 1,
          paddingLeft: "20vw",
          width: "100%",
        }}
      >
        {props.children}
      </div>
    </>
  );
}

export default ProfileSidebar;
