import React from "react";

const ButtonWithLoader = ({
  type,
  isLoading,
  text,
  loadingText,
  onClick,
  className,
  div,
}) => {
  const handleClick = async (event) => {
    if (!isLoading) {
      onClick && (await onClick(event));
    }
  };

  let classValue = className
    ? className
    : "py-2 px-4 font-semibold rounded-lg shadow-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ";

  className += isLoading ? " opacity-50 cursor-not-allowed" : "";

  return div ? (
    <div
      type={type}
      onClick={handleClick}
      disabled={isLoading}
      className={classValue}
    >
      {isLoading ? loadingText : text}
    </div>
  ) : (
    <button
      type={type}
      onClick={handleClick}
      disabled={isLoading}
      className={classValue}
    >
      {isLoading ? loadingText : text}
    </button>
  );
};

export default ButtonWithLoader;
