import React from "react";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Link, Outlet, json, useLocation, useNavigate } from "react-router-dom";
import ProfileOptions from "./ProfileOptions";
import { useDispatch, useSelector } from "react-redux";
import { getFetch } from "lib/fetch";
import Popup from "reactjs-popup";
import { useEffect } from "react";
import { NotificationsComponent } from "features/Profile/index";
import { generalActions } from "store/generalSlice";
import { Avatar, IconButton } from "@mui/material";

import ToggleButton from "components/ui/buttons/ThemeToggle/Index";
import { UserImage } from "components/ui/ImageComponent";
import { activePageActions } from "store/activePageSlice";

const paths = {
  VMDR: ["dashboard", "team", "report", "target", "invite"],
  SCANNER: ["dashboard", "scanProfile", "scanEngine", "report"],
};

function UserNavbar(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const themeState = useSelector((state) => state.general.theme);

  useEffect(() => {
    let theme = window.localStorage.getItem("theme");
    if (theme == "dark") {
      document.documentElement.classList.add("dark");
      dispatch(generalActions.setTheme("dark"));
    } else {
      document.documentElement.classList.remove("dark");
      dispatch(generalActions.setTheme(""));
    }

    // themes are "dark" or ""
  }, [themeState]);

  const isDarkMode = useSelector((state) => state.general.theme) == "dark";

  const toggleTheme = () => {
    if (isDarkMode) {
      document.documentElement.classList.remove("dark");
      dispatch(generalActions.setTheme(""));
      window.localStorage.setItem("theme", "");
    } else {
      document.documentElement.classList.add("dark");
      dispatch(generalActions.setTheme("dark"));
      window.localStorage.setItem("theme", "dark");
    }
  };

  let user = useSelector((state) => state.user.data);

  let apps = ["VMDR", "SCANNER"];

  const currentApp = useSelector((state) => state.general.currentApp);

  useEffect(() => {
    let savedState = window.localStorage.getItem("currentApp");

    if (savedState == "VMDR" || savedState == "SCANNER") {
      if (user.role != "MANAGER" && savedState == "SCANNER") {
        dispatch(generalActions.setCurrentApp("VMDR"));
        return;
      }
      dispatch(generalActions.setCurrentApp(savedState));
    }
  }, [user.role]);

  useEffect(() => {
    dispatch(
      activePageActions.setActivePage(
        paths[currentApp].findIndex(
          (item) => item == location.pathname.slice(1)
        )
      )
    );

    window.localStorage.setItem("currentApp", currentApp);
  }, [location.pathname, currentApp]);

  const navigate = useNavigate();

  const handleChange = (event) => {
    if (user.role != "MANAGER" && event.target.value == "SCANNER") {
      dispatch(generalActions.setCurrentApp("VMDR"));
    } else dispatch(generalActions.setCurrentApp(event.target.value));

    navigate("/dashboard");
  };

  // use for noti image
  const [hasNewNotification, setHasNewNotification] = useState(false);

  return (
    <>
      <div className="fixed w-full bg-white dark:bg-lightBlack top-0 left-0 flex items-center justify-between border-b border-b3 h-15 px-2p z-10">
        <Link
          to={"/dashboard"}
          style={{ textDecoration: "none", color: "black" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "default",
            }}
          >
            <img
              src="/cyteHackLogo.svg"
              alt=""
              style={{ marginRight: "5px", height: "40px" }}
            />
            <span
              style={{ fontWeight: "500", fontSize: "22px" }}
              className="dark:text-white "
            >
              CyeNET
            </span>

            {/* <div className="ml-10 dark:text-white">{user.role}</div> */}
          </div>
        </Link>

        <div
          style={{ display: "flex", alignItems: "center" }}
          className="dark:text-white"
        >
          <div className="mr-6">
            <ToggleButton
              isDark={isDarkMode}
              invertedIconLogic
              onChange={toggleTheme}
            />
          </div>
          {!props.hideSelect && (
            <Select
              value={currentApp}
              onChange={handleChange}
              sx={{
                width: "10vw",
                height: "30px",
              }}
              className=" rounded mr-5"
            >
              {apps.map((item, i) => {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          )}

          <div>
            <NotificationsComponent
              passHasNewNotification={setHasNewNotification}
            >
              <img
                src={
                  hasNewNotification
                    ? isDarkMode
                      ? "notificationYesDark.svg"
                      : "/notificationYes.svg"
                    : isDarkMode
                    ? "/notificationNoDark.svg"
                    : "/notificationNo.svg"
                }
                alt=""
                style={{ marginRight: "20px", height: "25px" }}
                className="cursor-pointer"
              />
            </NotificationsComponent>
          </div>
          <ProfileOptions>
            <div className="mr-2p">
              <Avatar
                alt={user.name}
                src={user.profileImage || ".jpg"}
                style={{ width: "35px", height: "35px" }}
              />
            </div>
          </ProfileOptions>
        </div>
      </div>
      <div
        style={{ paddingTop: "60px", position: "relative", minHeight: "100vh" }}
        className="dark:bg-lightBlack2 "
      >
        {props.children}
      </div>
    </>
  );
}

export default UserNavbar;
