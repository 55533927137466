import React, { useEffect, useState } from "react";
import { getFetch } from "lib/fetch";
import RequestCard from "./RequestCard";
import { toast } from "react-toastify";
import Loader from "components/ui/loaders/Loader";
import { useDispatch, useSelector } from "react-redux";
import { teamSliceActions } from "store/teamSlice";
import TeamMemberSkeleton from "components/ui/loaders/TeamMemberSkeleton";

function Pending(props) {
  const data = useSelector((state) => state.team.pendingRequests);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(() => true);

      let res = await getFetch("/manager/pendingReferrals");

      if (res?.status == "ok") {
        dispatch(teamSliceActions.setPendingRequests(res?.pending_referrals));
      } else if (res?.error && res?.message) {
        toast.error(res?.message);
        dispatch(teamSliceActions.setPendingRequests([]));
      } else {
        toast.error("error loading referrals, try later");
        dispatch(teamSliceActions.setPendingRequests([]));
      }

      setIsLoading(() => false);
    };
    getData();
  }, []);
  return (
    <>
      {isLoading && data?.length == 0 ? (
        <TeamMemberSkeleton />
      ) : (
        data?.map((item) => <RequestCard pending data={item} />)
      )}
    </>
  );
}

export default Pending;
