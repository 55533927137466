import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  engines: [],
  deletedEngines: [],
};

let scanEngineSlice = createSlice({
  name: "scanEngineSlice",
  initialState: initialState,
  reducers: {
    setEngines(state, action) {
      state.engines = action.payload;
    },
    setDeletedEngines(state, action) {
      state.deletedEngines = action.payload;
    },
    reset(state) {
      return initialState;
    },
  },
});

export const scanEngineSliceActions = scanEngineSlice.actions;

export default scanEngineSlice;
