import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import InfoCard from "components/ui/cards/InfoCard";
import InfoCardColored from "components/ui/cards/InfoCardColored";
import { getFetch, pythonPostFetch } from "lib/fetch";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { dashboardSliceActions } from "store/scanner/dashboardSlice";
import { Skeleton } from "@mui/material";

function InfoCards(props) {
  const dispatch = useDispatch();

  const dashboardData = useSelector((state) => state.scanner.dashboard);
  const [isLoading,setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(()=>true);
    const getData = async () => {

      const res = await pythonPostFetch("/scanner/dashboard");
      const res2 = await getFetch("/dashboard");
  
      if (res?.status == "ok" || res?.status == "success") {
        dispatch(dashboardSliceActions.setRisk(res?.data?.risk_score_symbol));
        dispatch(dashboardSliceActions.setGauge(res?.data?.risk_score));
        dispatch(
          dashboardSliceActions.setInfoCardsData({
            ...res?.data?.bug_variants,
            totalVulnerabilities:
              res?.data?.bug_variants?.critical +
              res?.data?.bug_variants?.high +
              res?.data?.bug_variants?.medium +
              res?.data?.bug_variants?.low,
            // add all the bug variants together
          })
        );
        dispatch(
          dashboardSliceActions.setChartsData({
            critical: res?.data?.bug_variants?.critical,
            high: res?.data?.bug_variants?.high,
            medium: res?.data?.bug_variants?.medium,
            low: res?.data?.bug_variants?.low,
          })  
        );
        setIsLoading(false);

      } else if (res?.error && res?.message) {
        toast.error(res?.message);
      }

      if(res2?.status === "ok"){
        dispatch(dashboardSliceActions.setTotalAssets(res2?.data?.total_asset_count));
      }else{
         toast.error(res?.message);
         setIsLoading(false); 
      }
    };

    getData();
  }, []);

  if(isLoading){
    return  <>
    <Grid
      container
      spacing={{ xs: 1, md: 2 }}
      justifyContent="space-evenly"
      alignItems="center"
    >
      {Array(6).fill(1).map((item, index) => (
        <Grid item key={index} xs={1} md={2}>
          {/* Adjust width and height accordingly */}
          <Skeleton variant="rectangular"  height={100} />
        </Grid>
      ))}
    </Grid>
  </>
  }

  return (
    <div className="text-center">
      <Grid
        container
        spacing={{ xs: 1, md: 2 }}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item xs={2}>
          <InfoCard
            title={"Total Assets"}
            count={dashboardData?.totalAssets}
            topColor={"bg-[#3CCBF7] text-white "}
            bottomColor={"dark:bg-[#3CCBF7] dark:bg-opacity-50"}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoCard
            title={"Total Vulnerabilities"}
            count={dashboardData?.totalVulnerabilities}
            topColor={"bg-[#6922D6] text-white "}
            bottomColor={"dark:bg-[#6922D6] dark:bg-opacity-50"}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoCardColored
            type={"critical"}
            title={"Critical"}
            count={dashboardData?.critical}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoCardColored
            type={"high"}
            title={"High"}
            count={dashboardData?.high}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoCardColored
            type={"medium"}
            title={"Medium"}
            count={dashboardData?.medium}
          />
        </Grid>
        <Grid item xs={2}>
          <InfoCardColored
            type={"low"}
            title={"Low"}
            count={dashboardData?.low}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default InfoCards;
